// @flow

import { useCurrentCase } from 'hooks/useCurrentCase';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import type { GetWorklistItemQuery } from 'generated/graphql';
import {
  ADDENDUM_STATUSES,
  isReporterLockedState,
  ReportStatus,
  reportStatusState,
} from '../../state';
import { useCurrentCaseClaimedStatus } from 'hooks/useCurrentCaseClaimedStatus';
import { useEffect } from 'react';

export type UseReportLockedStateReturn = {
  locked: boolean,
  isClaimedByMe: boolean,
  loading: boolean,
  claimedBy: GetWorklistItemQuery['worklistItem']?.['claimedBy'],
  submittedBy: GetWorklistItemQuery['worklistItem']?.['report']?.['owner'],
  isAddendumStatus: boolean,
  isSubmittedStatus: boolean,
};

export const useReportLockedState = (): UseReportLockedStateReturn => {
  const { currentCase: worklistItem, loadingCase } = useCurrentCase();

  const { isClaimedByMe, isClaimedBySomeoneElse, isClaimed, isArchived } =
    useCurrentCaseClaimedStatus();

  const status = useRecoilValue(reportStatusState);
  const setIsReporterLocked = useSetRecoilState(isReporterLockedState);
  const isAddendumStatus = ADDENDUM_STATUSES.includes(status);

  const isSubmittedStatus =
    status === ReportStatus.ProvisionalSubmit || status === ReportStatus.Submitted;

  const unclaimed = !isClaimed && !isArchived;

  const locked =
    loadingCase ||
    worklistItem == null ||
    isClaimedBySomeoneElse ||
    unclaimed ||
    ReportStatus.Submitted === status;

  useEffect(() => {
    setIsReporterLocked(locked);
  }, [locked, setIsReporterLocked]);

  return {
    locked,
    isClaimedByMe,
    loading: loadingCase,
    claimedBy: worklistItem?.claimedBy,
    submittedBy: worklistItem?.report?.owner,
    isAddendumStatus,
    isSubmittedStatus,
  };
};
