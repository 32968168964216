// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { EditorType } from '../../core';
import { PLACEHOLDER_FIELDS_DICT } from './constants';
import type { WorklistItem, GetWorklistItemQuery } from 'generated/graphql';
import type { ComponentType } from 'react';
import type { GroupedWorklistItem } from 'hooks/useCurrentWorklistItems';

export const PLACEHOLDER_PLUGIN_ID: 'placeholder' = 'placeholder';
export type PlaceholderPluginID = typeof PLACEHOLDER_PLUGIN_ID;

export type PlaceholderFieldID = $Keys<typeof PLACEHOLDER_FIELDS_DICT>;
export type PlaceholderFieldItem = $ReadOnly<{
  key: PlaceholderFieldID,
  name: string,
  resolve: (
    worklistItem: WorklistItem | ?GroupedWorklistItem | ?GetWorklistItemQuery['worklistItem']
  ) => ?string,
}>;

export type PlaceholderPluginElement = $ReadOnly<{
  ...EditorElement,
  type: PlaceholderPluginID,
  placeholderID: PlaceholderFieldID,
  workListItemSmid: string,
}>;

export type PlaceholderPluginMenuClick = $ReadOnly<{
  editor: EditorType,
  placeholderField: PlaceholderFieldItem,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  instance: any,
  pluginID: PlaceholderPluginID,
  currentWorklistItems: $ReadOnlyArray<GroupedWorklistItem>,
}>;

export type PlaceholderPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  onPlaceholderMenuClick: (props: PlaceholderPluginMenuClick) => void,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,

  /**
   * Array of plugin fields that the user will be able to trigger.
   */
  placeholderFields?: PlaceholderFieldItem[],
  onPlaceholderRemove?: (props: { placeholderID: PlaceholderFieldID }) => void,
  currentWorklistItems?: $ReadOnlyArray<GroupedWorklistItem>,
}>;

export type PlaceholderPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...PlaceholderPluginOptions,
  pluginID: PlaceholderPluginID,
  placeholderFields: PlaceholderFieldItem[],
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
}>;
