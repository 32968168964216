// @flow
import Text from 'common/ui/Text';
import { css } from 'styled-components';
import TextWithTooltip from 'common/TextWithTooltip';
import { Stack } from '../ui/Layout';
import { Colors } from 'styles';
import { toLocaleTimeString, toLocaleString } from 'utils/date';
import { Chip } from '../ui/Chip/Chip';
import type { CSSRules } from 'styled-components';

export const formatStudyInformation = (
  studyDescription: ?string,
  accessionNumber: ?string,
  studyDate: ?Date
): string => {
  const studyDescriptionText =
    studyDescription == null || studyDescription === '' ? 'Study Desc: N/A' : studyDescription;
  const accessionNumberText =
    accessionNumber == null || accessionNumber === '' ? 'N/A' : accessionNumber;
  const formattedTime = toLocaleTimeString(studyDate, { hour: 'numeric' });
  const formattedDate = toLocaleString(studyDate, { year: '2-digit' });
  const studyDateText =
    studyDate != null && formattedTime != null && formattedDate != null
      ? `${formattedDate} ${formattedTime}`
      : 'Time: N/A';

  return `${studyDescriptionText} (ACC: ${accessionNumberText}), ${studyDateText}`;
};

export const formatStudyInformationWithoutDate = (
  studyDescription: ?string,
  accessionNumber: ?string
): string =>
  `${
    studyDescription == null || studyDescription === '' ? 'Study Desc: N/A' : studyDescription
  } (ACC: ${accessionNumber == null || accessionNumber === '' ? 'N/A' : accessionNumber})`;

export const VARIANT = Object.freeze({
  TOOLBAR: 'toolbar',
  PANEL: 'panel',
});
type VariantType = $Values<typeof VARIANT>;

type StudyInformationCardProps = {
  studyColor: ?string,
  studyDescription: ?string,
  accessionNumber: ?string,
  studyDate: ?Date,
  variant?: VariantType,
  textCss?: CSSRules,
  studyLabel?: string,
};

export const StudyInformationCard = ({
  studyColor,
  studyDescription,
  accessionNumber,
  studyDate,
  variant = VARIANT.TOOLBAR,
  textCss,
  studyLabel,
}: StudyInformationCardProps): React$Node => {
  return (
    <Stack
      alignX={variant === VARIANT.TOOLBAR ? 'center' : 'start'}
      alignY="center"
      css={css`
        user-select: none;
        min-width: 16px;
        padding-right: 24px;
      `}
      data-testid="study-information-card"
      space="xsmall"
    >
      {variant === VARIANT.TOOLBAR && (
        <div
          css={css`
            min-height: 12px;
            min-width: 12px;
            border-radius: 12px;
            background-color: ${studyColor ?? Colors.gray5};
          `}
        />
      )}
      {variant === VARIANT.PANEL && studyLabel != null && (
        <Chip
          size="xs"
          css={css`
            margin-left: 6px;
            background: ${studyColor};
          `}
        >
          {studyLabel}
        </Chip>
      )}
      <Text
        variant="button"
        css={css`
          display: flex;
          width: 100%;
          ${textCss || ''}
        `}
      >
        <TextWithTooltip
          conditionalTooltip={true}
          name={formatStudyInformation(studyDescription, accessionNumber, studyDate)}
          variant="noWrap"
          data-testid="study-information"
        />
      </Text>
    </Stack>
  );
};
