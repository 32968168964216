// @flow
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  hasReportContentChangedState,
  isReporterLockedState,
  reportStatusState,
  ReportStatus,
  draftReportWorklistSmid,
  unsignedReportDialogState,
  defaultUnsignedReportWarningDialogState,
} from '../state';
import type { ReportStatuses } from '../state';
import { useSendFocusEvent } from 'modules/focusManager';
import { PAGE_TYPES } from 'utils/pageTypes';
import { useOpenTabs } from 'hooks/useOpenTabs';
import { useCurrentCaseId } from 'hooks/useCurrentCase';

type Props = {
  onRead?: (id: string) => void | Promise<void>,
  onView?: (id: string) => void | Promise<void>,
};

type HookResult = {
  shouldShowDialogWhenReadOrViewCase: (caseToOpenId: string) => boolean,
  handleWorklistReadOrView: (eventType: 'read' | 'view', id: string) => void,
  onDialogActionSuccess: () => void,
  onDialogActionError: () => void,
};

export function useUnsignedReportWarningDialog({ onRead, onView }: Props = {}): HookResult {
  const sendEvent = useSendFocusEvent();
  const isReporterOpen = useOpenTabs().some((tab) => [PAGE_TYPES.REPORTER].includes(tab.type));
  const currentCaseId = useCurrentCaseId();

  const hasReportContentChanged = useRecoilValue(hasReportContentChangedState);
  const isReporterLocked = useRecoilValue(isReporterLockedState);
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);

  const [{ worklistActionQueued, readyForWorklistAction, eventType, id }, setDialogState] =
    useRecoilState(unsignedReportDialogState);

  const setDraftReportWorklistSmid = useSetRecoilState(draftReportWorklistSmid);

  useEffect(() => {
    // Important to return early if we don't have onRead or onView
    // So that this hook only executes on the correct page
    if (!onRead || !onView || !readyForWorklistAction || !eventType || !id) {
      return;
    }
    if (eventType === 'read') {
      onRead(id);
    } else if (eventType === 'view') {
      onView(id);
    }

    setDialogState(defaultUnsignedReportWarningDialogState);
  }, [readyForWorklistAction, eventType, id, onRead, onView, setDialogState]);

  const shouldShowDialogWhenReadOrViewCase = useCallback(
    (caseToOpenId: string) => {
      if (
        isReporterOpen &&
        caseToOpenId !== currentCaseId &&
        hasReportContentChanged &&
        !isReporterLocked &&
        isReportStatusEditable(reportStatus)
      ) {
        return true;
      } else {
        // If not, then we need to clear the empty report screen to show the new report
        setDraftReportWorklistSmid(null);
        return false;
      }
    },
    [
      currentCaseId,
      hasReportContentChanged,
      isReporterLocked,
      isReporterOpen,
      reportStatus,
      setDraftReportWorklistSmid,
    ]
  );

  const handleWorklistReadOrView = useCallback(
    (eventType: 'read' | 'view', id: string) => {
      setDialogState((prevState) => ({
        ...prevState,
        open: true,
        worklistActionQueued: true,
        id,
        eventType,
      }));
      sendEvent({ pageType: PAGE_TYPES.REPORTER });
    },
    [sendEvent, setDialogState]
  );

  const onDialogActionSuccess = useCallback(() => {
    if (worklistActionQueued) {
      setDialogState((prev) => ({
        ...prev,
        open: false,
        readyForWorklistAction: true,
      }));
    } else {
      setDialogState(defaultUnsignedReportWarningDialogState);
    }
  }, [worklistActionQueued, setDialogState]);

  const onDialogActionError = useCallback(() => {
    setDialogState(defaultUnsignedReportWarningDialogState);
  }, [setDialogState]);

  const hookResult = useMemo<HookResult>(
    () => ({
      shouldShowDialogWhenReadOrViewCase,
      handleWorklistReadOrView,
      onDialogActionSuccess,
      onDialogActionError,
    }),
    [
      shouldShowDialogWhenReadOrViewCase,
      handleWorklistReadOrView,
      onDialogActionSuccess,
      onDialogActionError,
    ]
  );

  return hookResult;
}

const isReportStatusEditable = (reportStatus: ReportStatuses) =>
  [ReportStatus.Idle, ReportStatus.AddendumAdd].includes(reportStatus);
