// @flow

import { useCallback, useEffect, useMemo } from 'react';
import type { GetMeQuery, GetMeQueryVariables, SavedWorklistView } from 'generated/graphql';
import type { FilterStateType } from '../../WorklistFilters/types';
import { GET_ME, UPDATE_WORKLIST_SETTINGS } from 'modules/Apollo/queries';
import { useMutation } from '@apollo/client';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useWorklistViews } from './useWorklistViews';
import { WorklistFilters } from '../../WorklistFilters/types';
import { equals } from 'ramda';
import { logger } from 'modules/logger';

type useSavedWorklistViewsProps = {
  surface: string,
  filters: FilterStateType,
  updateFilters: (filters: FilterStateType) => void,
};

type useSavedWorklistViewsReturn = {
  savedWorklistViews: SavedWorklistView[],
  saveWorklistViews: (updatedViews: string[]) => Promise<void>,
};

export const useSavedWorklistViews = ({
  surface,
  filters,
  updateFilters,
}: useSavedWorklistViewsProps): useSavedWorklistViewsReturn => {
  const { data } = useCurrentUser();
  const worklistSettings = data?.me.worklistSettings;
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);
  const { worklistViews, areWorklistViewsLoading } = useWorklistViews();

  const savedWorklistViews = useMemo(
    () => [...(worklistSettings?.savedWorklistViews ?? [])],
    [worklistSettings]
  );

  const saveWorklistViews = useCallback(
    async (updatedViews: string[]) => {
      const viewIndex = savedWorklistViews.findIndex((view) => view.surface === surface);

      let updatedSavedWorklistViews;

      if (viewIndex !== -1) {
        updatedSavedWorklistViews = [
          ...savedWorklistViews.slice(0, viewIndex),
          {
            ...savedWorklistViews[viewIndex],
            views: updatedViews,
          },
          ...savedWorklistViews.slice(viewIndex + 1),
        ];
      } else {
        updatedSavedWorklistViews = [
          ...savedWorklistViews,
          {
            surface,
            views: updatedViews,
          },
        ];
      }

      const updatedResponse = {
        ...worklistSettings,
        savedWorklistViews: updatedSavedWorklistViews,
      };

      await updateWorklistSettings({
        variables: { savedWorklistViews: updatedSavedWorklistViews },
        optimisticResponse: {
          __typename: 'Mutation',
          updateWorklistSettings: {
            __typename: 'WorklistSettings',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          const { me } = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};
          proxy.writeQuery({
            query: GET_ME,
            data: {
              me: {
                ...me,
                worklistSettings: {
                  ...updatedResponse,
                },
              },
            },
          });
        },
      });
    },
    [savedWorklistViews, surface, updateWorklistSettings, worklistSettings]
  );

  // This useEffect is used to ensure that any worklist views applied or saved to
  // the user's settings are valid worklist views. It's possible that an admin deletes
  // a worklist view that is currently saved to the user's settings, which would mean
  // that the next time they load the worklist, we would try to load that worklist view
  // which would throw an error. This ensures that if any worklist views are invalid, we
  // delete them from the user's settings and remove the value from the active filters.
  useEffect(() => {
    if (areWorklistViewsLoading) return;

    const surfaceSavedWorklistView = savedWorklistViews.find((view) => view.surface === surface);
    const worklistViewSmids = new Set(worklistViews.map((view) => view.smid));
    if (surfaceSavedWorklistView != null && surfaceSavedWorklistView.views.length !== 0) {
      const validSavedWorklistViews = surfaceSavedWorklistView.views.filter((view) =>
        worklistViewSmids.has(view)
      );

      if (validSavedWorklistViews.length !== surfaceSavedWorklistView.views.length) {
        saveWorklistViews(validSavedWorklistViews);
        logger.info(
          '[useSavedWorklistViews] Overriding saved worklist views due to invalid worklist view',
          {
            validWorklistViews: worklistViewSmids,
            savedWorklistViews: surfaceSavedWorklistView.views,
            surface,
          }
        );
      }
    }

    const activeViews = filters[WorklistFilters.Views];
    if (activeViews != null && activeViews.length !== 0) {
      const validActiveViews = activeViews.filter((view) => worklistViewSmids.has(view));
      if (!equals(activeViews, validActiveViews)) {
        updateFilters({ [WorklistFilters.Views]: validActiveViews });
        logger.info(
          '[useSavedWorklistViews] Updating active worklist view filter due to invalid worklist view',
          {
            validWorklistViews: worklistViewSmids,
            activeViews: activeViews,
            surface,
          }
        );
      }
    }
  }, [
    savedWorklistViews,
    worklistViews,
    saveWorklistViews,
    surface,
    updateFilters,
    filters,
    areWorklistViewsLoading,
  ]);

  return { savedWorklistViews, saveWorklistViews };
};
