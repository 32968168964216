// @flow
import { useMemo } from 'react';
import { hexToVTKColor } from '../utils/colors';
import { LinearPolyData } from './helpers/LinearPolyData';
import * as config from '../config';
import type { Segment, MouseHandlers } from '../types';
import { withCommonPrimitiveHelpers, transformCoordinate } from './helpers/common';
import { DisplayLocation } from '@kitware/vtk.js/Rendering/Core/Property2D/Constants';
import BatchedGeometry2DRepresentation from './helpers/BatchedGeometry2DRepresentation';
import type { BasePrimitiveProps } from './PrimitiveTypes';
import { useAnnotationColors } from '../Annotations/helpers/useAnnotationColors';
import { PICKABLE_TRANSPARENT_OPACITY } from './constants';

// warning- use of strips has not been validated yet
export type PolygonFill = 'verts' | 'lines' | 'polys' | 'strips';

type PolygonProps = {
  ...BasePrimitiveProps,
  fill?: PolygonFill,
  segments: $ReadOnlyArray<Segment>,
  opacity?: number,
  rawPoints?: boolean,
};

export const Polygon: React$ComponentType<{ ...PolygonProps, ...MouseHandlers }> =
  withCommonPrimitiveHelpers(
    ({
      id,
      fill = 'lines',
      segments,
      color,
      opacity = 1,
      thickness = config.thickness,
      rawPoints = false,
      visible = true,
    }: PolygonProps) => {
      const { defaultColor } = useAnnotationColors();

      const points: Array<number> = useMemo(
        () =>
          rawPoints
            ? [...segments.flatMap((segment) => segment.flat())]
            : segments.length
              ? [...segments.flatMap((segment) => segment.flat()), ...segments[0][0]]
              : [],
        [segments, rawPoints]
      );

      const property = useMemo(
        () => ({
          // We don't set 0 because it disables the picking
          opacity: opacity !== 0 ? opacity : PICKABLE_TRANSPARENT_OPACITY,
          color: hexToVTKColor(color ?? defaultColor),
          displayLocation: DisplayLocation.FOREGROUND,
          pointSize: 4,
          lineWidth: thickness,
        }),
        [color, opacity, thickness, defaultColor]
      );
      const actorProperties = useMemo(
        () => ({
          visibility: visible,
        }),
        [visible]
      );

      return (
        <BatchedGeometry2DRepresentation
          id={id}
          actor={actorProperties}
          property={property}
          transformCoordinate={transformCoordinate}
        >
          <LinearPolyData points={points} fill={fill} />
        </BatchedGeometry2DRepresentation>
      );
    }
  );
