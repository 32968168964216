// @flow
import type { Vector } from 'domains/viewer/AnnotationsManager/annotationCreators';
import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

import { PAGE_TYPES } from 'utils/pageTypes';
import type { EditorType } from 'slate';
import type { Key } from 'react';

export const DEEP_LINK_PLUGIN_ID: 'deepLink' = 'deepLink';
export type DeepLinkPluginID = typeof DEEP_LINK_PLUGIN_ID;

type SliceDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER,
  variant: 'imageSlice',
  context: {
    seriesSmid: string,
    seriesDisplayName: ?string,
    imageNumber: number,
    frameSmid: string,
  },
};

type PointDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER,
  variant: 'point',
  context: {
    seriesSmid: string,
    label: string,
    point: Vector,
  },
};

type StudyDeepLinkProps = {
  target: typeof PAGE_TYPES.VIEWER,
  variant: 'study',
  context: {
    studySmid: string,
  },
};

export type DeepLink = SliceDeepLinkProps | PointDeepLinkProps | StudyDeepLinkProps;

export type DeepLinkPluginElement = {
  ...EditorElement,
  ...DeepLink,
  type: DeepLinkPluginID,
};

export type DeepLinkPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: React$ComponentType<any>,
  ignoreMergeFieldsInNavigation?: boolean,
  isAutoFillComparisonEnabled?: boolean,
  onDeepLinkClick?: (element: DeepLinkPluginElement | null, editor: EditorType | null) => void,
  onDeepLinkEditClick?: (element: DeepLinkPluginElement | null, editor: EditorType | null) => void,
  getIsEditingStudyNode?: (key: Key) => boolean,
}>;

export type DeepLinkPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...DeepLinkPluginOptions,
  pluginID: DeepLinkPluginID,
  pluginName: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: React$ComponentType<any>,
}>;

export type DeepLinkContexts = DeepLink['context'];
export type DeepLinkVariants = DeepLink['variant'];
export type DeepLinkTargets = DeepLink['target'];
