// @flow

import type { CreateOnKeyDown } from '../../types';
import isHotkey from 'is-hotkey';
import type { HistoryPluginPropertyOptions } from './types';
import { getUndoHotkey, getRedoHotkey } from './utils';

export const onKeyDownHistory: CreateOnKeyDown<HistoryPluginPropertyOptions> =
  ({ pluginID }) =>
  (e, { editor }) => {
    if (isHotkey(getUndoHotkey(), e)) {
      e.preventDefault();
      // $FlowIgnore[prop-missing] History adds to the singleton. It'll be there if this is called
      editor.undo();
      return true;
    }

    if (isHotkey(getRedoHotkey(), e)) {
      e.preventDefault();
      // $FlowIgnore[prop-missing] History adds to the singleton. It'll be there if this is called
      editor.redo();
      return true;
    }
  };
