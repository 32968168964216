// @flow

import type { EditorPlugin } from '../../types';
import { PicklistEditable } from './components';
import { getRenderElement } from '../../utils';
import { PICKLIST_PLUGIN_ID } from './types';
import type { PicklistPluginOptions, PicklistPluginPropertyOptions } from './types';
import { enhanceEditorStatePicklist } from './enhanceEditorStatePicklist';
import { onKeyDownPicklist } from './onKeyDownPicklist';
import { DEFAULT_SHOW_PICKLIST_OPTIONS_IN_EDITOR } from 'config/constants';

export const PicklistPlugin = (
  {
    component = PicklistEditable,
    showPicklistOptionsInEditor = DEFAULT_SHOW_PICKLIST_OPTIONS_IN_EDITOR,
    pluginName = 'Picklist',
    disableKeyDown = false, // disabled when building picklists so input is only inserted via the field pane
    ...rest
  }: PicklistPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: PicklistPluginPropertyOptions = {
    pluginID: PICKLIST_PLUGIN_ID,
    pluginName,
    component,
    showPicklistOptionsInEditor,
    disableKeyDown,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement<PicklistPluginPropertyOptions>(options, {
      spreadAttributes: false,
    }),
    enhanceEditorState: enhanceEditorStatePicklist(options),
    onKeyDown: onKeyDownPicklist(options),
  };
};
