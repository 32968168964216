// @flow

import { Renderer } from '../../RichTextEditor';
import { useFields } from './Fields.Context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type { Styles } from 'generated/graphql';
import { useReporterStyles } from 'hooks/useReporterStyles';
import { UnifiedEditor } from '../../RichTextEditor/ui/UnifiedEditor';
import { Stack } from 'common/ui/Layout';
import { css } from 'styled-components';

export const FieldsSectionRenderer = (): React$Node => {
  const [{ content }] = useFields();
  const { data } = useCurrentUser();
  const reporterSettings = data?.me?.reporterSettings;

  const style: Styles = useReporterStyles(reporterSettings);

  return (
    <Stack
      data-stack-stretch
      css={css`
        max-height: 100%;
      `}
      space="small"
      stretchX
      stretchY
    >
      <UnifiedEditor isAddendumEditor={false} isDisabled={true} variant="view">
        <Renderer content={content} style={style} />
      </UnifiedEditor>
    </Stack>
  );
};
