// @flow
import { createContext } from '../util/createContext';
import type { ChipColor, ChipSize, ChipVariant } from './Chip';
import type { CreateContextReturn } from '../util/createContext';

type TChipContext = {
  size: ChipSize,
  color: ChipColor,
  variant: ChipVariant,
  disabled: boolean,
};

export const [ChipProvider, useChip, ChipContext]: CreateContextReturn<TChipContext> =
  createContext<TChipContext>({
    strict: true,
    errorMessage:
      'useChip: `context` is undefined. Seems you forgot to wrap component within the ChipProvider',
    name: 'ChipContext',
  });
