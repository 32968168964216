/* eslint-disable no-redeclare */
// @flow

import type { SeriesDataFragment } from 'generated/graphql';
import { useEffect, useRef } from 'react';
import { RecoilSync, syncEffect } from 'recoil-sync';
import { atomFamily, useRecoilValue } from 'recoil';
import type { RecoilState, AtomEffect } from 'recoil';
import { custom, or, literal } from '@recoiljs/refine';
import {
  useViewportsConfigurationsSelector,
  viewportsConfigurationsEntries,
} from '../ViewportsConfigurations';
import type { Study, Series, Stack } from '../ViewportsConfigurations';
import type { ViewportTypeKeys } from 'config/constants';
import { useBaseViewerData } from './StudyLoader/useStudies';
import type { ViewportConfiguration } from '../ViewportsConfigurations/types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isStackSmidMultiLayer } from './stackUtils';
import { rehydrateSlimStackToLayeredStack } from '../ViewportsConfigurations/manipulators';

type ItemType = 'study' | 'series' | 'stack' | 'viewType';
function makeItemKey(viewportId: string, type: ItemType) {
  return `${viewportId}:${type}`;
}
function parseItemKey(key: string): { viewportId: string, type: ItemType | string } {
  const [viewportId, type] = key.split(':');
  return { viewportId, type };
}

type ViewerRecoilContextProps = {
  children: React$Node,
};

function findStudy(studies: $ReadOnlyArray<Study>, smid: void | string) {
  return studies.find((study) => study.smid === smid);
}

function findSeries(seriesList: void | $ReadOnlyArray<SeriesDataFragment>, smid: void | string) {
  return seriesList?.find((series) => series.smid === smid);
}

function findStack(stackedFrames: void | $ReadOnlyArray<Stack>, smid: void | string) {
  return stackedFrames?.find((stack) => stack.smid === smid);
}

function getStoreValues(
  viewportId: string,
  viewportConfiguration: ?ViewportConfiguration,
  studies: $ReadOnlyArray<Study>
) {
  const study = findStudy(studies, viewportConfiguration?.study?.smid);
  const series = findSeries(study?.seriesList, viewportConfiguration?.series?.smid);
  const stack = isStackSmidMultiLayer(viewportConfiguration?.stack?.smid)
    ? rehydrateSlimStackToLayeredStack(viewportConfiguration?.stack, study)
    : findStack(study?.stackedFrames, viewportConfiguration?.stack?.smid);
  return [
    [makeItemKey(viewportId, 'viewType'), viewportConfiguration?.viewType],
    [makeItemKey(viewportId, 'study'), study],
    [makeItemKey(viewportId, 'series'), series],
    [makeItemKey(viewportId, 'stack'), stack],
  ];
}

export function ViewerRecoilContext({ children }: ViewerRecoilContextProps): React$Node {
  const updateAllKnownItemsRef = useRef();
  const viewportsConfigurations = useRecoilValue(useViewportsConfigurationsSelector());
  const { studies, loading } = useBaseViewerData();

  useEffect(() => {
    if (viewportsConfigurations == null) return;
    const updateAllKnownItems = updateAllKnownItemsRef.current;
    if (updateAllKnownItems == null) return;

    const diff = new Map(
      viewportsConfigurationsEntries(viewportsConfigurations).reduce(
        (acc, [viewportId, viewportConfiguration]) => {
          if (viewportConfiguration == null) return acc;

          return [...acc, ...getStoreValues(viewportId, viewportConfiguration, studies)];
        },
        []
      )
    );

    // Updating synced atom families to null will cause unwrapped exception because it will be null
    // from the sync effect. Let it stay previous until we have new items
    if (loading && diff.size === 0) {
      return;
    }

    updateAllKnownItems(diff);
  }, [studies, viewportsConfigurations, loading]);

  if (viewportsConfigurations == null) {
    return (
      <LinearProgress
        data-testid="viewer-recoil-context-loader"
        css="position: absolute; inset: 0;"
      />
    );
  }

  return (
    <RecoilSync
      storeKey="viewer"
      read={(itemKey) => {
        const { viewportId } = parseItemKey(itemKey);
        const viewportConfiguration = viewportsConfigurations[viewportId];
        const values = getStoreValues(viewportId, viewportConfiguration, studies);

        return values.find(([key]) => key === itemKey)?.[1];
      }}
      listen={({ updateAllKnownItems }) => {
        updateAllKnownItemsRef.current = updateAllKnownItems;
      }}
    >
      {children}
    </RecoilSync>
  );
}

const viewportContextSyncEffect = <I: ItemType, R>({
  viewportId,
  type,
}: {
  viewportId: string,
  type: I,
}): AtomEffect<R> => {
  return syncEffect({
    refine: or(
      custom((x) => x),
      literal(undefined)
    ),
    storeKey: 'viewer',
    itemKey: makeItemKey(viewportId, type),
  });
};

export const currentStudyAtomFamily: (viewportId: string) => RecoilState<?Study> = atomFamily({
  key: 'viewer.currentStudyAtomFamily',
  default: null,
  effects: (viewportId) => [
    viewportContextSyncEffect<'study', ?Study>({ viewportId, type: 'study' }),
  ],
});

export const currentSeriesAtomFamily: (viewportId: string) => RecoilState<?Series> = atomFamily({
  key: 'viewer.currentSeriesAtomFamily',
  default: null,
  effects: (viewportId) => [
    viewportContextSyncEffect<'series', ?Series>({ viewportId, type: 'series' }),
  ],
});

export const currentStackAtomFamily: (viewportId: string) => RecoilState<?Stack> = atomFamily({
  key: 'viewer.currentStackAtomFamily',
  default: null,
  effects: (viewportId) => [
    viewportContextSyncEffect<'stack', ?Stack>({ viewportId, type: 'stack' }),
  ],
});

export const currentViewTypeAtomFamily: <T: ViewportTypeKeys>(
  viewportId: string
) => RecoilState<T> =
  // $FlowIgnore[incompatible-type-arg] we use the above to force cast
  atomFamily({
    key: 'viewer.currentViewTypeAtomFamily',
    default: 'TWO_D_DRE',
    effects: (viewportId) => [
      viewportContextSyncEffect<'viewType', ?ViewportTypeKeys>({ viewportId, type: 'viewType' }),
    ],
  });
