// @flow
import { DEPLOY_ENV } from 'config';
import { Scrubber } from 'slate';

Scrubber.setScrubber((key: string, value: mixed) => {
  if (DEPLOY_ENV !== 'production') {
    return value;
  }

  if (key === 'text') return '... scrubbed ...';
  return value;
});
