// @flow

import { Colors } from 'styles';

type PicklistOptionProps = {
  children: Array<React$Node>,
  id: string,
  isSelected: boolean,
  onMouseOver: () => void,
  onSelect: () => void,
};

export function PicklistOption({
  children,
  id,
  onMouseOver,
  isSelected,
  onSelect,
}: PicklistOptionProps): React$Node {
  return (
    <li
      role="option"
      aria-selected={isSelected}
      onMouseOver={onMouseOver}
      onMouseDown={(e) => {
        // Prevent default so that clicking option doesn't steal focus from editor
        e.preventDefault();
        onSelect();
      }}
      data-testid={`picklist-option-${id}`}
      css={`
        display: grid;
        grid-template-columns: 20px 1fr;
        grid-column-gap: 5px;
        padding: 5px 15px;
        background-color: ${isSelected ? Colors.mainBlueHover : '#000a12'};
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;
      `}
    >
      {children}
    </li>
  );
}
