// @flow
import { useRef } from 'react';
import { equals } from 'ramda';

export const useEqualValue = <T, S>(
  value: Array<T>,
  // $FlowFixMe[incompatible-type] don't know how to tell Flow S can be T
  map: (T) => S = (value) => value
): Array<S> => {
  const prevValue = useRef<Array<S>>([]);
  const newValue = value.map(map);

  if (!equals(prevValue.current, newValue)) {
    prevValue.current = newValue;
  }

  return prevValue.current;
};

export const useStableValue = <T>(value: T, comparator: (T, T) => boolean): T => {
  const prevValue = useRef<T>(value);

  if (!comparator(prevValue.current, value)) {
    prevValue.current = value;
  }

  return prevValue.current;
};
