// @flow

import type { ApolloQueryResult, DocumentNode } from '@apollo/client';

import { useQuery } from '@apollo/client';

import { useCallback, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { useDebouncedCallback } from 'use-debounce';

export type FetchMoreFn = (startIndex: number, stopIndex: number) => void | Promise<void>;

export type UsePaginatedDataResult<TData, TVariables, TGraphQLResponse> = {|
  items: $ReadOnlyArray<TData>,
  loading: boolean,
  fetchMore: FetchMoreFn,
  hasNextPage: boolean,
  totalCount: number,
  isFetchMoreLoading: boolean,
  refetch: (variables: TVariables) => Promise<ApolloQueryResult<TGraphQLResponse>>,
  filteredCount: number,
  nextCursor: ?string,
|};

export type UsePaginatedDataArgs<TData, TVariables, TGraphQLResponse> = {|
  query: DocumentNode,
  variables: TVariables,
  transformData: (data: ?TGraphQLResponse) => {
    items: $ReadOnlyArray<TData>,
    totalCount: number,
    next: ?string,
    filteredCount?: number,
  },
  skip?: boolean,
  pollInterval?: number,
  limit?: number,
|};

export function useTablePaginatedData<TData, TVariables, TGraphQLResponse>({
  query,
  variables,
  transformData,
  skip = false,
  pollInterval,
  limit,
}: UsePaginatedDataArgs<TData, TVariables, TGraphQLResponse>): UsePaginatedDataResult<
  TData,
  TVariables,
  TGraphQLResponse,
> {
  const isMounted = useMountedState();

  const { data, loading, fetchMore, refetch } = useQuery(query, {
    variables,
    skip,
    fetchPolicy: 'cache-and-network',
    pollInterval: pollInterval ?? undefined,
  });
  const [isFetchMoreLoading, setIsFetchMoreLoading] = useState(false);

  const { items, totalCount, next, filteredCount } = useMemo(() => {
    return transformData(data);
  }, [data, transformData]);

  const hasNextPage = next != null;

  const handleFetchMore = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (!isMounted()) return;

      if (next == null) {
        return;
      }

      setIsFetchMoreLoading(true);

      fetchMore<TData, TVariables>({
        query,
        variables: {
          ...variables,
          cursor: next,
          limit: limit ?? stopIndex - items.length,
        },
      })
        .then(() => {
          setIsFetchMoreLoading(false);
        })
        .catch(() => {
          setIsFetchMoreLoading(false);
        });
    },
    [isMounted, next, query, fetchMore, variables, limit, items.length]
  );

  const fetchMoreDebounced = useDebouncedCallback(handleFetchMore, 500);

  return {
    items,
    totalCount,
    loading,
    fetchMore: fetchMoreDebounced,
    refetch,
    hasNextPage,
    isFetchMoreLoading,
    filteredCount: filteredCount ?? 0,
    nextCursor: next,
  };
}
