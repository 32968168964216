// @flow

import { useCurrentUser } from 'hooks/useCurrentUser';
import { UPDATE_WORKLIST_SETTINGS, GET_ME } from 'modules/Apollo/queries';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import type { GetMeQuery, GetMeQueryVariables } from 'generated/graphql';

type UseSavedSortingPreferencesReturn = {
  savedSortingPreferences: { [surface: string]: string },
  saveSortingPreferences: (surface: string, column: string, order: string) => void,
};

export const useSavedSortingPreferences = (): UseSavedSortingPreferencesReturn => {
  const meQuery = useCurrentUser();
  const worklistSettings = meQuery?.data?.me.worklistSettings;
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);

  const savedSortingPreferences = useMemo(() => {
    const preferences = worklistSettings?.savedSortingPreferences ?? [];

    return preferences.reduce<{ [key: string]: string }>(
      (acc: { [key: string]: string }, { surface, searchParams }) => {
        acc[surface] = searchParams;
        return acc;
      },
      {}
    );
  }, [worklistSettings]);

  const saveSortingPreferences = useCallback(
    (surface, column, order) => {
      const updatedSortingPreferences = [];
      const params = `sortColumn=${column}&sortOrder=${order}&tab=${surface}`;

      const exists = worklistSettings?.savedSortingPreferences?.find(
        (preference) => preference.surface === surface
      );

      if (exists && exists.searchParams === params) {
        return;
      }

      if (!exists) {
        updatedSortingPreferences.push({
          surface,
          searchParams: params,
        });
      }

      worklistSettings?.savedSortingPreferences?.forEach((preference) => {
        if (preference.surface === surface) {
          updatedSortingPreferences.push({
            surface: preference.surface,
            searchParams: params,
          });
        } else {
          updatedSortingPreferences.push(preference);
        }
      });

      const updatedResponse = {
        ...worklistSettings,
        savedSortingPreferences: updatedSortingPreferences,
      };

      updateWorklistSettings({
        variables: { savedSortingPreferences: updatedSortingPreferences },
        optimisticResponse: {
          __typename: 'Mutation',
          updateWorklistSettings: {
            __typename: 'WorklistSettings',
            ...updatedResponse,
          },
        },
        update: (proxy) => {
          const { me } = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};

          proxy.writeQuery({
            query: GET_ME,
            data: {
              me: {
                ...me,
                worklistSettings: {
                  ...updatedResponse,
                },
              },
            },
          });
        },
      });
    },
    [updateWorklistSettings, worklistSettings]
  );

  return { savedSortingPreferences, saveSortingPreferences };
};
