// @flow

import { reporter } from 'modules/analytics/constants';

export const marks = Object.freeze({
  nvoq: {
    Dictation: {
      DoneMessageSent: 'nvoq.Dictation.DoneMessageSent',
      FirstNonEmptyHypothesisText: 'nvoq.Dictation.FirstNonEmptyHypothesisText',
      FirstNonEmptyStableText: 'nvoq.Dictation.FirstNonEmptyStableText',
      StableTextReceived: 'nvoq.Dictation.StableTextReceived',
      StartDictation: 'nvoq.Dictation.StartDictation',
    },
    WS: {
      Create: 'nvoq.WS.Create',
      Open: 'nvoq.WS.Open',
      Close: 'nvoq.WS.Close',
    },
  },
  sirona: {
    Dictation: {
      RecordingStarted: 'sirona.Dictation.RecordingStarted',
      RecordingStopped: 'sirona.Dictation.RecordingStopped',
    },
  },
});

export const measures = Object.freeze({
  nvoq: {
    Dictation: {
      HypothesisStableGap: {
        name: reporter.sys.nvoqHypothesisStableGap,
        startMark: marks.nvoq.Dictation.FirstNonEmptyHypothesisText,
        endMark: marks.nvoq.Dictation.FirstNonEmptyStableText,
      },
      TimeToFirstStableText: {
        name: reporter.sys.nvoqTimeToFirstStableText,
        startMark: marks.nvoq.Dictation.StartDictation,
        endMark: marks.nvoq.Dictation.StableTextReceived,
      },
      TimeToLastStableText: {
        name: reporter.sys.nvoqTimeToLastStableText,
        startMark: marks.nvoq.Dictation.DoneMessageSent,
        endMark: marks.nvoq.Dictation.StableTextReceived,
      },
    },
    WS: {
      TimeToConnect: {
        name: reporter.sys.nvoqWebSocketTimeToConnect,
        startMark: marks.nvoq.WS.Create,
        endMark: marks.nvoq.WS.Open,
      },
      TimeConnected: {
        name: reporter.sys.nvoqWebSocketTimeConnected,
        startMark: marks.nvoq.WS.Open,
        endMark: marks.nvoq.WS.Close,
      },
    },
  },
  sirona: {
    Dictation: {
      DictationDuration: {
        name: reporter.sys.dictationDuration,
        startMark: marks.sirona.Dictation.RecordingStarted,
        endMark: marks.sirona.Dictation.RecordingStopped,
      },
    },
  },
});
