// @flow

import React from 'react';

import { usePicklistState } from 'domains/reporter/Template/usePicklist';
import Tooltip from 'common/ui/Tooltip';
import { fragmentToString } from 'domains/reporter/RichTextEditor/utils';
import { getPicklistOptionDisplayName } from 'domains/reporter/RichTextEditor/plugins/picklist';
import { isMacro } from 'domains/reporter/RichTextEditor/plugins/macroPlaceholder/utils';
import { Colors } from 'styles';
import { Stack } from 'common/ui/Layout';
import CheckIcon from '@material-ui/icons/Check';
import { css } from 'styled-components';

export const PicklistOptionsOutline = (): React$Node => {
  const { activePicklist, selectPicklistItem, selectedPicklistOptionIds } = usePicklistState();

  if (activePicklist == null) {
    return null;
  }

  return (
    <Stack vertical data-testid="picklist-options-outline">
      {activePicklist.options.map((option, idx) => {
        const isPicklistOptionSelected =
          activePicklist != null && selectedPicklistOptionIds[activePicklist?.id] === option.id;

        return (
          <Stack
            data-testid="picklist-option-row"
            aria-selected={isPicklistOptionSelected}
            alignY="start"
            key={'picklist-option-' + idx}
            css={css`
              color: ${Colors.blue4};
              margin-left: 28px;
              cursor: pointer;
            `}
            onMouseDown={(e) => {
              // Don't steal the focus from the editor!
              e.preventDefault();
              selectPicklistItem(idx);
            }}
            space="xsmall"
          >
            {isPicklistOptionSelected && (
              <CheckIcon css="margin-top: 2px; height: 16px; width: 16px" />
            )}
            <Tooltip
              content={
                option.text != null
                  ? isMacro(option.text)
                    ? fragmentToString(option.text)
                    : option.text
                  : getPicklistOptionDisplayName(option)
              }
              backgroundColor={Colors.gray7}
              color={Colors.gray1}
            >
              <div
                css={`
                  font-size: 1.3rem;
                  line-height: 20px;
                  ${!isPicklistOptionSelected ? 'margin-left: 22px;' : ''}
                  word-break: break-all;
                  &:hover {
                    color: ${Colors.blue2};
                  }
                `}
              >
                {idx + 1}
                {'. '}
                {getPicklistOptionDisplayName(option)}
              </div>
            </Tooltip>
          </Stack>
        );
      })}
    </Stack>
  );
};
