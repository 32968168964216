// @flow
import { saveWindowSyncInfo } from 'modules/windowDesyncDetector';
import { useCallback, useEffect } from 'react';
import {
  useCurrentCaseId,
  useCurrentComparativeStudies,
  useSetCurrentCase,
} from './useCurrentCase';
import {
  addExtensionListener,
  focusEvent,
  removeExtensionListener,
  syncEvent,
} from 'domains/extension/extensionEventCreators';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';
import { dispatchExtensionEvent } from '../domains/extension/extensionEventCreators';

/**
 * When mounted, registers a listener that will set the case context when a message is received from
 * another window. This hook will return a function `syncCase` that can send a message to other
 * windows to update their case context.
 */
export const useCaseSync = (): ((id: string, studies?: Array<string>) => void) => {
  const [, setCurrentComparativeStudies] = useCurrentComparativeStudies();
  const currentCaseId = useCurrentCaseId();
  const setCurrentCase = useSetCurrentCase();

  useEffect(() => {
    const handler = ({
      detail,
    }: {
      detail: {
        from: ?string,
        to: string,
        studies: ?(string[]),
      },
    }) => {
      const { from, to, studies } = detail;
      if (from != null && currentCaseId != null && from !== currentCaseId) {
        return;
      }
      if (studies != null) {
        setCurrentComparativeStudies({ caseSmid: to, studySmids: studies });
      } else {
        setCurrentCase(to);
        // focus viewer/0, instead of viewer/1, viewer/2, etc.
        const paths = ['viewer/0', 'reporter'];
        if (paths.some((path) => window.location.pathname.includes(path))) {
          dispatchExtensionEvent(focusEvent());
        }
      }
    };

    addExtensionListener(EXTENSION_RESPONSE_EVENTS.PRIORS, handler);

    return () => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.PRIORS, handler);
    };
  }, [currentCaseId, setCurrentCase, setCurrentComparativeStudies]);

  const syncCase = useCallback(
    (id: string, studies?: Array<string>) => {
      if (studies != null) {
        setCurrentComparativeStudies({ caseSmid: id, studySmids: studies });
      } else {
        setCurrentCase(id);
      }
      saveWindowSyncInfo(id);
      try {
        dispatchExtensionEvent(syncEvent({ from: null, to: id, studies }));
      } catch (err) {
        console.error(err);
      }
    },
    [setCurrentCase, setCurrentComparativeStudies]
  );

  return syncCase;
};
