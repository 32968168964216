// @flow
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const ANATOMIC_NAVIGATOR_PLUGIN_ID: 'anatomicNavigator' = 'anatomicNavigator';
export type AnatomicNavigatorPluginID = typeof ANATOMIC_NAVIGATOR_PLUGIN_ID;

export type AnatomicNavigatorPluginOptions = $ReadOnly<{|
  ...EditorPluginOptions,
|}>;

export type AnatomicNavigatorPluginPropertyOptions = $ReadOnly<{|
  ...EditorPluginPropertyOptions,
  ...AnatomicNavigatorPluginOptions,
  pluginID: AnatomicNavigatorPluginID,
  pluginName: string,
|}>;
