// @flow
import MuiDivider from '@material-ui/core/Divider';
import { Colors } from 'styles';

export const Divider = (props: { ... }): React$Node => (
  <MuiDivider
    {...props}
    css={`
      background-color: ${Colors.gray4};
    `}
  />
);
