// @flow

import type { CreateEnhanceEditorState } from '../../types';
import type { HeadingErrorPluginPropertyOptions } from './types';
import { Element, Transforms, Editor, Node } from '../../core';
import type { EditorType, PathType, NodeType } from '../../core';
import { isMarkActive } from '../../utils';
import { HeadingLevel } from '../heading/constants';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { normalizeText } from './utils';
import type { NodeEntry } from 'slate';

// Checks to see if there exists a node in the range with
const matchFunction = (
  n: NodeType,
  editor: EditorType,
  path: PathType,
  headingText: string
): boolean => {
  return (
    n.type === HEADING_PLUGIN_ID &&
    n.level === HeadingLevel.H1 &&
    normalizeText(Node.string(n)) === headingText &&
    ![...Node.texts(n)].some((textNodeEntry) => textNodeEntry[0].headingError === true)
  );
};

const setHeadingError = (editor: EditorType, path: PathType, value: boolean): void => {
  const { selection: originalSelection } = editor;
  if (originalSelection == null) {
    return;
  }

  const headingRange = Editor.range(editor, path);
  if (value) {
    Transforms.select(editor, headingRange);
    editor.addMark('headingError', value);
  } else {
    Transforms.select(editor, headingRange);
    if (isMarkActive(editor, 'headingError')) {
      editor.removeMark('headingError');
    }
  }

  Transforms.select(editor, originalSelection);
};

// This will flag when there is a duplicate heading in the editor. For a given heading node, we first check to see
// if there is at least one heading node before it that contains the same text and is not itself a duplicate header.
// If there is, then we flag it. Then, we do the same check for after the heading. This way, we are only flagging
// duplicate headers and not the original header.
export const enhanceEditorStateHeadingError: CreateEnhanceEditorState<
  HeadingErrorPluginPropertyOptions,
> =
  ({ pluginID }) =>
  (editor) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = (entry: NodeEntry<>) => {
      const [node, path] = entry;

      if (
        Element.isElement(node) &&
        node.type === HEADING_PLUGIN_ID &&
        Node.string(node) !== '' &&
        node.level === HeadingLevel.H1
      ) {
        const startOfEditor = Editor.start(editor, []);
        const headingText = normalizeText(Node.string(node));
        const beforeHeadingPoint = Editor.before(
          editor,
          Editor.point(editor, path, { edge: 'start' })
        );

        const previousMatchingHeadings =
          beforeHeadingPoint != null
            ? [
                ...Editor.nodes(editor, {
                  at: Editor.range(editor, startOfEditor, beforeHeadingPoint),
                  match: (n: NodeType) => matchFunction(n, editor, path, headingText),
                }),
              ]
            : [];

        if (previousMatchingHeadings.length > 0) {
          setHeadingError(editor, path, true);
        } else {
          const afterHeadingPoint = Editor.after(
            editor,
            Editor.point(editor, path, { edge: 'end' })
          );
          const endOfEditor = Editor.end(editor, []);

          const afterMatchingHeadings =
            afterHeadingPoint != null
              ? [
                  ...Editor.nodes(editor, {
                    at: Editor.range(editor, afterHeadingPoint, endOfEditor),
                    match: (n: NodeType) => matchFunction(n, editor, path, headingText),
                  }),
                ]
              : [];

          if (afterMatchingHeadings.length > 0) {
            setHeadingError(editor, path, true);
          } else {
            const afterHeadingPoint = Editor.after(
              editor,
              Editor.point(editor, path, { edge: 'end' })
            );
            const endOfEditor = Editor.end(editor, []);

            const afterMatchingHeadings =
              afterHeadingPoint != null
                ? [
                    ...Editor.nodes(editor, {
                      at: Editor.range(editor, afterHeadingPoint, endOfEditor),
                      match: (n: NodeType) => matchFunction(n, editor, path, headingText),
                    }),
                  ]
                : [];
            if (afterMatchingHeadings.length > 0) {
              setHeadingError(editor, path, true);
            } else if (
              [...Node.texts(node)].some((textNodeEntry) => textNodeEntry[0].headingError === true)
            ) {
              setHeadingError(editor, path, false);
            }
          }
        }
      }
      normalizeNode(entry);
    };

    return editor;
  };
