// @flow

import { Range, Transforms, Element, Editor, Point } from 'slate';
import type {
  EditorType,
  NodeType,
  OperationType,
  TextType,
  ElementType,
  TextUnit,
  NodeEntry,
} from 'slate';
import { clone } from 'ramda';

import { NOOP } from 'config/constants';
import type { AllPluginID } from '../../types';
import { BRACKET_TYPES } from '../../../constants';
import {
  getDefaultFieldName,
  hasDuplicateFieldName,
  isEligibleNamedField,
} from '../../../utils/fieldNaming';
import { PLACEHOLDER_PLUGIN_ID } from '../../placeholder/types';
import { convertParagraphsIntoLineBreaks } from '../../lineBreak/utils';
import { HEADING_PLUGIN_ID } from '../../heading/types';

type WithInlineTemplateOptions<T> = $ReadOnly<{
  pluginID: AllPluginID,

  /**
   * The text that will insert the given plugin.
   */
  triggerText?: string,

  /**
   * Called when inserting a node. Should return a valid Slate element.
   */
  createNode?: () => T,

  /**
   * Called after inserting the node into Slate.
   */
  onNodeCreate?: (element: T) => void,

  /**
   * Called after inserting the node into Slate.
   */
  onNodeRemove?: (element: T) => void,
}>;

/**
 * Plugin that handles the internals of slate for inline template style nodes such as placeholders and picklists.
 */
export const withInlineTemplate =
  <T>({
    triggerText,
    pluginID,
    createNode,
    onNodeCreate = NOOP,
    onNodeRemove = NOOP,
    onNodeCopy = NOOP,
  }: WithInlineTemplateOptions<T>): ((editor: EditorType) => EditorType) =>
  (editor) => {
    const {
      apply,
      insertText,
      insertFragment,
      isInline,
      deleteBackward,
      deleteForward,
      normalizeNode,
    } = editor;

    editor.apply = (operation: OperationType) => {
      apply(operation);
    };

    editor.isInline = (element: ElementType) => {
      return element.type === pluginID ? true : isInline(element);
    };

    editor.insertFragment = (fragment: Array<NodeType>) => {
      const newFragment = clone(fragment);

      // Get the lowest node above the current selection that matches the bracket selection
      // This is for checking if the paste is happening inside of a bracket
      const [bracketSelected] =
        Editor.above(editor, {
          // $FlowFixMe[incompatible-call]
          at: editor.selection,
          // $FlowFixMe[speculation-ambiguous]
          match: ({ type }) => BRACKET_TYPES.includes(type),
          mode: 'lowest',
        }) ?? [];

      if (bracketSelected != null) {
        return Transforms.insertNodes(editor, convertParagraphsIntoLineBreaks(newFragment));
      }

      return insertFragment(newFragment);
    };

    editor.insertText = (text) => {
      const { selection } = editor;
      const { marks } = editor;

      if (text === triggerText && createNode != null && selection) {
        const parent = Editor.above(editor, { at: Editor.start(editor, selection) });

        if (
          parent != null &&
          parent[0].type !== HEADING_PLUGIN_ID &&
          (parent[0].type !== PLACEHOLDER_PLUGIN_ID || !BRACKET_TYPES.includes(parent[0].type))
        ) {
          const nodeToInsert = createNode();
          Transforms.insertNodes(editor, nodeToInsert);
          onNodeCreate(nodeToInsert);

          return;
        }
      }

      /**
       * By default, Slate moves the cursor outside of the edge of an inline node
       * to insert text. This is not what we want for our use case. Override it.
       *
       * Line 162: packages/slate/src/create-editor.ts
       */
      const inlineEntry = Editor.above(editor, {
        match: (n: NodeType) => Editor.isInline(editor, n),
        mode: 'highest',
      });

      if (inlineEntry) {
        const [inlineNode] = inlineEntry;

        if (inlineNode.type === pluginID) {
          if (marks) {
            const node: TextType = { ...marks, text };
            Transforms.insertNodes(editor, node);
          } else {
            Transforms.insertText(editor, text);
          }

          editor.marks = null;

          return;
        }
      }

      insertText(text);
    };

    editor.deleteBackward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;

      const currentNodes = [
        ...Editor.nodes(editor, {
          match: (n: NodeType) => n.type === pluginID,
          mode: 'lowest',
        }),
      ];

      // If the selection is currently at the start of a bracket node, that means that
      // we want to unwrap the node and delete the brackets
      if (currentNodes.length > 0) {
        const [currentNode, currentPath] = currentNodes[0];

        if (Editor.isStart(editor, selection.anchor, currentPath)) {
          Transforms.unwrapNodes(editor, { at: currentPath });
          onNodeRemove(currentNode);
          return;
        }
      }

      // Check if the point before the selection is inside a bracket node. If it is,
      // then ensure that the selection is equal to the point right after that bracket node.
      const pointBefore = Editor.before(editor, selection);
      if (pointBefore != null) {
        const nodesBefore = [
          ...Editor.nodes(editor, {
            match: (n: NodeType) => {
              return n.type === pluginID;
            },
            at: pointBefore,
            mode: 'lowest',
          }),
        ];

        if (nodesBefore.length > 0) {
          const [nodeBefore, nodePathBefore] = nodesBefore[0];
          const pointAfter = Editor.after(editor, nodePathBefore);

          if (
            pointAfter != null &&
            Range.isCollapsed(selection) &&
            Point.equals(selection.anchor, pointAfter)
          ) {
            Transforms.unwrapNodes(editor, { at: nodePathBefore });
            onNodeRemove(nodeBefore);
            return;
          }
        }
      }

      deleteBackward(unit);
    };

    editor.deleteForward = (unit: TextUnit) => {
      const { selection } = editor;
      if (!selection) return;

      const currentNodes = [
        ...Editor.nodes(editor, {
          match: (n: NodeType) => n.type === pluginID,
          mode: 'lowest',
        }),
      ];

      if (currentNodes.length > 0) {
        const [currentNode, currentPath] = currentNodes[0];
        if (Editor.isEnd(editor, selection.anchor, currentPath)) {
          Transforms.unwrapNodes(editor, { at: currentPath });
          onNodeRemove(currentNode);
          return;
        }
      }

      // Check if the point after the selection is inside a bracket node. If it is,
      // then ensure that the selection is equal to the point right before that bracket node.
      const pointAfter = Editor.after(editor, selection);
      if (pointAfter != null) {
        const nodesAfter = [
          ...Editor.nodes(editor, {
            match: (n: NodeType) => {
              return n.type === pluginID;
            },
            at: pointAfter,
            mode: 'lowest',
          }),
        ];

        if (nodesAfter.length > 0) {
          const [nodeAfter, nodePathAfter] = nodesAfter[0];
          const pointBefore = Editor.before(editor, nodePathAfter);

          if (
            pointBefore != null &&
            Range.isCollapsed(selection) &&
            Point.equals(selection.anchor, pointBefore)
          ) {
            Transforms.unwrapNodes(editor, { at: nodePathAfter });
            onNodeRemove(nodeAfter);
            return;
          }
        }
      }

      deleteForward(unit);
    };

    editor.normalizeNode = (entry: NodeEntry<>) => {
      const [node, path] = entry;

      if (Element.isElement(node) && node.type === pluginID) {
        if (
          isEligibleNamedField(String(node.type)) &&
          (node.name == null ||
            String(node.name).trim() === '' ||
            hasDuplicateFieldName(editor, String(node.name)))
        ) {
          Transforms.setNodes(editor, { name: getDefaultFieldName(editor, entry) }, { at: path });
          // We do not return early, in this case, because we still want to normalize the node.
        }
      }

      return normalizeNode(entry);
    };

    return editor;
  };
