// @flow

import type { CreateOnKeyDown } from '../../types';
import type { PicklistPluginPropertyOptions, NodeSelectorState } from './types';
import { onKeyDownInlineTemplate } from '../common/inlineTemplate';
import { getNodeSelectedStateByNodeType } from '../../hooks';
import isHotkey from 'is-hotkey';
import { Editor, Range, Transforms } from '../../core';
import type { NodeType } from '../../core';
import { PICKLIST_PLUGIN_ID } from './types';
import { find } from '../../utils';
import { isSelectionContainedInNode } from '../../utils/isSelectionContainedInNode';
import { logger } from 'modules/logger';

export const onKeyDownPicklist: CreateOnKeyDown<PicklistPluginPropertyOptions> =
  ({ pluginID, disableKeyDown, variant }) =>
  (e, { editor, nodeState }) => {
    if (disableKeyDown === true) {
      const picklistEntry = find(editor, (n) => n.type === PICKLIST_PLUGIN_ID);

      const { selection } = editor;

      // If you are using the field pane in either the macro and template editors, we do not allow you to directly interact with
      // the picklist element in the editor. Rather, you need to edit the options in the pane. As a result, we block any keyboard interaction
      // within the picklist element that aren't entire deletions. If your cursor is entirely inside a picklist, we block all keyboard interactions.
      if (selection != null && picklistEntry != null) {
        if (isSelectionContainedInNode(editor, picklistEntry[1], editor.selection)) {
          e.preventDefault();
          logger.warn(
            `[onKeyDownPicklist] Attempt to edit picklist directly in ${variant === 'fragment' ? 'Macro' : 'Template'} Editor`
          );
        }
      }
    }

    const stateAndUpdater = getNodeSelectedStateByNodeType<NodeSelectorState>({
      nodeState,
      editor,
      pluginID,
    });

    const selection = editor.selection;
    if (selection == null) {
      return;
    }

    if (stateAndUpdater) {
      const [
        { isOpen, moveItemUp, selectPicklistItem, moveItemDown, resetActiveSelectedPicklistOption },
        setState,
      ] = stateAndUpdater;

      // If selection is collapsed, that means that we are likely not trying to replace the entire
      // picklist with another option
      if (isOpen && !Range.isCollapsed(selection)) {
        const [, path] = Editor.node(editor, selection, {
          match: (n: NodeType) => n.type === PICKLIST_PLUGIN_ID,
          mode: 'highest',
        });
        const edges = Editor.edges(editor, path);

        // If the selection is not equal to the edges of the picklist, that means we have
        // selected some portion of the picklist. As a result, we again are likely not trying
        // to replace the entire picklist with another option
        if (!Range.equals({ anchor: edges[0], focus: edges[1] }, selection)) {
          if (isHotkey('enter', e)) {
            e.preventDefault();
            Transforms.splitNodes(editor, {
              always: true,
              match: (n) => n.type === 'paragraph',
              mode: 'highest',
            });
            return true;
          }
        } else {
          if (isHotkey('up', e)) {
            e.preventDefault();
            moveItemUp();
            return true;
          } else if (isHotkey('down', e)) {
            e.preventDefault();
            moveItemDown();
            return true;
          } else if (isHotkey('esc', e)) {
            e.preventDefault();
            resetActiveSelectedPicklistOption();
            setState((state) => ({ ...state, isOpen: false }));
            return true;
          } else if (isHotkey('enter', e)) {
            e.preventDefault();
            selectPicklistItem();
            return true;
          }
        }
      }
    }

    return onKeyDownInlineTemplate({ pluginID })(e, { editor, nodeState });
  };
