// @flow

import { useEffect, useState } from 'react';
import { createPixelDataSharedWorker } from 'modules/viewer/workers/PixelDataSharedWorker';
import type { PixelDataSharedWorker } from 'modules/viewer/workers/PixelDataSharedWorker';
import { PATH } from 'config/constants';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import { atom, useSetRecoilState, useRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import Text from 'common/ui/Text';
import { openWindowEvent } from 'domains/extension/extensionEventCreators';
import { isWorkspaceManagerAvailable } from 'modules/workspace-manager';
import { dispatchExtensionEvent } from '../../extension/extensionEventCreators';
import { env } from 'config/env';

export const OpenPixelDataSharedWorker = (): React$Node => {
  const setWorkerOpened = useSetRecoilState(pixelWorkerOpenedState);
  useEffect(() => {
    createPixelDataSharedWorker((messageEvent: MessageEvent) => {
      // $FlowIgnore[incompatible-type] - we know the data is of type WorkerMessageData
      if (messageEvent.data?.type === 'pong') {
        setWorkerOpened(true);
        // $FlowIgnore[incompatible-use] - we know the data is of type PongMessage
        if (messageEvent.data?.inits > 0) {
          window.close();
        }
      }
    });
  }, [setWorkerOpened]);

  // scripts cannot close the window unless the page was launched from a window
  // navigating directly to `PATH.PIXEL_WORKER` will not let the page close itself
  return (
    <Text
      css={`
        flex: 1;
        align-self: center;
      `}
      variant="display2"
      align="center"
      flex="1"
    >
      You may close this window if it did not close automatically.
    </Text>
  );
};

export const useOpenPixelDataSharedWorker = (): PixelDataSharedWorker | null => {
  const [hasWorkerOpened, setWorkerOpened] = useRecoilState(pixelWorkerOpenedState);

  useEffect(() => {
    if (!hasWorkerOpened) {
      const isFocused = document.hasFocus();
      if (isWorkspaceManagerAvailable()) {
        const { protocol, host } = window.location;

        const url = `${protocol}//${host}${PATH.PIXEL_WORKER}`;
        dispatchExtensionEvent(
          openWindowEvent({
            url,
            windowConfig: {
              // `focused` disrupts functionality of `minimized` state
              // see https://issues.chromium.org/issues/40204304
              focused: null,
              top: null,
              left: null,
              width: null,
              height: null,
              state: 'minimized',
            },
          })
        );
        // generally not needed, but a fallback in case the new window somehow steals focus
        if (isFocused) {
          window.focus();
        }
      } else {
        if (env.STORYBOOK === 'true') {
          return;
        }

        window.open(
          PATH.PIXEL_WORKER,
          PATH.PIXEL_WORKER,
          'noopener=yes, width=300, height=150, left=0, top=0, toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no'
        );
        if (isFocused) {
          window.focus();
        }
      }

      // Viewer functionality is absolutely dependent on the Shared Worker opening.
      // In the event the dedicated window cannot open, update the state so that
      // the Viewer windows will start talking to the Shared Worker, which will
      // start a new Shared Worker if not already present.
      setTimeout(() => {
        setWorkerOpened(true);
      }, 1500);
    }
  }, [hasWorkerOpened, setWorkerOpened]);

  const [pixelDataSharedWorker, setPixelDataSharedWorker] = useState<PixelDataSharedWorker | null>(
    null
  );

  useEffect(() => {
    if (hasWorkerOpened && pixelDataSharedWorker == null) {
      setPixelDataSharedWorker(createPixelDataSharedWorker());
    }
  }, [hasWorkerOpened, pixelDataSharedWorker]);

  return pixelDataSharedWorker;
};

export const pixelWorkerOpenedState: RecoilState<boolean> = atom({
  key: 'viewer.dre.pixelWorkerOpenState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});

export default OpenPixelDataSharedWorker;
