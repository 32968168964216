//@flow
import { PixelDataLoader } from 'modules/viewer/PixelDataLoader';
import {
  providerMap,
  analyticsStackInitialLoadsTracker,
  analyticsStackUnhangedLoadsTracker,
  providerTracker,
} from 'modules/viewer/workers/PixelDataSharedWorker';

import { ProgressiveImagingProvider } from './ProgressiveImagingProvider';
import type { ProgressiveImagingProviderArgs } from './ProgressiveImagingProvider';
import analytics from 'modules/analytics';
import type { TransferType } from 'modules/viewer/PixelDataLoader';
import type { PixelDataSharedWorker } from 'modules/viewer/workers/PixelDataSharedWorker';
import type { FrameData } from 'modules/viewer/imageloaders/BaseWebSocketImageLoader';
import type {
  HandleFrameMessage,
  HandleInitialFrameMessage,
} from 'modules/viewer/workers/PixelWorkerConnection';
import type { FullSingleLayerStack } from '../../../ViewportsConfigurations/types';
import { LOADING_PRIORITIES } from 'domains/viewer/loadingPriorities';

export type WorkerLoadedImagingProviderArgs<T> = {
  ...ProgressiveImagingProviderArgs<T>,
  transferType?: TransferType,
  pixelDataSharedWorker: PixelDataSharedWorker,
};

export class WorkerLoadedImagingProvider<
  +T: FullSingleLayerStack = FullSingleLayerStack,
> extends ProgressiveImagingProvider {
  transferType: TransferType;
  #loadPromiseResolve: null | (() => void);
  #sharedWorker: PixelDataSharedWorker;
  #pendingLoads: Set<string>; // make private after done debugging

  constructor({
    transferType = 'pixels',
    pixelDataSharedWorker,
    ...progressiveImagingProviderArgs
  }: WorkerLoadedImagingProviderArgs<T>) {
    super(progressiveImagingProviderArgs);

    this.type = transferType === 'pixels' ? 'websockets-pixels' : 'websockets';
    this.transferType = transferType;
    this.#loadPromiseResolve = null;
    this.#sharedWorker = pixelDataSharedWorker;
    this.#pendingLoads = new Set();

    this.#sharedWorker.port.postMessage({
      type: 'init',
      transferType,
      SUPPORTED_TEXTURES: this.supportedTextures,
    });

    providerMap.set(this.stack.smid, this);
  }

  cancelLoad() {
    this.#sharedWorker.port.postMessage({
      type: 'cancel-transfer',
      stack: this.stack,
    });
  }

  _loadStack(initialSlice: number, priority: number): Promise<void> {
    this.stack.frames.forEach((frame) => {
      this.#pendingLoads.add(frame.smid);
    });

    if (this.transferType === 'dicom') {
      return this.#loadWithDicomTransfer(initialSlice, priority);
    } else {
      return this.#loadWithPixelTransfer(initialSlice, priority);
    }
  }

  handleFrame(frameData: HandleFrameMessage | HandleInitialFrameMessage | FrameData): void {
    super.handleFrame(frameData);
    this.#pendingLoads.delete(frameData.frameSmid);
  }

  updateLoadPriority({ focus, priority }: { focus: number, priority: number }) {
    if (this.transferType !== 'dicom') {
      this.#sharedWorker.port.postMessage({
        type: 'update-priority',
        stack: this.stack,
        focus,
        isDropped: this.isDroppedStack(),
        priority,
      });
    }
  }

  _resolveLoad() {
    this.#loadPromiseResolve?.();
  }

  #loadWithDicomTransfer(initialSlice: number, priority: number): Promise<void> {
    const imageLoader = new PixelDataLoader({
      transferType: 'dicom',
      SUPPORTED_TEXTURES: this.supportedTextures,
    });

    return new Promise((resolve) => {
      imageLoader.loadStack({
        stack: this.stack,
        initialFrameIndex: initialSlice,
        stackPriority: priority,
        isDropped: this.isDroppedStack(),
        callback: ({ type, data }) => {
          if (type === 'complete' && data == null) {
            resolve();
            return;
          } else if (data != null && typeof data === 'object') {
            this.handleFrame(data);
          } else {
            throw new Error(`Unknown message type: ${type}, or data was null when it shouldn't be`);
          }
        },
      });
    });
  }

  #loadWithPixelTransfer(initialSlice: number, priority: number): Promise<void> {
    return new Promise(async (resolve) => {
      this.#loadPromiseResolve = resolve;
      analytics.timing('viewer_ws_imaging_provider_load_stack');
      providerTracker.set(this.stack.smid, this);
      if (priority <= LOADING_PRIORITIES.HANGED) {
        analyticsStackInitialLoadsTracker.add(this.stack.smid);
      } else {
        analyticsStackUnhangedLoadsTracker.add(this.stack.smid);
      }

      // Request persistent storage for site as we will be storing the pixel data using IndexedDB
      // $FlowIssue[prop-missing] Flow support for navigator.storage is not complete
      if (navigator?.storage?.persist) {
        try {
          const isPersisted = await navigator.storage.persist();

          if (isPersisted === false) {
            console.warn('Persistent storage was not granted');
          }
        } catch (err) {
          console.warn('Persistent storage request failed', err);
        }
      }

      this.#sharedWorker.port.postMessage({
        type: 'load-stack',
        stack: this.stack,
        initialSlice,
        partOfInitialLoad: this.wasPartOfInitialLoad(),
        isDropped: this.isDroppedStack(),
        priority,
      });
    });
  }

  loadFrameIntoMemory(frameIndex: number, options?: { unloadOthers: boolean }) {
    const unloadOthers = options?.unloadOthers ?? false;

    if (unloadOthers) {
      this.stack.frames.forEach((frame, idx) => {
        if (idx !== frameIndex) {
          this.unloadFrameFromMemory(idx);
        }
      });
    }
    const frame = this.stack.frames[frameIndex];
    if (
      frame != null &&
      !this._frameLoadingStateMap[this.stack.smid]?.has(frame.smid) &&
      !this.#pendingLoads.has(frame.smid)
    ) {
      this.#pendingLoads.add(frame.smid);
      this.#sharedWorker.port.postMessage({
        type: 'load-frame',
        stack: this.stack,
        frameIndex,
        priority: LOADING_PRIORITIES.UNHANGED,
      });
    }
  }

  reloadAllFramesIntoMemory(): void {
    if (this.#pendingLoads.size === 0) {
      this.status = 'init';
      this.deferredLoad = null;
      this.load({
        initialSlice: Math.max(this.getActiveSlice('TWO_D_DRE'), 0),
        priority: LOADING_PRIORITIES.HANGED,
      });
    } else {
      this.stack.frames.forEach((frame, frameIndex) => {
        // a load is ongoing, but early frames may have been cache-only
        // reload those frames directly to fill in early gaps
        if (!this.#pendingLoads.has(frame.smid)) {
          this.loadFrameIntoMemory(frameIndex);
        }
      });
    }
  }
}
