// @flow

import { useSelected } from '../../../core';
import { PicklistElement } from './PicklistElement';
import type { PicklistPluginElement, ReportPicklistBeforeSubmissionOptions } from '../types';

type PicklistBuilderProps = {
  attributes: $Exact<{
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React$Ref<React$ElementType>,
  }>,
  options: $ReadOnlyArray<ReportPicklistBeforeSubmissionOptions>,
  children: React$Node | Array<React$Node>,
  element: PicklistPluginElement,
  'data-editor-element'?: string,
  variant?: string,
};

export const PicklistBuilder = ({
  attributes,
  options = [],
  children,
  element,
  variant,
  ...rest
}: PicklistBuilderProps): React$Node => {
  const selected = useSelected();

  return (
    <>
      <PicklistElement
        {...attributes}
        element={element}
        selected={selected}
        data-editor-element={rest['data-editor-element']}
        variant={variant}
      >
        {children}
      </PicklistElement>
    </>
  );
};
