// @flow

import { SECTION_HEADER_PLUGIN_ID } from './types';
import type { SectionHeaderPluginElement } from './types';
import { find } from '../../utils/find';
import { Transforms } from '../../core';
import type { EditorType, NodeEntry, PathType, PointType, RangeType } from '../../core';
import type { NodeType } from 'slate';

/**
 * Insert a section header whereever the current selection is in the editor.
 * @param {EditorType} editor The editor to insert into.
 * @param {string} conceptID The concept ID to use for the section header
 */
export const insertSectionHeader = ({
  editor,
  conceptID,
  viewerRefID,
  at,
}: {
  editor: EditorType,
  conceptID: string,
  viewerRefID: string,
  at?: PathType | PointType | RangeType | void,
}) => {
  Transforms.insertNodes(
    editor,
    {
      type: SECTION_HEADER_PLUGIN_ID,
      conceptID,
      viewerRefID,
      children: [
        {
          text: conceptID,
        },
      ],
    },
    {
      at,
    }
  );
};

/**
 * Find a section header node in the editor by it's concept ID, returns void if nothing found
 * @param {EditorType} editor The editor to search in
 * @param {string} conceptID The concept ID of the section header to match
 */
export const findNodeByIDs = ({
  editor,
  conceptID,
  viewerRefID,
}: {
  editor: EditorType,
  conceptID: string,
  viewerRefID: string,
}): ?NodeEntry<SectionHeaderPluginElement> =>
  find<SectionHeaderPluginElement>(
    editor,
    (n: NodeType) =>
      n.type === SECTION_HEADER_PLUGIN_ID &&
      n.conceptID === conceptID &&
      n.viewerRefID === viewerRefID,
    { at: [] }
  );
