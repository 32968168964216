// @flow

import type { CreateDeserializeHtml } from '../../types';

import type { BoldPluginPropertyOptions } from './types';

// TODO: This is not completed, it's just here to demostrate how the type system and structure of plugins.
export const deserializeBold: CreateDeserializeHtml<BoldPluginPropertyOptions> = () => {
  return {};
};
