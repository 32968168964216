// @flow

import { forwardRef } from 'react';
import { Colors } from 'styles';
import { NotEditable } from '../../../components';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import { Transforms, ReactEditor } from 'domains/reporter/RichTextEditor/core';
import { transparentize } from 'color2k';
import { Editor } from '../../../core';
import type { NodeType } from '../../../core';
import { equals } from 'ramda';
import { slateContentToString } from '../../../utils/slateContentToString';
import { isSquareBracketType } from '../../../constants';
import { touchedRequiredFieldsState } from '../../../../Reporter/state';
import { useRecoilState } from 'recoil';
import { PICKLIST_PLUGIN_ID } from '../../picklist';
import { logger } from 'modules/logger';

export type InlineTemplateElementProps = $ReadOnly<{
  children: React$Node,
  selected?: boolean,
  handleClick?: () => void,
  testID?: string,
  'data-editor-element'?: string,
  'data-slate-node'?: 'element',
  'data-slate-inline'?: true,
  'data-slate-void'?: true,
  'data-selected'?: boolean,
  dir?: 'rtl',
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  element: any,
  leftDelimiter?: string,
  rightDelimiter?: string,
  variant?: string,
}>;

// For the Slate editor selection to work as expected, it helps to have a NotEditable boundary around the bookmark content.
// e.g. without this, double clicking a single word inside of a bookmark will place the focus outside of the bookmark.
// This will only be rendered if the inline bookmark is not empty.
const ZERO_WIDTH_SPACE = '\u200B';

const cssForBrackets = (
  selected: boolean,
  isEmptyInlineBookmark: boolean,
  leftDelimiter: string,
  rightDelimiter: string,
  isEmptyTouchedRequired: boolean
) =>
  `
  &:focus {
    outline: 0;
  }
  &:before {
    content: ${"'" + leftDelimiter + "'"};
    font-family: 'Roboto Flex';
    color: ${isEmptyTouchedRequired ? Colors.red5 : selected ? Colors.blue5 : Colors.gray10};
    padding-right: ${isEmptyInlineBookmark ? '1px' : '0px'};
    position: relative;
    bottom: 1px;
  }
  &:after {
    content:  ${"'" + rightDelimiter + "'"};
    font-family: 'Roboto Flex';
    color: ${isEmptyTouchedRequired ? Colors.red5 : selected ? Colors.blue5 : Colors.gray10};
    padding-left: ${isEmptyInlineBookmark ? '1px' : '0px'};
    position: relative;
    bottom: 1px;
  }
  padding: 2px 0px;
  vertical-align: baseline;
  color: ${isEmptyTouchedRequired ? Colors.red5 : selected ? Colors.blue5 : Colors.yellow6};
  span[data-slate-string="true"]::selection {
    background-color: ${transparentize(Colors.blue5, 0.7)};
  }
  cursor: text;
  ${
    isEmptyInlineBookmark
      ? `span[data-slate-zero-width] {
      padding: 0 1px;
  }`
      : ''
  }
  `;

export const InlineTemplateElement: React$AbstractComponent<
  InlineTemplateElementProps,
  HTMLElement,
> = forwardRef<InlineTemplateElementProps, HTMLElement>(
  (
    {
      children,
      selected = false,
      handleClick,
      testID,
      element,
      leftDelimiter = '[',
      rightDelimiter = ']',
      variant,
      ...props
    },
    ref
  ) => {
    const [{ editor }] = useSlateSingletonContext();
    const [touchedRequiredFields] = useRecoilState(touchedRequiredFieldsState);
    const text = slateContentToString(element.children) ?? '';
    const isEmptyInlineBookmark = text === '';

    // When editing a template or macro we disable the ability to type directly into the brackets
    let showCursorNotAllowedSelectedPicklist = false;
    let isSelected = selected;
    let isEmptyTouchedRequired = false;

    try {
      if (editor != null && editor.selection != null) {
        const selectionNode = Editor.above(editor, {
          at: editor.selection,
          match: (n: NodeType) => isSquareBracketType(String(n.type)),
        });

        if (selectionNode != null) {
          showCursorNotAllowedSelectedPicklist =
            selected &&
            (variant === 'template' || variant === 'fragment') &&
            selectionNode[0].type === PICKLIST_PLUGIN_ID;

          if (!Editor.isEditor(selectionNode[0])) {
            isSelected = selected && equals(element.children, selectionNode[0].children);
          }
        }

        if (element !== null) {
          // $FlowIgnore[prop-missing] ReactEditorType and Editor type are compatible
          const key = ReactEditor.findKey(editor, element);
          if (touchedRequiredFields.has(key)) {
            isEmptyTouchedRequired = true;
          }
        }
      }
    } catch (e) {
      logger.info('[InlineTemplateElement] Attempting to check if field is required', {
        error: e,
      });
    }

    let css = '';
    if (element.shouldRenderBrackets !== false) {
      css = cssForBrackets(
        isSelected,
        isEmptyInlineBookmark,
        leftDelimiter,
        rightDelimiter,
        isEmptyTouchedRequired
      );
    }

    return (
      <span
        data-testid={testID}
        ref={ref}
        css={`
          ${css}
          cursor: ${showCursorNotAllowedSelectedPicklist ? 'not-allowed' : 'initial'};
        `}
        {...props}
        onClick={handleClick}
        onMouseUp={() => {
          // onMouseUp used to ensure this logic fires successfully upon first interaction inside Report and Template Editor
          // We want to specially handle clicks on empty inline bookmarks, to make it easier to select them.
          if (isEmptyInlineBookmark) {
            // $FlowFixMe[prop-missing] EditorType vs ReactEditorType
            // $FlowFixMe[incompatible-call] EditorType vs ReactEditorType
            const path = ReactEditor.findPath(editor, element);
            // $FlowFixMe[incompatible-call] EditorType vs ReactEditorType
            Transforms.select(editor, path);
            return;
          }
        }}
      >
        <>
          {isEmptyInlineBookmark ? <NotEditable /> : <NotEditable>{ZERO_WIDTH_SPACE}</NotEditable>}
          {children}
          {isEmptyInlineBookmark ? <NotEditable /> : <NotEditable>{ZERO_WIDTH_SPACE}</NotEditable>}
        </>
      </span>
    );
  }
);
InlineTemplateElement.displayName = 'InlineTemplateElement';
