// @flow
import { MessageDisplayValues } from 'generated/graphql';
import type { ToolInteractionMessageResponse } from 'generated/graphql';
import { showToastMessage } from '../feedback';

export function handleMessageResponse(
  response: $ReadOnly<ToolInteractionMessageResponse>
): void | null {
  const { message, severity, display } = response;

  if (message == null) return null;

  if (display === MessageDisplayValues.Toast) {
    return showToastMessage(message, severity);
  }

  return alert(message);
}
