// @flow

import type { AllReporterVariant } from '../../hooks/useReporter';
import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const INLINE_BOOKMARK_PLUGIN_ID: 'inlineBookmark' = 'inlineBookmark';
export type InlineBookmarkPluginID = typeof INLINE_BOOKMARK_PLUGIN_ID;

export type InlineBookmarkPluginElement = $ReadOnly<{
  ...EditorElement,
  name?: string,
  type: InlineBookmarkPluginID,
}>;

export type InlineBookmarkPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  component?: ComponentType<$FlowFixMe>,
  onInlineBookmarkInsert?: (inlineBookmark: InlineBookmarkPluginElement) => void,
  onInlineBookmarkRemove?: (inlineBookmark: InlineBookmarkPluginElement) => void,
  variant?: AllReporterVariant,
}>;

export type InlineBookmarkPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...InlineBookmarkPluginOptions,
  pluginID: InlineBookmarkPluginID,
  hotkey: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
}>;
