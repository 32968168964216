// @flow

import type {
  FilterDefinitionType,
  FilterStateType,
  WorklistFiltersType,
  WorklistSurfaceSearchValues,
} from './types';

import { SORTABLE_WORKLIST_COLUMN_MAP, SORT_ORDER } from 'config/constants';
import { WorklistSurface } from '../Worklist/types';
import type { WorklistSurfaceType } from '../Worklist/types';

export const DEFAULT_WORKLIST_SORT_COLUMN_ID = 'studyDate';
export const DEFAULT_SORT_COLUMNS = [SORTABLE_WORKLIST_COLUMN_MAP[DEFAULT_WORKLIST_SORT_COLUMN_ID]];

export const DEFAULT_SORT_ORDERS = [SORT_ORDER.ASCENDING];

export const DEFAULT_WORKLIST_SURFACE_SEARCH_VALUES: WorklistSurfaceSearchValues =
  Object.fromEntries(
    // $FlowFixMe[incompatible-type]
    Object.values(WorklistSurface).map((surface: WorklistSurfaceType) => [surface, ''])
  );

export const DEFAULT_EMPTY_WORKLIST_SURFACE_FILTERS: { [string]: FilterStateType } =
  Object.fromEntries(
    // $FlowFixMe[incompatible-type]
    Object.values(WorklistSurface).map((surface: WorklistSurfaceType) => [surface, {}])
  );

export type FilterConfig = {
  [WorklistSurfaceType]: {
    lockedFilters: FilterStateType,
    defaultFilters: FilterDefinitionType,
    hiddenFilters: WorklistFiltersType[],
  },
};
