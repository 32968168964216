// @flow

import type { AllReporterVariant } from '../../hooks/useReporter';

import { Editor, Node } from '../../core';
import type { EditorType, NodeType, NodeEntry } from '../../core';
import { HeadingLevel } from '../heading/constants';
import { HEADING_PLUGIN_ID } from '../heading/types';

export const getDuplicateHeadingErrorText = (variant: AllReporterVariant): string => {
  switch (variant) {
    case 'report':
      return 'Cannot sign report with duplicate section headers';
    case 'template':
      return 'Cannot save template with duplicate section headers';
    default:
      return 'Cannot have duplicate section headers';
  }
};

export const normalizeText = (text: string): string => {
  return text
    .trim()
    .toLowerCase()
    .replace(/[^\w\s']|_/g, '')
    .replace(/\s+/g, ' ');
};

export const reportHasDuplicateHeadings = (editor: EditorType, text?: string): boolean => {
  return (
    [
      ...Editor.nodes(editor, {
        at: [],
        match: (n: NodeType) =>
          n.type === HEADING_PLUGIN_ID &&
          n.level === HeadingLevel.H1 &&
          [...Node.texts(n)].some(
            (textNodeEntry) =>
              textNodeEntry[0].headingError === true &&
              (text == null || normalizeText(Node.string(textNodeEntry[0])) === normalizeText(text))
          ),
      }),
    ].length > 0
  );
};

export const getHeadingErrorNodesFromParentNode = (root: NodeType): Array<NodeEntry<>> => {
  const nodes = Node.nodes(root);
  const headingErrorNodes = [];
  for (const [node] of nodes) {
    if (
      node.type === HEADING_PLUGIN_ID &&
      [...Node.texts(node)].filter((textNode) => textNode[0].headingError).length > 0
    ) {
      headingErrorNodes.push(node);
    }
  }

  // $FlowIgnore[incompatible-return] we ensured these are node entries
  return headingErrorNodes;
};
