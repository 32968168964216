// @flow
import type { SlateContent } from '../types';
import { Text, Element } from '../core';
import type { NodeType } from 'slate';

export const walkSlateContent =
  (
    onElement?: (node: NodeType) => mixed,
    onText?: (node: NodeType) => mixed
  ): ((content: SlateContent) => void) =>
  (content) => {
    const walkNode = (node: NodeType) => {
      // At a text node
      if (Text.isText(node)) return onText ? onText(node) : node;

      // At an element node
      if (Element.isElement(node) && !!onElement) onElement(node);

      // Don't loop if element node has no children
      // $FlowFixMe[incompatible-type] we check the type above
      if (node.children.length === 0) return node;

      // Walk its children
      // $FlowFixMe[incompatible-use] we check the type above
      node.children.forEach(walkNode);
    };

    // Content can either be an array or object
    if (Array.isArray(content)) {
      content.forEach(walkNode);
    } else {
      walkNode(content);
    }
  };
