// @flow

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Colors } from 'styles';
import { Ellipsis } from 'common/ui/Ellipsis';
import TableLoading from 'common/TableLoading';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import type {
  Column,
  Row,
  TableHelpers,
  DataUpdater,
  ColumnDef,
  HeaderGroup,
} from '@tanstack/react-table';
import styled, { css } from 'styled-components';
import TextWithTooltip from './TextWithTooltip';
import { NOOP } from 'config/constants';
import { TableOverlay } from './TableOverlay';

export type TableRowProps<D, RP, TP> = {
  row: Row<D, RP, TP>,
  role: string,
  'aria-label': string,
  highlightRow: boolean,
  tableHelpers: TableHelpers<D, RP, TP>,
  minTableWidth: number,
  css?: string,
  ...RP,
};

export type RenderRowProps<D, RP, TP> = {
  row: Row<D, RP, TP>,
  bodyRowProps?: $Partial<TableRowProps<D, RP, TP>>,
  cellProps?: mixed,
};

export const DefaultRow = <D, RP, TP>({
  row,
  highlightRow,
  minTableWidth,
  ...props
}: TableRowProps<D, RP, TP>): React$Node => (
  <div
    css={`
      display: flex;
      align-items: center;
      ${highlightRow
        ? `
        &:hover,
        &:focus {
          opacity: 1;
          background-color: ${Colors.gray3};
        }
      `
        : ''}
      min-width: ${minTableWidth}px;
      display: flex;
      flex: 1 0 auto;
      ${props.css ?? ''}
    `}
    role="row"
    {...props}
    selected={row?.getIsSelected()}
  />
);

export type TableBodyProps<D, RP, TP> = {
  hasData: boolean,
  hasNextPage: boolean,
  loading: boolean,
  renderRow: (RenderRowProps<D, RP, TP>) => React$Node,
  rootRef: (node: Element | null) => void,
  rows: Array<Row<D, RP, TP>>,
  sentryRef: React$Ref<React$ElementType>,
  cellProps?: mixed,
  onScroll?: () => void,
  dataTestId?: string,
  className?: string,
};

export const DefaultBody = <D, RP, TP>({
  hasData,
  hasNextPage,
  loading,
  renderRow,
  rootRef,
  rows,
  cellProps = {},
  sentryRef,
  onScroll,
  dataTestId,
}: TableBodyProps<D, RP, TP>): React$Node => (
  <div
    className="table-body"
    ref={rootRef}
    onScroll={onScroll}
    css="flex: 1; overflow: auto; position: relative;"
    data-testid={dataTestId}
  >
    {/* To confine the TableLoading overlay to the table body and prevent
            flashing over static parents, place it here as a child and set
            `relative` position on the table body. */}
    <TableLoading loading={loading} hasData={hasData} />
    {rows.map((row) => renderRow({ row, cellProps }))}
    {hasNextPage && (
      <div key="loading" data-testid="loading">
        Loading...
      </div>
    )}
    {/* Avoid the sentry prematurely entering the root while the table is still loading.
     * Intersection Observer only fires on changes in intersection threshold, so if it is
     * still within the root + margin, loadMore won't fire.
     * This system is still not perfect; see RP-2176
     */}
    {hasNextPage && !loading && <div ref={sentryRef} />}
  </div>
);

const DEFAULT_COLUMN_PROPS_FOR_ALL_TABLES = {
  minSize: 0,
};

const NO_DEFAULT_COLUMN_PROPS_FOR_THIS_TABLE = {};
const NO_TOOLTIPS = [];

type TableProps<D, RP, TP> = {
  columns: $ReadOnlyArray<Column<D, RP, TP>>,
  data: $ReadOnlyArray<D>,
  className?: string,
  Row?: (TableRowProps<D, RP, TP>) => React$Node,
  Body?: (TableBodyProps<D, RP, TP>) => React$Node,
  highlightRow?: boolean,
  showHeaderSeparators?: boolean,
  onLoadMore?: () => mixed | null,
  hasNextPage?: boolean,
  disabled?: boolean,
  disabledMessage?: string,
  colsWithTooltip?: Array<number>,
  loading?: boolean,
  dataUpdater?: DataUpdater<D, RP, TP>,
  tableRef?: { current: TableHelpers<D, RP, TP> | null },
  css?: string,
  rowProps?: RP,
  'data-testid'?: string,
  defaultColumn?: ColumnDef<D, RP, TP>,
  cellProps?: mixed,
  ...TP,
};

/**
 * WARNING- make sure EVERYTHING going into the table is properly memoized.
 * That includes default properties/empty objects/empty arrays.
 * Failure to do so may re-memoize Body/Row definitions and cause internal
 * cells to lose their identity (breaking focused input cells, for example.)
 */
const Table = <D, RP: { isSelected: boolean }, TP>({
  columns,
  data,
  className,
  Row = DefaultRow,
  Body = DefaultBody,
  highlightRow = false,
  showHeaderSeparators = false,
  onLoadMore = NOOP,
  hasNextPage = false,
  disabled = false,
  disabledMessage = '',
  loading = false,
  colsWithTooltip = NO_TOOLTIPS,
  // Custom function that we supplied to our table instance,
  // so we can call it from our cell renderer (helps prevent editable table fields from losing inputFocus from rerendering)
  // https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/editable-data
  dataUpdater = NOOP,
  // $FlowFixMe[incompatible-type]
  rowProps = {},
  'data-testid': dataTestId,
  tableRef = { current: null },
  css,
  cellProps,
  defaultColumn = NO_DEFAULT_COLUMN_PROPS_FOR_THIS_TABLE,
  ...reactTableProps
}: TableProps<D, RP, TP>): React$Node => {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    onLoadMore,
    loading,
    hasNextPage,
    rootMargin: '0px 0px 600px 0px',
  });

  const defaultColumnWithExtraDefaults = useMemo(
    () => ({ ...DEFAULT_COLUMN_PROPS_FOR_ALL_TABLES, defaultColumn }),
    [defaultColumn]
  );

  // Use the state and functions returned from useReactTable to build your UI
  const tableHelpers = useReactTable({
    columns,
    data,
    dataUpdater,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    defaultColumn: defaultColumnWithExtraDefaults,
    ...reactTableProps,
  });

  const { getHeaderGroups, getRowModel } = tableHelpers;

  const headerGroups = getHeaderGroups();

  const minTableWidth = getMinTableWidth(headerGroups);

  const rows = getRowModel().rows;

  const scrollableRootRef = useRef<Element | null>(null);
  const lastScrollDistanceToTopRef = useRef<number | void>();

  const rootRefSetter = useCallback(
    (node: Element | null) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  // capture the current scrollTop position within the table list
  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      lastScrollDistanceToTopRef.current = rootNode.scrollTop;
    }
  }, []);

  // keep the scroll position when new items are added
  useLayoutEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    if (scrollableRoot) {
      scrollableRoot.scrollTop = lastScrollDistanceToTopRef.current ?? 0;
    }
  }, [rows, rootRef]);

  useEffect(() => {
    tableRef.current = tableHelpers;
  }, [tableHelpers, tableRef]);

  const renderRow = useCallback(
    ({ row, bodyRowProps, cellProps = {} }: RenderRowProps<D, RP, TP>) => {
      const cells = row.getAllCells();
      return (
        <Row
          isSelected={row.getIsSelected()}
          key={row.id}
          row={row}
          role="row"
          aria-label={'row-' + row.index}
          highlightRow={highlightRow && !row.getIsSelected()}
          tableHelpers={tableHelpers}
          minTableWidth={minTableWidth}
          {...rowProps}
          {...bodyRowProps}
          className={`table-row${row.getIsSelected() ? '-selected' : ''}`}
        >
          {cells.map((cell, index) => {
            const withTooltip = colsWithTooltip.includes(index);
            const size = cell.column.getSize();
            return withTooltip ? (
              <TextWithTooltip
                customEllipsisCss={{
                  flex: `${size} 0 auto`,
                  minWidth: `${cell.column.columnDef.minSize ?? 0}px`,
                  width: `${size}px`,
                  boxSizing: `border-box`,
                }}
                // $FlowIgnore[incompatible-type]
                name={flexRender(cell.column.columnDef.cell, {
                  ...cell.getContext(),
                  ...cellProps,
                })}
              />
            ) : (
              <Ellipsis
                key={cell.id}
                css={`
                  flex: ${size} 0 auto;
                  ${cell.column.columnDef.minSize != null
                    ? `min-width: ${cell.column.columnDef.minSize ?? 0}px;`
                    : ''}
                  width: ${size}px;
                  box-sizing: border-box;
                `}
                role="cell"
              >
                {flexRender(cell.column.columnDef.cell, { ...cell.getContext(), ...cellProps })}
              </Ellipsis>
            );
          })}
        </Row>
      );
    },
    [highlightRow, tableHelpers, rowProps, minTableWidth, colsWithTooltip]
  );

  // Render the UI for your table
  return (
    <div
      role="table"
      css={`
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        ${css ?? ''}
      `}
      className={className}
      data-testid={dataTestId}
    >
      {disabled && <TableOverlay message={disabledMessage} />}
      <div role="rowgroup" className="table-header">
        {headerGroups.map((headerGroup) => (
          <div
            key={headerGroup.id}
            role="row"
            css={`
              min-width: ${minTableWidth}px;
              display: flex;
              flex: 1 0 auto;
            `}
          >
            {headerGroup.headers.map((header, index) => {
              const size = header.getSize();
              return (
                <Fragment key={header.id}>
                  <div
                    css={`
                      font-weight: 500;
                      padding: 5px;
                      color: ${Colors.gray8};
                      box-sizing: border-box;
                      flex: ${size} 0 auto;
                      min-width: ${header.column.columnDef.minSize}px;
                      width: ${size}px;
                    `}
                    role="columnheader"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                  {showHeaderSeparators && index < headerGroup.headers.length - 1 && (
                    <div
                      css={`
                        position: relative;
                        width: 1px;
                        height: 18px;
                        top: 6px;
                        right: 8px;
                        background-color: ${Colors.gray10};
                      `}
                    ></div>
                  )}
                </Fragment>
              );
            })}
          </div>
        ))}
      </div>
      <Body
        className="table-body"
        hasData={data.length > 0}
        hasNextPage={hasNextPage}
        loading={loading}
        renderRow={renderRow}
        rootRef={rootRefSetter}
        onScroll={handleRootScroll}
        rows={rows}
        cellProps={cellProps}
        sentryRef={sentryRef}
        dataTestId={`${dataTestId ?? 'table'}-body`}
      />
    </div>
  );
};

type SelectableRowProps = TableRowProps<mixed, {}, { toggleAllRowsSelected: (boolean) => void }>;

const StyledRow = styled(DefaultRow).attrs((props: SelectableRowProps) => ({
  ...props,
  onClick: props.row.getToggleSelectedHandler(),
  'aria-selected': props.row.getIsSelected() ? 'true' : 'false',
  'min-width': `${props.minTableWidth}px`,
  display: 'flex',
  flex: '1 0 auto',
}));

export const SelectableRow: () => // $FlowFixMe[incompatible-type] a bit too much to figure out
React$Node = StyledRow`
  background-color: ${(props: SelectableRowProps) =>
    props.row.getIsSelected() ? Colors.gray5 : 'transparent'};
  cursor: pointer;
  ${(props: SelectableRowProps) =>
    props.highlightRow &&
    !props.row.getIsSelected() &&
    css`
      &:hover,
      &:focus {
        opacity: 1;
        background-color: ${Colors.gray3};
      }
    `}
`;

export function getMinTableWidth(headerGroups: Array<HeaderGroup<mixed, mixed, mixed>>): number {
  const deepestHeaderLevel = headerGroups.length - 1;
  return headerGroups[deepestHeaderLevel].headers.reduce(
    (groupWidth, header) => groupWidth + (header.column.columnDef.minSize ?? 0),
    0
  );
}

export default Table;
