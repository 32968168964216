// @flow
import React, { Suspense, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import ErrorBoundary from 'common/ErrorBoundary';
import { ThemeProviders } from './withRoot';
import { useMousetrap } from './utils/mousetraps';
import Mousetrap from 'mousetrap';
import { Colors } from 'styles';
import { FN_KEYS } from 'config/constants';
import { RecoilRoot } from 'recoil';
import useAnalytics from 'hooks/useAnalytics';
import { useUpscope } from 'hooks/useUpscope';
import { useCrossTabNotifier } from 'hooks/useCrossTabNotifier';
import { ToasterProvider } from 'common/ui/Toaster';
import { CompactUIProvider, useCompactUI } from 'hooks/useCompactUI';
import { AppRoutes } from 'common/Layouts/Routing/Routing';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { useBaseViewerData } from './domains/viewer/Viewer/StudyLoader/useStudies';
import analytics from 'modules/analytics';
import { useIsAuthenticated } from './hooks/useAuth';
import { useViewerSettingsSync } from './hooks/useViewerSettingsSync';
import { useGetCurrentUserFeatureFlags } from 'modules/feature-flags';
import { DatadogSharedContextApplier } from './DatadogSharedContextApplier';
import { useExtensionState } from './hooks/useExtensionState';
import { InactivityTracking } from 'common/InactivityTracking';
// import { useClearStoredDataOnClose } from './hooks/useClearStoredDataOnClose';

function SessionReplayRecorder() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/reporter') || location.pathname.includes('/worklist')) {
      analytics.startSessionReplayRecording();
    }
    if (location.pathname.includes('/viewer')) {
      analytics.stopSessionReplayRecording();
    }
  }, [location]);

  return null;
}

const GlobalStyle = createGlobalStyle`
  body {
    background: ${Colors.gray1};
  }
`;

const App = () => {
  useAnalytics();
  useMousetrap();
  useUpscope();
  useCrossTabNotifier();
  useViewerSettingsSync();
  useGetCurrentUserFeatureFlags();
  useExtensionState();
  // clears all cached data on browser close
  // disabled for now
  // useClearStoredDataOnClose();
  const { resizeListener } = useCompactUI();

  useEffect(() => {
    Mousetrap.addKeycodes(FN_KEYS);
  }, []);

  return (
    <div css="width: 100vw; height: 100%; display: flex;">
      {resizeListener}
      <GlobalStyle />
      <AppRoutes />
      <SessionReplayRecorder />
      <DatadogSharedContextApplier />
    </div>
  );
};

// We can put in this component any hook that we want to run as soon as possible
// while the app is loading. This is useful for caching data that we know we will
// need later on.
const EarlyCacheQueries = () => {
  useBaseViewerData();
  return null;
};

type AuthorizedWrapperProps = { children: React$Node };

const AuthorizedWrapper = ({ children }: AuthorizedWrapperProps) => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? children : null;
};

const DecoratedApp = (): React$Node => (
  // with BrowserRouter we can depend on useLocation updating AFTER window.location
  // this is very important for useOpenTabs and all of the data that depends on it
  // namely current case and current worklist id
  // previous issue: https://sironamedical.atlassian.net/browse/EN-5488
  // make sure to test choosing new cases from the worklist when updating router code
  <BrowserRouter>
    <ThemeProviders>
      <RecoilRoot>
        <ToasterProvider>
          <AuthorizedWrapper>
            <Suspense>
              <EarlyCacheQueries />
            </Suspense>
            <Suspense>
              <InactivityTracking />
            </Suspense>
          </AuthorizedWrapper>
          <ErrorBoundary name="root">
            <CompactUIProvider>
              <App />
            </CompactUIProvider>
          </ErrorBoundary>
        </ToasterProvider>
      </RecoilRoot>
    </ThemeProviders>
  </BrowserRouter>
);

export default DecoratedApp;
