// @flow

import { useCallback, useMemo } from 'react';
import type { ComponentType } from 'react';
import { useSlate } from 'slate-react';
import IconButton from '@material-ui/core/IconButton';
import { Colors } from 'styles';
import { CLEAR_FORMATTING_PLUGIN_ID } from '../plugins/clearFormatting/types';
import type { ClearFormattingPluginID } from '../plugins/clearFormatting/types';
import type { MarkPluginID } from '../plugins';
import {
  isMarkActive,
  toggleMark,
  clearMarksForSelection,
  isButtonDisabled,
  isHeadingButtonActive,
} from '../utils';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';
import type { HeadingPluginID } from '../plugins/heading/types';
import type { HoveringToolbarExtraProps } from '../types/plugin';
import { getHeadingIcon } from '../plugins/heading/utils/styling';
import { HeadingLevel } from '../plugins/heading/constants';
import { toggleHeading } from '../plugins/heading/utils/normalization';
import Tooltip from '@material-ui/core/Tooltip';
import { AUTOCORRECT_PLUGIN_ID } from '../plugins/autoCorrect/types';
import type { AutoCorrectPluginID } from '../plugins/autoCorrect/types';
import { useSetRecoilState } from 'recoil';
import { autoCorrectModalState } from '../../Reporter/state';
import { Editor } from '../core';

const ButtonContainer = (props: {
  children: React$Node,
  isDisabled: boolean,
  isDisabledReason: ?string,
}): React$Node => {
  return props.isDisabled ? (
    <Tooltip title={props.isDisabledReason}>{props.children}</Tooltip>
  ) : (
    <>{props.children}</>
  );
};

export type MarkButtonProps = $ReadOnly<{
  pluginID: MarkPluginID | ClearFormattingPluginID | HeadingPluginID | AutoCorrectPluginID,
  icon: ComponentType<{ ... }>,
  pluginName: string,
  options?: HoveringToolbarExtraProps,
}>;

export const MarkButton = ({
  pluginID,
  icon: Icon,
  pluginName,
  options,
}: MarkButtonProps): React$Node => {
  const editor = useSlate();
  const setAutoCorrectModalOptions = useSetRecoilState(autoCorrectModalState);

  const handleMark = useCallback(
    (evt: empty) => {
      evt.preventDefault();
      if (pluginID === CLEAR_FORMATTING_PLUGIN_ID) {
        clearMarksForSelection(editor);
      } else if (pluginID === HEADING_PLUGIN_ID && options?.level != null) {
        toggleHeading(editor, pluginID, options.level);
        // When a user changes a bit of text to a heading, any marks on that text should be cleared.
        clearMarksForSelection(editor);
      } else if (pluginID === AUTOCORRECT_PLUGIN_ID && editor.selection != null) {
        const selectionText = Editor.string(editor, editor.selection);
        setAutoCorrectModalOptions({ open: true, selectionText });
      } else {
        toggleMark(editor, pluginID);
      }
    },
    [editor, options, pluginID, setAutoCorrectModalOptions]
  );
  const isActive =
    isMarkActive(editor, pluginID) || isHeadingButtonActive(editor, pluginID, options?.level);

  const [isDisabled, isDisabledReason] = isButtonDisabled(editor, pluginID);
  const style = useMemo(
    () => ({
      color: isActive ? Colors.mainBlue : 'inherit',
    }),
    [isActive]
  );

  const ToolbarIcon =
    pluginID === HEADING_PLUGIN_ID
      ? getHeadingIcon({
          level: options?.level ?? HeadingLevel.H1,
          isActive,
          isDisabled,
        })
      : Icon;

  return (
    <ButtonContainer isDisabled={isDisabled} isDisabledReason={isDisabledReason}>
      <span>
        <IconButton
          disabled={isDisabled}
          size="small"
          aria-label={pluginName}
          onMouseDown={handleMark}
        >
          <ToolbarIcon fontSize="small" style={style} />
        </IconButton>
      </span>
    </ButtonContainer>
  );
};
