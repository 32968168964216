// @flow
import { atom, DefaultValue, selectorFamily } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localForagePersisterEffect,
} from 'utils/recoilEffects';
import type { HangingProtocol } from './useHangingProtocol';
import { omit } from 'ramda';
import { env } from 'config/env';

export const previousHangingProtocolState: RecoilState<?HangingProtocol> = atom({
  key: 'previousHangingProtocolState',
  default: null,
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
    }),
  ],
});

export const previewHangingProtocolState: RecoilState<{ [key: string]: ?HangingProtocol }> = atom({
  key: 'previewHangingProtocolState',
  default: {},
  effects: [
    broadcastChannelSynchronizerEffect(),
    localForagePersisterEffect({
      version: 1,
      __LEGACY_MIGRATION_DO_NOT_USE__disabled: env.NODE_ENV === 'test',
    }),
  ],
});

export const previewHangingProtocolSelector: (?string) => RecoilState<?HangingProtocol> =
  selectorFamily<?HangingProtocol, ?string>({
    key: 'previewHangingProtocolSelector',
    get:
      (caseSmid: ?string) =>
      ({ get }) => {
        const state = get(previewHangingProtocolState);
        return state != null && caseSmid != null ? state[caseSmid] : null;
      },
    set:
      (caseSmid: ?string) =>
      ({ set, reset }, hangingProtocol) => {
        if (caseSmid == null) return;

        if (hangingProtocol instanceof DefaultValue && caseSmid != null) {
          set(previewHangingProtocolState, (state) => omit([caseSmid], state));
          return;
        }

        set(previewHangingProtocolState, (state) => ({
          ...state,
          [caseSmid]: hangingProtocol,
        }));
      },
  });

export const isPreviewingHangingProtocolState: RecoilState<boolean> = atom({
  key: 'isPreviewingHangingProtocolState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});
