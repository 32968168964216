// @flow
import assignIn from 'lodash/assignIn';

export const acronym = (string: ?string): string | null => {
  if (string == null) {
    return '';
  }

  const matches = string.match(/\b(\w)/g);
  return matches ? matches.join('') : null;
};

// $FlowIgnore[unclear-type]
export const copyGetter = <T: Object>(obj: T): T => {
  return assignIn({}, obj);
};
