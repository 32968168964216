// @flow

import { HEADING_PLUGIN_ID } from './types';
import type { HeadingPluginOptions, HeadingPluginPropertyOptions } from './types';

import type { EditorPlugin } from '../../types';
import { HeadingEditable } from './components';
import { getRenderElement } from '../../utils/getRenderElement';
import { MarkButton } from '../../components/MarkButton';
import { ReactComponent as HeaderOneIcon } from 'assets/H1.svg';
import { onKeyDownHeading } from './onKeyDownHeading';
import { enhanceEditorStateHeading } from './enhanceEditorStateHeading';
import { getHeadingIcon } from './utils/styling';
import { HeadingLevel } from './constants';
import { DEFAULT_HEADING_KEYWORDS } from 'hooks/useHeadingKeywords';

export const DEFAULT_H1_HOTKEY = 'ctrl+alt+1';

export const DEFAULT_H2_HOTKEY = 'ctrl+alt+2';

export const HeadingPlugin = (
  {
    component = HeadingEditable,
    pluginName = 'Heading',
    icon = HeaderOneIcon,
    headingKeywords = DEFAULT_HEADING_KEYWORDS,
    isInsertingMacro = false,
    ...rest
  }: HeadingPluginOptions = {
    ...null,
  }
): EditorPlugin<> => {
  const options: HeadingPluginPropertyOptions = {
    pluginID: HEADING_PLUGIN_ID,
    pluginName,
    component,
    headingKeywords,
    isInsertingMacro,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement(options, { spreadAttributes: false }),
    onKeyDown: onKeyDownHeading(options),
    enhanceEditorState: enhanceEditorStateHeading(options),
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={getHeadingIcon({
              level: HeadingLevel.H1,
              isActive: false,
              isDisabled: false,
            })}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
            options={{ level: HeadingLevel.H1 }}
          />
        ),
        options: { level: HeadingLevel.H1 },
      },
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={getHeadingIcon({
              level: HeadingLevel.H2,
              isActive: false,
              isDisabled: false,
            })}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
            options={{ level: HeadingLevel.H2 }}
          />
        ),
        options: { level: HeadingLevel.H2 },
      },
    ],
  };
};
