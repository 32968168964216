// @flow
import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import {
  localStoragePersisterEffect,
  broadcastChannelSynchronizerEffect,
} from 'utils/recoilEffects';
import type { Layout } from 'config/constants';
import { MAX_VIEWER_WINDOWS } from 'config/constants';

/*
 * Layout is structured as [<# of rows>, <# of columns>] tuple
 */
export type { Layout };

export type Layouts = {
  [windowId: string]: Layout,
};

export type AllLayouts = {
  [caseSmid: string]: Layouts,
};

export const DEFAULT_LAYOUT: Layout = [2, 2];
export const DEFAULT_LAYOUTS: Layouts = Object.fromEntries(
  Array.from({ length: MAX_VIEWER_WINDOWS }, (_, i) => [String(i), DEFAULT_LAYOUT])
);

export const layoutsForAllCasesState: RecoilState<AllLayouts> = atom({
  key: 'layoutsForAllCasesState',
  default: {},
  effects: [
    broadcastChannelSynchronizerEffect(),
    localStoragePersisterEffect({
      version: 2,
    }),
  ],
});
