// @flow
import type { StudyGroup } from '../ViewportsConfigurations/types';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import { useStudies, useBaseViewerData } from 'domains/viewer/Viewer/StudyLoader/useStudies';
import { usePreviewHangingProtocolConfiguration } from './usePreviewHangingProtocol';
import type { GetHangingProtocolsQuery, ViewType } from 'generated/graphql';
import {
  hangingProtocolState,
  studyToSlimStudy,
  seriesToSlimSeries,
} from 'domains/viewer/ViewportsConfigurations';
import type { ViewportsConfigurations } from 'domains/viewer/ViewportsConfigurations';
import type { Layouts } from '../Viewer/StudyLoader/viewerLoaderState';
import { hangingProtocolLayoutsState } from '../ViewportsConfigurations/state';
import { ViewTypeValues } from 'generated/graphql';
import { logger } from 'modules/logger';

export type HangingProtocol = GetHangingProtocolsQuery['hangingProtocols']['items'][0];

type UseHangingProtocol = {
  loadingHangingProtocol: boolean,
  hangingProtocol: ?HangingProtocol,
  hangingProtocolViewportsConfigurations: ?ViewportsConfigurations,
  hangingProtocolLayouts: ?Layouts,
};

const useMatchedHangingProtocol = ({
  groupedStudies,
  loading,
  studyDescriptions,
  cases,
}: {
  cases: Array<Array<string>>,
  groupedStudies: Array<StudyGroup>,
  loading: boolean,
  studyDescriptions: Array<string>,
}) => {
  const { hangingProtocol, loading: loadingHangingProtocol } = useBaseViewerData();
  return [hangingProtocol, loadingHangingProtocol];
};

export const useHangingProtocol = (): UseHangingProtocol => {
  const previewedHangingProtocol = usePreviewHangingProtocolConfiguration();
  const { loading, groupedStudies } = useStudies();

  const studyDescriptions: string[] = useMemo(
    () =>
      groupedStudies.flatMap((studies) =>
        studies.reduce<string[]>(
          (studies, { description }) => (description != null ? [...studies, description] : studies),
          []
        )
      ),
    [groupedStudies]
  );
  const cases = useMemo(() => {
    return groupedStudies.map((group) => group.map((study) => study.smid));
  }, [groupedStudies]);

  const [matchedHangingProtocol, matchedHangingProtocolLoading] = useMatchedHangingProtocol({
    groupedStudies,
    loading,
    studyDescriptions,
    cases,
  });

  const hangingProtocol = useMemo(
    () => previewedHangingProtocol || matchedHangingProtocol,
    [matchedHangingProtocol, previewedHangingProtocol]
  );

  const validateViewType = (viewType: ?ViewType): ViewType => {
    if (!Object.values(ViewTypeValues).includes(viewType)) {
      logger.debug(`Invalid ViewType from Hanging Protocol viewType: ${viewType ?? 'null'}`);
    }
    return viewType ?? ViewTypeValues.TwoDDre;
  };

  const hangingProtocolViewportsConfigurations: ?ViewportsConfigurations = useMemo(
    () =>
      hangingProtocol?.viewportsConfigurations?.reduce(
        (acc, { id, configuration: { study, series, ...configuration } }) => {
          return {
            ...acc,
            [id]: {
              ...configuration,
              study: study != null ? studyToSlimStudy(study) : undefined,
              series: series != null ? seriesToSlimSeries(series) : undefined,
              viewType: validateViewType(configuration.viewType),
            },
          };
        },
        { ...null }
      ),
    [hangingProtocol?.viewportsConfigurations]
  );

  const setHangingProtocol = useSetRecoilState(hangingProtocolState);
  useEffect(() => {
    setHangingProtocol(hangingProtocolViewportsConfigurations);
  }, [setHangingProtocol, hangingProtocolViewportsConfigurations]);

  const hangingProtocolLayouts: ?Layouts = useMemo(() => {
    if (hangingProtocol == null) return;

    const layouts = Object.fromEntries(
      hangingProtocol.data.layouts.map(({ id, layout }) => [
        id,
        [layout[0], layout[1]], // converting the GraphQL array to a tuple
      ])
    );

    return layouts;
  }, [hangingProtocol]);

  const setHangingProtocolLayouts = useSetRecoilState(hangingProtocolLayoutsState);
  useEffect(() => {
    setHangingProtocolLayouts(hangingProtocolLayouts);
  }, [setHangingProtocolLayouts, hangingProtocolLayouts]);

  return {
    hangingProtocol,
    loadingHangingProtocol: matchedHangingProtocolLoading,
    hangingProtocolViewportsConfigurations,
    hangingProtocolLayouts,
  };
};
