// @flow

import { PICKLIST_PLUGIN_ID } from './plugins/picklist/types';
import { INLINE_BOOKMARK_PLUGIN_ID } from './plugins/inlineBookmark/types';
import { PLACEHOLDER_PLUGIN_ID } from './plugins/placeholder/types';
import type { ElementPluginID } from './plugins/types';

export const NEWLINE = '\n';
export const BRACKET_TYPES = [PICKLIST_PLUGIN_ID, INLINE_BOOKMARK_PLUGIN_ID, PLACEHOLDER_PLUGIN_ID];
export const SQUARE_BRACKET_TYPES = [PICKLIST_PLUGIN_ID, INLINE_BOOKMARK_PLUGIN_ID];

export const getNavigationNodeTypes = (
  ignoreMergeFieldsInNavigation?: boolean
): Array<ElementPluginID> => {
  return ignoreMergeFieldsInNavigation === true ? SQUARE_BRACKET_TYPES : BRACKET_TYPES;
};

export const isBracketType = (type: ElementPluginID): boolean => BRACKET_TYPES.includes(type);

export const isSquareBracketType = (type: ?string): boolean =>
  type != null && SQUARE_BRACKET_TYPES.includes(type);
