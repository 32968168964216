// @flow

import { createContext, useContext, useRef, useEffect, useState, useCallback } from 'react';
import { useEditor, useSelected } from '../core';
import type { NodeType, EditorType } from '../core';
import { find } from '../utils';
import type { AllPluginID } from '../plugins';

type StateAndUpdater<T> = [T, (((T) => T) | T) => void];

export type NodeState = WeakMap<NodeType, StateAndUpdater<$FlowFixMe>>;

export type NodeStateContextProps = NodeState;

const NodeStateContext = createContext<?NodeStateContextProps>(undefined);

export const NodeStateProvider = ({ children }: { children: React$Node }): React$Node => {
  const nodeStateRef = useRef(new WeakMap());

  return (
    <NodeStateContext.Provider value={nodeStateRef.current}>{children}</NodeStateContext.Provider>
  );
};

/**
 * Hook to provide you the node state WeakMap reference.
 */
export const useNodeStateRef = (): NodeStateContextProps => {
  const nodeStateContext = useContext(NodeStateContext);

  if (!nodeStateContext) {
    throw new Error('useNodeStateRef must be used within a NodeStateProvider.');
  }

  return nodeStateContext;
};

/**
 * Registers a new Recoil atom to a given node with default state. Returns a state and updater
 * tuple. Under the hood it registers the atom to the nodeState WeakMap using the node passed in.
 * Resets the atom and WeakMap when the associated React element is deselected or unmounts from the DOM.
 *
 * NOTE: If you need to persist the state permanently you'll need to use IDs and write a Slate plugin in the editor
 * to handle the IDs on split_nodes (what happens on pressing enter) in editor.apply. Only do that if you really
 * need it because you could break collaboration support by relying on it.
 *
 * More info here:
 * https://slate-js.slack.com/archives/C1RH7AXSS/p1606755104177300
 */
export const useRegisterNodeSelectedState = <T>(
  defaultState: T,
  node?: NodeType
): StateAndUpdater<T> => {
  // Always use the initial default that the hook was registered with to get close to
  // how useState performs by default.
  const defaultStateRef = useRef(defaultState);
  const nodeState = useNodeStateRef();
  const stateAndUpdater = useState<T>(() => defaultStateRef.current);
  const selected = useSelected();

  const resetState = useCallback(() => {
    const [, setState] = stateAndUpdater;
    setState(defaultStateRef.current);
    if (node != null) {
      nodeState.delete(node);
    }
  }, [stateAndUpdater, nodeState, defaultStateRef, node]);

  useEffect(() => {
    // Only set the weakmap if the node is selected. It's possible that you could get cross-talk
    // between nodes if you don't screen them by selected first.
    if (!selected) return;

    // If the cursor moves outside of an element that uses this hook it will be undefined
    // until the node is active again in the editor selection. In that case we clear the state of the given
    // node so that if it activates again in the current selection the state will be reset to the default
    // value.
    //
    // It is handled inside of this effect (instead of outside of this function) because we cannot call
    // hooks conditionally.
    if (!node) {
      resetState();
      return;
    }

    nodeState.set(node, stateAndUpdater);
  }, [node, stateAndUpdater, nodeState, resetState, selected]);

  // We only want to cleanup when the associated React component unmounts from the DOM
  // This should happen automatically, just making sure.
  useEffect(() => {
    return () => resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return stateAndUpdater;
};

/**
 * Given the current selection of the editor, associate a Recoil atom to a node of a given type.
 *
 * TODO: Make sure this starts at the lowest level for recursive nodes.
 */
export const useRegisterNodeSelectedStateType = <T>(
  defaultState: T,
  type: string
): StateAndUpdater<T> => {
  const editor = useEditor();
  const nodeEntry = find(editor, (n: NodeType) => n.type === type);

  return useRegisterNodeSelectedState<T>(defaultState, nodeEntry?.[0]);
};

/**
 * A helper function that returns the associated Recoil atom with the given node.
 */
export const getNodeSelectedStateByNode = <T>({
  nodeState,
  node,
}: {
  nodeState: NodeState,
  node: NodeType,
}): ?StateAndUpdater<T> => {
  return nodeState.get(node);
};

/**
 * A helper function that returns the associated Recoil atom with the given node.
 */
export const getNodeSelectedStateByNodeType = <T>({
  nodeState,
  pluginID,
  editor,
}: {
  nodeState: NodeState,
  pluginID: AllPluginID,
  editor: EditorType,
}): ?StateAndUpdater<T> => {
  const nodeEntry = find(editor, (n: NodeType) => n.type === pluginID);

  if (!nodeEntry) return null;

  return nodeState.get(nodeEntry[0]);
};

/**
 * Returns the associated Recoil atom with the given node.
 */
export const useNodeSelectedState = <T>(node: NodeType): ?StateAndUpdater<T> => {
  const nodeState = useNodeStateRef();

  return nodeState.get(node);
};
