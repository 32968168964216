// @flow

export const LIST_VARIANTS = {
  ol: 'ol',
  ul: 'ul',
  li: 'li',
};

export type ListVariant = $Keys<typeof LIST_VARIANTS>;

export const LIST_WRAPPER = {
  '*': LIST_VARIANTS.ul,
  '-': LIST_VARIANTS.ul,
  '1.': LIST_VARIANTS.ol,
};

export const LIST_KEYBOARD_SHORTCUTS = {
  dash: '-',
  bullet: '*',
  number: '1.',
};

export type ListKeyboardShortcut = $Keys<typeof LIST_KEYBOARD_SHORTCUTS>;

export const INCREASE_INDENT_HOTKEY_WINDOWS = 'ctrl+]';
export const DECREASE_INDENT_HOTKEY_WINDOWS = 'ctrl+[';
export const BULLETED_LIST_HOTKEY_WINDOWS = 'ctrl+8';
export const NUMBERED_LIST_HOTKEY_WINDOWS = 'ctrl+7';

export const INCREASE_INDENT_HOTKEY_MAC = 'cmd+]';
export const DECREASE_INDENT_HOTKEY_MAC = 'cmd+[';
export const BULLETED_LIST_HOTKEY_MAC = 'cmd+shift+8';
export const NUMBERED_LIST_HOTKEY_MAC = 'cmd+shift+7';

export const LIST_TOOLBAR_MODE_OPTIONS = {
  stacked: 'stacked',
  expanded: 'expanded',
};

export type ListToolbarMode = $Keys<typeof LIST_TOOLBAR_MODE_OPTIONS>;

export type ListToolbarModes = {|
  reporter: ListToolbarMode,
  macro: ListToolbarMode,
  template: ListToolbarMode,
|};

export const LIST_TOOLBAR_MODES: ListToolbarModes = {
  reporter: LIST_TOOLBAR_MODE_OPTIONS.stacked,
  macro: LIST_TOOLBAR_MODE_OPTIONS.stacked,
  template: LIST_TOOLBAR_MODE_OPTIONS.stacked,
};
