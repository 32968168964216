// @flow

import type { Scalars } from 'generated/graphql';
import { WorklistColumnIdValues } from 'generated/graphql';

export const WorklistGlobalFilters = Object.freeze({
  PatientSearch: 'patientSearch',
  Views: 'views',
});

export type WorklistGlobalFiltersType = $Values<typeof WorklistGlobalFilters>;

export const WorklistFilters = Object.freeze({
  PatientSearch: WorklistGlobalFilters.PatientSearch,
  Views: WorklistGlobalFilters.Views,
  PatientSex: WorklistColumnIdValues.PatientSex,
  StudyDate: WorklistColumnIdValues.StudyDate,
  PatientDob: WorklistColumnIdValues.PatientDob,
  Modality: WorklistColumnIdValues.Modality,
  HasDraftReport: WorklistColumnIdValues.HasDraftReport,
  CustomStatus: WorklistColumnIdValues.CustomStatus,
  SubmittedAt: WorklistColumnIdValues.SubmittedAt,
  AccessionNumber: WorklistColumnIdValues.AccessionNumber,
  Mrn: WorklistColumnIdValues.Mrn,
  Status: WorklistColumnIdValues.Status,
  Age: WorklistColumnIdValues.Age,
  PatientType: WorklistColumnIdValues.PatientType,
  StudyDescription: WorklistColumnIdValues.StudyDescription,
  Site: WorklistColumnIdValues.Site,
  Radiologist: WorklistColumnIdValues.Radiologist,
  BodyPart: WorklistColumnIdValues.BodyPart,
  PatientName: WorklistColumnIdValues.PatientName,
  ReferringPhysician: WorklistColumnIdValues.ReferringPhysician,
  Priority: WorklistColumnIdValues.Priority,
});

export const WorklistFilterSourceName = {
  Modality: 'modality',
  PatientType: 'patient_type',
  PatientSex: 'patient_sex',
  StudyDescription: 'study_description',
  BodyPart: 'body_part',
  Radiologist: 'claimed_by',
  CustomStatus: 'custom_status',
  Status: 'status',
  Site: 'site',
  HasDraftReport: 'has_draft_report',
  PatientName: 'patient_last_name',
  ReferringPhysician: 'referring_physician',
  Priority: 'priority',
};

export type WorklistFilterSourceNameType = $Values<typeof WorklistFilterSourceName>;

export type WorklistFiltersType = $Values<typeof WorklistFilters>;

export const FilterVariant = Object.freeze({
  MultiSelect: 'multiselect',
  DateRange: 'daterange',
  Toggle: 'toggle',
  NumberRange: 'numberrange',
  Text: 'text',
});

export type FilterVariantType = $Values<typeof FilterVariant>;

export type BaseFilterType = {
  label: string,
  hidden?: boolean,
  isLoading?: boolean,
};

export type MultiSelectFilterItemType = {
  value: string,
  label: string,
  count?: number,
};

export type ToggleFilterType = {
  ...BaseFilterType,
  type: typeof FilterVariant.Toggle,
  defaultValue: boolean,
};

export type MultiSelectFilterType = {
  ...BaseFilterType,
  type: typeof FilterVariant.MultiSelect,
  isSearchable?: boolean,
  source: WorklistFilterSourceNameType,
  items?: MultiSelectFilterItemType[],
  isBoolean?: boolean, // Must only have 1 selected, if not, it's like no filter is added};
  isLoading?: boolean,
  formatItems?: (items: MultiSelectFilterItemType[]) => MultiSelectFilterItemType[],
};

export type DateRangeFilterType = {
  ...BaseFilterType,
  type: typeof FilterVariant.DateRange,
  minDate?: Date,
  maxDate?: Date,
};

export type NumberRangeFilterType = {
  ...BaseFilterType,
  type: typeof FilterVariant.NumberRange,
};

export type TextFilterType = {
  ...BaseFilterType,
  type: typeof FilterVariant.Text,
};

export type FilterType =
  | MultiSelectFilterType
  | DateRangeFilterType
  | ToggleFilterType
  | NumberRangeFilterType
  | TextFilterType;

export type FilterValueType = string[];

export type FilterStateType = { [key: WorklistFiltersType]: FilterValueType };

export type FilterDefinitionType = { [key: WorklistFiltersType]: FilterType };

export type ProcessedFilterValueType =
  | [?Date, ?Date]
  | ?boolean
  | $ReadOnlyArray<string>
  | ?string
  | [?number, ?number];

export type ProcessedDateFilterValueType = [?Date, ?Date];

export type ProcessedBooleanFilterValueType = ?boolean;

export type ProcessedMultiSelectFilterValueType = $ReadOnlyArray<string>;

export type ProcessedNumberRangeFilterValueType = [?number, ?number];

export type ProcessedTextFilterValueType = $ReadOnlyArray<Scalars['String']>;

export type ProcessedFilterStateType = {
  patientSex?: ?ProcessedMultiSelectFilterValueType,
  patientDob?: ?ProcessedDateFilterValueType,
  studyDate?: ?ProcessedDateFilterValueType,
  submittedAt?: ?ProcessedDateFilterValueType,
  modality?: ?ProcessedMultiSelectFilterValueType,
  views?: ?ProcessedMultiSelectFilterValueType,
  hasDraftReport?: ?ProcessedBooleanFilterValueType,
  customStatus?: ?ProcessedMultiSelectFilterValueType,
  patientSearch?: ?ProcessedBooleanFilterValueType,
  bodyPart?: ?ProcessedMultiSelectFilterValueType,
  mrn?: ?ProcessedTextFilterValueType,
  status?: ?ProcessedMultiSelectFilterValueType,
  age?: ?ProcessedNumberRangeFilterValueType,
  accessionNumber?: ?ProcessedTextFilterValueType,
  patientType?: ?ProcessedMultiSelectFilterValueType,
  studyDescription?: ?ProcessedMultiSelectFilterValueType,
  site?: ?ProcessedMultiSelectFilterValueType,
  patientName?: ?ProcessedTextFilterValueType,
  radiologist?: ?ProcessedMultiSelectFilterValueType,
  referringPhysician?: ?ProcessedMultiSelectFilterValueType,
  priority?: ?ProcessedMultiSelectFilterValueType,
};

export type WorklistSurfaceSearchValues = { [string]: string };

export type FilterStateBySurface = { [string]: FilterStateType };

export type NumberRangeTupleString = [?string, ?string];
export type NumberRangeTuple = [?number, ?number];
