// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const SECTION_HEADER_PLUGIN_ID: 'sectionHeader' = 'sectionHeader';
export type SectionHeaderPluginID = typeof SECTION_HEADER_PLUGIN_ID;

export type SectionHeaderPluginElement = $ReadOnly<{
  ...EditorElement,
  type: SectionHeaderPluginID,
  conceptID: string,
  viewerRefID: string,
}>;

export type SectionHeaderPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: any,
}>;

export type SectionHeaderPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...SectionHeaderPluginOptions,
  pluginID: SectionHeaderPluginID,
  pluginName: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: any,
}>;
