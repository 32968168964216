// @flow

import type {
  NodeType,
  EditorType,
  PathType,
  RangeType,
  NodeEntry,
  RangeRefType,
  LocationType,
  PointType,
} from '../../../core';
import type { HeadingLevel as GraphQLHeadingLevel, HeadingKeywords } from 'generated/graphql';

import { Editor, Element, Transforms, Node, Range, Text, Point, Path } from '../../../core';
import { HEADING_PLUGIN_ID } from '../types';
import { HeadingLevel } from '../constants';
import type { HeadingPluginElement } from '../types';
import type { THeadingLevel } from '../constants';

import {
  getEditorSelectionSafe,
  getRangeRefSafe,
  isMarkActive,
  getPathRefSafe,
} from '../../../utils';
import {
  createInlineParagraph,
  createParagraph,
  isInlineParagraph,
  isRegularParagraph,
  createParagraphWithChildren,
  createInlineParagraphWithChildren,
  isInlineNode,
  isEmptyParagraph,
} from '../../paragraph/utils';
import { PARAGRAPH_PLUGIN_ID } from '../../paragraph/types';
import { areMultipleNodesSelected } from '../../../utils/areMultipleNodesSelected';
import type { SlateContent } from '../../../../Reporter/types';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';

export const isHeadingNode = (node: ?NodeType, level?: THeadingLevel): boolean => {
  return (
    node != null &&
    Element.isElement(node) &&
    node.type === HEADING_PLUGIN_ID &&
    (level == null || node.level === level)
  );
};

export const capitalizeEntireFirstWord = (text: string): string => {
  if (text.length === 0) return text;
  const wordsInStep = text.split(' ');
  const indexOfWordToCapitalize = wordsInStep.findIndex((word) => word.length > 0); // string manipulation is funny, " " gets encoded as "" in the previous step, so we are checking for length > 0
  wordsInStep[indexOfWordToCapitalize] = wordsInStep[indexOfWordToCapitalize].toUpperCase();
  return wordsInStep.join(' ').trim();
};

export const getDefaultContentForHeading = (text: string): SlateContent => {
  const inlineHeadings = ['examination', 'clinical history', 'comparison', 'technique'];
  const getParagraphChild = (shouldPrependSpace: boolean) =>
    createParagraphWithChildren([
      {
        text: shouldPrependSpace ? ' ' : '',
      },
      {
        type: 'inlineBookmark',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: '',
      },
    ]);

  const lowerText = text.toLowerCase();
  return inlineHeadings.includes(lowerText)
    ? [createInlineParagraphWithChildren([...getParagraphChild(true).children]), createParagraph()]
    : [createInlineParagraph(), createParagraph(), getParagraphChild(false), createParagraph()];
};

// Given the string, nodeText, find the longest matching word in the provided headingKeywords that
// is a substring of nodeText
const getLongestMatchingKeyword = (headingKeywords: Array<string>, nodeText: string): ?string => {
  let longestKeyword = '';
  for (let i = 0; i < headingKeywords.length; i++) {
    const currentKeyword = headingKeywords[i];

    if (nodeText.toLowerCase().includes(currentKeyword.toLowerCase())) {
      if (currentKeyword.length > longestKeyword.length) {
        longestKeyword = currentKeyword;
      }
    }
  }

  return longestKeyword;
};

// Given a NodeEntry in the editor, find the index in the entry's text children array
// that includes the longest matching keyword. This function will return the
// [longestMatchingKeyword, index], or [null, -1] if no keyword exists.
export const getHeadingKeywordIndex = (
  editor: EditorType,
  headingKeywords: HeadingKeywords,
  nodeEntry: NodeEntry<>
): [?string, number] => {
  const nodeTextArray = Array.from(Node.texts(nodeEntry[0]));
  const keywords = Object.values(headingKeywords)
    .flat()
    .map((label) => String(label))
    .sort((a, b) => b.length - a.length);

  const longestMatchingKeyword = getLongestMatchingKeyword(
    keywords,
    Node.string(nodeEntry[0])
  )?.toLowerCase();

  if (longestMatchingKeyword == null || longestMatchingKeyword === '') {
    return [null, -1];
  }

  const textArrayIndex = nodeTextArray.findIndex((textChild) =>
    textChild[0].text.toLowerCase().includes(longestMatchingKeyword)
  );

  // The text should be at the very start of the line, or the first word other than whitespace
  if (
    textArrayIndex === -1 ||
    !nodeTextArray[textArrayIndex][0].text
      .trim()
      .toLowerCase()
      .startsWith(longestMatchingKeyword) ||
    (textArrayIndex !== 0 &&
      nodeTextArray
        .slice(0, textArrayIndex)
        .map((node) => node[0].text)
        .join('')
        .trim() !== '')
  ) {
    return [null, -1];
  }

  return [longestMatchingKeyword, textArrayIndex];
};

export const upgradeHeadingKeywordToHeading = (
  editor: EditorType,
  nodeEntry: NodeEntry<>,
  headingKeyword: string,
  textArrayIndex: number,
  select: boolean
): void => {
  analytics.track(reporter.sys.upgradeHeadingKeywordToHeading, {
    headingKeyword,
  });

  const [node, path] = nodeEntry;
  const nodeTextArray = Array.from(Node.texts(node));
  const nodeText = nodeTextArray[textArrayIndex][0].text;
  let newSelectionPoint: ?LocationType = editor.selection;

  Editor.withoutNormalizing(editor, () => {
    const keywordIndex = nodeText.toLowerCase().indexOf(headingKeyword.toLowerCase());
    const leadingSpaces = nodeText.match(/^\s+/);
    if (leadingSpaces != null) {
      Transforms.delete(editor, {
        at: {
          anchor: { path: [...path, textArrayIndex], offset: 0 },
          focus: {
            path: [...path, textArrayIndex],
            offset: leadingSpaces[0].length,
          },
        },
      });
    }

    // Keep a rangeRef of the keyword, as the edges will change as we edit the nodes
    // in the editor during the normalization and formatting process
    const keywordRangeRef = Editor.rangeRef(
      editor,
      {
        anchor: { path: [...path, textArrayIndex], offset: 0 },
        focus: {
          path: [...path, textArrayIndex],
          offset: keywordIndex + headingKeyword.length,
        },
      },
      {
        affinity: 'outward',
      }
    );

    setHeading(editor, HEADING_PLUGIN_ID, HeadingLevel.H1, keywordRangeRef);

    let keywordRange = getRangeRefSafe(keywordRangeRef);

    const beforeHeadingPoint = Editor.before(editor, Editor.start(editor, keywordRange));

    // If the previous node to the new heading node is also a heading, we have to add
    // a newline to separate them
    if (beforeHeadingPoint != null) {
      const prevHeadingEntry = Editor.node(editor, beforeHeadingPoint, { depth: 1 });
      if (
        prevHeadingEntry != null &&
        (isHeadingNode(prevHeadingEntry[0]) || isInlineNode(prevHeadingEntry[0]))
      ) {
        Transforms.insertNodes(editor, [createParagraph()], { at: beforeHeadingPoint });
      }
    }

    keywordRange = getRangeRefSafe(keywordRangeRef);

    const afterHeadingPoint = !Path.equals(
      Range.start(keywordRange).path,
      Range.end(keywordRange).path
    )
      ? Editor.end(editor, keywordRange)
      : Editor.after(editor, Editor.end(editor, keywordRange));

    if (afterHeadingPoint != null) {
      const nextNodeEntry = Editor.node(editor, afterHeadingPoint, { depth: 1 });
      // If the next node in the editor is a non-inline paragraph, set it as inline.
      // Selection is set to the start of the next node.
      if (nextNodeEntry != null && isRegularParagraph(nextNodeEntry[0])) {
        const insertPoint = Editor.start(editor, nextNodeEntry[1]);
        const insertAt = {
          path: insertPoint.path.length === 1 ? insertPoint.path : [insertPoint.path[0]],
          offset: insertPoint.offset,
        };

        Transforms.setNodes(
          editor,
          { shouldForceInline: true },
          {
            at: insertAt,
          }
        );

        newSelectionPoint = insertPoint;
        // If there is no next node, add an inline paragraph. Selection is
        // set to the point after the new heading node.
      } else if (nextNodeEntry == null) {
        Transforms.insertNodes(editor, [createInlineParagraph()], {
          at: afterHeadingPoint,
        });

        newSelectionPoint = afterHeadingPoint;
        // If the next node is an inline paragraph but also includes a newline,
        // split the node at that character and set the second paragraph after the
        // split to non inline. That paragraph is also formatted to have the first
        // character capitalized. Selection is set to the start of the
        // end of the second paragraph.
      } else if (isInlineNode(nextNodeEntry[0]) && Node.string(nextNodeEntry[0]).includes('\n')) {
        const newlineIndex = Node.string(nextNodeEntry[0]).indexOf('\n');
        const splitPath = { path: [...nextNodeEntry[1], 0], offset: newlineIndex + 1 };

        Transforms.splitNodes(editor, { at: splitPath });

        const newlineEntry = Editor.node(editor, splitPath, { depth: 1 });
        if (newlineEntry != null) {
          Transforms.insertText(editor, ' ', {
            at: {
              anchor: { path: [...newlineEntry[1], 0], offset: 0 },
              focus: { path: [...newlineEntry[1], 0], offset: 1 },
            },
          });

          const postNewlineEntry = Editor.next(editor, { at: newlineEntry[1] });
          if (postNewlineEntry != null) {
            Transforms.unsetNodes(editor, 'shouldForceInline', {
              at: postNewlineEntry[1],
            });

            const postNewlineEdges = Editor.edges(editor, postNewlineEntry[0]);
            const capitalizationText = toCapitalizationCase(Node.string(postNewlineEntry[0]));
            Transforms.insertText(editor, capitalizationText, {
              at: { anchor: postNewlineEdges[0], focus: postNewlineEdges[1] },
            });

            newSelectionPoint = postNewlineEdges[1];
          }
        }
        // Selection is set to the start of the next inline paragraph
      } else {
        newSelectionPoint = Editor.start(editor, nextNodeEntry[1]);
      }
      // If there is no point after the heading, add an inline paragraph
      // and set the selection to the end of the editor
    } else {
      Transforms.insertNodes(editor, [createInlineParagraph()], {
        at: Editor.end(editor, []),
      });
      newSelectionPoint = Editor.end(editor, []);
    }

    if (select && newSelectionPoint != null) {
      Transforms.select(editor, newSelectionPoint);
    }
  });
};

// This normalizes a heading in a document by:
// 1. Removes leading and trailing whitespace
// 2. Removes all non-word characters except for apostrophes
// 3. Replaces multiple consecutive whitespace characters with a single space
export const removePunctuationAndWhitespace = (text: string): string => {
  return text
    .trim()
    .replace(/[^\w\s']/g, '')
    .replace(/\s+/g, ' ');
};

/**
 * This function can be used to make a line of text into title case, which
 * means the first letter of each word is capitalized. The forceLowercase dictates
 * whether the rest of the characters should be left as is, or forced to be lowercase.
 *
 * Example:
 *
 *  toTitleCase("she IS in PAIN", false) ==> "She IS In PAIN"
 *  toTitleCase("she IS in PAIN", true) ==> "She Is In Pain"
 */
export const toTitleCase = (text: string, forceLowercase?: boolean): string => {
  return forceLowercase === true
    ? text.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
        return match.toUpperCase();
      })
    : text.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      });
};

export const toCapitalizationCase = (text: string): string => {
  // Convert the entire string to lowercase
  text = text.toLowerCase();

  // Capitalize the first letter
  text = text.charAt(0).toUpperCase() + text.slice(1);

  return text;
};

// Given a path in the editor, remove the colon at the start of the node next
// to the path if it exists
export const removeAdjacentColon = (editor: EditorType, nodePath: PathType): void => {
  const nodeEntry = Editor.node(editor, nodePath, { depth: 1 });
  const nextNodeEntry = Editor.next(editor, { at: nodeEntry[1] });
  if (nextNodeEntry != null && Node.string(nextNodeEntry[0]).trim().startsWith(':')) {
    Transforms.delete(editor, {
      distance: 1,
      at: {
        path: Editor.leaf(editor, nextNodeEntry[1], { depth: 2, edge: 'start' })[1],
        offset: Node.string(nextNodeEntry[0]).indexOf(':'),
      },
      unit: 'character',
    });
  }
};

export const insertHeading = (level: THeadingLevel, text?: string): HeadingPluginElement => ({
  type: HEADING_PLUGIN_ID,
  level,
  children: [
    { text: level === HeadingLevel.H1 ? (text ?? '').toUpperCase() : toTitleCase(text ?? '') },
  ],
});

export const isPointAfterSelectionInsideHeading = (editor: EditorType): boolean => {
  const { selection } = editor;
  if (selection == null) {
    return false;
  }

  const pointAfter = Editor.after(editor, selection, { distance: 1 });

  if (pointAfter == null) {
    return false;
  }

  const [parentNode] = pointAfter && Editor.parent(editor, pointAfter);

  return isHeadingNode(parentNode);
};

export const isOnlyHeadingSelected = (editor: EditorType): boolean => {
  const { selection } = editor;
  if (selection == null) {
    return false;
  }

  if (areMultipleNodesSelected(editor)) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: NodeType) => isHeadingNode(n),
    })
  );

  return !!match;
};

// Given a selection, check that only heading text is selected. This means
// that even if multiple nodes are selected, if only whitespace is selected
// in non-heading nodes, then this will return true
export const isOnlyHeadingTextSelected = (editor: EditorType): boolean => {
  const { selection } = editor;
  if (selection == null) {
    return false;
  }
  const headingNodeText = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: NodeType) => isHeadingNode(n),
    })
  )
    .map((nodeEntry) => Node.string(nodeEntry[0]).trim())
    .join('');

  return headingNodeText.includes(Editor.string(editor, selection).trim());
};

// This normalizes a heading in a document by:
// 1. Removes leading and trailing whitespace
// 2. Converts string to lowercase
// 3. Removes all non-word characters except for apostrophes
// 4. Replaces multiple consecutive whitespace characters with a single space
export const normalizeText = (text: string): string => {
  return removePunctuationAndWhitespace(text).toLowerCase();
};

export const mapHeadingLevel = (level: ?GraphQLHeadingLevel): ?THeadingLevel => {
  switch (level) {
    case 'H1':
      return HeadingLevel.H1;
    case 'H2':
      return HeadingLevel.H2;
    default:
      return null;
  }
};

export const setHeading = (
  editor: EditorType,
  key: string,
  level: THeadingLevel,
  rangeRef?: RangeRefType
) => {
  const selection = Editor.unhangRange(
    editor,
    rangeRef
      ? (getRangeRefSafe(rangeRef) ?? getEditorSelectionSafe(editor))
      : getEditorSelectionSafe(editor)
  );

  const [, preSplitTextNodePath] = Editor.node(editor, selection);
  const [, preSplitParagraphNodePath] = Editor.parent(editor, preSplitTextNodePath);

  const isFocusEnd = Editor.isEnd(editor, selection.focus, preSplitParagraphNodePath);
  const isAnchorEnd = Editor.isEnd(editor, selection.anchor, preSplitParagraphNodePath);
  const nextHeadingEntry = Editor.next(editor, { at: preSplitParagraphNodePath });

  /**
   * If the next node starts with a colon, we remove the colon.
   */
  removeAdjacentColon(editor, preSplitParagraphNodePath);

  /**
   * If the text at the end of its paragraph, and we are not at the end of
   * of the editor, we add an empty inline paragraph after to preserve this state.
   *
   * For example, if we set 'exam' as an H1 in the following text:
   *
   *   exam
   *   there is pain
   *
   * we WANT the end result to be:
   *
   *   EXAM:
   *   there is pain
   *
   * and NOT to be:
   *
   *   EXAM: there is pain
   *
   */
  if (
    nextHeadingEntry != null &&
    !isEmptyParagraph(nextHeadingEntry[0]) &&
    (isFocusEnd || isAnchorEnd)
  ) {
    Transforms.insertNodes(editor, createInlineParagraph(), {
      at: Editor.after(editor, preSplitParagraphNodePath),
    });
  }

  Transforms.setNodes(
    editor,
    {
      type: key,
      level,
      shouldForceInline: undefined,
    },
    { split: true, at: selection }
  );

  const postSelection = Editor.unhangRange(
    editor,
    rangeRef
      ? (getRangeRefSafe(rangeRef) ?? getEditorSelectionSafe(editor))
      : getEditorSelectionSafe(editor)
  );

  const [postSplitNode, postSplitNodePath] = Editor.node(
    editor,
    Point.isPoint(postSelection) ? postSelection : Editor.start(editor, postSelection),
    { depth: 1 }
  );

  /**
   * If the next node starts with a colon, we remove the colon.
   */
  removeAdjacentColon(editor, postSplitNodePath);

  /**
   * If the text does not end in a colon, append a colon
   */
  if (!Node.string(postSplitNode).endsWith(':') && !Range.isCollapsed(postSelection)) {
    const headingEndPath = Editor.end(editor, postSplitNodePath);
    Transforms.insertText(editor, ':', {
      at: { path: headingEndPath.path, offset: Node.string(postSplitNode).length },
      unit: 'character',
    });
  }

  const nextNodeEntry = Editor.next(editor, { at: postSplitNodePath });
  if (nextNodeEntry != null) {
    if (!isFocusEnd && !isAnchorEnd && isRegularParagraph(nextNodeEntry[0])) {
      Transforms.setNodes(
        editor,
        { shouldForceInline: true },
        {
          at: nextNodeEntry[1],
        }
      );
    } else if (nextNodeEntry != null && !isInlineParagraph(nextNodeEntry[0])) {
      Transforms.insertNodes(editor, createInlineParagraph(), {
        at: nextNodeEntry[1],
      });
    }
  } else {
    const endOfDocument = Editor.end(editor, []);
    /**
     * If the heading it set at the very end of the document, add an empty inline paragraph
     */
    if (Point.equals(endOfDocument, Editor.end(editor, postSplitNodePath))) {
      Transforms.insertNodes(editor, createInlineParagraph(), {
        at: endOfDocument,
      });
    }
  }

  if (!Range.isCollapsed(selection)) {
    Transforms.select(editor, postSplitNodePath);
  }
};

/**
 * If multiple nodes are selected, with additional text other than the heading text in the selection,
 * then we set that additional text as a heading, and set the colon accordingly. This will handle a number
 * of situations: when we're selecting only paragraphs, when we're selecting
 *
 * Example:
 * ---------------------------------------------------
 * Input:
 *
 *   waffles and |bagels
 *   MAKE ME:
 *   really| happy
 *
 * Set heading
 *
 *   waffles and
 *   BAGELS MAKE ME REALLY:
 *   happy
 *
 */
export const setMultipleNodesAsHeading = (editor: EditorType, level: THeadingLevel): void => {
  const selection = getEditorSelectionSafe(editor);
  // Get all of the nodes in the editor that are currently selected
  const nodeEntriesToSet = Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n: NodeType) => Element.isElement(n) && !Text.isText(n),
    })
  );

  if (nodeEntriesToSet.length === 0) {
    return;
  }

  const headingNodeEntryIndices = nodeEntriesToSet.reduce(
    (acc, nodeEntry, index) => (isHeadingNode(nodeEntry[0]) ? [...acc, index] : acc),
    []
  );

  // We need to keep a living reference to the heading nodes, as these are the nodes that
  // we're going to be editing by prepending or appending text. Since the surrounding nodes may be edited
  // and removed, we need the paths to these headings to change when those operations are done
  const headingEntries =
    headingNodeEntryIndices.length === 0
      ? []
      : headingNodeEntryIndices.map((index) => [
          nodeEntriesToSet[index][0],
          Editor.pathRef(editor, nodeEntriesToSet[index][1]),
        ]);

  /** If only two headings and no other text is being merged, we just go ahead and merge the two
   * texts with the '/' delimiter
   *
   * Example:
   * --------------------------------------------
   * Input:
   *
   *  |FINDINGS:
   *
   *   IMPRESSIONS|
   *
   * Select as heading:
   *
   *   FINDINGS/IMPRESSIONS:|
   *
   */
  if (
    headingNodeEntryIndices.length > 1 &&
    [...Array(nodeEntriesToSet.length).keys()]
      .filter((index) => !headingNodeEntryIndices.includes(index))
      .map((index) => Node.string(nodeEntriesToSet[index][0]).trim())
      .join('')
      .trim().length === 0
  ) {
    const firstHeadingEntry = headingEntries[0];
    let textToAppend = '';

    // Go through each node to set, going backwards. For each node, we add it to
    // the text we will append to the existing heading, and remove the node. Then,
    // merge the text with the heading
    [...nodeEntriesToSet.entries()].reverse().forEach((element) => {
      const [index, nodeEntry] = element;
      const isFirstHeadingEntry = headingNodeEntryIndices[0] === index;
      if (isFirstHeadingEntry) {
        return;
      }

      if (!isFirstHeadingEntry) {
        if (headingNodeEntryIndices.includes(index)) {
          textToAppend = '/' + Node.string(nodeEntry[0]).trim() + textToAppend;
        }

        Transforms.removeNodes(editor, { at: nodeEntry[1] });
      }
    });

    mergeTextAndHeadings(
      editor,
      [firstHeadingEntry[0], getPathRefSafe(firstHeadingEntry[1])],
      level,
      textToAppend,
      ''
    );
    return;
  }

  let textToPrepend = '';
  let textToAppend = '';

  const headingNodeEntryIndex =
    headingNodeEntryIndices.length > 0 ? headingNodeEntryIndices[0] : -1;

  // Go through each nodes in our selection, in reverse. We have to do this in reverse since
  // we will be mutating the state of the editor
  for (let i = nodeEntriesToSet.length - 1; i >= 0; i--) {
    if (i === headingNodeEntryIndex) {
      continue;
    }

    const nodeEntry = nodeEntriesToSet[i];
    const nodeRange = {
      anchor: Editor.start(editor, nodeEntry[1]),
      focus: Editor.end(editor, nodeEntry[1]),
    };
    const intersection = Range.intersection(selection, nodeRange);

    if (intersection == null) {
      continue;
    }

    // If the index is before the index of our heading node, then the text should be
    // prepended. If not, it should be appended.
    if (i < headingNodeEntryIndex) {
      textToPrepend =
        ' ' +
        Node.string(nodeEntry[0]).substring(Number(intersection?.anchor?.offset)) +
        textToPrepend;
    } else {
      textToAppend =
        ' ' +
        Node.string(nodeEntry[0]).substring(0, Number(intersection?.focus?.offset)) +
        textToAppend;
    }

    // If the intersection is equal to the nodeRange, this means the entire node is
    // within our selection and should be entirely removed. If not, we only delete the
    // text within the intersection
    if (Range.equals(intersection, nodeRange)) {
      Transforms.removeNodes(editor, { at: nodeEntry[1] });
    } else {
      Transforms.delete(editor, { at: intersection });
    }
  }

  // If there is at least one heading node within our selection, that heading node serves
  // as our 'anchor' node to which we will prepend and append text. Since we have edited the state
  // of the editor, we have to be sure to use our path reference stored in headingEntries
  if (headingNodeEntryIndices.length > 0) {
    mergeTextAndHeadings(
      editor,
      [headingEntries[0][0], getPathRefSafe(headingEntries[0][1])],
      level,
      textToAppend.trim() === '' ? '' : ' ' + textToAppend.trim(),
      textToPrepend.trim() === '' ? '' : textToPrepend.trim() + ' '
    );
  } else if (editor.children.length === 0) {
    // If the editor is empty, there are extra steps that need to be taken for inserting the heading
    createHeadingInEmptyEditor(editor, selection, textToAppend.trim(), level);
  } else {
    // If neither of the above is true, this means that we have only selected non-heading nodes
    // within a non-empty editor. At this point, it's just a matter of inserting a new heading node
    // with the text required
    Transforms.insertNodes(editor, [insertHeading(level, textToAppend.trim() + ':')], {
      at: Editor.start(editor, selection),
    });
    Transforms.select(editor, {
      anchor: Editor.start(editor, selection),
      focus: Editor.start(editor, selection),
    });
    Transforms.move(editor, { distance: textToAppend.trim().length + 1 });
  }
};

/** Given an editor, a heading node, and text to prepend and append, we insert the text to prepend
 * at the start of the heading, and the text to append at the end of the heading. We also set the level
 * of the heading to the given heading if necessary.
 *
 * Example:
 * -----------------------------------------------
 * input:
 *    headingNodeEntry: [{type: 'heading', level: 1, children: [{text: 'HEADING:'}]}, [1]]
 *    level: 1
 *    textToAppend: 'after'
 *    textToPrepend: 'before'
 *
 * output:
 *    the headingNodeEntry at [1] is now at [0] and has the following content:
 *
 *    BEFORE HEADING AFTER:
 *
 */
export const mergeTextAndHeadings = (
  editor: EditorType,
  headingNodeEntry: NodeEntry<NodeType>,
  level: THeadingLevel,
  textToAppend: string,
  textToPrepend: string
) => {
  const colonIndex = Node.string(headingNodeEntry[0]).indexOf(':');

  // If there is a colon in the existing heading, we remove that colon
  if (colonIndex !== -1) {
    Transforms.delete(editor, {
      at: { path: [...headingNodeEntry[1], 0], offset: colonIndex },
      unit: 'character',
      distance: 1,
    });
  }

  // If there is text to prepend, we insert this text to the start of the heading
  if (textToPrepend !== '') {
    Transforms.insertText(editor, textToPrepend, {
      at: Editor.start(editor, headingNodeEntry[1]),
    });
  }

  // If there is text to append, we remove the colon within the text to append
  // and add a colon to the end. Finally, we insert this text to the end of the heading
  if (textToAppend !== '') {
    textToAppend = textToAppend.replace(':', '') + ':';
    Transforms.insertText(editor, textToAppend, {
      at: Editor.end(editor, headingNodeEntry[1]),
    });
  }

  // If the current level fo the heading is not equal to the given level, we set the
  // level accordingly
  if (headingNodeEntry[0].level !== level) {
    Transforms.setNodes(
      editor,
      {
        level,
      },
      { at: headingNodeEntry[1] }
    );
  }

  // We fetch the heading again to ensure we have the most up to date text of the heading,
  // after the previous edits. If it doesn't already end with a colon, we insert a colon.
  const afterEditNodeEntry = Editor.node(editor, headingNodeEntry[1]);
  if (afterEditNodeEntry != null && !Node.string(afterEditNodeEntry[0]).endsWith(':')) {
    Transforms.insertText(editor, ':', {
      at: Editor.end(editor, afterEditNodeEntry[1]),
    });
  }

  // Move the cursor to the end of the heading we have edited
  Transforms.select(editor, Editor.end(editor, afterEditNodeEntry[1]));
};

// Due to some intricacies with slate, we cannot simply insert a heading into an empty array. We
// add a placeholder paragraph, then the heading, and remove the placeholder paragraph.
export const createHeadingInEmptyEditor = (
  editor: EditorType,
  selection: RangeType,
  text: string,
  level: THeadingLevel
) => {
  Transforms.insertNodes(editor, [createParagraph()], { at: [0] });
  Transforms.insertNodes(editor, [insertHeading(level, text.trim() + ':')], {
    at: Editor.start(editor, selection),
  });
  Transforms.select(editor, {
    anchor: Editor.start(editor, selection),
    focus: Editor.start(editor, selection),
  });
  Transforms.removeNodes(editor, { at: [0] });
  Transforms.move(editor, { distance: text.trim().length + 1 });
};

// If multiple nodes are selected, but really only the heading text is "real" text in the selection,
// we can also go ahead and unset the heading, but delete the extra space nodes
export const unsetMultipleNodesAsHeading = (editor: EditorType): void => {
  const selection = getEditorSelectionSafe(editor);

  // Fetch the nodes that are in our selection. We don't have to check for intersections
  // since this function only handles a situation where only the heading text is selected
  const nodeEntriesToUnset = Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n: NodeType) => Element.isElement(n) && !Text.isText(n),
    })
  );

  let textToInsert = '';

  // For each node in our selection, fetch and merge the text
  for (const nodeEntry of nodeEntriesToUnset) {
    textToInsert = Node.string(nodeEntry[0]).trim() + ' ' + textToInsert;
  }

  // Insert the text after the last node in our selection
  Transforms.insertNodes(
    editor,
    [createParagraph(textToInsert.trim().toLowerCase().replace(':', ''))],
    {
      at: Editor.after(editor, nodeEntriesToUnset[nodeEntriesToUnset.length - 1][1]),
    }
  );

  const reversed = nodeEntriesToUnset.reverse().slice(1);
  // Remove each of the nodes we want to unset
  for (const nodeEntry of reversed) {
    Transforms.removeNodes(editor, { at: nodeEntry[1] });
  }
};

export const unsetHeading = (editor: EditorType): void => {
  let selection = getEditorSelectionSafe(editor);
  const [node, nodePath] = Editor.node(editor, selection);
  const nodeText = Node.string(node);
  const parentHeadingEntry = Editor.parent(editor, nodePath);
  let shouldForceInline: ?boolean = undefined;

  if (parentHeadingEntry != null) {
    const nextNodeEntry = Editor.next<NodeType>(editor, { at: parentHeadingEntry[1] });

    shouldForceInline =
      nextNodeEntry != null && isInlineParagraph(nextNodeEntry[0]) ? true : undefined;

    const headingRange = {
      anchor: Editor.start(editor, parentHeadingEntry[1]),
      focus: Editor.end(editor, parentHeadingEntry[1]),
    };
    Transforms.insertText(editor, Editor.string(editor, headingRange).toLowerCase(), {
      at: headingRange,
    });

    if (selection != null) {
      Transforms.select(editor, selection);
    }
  }

  /**
   * If the text ends in a colon, we remove the colon.
   */
  if (nodeText.trim().endsWith(':')) {
    Transforms.delete(editor, {
      distance: 1,
      at: { path: nodePath, offset: nodeText.indexOf(':') },
      unit: 'character',
    });
  }

  /**
   * If the next node starts with a colon, we remove the colon.
   */
  removeAdjacentColon(editor, nodePath);

  Transforms.setNodes(
    editor,
    {
      type: 'paragraph',
      level: undefined,
      shouldForceInline,
    },
    { split: true }
  );

  /**
   * After toggling, if the previous node is a heading, then we should set
   * the split node as inline. We should also add an extra paragraph if
   * the next node is also a heading. This happens if we're unsetting only part of a heading
   *
   * For example, if we unset "exam" in the following
   *
   *  HER EXAM WENT WELL
   *
   * we WANT the end result to be:
   *
   *   HER exam
   *   WENT WELL
   *
   * and NOT to be:
   *
   *   HER exam WENT WELL
   *
   */
  selection = getEditorSelectionSafe(editor);
  const changedNodeEntry = Editor.node(editor, selection, { depth: 1 });
  const previousHeadingEntry = Editor.previous(editor, { at: changedNodeEntry[1] });
  if (previousHeadingEntry != null && isHeadingNode(previousHeadingEntry[0])) {
    Transforms.setNodes(editor, { shouldForceInline: true });
  }

  const nextHeadingEntry = Editor.next(editor, { at: changedNodeEntry[1] });
  if (nextHeadingEntry != null && isHeadingNode(nextHeadingEntry[0])) {
    const beforeNextHeadingPath = Editor.before(editor, nextHeadingEntry[1]);
    if (beforeNextHeadingPath != null) {
      Transforms.insertNodes(editor, createParagraph(), { at: beforeNextHeadingPath });
    }
  }

  if (isMarkActive(editor, 'headingError')) {
    Editor.removeMark(editor, 'headingError');
  }
};

export const areMultipleHeadingsSelected = (editor: EditorType): boolean => {
  const { selection } = editor;
  if (selection == null) {
    return false;
  }

  const selectedNodes = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n: NodeType) => isHeadingNode(n),
  });

  let count = 0;
  for (const [node] of selectedNodes) {
    if (node != null) {
      count++;
    }

    if (count > 1) {
      return true;
    }
  }

  return false;
};

export const getHeadingCategory = (
  headingKeyword: ?string,
  headingKeywords: HeadingKeywords
): ?string => {
  if (headingKeyword == null) {
    return null;
  }

  for (const category in headingKeywords) {
    if (
      Array.isArray(headingKeywords[category]) &&
      headingKeywords[category].some((variant) => {
        return variant.includes(normalizeText(headingKeyword));
      })
    ) {
      return category;
    }
  }
};

export const doesEditorHaveSimilarHeading = (
  editor: EditorType,
  headingKeyword: ?string,
  headingKeywords: HeadingKeywords
): boolean => {
  if (headingKeyword == null) return false;

  const existingH1s = [
    ...Editor.nodes(editor, {
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
      match: (n: NodeType) => n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
    }),
  ];

  const headingKeywordCategory = getHeadingCategory(headingKeyword, headingKeywords);

  if (headingKeywordCategory == null) {
    return false;
  }

  return existingH1s.some(([node]) =>
    headingKeywords[headingKeywordCategory].includes(normalizeText(Node.string(node).toLowerCase()))
  );
};

export const toggleHeading = (editor: EditorType, key: string, level: THeadingLevel) => {
  analytics.track(reporter.usr.markApplied, {
    pluginId: key,
    level,
    selectedText:
      editor.selection != null ? Editor.string(editor, editor.selection) : 'No text selected',
  });

  Editor.withoutNormalizing(editor, () => {
    const { selection } = editor;
    if (selection == null) {
      return;
    }

    if (isHeadingActive(editor, key, level)) {
      // If only a heading is selected and nothing else, then we can go ahead an unset that single node
      if (isOnlyHeadingSelected(editor)) {
        unsetHeading(editor);
        return;
      } else if (areMultipleNodesSelected(editor)) {
        // If multiple nodes are selected, but really only the heading text is "real" text in the selection,
        // we can also go ahead and unset the heading, but delete the extra space nodes
        if (isOnlyHeadingTextSelected(editor) && !areMultipleHeadingsSelected(editor)) {
          unsetMultipleNodesAsHeading(editor);
        } else {
          // If multiple nodes are selected, with additional text other than the heading text in the selection,
          // then we set that additional text as a heading, and set the colon accordingly
          setMultipleNodesAsHeading(editor, level);
        }
        return;
      }
    }

    if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, insertHeading(level));
    } else if (areMultipleNodesSelected(editor)) {
      setMultipleNodesAsHeading(editor, level);
    } else {
      setHeading(editor, key, level);
    }
  });

  Editor.normalize(editor, { force: true });
};

export const isHeadingActive = (
  editor: EditorType,
  type: string,
  level: ?THeadingLevel
): boolean => {
  const selection = getEditorSelectionSafe(editor);
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: NodeType) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === type && n.level === level,
    })
  );

  return !!match;
};

export const selectionIncludesHeading = (editor: EditorType): boolean =>
  //$FlowIgnore[incompatible-type] we know this is a heading level.
  Object.values(HeadingLevel).some((level: THeadingLevel) =>
    isHeadingActive(editor, HEADING_PLUGIN_ID, level)
  );

export const isCursorAfterHeadingColon = (editor: EditorType, selection: RangeType): boolean => {
  const nodeEntry = Editor.node(editor, selection, { depth: 1 });
  if (nodeEntry != null && isHeadingNode(nodeEntry[0])) {
    const selectionStart = Editor.start(editor, selection);
    const nodeText = Node.string(nodeEntry[0]);
    const colonIndex = nodeText.indexOf(':');
    const selectionString = Editor.string(editor, {
      anchor: Editor.start(editor, nodeEntry[1]),
      focus: selectionStart,
    });
    return (
      colonIndex !== -1 &&
      (selectionStart.offset > colonIndex || selectionString.length > colonIndex)
    );
  }

  return false;
};

export const getHeadingNodeWithTextInRange = ({
  editor,
  text,
  range,
  level = HeadingLevel.H1,
}: {
  editor: EditorType,
  text: string,
  range?: RangeType,
  level?: THeadingLevel,
}): ?NodeEntry<> => {
  const maybeHeadingNode = Editor.nodes(editor, {
    at: range ?? [],
    match: (n: NodeType) => {
      return (
        n.type === HEADING_PLUGIN_ID &&
        (level == null || n.level === level) &&
        normalizeText(Node.string(n)) === normalizeText(text)
      );
    },
  }).next();

  if (!maybeHeadingNode.done) {
    return maybeHeadingNode.value;
  }

  return null;
};

export const getHeadingNodeWithSynonym = (
  editor: EditorType,
  keywords: $ReadOnlyArray<string> | Array<string>,
  level?: THeadingLevel
): ?NodeEntry<> => {
  const lowerKeywords = keywords.map((keyword) => keyword.toLowerCase());
  const maybeHeadingNode = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => {
      return (
        n.type === HEADING_PLUGIN_ID &&
        (level == null || n.level === level) &&
        lowerKeywords.includes(Node.string(n).trim().toLowerCase().replace(':', ''))
      );
    },
  }).next();

  if (!maybeHeadingNode.done) {
    return maybeHeadingNode.value;
  }

  return null;
};

/**
 * Used in Focus Mode to parse a node and return the heading text if it is a heading node.
 *
 * This returns nodes that are either heading nodes or heading-like nodes.
 */
export const getNextHeadingOrHeadingLikeNode = ({
  editor,
  location,
  level = HeadingLevel.H1,
  filterFn,
}: {
  editor: EditorType,
  location: RangeType | PathType | PointType | void,
  level?: number,
  filterFn?: (n: NodeType) => boolean,
}): NodeEntry<> | void => {
  return Editor.next(editor, {
    at: location,
    match: (n: NodeType) =>
      ((n.type === HEADING_PLUGIN_ID && typeof n.level === 'number' && level <= n.level) ||
        (n.type === PARAGRAPH_PLUGIN_ID && n.shouldForceInline !== true)) &&
      (filterFn != null ? filterFn(n) : true),
  });
};

/**
 * Used in Focus Mode to parse a node and return the first heading text if it is a heading node.
 *
 * This returns nodes that are either heading nodes or heading-like nodes.
 */
export const getFirstHeadingOrHeadingLikeNode = ({
  editor,
  level = HeadingLevel.H1,
  filterFn,
}: {
  editor: EditorType,
  level?: number,
  filterFn?: (n: NodeType) => boolean,
}): Array<NodeEntry<empty>> => {
  return [
    ...Editor.nodes(editor, {
      at: [],
      match: (n: NodeType) =>
        ((n.type === HEADING_PLUGIN_ID && typeof n.level === 'number' && level <= n.level) ||
          (n.type === PARAGRAPH_PLUGIN_ID && n.shouldForceInline !== true)) &&
        (filterFn != null ? filterFn(n) : true),
    }),
  ];
};

/**
 * Used in Focus Mode to parse a node and return the heading text if it is a heading node.
 *
 * Under normal circumstances, heading-like nodes are upgraded to actual heading nodes.
 * This covers the case where you have a non-heading node that looks like a heading.
 *
 * Ex: "LIVER: Liver is normal" may be a text node, but should be treated as a heading
 */
export const getMaybeHeadingFormatted = (n: NodeType): string => {
  const pattern = /[A-Za-z\s]+:/;
  const string = Node.string(n);
  if (pattern.test(string)) {
    return string.split(':')[0].trim().replace(/\s+/g, ' ').toLowerCase();
  }
  return '';
};

export const getHeadingSectionEdgesByNode = (
  editor: EditorType,
  headingNodeEntry: NodeEntry<>,
  level?: THeadingLevel
): ?RangeType => {
  const levelToSearch = level ?? HeadingLevel.H1;
  const nextHeadingNode = Editor.next(editor, {
    at: headingNodeEntry[1],
    match: (n: NodeType) => n.type === HEADING_PLUGIN_ID && n.level === levelToSearch,
  });

  let anchor: ?PointType = null;
  let focus: ?PointType = null;
  if (nextHeadingNode == null) {
    anchor = Editor.after(editor, headingNodeEntry[1]);
    focus = Editor.end(editor, []);
  } else {
    anchor = Editor.after(editor, headingNodeEntry[1]);
    focus = Editor.before(editor, nextHeadingNode[1]);
  }

  if (anchor == null || focus == null) {
    return null;
  }

  return { anchor, focus };
};

/**
 * Given a list of sections, this function will return the range of the target section.
 * Ex: If sections = ['FINDINGS', 'LIVER'], this function will return the range of the
 * 'LIVER' section.
 *
 * @param {EditorType} editor
 * @param {string[]} sections
 */
export const getHeadingSectionEdgesByRegex = (
  editor: EditorType,
  sections?: Array<string>
): ?RangeType => {
  if (sections == null || sections.length === 0) {
    return null;
  }

  let targetHeaderPoint: PathType | PointType = Editor.start(editor, []);
  let headingNode: NodeEntry<empty> | NodeEntry<> | void;
  for (let secIx = 0; secIx < sections.length; secIx++) {
    const section = sections[secIx];
    const level = secIx + 1;
    if (level === 1) {
      const [firstHeadingNode] = getFirstHeadingOrHeadingLikeNode({
        editor,
        filterFn: (n: NodeType) => getMaybeHeadingFormatted(n) === section.toLowerCase(),
        level,
      });
      headingNode = firstHeadingNode;
    } else {
      const nextHeadingNode = getNextHeadingOrHeadingLikeNode({
        editor,
        location: headingNode ? headingNode[1] : [],
        filterFn: (n: NodeType) => getMaybeHeadingFormatted(n) === section.toLowerCase(),
        level,
      });
      headingNode = nextHeadingNode;
    }
    if (headingNode == null) {
      return null;
    }
    targetHeaderPoint = headingNode[1];
  }
  if (headingNode == null) return null;

  const lastHeadingNode = getNextHeadingOrHeadingLikeNode({
    editor,
    location: targetHeaderPoint,
    filterFn: (n: NodeType) => !!getMaybeHeadingFormatted(n),
  });

  let anchor: ?PointType = null;
  let focus: ?PointType = null;
  if (headingNode[0].type === HEADING_PLUGIN_ID) {
    anchor = Editor.after(editor, targetHeaderPoint);
  } else {
    anchor = Editor.start(editor, targetHeaderPoint);
  }
  if (lastHeadingNode == null) {
    focus = Editor.end(editor, []);
  } else {
    focus = Editor.before(editor, lastHeadingNode[1]);
  }

  if (anchor == null || focus == null) {
    return null;
  }

  return { anchor, focus };
};

export const maybeConvertTextParagraphsToHeadings = (
  editor: EditorType,
  fragment: SlateContent,
  headingKeywords: HeadingKeywords
): SlateContent => {
  const fragmentToReturn = [];

  for (const node of fragment) {
    const isTextParagraph =
      node.type === PARAGRAPH_PLUGIN_ID &&
      Array.isArray(node.children) &&
      node.children.every((child) => Text.isText(child));

    if (!isTextParagraph) {
      fragmentToReturn.push(node);
      continue;
    }

    const nodeString = Node.string(node).trim();
    const [longestMatchingKeyword] = getHeadingKeywordIndex(editor, headingKeywords, [node, []]);

    if (
      longestMatchingKeyword == null ||
      longestMatchingKeyword === '' ||
      nodeString.toLowerCase().indexOf(longestMatchingKeyword + ':') !== 0
    ) {
      fragmentToReturn.push(node);
      continue;
    }

    const suffixText = nodeString.slice(longestMatchingKeyword.length).trim();
    const headingText = longestMatchingKeyword.toUpperCase();

    fragmentToReturn.push({
      type: HEADING_PLUGIN_ID,
      level: HeadingLevel.H1,
      children: [{ text: headingText + ':' }],
    });

    fragmentToReturn.push({
      type: PARAGRAPH_PLUGIN_ID,
      shouldForceInline: true,
      children: [{ text: suffixText.slice(1) }],
    });
  }

  return fragmentToReturn;
};
