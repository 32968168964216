// @flow

import type { AllReporterVariant } from '../../RichTextEditor/hooks/useReporter';

import { Spacings } from 'styles';

import { Stack } from 'common/ui/Layout';
import useStudyIds from 'hooks/useStudyIds';

export const FIELDS_WRAPPER_PADDING = 32;
export const FIELDS_WRAPPER_TOP_PADDING = 16;

export type EditorWrapperProps = {
  variant: AllReporterVariant,
  children: React$Node,
  aiMode?: boolean,
  isFieldPane?: boolean,
  space?: $Keys<typeof Spacings>,
};

export const EditorWrapper = ({
  aiMode = false,
  variant,
  children,
  isFieldPane = false,
  space = 'small',
}: EditorWrapperProps): React$Node => {
  const studyIDs = useStudyIds();

  let padding = isFieldPane ? 0 : FIELDS_WRAPPER_PADDING;
  let topPadding = 0;

  if (!aiMode) {
    if (studyIDs.length > 0 && variant === 'report') {
      topPadding = 16;
    } else if (variant === 'report') {
      topPadding = FIELDS_WRAPPER_TOP_PADDING;
    } else {
      topPadding = padding = 0;
    }
  }

  return (
    <Stack
      vertical={variant === 'report'}
      css={`
        box-sizing: border-box;
        padding: ${topPadding}px ${padding}px ${padding}px ${padding}px;
        height: 100%;
        overflow: auto;
      `}
      stretchX
      stretchY
      data-testid={`fields-wrapper-${variant}`}
      space={space}
      data-stack-stretch
    >
      {children}
    </Stack>
  );
};
