// @flow
import { useState, useContext, memo } from 'react';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import { encodeId } from '../../utils/representationId';
import { Handle } from './Handle';
import { AnnotationMutationsContext } from '../../modules/AnnotationMutationsContext';
import type { DreMouseEvent } from '../../types';
import type { SegmentPointPositionTuple, Segment } from '../types';

type HandlesProps = {
  visible: boolean,
  annotationId: string,
  segments: $ReadOnlyArray<Segment>,
  color: string,
  onMouseLeave: (DreMouseEvent) => mixed,
  onMouseOver: (DreMouseEvent) => mixed,
  mapper: (
    segmentIndex: number,
    pointIndex: number,
    mousePosition: vec3
  ) => Array<SegmentPointPositionTuple>,
  opacity?: number,
};

const DRAG_WARNING = `[SIRONA] Couldn't move handle. Either worldPosition or updateAnnotation is not defined.`;

export const Handles: React$ComponentType<HandlesProps> = memo(
  ({
    visible = true,
    opacity = 1,
    annotationId,
    segments,
    color,
    onMouseLeave,
    onMouseOver,
    mapper,
  }: HandlesProps): React$Node => {
    const [dragging, setDragging] = useState(false);
    const { updateAnnotationSegments } = useContext(AnnotationMutationsContext);

    return segments
      .map((segment, segmentIndex) =>
        segment.map((point, pointIndex) => {
          const name = `handle${segmentIndex}-${pointIndex}`;
          const serializedId = encodeId({
            id: annotationId,
            name,
          });
          return (
            <Handle
              key={serializedId}
              id={serializedId}
              data-testid={serializedId}
              point={point}
              color={color}
              opacity={dragging ? 1 : opacity}
              visible={visible}
              onMouseLeave={onMouseLeave}
              onMouseOver={onMouseOver}
              onDragStart={() => setDragging(true)}
              onDrag={({ worldPosition }) => {
                if (worldPosition == null || updateAnnotationSegments == null) {
                  console.warn(DRAG_WARNING);
                  return;
                }
                // Updates annotation locally
                updateAnnotationSegments(
                  annotationId,
                  true
                )(mapper(segmentIndex, pointIndex, worldPosition));
              }}
              onDragEnd={({ worldPosition }) => {
                setDragging(false);
                if (worldPosition == null || updateAnnotationSegments == null) {
                  console.warn(DRAG_WARNING);
                  return;
                }
                // Updates annotation on server
                updateAnnotationSegments(annotationId)(
                  mapper(segmentIndex, pointIndex, worldPosition)
                );
              }}
            />
          );
        })
      )
      .flat(1);
  }
);

Handles.displayName = 'Handles';
