// @flow

import {
  MY_QUEUE,
  QC,
  PENDING,
  IN_PROGRESS,
  RECENTLY_READ,
  ARCHIVE,
  WORKLIST_VIEW,
  STUDIES_DIALOG,
  PATIENT_JACKET_PRIORS,
} from 'config/constants';

export const WorklistSurface = Object.freeze({
  MY_QUEUE: MY_QUEUE,
  QC: QC,
  PENDING: PENDING,
  IN_PROGRESS: IN_PROGRESS,
  RECENTLY_READ: RECENTLY_READ,
  ARCHIVE: ARCHIVE,
  WORKLIST_VIEW: WORKLIST_VIEW,
  STUDIES_DIALOG: STUDIES_DIALOG,
  PATIENT_JACKET_PRIORS: PATIENT_JACKET_PRIORS,
});

export type WorklistSurfaceType =
  | 'MY_QUEUE'
  | 'QC'
  | 'PENDING'
  | 'IN_PROGRESS'
  | 'RECENTLY_READ'
  | 'ARCHIVE'
  | 'STUDIES_DIALOG'
  | 'WORKLIST_VIEW'
  | 'PATIENT_JACKET_PRIORS';
