// @flow

import { compareDesc } from 'date-fns';
import { useViewType, useViewportId } from '../state';
import { createActiveSliceChangedEmitterEvent } from './BaseImagingProvider';
import { useImagingContext } from './ImagingContext';

import { useEffect, useMemo } from 'react';
import {
  atomFamily,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import type { RecoilState, RecoilValueReadOnly } from 'recoil';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useStudies, useBaseViewerData } from 'domains/viewer/Viewer/StudyLoader/useStudies';
import { REVIEWED_THRESHOLD } from 'config/constants';
import type { Stack, Study } from 'domains/viewer/ViewportsConfigurations/types';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';
import { DEFAULT_STUDY_DATE_VALUE } from 'domains/reporter/RichTextEditor/plugins/deepLink/constants';

export type CaseReviewedStatus = {
  seriesSmid: string,
  studySmid: string,
  studyDate: ?Date,
  studyDescription: ?string,
  threshold: number,
};

export type ReviewedStudyByDate = {
  smid: string,
  description: ?string,
  studyDate: ?Date,
};

export function caseReviewedStatusesMergeFunction(
  currentReviewedStatuses: ?(CaseReviewedStatus[]),
  incomingReviewedStatuses: CaseReviewedStatus[]
): CaseReviewedStatus[] {
  if (currentReviewedStatuses == null) return incomingReviewedStatuses;

  const newStatuses = [];
  incomingReviewedStatuses.forEach((incomingStatus) => {
    if (
      currentReviewedStatuses.every(
        (currentStatus) => currentStatus.seriesSmid !== incomingStatus.seriesSmid
      )
    ) {
      newStatuses.push(incomingStatus);
    }
  });

  if (newStatuses.length === 0) return currentReviewedStatuses;

  return [...currentReviewedStatuses, ...newStatuses];
}

export const caseReviewedStatuses: (caseSmid: string) => RecoilState<CaseReviewedStatus[]> =
  atomFamily({
    key: 'caseReviewedStatuses',
    default: [],
    effects: [
      broadcastChannelSynchronizerEffect({
        mergeFn: caseReviewedStatusesMergeFunction,
      }),
      localStoragePersisterEffect({ version: 1 }),
    ],
  });

export const isStackReviewed: ({
  caseSmid: ?string,
  stackSeriesSmids: Array<string>,
}) => RecoilValueReadOnly<boolean> = selectorFamily({
  key: 'isStackReviewed',
  get:
    ({ caseSmid, stackSeriesSmids }) =>
    ({ get }) => {
      if (caseSmid == null || stackSeriesSmids.length === 0) return false;

      const reviewedStatuses = get(caseReviewedStatuses(caseSmid));
      return reviewedStatuses.some((reviewedStatus) =>
        stackSeriesSmids.some((stackSeriesSmid) => reviewedStatus.seriesSmid === stackSeriesSmid)
      );
    },
});

export const reviewedStudySmids: (caseSmid: ?string) => RecoilValueReadOnly<Set<string>> =
  selectorFamily({
    key: 'reviewedStudies',
    get:
      (caseSmid) =>
      ({ get }) => {
        if (caseSmid == null) return new Set();

        const reviewedStatuses = get(caseReviewedStatuses(caseSmid));
        return new Set(reviewedStatuses.map((reviewedStatus) => reviewedStatus.studySmid));
      },
  });

export const reviewedStudiesByDateMap: ({
  caseSmid: ?string,
  currentStudySmids: $ReadOnlyArray<string>,
}) => RecoilValueReadOnly<Map<string, ReviewedStudyByDate>> = selectorFamily({
  key: 'reviewedStudiesByDate',
  get:
    ({ caseSmid, currentStudySmids }) =>
    ({ get }) => {
      if (caseSmid == null || currentStudySmids == null) return new Map();

      const allReviewedStatuses = [...get(caseReviewedStatuses(caseSmid))];
      const reviewedStatuses = allReviewedStatuses.filter(
        (study) => !currentStudySmids.includes(study.studySmid)
      );
      reviewedStatuses.sort((a, b) =>
        compareDesc(
          new Date(a.studyDate ?? DEFAULT_STUDY_DATE_VALUE),
          new Date(b.studyDate ?? DEFAULT_STUDY_DATE_VALUE)
        )
      );
      const map = new Map();
      reviewedStatuses.forEach((reviewedStatus) => {
        const { studySmid, studyDescription, studyDate } = reviewedStatus;
        const existing = map.get(studySmid);
        map.set(studySmid, {
          smid: studySmid,
          // don't overwrite existing descriptions and dates if defined
          description: studyDescription ?? existing?.description,
          studyDate: studyDate ?? existing?.studyDate,
        });
      });
      return map;
    },
});

export const useIsStackReviewed = (stack: ?Stack): boolean => {
  const caseSmid = useCurrentCaseId();
  const stackSeriesSmids = useMemo(
    () => [...new Set(stack?.frames?.map((frame) => frame.series.smid) ?? [])],
    [stack]
  );
  return useRecoilValue(isStackReviewed({ caseSmid, stackSeriesSmids }));
};

export const useReviewedStudySmids = (study: ?Study): Set<string> => {
  const caseSmid = useCurrentCaseId();
  return useRecoilValue(reviewedStudySmids(caseSmid));
};

export const useReviewedStudiesByDate = (): Map<string, ReviewedStudyByDate> => {
  const caseSmid = useCurrentCaseId();
  const { mainStudiesIds } = useBaseViewerData();
  return useRecoilValue(reviewedStudiesByDateMap({ caseSmid, currentStudySmids: mainStudiesIds }));
};

export const useResetReviewedStudiesByDate = (): (() => void) => {
  const caseSmid = useCurrentCaseId();

  const resetReviewedStatuses = useResetRecoilState(caseReviewedStatuses(caseSmid ?? ''));
  return () => {
    resetReviewedStatuses();
  };
};

export function useTrackViewedSlices(): void {
  const viewType = useViewType();
  const viewportId = useViewportId();
  const { imagingProvider } = useImagingContext();
  const caseSmid = useCurrentCaseId();
  const { studies } = useStudies();

  const updateReviewedSeries = useRecoilCallback(
    ({ set }) =>
      (newSeriesSmids: Set<string>) => {
        if (caseSmid != null) {
          set(caseReviewedStatuses(caseSmid), (reviewedSeries) => {
            const newReviewedSeries = [];
            newSeriesSmids.forEach((newSeriesSmid) => {
              // for every series that is sufficiently reviewed in a scroll operation,
              // mark it as reviewed only if it hasn't already
              if (reviewedSeries.every((rs) => rs.seriesSmid !== newSeriesSmid)) {
                // for multi-layer stacks, the layer's series might one day come from
                // a different study than the current viewport's base series' study,
                // so we should do a search across all studies
                const study = studies.find((study) =>
                  study.seriesList.some((studySeries) => studySeries.smid === newSeriesSmid)
                );

                if (study == null) {
                  return;
                }

                newReviewedSeries.push({
                  seriesSmid: newSeriesSmid,
                  studySmid: study.smid,
                  studyDate: study.studyDate,
                  studyDescription: study.description,
                  threshold: REVIEWED_THRESHOLD,
                });
              }
            });

            if (newReviewedSeries.length === 0) {
              // all reviewed series are already marked, so don't change anything
              return reviewedSeries;
            }

            return [...reviewedSeries, ...newReviewedSeries];
          });
        }
      },
    [caseSmid, studies]
  );

  useEffect(() => {
    if (imagingProvider == null) return;

    const handleActiveSliceChanged = () => {
      const slice = imagingProvider.getActiveSlice(viewType, viewportId);

      const seriesWithThresholdsReached = imagingProvider.markSliceViewed(viewType, slice);
      updateReviewedSeries(seriesWithThresholdsReached);
    };

    // call with current active slice when initialized
    handleActiveSliceChanged();

    imagingProvider.emitter.on(
      createActiveSliceChangedEmitterEvent(viewType),
      handleActiveSliceChanged
    );

    return () => {
      imagingProvider.emitter.off(
        createActiveSliceChangedEmitterEvent(viewType),
        handleActiveSliceChanged
      );
    };
  }, [viewType, viewportId, imagingProvider, updateReviewedSeries]);
}
