// @flow

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import type { ParagraphPluginElement, ParagraphPluginPropertyOptions } from '../types';
import { PARAGRAPH_LIST_ITEM_VARIANT, PARAGRAPH_PLUGIN_ID } from '../types';
import styled, { css } from 'styled-components';
import type { StyledComponent } from 'styled-components';
import { useCurrentUser } from 'hooks/useCurrentUser';
import type { Styles } from 'generated/graphql';
import { useReporterStyles } from 'hooks/useReporterStyles';

type ParagraphWrapperProps = {
  shouldForceInline?: boolean,
  listItemVariant?: boolean,
  children: React$Node,
};

export const ParagraphWrapper = ({
  children,
  shouldForceInline = false,
  listItemVariant = false,
}: ParagraphWrapperProps): React$Node => {
  const { data } = useCurrentUser();
  const reporterSettings = data?.me?.reporterSettings;

  const style: Styles = useReporterStyles(reporterSettings);

  const paragraphCss = css`
    margin: 0;
    display: ${listItemVariant ? undefined : shouldForceInline === true ? 'inline' : 'block'};
    text-transform: ${style?.bodyStyle?.textTransform};
    font-size: ${style?.bodyStyle?.fontSize}px;
    font-family: ${style?.bodyStyle?.fontFamily};
    font-weight: ${style?.bodyStyle?.textDecoration?.isBold === true ? 'bold' : 'normal'};
    font-style: ${style?.bodyStyle?.textDecoration?.isItalic === true ? 'italic' : 'normal'};
    text-decoration: ${style?.bodyStyle?.textDecoration?.isUnderline === true
      ? 'underline'
      : 'none'};
  `;

  if (listItemVariant === true) {
    return <li css={paragraphCss}>{children}</li>;
  }

  /**
   * Using a <div> instead of a <p> to allow nested UGC:
   * https://sironamedical.atlassian.net/browse/RP-1622
   */
  return (
    <div data-element-type={PARAGRAPH_PLUGIN_ID} role="paragraph" css={paragraphCss}>
      {children}
    </div>
  );
};

export const ParagraphEditable: GetRenderElementComponentType<
  ParagraphPluginPropertyOptions,
  ParagraphPluginElement,
> = ({ element, children, ...rest }): React$Node => (
  <ParagraphWrapper
    listItemVariant={element.variant === PARAGRAPH_LIST_ITEM_VARIANT}
    shouldForceInline={element.shouldForceInline}
  >
    {children}
  </ParagraphWrapper>
);
export const ParagraphRenderer: StyledComponent<{ ... }, { ... }, typeof ParagraphEditable> =
  styled(ParagraphEditable)`
    white-space: pre-wrap;
  `;
