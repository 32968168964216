// @flow

import type { CreateOnKeyDown } from '../../types';
import type { MacroPlaceholderPluginPropertyOptions } from './types';
import { onKeyDownInlineTemplate } from '../common/inlineTemplate';

export const onKeyDownMacroPlaceholder: CreateOnKeyDown<MacroPlaceholderPluginPropertyOptions> =
  ({ pluginID }) =>
  (e, { editor, nodeState }) => {
    return onKeyDownInlineTemplate({ pluginID })(e, { editor, nodeState });
  };
