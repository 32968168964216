// @flow

import type { Fragment } from '../types/content';
import type { NodeType } from 'slate';

import { Text, Node } from '../core';

export const fragmentToString = (fragment: NodeType[] | Fragment): string => {
  return fragment
    .map((node) => {
      if (Text.isText(node)) {
        return node.text;
      } else {
        // $FlowIgnore[incompatible-use] we check the type above
        return node.children.map(Node.string).join('');
      }
    })
    .join(' ');
};
