// @flow
import type { EditorType, LocationType } from 'slate';
import { Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

// When focus is called in certain contexts, it does focus the editor but another action de-focuses it.
// For example, clicking a button in a modal the editor will not actually be focused in the end
// Add a 0 delay timeout to ensure the editor is ends up focused
export const focusEditor = (editor: null | EditorType) => {
  if (editor != null) {
    setTimeout(() => {
      // $FlowIgnore[prop-missing] ReactEditor vs EditorType
      ReactEditor.focus(editor);
    }, 0);
  }
};

// Same as focusEditor but also selects a path if you need to change the
// selection. If you try to do the select outside of the timeout, you will end
// up with an odd editor state where it is focused, and visually the selection
// is in the correct place, but the first character you type will be in the old
// selection location.
export const selectAndFocusEditor = (editor: null | EditorType, path: LocationType) => {
  if (editor != null) {
    setTimeout(() => {
      // $FlowIgnore[prop-missing] ReactEditor vs EditorType
      ReactEditor.focus(editor);
      Transforms.select(editor, path);
    }, 0);
  }
};
