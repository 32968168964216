// @flow

import { Editor, Node } from '../core';
import type { EditorType, NodeEntry } from '../core';
import { getNavigationNodeTypes } from '../constants';
import type { NodeType } from 'slate';

export const getLowestBracket = (
  editor: EditorType,
  {
    reverse,
    textEmpty,
    ignoreMergeFields,
  }: { reverse?: boolean, textEmpty?: boolean, ignoreMergeFields?: boolean } = {
    reverse: false,
    textEmpty: false,
    ignoreMergeFields: false,
  }
): ?NodeEntry<> => {
  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFields);

  const nodeEntries = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) =>
      navigationNodeTypes.includes(n.type) && (textEmpty !== true || Node.string(n) === ''),
    mode: 'lowest',
    reverse,
  });

  return nodeEntries.next().value;
};
