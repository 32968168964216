// @flow

import { useRef, useEffect } from 'react';
import { Editor, Range } from 'slate';
import type { RangeRefType } from 'slate';
import { useSlateSingletonContext } from '../../SlateSingletonContext';
import { useRecorder } from 'common/Recorder/useRecorder/useRecorder';
import { useRecoilValue } from 'recoil';
import { RICH_TEXT_EDITOR, mostRecentInputStateFamily } from 'hooks/useMostRecentInput';
import { logger } from 'modules/logger';

type RecorderToTimeSelectionMap = { [id: string]: Map<number, RangeRefType> };
export type RecorderToTimeSelectionMapRef = { current: RecorderToTimeSelectionMap };

/**
 * Tracks the user's selection in the editor while recording.
 *
 * @returns {RecorderToTimeSelectionMapRef} For each recorder being processed, provides a map of duration of recording (in ms) to a ref of the selection at that point in time.
 */
export const useSelectionTracking = (): RecorderToTimeSelectionMapRef => {
  const recorderToTimeSelectionMap = useRef<RecorderToTimeSelectionMap>({});

  const [{ editor }] = useSlateSingletonContext();
  const { isRecording, getRecorder } = useRecorder();
  const { isMousePressed } = useRecoilValue(mostRecentInputStateFamily(RICH_TEXT_EDITOR));

  // Create a new time:selection entry each time the user changes selection while dictating.
  useEffect(() => {
    if (isRecording && !isMousePressed) {
      if (editor?.selection == null) {
        logger.error('[useSelectionTracking] editor.selection is null. Cannot track selection.');
        return;
      }

      const recorder = getRecorder();

      if (recorder == null) {
        logger.error(
          '[useSelectionTracking] recording, but recorder is null. This should never happen.'
        );
        return;
      }

      const id = recorder.id;
      const timeToSelectionMap = recorderToTimeSelectionMap.current[id];

      if (timeToSelectionMap == null) {
        const newTimeToSelectionMap = new Map<number, RangeRefType>();

        newTimeToSelectionMap.set(
          recorder.getDurationMillis(),
          // $FlowIgnore[incompatible-call] - we check above to make sure selection is not null
          Editor.rangeRef(editor, editor.selection, { affinity: 'inward' })
        );

        logger.info('[useSelectionTracking] added new timeToSelectionMap for recorder', {
          id,
          duration: recorder.getDurationMillis(),
          selection: editor.selection,
        });
        recorderToTimeSelectionMap.current = {
          ...recorderToTimeSelectionMap.current,
          [id]: newTimeToSelectionMap,
        };
        return;
      }

      const trackedSelections = Array.from(timeToSelectionMap.values());
      const lastTrackedSelection = trackedSelections[trackedSelections.length - 1].current;
      const isNewSelection =
        // $FlowIgnore[incompatible-call] - we check above to make sure it's not null
        lastTrackedSelection != null && !Range.equals(lastTrackedSelection, editor.selection);

      if (isNewSelection) {
        logger.info('[useSelectionTracking] added new entry in timeToSelectionMap for recorder', {
          id,
          duration: recorder.getDurationMillis(),
          selection: editor.selection,
        });
        timeToSelectionMap.set(
          recorder.getDurationMillis(),
          // $FlowIgnore[incompatible-call] - we check above to make sure selection is not null
          Editor.rangeRef(editor, editor.selection, { affinity: 'inward' })
        );
      }
    }
  }, [editor, getRecorder, isMousePressed, isRecording]);

  return recorderToTimeSelectionMap;
};
