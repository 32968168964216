// @flow

import type { CreateEnhanceEditorState } from '../../types/plugin';
import type { LineBreakPluginPropertyOptions } from './types';
import type { NodeType, TextUnit } from '../../core';
import { Text, Editor, Range, Transforms, Node } from '../../core';
import { find } from '../../utils/find';
import { isSelectionInLineBreak } from './utils';
import { BRACKET_TYPES } from '../../constants';
import type { EditorType, ElementType, NodeEntry, OperationType } from 'slate';

export const enhanceEditorStateLineBreak: CreateEnhanceEditorState<
  LineBreakPluginPropertyOptions,
> =
  ({ pluginID }) =>
  (editor: EditorType) => {
    const { apply, isInline, deleteBackward, deleteForward, normalizeNode } = editor;

    editor.apply = (op: OperationType) => {
      apply(op);

      // If the selection is inside a line break, then the editor is in an
      // invalid state. We do this check after apply has been called, as even operations
      // like removing and inserting nodes can inadvertently move our selection.
      // We have to move the selection to the next text node. This is
      // guaranteed to exist because slate always has a starting and ending text node
      // after an inline node for selection purposes.
      let selection = null;
      if (op.type === 'set_selection' && op.newProperties != null) {
        const { anchor, focus } = op.newProperties;
        selection = anchor != null && focus != null ? { anchor, focus } : null;
      } else {
        selection = editor.selection;
      }

      if (selection != null && isSelectionInLineBreak(editor, selection)) {
        const nextNode = Editor.next(editor, {
          at: selection,
          match: (n: NodeType) => Text.isText(n),
          voids: false,
        });

        if (nextNode != null) {
          Transforms.select(editor, Editor.start(editor, nextNode[1]));
          return;
        }
      }
    };

    // Since this node will only ever live inside a field, it is an inline node.
    editor.isInline = (element: ElementType) => {
      return element.type === pluginID ? true : isInline(element);
    };

    /** If the user is at the right outside edge of a LineBreak node, and deletes backwards,
     * delete the node
     *
     * Before Pressing Delete Key:                             After Pressing Delete Key:
     * ------------------------------------------------------  ------------------------------------------------------
     * <editor>                                                 <editor>
     *   <hp>                                                     <hp>
     *     <hinlinebookmark>                                        <hinlinebookmark>
     *        <htext>hello<htext>                                     <htext>hello<cursor/></htext>
     *        <hlinebreak>                                            <htext>goodbye</htext>
     *          <htext/>                                            </hinlinebookmark>
     *        </hlinebreak>                                       </hp>
     *        <htext><cursor/>goodbye</htext>                   </editor>
     *     </hinlinebookmark>
     *   </hp>
     * </editor>
     *
     **/
    editor.deleteBackward = (unit: TextUnit) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const matchEntryBefore = find(editor, (n: NodeType) => n.type === pluginID, {
          at: Editor.before(editor, selection),
        });

        if (matchEntryBefore != null) {
          const targetPoint = Editor.before(editor, matchEntryBefore[1]);

          Transforms.removeNodes(editor, { at: matchEntryBefore[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteBackward(unit);
    };

    /** If the user is at the left outside edge of a LineBreak node, and deletes forwards,
     * delete the node
     *
     * Before Pressing Delete Key:                             After Pressing Delete Key:
     * ------------------------------------------------------  ------------------------------------------------------
     * <editor>                                                 <editor>
     *   <hp>                                                     <hp>
     *     <hinlinebookmark>                                        <hinlinebookmark>
     *        <htext>hello<cursor/><htext>                            <htext>hello</htext>
     *        <hlinebreak>                                            <htext><cursor/>goodbye</htext>
     *          <htext/>                                            </hinlinebookmark>
     *        </hlinebreak>                                         </hp>
     *        <htext><cursor/>goodbye</htext>                      </editor>
     *     </hinlinebookmark>
     *   </hp>
     * </editor>
     *
     **/
    editor.deleteForward = (unit: TextUnit) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        const matchEntryAfter = find(editor, (n: NodeType) => n.type === pluginID, {
          at: Editor.after(editor, selection),
        });

        if (matchEntryAfter != null) {
          const targetPoint = Editor.before(editor, matchEntryAfter[1]);

          Transforms.removeNodes(editor, { at: matchEntryAfter[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteForward(unit);
    };

    editor.normalizeNode = (entry: NodeEntry<>) => {
      const [node, path] = entry;
      if (node.type === pluginID) {
        // If the line break has any non-empty text children, we move it to the next node
        if (
          Array.isArray(node.children) &&
          (node.children.some((child) => !Text.isText(child)) || Node.string(node) !== '')
        ) {
          Editor.withoutNormalizing(editor, () => {
            const nodes = node.children;
            const edges = Editor.edges(editor, path);
            Transforms.insertText(editor, '', {
              at: { anchor: edges[0], focus: edges[1] },
            });

            const pointAfter = Editor.after(editor, path);
            Transforms.insertNodes(editor, nodes, { at: pointAfter ?? Editor.end(editor, []) });
            return;
          });
        }

        // If the line break's parent is not a bracket type, we remove it and split the parent.
        // We do this because line breaks can only exist inside a bracket.
        const parent = Editor.parent(editor, path);
        if (!Editor.isEditor(parent) && !BRACKET_TYPES.includes(parent[0].type)) {
          Transforms.removeNodes(editor, { at: path });
          Transforms.splitNodes(editor, { at: path });

          return;
        }
      }

      normalizeNode(entry);
    };

    return editor;
  };
