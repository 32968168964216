// @flow
import type { StableTextResponse } from '../../ASRPlex/ASRPlexProtocol';
import type { NvoqMessage } from '../createNvoqWebsocketFactory';

export const createSyntheticSubstitutions = (msg: NvoqMessage): NvoqMessage => {
  const testString = msg.data.text.trim().toLowerCase();

  // Do not do any replace logic if the message contains a substitution
  if (testString.includes('<srna>')) return msg;

  /**
   * Use the code below to mock substitutions coming from nVoq. Useful if you are developing something
   * before the backend team has put them in.
   */
  return msg;

  // const newTextFragment = pipe(
  //   replace(
  //     /(file debug ticket)/g,
  //     buildSubstitutionString({
  //       action: 'FileDebugTicket',
  //     })
  //   ),
  // )(testString);

  // return testString === newTextFragment
  //   ? msg
  //   : {
  //       ...msg,
  //       data: { ...msg.data, text: newTextFragment, substitutedText: newTextFragment, markers: [] },
  //     };
};

export const createSyntheticSubstitutionsForMarkers = (
  msg: StableTextResponse
): StableTextResponse => {
  const testString = msg.payload.text.trim().toLowerCase();

  // Do not do any replace logic if the message contains a substitution
  if (testString.includes('<srna>')) return msg;

  /**
   * Use the code below to mock substitutions coming from nVoq. Useful if you are developing something
   * before the backend team has put them in.
   */
  return msg;

  // const newTextFragment = pipe(
  //   replace(
  //     /(file debug ticket)/g,
  //     buildSubstitutionString({
  //       action: 'FileDebugTicket',
  //     })
  //   ),
  // )(testString);

  // return testString === newTextFragment
  //   ? msg
  //   : {
  //       ...msg,
  //       data: { ...msg.data, text: newTextFragment, substitutedText: newTextFragment, markers: [] },
  //     };
};
