// @flow

import type { PickerEvent } from '../modules/usePicker';
import { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import { MOUSE_BUTTON } from '../constants';
import { useMouseWorldPosition } from '../modules/useMouseWorldPosition';
import { useEventAction } from '../modules/usePicker';
import { useKeyPressEvent } from 'react-use';
import { useAnnotationReactiveRender } from '../modules/useAnnotationReactiveRender';
import { quickVec3Comparison } from '../utils/math';
import { usePlaceSegmentOnPlane } from '../Annotations/helpers/usePlaceSegmentOnPlane';
import { nanoid } from 'nanoid';
import { NAMESPACES, sendEvent } from 'modules/EventsManager';
import type { Dispatch, SetStateAction } from 'types/react';
import { useAnnotationsRenderBatcher } from '../Annotations/AnnotationsRenderBatcher';
import { useImagingContext } from '../modules/imaging/ImagingContext';
import { useViewType } from '../modules/state';
import { createActiveSliceChangedEmitterEvent } from '../modules/imaging/BaseImagingProvider';

export function makeSegmentsFromPoints(points: $ReadOnlyArray<vec3>): $ReadOnlyArray<[vec3, vec3]> {
  return points.reduce((segments, point, index) => {
    const nextPoint = points[index + 1];
    if (nextPoint == null) {
      return segments;
    }

    return [...segments, [point, points[index + 1]]];
  }, []);
}

export function useMouseDefinedPoints({
  minPoints = 0,
  maxPoints = Infinity,
  onComplete,
  onCancel,
  initialPoints = [],
}: {
  minPoints: number | ((points: $ReadOnlyArray<vec3>) => number),
  maxPoints: number | ((points: $ReadOnlyArray<vec3>) => number),
  onComplete?: (segments: $ReadOnlyArray<vec3>) => Promise<mixed> | void,
  onCancel?: () => mixed,
  initialPoints?: $ReadOnlyArray<vec3>,
}): [$ReadOnlyArray<vec3>, Dispatch<SetStateAction<$ReadOnlyArray<vec3>>>] {
  const getMaxPoints = useCallback(
    (points) => (typeof maxPoints === 'function' ? maxPoints(points) : maxPoints),
    [maxPoints]
  );

  const [points, setPoints] = useState<$ReadOnlyArray<vec3>>(initialPoints);
  const mousePosition = useMouseWorldPosition();

  const isMouseDown = useRef(false);

  const [segmentId, setSegmentId] = useState(nanoid());
  const [detail1SegmentId, setDetail1Id] = useState('');

  const addPoint = useCallback(
    async ({
      id,
      button,
      originalEvent,
    }: {
      button: void | number,
      id: ?string,
      originalEvent: SyntheticMouseEvent<Element>,
    }) => {
      // Tools can only be used with the left mouse button
      if (button !== MOUSE_BUTTON.LEFT) return;
      // We can't proceed if the mouse position is not known yet.
      if (mousePosition == null) return;

      // handle double-click management
      if (originalEvent?.detail === 1) {
        setDetail1Id(segmentId);
      } else if (originalEvent?.detail === 2) {
        isMouseDown.current = false;
        // ending segment was double-clicked, ignore this click
        if (detail1SegmentId !== segmentId) {
          setSegmentId(nanoid());
          return;
        } else {
          if (points.length <= 1) {
            /* Only cancel the annotation and expand viewport if 0-1 segments into the annotation.
             * Most tools have only 2 segments, so the tool will complete on the first click.
             * For others like angle with 3 segments, the first click will register as a segment like normal,
             * and the second part of the double click is instead thrown out completely.
             */
            setPoints([]);
            setSegmentId(nanoid());
            sendExpandViewportEvent();
          }
          return;
        }
      }

      // If the point matches the last point, it's a click.
      const lastPoint = points.slice(-1)[0];
      if (lastPoint != null && quickVec3Comparison(lastPoint, mousePosition)) {
        return;
      }

      // If the first point is over an existing primitive we don't want to
      // create a new annotation, the handle action will take precedence.
      if (id != null && points.length === 0) return;

      let updatedPoints = [...points];
      if (points.length < getMaxPoints(updatedPoints)) {
        updatedPoints = [...updatedPoints, mousePosition];
      }

      if (updatedPoints.length === getMaxPoints(updatedPoints)) {
        // Setting this to false will make the subsequent mouse up event
        // not trigger the addPoint function.
        isMouseDown.current = false;

        // If we've reached the maximum number of points, we can send the
        // data to the callback.
        const completePromise = onComplete?.(updatedPoints);

        if (completePromise == null) {
          setPoints([]);
          setSegmentId(nanoid());
        } else {
          // Prevent the tool from drawing a new line before complete finishes
          setPoints(updatedPoints);

          completePromise.then(() => {
            setPoints([]);
            setSegmentId(nanoid());
          });
        }
      } else if (updatedPoints.length !== points.length) {
        setPoints(updatedPoints);
      }
    },
    [points, mousePosition, getMaxPoints, onComplete, segmentId, detail1SegmentId]
  );

  const handleMouseDown = useCallback(
    ({ id, button, originalEvent }: PickerEvent) => {
      isMouseDown.current = true;
      addPoint({ id, button, originalEvent });
    },
    [addPoint]
  );
  const handleMouseUp = useCallback(
    ({ id, button, originalEvent }: PickerEvent) => {
      // Ignore mouseUp events if the mouse is not down.
      if (isMouseDown.current === false) return;

      isMouseDown.current = false;
      addPoint({ id, button, originalEvent });
    },
    [addPoint]
  );
  useEventAction('mouseDown', handleMouseDown);
  useEventAction('mouseUp', handleMouseUp);

  const requestRender = useAnnotationsRenderBatcher();
  // Cancel the annotation if the Esc key is pressed
  useKeyPressEvent('Escape', () => {
    isMouseDown.current = false;
    setPoints([]);
    onCancel?.();
    requestRender();
  });

  useAnnotationReactiveRender(points.length);

  return [points, setPoints];
}

export function useMouseDefinedSegments(
  minPoints: number | ((points: $ReadOnlyArray<vec3>) => number) = 0,
  maxPoints: number | ((points: $ReadOnlyArray<vec3>) => number) = Infinity,
  onComplete: (segments: $ReadOnlyArray<[vec3, vec3]>) => Promise<mixed> | void,
  onCancel?: () => mixed,
  initialPoints?: $ReadOnlyArray<vec3> = []
): [?$ReadOnlyArray<[vec3, vec3]>, () => void] {
  const getMinPoints = useCallback(
    (points) => (typeof minPoints === 'function' ? minPoints(points) : minPoints),
    [minPoints]
  );

  const [points, setPoints] = useMouseDefinedPoints({
    minPoints,
    maxPoints,
    onComplete: (points) => onComplete(makeSegmentsFromPoints(points)),
    onCancel,
    initialPoints,
  });
  const placeSegmentOnPlane = usePlaceSegmentOnPlane();
  const mousePosition = useMouseWorldPosition();
  const viewType = useViewType();
  const { imagingProvider } = useImagingContext();

  // we use the placeSegmentOnPlane utility to make sure that the
  // world space points are placed on the plane where VTK has placed the slice
  const segments = useMemo(
    // If we don't have enough points we are going to return `null`.
    () =>
      points.length + 1 < getMinPoints(points) || mousePosition == null
        ? null
        : placeSegmentOnPlane([...makeSegmentsFromPoints([...points, mousePosition])]),
    [points, getMinPoints, mousePosition, placeSegmentOnPlane]
  );

  const resetSegments = useCallback(() => {
    setPoints([]);
  }, [setPoints]);

  useEffect(() => {
    if (segments && segments.length > 0 && imagingProvider?.emitter) {
      // Want to immediately reset and not on a debounced event that might fire on stable frame
      const event = createActiveSliceChangedEmitterEvent(viewType);
      imagingProvider.emitter.on(event, resetSegments);

      return () => {
        imagingProvider.emitter.off(event, resetSegments);
      };
    }
  }, [imagingProvider?.emitter, viewType, resetSegments, segments]);

  return [segments, resetSegments];
}

export const sendExpandViewportEvent = () => {
  sendEvent(NAMESPACES.DRE, { type: 'expand' });
};

export const useExpandViewportDoubleClickAction = () => {
  useEventAction('doubleClick', sendExpandViewportEvent);
};
