// @flow

import { withInlineTemplate } from '../common/inlineTemplate';
import type { CreateEnhanceEditorState } from '../../types';
import { walkSlateContent, createEditorApplyOverrideNotification } from '../../utils';
import { clone } from 'ramda';
import { insertPicklist } from './utils';
import { PICKLIST_PLUGIN_ID } from './types';
import type { PicklistPluginElement, PicklistPluginPropertyOptions } from './types';
import { Editor } from '../../core';
import type { NodeType } from '../../core';
import type { OperationType } from 'slate';

export const enhanceEditorStatePicklist: CreateEnhanceEditorState<PicklistPluginPropertyOptions> =
  (options) => (editor) => {
    const { apply, insertFragment, deleteFragment } = editor;

    editor.apply = (operation: OperationType) => {
      // If any node is set to be a picklist give it an ID and call insert picklist
      if (operation?.type === 'set_node' && operation.newProperties.type === options.pluginID) {
        createEditorApplyOverrideNotification(options.pluginID, operation);

        const { children, ...picklistProps } = insertPicklist();
        operation.newProperties = picklistProps;
        options.onPicklistInsert?.(operation.newProperties);
      }

      apply(operation);
    };

    // If we are pasting check the fragment for picklists
    // If we are pasting a picklist then generate it a new ID and
    // clone the options
    editor.insertFragment = (fragment: Array<NodeType>) => {
      const newFragment = clone(fragment);

      // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
      const onElement = (node: any) => {
        if (node.type === options.pluginID) {
          const newPicklist = options.onPicklistCopy?.(node);

          if (newPicklist) {
            node.picklistID = newPicklist.id;
          }
        }
      };

      walkSlateContent(onElement)(newFragment);

      return insertFragment(newFragment);
    };

    editor.deleteFragment = () => {
      if (editor.selection && options.onPicklistRemove) {
        const picklists = Editor.nodes(editor, {
          at: editor.selection,
          match: (n: NodeType) => n.type === PICKLIST_PLUGIN_ID,
          mode: 'all',
        });
        Array.from(picklists).forEach((picklistNode) => {
          options.onPicklistRemove?.(picklistNode[0]);
        });
      }
      return deleteFragment();
    };

    return withInlineTemplate<PicklistPluginElement>({
      pluginID: options.pluginID,
      createNode: insertPicklist,
      onNodeCreate: options.onPicklistInsert,
      onNodeRemove: options.onPicklistRemove,
    })(editor);
  };
