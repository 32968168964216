// @flow

import MenuItem from '@material-ui/core/MenuItem';
import { Colors } from 'styles';
import { useEditor } from '../../../core';
import type { PlaceholderPluginPropertyOptions } from '../types';
import { transparentize } from 'color2k';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';

type PlaceholderDropdownProps = $ReadOnly<{
  ...$Diff<PlaceholderPluginPropertyOptions, { component: mixed }>,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  instance: any,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  attrs: any,
}>;

export const PlaceholderDropdown = ({
  instance,
  attrs,
  pluginID,
  onPlaceholderMenuClick,
  placeholderFields,
  ...rest
}: PlaceholderDropdownProps): React$Node => {
  const editor = useEditor();
  const { currentWorklistItems } = useCurrentWorklistItems();

  return (
    <div
      {...attrs}
      css={`
        background-color: ${Colors.gray3};
        overflow-y: auto;
        box-shadow: 0 0.5rem 1rem ${transparentize(Colors.gray1, 0.5)};

        .MuiTouchRipple-root {
          display: none;
        }
      `}
    >
      {placeholderFields.map((placeholderField) => (
        <MenuItem
          key={placeholderField.key}
          onMouseDown={(e) => {
            e.preventDefault();
            const [item] = currentWorklistItems;
            analytics.track(reporter.usr.insertPlaceholder, {
              smid: item?.smid || '',
              placeholder: {
                name: placeholderField.name,
                key: placeholderField.key,
              },
            });
            onPlaceholderMenuClick({
              editor,
              placeholderField,
              pluginID,
              instance,
              currentWorklistItems,
            });
          }}
        >
          {placeholderField.name}
        </MenuItem>
      ))}
    </div>
  );
};
