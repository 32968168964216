// @flow

import { Colors } from 'styles';
import { Stack } from 'common/ui/Layout';
import { ToolbarButton } from 'common/ui/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tooltip from 'common/ui/Tooltip';
import { transparentize } from 'color2k';
import { useListInteraction } from '../hooks/useListInteraction';
import type { ToolbarButtonConfig } from '../hooks/useListInteraction';

/**
 * Expanded Mode
 * All toolbar buttons are visible
 */
export const ListToolbarExpanded = (): React$Node => {
  const { toolbarButtonConfigs } = useListInteraction();

  return (
    <Stack alignY="center">
      {toolbarButtonConfigs.map((config, idx) => {
        return (
          <Tooltip
            key={idx}
            content={
              <Stack vertical>
                <Typography
                  variant="inherit"
                  css={`
                    color: ${Colors.gray1};
                  `}
                  align="center"
                >
                  {config.label}
                </Typography>
                <Typography
                  variant="inherit"
                  css={`
                    color: ${Colors.gray5};
                  `}
                  align="center"
                >
                  {config.secondaryLabel}
                </Typography>
              </Stack>
            }
            backgroundColor={Colors.gray7}
          >
            <ToolbarButton
              aria-label={config.label}
              onMouseDown={(e) => {
                e.preventDefault();
                config.onMouseDown();
              }}
              selected={config.selected}
            >
              {config.icon}
            </ToolbarButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

/**
 * Stacked Mode
 * All toolbar buttons are visible once the List Toolbar Button is clicked and expanded
 */
export const ListToolbarStacked = (): React$Node => {
  const { toolbarButtonConfigs, toolbarDividerIndex } = useListInteraction();

  // list variant button configs
  const preDividerToolbarButtonConfigs = toolbarButtonConfigs.slice(0, toolbarDividerIndex);
  // indent button configs
  const postDividerToolbarButtonConfigs = toolbarButtonConfigs.slice(toolbarDividerIndex);

  return (
    <div
      css={`
        background-color: ${Colors.gray3};
        overflow-y: auto;
        box-shadow: 0 0.5rem 1rem ${transparentize(Colors.gray1, 0.5)};

        .MuiTouchRipple-root {
          display: none;
        }
      `}
    >
      <List>
        {preDividerToolbarButtonConfigs.map((config, idx) => (
          <ListToolbarStackedButton key={idx} config={config} />
        ))}
      </List>
      <Divider />
      <List>
        {postDividerToolbarButtonConfigs.map((config, idx) => (
          <ListToolbarStackedButton key={idx} config={config} />
        ))}
      </List>
    </div>
  );
};

const ListToolbarStackedButton = ({ config }: { config: ToolbarButtonConfig }): React$Node => (
  <ListItem
    button
    role="button"
    aria-label={config.label}
    selected={config.selected}
    onMouseDown={(e) => {
      e.preventDefault();
      config.onMouseDown();
    }}
    css="width: 340px;"
  >
    <ListItemIcon>{config.icon}</ListItemIcon>
    <ListItemText primary={config.label} />
    <ListItemText css="left: 220px; position: absolute;" secondary={config.secondaryLabel} />
  </ListItem>
);
