// @flow
import { useCallback, useState } from 'react';
// $FlowIgnore[untyped-import] - the types exist... but not for flow
import { useTrackIdleTimeGlobally } from '@sironamedical/common/hooks/useTrackIdleTimeGlobally';
// $FlowIgnore[untyped-import] - the types exist... but not for flow
import { Countdown } from '@sironamedical/common/components/Countdown';
import GenericSnackBar from './GenericSnackBar/GenericSnackBar';
import { useCurrentUser } from 'hooks/useCurrentUser/suspense';
import { useRecoilValue } from 'recoil';
import { recorderState } from './Recorder/useRecorder/state';
import useAuth from 'hooks/useAuth';
import { css } from 'styled-components';
import { Colors } from 'styles';

const ONE_MINUTE_IN_MS = 1000 * 60;

export const InactivityTracking = ({
  message = 'You will be logged out due to inactivity in:',
}: {
  message?: string,
}): React$Node => {
  const isRecording = useRecoilValue(recorderState);
  const [sessionEndTime, setSessionEndTime] = useState<number | null>(null);
  const { logout } = useAuth();
  const data = useCurrentUser();
  const configuredTimeoutInMinutes = data?.me?.sessionTimeoutInMinutes;
  const idleTimeoutInMs = configuredTimeoutInMinutes * ONE_MINUTE_IN_MS;
  const isPaused =
    configuredTimeoutInMinutes == null || isRecording || idleTimeoutInMs === 0 || !idleTimeoutInMs;
  const promptTimeoutInMs =
    idleTimeoutInMs > ONE_MINUTE_IN_MS ? ONE_MINUTE_IN_MS : ONE_MINUTE_IN_MS / 2;

  const onIdle = useCallback(() => {
    logout();
  }, [logout]);

  const onPrompt = useCallback(() => {
    setSessionEndTime(Date.now() + promptTimeoutInMs);
  }, [promptTimeoutInMs]);

  const onActive = useCallback(() => {
    setSessionEndTime(null);
  }, []);

  useTrackIdleTimeGlobally({
    idleTimeoutInMs: isPaused ? null : idleTimeoutInMs,
    promptTimeoutInMs: isPaused ? 0 : promptTimeoutInMs,
    onIdle,
    onPrompt,
    onActive,
    pause: isPaused,
  });

  return (
    <GenericSnackBar
      open={sessionEndTime !== null}
      severity="default"
      horizontal="left"
      msg={
        <p
          css={css`
            text-align: center;
            font-size: 2rem;
            .countdown {
              font-weight: bold;
            }
            .countdown--normal {
              color: ${Colors.yellow1};
            }
            .countdown--expired,
            .countdown--warning {
              color: ${Colors.red3};
            }
          `}
        >
          {message}{' '}
          <span>
            <Countdown endTimeInMs={sessionEndTime} />
          </span>
        </p>
      }
      data-testid="inactivity-tracking-snackbar"
    />
  );
};
