// @flow

import type { EditorPlugin } from '../../types';
import { MacroPlaceholderEditable } from './components';
import { getRenderElement } from '../../utils';
import { MACRO_PLACEHOLDER_PLUGIN_ID } from './types';
import type { MacroPlaceholderPluginOptions, MacroPlaceholderPluginPropertyOptions } from './types';
import { enhanceEditorStateMacroPlaceholder } from './enhanceEditorStateMacroPlaceholder';
import { onKeyDownMacroPlaceholder } from './onKeyDownMacroPlaceholder';

export const MacroPlaceholderPlugin = (
  {
    component = MacroPlaceholderEditable,
    pluginName = 'Macro Placeholder',
    ...rest
  }: MacroPlaceholderPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: MacroPlaceholderPluginPropertyOptions = {
    pluginID: MACRO_PLACEHOLDER_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement<MacroPlaceholderPluginPropertyOptions>(options, {
      spreadAttributes: false,
    }),
    enhanceEditorState: enhanceEditorStateMacroPlaceholder(options),
    onKeyDown: onKeyDownMacroPlaceholder(options),
  };
};
