// @flow
import type { CreateOnKeyDown } from '../../types';
import isHotkey from 'is-hotkey';
import type { HeadingPluginPropertyOptions } from './types';
import { DEFAULT_H1_HOTKEY, DEFAULT_H2_HOTKEY } from '.';
import { toggleHeading } from '../heading/utils/normalization';
import { HeadingLevel } from './constants';
import { createParagraph } from '../paragraph/utils';
import { Transforms, Range, Editor } from '../../core';
import { clearMarksForSelection } from 'domains/reporter/RichTextEditor/utils';
import { isHeadingNode } from './utils/normalization';
import { logger } from 'modules/logger';
import {
  getHeadingIfCursorRightAtEnd,
  isLastCharacterAColon,
} from '../../stitching/fragmentHelpers';
import { Node, Point } from 'slate';

export const onKeyDownHeading: CreateOnKeyDown<HeadingPluginPropertyOptions> =
  ({ pluginID }) =>
  (e, { editor }) => {
    const { selection } = editor;
    if (selection == null) {
      return;
    }

    if (isHotkey(DEFAULT_H1_HOTKEY, e)) {
      e.preventDefault();
      toggleHeading(editor, pluginID, HeadingLevel.H1);
      // When a user changes a bit of text to a heading, any marks on that text should be cleared.
      if (selection && !Range.isCollapsed(selection)) {
        clearMarksForSelection(editor);
      }
      return true;
    }

    if (isHotkey(DEFAULT_H2_HOTKEY, e)) {
      e.preventDefault();
      toggleHeading(editor, pluginID, HeadingLevel.H2);
      // When a user changes a bit of text to a heading, any marks on that text should be cleared.
      if (selection && !Range.isCollapsed(selection)) {
        clearMarksForSelection(editor);
      }
      return true;
    }

    if (isHotkey('enter', e) && selection != null) {
      try {
        const nodeEntry = Editor.node(editor, selection, { depth: 1 });
        if (nodeEntry != null && isHeadingNode(nodeEntry[0])) {
          e.preventDefault();
          Transforms.insertNodes(editor, createParagraph());
          return true;
        }
      } catch (e) {
        logger.error('[onKeyDownHeading] Error occurred when pressing "enter" in a heading', e);
      }
    }

    if (isHotkey('left', e)) {
      /**
       * if cursor is 2 offset ahead (to the right) of the end of the heading (just prior to movement)
       *
       * e.g. (without colon):
       *  <heading><text>EXAMINATION</text></heading>
       *  <paragraph><text> <cursor/></text><paragraph>
       */
      let pointBeforeSelection = Editor.before(editor, selection, {
        distance: 2,
        unit: 'offset',
      });
      let headingEntry = getHeadingIfCursorRightAtEnd(editor, pointBeforeSelection);
      if (pointBeforeSelection && headingEntry) {
        const targetPoint = Editor.end(editor, headingEntry[1]);
        const headingText = Node.string(headingEntry[0]);
        if (!isLastCharacterAColon(headingText)) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);
          return true;
        }
      }
      /**
       * if cursor is 1 offset ahead (to the right) of the end of the heading (just prior to movement)
       *
       * e.g.:
       *  <heading><text>EXAMINATION:</text></heading>
       *  <paragraph><text><cursor/></text><paragraph>
       */
      pointBeforeSelection = Editor.before(editor, selection);
      headingEntry = getHeadingIfCursorRightAtEnd(editor, pointBeforeSelection);
      if (pointBeforeSelection && headingEntry) {
        const targetPoint = Editor.end(editor, headingEntry[1]);
        const headingText = Node.string(headingEntry[0]);
        if (isLastCharacterAColon(headingText)) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);
          Transforms.move(editor, { reverse: true });
          return true;
        }
      }
    }

    if (isHotkey('right', e)) {
      /**
       * if cursor is 1 offset before (to the left) of the end of the heading (just prior to movement)
       *
       * e.g.:
       *  <heading><text>EXAMINATION<cursor/>:</text></heading>
       */
      const pointAfterSelection = Editor.after(editor, selection);
      let headingEntry = getHeadingIfCursorRightAtEnd(editor, pointAfterSelection);
      if (pointAfterSelection && headingEntry) {
        const targetPoint = Editor.end(editor, headingEntry[1]);
        if (Point.equals(targetPoint, pointAfterSelection)) {
          const headingText = Node.string(headingEntry[0]);
          if (isLastCharacterAColon(headingText)) {
            e.preventDefault();
            Transforms.move(editor, { distance: 2 });
            return true;
          }
        }
      }

      /**
       * if cursor is right before (to the left) of the end of the heading (just prior to movement)
       *
       * e.g. (without colon)
       *  <heading><text>EXAMINATION<cursor/></text></heading>
       */
      headingEntry = getHeadingIfCursorRightAtEnd(editor, selection);
      if (headingEntry) {
        const endOfHeading = Editor.end(editor, headingEntry[1]);
        if (Point.equals(endOfHeading, Editor.point(editor, selection))) {
          e.preventDefault();
          Transforms.move(editor, { distance: 2, unit: 'offset' });
          return true;
        }
      }
    }
  };
