// @flow

import { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import { usePopper } from 'react-popper';
import styled, { css } from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import { useSlate } from 'slate-react';
import { Colors } from 'styles';
import { PicklistElement } from './PicklistElement';
import { useRegisterNodeSelectedStateType } from '../../../hooks';
import { areMultipleSiblingBracketsSelected } from '../../../utils';
import { Portal } from 'common/ui/Portal';
import { transparentize } from 'color2k';
import { getPicklistOptionDisplayName } from '../utils';
import { usePicklistState } from '../../../../Template/usePicklist';
import type { PicklistPluginElement, ReportPicklistBeforeSubmissionOptions } from '../types';
import { PicklistOption } from './PicklistOption';
import type { AllPluginID } from '../../types';
import { useSelected } from '../../../core';

const Cell = styled.span`
  overflow: hidden;
`;

type PicklistSelectorProps = {
  options: $ReadOnlyArray<ReportPicklistBeforeSubmissionOptions>,
  children: Array<React$Node> | React$Node,
  attributes: $Exact<{
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React$Ref<React$ElementType>,
  }>,
  isSelected: boolean,
  pluginID: AllPluginID,
  showPicklistOptionsInEditor: boolean,
  element: PicklistPluginElement,
  'data-editor-element'?: string,
  leftDelimiter?: string,
  rightDelimiter?: string,
};

export const PicklistSelector = ({
  options,
  children,
  pluginID,
  attributes,
  showPicklistOptionsInEditor,
  element,
  isSelected,
  ...props
}: PicklistSelectorProps): React$Node => {
  const [referenceElement, setReferenceElement] = useState<?HTMLElement>(null);

  const { selectedPicklistOptionIds, setSelectedPicklistOptionIds, selectPicklistItem } =
    usePicklistState();
  const [hoveredPicklistOptionIndex, setHoveredPicklistOptionIndex] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const { styles, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'flip', options: { fallbackPlacements: ['top-start', 'bottom-start'] } }],
  });
  const editor = useSlate();
  const selected = useSelected();
  const moveItemUp = useCallback(() => {
    setSelectedPicklistOptionIds((prev) => {
      const selectedIndex = options.findIndex((o) => o.id === prev[element.picklistID]);
      if (selectedIndex != null) {
        return {
          ...prev,
          [element.picklistID]: options[selectedIndex - 1]
            ? options[selectedIndex - 1].id
            : options[options.length - 1].id,
        };
      }

      return prev;
    });
  }, [setSelectedPicklistOptionIds, options, element.picklistID]);

  const moveItemDown = useCallback(() => {
    setSelectedPicklistOptionIds((prev) => {
      const selectedIndex = options.findIndex((o) => o.id === prev[element.picklistID]);
      if (selectedIndex != null) {
        return {
          ...prev,
          [element.picklistID]: options[selectedIndex + 1]
            ? options[selectedIndex + 1].id
            : options[0].id,
        };
      }

      return prev;
    });
  }, [setSelectedPicklistOptionIds, options, element.picklistID]);

  const resetActiveSelectedPicklistOption = useCallback(() => {
    setSelectedPicklistOptionIds((prev) => {
      const selectedIndex = options.findIndex((o) => o.id === prev[element.picklistID]);
      if (selectedIndex != null) {
        return {
          ...prev,
          [element.picklistID]: options[0],
        };
      }

      return prev;
    });
  }, [element.picklistID, options, setSelectedPicklistOptionIds]);

  const multipleSiblingBracketsSelected = areMultipleSiblingBracketsSelected(editor);

  const [{ isOpen }, setState] = useRegisterNodeSelectedStateType(
    {
      isOpen: isSelected && !multipleSiblingBracketsSelected,
      moveItemUp,
      moveItemDown,
      selectPicklistItem,
      resetActiveSelectedPicklistOption,
    },
    pluginID
  );

  useEffect(() => {
    setState((s) => ({ ...s, moveItemUp, moveItemDown, selectPicklistItem }));
  }, [setState, moveItemUp, moveItemDown, selectPicklistItem]);

  const popperRef = useRef(popperElement);

  useEffect(() => {
    popperRef.current = popperElement;
  }, [popperElement]);

  useOnClickOutside(popperRef, ({ target }: MouseEvent) => {
    if (target instanceof Node && !referenceElement?.contains(target)) {
      setState((state) => ({ ...state, isOpen: false }));
    }
  });

  useLayoutEffect(() => {
    update && update();
  }, [update]);

  const hasDropdown = options != null && options.length > 0;

  return (
    <>
      <span ref={setReferenceElement}>
        <PicklistElement
          {...attributes}
          data-editor-element={props['data-editor-element']}
          handleClick={() => setState((state) => ({ ...state, isOpen: true }))}
          selected={selected}
          element={element}
          leftDelimiter={props.leftDelimiter}
          rightDelimiter={props.rightDelimiter}
        >
          {children}
        </PicklistElement>
      </span>

      {showPicklistOptionsInEditor && hasDropdown && isOpen && (
        <Portal>
          <ul
            role="listbox"
            data-testid="picklist-selector"
            css={css`
              ${css(styles.popper.transform != null ? styles.popper : { opacity: 0 })}
              z-index: 1;
              padding: 0;
              margin: 0;
              list-style: none;
              flex: 1;
              min-height: 0;
              background-color: ${Colors.gray2};
              border-radius: 3px;
              box-shadow: 2px 2px 4px 0 ${transparentize(Colors.gray1, 0.5)};
              overflow: auto;
              min-width: 200px;
              max-width: 500px;
              border: 1px solid ${transparentize(Colors.gray4, 0.5)};
              z-index: 10000;
            `}
            ref={setPopperElement}
          >
            <li
              css={`
                display: grid;
                grid-template-columns: 20px 1fr;
                grid-column-gap: 5px;
                padding: 5px 15px;
                font-weight: 500;
                color: ${Colors.gray8};
              `}
            >
              <Cell>#</Cell>
              <Cell>Name</Cell>
            </li>
            {options.map((item, index) => {
              const isHovered =
                hoveredPicklistOptionIndex != null &&
                hoveredPicklistOptionIndex < options.length &&
                index === hoveredPicklistOptionIndex;
              const isSelected =
                hoveredPicklistOptionIndex == null &&
                selectedPicklistOptionIds[element.picklistID] != null &&
                selectedPicklistOptionIds[element.picklistID] === item.id;

              return (
                <PicklistOption
                  id={item.id}
                  key={item.id}
                  onSelect={() => {
                    hoveredPicklistOptionIndex != null &&
                      selectPicklistItem(hoveredPicklistOptionIndex);
                  }}
                  onMouseOver={() => {
                    index != null && setHoveredPicklistOptionIndex(index);
                  }}
                  isSelected={isHovered || isSelected}
                >
                  <Cell>{index + 1}</Cell>
                  <Cell>{getPicklistOptionDisplayName({ ...item })}</Cell>
                </PicklistOption>
              );
            })}
          </ul>
        </Portal>
      )}
    </>
  );
};
