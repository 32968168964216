// @flow

import type {
  PathRefType,
  PointRefType,
  RangeRefType,
  PathType,
  PointType,
  RangeType,
} from '../core';

export const getPathRefSafe = (pathRef: PathRefType): PathType => {
  if (pathRef.current == null) {
    throw new Error('Cannot get pathRef of undefined!');
  }

  return pathRef.current;
};

export const getPointRefSafe = (pointRef: PointRefType): PointType => {
  if (pointRef.current == null) {
    throw new Error('Cannot get pointRef of undefined!');
  }

  return pointRef.current;
};

export const getRangeRefSafe = (rangeRef: ?RangeRefType): RangeType => {
  if (rangeRef == null || rangeRef.current == null) {
    throw new Error('Cannot get rangeRef of undefined!');
  }

  return rangeRef.current;
};
