// @flow

import { createPortal } from 'react-dom';

/**
 * NOTE: This is a naive portal implementation. If we need something fancier consider this:
 * https://github.com/chakra-ui/chakra-ui/tree/develop/packages/portal
 */

export type PortalProps = {
  children: React$Node,

  /**
   * Where to append the portaled element.
   *   @default document.body
   */
  mountPoint?: ?HTMLElement,
};

export const Portal = ({
  children,
  // $FlowIgnore[incompatible-cast] we know document.body will be defined
  mountPoint = (document.body: HTMLElement),
}: PortalProps): React$Node => {
  if (mountPoint == null) {
    return null;
  }
  return createPortal(children, mountPoint);
};
