// @flow
import { useState } from 'react';
import type { DreMouseEvent } from '../../types';
import { activePrimitiveState } from '../../modules/state';
import { useRecoilValue } from 'recoil';
import { decodeId } from '../../utils/representationId';
import type { AnnotationEditableOptions } from 'generated/graphql';
import { useAnnotationColors } from './useAnnotationColors';

export type HoverConfig = {
  onMouseOver: (DreMouseEvent) => mixed,
  onMouseLeave: (DreMouseEvent) => mixed,
  color: string,
  hovered: boolean,
};

type HoverProps = {
  targetId: string,
  defaultColor?: string,
  hoverColor?: string,
  allowedTransformations?: AnnotationEditableOptions,
};

export const useHover = ({
  targetId,
  defaultColor,
  hoverColor,
  allowedTransformations,
}: HoverProps): HoverConfig => {
  const annotationColors = useAnnotationColors();
  const [hovered, setHovered] = useState(false);
  const activeId = useRecoilValue(activePrimitiveState);
  const active = activeId != null ? decodeId(activeId).id === targetId : false;

  return {
    onMouseOver: () => setHovered(true),
    onMouseLeave: ({ id }) => {
      if (id !== targetId) setHovered(false);
    },
    color:
      hovered || active
        ? (hoverColor ?? annotationColors.hoverColor)
        : (defaultColor ?? annotationColors.defaultColor),
    hovered,
  };
};
