// @flow
import { useCallback, useMemo } from 'react';
import {
  coercion,
  optional,
  object,
  literal,
  string,
  number,
  array,
  withDefault,
} from '@recoiljs/refine';
import { MouseActionValues, ToolInteractionTypeValues, MouseButtonValues } from 'generated/graphql';
import type {
  MouseAction,
  ToolInteractionUnion,
  ToolAnnotationInteraction,
} from 'generated/graphql';
import { stringLiteralsCodegen } from 'utils/refine';
import { useEventAction } from '../../../modules/usePicker';
import type { PickerEvent } from '../../../modules/usePicker';
import type { VtkAnnotation } from '../../../Annotations';
import { MOUSE_BUTTON } from '../../../constants';
import { interactionHttpRequestChecker } from '../checkers';
import type { TriggerToolInteraction } from '../ConfigBasedTool';

type UseAnnotationInteractionArgs = {
  annotations: $ReadOnlyArray<VtkAnnotation>,
  interactions: $ReadOnlyArray<$ReadOnly<ToolInteractionUnion>>,
  triggerToolInteraction: TriggerToolInteraction,
  toolId: string,
};

const annotationInteractionChecker = object({
  __typename: optional(literal('ToolAnnotationInteraction')),
  type: literal(ToolInteractionTypeValues.Annotation),
  id: string(),
  maxConcurrentRequests: withDefault(number(), 0),
  request: interactionHttpRequestChecker,
  targetAnnotationId: optional(string()),
  targetAnnotationGroupId: optional(string()),
  button: stringLiteralsCodegen(MouseButtonValues),
  action: stringLiteralsCodegen(MouseActionValues),
});

export function useAnnotationInteraction({
  annotations,
  interactions,
  triggerToolInteraction,
  toolId,
}: UseAnnotationInteractionArgs) {
  const annotationInteractions = useMemo(
    () =>
      coercion<$ReadOnlyArray<$ReadOnly<ToolAnnotationInteraction>>>(
        array(annotationInteractionChecker)
      )(
        interactions.filter(
          (interaction) => interaction.type === ToolInteractionTypeValues.Annotation
        )
      ) ?? [],
    [interactions]
  );

  const handleAnnotationInteraction = useCallback(
    (action: MouseAction, event: PickerEvent) => {
      if (event.id == null) {
        return;
      }

      const targetAnnotation = annotations.find((annotation) => annotation.id === event.id);

      if (targetAnnotation == null) {
        return;
      }

      const annotationClickInteractions = annotationInteractions.filter(
        (interaction) =>
          MOUSE_BUTTON[interaction.button] === event.button && interaction.action === action
      );

      annotationClickInteractions.forEach((interaction) => {
        if (
          targetAnnotation.id === interaction.targetAnnotationId ||
          targetAnnotation.groupId === interaction.targetAnnotationGroupId
        ) {
          triggerToolInteraction({
            toolId,
            toolInteractionId: interaction.id,
            toolInteractionType: interaction.type,
          });
        }
      });
    },
    [annotations, annotationInteractions, triggerToolInteraction, toolId]
  );

  useEventAction('click', (event) => {
    handleAnnotationInteraction(MouseActionValues.Click, event);
  });
  useEventAction('doubleClick', (event) => {
    handleAnnotationInteraction(MouseActionValues.DoubleClick, event);
  });
}
