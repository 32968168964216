// @flow
import type { DreMouseEvent } from '../../modules/types';
import { useContext, useRef } from 'react';
import type { MouseHandlers } from '../../types';
import type { Segment } from '../types';
import { AnnotationMutationsContext } from '../../modules/AnnotationMutationsContext';
import { getVectorsDifference } from '../../utils/math';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import { segmentsForAnnotationSelector } from 'domains/viewer/ViewportsConfigurations/state';
import { useRecoilValue } from 'recoil';
import type { AnnotationEditableOptions } from 'generated/graphql';

type Props = {
  annotationId: string,
  allowedTransformations: AnnotationEditableOptions,
};

export type Handlers = {
  onDragStart: $NonMaybeType<MouseHandlers['onDragStart']>,
  onDrag: $NonMaybeType<MouseHandlers['onDrag']>,
  onDragEnd: $NonMaybeType<MouseHandlers['onDragEnd']>,
};

const DRAG_WARNING = `[SIRONA] Couldn't move annotation. Either worldPosition, origin, or updateAnnotation is not defined.`;

const defineUpdates = (segments: $ReadOnlyArray<Segment>, origin: vec3, destination: vec3) => {
  const difference = getVectorsDifference(origin, destination);

  return segments.reduce((updates, [start, end], index) => {
    return [
      ...updates,
      [index, 0, getVectorsDifference(start, difference)],
      [index, 1, getVectorsDifference(end, difference)],
    ];
  }, []);
};

const noop = () => {};

export const useMoveAnnotation = ({ annotationId, allowedTransformations }: Props): Handlers => {
  const segments = useRecoilValue(segmentsForAnnotationSelector(annotationId));
  const { updateAnnotationSegments } = useContext(AnnotationMutationsContext);
  const originRef = useRef(null);
  const originalSegmentsRef = useRef(null);

  if (allowedTransformations.move === false) {
    return {
      onDragStart: noop,
      onDrag: noop,
      onDragEnd: noop,
    };
  }

  const onDragStart = ({ worldPosition }: DreMouseEvent) => {
    originRef.current = worldPosition;
    originalSegmentsRef.current = segments;
  };

  const onDrag = ({ worldPosition }: DreMouseEvent) => {
    if (
      updateAnnotationSegments == null ||
      originRef.current == null ||
      worldPosition == null ||
      originalSegmentsRef.current == null
    ) {
      console.warn(DRAG_WARNING);
      return;
    }
    const newPoints = defineUpdates(originalSegmentsRef.current, originRef.current, worldPosition);
    updateAnnotationSegments(annotationId, true)(newPoints);
  };

  const onDragEnd = ({ worldPosition }: DreMouseEvent) => {
    if (
      updateAnnotationSegments == null ||
      originRef.current == null ||
      worldPosition == null ||
      originalSegmentsRef.current == null
    ) {
      console.warn(DRAG_WARNING);
      return;
    }
    const newPoints = defineUpdates(originalSegmentsRef.current, originRef.current, worldPosition);
    updateAnnotationSegments(annotationId)(newPoints);
  };

  return {
    onDragStart,
    onDrag,
    onDragEnd,
  };
};
