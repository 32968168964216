// @flow
import React, { useMemo } from 'react';
import { Slate } from '../core';
import type { SlateProps } from '../core';
import { compose } from 'ramda';
import { useSlateSingletonContext } from '../../Reporter/SlateSingletonContext';
import { usePlugins } from '../hooks';

export const EnhancedSlate = ({ editor, onChange, value, children }: SlateProps): React$Node => {
  const [{ editor: ctxEditor }] = useSlateSingletonContext();
  const pluginsBag = usePlugins();
  const { getProviderEnhancers } = pluginsBag;

  const EnhancedSlateComponent = useMemo(() => {
    if (ctxEditor == null) return Slate;
    const providerEnhancers = getProviderEnhancers();
    return providerEnhancers.length > 0 ? compose(...providerEnhancers)(Slate) : Slate;
  }, [getProviderEnhancers, ctxEditor]);

  return (
    <EnhancedSlateComponent editor={editor} onChange={onChange} value={value}>
      {children}
    </EnhancedSlateComponent>
  );
};
