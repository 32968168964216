// @flow

import { Editor } from '../core';
import type { EditorType } from '../core';
import { BOLD_PLUGIN_ID } from '../plugins/bold';
import { ITALIC_PLUGIN_ID } from '../plugins/italic';
import { UNDERLINE_PLUGIN_ID } from '../plugins/underline';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';

const CLEAR_FORMAT_BLACKLIST = [BOLD_PLUGIN_ID, ITALIC_PLUGIN_ID, UNDERLINE_PLUGIN_ID];
export const clearMarksForSelection = (editor: EditorType): void => {
  analytics.track(reporter.usr.clearMarksForSelection, {
    selectedText:
      editor.selection != null ? Editor.string(editor, editor.selection) : 'No text selected',
  });

  return Editor.withoutNormalizing(editor, () => {
    CLEAR_FORMAT_BLACKLIST.forEach((markToRemove) => editor.removeMark(markToRemove));
  });
};
