// @flow

/**
 * DOCS: https://github.com/SironaMedical/services-frontend/wiki/Rich-Text-Editor#macros
 */
import { createEditor, Editor, Transforms } from '../../core';
import { createEditorWarning } from '../../utils';
import type { SlateContent, EnhanceEditorState } from '../../types';
import { useCallback, useMemo } from 'react';
import { usePlugins } from '../../hooks';
import { compose } from 'ramda';
import { MACRO_PLACEHOLDER_PLUGIN_ID } from './types';
import type { MacroPlaceholderPluginElement } from './types';
import { insertMacroAtPath } from './utils';
import { createParagraphWithChildren } from '../paragraph/utils';
import { logger } from 'modules/logger';
import type { NodeType } from 'slate';
import type { Macros } from '../../../Template/usePicklist';
import type { ReportMacros } from 'hooks/useCurrentCaseReport';

export const unfurlMacroPlaceholderStatic = (
  slateContent: SlateContent,
  macros: Macros,
  editorStateEnhancers: EnhanceEditorState[] = [],
  { fragmentMode }: { fragmentMode: boolean } = {
    fragmentMode: false,
  }
): SlateContent => {
  const tempEditor =
    editorStateEnhancers.length > 0
      ? compose(...editorStateEnhancers)(createEditor())
      : createEditor();

  const slateContentAsArray = Array.isArray(slateContent) ? slateContent : [slateContent];

  if (!fragmentMode) {
    tempEditor.children = slateContent;
  } else {
    // $FlowIgnore[incompatible-type] Slate internals are mixed
    tempEditor.children = [createParagraphWithChildren(slateContentAsArray)];
  }

  Editor.withoutNormalizing(tempEditor, () => {
    const recursivelyResolveMacroPlaceholders = () => {
      // If you use the generator directly Slate ends up throwing an error. I'm not
      // sure why.
      const macroPlaceholderEntries = [
        // $FlowIgnore[incompatible-call] - Internal Slate Flow types off
        ...Editor.nodes<MacroPlaceholderPluginElement>(tempEditor, {
          at: [],
          match: (n: NodeType) => n.type === MACRO_PLACEHOLDER_PLUGIN_ID,
        }),
      ];

      // If there are no macro placeholders to resolve, return
      if (macroPlaceholderEntries.length === 0) return;

      // Macros run operations containing many nodes from top to bottom of the tree. When many
      // nodes are applied, the paths of any future macro placeholder nodes will have changed
      // to be currentNodePath + (number of nodes inserted - 1). This is cumulative per path item
      // in the node so the map gets really wild.
      //
      // To avoid the complexity, we create a WeakMap and apply a pathRef to each path for the macro placeholder.
      // This keeps the "living" path of the node and updates automatically as other nodes are added to
      // the tree.
      //
      // This is needed because when the macro placeholders need to be replaced at the exact location in which
      // they're shown in the template.
      //
      // If this doesn't work a more hack solution would be to run the operations bottom up instead of top bottom.
      // This works and is simpler, but I'm worried there's edge cases not considered that the path refs will
      // handle for us.
      const NODE_PATH_TO_PATH_REF = new WeakMap();

      macroPlaceholderEntries.forEach(([node, path]) => {
        NODE_PATH_TO_PATH_REF.set(node, Editor.pathRef(tempEditor, path));
      });

      for (const [node] of macroPlaceholderEntries) {
        const maybeMacro = macros?.find((macro) => macro.smid === node.referenceID);

        const pathRef = NODE_PATH_TO_PATH_REF.get(node);
        if (!pathRef?.current) {
          throw new Error('Cannot resolve path ref for node.');
        }

        // Use current path ref for any operations on the editor
        if (maybeMacro != null) {
          insertMacroAtPath(tempEditor, pathRef.current, maybeMacro.text);
        } else {
          Transforms.removeNodes(tempEditor, { at: pathRef.current });
          const macroPlaceholderErrorMessage = `[useMacroPlaceholder] Cannot find macro for macro placeholder ID: ${node.referenceID}. Make sure this macro is imported and active for the clinic.`;
          createEditorWarning(macroPlaceholderErrorMessage);
          logger.error(macroPlaceholderErrorMessage, {
            node,
            macroPlaceholderEntries: JSON.stringify(macroPlaceholderEntries),
            macros,
          });
        }

        pathRef.unref();
        NODE_PATH_TO_PATH_REF.delete(node);
      }

      recursivelyResolveMacroPlaceholders();
    };

    recursivelyResolveMacroPlaceholders();
  });

  return tempEditor.children;
};

export const useMacroPlaceholders = (): ({
  unfurlMacroPlaceholder: (template: SlateContent, macros: ReportMacros) => SlateContent,
}) => {
  const { getEditorStateEnhancers } = usePlugins();

  /**
   * Take in a template and resolve macro placeholders to be the underlying content adhering
   * to the rules of Slate.
   */
  const unfurlMacroPlaceholder = useCallback(
    (template: SlateContent, macros: ReportMacros) =>
      unfurlMacroPlaceholderStatic(template, macros, getEditorStateEnhancers()),
    [getEditorStateEnhancers]
  );

  const bag = useMemo(() => {
    return { unfurlMacroPlaceholder };
  }, [unfurlMacroPlaceholder]);

  return bag;
};
