// @flow

import type { EditorType, NodeType } from '../core';
import { find } from './find';
import type { EditorFindOptions } from './find';

export type SomeOptions = EditorFindOptions;

/**
 * Iterate through all of the nodes in the editor and break early for the first truthy match. Otherwise
 * returns false.
 */
export const some = <T = NodeType>(
  editor: EditorType,
  match: (node: NodeType) => boolean,
  options: SomeOptions = {}
): boolean => {
  return !!find<T>(editor, match, options);
};
