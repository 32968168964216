// @flow

import type { CreateOnKeyDown } from '../../types';
import type { InlineBookmarkPluginPropertyOptions } from './types';
import { onKeyDownInlineTemplate } from '../common/inlineTemplate';

export const onKeyDownInlineBookmark: CreateOnKeyDown<InlineBookmarkPluginPropertyOptions> =
  ({ pluginID }) =>
  (e, { editor, nodeState }) => {
    return onKeyDownInlineTemplate({ pluginID })(e, { editor, nodeState });
  };
