// @flow
import { nanoid } from 'nanoid';
import { sendEvent, NAMESPACES } from 'modules/EventsManager/eventsManager';
import { PAGE_TYPES } from 'utils/pageTypes';

const TOAST_SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

export function showToastMessage(
  message: string,
  severity?: $Keys<typeof TOAST_SEVERITY> = 'INFO'
) {
  sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
    type: 'showCrossTabNotification',
    payload: {
      toastKey: nanoid(),
      targetPageTypes: [PAGE_TYPES.VIEWER],
      position: 'top-right',
      isUnique: true,
      severity: TOAST_SEVERITY[severity] || TOAST_SEVERITY.INFO,
      message,
    },
  });
}
