// @flow

import type { EditorPlugin } from '../../types';
import { onKeyDownGeneralKeyboardControls } from './onKeyDownGeneralKeyboardControls';
import { enhanceProviderGeneralKeyboardControls } from './enhanceProviderGeneralKeyboardControls';
import { GENERAL_KEYBOARD_CONTROLS_PLUGIN_ID } from './types';
import type {
  GeneralKeyboardControlsPluginOptions,
  GeneralKeyboardControlsPluginPropertyOptions,
} from './types';
import { NOOP } from 'config/constants';

export const GeneralKeyboardControlsPlugin = (
  {
    pluginName = 'General Keyboard',
    onKeyDownOverride = NOOP,
    ...rest
  }: GeneralKeyboardControlsPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: GeneralKeyboardControlsPluginPropertyOptions = {
    pluginID: GENERAL_KEYBOARD_CONTROLS_PLUGIN_ID,
    pluginName,
    onKeyDownOverride,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    onKeyDown: onKeyDownGeneralKeyboardControls(options),
    enhanceProvider: enhanceProviderGeneralKeyboardControls(options),
  };
};
