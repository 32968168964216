// @flow

export type SupportedTexturesMap = {
  norm16: boolean,
  snorm: boolean,
};

export function getSupportedTextures(): SupportedTexturesMap {
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl2');
  // $FlowIssue[prop-missing] - Flow doesn't know about getSupportedExtensions
  const extensions: string[] = gl?.getSupportedExtensions() ?? [];
  const norm16 = extensions.includes('EXT_texture_norm16');
  const snorm = extensions.includes('EXT_render_snorm');
  canvas.remove();
  return { norm16, snorm };
}

export type SupportedTextureTypes =
  | Int8Array
  | Uint8Array
  | Int16Array
  | Uint16Array
  | Float32Array
  | Float64Array;

export const SUPPORTED_TEXTURES: {
  norm16: boolean,
  snorm: boolean,
} = getSupportedTextures();
