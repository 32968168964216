// @flow

/**
 * VTK only allows to obtain a representation ID from a vtkActor.
 * In order to store more data into each representation without
 * requiring to add the burden of a dedicated hashmap, we serialize
 * all the useful info into the representation ID.
 * The representation ID is going to be a base64 encoded URL Search
 * Params data structure. It can be easily encoded or decoded using
 * the utilities provided by this module.
 */

const cache = new Map();

// parse('id=abc&type=handle') => { id: 'abc', type: 'handle' }
export const decodeId = (id: string): { [key: string]: string } => {
  if (cache.has(id)) {
    // $FlowFixMe[incompatible-return] cache.has checks it
    return cache.get(id);
  } else {
    const data = Object.fromEntries(new URLSearchParams(id));
    cache.set(id, data);
    return data;
  }
};

// serialize({ id: 'abc', type: 'handle' }, 'name=line1') => 'id=abc&type=handle&name=line1'
export const encodeId = (data: { [key: string]: string }, id?: string): string => {
  // NOTE(zivolo): URLSearchParams#toString is too slow, that's why we have our own implementation
  let params = id != null ? decodeId(id) : {};

  params = { ...params, ...data };

  return Object.entries(params)
    .map((x) => x.join('='))
    .join('&');
};
