// @flow

import * as React from 'react';
import type { CreateRenderLeaf } from '../../types';
import type { DictationPluginPropertyOptions } from './types';

export const renderLeafDictation: CreateRenderLeaf<DictationPluginPropertyOptions> = ({
  component: Component,
  pluginID,
}) =>
  function RenderLeafDictation({ leaf, attributes, children, ...rest }): React$Node {
    if (typeof pluginID === 'string' && leaf[pluginID] != null && leaf.text != null) {
      return <Component data-editor-leaf={pluginID} leaf={leaf} children={children} {...rest} />;
    }

    return children;
  };
