// @flow

import type { CreateEnhanceEditorState } from '../../types';
import { Editor, Range, Transforms } from '../../core';
import { find } from '../../utils';
import type { SectionHeaderPluginPropertyOptions } from './types';
import type { ElementType, NodeType } from 'slate';

export const enhanceEditorStateSectionHeader: CreateEnhanceEditorState<
  SectionHeaderPluginPropertyOptions,
> =
  ({ pluginID }) =>
  (editor) => {
    const { isInline, deleteBackward, deleteForward } = editor;

    editor.isInline = (element: ElementType) => {
      return element.type === pluginID ? true : isInline(element);
    };

    editor.deleteBackward = (...args) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        // If outside of a SectionHeader node see if the next left point (where the cursor
        // would be on delete) is a SectionHeader node. If so, remove the entire node.
        const matchEntryBefore = find(editor, (n: NodeType) => n.type === pluginID, {
          at: Editor.before(editor, selection),
        });

        if (matchEntryBefore != null) {
          const targetPoint = Editor.before(editor, matchEntryBefore[1]);

          Transforms.removeNodes(editor, { at: matchEntryBefore[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteBackward(...args);
    };

    editor.deleteForward = (...args) => {
      const { selection } = editor;

      if (selection && Range.isCollapsed(selection)) {
        // If outside of a SectionHeader node see if the next right point (where the cursor
        // would be on delete forward) is a SectionHeader node. If so, remove the entire node.
        const matchEntryAfter = find(editor, (n: NodeType) => n.type === pluginID, {
          at: Editor.after(editor, selection),
        });

        if (matchEntryAfter != null) {
          const targetPoint = Editor.before(editor, matchEntryAfter[1]);

          Transforms.removeNodes(editor, { at: matchEntryAfter[1] });
          if (targetPoint != null) Transforms.select(editor, targetPoint);
          return;
        }
      }

      return deleteForward(...args);
    };

    return editor;
  };
