// @flow

export const trimHypothesisText = (
  currentHypothesisText: string,
  lastStableTextInserted: string
): string => {
  // Convert newlines to whitespaces
  const modifiedHypothesisText = currentHypothesisText.replace(/\n/g, ' ');

  // Find last occurrence of lastStableTextInserted in the modified text
  const index = modifiedHypothesisText.lastIndexOf(lastStableTextInserted);

  // If found, trim everything including and before it
  if (index !== -1) {
    const startIndex = index + lastStableTextInserted.length;
    return modifiedHypothesisText.substring(startIndex);
  }

  // If not found, return the current hypothesis text as is
  return currentHypothesisText;
};
