// @flow
import { useCallback, useEffect, useMemo } from 'react';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useWorklistFiltersContext } from '../domains/worklist/WorklistFilters/WorklistFiltersContext';
import type { WorklistSurfaceType } from '../domains/worklist/Worklist/types';
import { useMutation } from '@apollo/client';
import { GET_ME, UPDATE_WORKLIST_SETTINGS } from '../modules/Apollo/queries';
import type { GetMeQuery, GetMeQueryVariables } from '../generated/graphql';
import { logger } from '../modules/logger';
import { useLocation } from 'react-router-dom';
import {
  worklistAutoLoadState,
  worklistAutoLoadStateDefault,
} from '../domains/worklist/Worklist/state';
import { WorklistItemStatusValues } from '../generated/graphql';
import { useRecoilState } from 'recoil';
import { useWorklistTableData } from '../domains/worklist/Worklist/hooks/useWorklistTableData';
import { useCurrentCaseId } from './useCurrentCase';
import { useClaimPatient } from './useClaimPatient';
import { PAGE_TYPES, getPageType } from '../utils/pageTypes';
import { useSavedSortingPreferences } from '../domains/worklist/Worklist/hooks/useSavedSortingPreferences';

type UseWorklistAutoLoadReturn = {
  isWorklistAutoLoadEnabled: boolean,
  isWorklistAutoLoadToggleDisplayable: boolean,
  toggleWorklistAutoLoad: () => Promise<void>,
  readNextCase: () => Promise<?string>,
};

const displayableSurfaces: WorklistSurfaceType[] = ['PENDING', 'MY_QUEUE', 'WORKLIST_VIEW'];

export const useWorklistAutoLoad = (): UseWorklistAutoLoadReturn => {
  const [isWorklistAutoLoadFeatureEnabled] = useFeatureFlagEnabled(FF.WORKLIST_AUTO_LOAD);

  const [worklistAutoLoadRecoilState, setWorklistAutoLoadRecoilState] =
    useRecoilState(worklistAutoLoadState);
  const currentCaseId = useCurrentCaseId();
  const { startRead } = useClaimPatient();
  const { data } = useCurrentUser();
  const location = useLocation();
  const isWorklist = useMemo(
    () => getPageType(location.pathname) === PAGE_TYPES.WORKLIST,
    [location.pathname]
  );
  const isReporter = useMemo(
    () => getPageType(location.pathname) === PAGE_TYPES.REPORTER,
    [location.pathname]
  );

  const userId = data?.me.id;

  const worklistSettings = data?.me.worklistSettings;

  const {
    surface,
    debouncedSearchForSurface,
    filters,
    availableFilters,
    filtersBySurface,
    debouncedSearches,
    surfacesToDefaultFilters,
  } = useWorklistFiltersContext();

  const { savedSortingPreferences } = useSavedSortingPreferences();

  const { items, refetch, variables } = useWorklistTableData({
    search: worklistAutoLoadRecoilState.searchForSurface,
    surface,
    userId,
    pixelDataSharedWorker: null,
    overrideFilters: worklistAutoLoadRecoilState.filters,
    overrideAvailableFilters: worklistAutoLoadRecoilState.availableFilters,
  });

  const isWorklistAutoLoadToggleDisplayable = useMemo(() => {
    return isWorklistAutoLoadFeatureEnabled && displayableSurfaces.includes(surface);
  }, [isWorklistAutoLoadFeatureEnabled, surface]);

  const worklistAutoLoadOptions = useMemo(() => {
    return worklistSettings?.worklistAutoLoad ?? { surface: null };
  }, [worklistSettings]);
  const [updateWorklistSettings] = useMutation(UPDATE_WORKLIST_SETTINGS);

  /**
   * checks if the worklist auto load feature is enabled and
   * the current surface is the same as the one saved in the atom
   */
  const isWorklistAutoLoadEnabled = useMemo(() => {
    return isWorklistAutoLoadFeatureEnabled && surface === worklistAutoLoadRecoilState.surface;
  }, [isWorklistAutoLoadFeatureEnabled, surface, worklistAutoLoadRecoilState.surface]);

  /**
   * updates the worklist auto load atom with the current surface and search params
   */
  useEffect(() => {
    // if the reporter window is open and there is mismatch between autoLoad options in user settings, and recoil,
    // set the recoil state with best known options
    // currently sort order/columns are persisted, but filter settings are not
    if (
      isReporter &&
      worklistAutoLoadRecoilState?.surface == null &&
      worklistAutoLoadOptions?.surface != null
    ) {
      const worklistAutoLoadOptionsSurface = worklistAutoLoadOptions.surface;
      setWorklistAutoLoadRecoilState({
        // $FlowIgnore[incompatible-cast] - surface should be validated upstream to be a WorklistSurfaceType
        surface: (worklistAutoLoadOptionsSurface: ?WorklistSurfaceType),
        searchParamsString: savedSortingPreferences?.[worklistAutoLoadOptionsSurface] ?? null,
        filters: filtersBySurface?.[worklistAutoLoadOptionsSurface] ?? null,
        availableFilters: surfacesToDefaultFilters?.[worklistAutoLoadOptionsSurface] ?? null,
        searchForSurface: debouncedSearches?.[worklistAutoLoadOptionsSurface] ?? null,
      });
    }

    if (!isWorklist) {
      return;
    }
    if (worklistAutoLoadOptions?.surface === surface) {
      setWorklistAutoLoadRecoilState({
        // $FlowIgnore[incompatible-cast] - surface should be validated upstream to be a WorklistSurfaceType
        surface: (worklistAutoLoadOptions?.surface: ?WorklistSurfaceType),
        searchParamsString: savedSortingPreferences?.[surface] ?? null,
        filters,
        availableFilters,
        searchForSurface: debouncedSearchForSurface,
      });
    }
    if (worklistAutoLoadOptions?.surface == null) {
      setWorklistAutoLoadRecoilState(worklistAutoLoadStateDefault);
    }
  }, [
    availableFilters,
    debouncedSearchForSurface,
    debouncedSearches,
    filters,
    filtersBySurface,
    isReporter,
    isWorklist,
    savedSortingPreferences,
    setWorklistAutoLoadRecoilState,
    surface,
    surfacesToDefaultFilters,
    worklistAutoLoadOptions.surface,
    worklistAutoLoadRecoilState?.surface,
  ]);

  const toggleWorklistAutoLoad = useCallback(async () => {
    const updatedWorklistAutoLoad = {
      surface: isWorklistAutoLoadEnabled ? null : surface,
    };
    const updatedResponse = {
      ...worklistSettings,
      worklistAutoLoad: updatedWorklistAutoLoad,
    };

    logger.info(
      `[useWorklistAutoLoad] - Attempting to toggle ${isWorklistAutoLoadEnabled ? 'OFF' : 'ON'} Worklist AutoLoad for ${surface} tab`,
      { surface, isWorklistAutoLoadEnabled }
    );

    await updateWorklistSettings({
      variables: { worklistAutoLoad: updatedWorklistAutoLoad },
      optimisticResponse: {
        __typename: 'Mutation',
        updateWorklistSettings: {
          __typename: 'WorklistSettings',
          ...updatedResponse,
        },
      },
      update: (proxy) => {
        const { me } = proxy.readQuery<GetMeQuery, GetMeQueryVariables>({ query: GET_ME }) ?? {};
        proxy.writeQuery({
          query: GET_ME,
          data: {
            me: {
              ...me,
              worklistSettings: {
                ...updatedResponse,
              },
            },
          },
        });
      },
      onError: (error): void => {
        logger.error(
          `[useWorklistAutoLoad] - Failed to toggle Worklist AutoLoad for ${surface} tab`,
          { error }
        );
      },
      onCompleted: async (data): Promise<void> => {
        logger.info(
          `[useWorklistAutoLoad] - Successfully toggled Worklist AutoLoad for ${surface} tab`
        );
      },
    });
  }, [isWorklistAutoLoadEnabled, surface, updateWorklistSettings, worklistSettings]);

  const getNextCase = useCallback(async () => {
    if (worklistAutoLoadOptions?.surface == null) {
      return null;
    }

    await refetch(variables);

    if (items.length === 0) {
      return null;
    }

    return items.find((item) => {
      // if the item is the current case, skip it
      if (currentCaseId === item.smid) {
        return false;
      }

      if (item.status === WorklistItemStatusValues.Archive) {
        return false;
      }

      // if the item is claimed by someone else, skip it
      if (item.claimedBy != null && item.claimedBy.id !== userId) {
        return false;
      }

      // if the item has a report that has submittedAt (including currently in countdown), skip it
      if (item.report?.submittedAt != null) {
        return false;
      }

      return true;
    });
  }, [currentCaseId, items, refetch, userId, worklistAutoLoadOptions?.surface, variables]);

  const readNextCase = useCallback(async () => {
    const nextCase = await getNextCase();

    if (nextCase == null) {
      logger.info(`[useWorklistAutoLoad] - No next case found`);
      return null;
    }

    logger.info(`[useWorklistAutoLoad] - WorklistItem ${nextCase.smid} is next in line to be read`);
    startRead({
      overrideCaseId: nextCase?.smid,
      overrideCaseGroupId: nextCase?.groupId,
      overrideStudyIds: [], // prior_studies should be reset
    });
    return nextCase.smid;
  }, [getNextCase, startRead]);

  return {
    isWorklistAutoLoadEnabled,
    isWorklistAutoLoadToggleDisplayable,
    toggleWorklistAutoLoad,
    readNextCase,
  };
};
