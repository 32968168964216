// @flow
import { useEffect, useCallback, useMemo } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import { useCurrentCaseId } from './useCurrentCase';
import { usePrevious } from 'react-use';
import { Editor, Range, Path } from 'domains/reporter/RichTextEditor/core';
import type { EditorType } from 'domains/reporter/RichTextEditor/core';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import { useWorklistItemAnalytics } from 'hooks/useWorklistItemAnalytics';

type TypedCharacters = { [string]: { inserted: string, deleted: string } };
export const typedCharactersStateFamily: (container: string) => RecoilState<TypedCharacters> =
  atomFamily({
    key: 'typedCharactersState',
    default: {},
  });

export const useTypedCharacters = (
  id: string
): ({
  typedCharacters: TypedCharacters,
  handleTrackingTypedCharacters: ({ event: KeyboardEvent, editor: ?EditorType }) => void,
  trackTypedCharacters: () => void,
}) => {
  const analyticsData = useWorklistItemAnalytics();

  const currentCaseId = useCurrentCaseId();
  const previousCaseId = usePrevious(currentCaseId);
  const [typedCharacters, setTypedCharacters] = useRecoilState(typedCharactersStateFamily(id));

  // Reset the typedCharacters count every time the user changes case
  useEffect(() => {
    if (currentCaseId != null && previousCaseId != null && currentCaseId !== previousCaseId) {
      setTypedCharacters({});
    }
  }, [currentCaseId, previousCaseId, setTypedCharacters]);

  const handleTrackingTypedCharacters = useCallback(
    ({ event, editor }: { event: KeyboardEvent, editor: ?EditorType }): void => {
      /* 
        We want to track whenever users manually insert characters into the report
        by typing. This is not a perfect solution. But it's a good start.
        Assumptions:
        - We only want to track alphanumeric characters, punctuation, and whitespace.
        - These indicate a user is typing report content instead of dictating it.
        - We don't want to track special keys like Enter, Tab, etc.
        - We don't want to track key combinations like Ctrl+C, Ctrl+V, etc.
        */
      const updateTypedCharacters = (prevState: TypedCharacters) => {
        if (editor?.selection == null) return prevState;

        const [start, end] = Range.edges(editor.selection);

        if (!Path.equals(start.path, end.path)) return prevState;

        const node = Editor.node(editor, start.path);
        const path = JSON.stringify(start.path);

        let deletedContent = '';

        if (
          new RegExp(/^[a-zA-Z0-9.!?,-\s]$/).test(event.key) &&
          !event.ctrlKey &&
          !event.metaKey &&
          !event.altKey
        ) {
          // $FlowIgnore[incompatible-call] we assert that editor.selection is not null above
          if (!Range.isCollapsed(editor.selection)) {
            if (node != null && node[0].text != null) {
              // $FlowIgnore[incompatible-use] we assert that the node is a text node above
              const selectedText = node[0].text.slice(start.offset, end.offset);
              deletedContent = selectedText;
            }
          }

          return {
            ...prevState,
            [path]: prevState[path]
              ? {
                  ...prevState[path],
                  inserted: prevState[path]['inserted'] + event.key,
                  deleted: prevState[path]['deleted'] + deletedContent,
                }
              : { inserted: event.key, deleted: deletedContent },
          };
        } else if (event.key === 'Backspace') {
          // $FlowIgnore[incompatible-call] we assert that editor.selection is not null above
          if (Range.isCollapsed(editor.selection)) {
            // we want to know the character to the left of our cursor
            if (node != null && node[0].text != null) {
              // $FlowIgnore[incompatible-use] we assert that the node is a text node above
              const selectedText = node[0].text.slice(start.offset - 1, start.offset);
              deletedContent = selectedText;
            }
          } else {
            if (node != null && node[0].text != null) {
              // $FlowIgnore[incompatible-use] we assert that the node is a text node above
              const selectedText = node[0].text.slice(start.offset, end.offset);
              deletedContent = selectedText;
            }
          }

          return {
            ...prevState,
            [path]: prevState[path]
              ? { ...prevState[path], deleted: deletedContent + prevState[path]['deleted'] }
              : { inserted: '', deleted: deletedContent },
          };
        }

        // NOOP, but we provide for Flow
        return prevState;
      };

      setTypedCharacters(updateTypedCharacters);
    },
    [setTypedCharacters]
  );

  const trackTypedCharacters = useCallback(() => {
    if (Object.entries(typedCharacters).length > 0) {
      const deletedCharactersCount = Object.values(typedCharacters).reduce(
        (acc, curr) => acc + curr['deleted'].length,
        0
      );
      const insertedCharactersCount = Object.values(typedCharacters).reduce(
        (acc, curr) => acc + curr['inserted'].length,
        0
      );
      analytics.track(reporter.usr.charactersTyped, {
        ...analyticsData,
        insertedCharactersCount,
        deletedCharactersCount,
      });
    }
  }, [analyticsData, typedCharacters]);

  return useMemo(
    () => ({
      typedCharacters,
      handleTrackingTypedCharacters,
      trackTypedCharacters,
    }),
    [handleTrackingTypedCharacters, trackTypedCharacters, typedCharacters]
  );
};
