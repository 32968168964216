// @flow
import { useCallback, useState } from 'react';
import type { Macro } from 'generated/graphql';
import { Transforms } from 'slate';
import type { NodeType, EditorType, LocationType } from 'slate';
import { clone, prop, uniqBy } from 'ramda';
import { getPicklistsNodesFromParentNode } from '../../../RichTextEditor/plugins/picklist/utils';
import { nanoid } from 'nanoid';
import { PICKLIST_PLUGIN_ID } from '../../../RichTextEditor/plugins/picklist';
import { partialEditor, walkSlateContent } from '../../../RichTextEditor/utils';
import { unfurlMacroPlaceholderStatic } from '../../../RichTextEditor/plugins/macroPlaceholder';
import { usePicklistState } from '../../../Template/usePicklist';
import type { EnhanceEditorState } from '../../../RichTextEditor/types';
import { stitchNodesIntoEditor } from '../../../RichTextEditor/stitching';
import {
  isOnlyHeadingSelected,
  isPointAfterSelectionInsideHeading,
} from '../../../RichTextEditor/plugins/heading/utils/normalization';
import { useToasterDispatch } from 'common/ui/Toaster/Toaster';
import type { ToastKey } from 'common/ui/Toaster/Toast';
import { logger } from 'modules/logger';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import { hydratePlaceholders } from '../../../RichTextEditor/plugins/placeholder/utils';
import { fragmentContainsBrackets } from '../../../RichTextEditor/stitching/fragmentHelpers';
import { prepFragmentAndInsertionPoint } from '../../../RichTextEditor/plugins/inlineBookmark/utils';
import { atom, useSetRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import type { Macros } from '../../../Template/usePicklist';

export type UseMacrosReturn = ({
  editor?: EditorType,
  editorStateEnhancers?: EnhanceEditorState[],
  macro: Macro,
  at?: LocationType,
}) => void;

export const isInsertingMacroState: RecoilState<boolean> = atom({
  key: 'isInsertingMacro',
  default: false,
});

export const useInsertMacro = (): UseMacrosReturn => {
  const { setPicklists, setMacros } = usePicklistState();
  const { enqueueOrUpdateToast, enqueueToast } = useToasterDispatch();
  const { currentWorklistItems } = useCurrentWorklistItems();
  const [latestToastKey, setLatestToastKey] = useState<ToastKey | null>(null);
  const setIsInsertingMacro = useSetRecoilState(isInsertingMacroState);

  const insertMacro = useCallback(
    ({
      editor,
      editorStateEnhancers,
      macro,
      at,
    }: {
      editor?: EditorType,
      editorStateEnhancers?: EnhanceEditorState[],
      macro: Macro,
      at?: LocationType,
    }) => {
      if (!editor || !editorStateEnhancers) {
        const editorErrorMessage =
          '[useInsertMacro] Cannot insert macro because there is no active editor!';
        logger.warn(editorErrorMessage, {
          logID: JSON.stringify(at),
          macro,
          editor: editor != null ? partialEditor(editor) : 'null',
        });
        return;
      }

      let selection = editor.selection;

      if (selection == null) {
        const selectionErrorMessage =
          '[useInsertMacro] Cannot insert macro because there is no selection!';
        logger.warn(selectionErrorMessage, {
          logID: JSON.stringify(at),
          macro,
          editor: partialEditor(editor),
        });
        return;
      }

      // Cannot insert macro into heading
      if (isOnlyHeadingSelected(editor)) {
        // check if point after current selection is in header. if so, trigger toast.
        // else, move the selection to point after and continue with macro insertion
        if (isPointAfterSelectionInsideHeading(editor)) {
          const toastKey = enqueueOrUpdateToast(
            'You cannot insert macros into a heading.',
            latestToastKey,
            {
              severity: 'error',
            }
          );
          setLatestToastKey(toastKey);
          return;
        } else {
          Transforms.move(editor, { distance: 1 });
          selection = editor.selection;

          if (selection == null) {
            const selectionErrorMessage =
              '[useInsertMacro] Cannot insert macro because there is no selection!';
            logger.warn(selectionErrorMessage, {
              logID: JSON.stringify(at),
              macro,
              editor: partialEditor(editor),
            });
            return;
          }
        }
      }

      setIsInsertingMacro(true);

      const newMacro = clone(macro);
      const macroContainsFields =
        macro.picklists.length > 0 ||
        macro.nestedMacros.length > 0 ||
        fragmentContainsBrackets(Array.from(macro.text));

      let [fragment, location] = prepFragmentAndInsertionPoint(
        editor,
        [...newMacro.text],
        macroContainsFields,
        {
          location: at ?? selection,
          removeEmptyBrackets: true,
        }
      );

      if (location == null) {
        location = selection;
      }

      // $FlowIgnore[cannot-write] - we can overwrite the text property
      newMacro.text = fragment;

      // Searches for picklistsIDs on the Editor and on the new macro.
      // If a picklist exists on the editor and the new macro tries to insert the same id
      // Then the new macro picklists will have a new generated picklistID with nanoid()
      // This logic prevents the generation of picklist elements with the same ID
      const editorPicklistsIds = getPicklistsNodesFromParentNode(editor).map(
        ({ picklistID }) => picklistID
      );

      // Stores the id of the old picklist as a key and the new id as the value
      const newPicklistMap = new Map();
      macro.picklists
        .filter(({ id }) => editorPicklistsIds.includes(id))
        .forEach(({ id }) => {
          newPicklistMap.set(id, nanoid());
        });

      const onElement = (node: NodeType) => {
        const newPicklistID = newPicklistMap.get(node.picklistID);
        if (node.type === PICKLIST_PLUGIN_ID && newPicklistID != null) {
          // $FlowIgnore[prop-missing]
          node.picklistID = newPicklistID;
        }
      };
      walkSlateContent(onElement)(newMacro.text);

      // $FlowIgnore[cannot-write] Ignoring non-writable type
      newMacro.picklists = macro.picklists.map((picklist) => {
        const newPicklistID = newPicklistMap.get(picklist.id);
        return {
          ...picklist,
          ...(newPicklistID != null
            ? {
                id: newPicklistID,
              }
            : {}),
        };
      });

      const nestedMacros = newMacro.nestedMacros ?? [];
      const unfurledContent = unfurlMacroPlaceholderStatic(
        newMacro.text,
        // $FlowFixMe[incompatible-call] - we've defined the types for macros differently depending on the query context, which makes this exceedingly difficult to type. The appropriate solution is to refactor the queries related to GET_MACROS and GET_CURRENT_WORKLIST_REPORT to return the same type.
        nestedMacros,
        editorStateEnhancers,
        {
          fragmentMode: true,
        }
      );

      // If Macros are wrapped in a paragraph,
      // instead of inserting as a new paragraph, insert the children
      let newNodes = unfurledContent;
      if (unfurledContent[0].type === 'paragraph') {
        newNodes = [...unfurledContent[0].children, ...unfurledContent.slice(1)];
      }

      stitchNodesIntoEditor(editor, newNodes, {
        at: location,
        enableNormalize: true,
        select: true,
        enableLogging: true,
        enqueueToast,
        isInsertingMacro: true,
      });

      setPicklists((statePicklists) => [...statePicklists, ...newMacro.picklists]);
      setMacros((stateMacros: Macros): Macros => {
        const currentMacros = stateMacros ?? [];
        const macros = [...currentMacros, newMacro, ...newMacro.nestedMacros];
        const newMacros = uniqBy(prop('smid'), macros);
        // $FlowFixMe[incompatible-return] - we've defined the types for macros differently depending on the query context, which makes this exceedingly difficult to type. The appropriate solution is to refactor the queries related to GET_MACROS and GET_CURRENT_WORKLIST_REPORT to return the same type.
        return newMacros;
      });

      hydratePlaceholders(editor, currentWorklistItems);

      setIsInsertingMacro(false);
    },
    [
      setIsInsertingMacro,
      enqueueToast,
      setPicklists,
      setMacros,
      currentWorklistItems,
      enqueueOrUpdateToast,
      latestToastKey,
    ]
  );

  return insertMacro;
};
