// @flow

import type { MarkPluginID } from '../plugins/types';
import styled from 'styled-components';

import type { TextType } from '../core';
import type { RendererRenderText } from './types';
import { forEachObjIndexed } from 'ramda';
import { getSourceTextColor } from '../plugins/textSourceStyling/utils';

export type TextComponentProps = $ReadOnly<{
  node: TextType,
  renderText: RendererRenderText,
}>;

const StyledText = styled.span`
  /* Make sure empty content still renders line height of element */
  &::before {
    content: '\u{200b}'; // unicode zero width space character
  }

  ${({ source }: { source: string }) => {
    return `color: ${getSourceTextColor(source)};`;
  }}
`;

export const TextComponent = ({
  node: { text, ...marks },
  renderText,
}: TextComponentProps): React$Node => {
  let children: React$Node = <StyledText>{text}</StyledText>;

  forEachObjIndexed((_val: $FlowFixMe, mark: MarkPluginID) => {
    children = renderText({ mark, children });
  }, marks);

  return children;
};
