// @flow

import type { InlineBookmarkPluginElement } from '../types';

import { InlineBookmarkElement } from './InlineBookmarkElement';

type InlineTemplateElementProps = $ReadOnly<{
  children: React$Node,
  selected?: boolean,
  leftDelimiter: React$Node,
  rightDelimiter: React$Node,
  testID?: string,
  attributes: $Exact<{
    'data-slate-node': 'element',
    'data-slate-inline'?: true,
    'data-slate-void'?: true,
    dir?: 'rtl',
    ref: React$Ref<React$ElementType>,
  }>,
  element: InlineBookmarkPluginElement,
  'data-editor-element'?: string,
  variant?: string,
}>;

export const InlineBookmarkTemplate = ({
  attributes,
  options = [],
  children,
  element,
  leftDelimiter,
  selected,
  rightDelimiter,
  variant,
  ...rest
}: InlineTemplateElementProps): React$Node => {
  return (
    <span readOnly>
      <InlineBookmarkElement
        {...attributes}
        element={element}
        variant={variant}
        selected={selected}
      >
        {children}
      </InlineBookmarkElement>
    </span>
  );
};
