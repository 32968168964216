// @flow
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Ellipsis } from './ui/Ellipsis';
import { trim } from 'utils/string';
import useResizeAware from 'react-resize-aware';

type Props = {
  css?: string,
  name?: ?string,
  className?: ?string,
  conditionalTooltip?: boolean,
  customEllipsisCss?: mixed | null,
  'data-testid'?: string,
  variant?: 'default' | 'noWrap',
};

const NoMaxWidthTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 'none',
  },
}))(Tooltip);

const TextWithTooltip = ({
  name,
  className,
  conditionalTooltip = false,
  customEllipsisCss = null,
  'data-testid': dataTestid,
  variant = 'default',
}: Props): React$Node => {
  const [resizeListener, sizes] = useResizeAware((target) => ({
    clientWidth: target?.parentElement?.clientWidth ?? 0,
    scrollWidth: target?.parentElement?.scrollWidth ?? 0,
  }));

  const isOverflowing = sizes != null ? sizes.scrollWidth > sizes.clientWidth : false;
  return (
    <Ellipsis
      data-privacy
      css={{
        minWidth: 0,
        display: 'inline-block',
        padding: 0,
        position: 'relative',
        ...customEllipsisCss,
      }}
      className={className}
      data-testid={dataTestid}
      data-value={trim(name)}
    >
      {resizeListener}
      {variant === 'default' ? (
        <Tooltip title={name} disableHoverListener={conditionalTooltip === true && !isOverflowing}>
          <span>{name}</span>
        </Tooltip>
      ) : variant === 'noWrap' ? (
        <NoMaxWidthTooltip
          title={name}
          disableHoverListener={conditionalTooltip === true && !isOverflowing}
        >
          <span>{name}</span>
        </NoMaxWidthTooltip>
      ) : null}
    </Ellipsis>
  );
};

export default TextWithTooltip;
