// @flow

import type { EditorType, NodeEntry, NodeType, SpanType, LocationType, RangeType } from '../core';

import { useRef, useEffect } from 'react';
// $FlowFixMe[untyped-import]
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import type { ToastKey } from 'common/ui/Toaster/Toast';
import type { Toast } from 'common/ui/Toaster/Toaster';
import type { SlateContent } from '../../Reporter/types';
import { Node, ReactEditor, Transforms, Editor } from '../core';
import { walkSlateContent } from './walkSlateContent';
import { selectAndFocusEditor } from './focusEditor';
import { isSquareBracketType } from '../constants';
import { NOOP } from 'config/constants';
import { logger } from 'modules/logger';
import type { AllReporterVariant } from '../hooks/useReporter';

export const warnOnRequiredFieldDeletion = (
  requiredFields: Array<NodeType>,
  enqueueToast?: (msg: React$Node, options?: $ReadOnly<$Partial<Toast>>) => ToastKey,
  variant?: AllReporterVariant
): void => {
  if (requiredFields.length === 0 || enqueueToast == null) {
    return;
  }

  const requiredFieldNames = requiredFields.map((node) => String(node.name));

  let toastMessage = '';

  if (requiredFields.length === 1) {
    toastMessage = `"${requiredFieldNames[0]}" field is required and cannot be deleted.`;
  } else {
    const allButLastFieldNames = requiredFieldNames
      .map((name) => `"${name}"`)
      .slice(0, -1)
      .join(', ');
    const lastFieldName = requiredFieldNames[requiredFieldNames.length - 1];
    toastMessage = `${allButLastFieldNames}${requiredFieldNames.length > 2 ? ',' : ''} and "${lastFieldName}" fields are required and cannot be deleted.`;
  }

  enqueueToast(toastMessage);
};

export const insertContentButPreserveRequiredFields = ({
  editor,
  entirelySelectedRequiredEntries,
  selection,
  onComplete = NOOP,
  enqueueToast,
  select = true,
  variant,
}: {
  editor: EditorType,
  entirelySelectedRequiredEntries: NodeEntry<empty>[],
  selection: RangeType,
  onComplete?: () => void,
  enqueueToast?: (msg: React$Node, options?: $ReadOnly<$Partial<Toast>>) => ToastKey,
  select?: boolean,
  variant?: AllReporterVariant,
}): void => {
  const requiredNodes: Array<NodeType> = entirelySelectedRequiredEntries.map(([node]) => node);

  Transforms.delete(editor, { at: selection });

  Transforms.insertFragment(editor, requiredNodes);

  warnOnRequiredFieldDeletion(requiredNodes, enqueueToast, variant);

  logger.info(
    '[insertContentButPreserveRequiredFields] Required field deletion prevented in insertText',
    { fieldNames: requiredNodes.map((node) => node.name), variant }
  );

  try {
    const nextNodeEntry = Editor.next(editor);
    Transforms.select(
      editor,
      nextNodeEntry != null ? Editor.start(editor, nextNodeEntry[1]) : Editor.end(editor, [])
    );
  } catch (e) {
    logger.warn('[requiredFields] Error setting selection after preserving required fields', e);
  }

  onComplete();
};

export const isRequiredFieldNode = (n: NodeType): boolean => n.required === true;

export const isEmptyRequiredFieldNode = (node: NodeType): boolean =>
  isRequiredFieldNode(node) &&
  (Node.string(node) === '' || (node.type === 'picklist' && Node.string(node) === ' '));

export const getEmptyRequiredFields = (content: SlateContent): NodeType[] => {
  const emptyRequiredFields: NodeType[] = [];

  const onElement = (elementNode: NodeType) => {
    if (isEmptyRequiredFieldNode(elementNode)) {
      emptyRequiredFields.push(elementNode);
    }
  };

  walkSlateContent(onElement)(content);

  return emptyRequiredFields;
};

export const moveSelectionToFirstEmptyRequiredField = (
  content: SlateContent,
  editor: EditorType
): void => {
  const firstRequiredNodeEntry = getFirstEmptyRequiredField(editor);
  if (!firstRequiredNodeEntry.done && firstRequiredNodeEntry.value != null) {
    const [node, path] = firstRequiredNodeEntry.value;

    try {
      // $FlowIgnore[prop-missing] EditorType vs ReactEditorType
      const target = ReactEditor.toDOMNode(editor, node);
      scrollIntoViewIfNeeded(target, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
        block: 'end',
      });
      selectAndFocusEditor(editor, path);
    } catch (e) {
      // no-op, this can happen if we run Slate headlessly since there will be no
      // associated DOM
    }
  }
};

// I can't use MutableRefObject to type with flow apparently? But its ok, even
// though this needs to be added to a dep array, the object is stable so it
// won't trigger a re-render
export const useDialogOpenStateRef = (dialogOpenState: boolean): { current: boolean } => {
  const isDialogOpenRef = useRef<boolean>(false);

  useEffect(() => {
    if (dialogOpenState !== isDialogOpenRef.current) {
      isDialogOpenRef.current = dialogOpenState;
    }
  }, [dialogOpenState]);

  return isDialogOpenRef;
};

export const getLowestRequiredFields = (editor: EditorType): Array<NodeEntry<empty>> => {
  return [
    ...Editor.nodes(editor, {
      match: (n: NodeType) => n.required === true,
      mode: 'lowest',
    }),
  ];
};

export const getSelectedRequiredFields = (editor: EditorType): Array<NodeEntry<empty>> => {
  return [
    ...Editor.nodes(editor, {
      match: (n: NodeType) => n.required === true,
    }),
  ];
};

export const getRequiredFieldsAtLocation = (
  editor: EditorType,
  at: LocationType | SpanType
): Array<NodeEntry<empty>> => {
  return [
    ...Editor.nodes(editor, {
      at,
      match: (n: NodeType) => n.required === true,
      mode: 'lowest',
    }),
  ];
};

const getFirstEmptyRequiredField = (editor: EditorType): IteratorResult<NodeEntry<empty>, void> => {
  return Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => isEmptyRequiredFieldNode(n),
  }).next();
};

// Generator of nodes within a given selection or the entire editor that are
// empty required fields
export const getEmptyTouchedRequiredFields = (
  editor: EditorType,
  selection: RangeType | null
): Generator<NodeEntry<NodeType>, void, void> =>
  Editor.nodes(editor, {
    at: selection ?? [],
    match: (n: NodeType) =>
      isSquareBracketType(String(n.type)) &&
      n.required === true &&
      // $FlowIgnore[incompatible-type]
      n.children.length === 1 &&
      // $FlowIgnore[incompatible-use]
      n.children[0].text.trim() === '',
    mode: 'all',
  });

export const getRequiredFieldsInRange = (
  editor: EditorType,
  selection: RangeType | null
): Generator<NodeEntry<NodeType>, void, void> =>
  Editor.nodes(editor, {
    at: selection ?? [],
    match: (n: NodeType) => isRequiredFieldNode(n),
    mode: 'all',
  });

export const isRequiredFieldInRange = (editor: EditorType, range: RangeType): boolean => {
  const maybeRequiredField = Editor.nodes(editor, {
    at: range,
    match: (n: NodeType) => isRequiredFieldNode(n),
  }).next();

  if (!maybeRequiredField.done) {
    return true;
  }

  return false;
};
