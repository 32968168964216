// @flow
import Appbar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import styles from './styles';
import { css } from 'styled-components';
import { SIDENAV_WIDTH } from 'domains/reporter/RichTextEditor/plugins/inlineBookmark/constants';
import { Spacings } from 'styles';

const defaultContent = <Typography>SIRONA</Typography>;

type GenericAppBarProps = {
  classes: typeof styles,
  content?: React$Node,
  children?: React$Node,
  fixed?: boolean,
  className?: string,
};

/**
 * Reusable generic app bar component with consistent styling throughout app
 * A variant of this should appear at the top of every view
 *
 */
function GenericAppBar({
  classes,
  content = defaultContent,
  children,
  fixed = false,
  className,
}: GenericAppBarProps) {
  return (
    <Appbar
      className={classNames(classes.appbar, className)}
      position={fixed ? 'fixed' : 'relative'}
      elevation={0}
      css={css`
        app-region: drag;
        padding-left: calc(env(titlebar-area-x, 0) - ${SIDENAV_WIDTH}px + ${Spacings.medium}px);
      `}
    >
      <Toolbar disableGutters={true} variant="dense" classes={{ root: classes.toolbar }}>
        {children ?? content}
      </Toolbar>
    </Appbar>
  );
}

const GenericAppBarWithStyles: React$AbstractComponent<
  $Rest<GenericAppBarProps, { classes: typeof styles }>,
  mixed,
> = withStyles(styles)(GenericAppBar);

export default GenericAppBarWithStyles;
