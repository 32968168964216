// @flow

import type { EditorType, NodeType } from '../../core';
import { Editor, ReactEditor } from '../../core';
import { usePlugins } from '../../hooks';
import { useCallback } from 'react';
import { PICKLIST_PLUGIN_ID, insertSelectedPicklistItem } from '../picklist';
import { createEditorWarning, partialEditor } from '../../utils';
import { usePicklistState } from '../../../Template/usePicklist';
import { unfurlMacroPlaceholderStatic } from '../macroPlaceholder';
import { findBestMatch } from 'utils/stringSimilarity';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import { logger } from 'modules/logger';
import { stringifyRange } from '../../utils/stringify';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';

// If picklists are not being applied as expected, these thresholds likely need to be tuned.
const PICKLIST_COMMAND_SIMILARITY_THRESHOLD = 0.41;
export const PICKLIST_COMMAND_WORDS = ['pick', 'option', 'take', 'choose', 'insert'];

type ParsePotentialPicklistCommandResult = {
  bestMatch: string,
  dictatedCommand: string,
  wordIndex: number,
} | null;

export const parsePotentialPicklistCommand = (
  words: string[]
): ParsePotentialPicklistCommandResult => {
  for (let i = 0; i < words.length; i++) {
    const { bestMatch, rating } = findBestMatch(words[i], PICKLIST_COMMAND_WORDS);

    if (rating > PICKLIST_COMMAND_SIMILARITY_THRESHOLD) {
      const dictatedCommand = words[i];
      return { bestMatch, dictatedCommand, wordIndex: i };
    }
  }
  return null;
};

/**
 * Returns a number if it finds one in the string that should be number. Otherwise
 * returns -1
 */
export const parsePicklistNumber = (numberString: ?string): number => {
  if (numberString == null) return -1;

  const potentialNumber = parseInt(numberString, 10);

  if (Number.isNaN(potentialNumber)) {
    return -1;
  }

  return potentialNumber;
};
export type GetPicklistOptionSelectionMatchReturn = {
  preMatchText: string,
  pickIndex: number,
  postMatchText: string,
};

export type GetPicklistOptionSelectionMatch = (
  text: string
) => ?GetPicklistOptionSelectionMatchReturn;

export type InsertSelectedPicklistItemByIndexItem = (pickIndex: number) => void;

export type UsePicklistDictationSelectionBag = $ReadOnly<{
  insertSelectedPicklistItemByIndex: InsertSelectedPicklistItemByIndexItem,
  getPicklistOptionSelectionMatch: GetPicklistOptionSelectionMatch,
}>;

export const usePicklistDictationSelection = ({
  editor,
}: {
  editor: ?EditorType,
}): UsePicklistDictationSelectionBag => {
  const { activatedPlugins, getEditorStateEnhancers } = usePlugins();

  const { picklists, macros, setSelectedPicklistOptionIds, activePicklist } = usePicklistState();

  const { onInsertTextRequiredField } = useRequiredFieldIndicator();

  const getPicklistOptionSelectionMatchCallback = useCallback(
    (text) => {
      if (text === '') return null;

      if (!activatedPlugins.includes(PICKLIST_PLUGIN_ID)) {
        throw new Error(
          `Cannot use the dictation plugin without using the ${PICKLIST_PLUGIN_ID} plugin.`
        );
      }

      const normalizedText = text.trim().toLowerCase();
      const words = normalizedText.split(' ');

      const picklistCommand = parsePotentialPicklistCommand(words);

      // a user *must* use a picklist command to insert a picklist item
      if (picklistCommand == null) {
        return null;
      }

      const pickIndex = parsePicklistNumber(words[picklistCommand.wordIndex + 1]) - 1;

      if (pickIndex < 0) {
        return null;
      }

      const preMatchText = words
        .filter((word, index) => index < picklistCommand.wordIndex)
        .join(' ');
      const postMatchText = words
        .filter((word, index) => index > picklistCommand.wordIndex + 1)
        .join(' ');

      return {
        preMatchText,
        pickIndex,
        postMatchText,
      };
    },
    [activatedPlugins]
  );

  const insertSelectedPicklistItemCallback = useCallback(
    (pickIndex) => {
      if (editor == null || editor.selection == null) return;
      const { selection } = editor;

      const nodes = [
        ...Editor.nodes(editor, {
          at: selection,
          match: (n: NodeType) =>
            n.type === PICKLIST_PLUGIN_ID && n.picklistID === activePicklist?.id,
        }),
      ];

      const picklistEntry = nodes[0];
      if (picklistEntry == null) return;

      const [picklistNode] = picklistEntry;

      const foundPicklist = picklists.find((picklist) => picklist.id === picklistNode.picklistID);

      if (!foundPicklist) {
        const missingPicklistError = `[usePicklistDictationSelection] Could not find a picklist from the picklist ID ${picklistNode.picklistID}. Skipping picklist shortcut.`;
        createEditorWarning(missingPicklistError);

        logger.error(missingPicklistError, {
          editor: editor != null ? partialEditor(editor) : 'null',
          picklistID: picklistNode.picklistID,
        });
        return;
      }

      const optionToInsert = foundPicklist.options?.[pickIndex];
      if (optionToInsert == null) return;

      analytics.track(reporter.usr.voiceCommand, {
        commandType: 'InsertPicklist',
        selection: editor.selection != null ? stringifyRange(editor.selection) : 'null',
      });

      insertSelectedPicklistItem(
        editor,
        [...picklistEntry],
        unfurlMacroPlaceholderStatic(optionToInsert.text, macros, getEditorStateEnhancers(), {
          fragmentMode: true,
        }),
        // $FlowIgnore[prop-missing]
        () => onInsertTextRequiredField(ReactEditor.findKey(editor, picklistNode))
      );

      setSelectedPicklistOptionIds((prev) => ({
        ...prev,
        [foundPicklist.id]: optionToInsert.id,
      }));
    },
    [
      activePicklist?.id,
      editor,
      getEditorStateEnhancers,
      macros,
      onInsertTextRequiredField,
      picklists,
      setSelectedPicklistOptionIds,
    ]
  );

  return {
    getPicklistOptionSelectionMatch: getPicklistOptionSelectionMatchCallback,
    insertSelectedPicklistItemByIndex: insertSelectedPicklistItemCallback,
  };
};
