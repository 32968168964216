// @flow

import { useMutation, useQuery } from '@apollo/client';
import type {
  GetWorklistCustomStatusesQuery,
  GetWorklistCustomStatusesQueryVariables,
} from 'generated/graphql';
import { GET_WORKLIST_CUSTOM_STATUSES } from 'modules/Apollo/queries';
import { useCallback, useMemo, useRef } from 'react';
import type { WorklistCustomStatus } from '../generated/graphql';
import {
  CHANGE_WORKLIST_ITEM_CUSTOM_STATUS,
  UNDO_CHANGE_WORKLIST_ITEM_CUSTOM_STATUS,
} from '../modules/Apollo/queries';
import { logger } from '../modules/logger';
import { NAMESPACES, sendEvent } from '../modules/EventsManager/eventsManager';
import { useCurrentCase } from './useCurrentCase';
import { useToasterDispatch } from '../common/ui/Toaster/Toaster';
import type { MultiSelectFilterItemType } from '../domains/worklist/WorklistFilters/types';

// Used to render an Em Dash
export const NULL_WORKLIST_CUSTOM_STATUS = '\u2014';

export const formatStatusName = (name: string): string => {
  if (!name) {
    return '';
  }

  // List of words not to capitalize
  const wordsNotToCapitalize = ['to', 'and', 'for', 'of', 'on', 'at', 'with', 'by', 'from'];

  return name
    .toLowerCase()
    .split('_')
    .map((word, index) =>
      wordsNotToCapitalize.includes(word) && index !== 0
        ? word
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(' ');
};

const createFilterObject = (statuses: ?$ReadOnlyArray<WorklistCustomStatus>) => {
  if (statuses == null) return [];

  return statuses
    .filter((status) => status.smid !== NULL_WORKLIST_CUSTOM_STATUS)
    .map((status) => {
      return { value: status.smid, label: formatStatusName(status.name) };
    });
};

type UpdateCustomStatusProps = {
  currentCaseId: string,
  prevCustomStatus: ?$Diff<WorklistCustomStatus, { __typename?: 'WorklistCustomStatus' }>,
  customStatus: $Diff<WorklistCustomStatus, { __typename?: 'WorklistCustomStatus' }>,
  accessionNumber: string,
  groupId: ?string,
};

type UseWorklistCustomStatusReturn = {
  customStatuses: Array<WorklistCustomStatus>,
  customStatusFilters: MultiSelectFilterItemType[],
  isLoadingUndoUpdateCustomStatus: boolean,
  isLoadingUpdateCustomStatus: boolean,
  isLoadingCustomStatuses: boolean,
  updateCustomStatus: (UpdateCustomStatusProps) => Promise<void>,
  undoUpdateCustomStatus: (UpdateCustomStatusProps) => Promise<void>,
};

export function useWorklistCustomStatuses(): UseWorklistCustomStatusReturn {
  const { refreshCase } = useCurrentCase();
  const { data: customStatusesData, loading: isLoadingCustomStatuses } = useQuery<
    GetWorklistCustomStatusesQuery,
    GetWorklistCustomStatusesQueryVariables,
  >(GET_WORKLIST_CUSTOM_STATUSES);
  const toastKey = useRef('');

  const customStatuses = useMemo(() => {
    const statuses = customStatusesData?.worklistCustomStatuses ?? [];
    return statuses.map((status) => ({
      ...status,
      name: formatStatusName(status.name),
    }));
  }, [customStatusesData]);

  const customStatusFilters = useMemo(() => {
    return createFilterObject(customStatusesData?.worklistCustomStatuses);
  }, [customStatusesData?.worklistCustomStatuses]);

  const [changeWorklistItemCustomStatus, { loading: isLoadingUpdateCustomStatus }] = useMutation(
    CHANGE_WORKLIST_ITEM_CUSTOM_STATUS
  );

  const [undoChangeWorklistItemCustomStatus, { loading: isLoadingUndoUpdateCustomStatus }] =
    useMutation(UNDO_CHANGE_WORKLIST_ITEM_CUSTOM_STATUS);

  const { enqueueOrUpdateToast, closeToast, enqueueToast } = useToasterDispatch();

  const undoUpdateCustomStatus = useCallback(
    async ({
      currentCaseId,
      prevCustomStatus,
      customStatus,
      accessionNumber,
      groupId,
    }: UpdateCustomStatusProps) => {
      if (prevCustomStatus == null) {
        return;
      }

      await undoChangeWorklistItemCustomStatus({
        variables: {
          smid: currentCaseId,
          customStatusID: prevCustomStatus.smid,
        },
        onError: (error) => {
          if (groupId == null) {
            logger.error(
              `[useWorklistCustomStatus] - Error occurred when changing custom status of case ${currentCaseId} from "${prevCustomStatus.name}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus.smid,
              }
            );
          } else {
            logger.error(
              `[useWorklistCustomStatus] - Error occurred when changing custom status of group ${groupId}, triggered by case ${currentCaseId}, from "${prevCustomStatus.name}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus.smid,
                groupId,
              }
            );
          }

          enqueueToast(
            `An error occurred when undoing the change to the workflow status for ${accessionNumber ?? 'N/A'}. Please try again.`,
            {
              severity: 'error',
              position: 'top-right',
              yOffset: 45,
            }
          );
        },
        onCompleted: async (data): Promise<void> => {
          if (groupId == null) {
            logger.info(
              `[useWorklistCustomStatus] - Undoing custom status change of case ${currentCaseId} from "${customStatus.name}" back to "${prevCustomStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus.smid,
              }
            );
          } else {
            logger.info(
              `[useWorklistCustomStatus] - Undoing custom status change of group ${groupId}, triggered by case ${currentCaseId}, from "${customStatus.name}" back to "${prevCustomStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus.smid,
                groupId,
              }
            );
          }

          await refreshCase();

          sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
            type: 'claimedItems',
          });

          sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
            type: 'worklistItemGroup',
          });

          closeToast(toastKey.current);

          enqueueOrUpdateToast(
            `Workflow status change has been undone for ${accessionNumber ?? 'N/A'} and returned to your queue.${groupId != null ? ' Its grouped exams have been changed and returned to your queue as well.' : ''}`,
            toastKey.current,
            {
              severity: 'default',
              position: 'top-right',
              icon: 'greenCheck',
              yOffset: 45,
            }
          );
        },
      });
    },
    [
      undoChangeWorklistItemCustomStatus,
      enqueueToast,
      refreshCase,
      closeToast,
      enqueueOrUpdateToast,
    ]
  );

  const updateCustomStatus = useCallback(
    async ({
      currentCaseId,
      prevCustomStatus,
      customStatus,
      accessionNumber,
      groupId,
    }: UpdateCustomStatusProps) => {
      await changeWorklistItemCustomStatus({
        variables: {
          smid: currentCaseId,
          customStatusID: customStatus.smid,
        },
        onError: (error) => {
          if (groupId == null) {
            logger.error(
              `[useWorklistCustomStatus] - Error occurred when changing custom status of case ${currentCaseId} from "${prevCustomStatus?.name ?? 'null'}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus?.smid,
              }
            );
          } else {
            logger.error(
              `[useWorklistCustomStatus] - Error occurred when changing custom status of group ${groupId}, triggered by case ${currentCaseId}, from "${prevCustomStatus?.name ?? 'null'}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus?.smid,
                groupId,
              }
            );
          }

          toastKey.current = enqueueOrUpdateToast(
            `An error occurred when changing the workflow status for ${accessionNumber ?? 'N/A'}. Please try again.`,
            toastKey.current,
            {
              severity: 'error',
              position: 'top-right',
              yOffset: 45,
            }
          );
        },
        onCompleted: async (data): Promise<void> => {
          if (groupId == null) {
            logger.info(
              `[useWorklistCustomStatus] - Custom status of case ${currentCaseId} changed from "${prevCustomStatus?.name ?? 'null'}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus?.smid,
              }
            );
          } else {
            logger.info(
              `[useWorklistCustomStatus] - Custom status of group ${groupId}, triggered by case ${currentCaseId}, changed from "${prevCustomStatus?.name ?? 'null'}" to "${customStatus.name}"`,
              {
                currentCaseId,
                customStatusID: customStatus.smid,
                prevCustomStatusID: prevCustomStatus?.smid,
                groupId,
              }
            );
          }

          await refreshCase();

          sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
            type: 'claimedItems',
          });

          sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
            type: 'worklistItemGroup',
          });

          const undoProps =
            prevCustomStatus != null
              ? {
                  actionColor: 'default',
                  actionName: 'UNDO',
                  actionHandler: () =>
                    undoUpdateCustomStatus({
                      currentCaseId,
                      prevCustomStatus,
                      customStatus,
                      accessionNumber,
                      groupId,
                    }),
                }
              : {};

          toastKey.current = enqueueOrUpdateToast(
            `Workflow status has been changed for ${accessionNumber ?? 'N/A'} and removed from your queue.${groupId != null ? ' Its grouped exams have been changed and removed from your queue as well.' : ''}`,
            toastKey.current,
            {
              severity: 'default',
              position: 'top-right',
              icon: 'greenCheck',
              autoHideDuration: 8000,
              yOffset: 45,
              ...undoProps,
            }
          );
        },
      });
    },
    [changeWorklistItemCustomStatus, enqueueOrUpdateToast, refreshCase, undoUpdateCustomStatus]
  );

  return useMemo(
    () => ({
      customStatuses,
      customStatusFilters,
      undoUpdateCustomStatus,
      updateCustomStatus,
      isLoadingUpdateCustomStatus,
      isLoadingUndoUpdateCustomStatus,
      isLoadingCustomStatuses,
    }),
    [
      customStatuses,
      customStatusFilters,
      undoUpdateCustomStatus,
      updateCustomStatus,
      isLoadingUpdateCustomStatus,
      isLoadingUndoUpdateCustomStatus,
      isLoadingCustomStatuses,
    ]
  );
}
