// @flow

import GenericAppBar from 'common/GenericAppBar/GenericAppBar';
import { Stack } from 'common/ui/Layout';

type ToolbarProps = {
  children: React$Node,
  toolbarCss?: string,
  fixed?: boolean,
};

export const Toolbar = ({ children, toolbarCss, fixed = false }: ToolbarProps): React$Node => {
  return (
    <GenericAppBar fixed={fixed}>
      <Stack
        data-testid="common-ui-toolbar"
        alignX="between"
        alignY="center"
        space="small"
        css={`
          height: 100%;
          width: env(titlebar-area-width, 100%);
          ${toolbarCss ?? ''}
        `}
      >
        {children}
      </Stack>
    </GenericAppBar>
  );
};
