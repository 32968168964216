// @flow

import type { ToastKey } from 'common/ui/Toaster/Toast';
import type { Toast } from 'common/ui/Toaster/Toaster';
import type { AllReporterVariant } from '../../hooks/useReporter';
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { SetterOrUpdater } from 'recoil';
import type { Key } from 'slate-react';
import type { RangeType, EditorType } from '../../core';

export const REQUIRED_FIELDS_PLUGIN_ID: 'requiredFields' = 'requiredFields';
export type RequiredFieldsPluginID = typeof REQUIRED_FIELDS_PLUGIN_ID;

export type RequiredFieldsPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  setConfirmDeletionDialogState?: SetterOrUpdater<{
    deleteCallback: () => void,
    open: boolean,
    isMultiple: boolean,
  }>,
  isDialogOpenRef?: { current: boolean },
  variant?: AllReporterVariant,
  enqueueToast?: (msg: React$Node, options?: $ReadOnly<$Partial<Toast>>) => ToastKey,
  onInsertTextRequiredField?: (path: Key) => void,
  syncRequiredFields?: (selection: RangeType, editor: EditorType) => void,
}>;

export type RequiredFieldsPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...RequiredFieldsPluginOptions,
  pluginID: RequiredFieldsPluginID,
  pluginName: string,
}>;
