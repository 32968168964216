// @flow

import type { EditorPlugin } from '../../types';
import { getRenderElement } from '../../utils';
import { DeepLinkElement } from './components';
import { enhanceEditorState } from './enhanceEditorState';
import { enhanceProvider } from './enhanceProvider';
import { onKeyDown } from './onKeyDown';
import { DEEP_LINK_PLUGIN_ID } from './types';
import type {
  DeepLinkPluginElement,
  DeepLinkPluginOptions,
  DeepLinkPluginPropertyOptions,
} from './types';
import type { EditorType } from 'slate';

/**
 * A node component that represents a link to another part of the application (image,
 * measure, location) with a specific target.
 *
 * Handles broadcast events to insert a deep link and places it at the cursor location.
 *
 * @param {DeepLinkPluginOptions} options - Plugin options
 */
export const DeepLinkPlugin = ({
  component = DeepLinkElement,
  pluginName = 'Deep Link',
  onDeepLinkClick = (editor: DeepLinkPluginElement | null, element: EditorType | null) => {},
  onDeepLinkEditClick = (editor: DeepLinkPluginElement | null, element: EditorType | null) => {},
  getIsEditingStudyNode,
  ...rest
}: DeepLinkPluginOptions = {}): EditorPlugin<> => {
  const options: DeepLinkPluginPropertyOptions = {
    pluginID: DEEP_LINK_PLUGIN_ID,
    pluginName,
    component,
    onDeepLinkClick,
    onDeepLinkEditClick,
    getIsEditingStudyNode,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement(options),
    onKeyDown: onKeyDown(options),
    enhanceProvider: enhanceProvider(options),
    enhanceEditorState: enhanceEditorState(options),
  };
};
