// @flow
import { useEffect } from 'react';
import type { CreateEnhanceProvider } from '../../types';
import type { GeneralKeyboardControlsPluginPropertyOptions } from './types';
import type { EditorType } from '../../core';
import { useEventsListener, NAMESPACES } from 'modules/EventsManager';
import { selectNextBracket, selectPreviousBracket } from '../../utils';
import { useRecorder } from 'common/Recorder/useRecorder';
import { unreachableCaseLog } from 'types';
import { useMostRecentInput, INPUT_TYPES, RICH_TEXT_EDITOR } from 'hooks/useMostRecentInput';
import { useImpressionGenerator } from 'hooks/useImpressionGenerator';
import { RESPONSE_EVENTS as EXTENSION_RESPONSE_EVENTS } from 'domains/extension/constants';
import {
  addExtensionListener,
  removeExtensionListener,
} from 'domains/extension/extensionEventCreators';
import type { SlateProps } from 'slate-react';

type ProviderComponentProps = $ReadOnly<{
  editor: EditorType,
  children: React$Node,
  ignoreMergeFieldsInNavigation?: boolean,
}>;

export const ProviderComponent = ({
  children,
  editor,
  ignoreMergeFieldsInNavigation,
  ...rest
}: ProviderComponentProps): React$Node => {
  const { toggleRecording } = useRecorder();
  const { setMostRecentInput } = useMostRecentInput(RICH_TEXT_EDITOR);
  const { generateAndStitchImpression } = useImpressionGenerator();

  useEventsListener(NAMESPACES.REPORTER_SHORTCUT, ({ source, payload }) => {
    setMostRecentInput(INPUT_TYPES.KEYBOARD);

    const { action } = payload;

    switch (action) {
      case 'nextBracket':
        selectNextBracket(editor, { ignoreMergeFieldsInNavigation });
        break;
      case 'previousBracket':
        selectPreviousBracket(editor, {
          ignoreMergeFieldsInNavigation,
        });
        break;
      case 'toggleRecording':
        toggleRecording();
        break;
      case 'draftReport':
      case 'submitReport':
      case 'discardReport':
        // This is handled in Fields.Context so that its provider does not need to be lifted further.
        break;
      case 'generateImpression':
        generateAndStitchImpression(editor);
        break;

      default:
        unreachableCaseLog(action);
        return;
    }
  });

  useEffect(() => {
    // $FlowIssue[prop-missing] Flow doesn't support custom detail type
    const handler: EventListener = ({ detail }) => {
      setMostRecentInput(INPUT_TYPES.KEYBOARD);
      switch (detail) {
        case 'reporter-next-field':
          selectNextBracket(editor, { ignoreMergeFieldsInNavigation });
          break;
        case 'reporter-previous-field':
          selectPreviousBracket(editor, { ignoreMergeFieldsInNavigation });
          break;
        case 'reporter-toggle-recording':
          toggleRecording();
          break;
        case 'reporter-submit-report':
        case 'reporter-discard-report':
          // This is handled in Fields.Context so that its provider does not need to be lifted further.
          break;
        case 'reporter-generate-impression':
          generateAndStitchImpression(editor);
          break;
        default:
          unreachableCaseLog(detail);
          return;
      }
    };

    addExtensionListener(EXTENSION_RESPONSE_EVENTS.SHORTCUT_TRIGGERED, handler);

    return () => {
      removeExtensionListener(EXTENSION_RESPONSE_EVENTS.SHORTCUT_TRIGGERED, handler);
    };
  }, [
    editor,
    generateAndStitchImpression,

    ignoreMergeFieldsInNavigation,
    setMostRecentInput,
    toggleRecording,
  ]);

  return children;
};

export const enhanceProviderGeneralKeyboardControls: CreateEnhanceProvider<
  GeneralKeyboardControlsPluginPropertyOptions,
> =
  ({ pluginID }) =>
  (Component: React$ComponentType<SlateProps>) =>
    function EnhanceProviderGeneralKeyboardControls(props) {
      return (
        <ProviderComponent editor={props.editor}>
          <Component {...props} />
        </ProviderComponent>
      );
    };
