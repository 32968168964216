// @flow

import type { InlineBookmarkPluginElement } from '../types';
import { InlineTemplateElement } from '../../common/inlineTemplate';
import { EMPTY } from 'config/constants';
import { forwardRef } from 'react';

type InlineBookmarkElementProps = {
  selected?: boolean,
  element: InlineBookmarkPluginElement,
  children: React$Node,
  handleClick?: () => void,
  'data-editor-element'?: string,
  'data-slate-node'?: 'element',
  'data-slate-inline'?: true,
  'data-slate-void'?: true,
  dir?: 'rtl',
  variant?: string,
};

export const InlineBookmarkElement: React$AbstractComponent<
  InlineBookmarkElementProps,
  HTMLElement,
> = forwardRef<InlineBookmarkElementProps, HTMLElement>(
  ({ selected, element, handleClick, attributes = EMPTY.OBJECT, ...rest }, ref) => {
    return (
      <InlineTemplateElement
        ref={ref}
        {...rest}
        {...attributes}
        selected={selected}
        testID="inlineBookmark"
        data-selected={selected}
        element={element}
        handleClick={handleClick}
      />
    );
  }
);
InlineBookmarkElement.displayName = 'InlineBookmarkElement';
