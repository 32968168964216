// @flow

import type { EditorPlugin } from '../../types';
import { PlaceholderEditable, PlaceholderDropdown } from './components';
import { PLACEHOLDER_FIELDS } from './constants';
import { getRenderElement, getRenderToolbar } from '../../utils';
import { PLACEHOLDER_PLUGIN_ID } from './types';
import type { PlaceholderPluginOptions, PlaceholderPluginPropertyOptions } from './types';
import { enhanceEditorStatePlaceholder } from './enhanceEditorStatePlaceholder';
import { onKeyDownPlaceholder } from './onKeyDownPlaceholder';
import CodeIcon from '@material-ui/icons/Code';

export const PlaceholderPlugin = ({
  component = PlaceholderEditable,
  placeholderFields = PLACEHOLDER_FIELDS,
  pluginName = 'Placeholder',
  ...rest
}: PlaceholderPluginOptions): EditorPlugin<> => {
  const options: PlaceholderPluginPropertyOptions = {
    pluginID: PLACEHOLDER_PLUGIN_ID,
    pluginName,
    placeholderFields,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement<PlaceholderPluginPropertyOptions>(options, {
      spreadAttributes: false,
    }),
    enhanceEditorState: enhanceEditorStatePlaceholder(options),
    onKeyDown: onKeyDownPlaceholder(options),
    toolbarConfig: () => ({
      pluginID: options.pluginID,
      toolbarButton: <CodeIcon />,
      component: getRenderToolbar({ ...options, component: PlaceholderDropdown }),
      options: {
        tooltipText: 'Merge Fields',
        ariaLabel: 'Merge Fields Toolbar Button',
      },
    }),
  };
};
