// @flow

import type { PathType, EditorType, NodeType, RangeType, NodeEntry } from '../../core';
import { LIST_PLUGIN_ID } from './types';
import { Editor, Node, Path, Range, Transforms, Text } from '../../core';
import type { ListVariant } from './constants';
import type { CurrentActiveListVariantState } from './hooks/useCurrentList';
import { LIST_VARIANTS } from './constants';
import { PARAGRAPH_LIST_ITEM_VARIANT, PARAGRAPH_PLUGIN_ID } from '../paragraph/types';
import {
  createListItemNode,
  createListItemNodeWithChildren,
  decreaseListIndentation,
  findClosestBlockPathAtSelection,
  getCurrentListAtPath,
  isInsideListAtPath,
  isInsideListAtSelection,
  isListItemEmpty,
  increaseListIndentation,
  triggerListInsertion,
} from './utils';
import { splitInlineBookmark } from '../inlineBookmark/utils';
import { logger } from 'modules/logger';
import { partialEditor } from '../../utils';
import type { ParagraphPluginElement } from '../paragraph/types';
import { removeBlocksInRange } from '../../utils/removeBlocksInRange';
import { getBlocksInRange } from '../../utils/getBlocksInRange';
import type { LocationType } from 'slate';

/**
 *
 * This file houses the functions directly assigned to a UI button press, keyboard command
 * or dictation command.
 * Any other helper functions sit in the utils file.
 */

/**
 * Unsets one or more list items.
 * If no selection is provided, it will unset the list item at the current selection.
 *
 * The rules for unsetting are as follows:
 * - All selected items will be unset, including the children of the last item selected if any.
 * - The first selected item determines the level of indentation for the remaining items,
 *   which includes the children of the last item selected. (Note: this is not equivalent to
 *   the level of indentation of the first selected item, see next point)
 * - The indentation level is set to the previous node's indentation. For example:
 *   - If the first selected item is the first of its siblings, the indentation level
 *     will be set to the previous node's indentation level.
 *   - If the first selected item is NOT the first of its siblings (if any),
 *     the indentation level will remain the same.
 *
 * These rules are in place to ensure that it simultaneously obeys the list structure
 * and creates a logical format for the user.
 */
export const demoteListItems = (editor: EditorType, path?: RangeType) => {
  const selection = path ?? editor?.selection;
  if (editor == null || selection == null) return;
  // Get all list items in the selection
  const listItemMatches = Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n: NodeType) => n.variant === PARAGRAPH_LIST_ITEM_VARIANT,
    })
  );
  if (listItemMatches.length === 0) return;

  /**
   * Let's get the targetPath to move the nodes to.
   */
  const firstItem = listItemMatches[0];
  // Capture the path of the previous node so we can move them to the same indentation level.
  const prevNode = Editor.previous(editor, {
    at: firstItem[1],
    match: (n: NodeType) => n.type === PARAGRAPH_PLUGIN_ID,
  });
  /**
   * If the list is the very first node in the editor (i.e. very top of the editor),
   * and the item being demoted is the first item in the list, we need to move it to the top of the editor.
   */
  const topNode = Editor.node(editor, [0]);
  let targetPath = topNode[1];
  if (prevNode != null) {
    targetPath = [...prevNode[1]];
    targetPath[targetPath.length - 1] += 1;
  }

  /**
   * Get the children of the last item in the selection.
   * These will also need to be moved to the same indentation level
   * to avoid breaking the list structure
   */
  const lastItemChildren: NodeEntry<NodeType>[] = [];
  const lastItem = listItemMatches[listItemMatches.length - 1];
  const rootItem = lastItem[1].length > firstItem[1].length ? firstItem : lastItem;
  const parentOfRootItem = Editor.parent(editor, rootItem[1]);
  const allItemsAtRootItemLevel = Editor.nodes(editor, {
    at: parentOfRootItem[1],
    match: (n: NodeType) => [LIST_PLUGIN_ID, PARAGRAPH_PLUGIN_ID].includes(n.type),
  });
  let result;
  let hasChildren = false;
  while ((result = allItemsAtRootItemLevel.next()) && !result.done) {
    const item = result.value;
    // Advance to the node after the last selected item
    if (Path.isAfter(item[1], lastItem[1])) {
      // If the last selected item has children, they are book-ended by list nodes
      if (item[0].type === LIST_PLUGIN_ID && item[1].length === rootItem[1].length) {
        if (hasChildren) {
          break;
        }
        hasChildren = true;
        continue;
      }
      // Break if the subsequent node is a sibling list item
      if (
        item[0].variant === PARAGRAPH_LIST_ITEM_VARIANT &&
        item[1].length === rootItem[1].length
      ) {
        break;
      }
      lastItemChildren.push(item);
    }
  }

  // Reverse order to ensure that we handle last items first to maintain path order
  listItemMatches.reverse();
  lastItemChildren.reverse();

  const pathRefs = [...lastItemChildren, ...listItemMatches].map((entry) =>
    Editor.pathRef(editor, entry[1])
  );

  Editor.withoutNormalizing(editor, () => {
    for (const pathRef of pathRefs) {
      if (pathRef?.current != null) {
        Transforms.unsetNodes(editor, 'variant', { at: pathRef.current });
        // unsetting should not make pathRef.current null...
        if (pathRef?.current != null) {
          Transforms.moveNodes(editor, { at: pathRef.current, to: targetPath });
        }
      }
    }
  });
};

export const increaseIndent = (
  editor: ?EditorType,
  currentActiveListVariant: ?CurrentActiveListVariantState
) => {
  if (editor != null && currentActiveListVariant != null) {
    const path = findClosestBlockPathAtSelection(editor);

    increaseListIndentation(editor, LIST_VARIANTS[currentActiveListVariant], path);
  }
};

export const decreaseIndent = (
  editor: ?EditorType,
  currentActiveListVariant: ?CurrentActiveListVariantState
) => {
  if (editor != null && editor.selection != null && currentActiveListVariant != null) {
    const path = findClosestBlockPathAtSelection(editor);
    decreaseListIndentation(editor, LIST_VARIANTS[currentActiveListVariant], path);
  }
};
/**
 * Voice Command: InsertIndentedBullet / InsertIndentedNumbering
 *
 * If dealing with expanded selection, or if no selection, do nothing.
 *
 * If not inside a list, insert a new list with a single list item instead
 *
 * If inside empty list item, increase the indent instead
 *
 * If inside non-empty list item, split the list item based on selection, and then increase indent
 */
export const indentNextListItem = (editor: EditorType, variant: ListVariant): void => {
  if (editor.selection != null && !Range.isCollapsed(editor.selection)) {
    return;
  }

  const path = findClosestBlockPathAtSelection(editor);

  // insert a list with a single list item instead, if not inside a list yet
  if (!isInsideListAtPath(editor, path)) {
    return insertList(editor, variant);
  }

  const node = Node.get(editor, path);

  // split the current list item onto a new line.
  if (!isListItemEmpty(node)) {
    splitInlineBookmark(editor);
  }

  increaseIndent(editor, variant);
};

/**
 *
 * Builds upon decreaseIndent, where it keeps decreasing indent until outside any lists, from any depth
 *
 */
export const exitAllLists = (
  editor: EditorType,
  currentActiveListVariant: ?CurrentActiveListVariantState
) => {
  if (editor != null && currentActiveListVariant != null) {
    while (
      isInsideListAtPath(editor, findClosestBlockPathAtSelection(editor)) ||
      isInsideListAtSelection(editor)
    ) {
      decreaseIndent(editor, currentActiveListVariant);
    }
  }
};

/**
 *
 * Each block in the selection, will become a list item in a new list of the given variant
 *
 */
export const insertList = (editor: ?EditorType, variant: ?ListVariant, path?: RangeType) => {
  if (editor != null && variant != null) {
    const selection = path ?? editor.selection;

    if (selection == null) {
      const selectionErrorMessage = '[list/functions] Null selection inside insertList';
      logger.error(selectionErrorMessage, {
        editor: partialEditor(editor),
        variant,
      });
      return;
    }

    Editor.withoutNormalizing(editor, () => {
      if (Range.isCollapsed(selection)) {
        const currentParentNode = Editor.above(editor, {
          at: selection,
          match: (n: NodeType) => Node.isNode(n) && !Text.isText(n),
          mode: 'lowest',
        });

        let insertionPoint: ?LocationType = selection;

        if (
          path == null &&
          editor.children.length > 1 &&
          currentParentNode != null &&
          Node.string(currentParentNode[0]) === ''
        ) {
          Transforms.removeNodes(editor, { at: currentParentNode[1] });
          insertionPoint = editor?.selection;
        }

        if (insertionPoint != null) {
          triggerListInsertion({
            editor,
            variant,
            at: insertionPoint,
            listItemNodes: [createListItemNode()],
            edge: 'start',
          });
        }
      } else {
        const blocks = getBlocksInRange(editor, selection);
        if (blocks.length > 1) {
          const listItemNodes = createListItemNodesFromBlocks(editor, blocks);
          removeBlocksInRange(editor, selection);

          // Insert the list using the path of the first block.
          // This means collapsed selections, single-block-expanded selections
          // and multi-block-expanded selections are all covered
          const insertionPath = blocks[0][1];
          triggerListInsertion({
            editor,
            variant,
            at: insertionPath,
            listItemNodes,
            edge: 'end',
          });
        } else if (selection != null) {
          const textContent = Editor.string(editor, selection);
          const listItems = createListItemNode(textContent);

          Transforms.delete(editor, { at: selection, mode: 'lowest' });
          let insertionPath: ?LocationType = Editor.start(editor, selection);

          const currentParentNode = Editor.above(editor, {
            at: selection,
            match: (n: NodeType) => Node.isNode(n) && !Text.isText(n),
            mode: 'lowest',
          });

          if (
            path == null &&
            editor.children.length > 1 &&
            currentParentNode != null &&
            Node.string(currentParentNode[0]) === ''
          ) {
            Transforms.removeNodes(editor, { at: currentParentNode[1] });
            insertionPath = editor?.selection;
          }

          if (insertionPath != null) {
            triggerListInsertion({
              editor,
              variant,
              at: insertionPath,
              listItemNodes: [listItems],
              edge: 'end',
            });
          }
        }
      }
    });
  }
};

/**
 *
 * Each block in the selection, will become a list item in a new list of the given variant
 *
 */
export const extendPreviousList = (
  editor: ?EditorType,
  variant: ?ListVariant,
  range: RangeType,
  previousList: PathType
) => {
  if (editor != null && variant != null) {
    const selection = range ?? editor.selection;

    if (selection == null) {
      const selectionErrorMessage = '[list/functions] Null selection inside extendPreviousList';
      logger.error(selectionErrorMessage, {
        editor: partialEditor(editor),
        variant,
      });
      return;
    }

    const blocks = getBlocksInRange(editor, selection);
    const listItemNodes = createListItemNodesFromBlocks(editor, blocks);

    removeBlocksInRange(editor, selection);

    const previousNode = Editor.node(editor, previousList);
    if (previousNode == null) {
      return;
    }

    const listEntry =
      previousNode[0].type === LIST_PLUGIN_ID
        ? previousNode
        : getCurrentListAtPath(editor, previousList);
    const endInsertionPoint =
      // $FlowIgnore[incompatible-use] - Node.children is definitely an array
      listEntry != null ? [...listEntry[1], listEntry[0].children.length] : null;

    if (endInsertionPoint != null) {
      Transforms.insertNodes(editor, listItemNodes, {
        at: endInsertionPoint,
      });
    }
  }
};

export const createListItemNodesFromBlocks = (
  editor: EditorType,
  blocks: Array<NodeEntry<NodeType>>
): Array<ParagraphPluginElement> => {
  // Gather list nodes that correspond to the above blocks
  return blocks.map(([blockNode]) => {
    const isTextParagraph = [...Node.children(blockNode, [])].every((child) =>
      Text.isText(child[0])
    );

    return isTextParagraph
      ? createListItemNode(Node.string(blockNode))
      : // $FlowIgnore[incompatible-call] - Node.children is definitely an array
        createListItemNodeWithChildren(blockNode.children);
  });
};

export const createListItemNodesFromRange = (
  editor: EditorType,
  range: RangeType
): Array<ParagraphPluginElement> => {
  const blocks = getBlocksInRange(editor, range);

  // Gather list nodes that correspond to the above blocks
  return blocks.map(([blockNode]) => {
    const isTextParagraph = [...Node.children(blockNode, [])].every((child) =>
      Text.isText(child[0])
    );

    return isTextParagraph
      ? createListItemNode(Node.string(blockNode))
      : // $FlowIgnore[incompatible-call] - Node.children is definitely an array
        createListItemNodeWithChildren(blockNode.children);
  });
};

export const switchListVariant = (editor: EditorType, range?: RangeType): void => {
  const selection = range ?? editor?.selection;
  if (!selection) return;

  // Find the nearest list node from the selection
  const listNode = Editor.above(editor, {
    at: range,
    match: (n: NodeType) => n.type === LIST_PLUGIN_ID,
  });

  if (!listNode) return;

  const [node, nodePath] = listNode;

  const newVariant = node.variant === LIST_VARIANTS.ol ? LIST_VARIANTS.ul : LIST_VARIANTS.ol;

  Transforms.setNodes(editor, { variant: newVariant }, { at: nodePath });
};

/**
 * Voice Command: NextBullet / NextNumber
 *
 * If dealing with expanded selection, or if no selection, do nothing.
 *
 * If not inside a list, insert a new list with a single list item instead
 *
 * If inside list item, split list item based on selection,
 *
 * i.e empty list item creates new empty list item and
 * non-empty list item moves content after selection to next list item
 */
export const insertNextListItem = (editor: EditorType, variant: ListVariant): void => {
  if (editor.selection != null && !Range.isCollapsed(editor.selection)) {
    return;
  }

  const path = findClosestBlockPathAtSelection(editor);
  if (isInsideListAtPath(editor, path)) {
    splitInlineBookmark(editor);
  } else {
    insertList(editor, variant);
  }
};
