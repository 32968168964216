// @flow

import styled from 'styled-components';

import {
  BoldRenderer,
  ItalicRenderer,
  UnderlineRenderer,
  ParagraphRenderer,
  InlineBookmarkRenderer,
  OrderedListRenderer,
  UnorderedListRenderer,
  PicklistRenderer,
  PlaceholderRenderer,
  HighlightedRenderer,
  HeadingRenderer,
  LineBreakRenderer,
  TextSourceStylingRenderer,
  HeadingErrorRenderer,
} from '../plugins';
import type { ElementPlugin } from '../plugins';
import { ElementComponent } from './Element';
import { unreachableCaseLog } from 'types';
import type { RendererRenderElement, RendererRenderText } from './types';
import { SectionHeaderRenderer } from '../plugins/sectionHeader/components/index';
import { DeepLinkRenderer, ReadonlyDeepLinkRenderer } from '../plugins/deepLink/components/index';
import type { Styles } from 'generated/graphql';
import { getDefaultReporterStyles } from 'hooks/useReporterStyles';
import { LIST_VARIANTS } from '../plugins/list/constants';
import { ReadonlyInlineBookmarkRenderer } from '../plugins/inlineBookmark/components/InlineBookmark';

export type RendererProps = $ReadOnly<{
  content: ElementPlugin[],
  renderElement?: RendererRenderElement,
  renderText?: RendererRenderText,
  style?: Styles,
  className?: string,
  isReadOnly?: boolean,
}>;

const RendererContainer = styled.div``;

const defaultRenderText: RendererRenderText = ({ mark, children }) => {
  switch (mark) {
    case 'bold':
      return <BoldRenderer>{children}</BoldRenderer>;
    case 'italic':
      return <ItalicRenderer>{children}</ItalicRenderer>;
    case 'underline':
      return <UnderlineRenderer>{children}</UnderlineRenderer>;
    case 'highlighted':
      return <HighlightedRenderer>{children}</HighlightedRenderer>;
    case 'headingError':
      return <HeadingErrorRenderer>{children}</HeadingErrorRenderer>;
    case 'source':
      // $FlowFixMe[prop-missing]
      return <TextSourceStylingRenderer>{children}</TextSourceStylingRenderer>;
    default:
      unreachableCaseLog(mark);
      return children;
  }
};

const defaultRenderElement: RendererRenderElement = ({ children, node }) => {
  switch (node.type) {
    case 'inlineBookmark':
      return <InlineBookmarkRenderer element={node}>{children}</InlineBookmarkRenderer>;
    case 'paragraph':
      return <ParagraphRenderer element={node}>{children}</ParagraphRenderer>;
    case 'list':
      if (node.variant === LIST_VARIANTS.ol) {
        return <OrderedListRenderer element={node}>{children}</OrderedListRenderer>;
      } else if (node.variant === LIST_VARIANTS.ul) {
        return <UnorderedListRenderer element={node}>{children}</UnorderedListRenderer>;
      } else return null;
    case 'picklist':
      return <PicklistRenderer element={node}>{children}</PicklistRenderer>;
    case 'placeholder':
      return <PlaceholderRenderer element={node}>{children}</PlaceholderRenderer>;
    case 'sectionHeader':
      return <SectionHeaderRenderer element={node}>{children}</SectionHeaderRenderer>;
    case 'deepLink':
      return <DeepLinkRenderer element={node}>{children}</DeepLinkRenderer>;
    case 'heading':
      return <HeadingRenderer element={node}>{children}</HeadingRenderer>;
    case 'lineBreak':
      return <LineBreakRenderer element={node}>{children}</LineBreakRenderer>;
    default:
      unreachableCaseLog(node.type);
      return null;
  }
};

export const readOnlyRenderElement: RendererRenderElement = ({ children, node }) => {
  switch (node.type) {
    case 'inlineBookmark':
      return (
        <ReadonlyInlineBookmarkRenderer element={node} leftDelimiter="" rightDelimiter="">
          {children}
        </ReadonlyInlineBookmarkRenderer>
      );
    case 'paragraph':
      return <ParagraphRenderer element={node}>{children}</ParagraphRenderer>;
    case 'list':
      if (node.variant === LIST_VARIANTS.ol) {
        return <OrderedListRenderer element={node}>{children}</OrderedListRenderer>;
      } else if (node.variant === LIST_VARIANTS.ul) {
        return <UnorderedListRenderer element={node}>{children}</UnorderedListRenderer>;
      } else return null;
    case 'picklist':
      return (
        <PicklistRenderer leftDelimiter="" rightDelimiter="" element={node}>
          {children}
        </PicklistRenderer>
      );
    case 'placeholder':
      return (
        <PlaceholderRenderer element={node} leftDelimiter="" rightDelimiter="">
          {children}
        </PlaceholderRenderer>
      );
    case 'sectionHeader':
      return <SectionHeaderRenderer element={node}>{children}</SectionHeaderRenderer>;
    case 'deepLink':
      return <ReadonlyDeepLinkRenderer element={node}>{children}</ReadonlyDeepLinkRenderer>;
    case 'heading':
      return <HeadingRenderer element={node}>{children}</HeadingRenderer>;
    case 'lineBreak':
      return <LineBreakRenderer element={node}>{children}</LineBreakRenderer>;
    default:
      unreachableCaseLog(node.type);
      return null;
  }
};

export const Renderer = ({
  content,
  renderElement,
  renderText = defaultRenderText,
  styles = getDefaultReporterStyles(),
  isReadOnly = false,
  ...rest
}: RendererProps): React$Node => {
  return (
    <RendererContainer {...rest} style={styles.bodyStyle}>
      {content.map((node, index) => (
        <ElementComponent
          key={index}
          node={node}
          renderElement={
            renderElement ?? (isReadOnly ? readOnlyRenderElement : defaultRenderElement)
          }
          renderText={renderText}
        />
      ))}
    </RendererContainer>
  );
};
