// @flow

import type { EditorPlugin } from '../../types';
import { onKeyDownClearFormatting } from './onKeyDownClearFormatting';
import { CLEAR_FORMATTING_PLUGIN_ID } from './types';
import type { ClearFormattingPluginOptions, ClearFormattingPluginPropertyOptions } from './types';
import { MarkButton } from '../../components';
import FormatClearIcon from '@material-ui/icons/FormatClear';

export const DEFAULT_CLEAR_FORMATTING_HOTKEY = 'mod+/';

export const ClearFormattingPlugin = (
  {
    pluginName = 'Clear Formatting',
    hotkey = DEFAULT_CLEAR_FORMATTING_HOTKEY,
    icon = FormatClearIcon,
    ...rest
  }: ClearFormattingPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: ClearFormattingPluginPropertyOptions = {
    pluginID: CLEAR_FORMATTING_PLUGIN_ID,
    pluginName,
    hotkey,
    icon,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    onKeyDown: onKeyDownClearFormatting(options),
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={options.icon}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
          />
        ),
      },
    ],
  };
};
