// @flow
import { PATH } from 'config/constants';
import { useViewerMetadata } from 'domains/viewer/Viewer/StudyLoader/useViewerMetadata';
import analytics from 'modules/analytics';
import { globalContext } from 'modules/analytics/constants';
import { useEffect } from 'react';
import { generatePath, matchPath } from 'react-router-dom';
import type { RecoilState } from 'recoil';
import { atom, useSetRecoilState } from 'recoil';

export const openedViewersState: RecoilState<$ReadOnlyArray<string>> = atom({
  key: 'openedViewersState',
  default: [],
});

export function replaceViewerId(location: Location, windowId: string): string {
  const match = matchPath(PATH.VIEWER, location.pathname);
  const pathname = generatePath(PATH.VIEWER, { ...match?.params, windowId });
  return `${pathname}${location.search}`;
}

export const TrackOpenedViewers = ({ children }: { children: React$Node }): React$Node => {
  const setOpenedViewers = useSetRecoilState(openedViewersState);
  const { openWindowsIds } = useViewerMetadata();

  useEffect(() => {
    setOpenedViewers(openWindowsIds);
    analytics.addContext(globalContext.viewer.windowsOpen, openWindowsIds?.length);
    analytics.addContext(globalContext.viewer.openWindowsIds, openWindowsIds);
  }, [setOpenedViewers, openWindowsIds]);

  return children;
};
