// @flow

import type { EditorType, RangeType } from 'slate';
import type { OnExternalSubstitution } from './types';
import type { ASRPlexMessage, ErrorResponse } from './ASRPlex/ASRPlexProtocol';
import type { PCMRecorderDataAvailableEvent } from 'common/Recorder/useRecorder/useRecorder';
import { ASRPlexTransaction } from './ASRPlex/ASRPlexProtocol';
import { dictationWebSocketState, useRetryDictationWebSocket } from './ASRPlex/useASRPlexWebSocket';

import { recorderEmitter } from 'common/Recorder/useRecorder';
import { useCurrentCaseId } from 'hooks/useCurrentCase';

import { createContext, useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { logger } from 'modules/logger';
import { useTextProcessing } from './useTextProcessing';
import { useRecorder } from 'common/Recorder/useRecorder/useRecorder';

type StopRecordingEmitted = {
  selection: RangeType,
  duration: number,
};

export type DictationProps = {
  editor: EditorType,
  onWebsocketError: () => void,
  onError: (e: ErrorResponse) => void,
  enablePicklistDictation: boolean,
  onExternalSubstitution: OnExternalSubstitution,
};

export type UseDictation = {
  socket: ?WebSocket,
};

export const useDictation = ({
  editor,
  onWebsocketError,
  onError,
  enablePicklistDictation,
  onExternalSubstitution,
}: DictationProps): UseDictation => {
  const socket = useRetryDictationWebSocket();
  const transaction = useRef<?ASRPlexTransaction>(null);
  const setSocketState = useSetRecoilState(dictationWebSocketState);

  const caseSmid = useCurrentCaseId();

  const { handleStableText, processHypothesisTextMarkers } = useTextProcessing({
    editor,
    enablePicklistDictation,
    onExternalSubstitution,
  });

  const { getRecorder } = useRecorder();

  useEffect(() => {
    if (socket == null) return;

    const onMessage = async (ev: MessageEvent) => {
      const msg: ASRPlexMessage = JSON.parse(typeof ev.data === 'string' ? ev.data : '');

      if (msg.type === 'hypothesis_text' && editor.selection != null) {
        processHypothesisTextMarkers(msg, editor.selection);
      } else if (msg.type === 'stable_text') {
        const recorder = getRecorder();
        const id = recorder?.id;

        if (id == null) {
          logger.error(
            '[DictationProvider] tried to process stable text without a recorder id. This should never happen.'
          );
          return;
        }

        handleStableText(id, msg);
      } else if (msg.type === 'error') {
        onError(msg);
        // TODO: Handle error case, and any state management that needs to happen
      }
    };

    socket.onmessage = onMessage;
  }, [
    editor.selection,
    getRecorder,
    handleStableText,
    onError,
    processHypothesisTextMarkers,
    socket,
  ]);

  const onProcessedDataAvailable = useCallback(
    ({ id, buffer }: PCMRecorderDataAvailableEvent) => {
      if (buffer == null || buffer.length === 0 || socket == null) {
        return;
      }

      if (transaction.current == null) {
        transaction.current = new ASRPlexTransaction(id, socket);
        transaction.current.startTransaction({
          // $FlowIgnore[incompatible-call] handle case smid missing error
          case_smid: caseSmid,
        });
        // $FlowIgnore[incompatible-use] we ensure this is an ASRPlexTransaction above
        const transaction_id = transaction.current.transaction_id;
        setSocketState((prev) => ({
          ...prev,
          active_transaction_id: transaction_id,
        }));
      }

      // $FlowIgnore[incompatible-use] we ensure this is an ASRPlexTransaction above
      transaction.current.sendAudio(buffer);
    },
    [caseSmid, setSocketState, socket]
  );

  const onStopRecording = useCallback(({ duration }: StopRecordingEmitted) => {
    if (transaction.current != null) {
      transaction.current.stopTransaction();
    } else {
      logger.error(
        '[DictationProvider] tried to stop a transaction that does not exist. This indicates the application is in a bad state.'
      );
    }
  }, []);

  useEffect(() => {
    recorderEmitter.on<PCMRecorderDataAvailableEvent>('data_available', onProcessedDataAvailable);
    recorderEmitter.on<StopRecordingEmitted>('stop_recording', onStopRecording);

    return () => {
      recorderEmitter.off('data_available', onProcessedDataAvailable);
      recorderEmitter.off('stop_recording', onStopRecording);
    };
  }, [onProcessedDataAvailable, onStopRecording]);

  return {
    socket,
  };
};

const DictationContext = createContext<?UseDictation>();

export type DictationContextProps = $ReadOnly<{
  ...DictationProps,
  children: React$Node,
}>;

export const DictationProvider = ({ children, ...rest }: DictationContextProps): React$Node => {
  const bag = useDictation(rest);
  return <DictationContext.Provider value={bag}>{children}</DictationContext.Provider>;
};
