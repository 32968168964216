// @flow

import type { InlineBookmarkPluginElement, InlineBookmarkPluginPropertyOptions } from '../types';

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { useReporterState } from '../../../hooks';
import { useSelected } from '../../../core';
import { INLINE_BOOKMARK_DELIMITER_LEFT, INLINE_BOOKMARK_DELIMITER_RIGHT } from '../constants';
import { InlineBookmarkElement } from './InlineBookmarkElement';
import { ReadonlyInlineBookmarkElement } from './ReadonlyInlineBookmarkElement';
import styled from 'styled-components';
import { unreachableCaseError } from 'types';
import { InlineBookmarkTemplate } from '.';

export const InlineBookmarkEditable: GetRenderElementComponentType<
  InlineBookmarkPluginPropertyOptions,
  InlineBookmarkPluginElement,
> = ({ pluginOptions: { pluginID }, element, attributes, ...rest }): React$Node => {
  const selected = useSelected();
  const { variant } = useReporterState();

  switch (variant) {
    case 'report':
      return (
        <InlineBookmarkElement
          {...attributes}
          {...rest}
          selected={selected}
          element={element}
          variant={variant}
        />
      );
    case 'fragment':
    case 'template':
      return (
        <InlineBookmarkTemplate
          {...rest}
          variant={variant}
          attributes={attributes}
          selected={selected}
          leftDelimiter={INLINE_BOOKMARK_DELIMITER_LEFT}
          rightDelimiter={INLINE_BOOKMARK_DELIMITER_RIGHT}
          element={element}
        />
      );
    case 'view':
      return null;

    default:
      unreachableCaseError(variant);
  }
};

// $FlowIgnore[signature-verification-failure] Styled components types won't play nice
export const InlineBookmarkRenderer = styled(InlineBookmarkElement)`
  white-space: pre-wrap;
`;

// $FlowIgnore[signature-verification-failure] Styled components types won't play nice
export const ReadonlyInlineBookmarkRenderer = styled(ReadonlyInlineBookmarkElement)`
  white-space: pre-wrap;
`;
