// @flow
import { createContext } from 'react';
import type { VtkAnnotation, SegmentPointPositionTuple } from '../types';

export const AnnotationMutationsContext: React$Context<{
  updateAnnotation: ?(id: string) => (annotation: $Partial<VtkAnnotation>) => Promise<void> | void,
  updateAnnotationSegments: ?(
    string,
    ?boolean
  ) => (Array<SegmentPointPositionTuple>) => Promise<mixed> | void,
  deleteAnnotation: ?(string) => Promise<mixed> | void,
}> = createContext({
  updateAnnotation: null,
  deleteAnnotation: null,
  updateAnnotationSegments: null,
});
