// @flow

import type { EditorPlugin } from '../../types';
import { getRenderElement } from '../../utils/getRenderElement';
import { ParagraphEditable } from './components';
import { PARAGRAPH_PLUGIN_ID } from './types';
import type { ParagraphPluginOptions, ParagraphPluginPropertyOptions } from './types';
import { enhanceEditorStateParagraph } from './enhanceEditorStateParagraph';
import { onKeyDownParagraph } from './onKeydownParagraph';

export const ParagraphPlugin = (
  { pluginName = 'Paragraph', component = ParagraphEditable, ...rest }: ParagraphPluginOptions = {
    ...null,
  }
): EditorPlugin<> => {
  const options: ParagraphPluginPropertyOptions = {
    pluginID: PARAGRAPH_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement(options, { spreadAttributes: false }),
    enhanceEditorState: enhanceEditorStateParagraph(options),
    onKeyDown: onKeyDownParagraph(options),
  };
};
