// @flow

import { useEffect, useMemo, useRef, useState } from 'react';
import type { EditorType } from 'slate';
import isHotkey from 'is-hotkey';

import {
  AnatomicNavigatorPlugin,
  BoldPlugin,
  ParagraphPlugin,
  ItalicPlugin,
  ListPlugin,
  UnderlinePlugin,
  PicklistPlugin,
  PlaceholderPlugin,
  InlineBookmarkPlugin,
  ClearFormattingPlugin,
  DictaphoneControlsPlugin,
  GeneralKeyboardControlsPlugin,
  DictationPlugin,
  ExpandSelectionPlugin,
  SectionHeaderPlugin,
  DeepLinkPlugin,
  HighlightedPlugin,
  MacroPlaceholderPlugin,
  HeadingPlugin,
  AutoCorrectPlugin,
  RichTextEditor,
  TextSourceStylingPlugin,
} from '../../RichTextEditor';
import type { EditorPlugin, AllReporterVariant } from '../../RichTextEditor';
import type { SlateContent } from '../types';
import { EMPTY, DISCRIMINATORY_WORDS } from 'config/constants';
import { useFieldsState, useFieldsDispatch } from './Fields.Context';
import type { FieldsProviderProps } from './Fields.Context';
import { useToolPreferences } from 'hooks/usePreferences';
import { generateGlobalReporterShortcutsHandlers } from 'hooks/useCrossWindowReporterShortcuts';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { usePicklistState } from '../../Template/usePicklist';
import { useReporterStyles } from 'hooks/useReporterStyles';
import { HeadingErrorPlugin } from '../../RichTextEditor/plugins/headingError/headingErrorPlugin';
import { useHeadingKeywords } from 'hooks/useHeadingKeywords';
import { UnifiedEditor } from '../../RichTextEditor/ui/UnifiedEditor';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import { useCurrentList } from '../../RichTextEditor/plugins/list/hooks/useCurrentList';
import type { OnStableText } from '../../RichTextEditor/plugins/dictation/types';
import { handlePlaceholderItemClick } from '../../RichTextEditor/plugins/placeholder/utils';
import { RequiredFieldsPlugin } from '../../RichTextEditor/plugins/requiredFields/requiredFieldsPlugin';
import { useToasterDispatch } from 'common/ui/Toaster/Toaster';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';
import { RequiredFieldIndicators } from '../../RichTextEditor/ui/RequiredFieldIndicators';
import ImpressionGeneratorButton from '../../RichTextEditor/ui/ImpressionGeneratorButton';
import { useFocusMode } from 'hooks/useFocusMode';
import { useDeepLinkStudy } from 'hooks/useDeepLinkStudy';
import { useRecoilValue } from 'recoil';
import { isInsertingMacroState } from 'domains/reporter/Reporter/hooks/useInsertMacro/useInsertMacro';

export type FieldsEditorProps = $ReadOnly<{
  onChange: (content: SlateContent) => void,
  content: SlateContent,
  plugins?: Array<EditorPlugin<>>,
  variant?: AllReporterVariant,
  onInit?: FieldsProviderProps['onInit'],
  isDisabled: boolean,
  isAddendumEditor?: boolean,
  label?: string,
  aiMode?: boolean,
  onStableText?: OnStableText,
  editor?: ?EditorType,
}>;

export const FieldsEditor = ({
  content,
  onChange,
  variant = 'report',
  isDisabled,
  onInit,
  aiMode = false,
  onStableText = () => false,
  plugins: pluginsProps = EMPTY.ARRAY,
  isAddendumEditor = false,
  label,
  editor,
}: FieldsEditorProps): React$Node => {
  const { reporterToolbarMountPoint, activateTemplateID, addendumSMID } = useFieldsState();
  const { onReportChange } = useFieldsDispatch();
  const { data } = useCurrentUser();
  const headingKeywords = useHeadingKeywords();
  const isInsertingMacro = useRecoilValue(isInsertingMacroState);
  const { enqueueToast } = useToasterDispatch();
  const reporterSettings = data?.me?.reporterSettings;
  const nvoqCredentials = data?.me?.nvoqCredentials;
  const [toolPreferences] = useToolPreferences();
  const style = useReporterStyles(reporterSettings);
  const ignoreMergeFieldsInNavigation =
    reporterSettings?.mergeFieldsSettings?.ignoreNavigation ?? false;
  const { copyPicklist } = usePicklistState();
  const { listToolbarModes, setCurrentActiveListVariant } = useCurrentList();
  const [isReporterAnatomicNavigatorEnabled] = useFeatureFlagEnabled(
    FF.REPORTER_ANATOMIC_NAVIGATOR
  );
  const { syncRequiredFields } = useRequiredFieldIndicator();

  const [isAutoImpressionsEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);
  const [isAutoFillComparisonFeatureEnabled] = useFeatureFlagEnabled(
    FF.REPORTER_AUTO_FILL_COMPARISON
  );
  const isAutoFillComparisonEnabled =
    isAutoFillComparisonFeatureEnabled && reporterSettings?.autoFillComparison?.enabled;
  const { onInsertTextRequiredField } = useRequiredFieldIndicator();
  const unifiedEditorRef = useRef();
  const [editorKey, setEditorKey] = useState(0);
  const { isFocusModeFeatureEnabled, toggleFocusMode } = useFocusMode();
  const { handleDeepLinkClick, handleEditDeepLinkClick, getIsEditingStudyNode } = useDeepLinkStudy({
    isAutoFillComparisonEnabled,
  });

  useEffect(() => {
    setEditorKey((prev) => prev + 1);
  }, [variant, setEditorKey]);

  const plugins = useMemo(() => {
    if (
      nvoqCredentials == null ||
      nvoqCredentials.username == null ||
      nvoqCredentials.password == null
    ) {
      return [];
    }

    const plugs: EditorPlugin<>[] = [
      ExpandSelectionPlugin(),
      DictationPlugin({
        nvoqID: nvoqCredentials?.username,
        nvoqAuthorization: nvoqCredentials?.password,
        activeTemplateId: activateTemplateID,
        onStableText,
        aiMode,
        target: 'reporter',
        enablePicklistDictation: true,
      }),
      HighlightedPlugin({
        words: DISCRIMINATORY_WORDS,
      }),
      AutoCorrectPlugin(),
      ...(isReporterAnatomicNavigatorEnabled ? [AnatomicNavigatorPlugin()] : []),
      HeadingPlugin({ headingKeywords, isInsertingMacro }),
      BoldPlugin(),
      SectionHeaderPlugin(),
      DeepLinkPlugin({
        ignoreMergeFieldsInNavigation,
        isAutoFillComparisonEnabled,
        onDeepLinkClick: handleDeepLinkClick,
        onDeepLinkEditClick: handleEditDeepLinkClick,
        getIsEditingStudyNode,
      }),
      ParagraphPlugin(),
      ItalicPlugin(),
      UnderlinePlugin(),
      ...(isFocusModeFeatureEnabled ? [TextSourceStylingPlugin()] : []),
      ClearFormattingPlugin(),
      ListPlugin({ toolbarMode: listToolbarModes.reporter, setCurrentActiveListVariant }),
      // Required fields must come before picklist and inline bookmark plugins
      RequiredFieldsPlugin({
        enqueueToast,
        variant,
        onInsertTextRequiredField,
        syncRequiredFields,
      }),
      // Picklist must come before all other inline template plugins
      PicklistPlugin({
        showPicklistOptionsInEditor: toolPreferences?.dictaphone.showPicklistOptionsInEditor,
        onPicklistCopy: copyPicklist,
      }),
      InlineBookmarkPlugin(),
      PlaceholderPlugin({
        onPlaceholderMenuClick: handlePlaceholderItemClick,
      }),
      // Always last so other plugins can handle the button presses
      DictaphoneControlsPlugin({
        ignoreMergeFieldsInNavigation,
        toggleFocusMode,
      }),
      GeneralKeyboardControlsPlugin({
        ignoreMergeFieldsInNavigation,
        onKeyDownOverride: (e) => {
          if (!toolPreferences) return false;

          return generateGlobalReporterShortcutsHandlers(toolPreferences.keyboard.shortcuts).some(
            (handler) => {
              if (isHotkey(handler.key, e)) {
                e.preventDefault();

                handler.callback(new Event('keydown'));
                return true;
              }

              return false;
            }
          );
        },
      }),
      // Plugin is only injected to support ad-hoc macros since they create synthetic macroPlaceholders
      // to be able to stitch in a macro at a given path.
      //
      // CANNOT BE USED AS A NODE IN A REPORT
      MacroPlaceholderPlugin(),
      HeadingErrorPlugin(),
      ...pluginsProps,
    ];

    return plugs;
  }, [
    nvoqCredentials,
    activateTemplateID,
    onStableText,
    aiMode,
    isReporterAnatomicNavigatorEnabled,
    headingKeywords,
    isInsertingMacro,
    ignoreMergeFieldsInNavigation,
    isAutoFillComparisonEnabled,
    handleDeepLinkClick,
    handleEditDeepLinkClick,
    getIsEditingStudyNode,
    isFocusModeFeatureEnabled,
    listToolbarModes.reporter,
    setCurrentActiveListVariant,
    enqueueToast,
    variant,
    onInsertTextRequiredField,
    syncRequiredFields,
    toolPreferences,
    copyPicklist,
    toggleFocusMode,
    pluginsProps,
  ]);

  // https://sironamedical.atlassian.net/browse/EN-2417
  if (content == null) return null;

  return (
    <>
      <RequiredFieldIndicators unifiedEditorRef={unifiedEditorRef} />
      {isAutoImpressionsEnabled && (
        <ImpressionGeneratorButton unifiedEditorRef={unifiedEditorRef} />
      )}
      <UnifiedEditor
        unifiedEditorRef={unifiedEditorRef}
        isAddendumEditor={isAddendumEditor}
        variant={variant}
        isDisabled={variant === 'view' || isDisabled}
        aiMode={aiMode}
        label={label}
        addendumSMID={addendumSMID}
      >
        <RichTextEditor
          key={editorKey}
          isDisabled={variant === 'view' || isDisabled}
          variant={variant}
          style={style.bodyStyle}
          cursorStyle={reporterSettings?.cursorStyle}
          onChange={onChange}
          value={content}
          editor={editor}
          plugins={plugins}
          navMountPoint={reporterToolbarMountPoint}
          onInit={onInit}
          isAddendumEditor={isAddendumEditor}
          lineIndicator={reporterSettings?.lineIndicator}
          aiMode={aiMode}
          onReportChange={onReportChange}
        />
      </UnifiedEditor>
    </>
  );
};
