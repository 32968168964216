// @flow
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useCallback, useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

import { ReactComponent as WarningRoundedIcon } from 'assets/WarningRoundedIcon.svg';
import { Button } from 'common/ui/Button';
import Dialog from 'common/ui/Dialog';
import Text from 'common/ui/Text';
import { Stack } from 'common/ui/Layout';
import { Colors } from 'styles';
import { useFieldsDispatch } from '../Fields/Fields.Context';
import { useDiscardReport } from 'hooks/useDiscardReport';

import {
  defaultUnsignedReportWarningDialogState,
  discardReportState,
  unsignedReportDialogState,
} from '../state';
import { useReportLockedState } from '../hooks/useReportLockedState/useReportLockedState';
import { useUnsignedReportWarningDialog } from './useUnsignedReportWarningDialog';
import { FF, useSplitFlag } from 'modules/feature-flags';

const LoadingIndicator = ({
  'data-testid': dataTestID,
  label,
}: {
  'data-testid': string,
  label: string,
}): React$Node => (
  <Stack space="small">
    <div
      css={`
        margin: 0 12px -5px -15px;
        padding: 2px 0 0 0;
      `}
    >
      <CircularProgress
        size="20px"
        style={{ color: label === 'Draft' ? Colors.white : Colors.blue2 }}
        data-testid={dataTestID}
      />
    </div>
    <Text color={Colors.gray7}>{label}</Text>
  </Stack>
);

export const UnsignedReportWarningDialog = ({
  submitting,
  onSubmit,
}: {
  submitting: boolean,
  onSubmit: (onSuccess: () => void, onError: () => void) => Promise<void>,
}): React$Node => {
  const { handleDiscardReport } = useDiscardReport();
  const { handleSaveDraft, handleCancelAddAddendum } = useFieldsDispatch();
  const { onDialogActionSuccess, onDialogActionError } = useUnsignedReportWarningDialog();

  const [dialogState, setDialogState] = useRecoilState(unsignedReportDialogState);
  const discardReportOptions = useRecoilValue(discardReportState);
  const { isAddendumStatus } = useReportLockedState();
  const [isSavingDraft, setIsSavingDraft] = useState(false);

  const onCancel = useCallback(() => {
    // We DON'T want to close the window if the user cancels the dialog
    setDialogState(defaultUnsignedReportWarningDialogState);
  }, [setDialogState]);

  const onDiscard = useCallback(async () => {
    if (isAddendumStatus) {
      await handleCancelAddAddendum({
        onSuccess: onDialogActionSuccess,
        onError: onDialogActionError,
      });
    } else {
      await handleDiscardReport({ onSuccess: onDialogActionSuccess, onError: onDialogActionError });
    }
  }, [
    isAddendumStatus,
    handleCancelAddAddendum,
    onDialogActionSuccess,
    onDialogActionError,
    handleDiscardReport,
  ]);

  const onSign = useCallback(async () => {
    await onSubmit(onDialogActionSuccess, onDialogActionError);
  }, [onSubmit, onDialogActionSuccess, onDialogActionError]);

  const onDraft = useCallback(async () => {
    setIsSavingDraft(true);
    await handleSaveDraft({
      onSuccess: () => {
        setIsSavingDraft(false);
        onDialogActionSuccess();
      },
      onError: onDialogActionError,
    });
  }, [handleSaveDraft, onDialogActionSuccess, onDialogActionError]);

  return (
    <UnsignedReportWarningDialogContent
      open={dialogState.open}
      onCancel={onCancel}
      onDiscard={onDiscard}
      onSign={onSign}
      onDraft={onDraft}
      isSavingDraft={isSavingDraft}
      isDiscarding={discardReportOptions.discarding}
      isSubmitting={submitting}
      isAddendumStatus={isAddendumStatus}
    />
  );
};

export const UnsignedReportWarningDialogContent = ({
  open,
  onCancel,
  onDiscard,
  onSign,
  onDraft,
  isSavingDraft,
  isDiscarding,
  isSubmitting,
  isAddendumStatus,
}: {
  open: boolean,
  onCancel: () => void,
  onDiscard: () => Promise<void>,
  onSign: () => Promise<void>,
  onDraft: () => Promise<void>,
  isSavingDraft: boolean,
  isDiscarding: boolean,
  isSubmitting: boolean,
  isAddendumStatus: boolean,
}): React$Node => {
  const [isReporterSubmissionEnabled] = useSplitFlag(
    FF.ENABLE_REPORTER_SUBMISSION,
    'on',
    (v) => v === 'on'
  );

  const isActionInProgress = isSavingDraft || isDiscarding || isSubmitting;
  const slug = 'unsigned-report-warning';

  const secondaryButtonCss = `
    white-space: nowrap;
    color: ${Colors.blue2};
  `;

  return (
    <Dialog
      variant="light"
      open={open}
      css={`
        .MuiDialog-paper {
          padding: 45px 30px 32px 30px;
          width: 500px;
          height: 268px;
        }
      `}
    >
      <DialogContent
        css={`
          width: 440px;
          text-align: center;
          padding-bottom: 0 !important;
        `}
        data-testid={`${slug}-modal-testid`}
      >
        <WarningRoundedIcon
          css={`
            color: ${Colors.red3};
            height: 60px;
            width: 60px;
            display: block;
            margin: 0 auto 16px;
          `}
        />
        <DialogContentText style={{ marginBottom: '0' }}>
          <Text color="black">
            There are unsaved changes in the current {isAddendumStatus ? 'addendum' : 'report'}.
          </Text>
        </DialogContentText>
        <DialogContentText style={{ marginBottom: '0' }}>
          <Text color="black">Do you want to save a draft?</Text>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        css={`
          justify-content: center;
          gap: 16px;
          padding: 0px;
        `}
      >
        <Button
          data-testid={`${slug}-cancel-button`}
          variant="ghost-blue"
          onClick={() => {
            if (isActionInProgress) return;
            onCancel();
          }}
        >
          <Text css={secondaryButtonCss}>Cancel</Text>
        </Button>
        <Button
          data-testid={`${slug}-discard-button`}
          variant="ghost-blue"
          onClick={() => {
            if (isActionInProgress) return;
            onDiscard();
          }}
          css="margin-left: 0 !important;"
        >
          {isDiscarding ? (
            <LoadingIndicator label="Discard" data-testid="discard-circular-progress" />
          ) : (
            <Text css={secondaryButtonCss}>Discard</Text>
          )}
        </Button>
        {isReporterSubmissionEnabled && (
          <Button
            data-testid={`${slug}-sign-button`}
            variant="ghost-blue"
            onClick={() => {
              if (isActionInProgress) return;
              onSign();
            }}
            css="margin-left: 0 !important;"
          >
            {isSubmitting ? (
              <LoadingIndicator label="Sign" data-testid="sign-circular-progress" />
            ) : (
              <Text css={secondaryButtonCss}>Sign</Text>
            )}
          </Button>
        )}
        <Button
          data-testid={`${slug}-draft-button`}
          variant="primary"
          onClick={() => {
            if (isActionInProgress) return;
            onDraft();
          }}
          css="margin-left: 0 !important;"
        >
          {isSavingDraft ? (
            <LoadingIndicator label="Draft" data-testid="draft-circular-progress" />
          ) : (
            <Text>Draft</Text>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UnsignedReportWarningDialog;
