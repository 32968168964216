// @flow

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import { localStoragePersisterEffect } from 'utils/recoilEffects';
import { SRNA_PERSIST_TOKEN } from 'config/constants';

export type ReadCaseState = {
  workstationID: string | null,
};

export const readCaseState: RecoilState<ReadCaseState> = atom({
  key: `${SRNA_PERSIST_TOKEN}readCaseState`,
  default: {
    workstationID: null,
  },
  effects: [localStoragePersisterEffect()],
});
