// @flow

/**
 * A wrapper for the MediaRecorder class that includes a slate selection reference.
 */
import type { RangeType } from 'slate';

import { nanoid } from 'nanoid';

export type SlateMediaRecorderOptions = {
  ...MediaRecorderOptions,
  selection: RangeType | null,
  ...
};

export class SlateMediaRecorder extends MediaRecorder {
  selection: RangeType | null;
  startTimestamp: DOMHighResTimeStamp | null;
  endTimestamp: DOMHighResTimeStamp | null;
  id: string;
  constructor(newStream: MediaStream, options: SlateMediaRecorderOptions) {
    const { selection, ...opts } = options;
    super(newStream, opts);
    this.selection = selection;
    this.startTimestamp = null;
    this.endTimestamp = null;
    this.id = nanoid();
  }

  startRecording(timeslice?: number) {
    this.start(timeslice);
    this.startTimestamp = performance.now();
  }

  stopRecording(cb: ?() => void) {
    if (cb != null) {
      this.addEventListener('stop', () => {
        this.endTimestamp = performance.now();
        cb();
      });
    }
    this.stop();
  }

  /**
   * Get the cumulative duration of the recording in milliseconds. This number will grow until
   * the recording stops. If the recording has not yet started, returns -1.
   */
  getDurationMillis(): number {
    if (this.startTimestamp == null) {
      return -1;
    }
    if (this.endTimestamp == null) {
      // $FlowIgnore[unsafe-arithmetic] we checked it above.
      return performance.now() - this.startTimestamp;
    }
    return this.endTimestamp - this.startTimestamp;
  }
}
