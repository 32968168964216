// @flow

import type { Step, StepEntry } from '../useSteps';

const shouldPartitionSteps = (step: Step): boolean => {
  if (step.type === 'VOICE_COMMAND') {
    if (step.action === 'AllCaps') {
      return false;
    } else {
      return true;
    }
  } else if (step.type === 'EXTERNAL_VOICE_COMMAND') {
    return true;
  }

  return step.type === 'INSERT_MACRO';
};

/**
 * createGroupedStepEntries takes an array of stepEntries,
 * and splits them up based on the conditions in shouldPartitionSteps.
 * see the `createGroupedStepEntries` tests for example outcomes.
 */
export const createGroupedStepEntries = (stepEntries: StepEntry[]): StepEntry[][] => {
  const groupedStepEntries: StepEntry[][] = [];
  let currentStepEntryGroup: StepEntry[] = [];

  stepEntries.forEach((stepEntry) => {
    const [step] = stepEntry;

    // ensure these stepEntries are in their separate groups, to properly allow render of stableText into editor
    if (shouldPartitionSteps(step)) {
      if (currentStepEntryGroup.length > 0) {
        groupedStepEntries.push(currentStepEntryGroup);
      }
      groupedStepEntries.push([stepEntry]);
      currentStepEntryGroup = [];
    } else {
      currentStepEntryGroup.push(stepEntry);
    }
  });

  // handle the final group
  if (currentStepEntryGroup.length > 0) {
    groupedStepEntries.push(currentStepEntryGroup);
  }

  // if every step is an empty text insertion, we can ignore it the whole group.
  // downstream, this will prevent unnecessarily calling applySteps
  // e.g. when the user was recording but didn't say anything
  // and then moves their selection to somewhere else.
  if (
    groupedStepEntries.every((stepEntries) => {
      return stepEntries.every(([step]) => {
        return (
          step.type === 'INSERT_TEXT' && step.payload.length === 1 && step.payload[0].text === ''
        );
      });
    })
  ) {
    return [];
  }

  return groupedStepEntries;
};
