// @flow
import { LIST_PLUGIN_ID } from './types';
import { PARAGRAPH_PLUGIN_ID, PARAGRAPH_LIST_ITEM_VARIANT } from '../paragraph/types';
import type { EditorType, LocationType, NodeType, NodeEntry, PathType } from '../../core';
import { Editor, Node, Path, Range, Transforms } from '../../core';
import {
  BULLETED_LIST_HOTKEY_MAC,
  BULLETED_LIST_HOTKEY_WINDOWS,
  DECREASE_INDENT_HOTKEY_MAC,
  DECREASE_INDENT_HOTKEY_WINDOWS,
  INCREASE_INDENT_HOTKEY_MAC,
  INCREASE_INDENT_HOTKEY_WINDOWS,
  LIST_VARIANTS,
  NUMBERED_LIST_HOTKEY_MAC,
  NUMBERED_LIST_HOTKEY_WINDOWS,
} from './constants';
import type { ParagraphPluginElement } from '../paragraph/types';
import type { ListVariant } from './constants';
import { createInlineBookmark } from '../inlineBookmark/utils';
import { getPathRefSafe, isApple } from '../../utils';
import { INLINE_BOOKMARK_PLUGIN_ID } from '../inlineBookmark/types';
import type { CurrentActiveListVariantState } from './hooks/useCurrentList';
import { logger } from 'modules/logger';
import { prettifyHotkey } from 'utils/prettifyHotkey';
import type { RangeType } from 'slate';
import { HEADING_PLUGIN_ID } from '../heading/types';
import { demoteListItems } from './functions';

export const isListChildValid = (node: NodeType): boolean => {
  return (
    node.type === PARAGRAPH_PLUGIN_ID ||
    (node.type === LIST_PLUGIN_ID && [LIST_VARIANTS.ol, LIST_VARIANTS.ul].includes(node.variant))
  );
};

/**
 * @param {*} pretty - useful if you want to render the hotkey combination as text
 */
export const getIncreaseIndentHotkey = (pretty: boolean = false): string => {
  const hotkey = isApple() ? INCREASE_INDENT_HOTKEY_MAC : INCREASE_INDENT_HOTKEY_WINDOWS;
  return pretty ? prettifyHotkey(hotkey) : hotkey;
};

export const getDecreaseIndentHotkey = (pretty: boolean = false): string => {
  const hotkey = isApple() ? DECREASE_INDENT_HOTKEY_MAC : DECREASE_INDENT_HOTKEY_WINDOWS;
  return pretty ? prettifyHotkey(hotkey) : hotkey;
};

export const getBulletedListHotkey = (pretty: boolean = false): string => {
  const hotkey = isApple() ? BULLETED_LIST_HOTKEY_MAC : BULLETED_LIST_HOTKEY_WINDOWS;
  return pretty ? prettifyHotkey(hotkey) : hotkey;
};

export const getNumberedListHotkey = (pretty: boolean = false): string => {
  const hotkey = isApple() ? NUMBERED_LIST_HOTKEY_MAC : NUMBERED_LIST_HOTKEY_WINDOWS;
  return pretty ? prettifyHotkey(hotkey) : hotkey;
};

export const findClosestBlockPathAtSelection = (
  editor: EditorType,
  selection?: RangeType
): PathType => {
  const block = Editor.above(editor, {
    at: selection,
    match: (n: NodeType) => Editor.isBlock(editor, n),
  });

  return block ? block[1] : [];
};

export const getCurrentListAtPath = (editor: EditorType, path: PathType): ?NodeEntry<empty> => {
  return Editor.above(editor, {
    at: path,
    match: (n: NodeType) => n.type === LIST_PLUGIN_ID,
  });
};

export const isInsideFirstListItemOfListAtPath = (editor: EditorType, path: PathType): boolean => {
  const currentList = getCurrentListAtPath(editor, path);

  if (currentList == null) return false;

  const firstListItemPath = [...currentList[1], 0];
  return Path.equals(path, firstListItemPath);
};

export const isInsideListAtPath = (editor: EditorType, path: PathType): boolean => {
  return !!getCurrentListAtPath(editor, path);
};

export const isInsideListAtSelection = (editor: EditorType): boolean => {
  if (editor == null || editor.selection == null) return false;
  const { selection } = editor;
  const { anchor, focus } = selection;

  // find a matching list node in the ancestors
  const findAncestorList = (path: PathType) => {
    for (const [node, currentPath] of Node.ancestors(editor, path)) {
      if (node.type === LIST_PLUGIN_ID) {
        return currentPath;
      }
    }
    return null;
  };

  const anchorListPath = findAncestorList(anchor.path);

  if (Range.isCollapsed(selection)) {
    return !!anchorListPath;
  }

  const focusListPath = findAncestorList(focus.path);

  return !!anchorListPath || !!focusListPath;
};

export const getListDepth = (editor: EditorType, path: PathType): number => {
  return [...Editor.levels(editor, { at: path, match: (n: NodeType) => n.type === LIST_PLUGIN_ID })]
    .length;
};

/**
 * returns list variant based on the nearest list to the user's selection
 * i.e if selection is 1 list level deep, use top level list variant
 * if selection is 2 list levels deep, use the corresponding sublist variant
 */
export const getCurrentActiveListVariant = (
  editor: ?EditorType,
  range?: RangeType
): CurrentActiveListVariantState => {
  const selection = range ?? editor?.selection;
  if (editor == null || selection == null) return null;

  const nodeEntry = Editor.above(editor, {
    at: Range.start(selection),
    match: (node: NodeType) =>
      node.type === LIST_PLUGIN_ID &&
      (node.variant === LIST_VARIANTS.ol || node.variant === LIST_VARIANTS.ul),
  });
  if (nodeEntry != null) {
    return nodeEntry[0].variant;
  }
  return null;
};

/**
 * returns the index of the current list item in the list at the current level
 */
export const getCurrentListItemPosition = (editor: ?EditorType, range: ?RangeType): number => {
  if (editor == null || range == null) return -1;
  try {
    const start = Editor.start(editor, range);
    const startOfSelection = Editor.nodes(editor, {
      at: start,
      match: (node: NodeType) => node.variant === LIST_VARIANTS.li,
    });
    const { value: firstListItem } = startOfSelection.next();
    if (firstListItem == null) return -1;
    // get selected list item(s)
    const listItems = Editor.nodes(editor, {
      match: (node: NodeType) => node.variant === LIST_VARIANTS.li,
    });
    if (listItems == null) return -1;

    const { value: currentSelectedItem } = listItems.next();
    if (currentSelectedItem == null) return -1;

    // get parent list
    const parentListEntry = Editor.above(editor, {
      at: currentSelectedItem[1],
      match: (node: NodeType) =>
        node.type === LIST_PLUGIN_ID &&
        (node.variant === LIST_VARIANTS.ol || node.variant === LIST_VARIANTS.ul),
    });
    // get all list items starting at the parent (includes the current one)
    const allListItemsFromParent = Editor.nodes(editor, {
      match: (n: NodeType) => n.variant === LIST_VARIANTS.li,
      mode: 'all',
      at: parentListEntry ? parentListEntry[1] : [],
    });
    if (allListItemsFromParent == null) return -1;

    let index = 0;
    for (const [, path] of allListItemsFromParent) {
      if (path.length === currentSelectedItem[1].length) {
        if (Path.equals(path, currentSelectedItem[1])) {
          return index;
        }
        index++;
      }
    }
    return -1;
  } catch (e) {
    return -1;
  }
};

export const listItemContainsOnlyEmptyInlineBookmark = (node: NodeType): boolean => {
  if (node.variant !== LIST_VARIANTS.li || node.type !== PARAGRAPH_PLUGIN_ID) {
    const variantErrorMessage =
      '[list/utils] listItemContainsOnlyEmptyInlineBookmark was called, but node is not a list item variant';
    logger.warn(variantErrorMessage, {
      node,
    });

    return false;
  }

  if (node?.children == null) {
    return false;
  }

  // length of three: inline bookmark node, plus two empty text nodes each side
  // $FlowIgnore[incompatible-use] - if node.children.length === 3 is true, then node.children[1] exists
  if ((node.children.length: number) === 3 && node.children[1].type === INLINE_BOOKMARK_PLUGIN_ID) {
    if (Node.string(node.children[1]) === '') {
      return true;
    }
  }

  return false;
};

export const isListItemEmpty = (node: NodeType): boolean => {
  if (node.variant !== LIST_VARIANTS.li || node.type !== PARAGRAPH_PLUGIN_ID) {
    const variantErrorMessage =
      '[list/utils] isListItemEmpty was called, but node is not a list item variant';
    logger.warn(variantErrorMessage, {
      node,
    });

    return false;
  }

  if (Node.string(node) === '') {
    return true;
  }

  return false;
};

export const createListItemNode = (text: string = ''): ParagraphPluginElement => ({
  type: PARAGRAPH_PLUGIN_ID,
  variant: LIST_VARIANTS.li,
  children: [{ text: '' }, { ...createInlineBookmark(text) }, { text: '' }],
});

export const createListItemNodeWithChildren = (
  children: Array<NodeType>
): ParagraphPluginElement => ({
  type: PARAGRAPH_PLUGIN_ID,
  variant: LIST_VARIANTS.li,
  children,
});

const createListNode = (variant: ListVariant, listItemNodes: Array<ParagraphPluginElement>) => ({
  type: LIST_PLUGIN_ID,
  variant,
  children: listItemNodes,
});

/**
 *
 * If the user types a list insertion shortcut followed by a space,
 * then replace their text with a list and the first list item
 *
 * E.g. '1. ' would replace this text with a numbered list, with 1. being the first list item
 */
export const triggerListInsertion = ({
  editor,
  variant,
  at,
  listItemNodes,
  edge = 'end',
}: {
  editor: EditorType,
  variant: ListVariant,
  at: LocationType,
  listItemNodes: Array<ParagraphPluginElement>,
  edge?: 'start' | 'end' | 'all',
}): void => {
  Transforms.insertNodes(editor, [createListNode(variant, listItemNodes)], {
    at,
    select: true,
  });

  if (editor.selection != null) {
    const listItemGenerator = Editor.nodes(editor, {
      at: editor.selection,
      match: (n: NodeType) => n.type === PARAGRAPH_PLUGIN_ID && n.variant === LIST_VARIANTS.li,
      mode: 'lowest',
    });
    const lowestListItem = listItemGenerator.next().value;

    if (lowestListItem != null) {
      const inlineBookmarkGenerator = Editor.nodes(editor, {
        at: lowestListItem[1],
        match: (n: NodeType) => n.type === INLINE_BOOKMARK_PLUGIN_ID,
        mode: 'highest',
      });
      const inlineBookmark = inlineBookmarkGenerator.next().value;

      if (inlineBookmark != null) {
        const pathToInlineBookmark = inlineBookmark[1];
        if (edge == null || edge === 'end') {
          Transforms.select(editor, Editor.end(editor, pathToInlineBookmark));
        } else if (edge === 'start') {
          Transforms.select(editor, Editor.start(editor, pathToInlineBookmark));
        } else if (edge === 'all') {
          Transforms.select(editor, pathToInlineBookmark);
        }
      }
    }
  }
};

/**
 * When increasing the indent of a list item, we want to move it inside the previous list item or
 * wrap in a new list
 */
export const increaseListIndentation = (
  editor: EditorType,
  variant: ListVariant,
  at: LocationType
): void => {
  const list = {
    type: 'list',
    variant,
    children: [],
  };
  const pathRefs = [
    ...Editor.nodes(editor, {
      at: editor.selection ?? at,
      match: (n: NodeType) => n.variant === PARAGRAPH_LIST_ITEM_VARIANT,
    }),
  ].map((entry) => Editor.pathRef(editor, entry[1]));

  // Iterate through each list item and nest it accordingly
  for (const pathRef of pathRefs) {
    const currLiPath = getPathRefSafe(pathRef);
    const prevNode = Editor.previous(editor, { at: currLiPath });
    const nextNode = Editor.next(editor, { at: currLiPath });
    const prevLiPathRef = prevNode ? Editor.pathRef(editor, prevNode[1]) : undefined;
    const nextListPathRef = nextNode ? Editor.pathRef(editor, nextNode[1]) : undefined;
    const hasPrevList =
      prevNode !== undefined &&
      getListDepth(editor, prevNode[1]) === getListDepth(editor, currLiPath) + 1;
    const hasNextList =
      nextNode !== undefined &&
      getListDepth(editor, nextNode[1]) === getListDepth(editor, currLiPath) + 1;
    /**
     * Test cases: also check depths are the same
     * 1. Indent should nest the selection into the list above
     * 2. Indent should nest the selection into the list below
     * 3. Indent should wrap the selection with the list above and below into a one list
     * 4. Indent should wrap the selection with the list into a new list
     */

    if (hasPrevList && hasNextList) {
      if (
        nextNode === undefined ||
        nextListPathRef === undefined ||
        prevNode === undefined ||
        prevLiPathRef === undefined
      )
        return;
      const prevLiPath = getPathRefSafe(prevLiPathRef);

      Transforms.moveNodes(editor, {
        at: currLiPath,
        to: [...nextNode[1], 0],
      });

      let nextListPath = getPathRefSafe(nextListPathRef);

      // Move the current selection into the previous list
      Transforms.moveNodes(editor, {
        at: nextListPath,
        to: [...prevLiPath, prevNode[0].children.length],
      });

      nextListPath = getPathRefSafe(nextListPathRef);

      Transforms.unwrapNodes(editor, { at: nextListPath });
    } else if (hasPrevList) {
      if (prevNode === undefined || prevLiPathRef === undefined) return;
      const prevLiPath = getPathRefSafe(prevLiPathRef);

      // Move the current selection into the previous list
      Transforms.moveNodes(editor, {
        at: currLiPath,
        to: [...prevLiPath, prevNode[0].children.length],
        mode: 'lowest',
      });
    } else if (hasNextList) {
      if (nextNode === undefined) return;
      // merge next list if it is the same depth
      Transforms.moveNodes(editor, {
        at: currLiPath,
        to: [...nextNode[1], 0],
      });
    } else {
      Transforms.wrapNodes(editor, list, { at: currLiPath });
    }
  }
};

export const decreaseListIndentation = (
  editor: EditorType,
  variant: ListVariant,
  at: LocationType
): void => {
  const pathRefs = [
    ...Editor.nodes(editor, {
      at: editor.selection ?? at,
      match: (n: NodeType) => n.variant === PARAGRAPH_LIST_ITEM_VARIANT,
    }),
  ].map((entry) => Editor.pathRef(editor, entry[1]));
  // Iterate through each list item and nest it accordingly
  for (const pathRef of pathRefs) {
    const currLiPath = getPathRefSafe(pathRef);
    if (isInsideListAtPath(editor, currLiPath)) {
      Transforms.liftNodes(editor, { at: currLiPath });
    }
    // used when handling case for multi-block selection at lowest list depths
    else if (isInsideListAtSelection(editor)) {
      demoteListItems(editor);
    }
    removeListItemOrphans(editor);
  }
};

/**
 *
 * Iterate through the editor, and remove the paragraph list variant,
 * If it's parent is not a list node
 */
export const removeListItemOrphans = (editor: EditorType) => {
  for (const [node, path] of Node.nodes(editor)) {
    if (node.variant === PARAGRAPH_LIST_ITEM_VARIANT && node.type === PARAGRAPH_PLUGIN_ID) {
      const parentPath = Path.parent(path);
      const parent = Node.get(editor, parentPath);

      if (parent.type !== 'list') {
        Transforms.unsetNodes(editor, 'variant', { at: path });
      }
    }
  }
};

/**
 *
 * Iterate through the nodes in a range, and return the list variants within that range
 */
export const getListVariantsInRange = (editor: EditorType, at: RangeType): Array<string> => {
  const variants = new Set([]);
  for (const [node] of Editor.nodes(editor, { at })) {
    if (!Editor.isEditor(node)) {
      if (node.type === LIST_PLUGIN_ID && node.variant != null) {
        variants.add(String(node.variant));
      } else if (
        (node.type === PARAGRAPH_PLUGIN_ID || node.type === HEADING_PLUGIN_ID) &&
        node.variant == null
      ) {
        variants.add('none');
      }
    }
  }

  return [...variants];
};

/**
 *
 * Iterate through the editor, and if we come across an empty list, remove it altogether.
 */
export const removeEmptyLists = (editor: EditorType) => {
  for (const [node, path] of Node.nodes(editor)) {
    if (node.type === LIST_PLUGIN_ID && Array.isArray(node.children)) {
      // $FlowIgnore[incompatible-type] - asserted node.children to be array
      const nodeChildren: Array<NodeType> = node.children;
      if (nodeChildren.length === 0) {
        Transforms.removeNodes(editor, { at: path });
      } else {
        const allChildrenEmpty = nodeChildren.every((child) => Node.string(child).trim() === '');

        if (allChildrenEmpty) {
          Transforms.removeNodes(editor, { at: path });
          removeEmptyLists(editor);
        }
      }
    }
  }
};
