// @flow
import { Editor, Node, Path } from '../core';
import { HeadingLevel } from '../plugins/heading';
import { normalizeText, toTitleCase, isHeadingNode } from '../plugins/heading/utils/normalization';
import type { NodeType, PointType, NodeEntry, EditorType, LocationType } from '../core';
import { endsWithNumber } from '../stitching/normalizationHelpers';
import { isSquareBracketType } from '../constants';

export const DEFAULT_FIELD_NAME = 'Field';

export const isEligibleNamedField = (type: string): boolean => {
  return isSquareBracketType(type);
};

export const hasDuplicateFieldName = (editor: EditorType, name: string): boolean => {
  let count = 0;
  const duplicateNodes = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => isEligibleNamedField(String(n.type)) && n.name === name,
  });

  for (const [node] of duplicateNodes) {
    if (node != null) {
      count++;
    }

    if (count > 1) {
      return true;
    }
  }

  return false;
};

const isUnnamedField = (node: NodeType) => {
  return isEligibleNamedField(String(node.type)) && (node.name == null || node.name === '');
};

const getDefaultNamedFields = ({
  editor,
  anchor,
  focus,
  name,
}: {
  editor: EditorType,
  anchor: PointType,
  focus: PointType,
  name: string,
}): Array<NodeEntry<>> => {
  return Array.from(
    Editor.nodes(editor, {
      at: {
        anchor,
        focus,
      },
      match: (node: NodeType) => {
        if (!isEligibleNamedField(String(node.type))) {
          return false;
        }

        const nodeName = node.name != null ? toTitleCase(normalizeText(String(node.name))) : null;

        return (
          isUnnamedField(node) ||
          nodeName === name ||
          (nodeName != null && nodeName.startsWith(name) && endsWithNumber(nodeName))
        );
      },
    })
  );
};

/**
 * This function will return a default name for a given field, dependent on the content of
 * the report. In particular, a field will be given the default name of:
 *
 *  (1) The text of the closest preceding H2 between the field and the closest
 *      preceding H1 (or start of the editor if there is no H1) + <# of preceding
 *      unnamed fields or default subheading-named fields between the H2 and this field + 1>
 *  (2) The text of the closest preceding H1 + <# of preceding unnamed fields or default named fields between the H1 and this field>, with 0 being skipped
 *  (3) "Field" + <# of preceding unnamed fields or default named fields between the start of the editor and this field +1>
 *
 * For example, given the following following report -
 *
 *        [0]
 *
 *        EXAMINATION:
 *
 *        [1]
 *
 *        Chest: [2][3]
 *
 *        CLINICAL HISTORY:
 *
 *        [4][5]
 *
 *        Lungs: [6]
 *
 * The different fields would have these names assigned by default:
 *
 *        [0] Field1
 *        [1] Examination
 *        [2] Chest1
 *        [3] Chest2
 *        [4] Clinical History
 *        [5] Clinical History2
 *        [6] Lungs1
 *
 */
export const getDefaultFieldName = (
  editor: EditorType,
  fieldEntry: ?NodeEntry<NodeType>
): string => {
  if (fieldEntry == null) {
    return '';
  }

  let fieldStartLocation: ?LocationType = null;
  try {
    fieldStartLocation =
      Editor.before(editor, fieldEntry[1]) ?? Editor.start(editor, fieldEntry[1]);
  } catch {
    fieldStartLocation = Editor.start(editor, fieldEntry[1]);
  }

  // Look for the closest preceding H1 to the picklist. This will be the start of our
  // search section
  const [closestHeadingEntry] = [
    ...Editor.nodes(editor, {
      at: { anchor: Editor.start(editor, []), focus: fieldStartLocation },
      match: (node: NodeType) => isHeadingNode(node, HeadingLevel.H1),
      reverse: true,
    }),
  ];

  // Trim the text of the heading and convert it to title case
  const closestHeadingText =
    closestHeadingEntry != null
      ? toTitleCase(normalizeText(Node.string(closestHeadingEntry[0])), true)
      : null;

  // The range that we will search for the subheading. It's equal to the end of
  // the heading we found in the last line (or the start of the editor if there is no heading)
  // to the start of our current field
  const subheadingSearchRange = {
    anchor:
      closestHeadingEntry != null
        ? Editor.end(editor, closestHeadingEntry[1])
        : Editor.start(editor, []),
    focus: fieldStartLocation,
  };

  const [closestSubheadingEntry] = [
    ...Editor.nodes(editor, {
      at: subheadingSearchRange,
      match: (node: NodeType) => isHeadingNode(node, HeadingLevel.H2),
      reverse: true,
    }),
  ];

  // Trim the text of the subheading and convert it to title case
  const closestSubheadingText =
    closestSubheadingEntry != null
      ? toTitleCase(normalizeText(Node.string(closestSubheadingEntry[0])))
      : null;

  const currentFieldName = String(fieldEntry[0].name ?? '');

  // If the field already has a name that is not a duplicate field name
  if (
    currentFieldName != null &&
    currentFieldName !== '' &&
    !hasDuplicateFieldName(editor, currentFieldName)
  ) {
    return String(currentFieldName);
  }

  const prevNodeEntry = Editor.previous(editor, { at: fieldEntry[1] });

  // If the previous node is a subheading, don't bother doing any other calculations and just set it to be that
  // subheading's text.
  if (prevNodeEntry != null && isHeadingNode(prevNodeEntry[0], HeadingLevel.H2)) {
    return toTitleCase(normalizeText(Node.string(prevNodeEntry[0]))) + '1';
  }

  let defaultFieldName = '';
  let defaultNamedFields: Array<NodeEntry<>> = [];

  // This calculates all the fields between the end of the preceding subheading that
  // either has no name, an empty name, or a variation of the subheading text (i.e. 'Chest1', 'Chest2')
  if (closestSubheadingEntry != null && closestSubheadingText != null) {
    defaultNamedFields = getDefaultNamedFields({
      editor,
      anchor: Editor.end(editor, closestSubheadingEntry[1]),
      focus: fieldStartLocation,
      name: closestSubheadingText,
    });
    defaultFieldName = closestSubheadingText;
  } else if (closestHeadingEntry != null && closestHeadingText != null) {
    // This calculates all the fields between the closest heading entry of the editor and the current field that
    // either has no name, an empty name, or a variation of the closet heading text name ('Examination', 'Examination1')
    defaultNamedFields = getDefaultNamedFields({
      editor,
      anchor: Editor.end(editor, closestHeadingEntry[1]),
      focus: fieldStartLocation,
      name: closestHeadingText,
    });
    defaultFieldName = closestHeadingText;
  } else {
    // This calculates all the fields between the start of the editor and the current field that
    // either has no name, an empty name, or a variation of the default name ('Field1', 'Field2')
    defaultNamedFields = getDefaultNamedFields({
      editor,
      anchor: Editor.start(editor, []),
      focus: fieldStartLocation,
      name: DEFAULT_FIELD_NAME,
    });
    defaultFieldName = DEFAULT_FIELD_NAME;
  }

  const numDefaultFieldNames = defaultNamedFields.filter(
    (nodeEntry) => !Path.equals(nodeEntry[1], fieldEntry[1])
  ).length;

  if (
    closestHeadingText != null &&
    defaultFieldName === closestHeadingText &&
    numDefaultFieldNames === 0
  ) {
    return defaultFieldName;
  } else {
    return defaultFieldName + (numDefaultFieldNames + 1);
  }
};
