// @flow
import { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';
import type { StyledComponent, PropsWithTheme } from 'styled-components';
import { transparentize } from 'color2k';
import { Colors, Fonts } from 'styles';
import { ChipProvider } from './useChip';

const SIZE = Object.freeze({
  xs: css`
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 2px 6px;
  `,
  sm: css`
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding: 6px 12px;
  `,
  md: css`
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 6px 12px;
  `,
  lg: css`
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 6px 12px;
  `,
});

const COLOR = Object.freeze({
  blue: css`
    ${({ variant }: { variant: ChipVariant }) => {
      if (variant === 'outline') {
        return css`
          background: ${transparentize(Colors.blue2, 0.88)};
          border: 1px solid ${Colors.blue2};
        `;
      }
      if (variant === 'solid') {
        return css`
          background: ${Colors.blue2};
          border: 1px solid transparent;
        `;
      }
    }}
  `,
  gray: css`
    ${({ variant }: { variant: ChipVariant }) => {
      if (variant === 'outline') {
        return css`
          background: transparent;
          border: 1px solid ${Colors.gray8};
        `;
      }
      if (variant === 'solid') {
        return css`
          background: ${Colors.gray5};
          border: 1px solid transparent;
        `;
      }
    }}
  `,
});

export type ChipSize = 'xs' | 'sm' | 'md' | 'lg';
export type ChipVariant = 'solid' | 'outline';
export type ChipColor = 'blue' | 'gray';

export type ChipProps = {
  size?: ChipSize,
  variant?: ChipVariant,
  color?: ChipColor,
  disabled?: boolean,
  onClick?: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
  className?: string,
  children: React$Node,
  ...
};

export const Chip: React$AbstractComponent<ChipProps, HTMLButtonElement> = forwardRef<
  ChipProps,
  HTMLButtonElement,
>(function ForwardedChip(
  {
    size = 'md',
    variant = 'solid',
    color = 'gray',
    disabled = false,
    onClick,
    className,
    children,
    ...rest
  },
  ref
) {
  const contextValue = useMemo(
    () => ({
      size,
      variant,
      color,
      disabled,
    }),
    [size, variant, color, disabled]
  );
  return (
    <ChipProvider value={contextValue}>
      <BaseChip
        {...rest}
        type="button"
        ref={ref}
        variant={variant}
        size={size}
        color={color}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        className={className}
      >
        {children}
        {!disabled ? <ChipOverlay /> : null}
      </BaseChip>
    </ChipProvider>
  );
});

type BaseChipProps = {
  size: $NonMaybeType<ChipProps['size']>,
  variant: $NonMaybeType<ChipProps['variant']>,
  color: $NonMaybeType<ChipProps['color']>,
  disabled: $NonMaybeType<ChipProps['disabled']>,
};

export const BaseChip: StyledComponent<BaseChipProps, empty, HTMLButtonElement> = styled.button`
  ${(props: PropsWithTheme<BaseChipProps, empty>) => COLOR[props.color]}
  ${(props: PropsWithTheme<BaseChipProps, empty>) => SIZE[props.size]}
  ${(props: PropsWithTheme<BaseChipProps, empty>) =>
    props.disabled &&
    css`
      & {
        background: ${Colors.gray6};
        cursor: not-allowed;
        border: 1px solid transparent;
      }
    `}

  position: relative;
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  color: ${Colors.gray10};
  font-family: ${Fonts.mainFont};

  border-radius: 100px;

  &:focus,
  &:focus-within {
    border-color: ${(props: PropsWithTheme<BaseChipProps, empty>) =>
      props.color === 'blue' ? Colors.blue5 : Colors.gray8};
    box-shadow: ${(props: PropsWithTheme<BaseChipProps, empty>) =>
        transparentize(props.color === 'blue' ? Colors.blue5 : Colors.gray8, 0.88)}
      0 0 0 4px;
  }
`;

export const ChipOverlay: StyledComponent<{}, empty, HTMLSpanElement> = styled.span`
  position: absolute;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  pointer-events: none;

  background-color: ${transparentize(Colors.gray10, 1)};

  ${BaseChip}:hover &, ${BaseChip}:focus & {
    background-color: ${transparentize(Colors.gray10, 0.85)};
  }

  ${BaseChip}:active & {
    background-color: ${transparentize(Colors.gray10, 0.7)};
  }
`;
