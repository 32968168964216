// @flow

import type { EditorType } from '../core';

/**
 * Set an editor's selection to null even if it's being ran in
 * headless mode.
 */
export const safeDeselect = (editor: EditorType) => {
  const { selection } = editor;

  if (selection) {
    editor.apply({
      type: 'set_selection',
      properties: selection,
      newProperties: null,
    });
  }
};
