// @flow

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import type { LineBreakPluginElement, LineBreakPluginPropertyOptions } from '../types';
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';

export const LineBreakEditable: GetRenderElementComponentType<
  LineBreakPluginPropertyOptions,
  LineBreakPluginElement,
> = ({ element, children, ...rest }): React$Node => {
  return (
    <span contentEditable={false}>
      {children}
      <br />
    </span>
  );
};

export const LineBreakRenderer: StyledComponent<{ ... }, { ... }, typeof LineBreakEditable> =
  styled(LineBreakEditable)`
    white-space: pre-wrap;
  `;
