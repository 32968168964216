// @flow
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';

import type { DreAllViewportTypes } from 'config/constants';
import { createContext } from 'common/ui/util/createContext';
import type { CreateContextReturn } from 'common/ui/util/createContext';

import { useViewerContext } from 'domains/viewer/Viewer/ViewerContext';
import type { Study, Series, Stack } from 'domains/viewer/ViewportsConfigurations/types';
import { viewportDisplayConfigurationSelector } from 'domains/viewer/ViewportsConfigurations';
import type { ViewportDisplayConfiguration } from 'domains/viewer/ViewportsConfigurations';
import { useAsyncError } from 'hooks/useAsyncError';

import {
  frameSmidsMapState,
  guideViewportAtom,
  isActiveViewportScrollingAtom,
  useResetState,
  useViewType,
  useViewportId,
} from '../state';
import { useViewportRenderMetrics } from '../useMeasureViewerPerformance';

import {
  BaseImagingProvider,
  emitterEvents,
  createActiveSliceChangedEmitterEvent,
} from './BaseImagingProvider';
import type { ProviderStatus, SlicePlane } from './BaseImagingProvider';
import { activeViewportState } from 'config/recoilState';
import { createDebouncedActiveSliceChangedEmitterEvent } from './RenderManager';
import { useMount } from 'react-use';
import { logger } from 'modules/logger';
import { PixelDataSharedWorkerError } from 'modules/viewer/workers/PixelWorkerConnection';
import type { Stack as FrameStack } from '../../../ViewportsConfigurations/types';
import { LOADING_PRIORITIES } from 'domains/viewer/loadingPriorities';

const AUTO_RETRY_LOAD_MAX_ATTEMPTS = 3;

const NON_RETRYABLE_ERRORS = [
  PixelDataSharedWorkerError.OutOfMemory,
  PixelDataSharedWorkerError.NotEnoughMemory,
];

export type TImagingContext = {
  imagingProvider: ?BaseImagingProvider<FrameStack>,
  readyToRender: boolean,
  status: ProviderStatus,
};

const [ContextProvider, useImagingContext]: CreateContextReturn<TImagingContext> =
  createContext<TImagingContext>({
    strict: true,
    errorMessage:
      'useImagingContext: `context` is undefined. Seems you forgot to wrap component within the ImagingContextProvider',
    name: 'ImagingContext',
  });

export type ImagingContextProviderProps = {
  study: ?Study,
  series: ?Series,
  stack: ?Stack,
  children: React$Node,
  isPriorStudy: boolean,
  viewportId: string,
  viewType: DreAllViewportTypes,
  viewportDisplayConfiguration: ?ViewportDisplayConfiguration,
};

export { useImagingContext, ContextProvider };
export const ImagingContextProvider = ({
  study,
  series,
  stack,
  children,
  isPriorStudy,
  viewType,
  viewportId,
  viewportDisplayConfiguration,
}: ImagingContextProviderProps): React$Node => {
  const throwAsyncError = useAsyncError();
  const resetState = useResetState(viewportId);
  const initialSlice = useRef(viewportDisplayConfiguration?.params2d?.imageNumber);
  const { stackProviders } = useViewerContext();
  const imagingProvider = stack != null ? stackProviders.get(stack.smid) : null;
  const [readyToRender, setReadyToRender] = useState(imagingProvider?.isReadyToRender() ?? false);
  const [status, setStatus] = useState<ProviderStatus>(() => imagingProvider?.status ?? 'init');

  const handleStatusUpdated = useCallback(
    (providerStatus: ProviderStatus) => {
      setStatus(providerStatus);
      if (imagingProvider == null) return;
      setReadyToRender(imagingProvider.isReadyToRender());
    },
    [imagingProvider]
  );

  useEffect(() => {
    if (imagingProvider == null) return;

    const imageChangeListenerId = imagingProvider.onImageChange(viewportId, viewType, () => {
      setReadyToRender(imagingProvider.isReadyToRender());
    });

    return () => {
      imagingProvider.offImageChange(imageChangeListenerId);
    };
  }, [imagingProvider, viewportId, viewType]);

  useEffect(() => {
    if (imagingProvider == null) return;
    setStatus(imagingProvider.status);
    if (imagingProvider.isReadyToRender()) {
      setReadyToRender(imagingProvider.isReadyToRender());
    }
    imagingProvider.emitter.on(emitterEvents.statusUpdated, handleStatusUpdated);
    return () => {
      imagingProvider.emitter.off(emitterEvents.statusUpdated, handleStatusUpdated);
    };
  }, [imagingProvider, handleStatusUpdated]);

  const {
    trackViewportMeta,
    logViewportRenderStart,
    logViewportRendered,
    logViewportInteractable,
    logViewportFinished,
    logViewportError,
  } = useViewportRenderMetrics(isPriorStudy);

  // We debounce this as we don't care to have an up to date value after
  // the viewports have rendered the first time. If we updated this instantly,
  // the viewports would have to re-render twice, one for the slice update and one for this
  const updateViewportDisplayConfiguration = useRecoilCallback(
    ({ set }) =>
      () => {
        if (stack == null || imagingProvider == null) return;

        const imageNumber = imagingProvider.getActiveSlice(viewType, viewportId);

        set(isActiveViewportScrollingAtom, false);
        set(
          viewportDisplayConfigurationSelector({ stackSmid: stack.smid, viewType, viewportId }),
          (config) => {
            return {
              ...config,
              params2d: {
                ...config?.params2d,
                imageNumber,
              },
            };
          }
        );
      },
    [stack, viewType, imagingProvider, viewportId]
  );

  const handleFrameLoaded = useRecoilCallback(
    ({ set, snapshot }) =>
      async (framesLoaded: number) => {
        if (imagingProvider == null) return;
        set(frameSmidsMapState(viewportId), imagingProvider.frameSmidsMap);
        trackViewportMeta(viewportId, { fromCache: imagingProvider.fromCache });

        if (framesLoaded === 1) {
          logViewportRendered(viewportId);
          logViewportInteractable(viewportId);
        }

        setReadyToRender(imagingProvider.isReadyToRender());
      },
    [viewportId, imagingProvider, trackViewportMeta, logViewportRendered, logViewportInteractable]
  );

  const handleStackLoaded = useCallback(() => {
    logViewportFinished(viewportId);
  }, [viewportId, logViewportFinished]);

  const handleLoadError = useCallback(
    (errorMessage: string) => {
      logViewportError(viewportId);
      if (imagingProvider != null) {
        if (
          imagingProvider.loadAttempts < AUTO_RETRY_LOAD_MAX_ATTEMPTS &&
          !NON_RETRYABLE_ERRORS.includes(errorMessage)
        ) {
          logger.warn('auto-retrying load attempt', ++imagingProvider.loadAttempts);
          imagingProvider.load({ priority: LOADING_PRIORITIES.ACTIVE });
          return;
        }
      }
      throwAsyncError(new Error(errorMessage));
    },
    [viewportId, logViewportError, imagingProvider, throwAsyncError]
  );

  const wasPartOfInitialLoad = imagingProvider?.wasPartOfInitialLoad();

  useEffect(() => {
    if (imagingProvider == null || wasPartOfInitialLoad == null) return;

    trackViewportMeta(viewportId, {
      renderMethod: imagingProvider.type,
      unpackingMethod: imagingProvider.unpackingMethod,
      lazyLoaded: !imagingProvider.wasPartOfInitialLoad(),
    });
  }, [imagingProvider, series, trackViewportMeta, viewportId, wasPartOfInitialLoad]);

  // Side-effects for when a series is loaded into a viewport,
  // either the initial series on load or when the user changes the series.
  useEffect(() => {
    if (imagingProvider == null) return;

    trackViewportMeta(viewportId, {
      instanceCount: imagingProvider?.stackSize,
    });
    logViewportRenderStart(viewportId, imagingProvider.stack.smid);
  }, [trackViewportMeta, logViewportRenderStart, imagingProvider, resetState, viewportId]);

  useMount(() => {
    if (stack == null || imagingProvider == null) {
      return;
    }

    const framesLoaded = imagingProvider.getFramesLoaded();

    // play catch-up on any events that may have been missed
    if (framesLoaded > 0) {
      handleFrameLoaded(framesLoaded);
      if (framesLoaded > 1) {
        // handleFrameLoaded only fires these for the first frame, which it missed
        logViewportRendered(viewportId);
        logViewportInteractable(viewportId);
      }
    }
    if (framesLoaded === imagingProvider?.stackSize) {
      handleStackLoaded();
    }
  });

  const handleReset = useRecoilCallback(
    ({ set }) =>
      () => {
        if (imagingProvider == null) {
          return;
        }
        set(frameSmidsMapState(viewportId), imagingProvider.frameSmidsMap);
        trackViewportMeta(viewportId, { fromCache: imagingProvider.fromCache });
      },
    [imagingProvider, viewportId, trackViewportMeta]
  );

  useEffect(() => {
    // If the viewport has been reset, but we are already ready to
    // show the image, manually trigger the events normally sent
    // by the imaging provider.
    if (imagingProvider != null && imagingProvider.isReadyToRender()) {
      handleReset();
      if (status !== 'complete' && imagingProvider.status === 'complete') {
        handleStackLoaded();
      }
    }
  }, [imagingProvider, status, handleReset, handleStackLoaded]);

  useEffect(() => {
    if (imagingProvider == null) return;
    imagingProvider.emitter.on(emitterEvents.loadError, handleLoadError);
    return () => imagingProvider.emitter.off(emitterEvents.loadError, handleLoadError);
  }, [imagingProvider, handleLoadError]);

  useEffect(() => {
    if (stack == null || imagingProvider == null) {
      return;
    }

    const emitter = imagingProvider.emitter;
    emitter.on(emitterEvents.frameLoaded, handleFrameLoaded);
    emitter.on(emitterEvents.stackLoaded, handleStackLoaded);
    emitter.on(
      createDebouncedActiveSliceChangedEmitterEvent(viewType),
      updateViewportDisplayConfiguration
    );

    // Update the active slice if a non-default slice was given from the viewport display configuration.
    // This should only apply if we don't have active state for the viewport already,
    // such as when the stack has been scrolled on a different viewport-
    // the display config would be out of date.
    if (initialSlice.current != null && initialSlice.current !== 0) {
      imagingProvider._initializeVtkSlice(viewType, viewportId, initialSlice.current);
    }

    const loadImage = async () => {
      try {
        await imagingProvider.load({
          initialSlice: initialSlice.current,
          priority: LOADING_PRIORITIES.HANGED,
        });
      } catch (e) {
        logViewportError(viewportId);
        throwAsyncError(e);
      }
    };

    loadImage();

    return () => {
      emitter.off(emitterEvents.frameLoaded, handleFrameLoaded);
      emitter.off(emitterEvents.stackLoaded, handleStackLoaded);
      emitter.off(
        createDebouncedActiveSliceChangedEmitterEvent(viewType),
        updateViewportDisplayConfiguration
      );
    };
  }, [
    stack,
    viewType,
    viewportId,
    imagingProvider,
    handleFrameLoaded,
    handleStackLoaded,
    logViewportError,
    throwAsyncError,
    updateViewportDisplayConfiguration,
  ]);

  const contextValue = useMemo(
    () => ({
      imagingProvider,
      status,
      readyToRender,
    }),
    [imagingProvider, status, readyToRender]
  );

  return <ContextProvider value={contextValue}>{children}</ContextProvider>;
};

export const useGetActiveSlice = (): (() => number) => {
  const viewType = useViewType();
  const viewportId = useViewportId();
  const { imagingProvider } = useImagingContext();
  return useCallback(
    () => imagingProvider?.getActiveSlice(viewType, viewportId) ?? 0,
    [viewType, viewportId, imagingProvider]
  );
};

export const useActiveSliceForViewport = (
  viewportId: string,
  viewType: DreAllViewportTypes
): number => {
  const { imagingProvider } = useImagingContext();
  // This state is used to trigger a re-render when the active slice changes.
  const [, setActiveSlice] = useState(
    () => imagingProvider?.getActiveSlice(viewType, viewportId) ?? 0
  );

  // If may look like we are receiving and tracking the active slice number
  // here, but actually we are only listening to it in order to trigger re-renders.
  useEffect(() => {
    if (imagingProvider == null) return;

    const handleActiveSliceChanged = () => {
      setActiveSlice(imagingProvider.getActiveSlice(viewType, viewportId));
    };

    imagingProvider.emitter.on(
      createActiveSliceChangedEmitterEvent(viewType),
      handleActiveSliceChanged
    );

    return () => {
      imagingProvider.emitter.off(
        createActiveSliceChangedEmitterEvent(viewType),
        handleActiveSliceChanged
      );
    };
  }, [viewType, viewportId, imagingProvider]);

  // We always want to return the most up-to-date slice number.
  return imagingProvider?.getActiveSlice(viewType, viewportId) ?? 0;
};
export const useActiveSlice = (): number => {
  const viewportId = useViewportId();
  const viewType = useViewType();

  return useActiveSliceForViewport(viewportId, viewType);
};

export const useActiveSlicePlane = (): ?SlicePlane => {
  const viewType = useViewType();
  const viewportId = useViewportId();
  const { imagingProvider } = useImagingContext();
  // This state is used to trigger a re-render when the active slice changes.
  const [, setActiveSlicePlane] = useState(() =>
    imagingProvider?.getActiveSlicePlane(viewType, viewportId)
  );

  // If may look like we are receiving and tracking the active slice number
  // here, but actually we are only listening to it in order to trigger re-renders.
  useEffect(() => {
    if (imagingProvider == null) return;

    const handleActiveSliceChanged = () => {
      setActiveSlicePlane(imagingProvider.getActiveSlicePlane(viewType, viewportId));
    };

    imagingProvider.emitter.on(
      createActiveSliceChangedEmitterEvent(viewType),
      handleActiveSliceChanged
    );

    return () => {
      imagingProvider.emitter.off(
        createActiveSliceChangedEmitterEvent(viewType),
        handleActiveSliceChanged
      );
    };
  }, [viewType, viewportId, imagingProvider]);

  // We always want to return the most up-to-date slice plane
  return imagingProvider?.getActiveSlicePlane(viewType, viewportId);
};

export const useGuideViewportImagingProvider = (): ?BaseImagingProvider<Stack> => {
  const { stackProviders } = useViewerContext();
  const guideViewport = useRecoilValue(guideViewportAtom);
  const guideViewportId = useRecoilValue(activeViewportState);

  // This is important, because `activeViewportState` is shared between DRE and Fovia,
  // while `guideViewportAtom` is DRE only. This results in cases where the
  // `guideViewportAtom` is updated to a new viewport (from FocusHandler), but
  // `activeViewportState` has not yet been updated and is out of sync.
  //
  // We only want to return the imaging provider for the guide viewport when we're sure
  // it's the _correct_ imaging provider for that viewport.
  if (guideViewport.id !== guideViewportId) return;

  const imagingProvider = stackProviders.get(guideViewport.stackSmid ?? '');

  return imagingProvider;
};

export const useGuideViewportActiveSlice = (): number => {
  const { stackProviders } = useViewerContext();
  const guideViewport = useRecoilValue(guideViewportAtom);
  const guideViewportId = useRecoilValue(activeViewportState);
  const imagingProvider = stackProviders.get(guideViewport.stackSmid ?? '');
  // This state is used to trigger a re-render when the active slice changes.
  // eslint-disable-next-line no-unused-vars
  const [_, setActiveSlice] = useState(
    () => imagingProvider?.getActiveSlice(guideViewport.viewType, guideViewportId) ?? 0
  );

  // If may look like we are receiving and tracking the active slice number
  // here, but actually we are only listening to it in order to trigger re-renders.
  useEffect(() => {
    if (imagingProvider == null) return;

    const handleActiveSliceChanged = () => {
      setActiveSlice(imagingProvider.getActiveSlice(guideViewport.viewType, guideViewportId));
    };
    imagingProvider.emitter.on(
      createActiveSliceChangedEmitterEvent(guideViewport.viewType),
      handleActiveSliceChanged
    );

    return () => {
      imagingProvider.emitter.off(
        createActiveSliceChangedEmitterEvent(guideViewport.viewType),
        handleActiveSliceChanged
      );
    };
  }, [guideViewport.viewType, guideViewportId, imagingProvider]);

  return imagingProvider?.getActiveSlice(guideViewport.viewType, guideViewportId) ?? 0;
};

export const useGuideViewportActiveSlicePlane = (): ?SlicePlane => {
  const { stackProviders } = useViewerContext();
  const guideViewport = useRecoilValue(guideViewportAtom);
  const guideViewportId = useRecoilValue(activeViewportState);
  const imagingProvider = stackProviders.get(guideViewport.stackSmid ?? '');
  // This state is used to trigger a re-render when the active slice changes.
  // eslint-disable-next-line no-unused-vars
  const [_, setActiveSlicePlane] = useState(() =>
    imagingProvider?.getActiveSlicePlane(guideViewport.viewType, guideViewportId)
  );

  // If may look like we are receiving and tracking the active slice number
  // here, but actually we are only listening to it in order to trigger re-renders.
  useEffect(() => {
    if (imagingProvider == null) return;

    const handleActiveSliceChanged = () => {
      setActiveSlicePlane(
        imagingProvider.getActiveSlicePlane(guideViewport.viewType, guideViewportId)
      );
    };
    imagingProvider.emitter.on(
      createActiveSliceChangedEmitterEvent(guideViewport.viewType),
      handleActiveSliceChanged
    );

    return () => {
      imagingProvider.emitter.off(
        createActiveSliceChangedEmitterEvent(guideViewport.viewType),
        handleActiveSliceChanged
      );
    };
  }, [guideViewport.viewType, guideViewportId, imagingProvider]);

  return imagingProvider?.getActiveSlicePlane(guideViewport.viewType, guideViewportId);
};
