// @flow
import { NAMESPACES, sendEvent } from 'modules/EventsManager';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useOpenTabs } from './useOpenTabs';
import { PATH } from '../config/constants';
import { logger } from '../modules/logger';

type UseManagePatientJacketReturn = {
  managePatientJacket: (nextCaseSmid: ?string) => void,
};

export const useManagePatientJacket = (): UseManagePatientJacketReturn => {
  const { data } = useCurrentUser();
  const me = data?.me;
  const openPatientJacketOnRead = me?.worklistSettings?.openPatientJacketOnRead ?? true;

  const openTabs = useOpenTabs();
  const isPatientJacketOpen = openTabs.some((tab) => tab.path.includes(`${PATH.WORKLIST}/jacket`));

  const managePatientJacket = (nextCaseSmid: ?string) => {
    if (nextCaseSmid == null) {
      logger.info('[useManagePatientJacket] sending closePatientJacket event', {
        nextCaseSmid,
        openPatientJacketOnRead,
        isPatientJacketOpen,
      });
      sendEvent(NAMESPACES.WORKLIST_EVENT, {
        type: 'closePatientJacket',
      });
    } else if (isPatientJacketOpen) {
      logger.info('[useManagePatientJacket] sending nextPatientJacket event', {
        nextCaseSmid,
        openPatientJacketOnRead,
        isPatientJacketOpen,
      });
      sendEvent(NAMESPACES.WORKLIST_EVENT, {
        type: 'nextPatientJacket',
        caseSmid: nextCaseSmid,
      });
    } else if (openPatientJacketOnRead) {
      logger.info('[useManagePatientJacket] sending openPatientJacket event', {
        nextCaseSmid,
        openPatientJacketOnRead,
        isPatientJacketOpen,
      });
      sendEvent(NAMESPACES.WORKLIST_EVENT, {
        type: 'openPatientJacket',
        caseSmid: nextCaseSmid,
      });
    }
  };

  return { managePatientJacket };
};
