// @flow

import { useFields } from './Fields.Context';
import type { FieldsProviderProps } from './Fields.Context';
import { FieldsEditor } from './Fields.Editor';
import { useRecoilValue } from 'recoil';
import { reportStatusState, SECTION_EDITOR_EDITABLE_STATUSES } from '../state';
import { Stack } from 'common/ui/Layout';
import { css } from 'styled-components';
import type { OnStableText } from '../../RichTextEditor/plugins/dictation/types';
import type { EditorType } from 'slate';

type Props = {
  isDisabled: boolean,
  onInit?: FieldsProviderProps['onInit'],
  aiMode?: boolean,
  onStableText?: OnStableText,
  editor?: ?EditorType,
};

export const FieldsSectionEditor = ({
  isDisabled,
  onInit,
  aiMode = false,
  onStableText,
  editor,
}: Props): React$Node => {
  const [{ content, editorKey }, { onReportChange }] = useFields();
  const status = useRecoilValue(reportStatusState);

  return (
    <Stack
      data-stack-stretch
      css={css`
        max-height: 100%;
        overflow: hidden;
      `}
      space="small"
      stretchX
      stretchY
    >
      <FieldsEditor
        key={editorKey}
        editor={editor}
        content={content}
        onChange={onReportChange}
        isDisabled={isDisabled}
        onInit={onInit}
        aiMode={aiMode}
        onStableText={onStableText}
        variant={SECTION_EDITOR_EDITABLE_STATUSES.includes(status) ? 'report' : 'view'}
      />
    </Stack>
  );
};
