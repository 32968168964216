// @flow
import { coercion, optional, object, literal, string, number, array } from '@recoiljs/refine';
import { ToolInteractionTypeValues, MouseButtonValues } from 'generated/graphql';
import type { ToolInteractionUnion, ToolViewportClickInteraction } from 'generated/graphql';
import { stringLiteralsCodegen } from 'utils/refine';
import { MOUSE_BUTTON } from '../../../constants';
import { useEventAction } from '../../../modules/usePicker';
import type { PickerEvent } from '../../../modules/usePicker';
import { showToastMessage } from '../feedback';
import { interactionHttpRequestChecker } from '../checkers';
import {
  useSetCursor,
  toCssCursor,
  useRegisterCursor,
  DEFAULT_CURSOR_ID,
} from '../../../modules/cursor';
import type { TriggerToolInteraction } from '../ConfigBasedTool';
import { useRecoilCallback } from 'recoil';
import { isDrawingAnnotationSelector } from '../responses/startAnnotation';
import { useMemo } from 'react';

const viewportClickInteractionChecker = object({
  __typename: optional(literal('ToolViewportClickInteraction')),
  type: literal(ToolInteractionTypeValues.ViewportClick),
  id: string(),
  cursorIcon: string(),
  button: stringLiteralsCodegen(MouseButtonValues),
  maxConcurrentRequests: number(),
  request: interactionHttpRequestChecker,
});

type UseViewportClickInteraction = {
  interactions: $ReadOnlyArray<$ReadOnly<ToolInteractionUnion>>,
  triggerToolInteraction: TriggerToolInteraction,
  loading: boolean,
  toolId: string,
};

export function useViewportClickInteraction({
  toolId,
  interactions,
  triggerToolInteraction,
  loading,
}: UseViewportClickInteraction) {
  const viewportClickInteractions =
    coercion<$ReadOnlyArray<$ReadOnly<ToolViewportClickInteraction>>>(
      array(viewportClickInteractionChecker)
    )(
      interactions.filter(
        (interaction) => interaction.type === ToolInteractionTypeValues.ViewportClick
      )
    ) ?? [];

  const leftViewportClickInteraction = viewportClickInteractions.find(
    (interaction) => interaction.button === MouseButtonValues.Left
  );
  const rightViewportClickInteraction = viewportClickInteractions.find(
    (interaction) => interaction.button === MouseButtonValues.Right
  );
  const middleViewportClickInteraction = viewportClickInteractions.find(
    (interaction) => interaction.button === MouseButtonValues.Middle
  );

  const cursor = leftViewportClickInteraction?.cursorIcon ?? '';
  const cursorConfig = useMemo(
    () => ({
      id: DEFAULT_CURSOR_ID,
      cursor: toCssCursor({ value: `url(${cursor})` }),
    }),
    [cursor]
  );

  useRegisterCursor(cursorConfig);
  const setCursor = useSetCursor();

  const handleClick = useRecoilCallback(
    ({ snapshot }) =>
      async (event: PickerEvent) => {
        const isDrawingAnnotation = await snapshot.getPromise(isDrawingAnnotationSelector);

        // if we are drawing an annotation, we don't want to trigger this interaction
        // if we clicked over an annotation, we don't want to trigger this interaction
        if (event.id != null || isDrawingAnnotation) {
          return;
        }

        let viewportClickInteraction;
        switch (event.button) {
          case MOUSE_BUTTON.LEFT:
            viewportClickInteraction = leftViewportClickInteraction;
            break;
          case MOUSE_BUTTON.MIDDLE:
            viewportClickInteraction = middleViewportClickInteraction;
            break;
          case MOUSE_BUTTON.RIGHT:
            viewportClickInteraction = rightViewportClickInteraction;
            break;
          default:
            return;
        }

        if (viewportClickInteraction == null) {
          // No interaction defined for this button
          return;
        }

        event.originalEvent?.stopPropagation();

        const maxConcurrentRequests = viewportClickInteraction.maxConcurrentRequests ?? 0;
        const currentConcurrentRequests = loading ? 1 : 0;
        if (maxConcurrentRequests !== 0 && currentConcurrentRequests >= maxConcurrentRequests) {
          // TODO: Scope this by interaction, right now it's global
          showToastMessage('Please wait for the current request to complete');
          return;
        }

        setCursor({ id: DEFAULT_CURSOR_ID, cursor: 'wait' });
        try {
          await triggerToolInteraction({
            toolId,
            toolInteractionId: viewportClickInteraction.id,
            toolInteractionType: viewportClickInteraction.type,
          });
        } catch (err) {
          // This is handled by the `useMutation` onError option
        } finally {
          setCursor(cursorConfig);
        }
      },
    [
      cursorConfig,
      leftViewportClickInteraction,
      loading,
      middleViewportClickInteraction,
      rightViewportClickInteraction,
      setCursor,
      toolId,
      triggerToolInteraction,
    ]
  );

  useEventAction('click', handleClick);
  useEventAction('contextMenu', handleClick);
}
