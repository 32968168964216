// @flow

import { Editor, Element, Range } from '../core';
import type { EditorType, NodeType } from '../core';
import { isSquareBracketType } from '../constants';
import { DEPLOY_ENV } from 'config';

export const areMultipleSiblingBracketsSelected = (editor: EditorType): boolean => {
  const { selection } = editor;
  if (selection == null || Range.isCollapsed(selection)) {
    return false;
  }

  try {
    const bracketPaths = Array.from(
      Editor.nodes<NodeType>(editor, {
        match: (n: NodeType) =>
          // $FlowFixMe[incompatible-call] type is an ElementPluginID
          Element.isElement(n) && n.type != null && isSquareBracketType(n.type),
      })
    ).map(([, path]) => path);

    const pathLengths = new Map();
    for (const path of bracketPaths) {
      const length = path.length;

      if (pathLengths.has(length)) {
        return true;
      } else {
        pathLengths.set(length, true);
      }
    }
  } catch (e) {
    // The editor's selection might be out of sync with the latest transform which
    // will cause this call to fail and throw. This function is called during the
    // render of a React component, meaning if we do not catch it we will crash
    // the editor. Being out of sync does not mean there is a bug, once the selection
    // syncs up the component that calls this will re-render and should work.
    if (DEPLOY_ENV !== 'production') {
      console.warn(e);
    }
    return false;
  }

  return false;
};
