// @flow

import { BroadcastChannel } from 'broadcast-channel';
import { rafThrottle } from 'utils/rafThrottle';
import type { ThrottledCallback } from 'utils/rafThrottle';
import type { StackSyncBroadcastChannelMessage } from '../../../Viewer/ViewerContext';

export class SynchronizationBroadcaster {
  #seriesSyncBroadcastChannel: BroadcastChannel<{
    messages: Array<StackSyncBroadcastChannelMessage>,
  }>;
  #rafThrottledCallback: ThrottledCallback<() => void>;
  #messages: Array<StackSyncBroadcastChannelMessage>;
  removeHandler: () => void;

  constructor() {
    this.#seriesSyncBroadcastChannel = new BroadcastChannel('series-sync', {
      webWorkerSupport: false,
    });
    this.#rafThrottledCallback = rafThrottle((updateCallback: () => void) => updateCallback());
    this.#messages = [];
  }

  setMessageHandler(
    handler: (data: { messages: Array<StackSyncBroadcastChannelMessage> }) => void
  ) {
    this.#seriesSyncBroadcastChannel.onmessage = handler;

    this.removeHandler = () => {
      this.#seriesSyncBroadcastChannel.removeEventListener('message', handler);
    };
  }

  queueMessage(message: StackSyncBroadcastChannelMessage) {
    this.#messages.push(message);
    this.#throttledDispatch();
  }

  #throttledDispatch() {
    this.#rafThrottledCallback(() => {
      this.#seriesSyncBroadcastChannel.postMessage({ messages: this.#messages.splice(0) });
    });
  }
}
