// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const LINE_BREAK_PLUGIN_ID: 'lineBreak' = 'lineBreak';
export type LineBreakPluginID = typeof LINE_BREAK_PLUGIN_ID;

type LineBreakVariant = LineBreakVariant;

export type LineBreakPluginElement = $ReadOnly<{
  ...EditorElement,
  type: LineBreakPluginID,
}>;

export type LineBreakPluginElementMutable = {
  ...LineBreakPluginElement,
};

export type LineBreakPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,
}>;

export type LineBreakPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...LineBreakPluginOptions,
  pluginID: LineBreakPluginID,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
}>;
