//@flow

import type { VtkAnnotation } from '../Annotations/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import analytics from 'modules/analytics';
import { viewer } from 'modules/analytics/constants';
import { useAnnotationsRenderTrackingState } from '../Annotations/AnnotationsRenderBatcher';
import { nanoid } from 'nanoid';
import { marks, measures } from '../performance';
import { useLayouts } from '../../Viewer/StudyLoader/useLayouts';
import debounce from 'lodash.debounce';
import { annotationsCacheState } from '../../ViewportsConfigurations/state';
import { useRecoilState } from 'recoil';

type RenderingEventType =
  | 'load'
  | 'create'
  | 'delete'
  | 'update'
  | 'viewports_change'
  | 'using_tool';

// shared frames for batch reporting, shared across all viewports
let frames: {
  [type: RenderingEventType]: Array<{
    duration: number,
    annotations: number,
    viewports: number,
  }>,
} = {
  load: [],
  create: [],
  delete: [],
  update: [],
  viewports_change: [],
  using_tool: [],
};

const eventTypes = ['load', 'create', 'delete', 'update', 'viewports_change', 'using_tool'];

const reportFrameDurations = () => {
  const frameSnapshot = frames;
  frames = {
    load: [],
    create: [],
    delete: [],
    update: [],
    viewports_change: [],
    using_tool: [],
  };

  eventTypes.forEach((type) => {
    if (frameSnapshot[type].length > 0) {
      const {
        durationSum,
        durationMin,
        durationMax,
        annotationsSum,
        annotationsMin,
        annotationsMax,
        viewportsSum,
        viewportsMin,
        viewportsMax,
      } = frameSnapshot[type].reduce(
        (acc, frame) => {
          acc.durationSum = acc.durationSum + frame.duration;
          acc.durationMin = Math.min(acc.durationMin, frame.duration);
          acc.durationMax = Math.max(acc.durationMax, frame.duration);

          acc.annotationsSum = acc.annotationsSum + frame.annotations;
          acc.annotationsMin = Math.min(acc.annotationsMin, frame.annotations);
          acc.annotationsMax = Math.max(acc.annotationsMax, frame.annotations);

          acc.viewportsSum = acc.viewportsSum + frame.viewports;
          acc.viewportsMin = Math.min(acc.viewportsMin, frame.viewports);
          acc.viewportsMax = Math.max(acc.viewportsMax, frame.viewports);

          return acc;
        },
        {
          durationSum: 0,
          durationMin: Infinity,
          durationMax: 0,
          annotationsSum: 0,
          annotationsMin: Infinity,
          annotationsMax: 0,
          viewportsSum: 0,
          viewportsMin: Infinity,
          viewportsMax: 0,
        }
      );
      const averageFrameDuration = durationSum / frameSnapshot[type].length;
      const averageAnnotations = annotationsSum / frameSnapshot[type].length;
      const averageViewports = viewportsSum / frameSnapshot[type].length;

      const stats = {
        type,
        totalFrames: frameSnapshot[type].length,
        durationMin,
        durationMax,
        averageFrameDuration,
        annotationsMin,
        annotationsMax,
        averageAnnotations,
        viewportsMin,
        viewportsMax,
        averageViewports,
        totalFrameRenderTime: durationSum,
      };
      analytics.track(viewer.sys.annotationsRendered, stats);
    }
  });
};

const debouncedReportFrameDurations = debounce(reportFrameDurations, 2000);

export const useTrackAnnotationRenderTime = (annotations: Array<VtkAnnotation>) => {
  const previousAnnotations = usePrevious(annotations);

  const { layoutForWindow } = useLayouts();
  const numberOfViewports = useMemo(
    () => (layoutForWindow?.[0] ?? 0) * (layoutForWindow?.[1] ?? 0),
    [layoutForWindow]
  );
  const previousNumberOfViewports = usePrevious(numberOfViewports);

  /*
   * disabling due to inaccuracies that require larger changes to annotations
   * see https://sironamedical.atlassian.net/browse/EN-7605 
  const trackLoad = useCallback(
    (duration) => {
      if (annotations.length > 0) {
        analytics.timing('viewer_annotations_render_complete');
        return track('load', duration, annotations.length, numberOfViewports);
      }
    },
    [annotations]
  );
  useWaitForRender(trackLoad);
  */

  const trackChanges = useCallback(
    (duration) => {
      if (previousAnnotations) {
        const numberOfAnnotations = annotations.length;
        if (annotations.length > previousAnnotations.length) {
          track('create', duration, numberOfAnnotations, numberOfViewports);
        } else if (annotations.length < previousAnnotations.length) {
          track('delete', duration, numberOfAnnotations, numberOfViewports);
        } else if (
          annotations.length === previousAnnotations.length &&
          numberOfViewports !== previousNumberOfViewports
        ) {
          if (numberOfAnnotations > 0) {
            track('viewports_change', duration, numberOfAnnotations, numberOfViewports);
          }
        } else if (annotations.length === previousAnnotations.length) {
          track('update', duration, numberOfAnnotations, numberOfViewports);
        }
      }
    },
    // we only need to watch the new annotations, as we know previous will update between
    // including previousAnnotations causes the effect to run twice
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [annotations, numberOfViewports]
  );
  useWaitForRender(trackChanges);
};

export const useTrackToolboxAnnotationRenderTime = (lastPoint: ?mixed) => {
  const [annotations] = useRecoilState(annotationsCacheState);
  const { layoutForWindow } = useLayouts();
  const numberOfViewports = useMemo(() => layoutForWindow?.[0] ?? 0, [layoutForWindow]);

  const numberOfAnnotations = useMemo(() => annotations.length + 1, [annotations]);
  const trackToolChanges = useCallback(
    (duration) => {
      if (lastPoint != null) {
        return track('using_tool', duration, numberOfAnnotations, numberOfViewports);
      }
    },
    [lastPoint, numberOfAnnotations, numberOfViewports]
  );

  useWaitForRender(trackToolChanges);
};

const useWaitForRender = (callback: (duration: number) => void): void => {
  const [renderTrackingState, setRenderTrackingState] = useAnnotationsRenderTrackingState();
  const [callbackId, setCallbackId] = useState<string | null>(null);
  useEffect(() => {
    const id = nanoid();
    setCallbackId(id);
    performance.mark(marks.Annotations.Start(id));
    setRenderTrackingState('REQUESTED');
  }, [callback, setRenderTrackingState]);

  useEffect(() => {
    if (callbackId !== null) {
      const track = performance.getEntriesByName(marks.Annotations.Start(callbackId))[0];
      if (track && renderTrackingState === 'RENDERED') {
        callback(performance.measure(...measures.Annotations(callbackId))?.duration ?? 0);
        performance.clearMarks(marks.Annotations.Start(callbackId));
      }
    }
  }, [callback, callbackId, renderTrackingState]);
};

const track = (
  type: RenderingEventType,
  duration: number,
  annotations: number,
  viewports: number
) => {
  frames[type].push({ duration, annotations, viewports });
  debouncedReportFrameDurations();
};
