// @flow

import type { ParagraphPluginElement } from '../plugins/paragraph/types';
import type { NodeType } from 'slate';

import { Node } from '../core';

export const slateContentToString = <T: NodeType | ParagraphPluginElement>(
  nodes: $ReadOnlyArray<T>
): string => {
  // $FlowIgnore[incompatible-cast] we are casting ParagraphPluginElement to NodeType because ParagraphPluginElement should be a subtype of NodeType
  return (nodes: $ReadOnlyArray<NodeType>).map((n) => Node.string(n)).join('\n');
};
