// @flow

import type { InlineBookmarkPluginElement } from '../types';
import { ReadonlyInlineTemplateElement } from '../../common/inlineTemplate';
import { EMPTY } from 'config/constants';
import { forwardRef } from 'react';

type ReadonlyInlineBookmarkElementProps = {
  selected?: boolean,
  element: InlineBookmarkPluginElement,
  children: React$Node,
  'data-editor-element'?: string,
  'data-slate-node'?: 'element',
  'data-slate-inline'?: true,
  'data-slate-void'?: true,
  dir?: 'rtl',
};

export const ReadonlyInlineBookmarkElement: React$AbstractComponent<
  ReadonlyInlineBookmarkElementProps,
  HTMLElement,
> = forwardRef<ReadonlyInlineBookmarkElementProps, HTMLElement>(
  ({ selected, element, attributes = EMPTY.OBJECT, ...rest }, ref) => {
    return (
      <ReadonlyInlineTemplateElement
        ref={ref}
        {...rest}
        {...attributes}
        selected={selected}
        testID="inlineBookmark"
        data-selected={selected}
        element={element}
      />
    );
  }
);
ReadonlyInlineBookmarkElement.displayName = 'ReadonlyInlineBookmarkElement';
