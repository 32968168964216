// @flow

export type ThrottledCallback<A> = { (A): void, cancel(): void };
export const rafThrottle = <A>(callback: (A) => mixed): ThrottledCallback<A> => {
  let requestId = null;

  let lastArgs;

  const later = (context: { ... }) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (this: { ... }, ...args: [A]) {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    if (requestId == null) return;
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
};
