// @flow
import type { AnnotationStyle } from 'generated/graphql';
import type { DreMouseEvent, MouseHandlers } from '../../modules/types';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import {
  optional,
  object,
  literal,
  union,
  string,
  number,
  withDefault,
  array,
  bool,
} from '@recoiljs/refine';
import type { Checker } from '@recoiljs/refine';
import { vec3Checker } from 'utils/refine';
import { Line, Label, Polygon } from '../../Primitives';
import { makeSegmentsFromPoints } from '../../AnnotationTools/utils';
import { useAnnotationColors } from '../helpers/useAnnotationColors';

type PrimitiveRendererProps = {
  id: string,
  cursor: string,
  color?: string,
  onDrag: MouseHandlers['onDrag'],
  onDragEnd: MouseHandlers['onDragEnd'],
  onDragStart: MouseHandlers['onDragStart'],
  onMouseLeave: (DreMouseEvent) => mixed,
  onMouseOver: (DreMouseEvent) => mixed,
};

type CommonPrimitiveProps = {
  style?: $ReadOnly<$Diff<AnnotationStyle, { __typename: mixed }>>,
  visible?: boolean,
  'data-testid'?: string,
};

type LinePrimitive = $ReadOnly<{
  ...CommonPrimitiveProps,
  type: 'line',
  start: vec3,
  end: vec3,
}>;

type LabelPrimitive = $ReadOnly<{
  ...CommonPrimitiveProps,
  type: 'label',
  position: vec3,
  offsetDirection?: vec3,
  offsetDistance?: number,
  label: string,
  alignVertical?: 'top' | 'center' | 'bottom',
  alignHorizontal?: 'left' | 'center' | 'right',
}>;

type PolygonPrimitive = $ReadOnly<{
  ...CommonPrimitiveProps,
  type: 'polygon',
  points: $ReadOnlyArray<vec3>,
  fill?: boolean,
}>;

export type Primitive = LinePrimitive | LabelPrimitive | PolygonPrimitive;
export type PrimitiveType = 'line' | 'label' | 'polygon';

const annotationStyleChecker = () =>
  optional(
    object({
      color: optional(string()),
      opacity: optional(number()),
    })
  );

function styleToProps(
  style: ?$ReadOnly<$Diff<AnnotationStyle, { __typename: mixed }>>,
  color: ?string,
  defaultColor: string
) {
  const out: { color?: string, opacity?: number } = {};
  if (style?.color != null) {
    const isDefaultColor = color === defaultColor;
    if (color == null) {
      out.color = style.color;
    } else {
      out.color = isDefaultColor ? style.color : color;
    }
  } else if (color != null) {
    out.color = color;
  }
  if (style?.opacity != null) {
    out.opacity = style.opacity;
  }
  return out;
}

export const primitivesConfig = [
  {
    type: 'line',
    checker: (object({
      type: literal('line'),
      visible: withDefault(bool(), false),
      'data-testid': optional(string()),
      style: annotationStyleChecker(),
      start: vec3Checker(),
      end: vec3Checker(),
    }): Checker<LinePrimitive>),
    renderer: (({ type, start, end, style, id, color, ...props }) => {
      const { defaultColor } = useAnnotationColors();
      return (
        <Line
          id={id}
          start={start}
          end={end}
          {...styleToProps(style, color, defaultColor)}
          {...props}
        />
      );
    }: React$ComponentType<{
      ...PrimitiveRendererProps,
      ...LinePrimitive,
    }>),
  },
  {
    type: 'label',
    checker: (object({
      type: literal('label'),
      visible: withDefault(bool(), false),
      'data-testid': optional(string()),
      style: annotationStyleChecker(),
      position: vec3Checker(),
      label: string(),
      alignVertical: withDefault(
        union(literal('top'), literal('center'), literal('bottom')),
        'top'
      ),
      alignHorizontal: withDefault(
        union(literal('left'), literal('center'), literal('right')),
        'left'
      ),
      offsetDirection: withDefault(vec3Checker(), [0, 0, 0]),
      offsetDistance: withDefault(number(), 0),
    }): Checker<LabelPrimitive>),
    renderer: (({
      type,
      style,
      position,
      label,
      alignVertical,
      alignHorizontal,
      offsetDirection,
      offsetDistance,
      id,
      color,
      ...props
    }) => {
      const { defaultColor } = useAnnotationColors();
      return (
        <Label
          id={id}
          anchorPoint={position}
          topLeftBoundingBoxPoint={position}
          children={label}
          alignVertical={alignVertical}
          alignHorizontal={alignHorizontal}
          offsetDirection={offsetDirection}
          offsetDistance={offsetDistance}
          {...styleToProps(style, color, defaultColor)}
          {...props}
        />
      );
    }: React$ComponentType<{
      ...PrimitiveRendererProps,
      ...LabelPrimitive,
    }>),
  },
  {
    type: 'polygon',
    checker: (object({
      type: literal('polygon'),
      visible: withDefault(bool(), false),
      'data-testid': optional(string()),
      fill: withDefault(bool(), false),
      style: annotationStyleChecker(),
      points: array(vec3Checker()),
    }): Checker<PolygonPrimitive>),
    renderer: (({ type, points, style, id, color, fill = false, ...props }) => {
      const { defaultColor } = useAnnotationColors();
      return (
        <Polygon
          id={id}
          fill={fill ? 'polys' : 'lines'}
          segments={makeSegmentsFromPoints(points)}
          {...styleToProps(style, color, defaultColor)}
          {...props}
        />
      );
    }: React$ComponentType<{
      ...PrimitiveRendererProps,
      ...PolygonPrimitive,
    }>),
  },
];

export const primitiveChecker: Checker<Primitive> = union(
  ...primitivesConfig.map(({ checker }) => checker)
);
