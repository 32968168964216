// @flow

export const isNumber = (s: mixed): boolean => {
  return (
    // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(s) &&
    // ...and ensure strings of whitespace fail
    !isNaN(parseFloat(s))
  );
};
