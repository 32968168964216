// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import { partialEditor } from 'domains/reporter/RichTextEditor/utils';
import { logger } from 'modules/logger';
import { useReportLockedState } from 'domains/reporter/Reporter/hooks/useReportLockedState/useReportLockedState';
import { useCurrentCaseReport } from './useCurrentCaseReport';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import type { ReportTemplateFieldsFragment } from 'generated/graphql';

type UseAutoloadTemplateProps = {
  unfurlTemplate: (template: ReportTemplateFieldsFragment) => void,
  loading?: boolean,
};

export const useAutoloadTemplate = ({
  unfurlTemplate,
  loading = false,
}: UseAutoloadTemplateProps): ({ autoloadTemplate: () => void, hasAutoloaded: boolean }) => {
  const { isClaimedByMe } = useReportLockedState();
  const { currentCaseReport: worklistItem, loadingCaseReport } = useCurrentCaseReport();
  const [{ editor }] = useSlateSingletonContext();
  const [hasAutoloaded, setHasAutoloaded] = useState(false);
  const currentWorklistItemSmid = useRef(null);

  useEffect(() => {
    if (worklistItem == null) return;

    // if window is reporter is still open, and the worklist item has changed, reset hasAutoloaded
    if (currentWorklistItemSmid.current !== worklistItem.smid) {
      logger.info('[useAutoloadTemplate] resetting hasAutoloaded to false');
      setHasAutoloaded(false);
    }
    currentWorklistItemSmid.current = worklistItem.smid;
  }, [worklistItem]);

  const autoloadTemplate = useCallback(() => {
    logger.info('[useAutoloadTemplate] autoloadTemplate called');
    if (loading || loadingCaseReport) return;

    if (isClaimedByMe === false) return;

    if (worklistItem == null) return;

    const { report } = worklistItem;
    if (report == null) return;

    const { created, sectionsLastUpdated, template } = report;

    if (template == null || (sectionsLastUpdated != null && sectionsLastUpdated !== created)) {
      return;
    }

    if (editor == null || editor.children.length === 0) return;

    const autoloadInfoMessage = `[ReporterComponent] Attempting to autoload template "${
      template.id
    }" for worklist item "${worklistItem.smid}" with procedure description "${
      worklistItem.procedure?.description ?? 'N/A'
    }"`;

    logger.info(autoloadInfoMessage, {
      logId: editor.selection ? stringifyRange(editor.selection) : 'null',
      editor: partialEditor(editor),
      template: {
        name: template.name,
        smid: template.id,
      },
      updatedTime: sectionsLastUpdated,
    });

    unfurlTemplate(template);

    setHasAutoloaded(true);
  }, [editor, isClaimedByMe, loading, loadingCaseReport, unfurlTemplate, worklistItem]);

  return {
    autoloadTemplate,
    hasAutoloaded,
  };
};

export default useAutoloadTemplate;
