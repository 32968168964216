// @flow

import type { SortColumn, SortOrder } from 'generated/graphql';
import type { WorklistFiltersType } from '../WorklistFilters/types';
import type { WorklistSurfaceType } from './types';
import { getWorklistURLParams } from '../WorklistFilters/utils';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useWorklistURLParams = (
  overrideURLSearchParams?: ?URLSearchParams
): {
  surface: ?WorklistSurfaceType,
  sortColumn: Array<SortColumn>,
  sortOrder: Array<SortOrder>,
  filters: ?{ [WorklistFiltersType]: string },
} => {
  const [urlSearchParams] = useSearchParams();

  const overrideParamsString = useMemo(
    () => (overrideURLSearchParams ? overrideURLSearchParams.toString() : null),
    [overrideURLSearchParams]
  );
  const urlSearchParamsString = useMemo(() => urlSearchParams.toString(), [urlSearchParams]);

  const searchParams = useMemo(() => {
    const params = new URLSearchParams(urlSearchParamsString);
    if (overrideParamsString !== null) {
      const overrideParams = new URLSearchParams(overrideParamsString);

      for (const [key, value] of overrideParams.entries()) {
        params.set(key, value);
      }
    }

    return params;
  }, [overrideParamsString, urlSearchParamsString]);

  const sortedSearchParams = useMemo(() => {
    const params = new URLSearchParams(searchParams.toString());

    const keys = Array.from(params.keys()).sort();
    const sortedParams = new URLSearchParams();
    for (const key of keys) {
      const value = params.get(key);
      if (value !== null) {
        sortedParams.set(key, value);
      }
    }

    return sortedParams;
  }, [searchParams]);

  const { surface, sortColumn, sortOrder, filters } = useMemo(
    () => getWorklistURLParams(sortedSearchParams),
    [sortedSearchParams]
  );

  return { surface, sortColumn, sortOrder, filters };
};
