// @flow

import type { ReportStatuses } from 'domains/reporter/Reporter/state';
import type { EditorType, RangeRefType, RangeType } from 'slate';
import type { NvoqMessage, ParsedDictationChunk } from './NvoqQueue/createNvoqWebsocketFactory';
import type { Step, StepEntry } from './NvoqQueue/useSteps';
import type { OnExternalSubstitution, OnStableText, WebsocketCreationOptions } from './types';

import { isSquareBracketType } from '../../constants';
import { useRangeMasksDispatch } from '../../hooks/useRangeMasks';
import { getEditorDictationSelectionSafe, getRangeRefSafe, partialEditor } from '../../utils';
import { createEditorLog } from '../../utils/logging';
import { stringifyRange } from '../../utils/stringify';
import { isCursorAfterHeadingColon } from '../heading/utils/normalization';
import { getCurrentActiveListVariant } from '../list/utils';
import { getOrInsertNextInlineParagraph } from '../paragraph/utils';
import { getDeferredSteps, stepEntriesTextToString, useSteps } from './NvoqQueue/useSteps';
import {
  autoCorrectNvoqStableText,
  autoCorrectStableTextMarkers,
} from './NvoqQueue/utils/autoCorrectNvoqStableText';
import { createGroupedStepEntries } from './NvoqQueue/utils/createGroupedStepEntries';
import {
  generateNextParagraphInListSironaSubstitutionForNvoq,
  generateNextParagraphInListSironaSubstitutionForMarkers,
} from './NvoqQueue/utils/generateNextParagraphInListSironaSubstitution';
import { maybeDoVoiceCommand } from './NvoqQueue/utils/maybeDoVoiceCommand';
import {
  createDictationChunks,
  createDictationChunksFromNvoqStableText,
} from './NvoqQueue/utils/parseNvoqStableText';
import { trimHypothesisText } from './NvoqQueue/utils/trimHypothesisText';
import { MANUAL_CORRECTION_WORDS_LIMIT } from './constants';
import { DICTATION_PLUGIN_ID } from './types';
import { usePicklistDictationSelection } from './usePicklistDictationSelection';
import { wordCount } from './utils';

import { getNavigationItemsFromEditor } from 'domains/reporter/Reporter/Outline/utils';
import { PROVISIONAL_SUBMIT_STATUSES, reportStatusState } from 'domains/reporter/Reporter/state';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useImpressionGenerator } from 'hooks/useImpressionGenerator';
import { RICH_TEXT_EDITOR, useMostRecentInput } from 'hooks/useMostRecentInput';
import { useReporterDictationStepToast } from 'hooks/useReporterDictationStepToast';
import { useWorklistItemAnalytics } from 'hooks/useWorklistItemAnalytics';
import analytics from 'modules/analytics';
import { reporter } from 'modules/analytics/constants';
import { logger } from 'modules/logger';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { Editor, Node, Range, Transforms } from 'slate';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';
import { useSelectionTracking } from '../../../Reporter/hooks/useSelectionTracking/useSelectionTracking';
import {
  END_OF_SENTENCE_PUNCTUATION,
  NO_SPACES_BEFORE,
  NO_SPACES_AFTER,
} from '../../stitching/normalizationHelpers';
import type { HypothesisTextResponse, StableTextResponse } from './ASRPlex/ASRPlexProtocol';
import { useFocusMode, FocusModeOperation } from 'hooks/useFocusMode';
import { slateSource } from 'domains/reporter/RichTextEditor/core/slateSource';

function getClosestValue(
  timeMap: Map<number, RangeRefType>,
  audioMarkerStart: number,
  audioMarkerEnd: number
): ?RangeRefType {
  let closestValue = null;

  if (timeMap.size === 1) {
    return timeMap.get(0);
  }

  for (let i = 0; i < timeMap.size; i++) {
    const selectionEnd = Array.from(timeMap.keys())[i];
    const selectionStart = i > 0 ? Array.from(timeMap.keys())[i - 1] : 0;

    if (audioMarkerStart > selectionStart && audioMarkerEnd < selectionEnd) {
      closestValue = timeMap.get(selectionStart);
    } else if (audioMarkerStart > selectionEnd) {
      closestValue = timeMap.get(selectionEnd);
    } else if (audioMarkerStart > selectionStart && audioMarkerStart < selectionEnd) {
      if (Math.abs(selectionEnd - audioMarkerStart) > Math.abs(selectionEnd - audioMarkerEnd)) {
        closestValue = timeMap.get(selectionStart);
      } else {
        closestValue = timeMap.get(selectionEnd);
      }
    }
  }

  return closestValue;
}

type UseTextProcessingProps = {
  editor: EditorType,
  onStableText?: OnStableText,
  enablePicklistDictation: boolean,
  onExternalSubstitution: OnExternalSubstitution,
};

type UseTextProcessingResult = {
  processStableText: (NvoqMessage, RangeType) => Promise<void>,
  handleStableText: (id: string, msg: StableTextResponse) => Promise<void>,
  processFocusMapAppend: (NvoqMessage, string[]) => Promise<void>,
  processFocusMapDelete: (string, string[]) => Promise<void>,
  processHypothesisText: (NvoqMessage, RangeType) => void,
  processHypothesisTextMarkers: (HypothesisTextResponse, RangeType) => void,
  renderHypothesisText: (string, RangeType, RangeType) => void,
  selectionToSelectionRef: { current: WeakMap<RangeType, RangeRefType> },
  selectionToStepsQueueMap: { current: Map<RangeType, StepEntry[]> },
  isMousePressed: boolean,
  setShouldSkipWebsocketCreationRef: { current: ?(shouldSkip: boolean) => void },
  mostRecentStableTextInserted: { current: ?string },
  skipWebsocketCreationOptionsRef: { current: ?WebsocketCreationOptions },
};

/**
 * Custom hook that establishes state and returns functions for processing stable/hypothesis text.
 *
 * Abstracts logic originally closely tied to the NvoqQueue apparatus, so it can be used by both NvoqQueue and ASRPlex.
 */
export const useTextProcessing = ({
  editor,
  onStableText,
  enablePicklistDictation,
  onExternalSubstitution,
}: UseTextProcessingProps): UseTextProcessingResult => {
  const selectionToSelectionRef = useRef(new WeakMap<RangeType, RangeRefType>());

  const { createStepsFromStableText, applySteps } = useSteps();
  const analyticsData = useWorklistItemAnalytics();
  const { data: meData } = useCurrentUser();
  const autoCorrectDictionary = useMemo(() => {
    return meData?.me?.autoCorrect != null
      ? Object.fromEntries(meData.me.autoCorrect.map((acEntry) => [acEntry.key, acEntry.value]))
      : {};
  }, [meData]);
  const selectionToStepsQueueMap = useRef(new Map<RangeType, StepEntry[]>());
  const selectionRefToStepsQueueMap = useRef(new Map<RangeRefType, StepEntry[]>());

  const { isMousePressed, mostRecentInput, setMostRecentInput } =
    useMostRecentInput(RICH_TEXT_EDITOR);
  const mousePressedSinceLastStableTextRender = useRef<?boolean>();

  const recorderToTimeSelectionMap = useSelectionTracking();

  // whenever the mouse is pressed, set the mousePressedSinceLastStableTextRender to true
  useEffect(() => {
    if (isMousePressed === true) {
      mousePressedSinceLastStableTextRender.current = true;
    }
  }, [isMousePressed]);

  // whenever the mostRecentInput changes and its not 'mouse', reset the mousePressedSinceLastStableTextRender
  useEffect(() => {
    if (mostRecentInput !== 'mouse') {
      mousePressedSinceLastStableTextRender.current = false;
    }
  }, [mostRecentInput]);

  type SetShouldSkipRecorderCreationFn = (shouldSkip: boolean) => void;
  const setShouldSkipWebsocketCreationRef = useRef<?SetShouldSkipRecorderCreationFn>();
  const decorateDispatch = useRangeMasksDispatch();

  const removeDecorationForSelection = useCallback(
    (selection: RangeType) => {
      // Remove the decoration for the grand unveiling of the stable text!
      decorateDispatch((decorations) => decorations.filter((d) => d.selectionRef !== selection));
    },
    [decorateDispatch]
  );
  const cleanMessageQueueForSelection = useCallback(
    (selection: RangeType) => {
      logger.info('[dictation] Cleaning message queue for selection', {
        logId: stringifyRange(selection),
        editor: partialEditor,
        selectionToStepsQueueMap: JSON.stringify(selectionToStepsQueueMap.current),
      });
      removeDecorationForSelection(selection);
      selectionToStepsQueueMap.current.delete(selection);
    },
    [removeDecorationForSelection]
  );

  const { insertSelectedPicklistItemByIndex } = usePicklistDictationSelection({ editor });
  const ignoreMergeFieldsInNavigation =
    meData?.me?.reporterSettings?.mergeFieldsSettings?.ignoreNavigation ?? false;
  const { generateAndStitchImpression } = useImpressionGenerator();
  const { enqueueDictationStepToast } = useReporterDictationStepToast();
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);
  const mostRecentStableTextInserted: { current: ?string } = useRef<?string>();
  const { onInsertTextRequiredField } = useRequiredFieldIndicator();
  const { getInsertionPoint, deleteTextInSection, mostRecentFocusModeOperationRef } =
    useFocusMode();

  const processFocusMapAppend = useCallback(
    async (msg: NvoqMessage, section: string[]) => {
      const dictationChunks = createDictationChunksFromNvoqStableText(msg);
      const stepEntries = await createStepsFromStableText(dictationChunks, {
        fieldNames: [],
        enablePicklistDictation: false,
      });

      const groupedStepEntries = createGroupedStepEntries(stepEntries);

      groupedStepEntries.forEach((stepEntryList: StepEntry[]) => {
        if (editor?.selection == null) {
          return;
        }

        const firstStepEntry: StepEntry = stepEntryList[0];
        const [firstStep, firstDictationChunk]: [Step, ParsedDictationChunk] = firstStepEntry;

        const shouldAttemptExternalVoiceCommand =
          firstStep.type === 'EXTERNAL_VOICE_COMMAND' &&
          firstDictationChunk.type === 'SUBSTITUTION' &&
          firstDictationChunk.payload.target !== 'EDITOR';

        if (shouldAttemptExternalVoiceCommand) {
          onExternalSubstitution({
            type: 'RecorderVoiceCommand',
            selection: editor.selection,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            substitution: firstDictationChunk.payload,
          });

          if (editor?.selection == null) {
            return;
          }

          if (firstDictationChunk.payload.action === 'InsertReportTemplate') {
            removeDecorationForSelection(editor.selection);
          }
        } else {
          const at = getInsertionPoint(editor, section);
          applySteps({
            editor,
            steps: stepEntries,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            at: at ?? Editor.end(editor, []),
            /**
             * If the at range includes the current editor selection, augment the editor selection,
             * otherwise leave it as is because that means they have tabbed to a future bracket
             * and this one is a previous queue item.
             *
             * NOTE: This function is named funky in Slate. See:
             * https://github.com/ianstormtaylor/slate/issues/4283
             */
            select: true,
            source: slateSource.ai.focusMode,
          });
          mostRecentFocusModeOperationRef.current = FocusModeOperation.Append;
        }
      });

      // eslint-disable-next-line no-console
      console.log('\x1b[31m\x1b[47m%s\x1b[0m', '<<< Start >>>', '\n');
      // eslint-disable-next-line no-console
      console.log({ stepEntries, section });
      // eslint-disable-next-line no-console
      console.log('\x1b[0m%s\x1b[32m\x1b[47m%s\x1b[0m', '\n', '<<< Finish >>>', '\n');
    },
    [
      applySteps,
      createStepsFromStableText,
      editor,
      getInsertionPoint,
      onExternalSubstitution,
      removeDecorationForSelection,
      mostRecentFocusModeOperationRef,
    ]
  );

  const processFocusMapDelete = useCallback(
    async (text: string, section: string[]) => {
      deleteTextInSection(editor, text, section);
    },
    [deleteTextInSection, editor]
  );

  const processStableTextMarkers = useCallback(
    async (msg: StableTextResponse, selectionRef: RangeRefType) => {
      let mutatedMessage = autoCorrectStableTextMarkers(msg, autoCorrectDictionary);

      const variant = getCurrentActiveListVariant(editor);
      mutatedMessage = generateNextParagraphInListSironaSubstitutionForMarkers(
        mutatedMessage,
        variant
      );

      const dictationChunks = createDictationChunks(mutatedMessage);
      const queuedStepsForSelection = selectionRefToStepsQueueMap.current.get(selectionRef) ?? [];
      const queuedDictationChunks = queuedStepsForSelection.map((stepEntry) => stepEntry[1]);

      const fieldNames = getNavigationItemsFromEditor(editor)
        .filter((item) => isSquareBracketType(String(item.node.type)))
        .map((item) => item.name);

      const stepEntries = await createStepsFromStableText(
        [...queuedDictationChunks, ...dictationChunks],
        { fieldNames, enablePicklistDictation }
      );

      let groupedStepEntries = createGroupedStepEntries(stepEntries);

      // if the stable text is empty, and the queued steps don't form a voice command, we don't want to do anything
      if (msg.payload.text === '' && stepEntriesTextToString(stepEntries) === '') return;

      if (isMousePressed === true) {
        // if the mouse is pressed, defer everything
        selectionRefToStepsQueueMap.current.set(selectionRef, stepEntries);
        return;
      }

      const deferredSteps = getDeferredSteps(stepEntries, { fieldNames });

      if (deferredSteps.length > 0 && msg.payload.done === false) {
        selectionRefToStepsQueueMap.current.set(selectionRef, [...deferredSteps]);

        groupedStepEntries = createGroupedStepEntries(stepEntries.slice(0, -deferredSteps.length));
      }

      // Filter out entries where INSERT_TEXT and payload text is "", since these are noops
      groupedStepEntries = groupedStepEntries.filter(
        (stepEntryList) =>
          !(
            stepEntryList.length === 1 &&
            stepEntryList[0][0].type === 'INSERT_TEXT' &&
            stepEntryList[0][0].payload[0].text === ''
          )
      );

      // At this point, groupedStepEntries is ready to be processed.
      groupedStepEntries.forEach((stepEntryList: StepEntry[]) => {
        if (selectionRef.current == null) {
          return;
        }

        const firstStepEntry: StepEntry = stepEntryList[0];
        const [firstStep, firstDictationChunk]: [Step, ParsedDictationChunk] = firstStepEntry;
        const shouldAttemptEditorVoiceCommand =
          firstStep.type === 'VOICE_COMMAND' &&
          firstDictationChunk.type === 'SUBSTITUTION' &&
          firstDictationChunk.payload.target === 'EDITOR';

        const shouldAttemptExternalVoiceCommand =
          firstStep.type === 'EXTERNAL_VOICE_COMMAND' &&
          firstDictationChunk.type === 'SUBSTITUTION' &&
          firstDictationChunk.payload.target !== 'EDITOR';

        let didVoiceCommand = false;

        if (shouldAttemptEditorVoiceCommand && editor.selection != null) {
          // A voice command could cause a selection which a ref is following to change. We need to update the selectionRef, if so.
          // But a voice command can also change the editor's selection, which we don't necessarily want.
          // We can capture the selection before the voice command, and set it again after.

          const tempSelection = Editor.rangeRef(editor, editor.selection, { affinity: 'inward' });

          // TODO: modify maybeDoVoiceCommand to accept a selectionRef
          didVoiceCommand = maybeDoVoiceCommand(
            editor,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            firstDictationChunk,
            {
              insertSelectedPicklistItemByIndex,
              ignoreMergeFieldsInNavigation,
              setMostRecentInput,
              generateAndStitchImpression,
              onInsertTextRequiredField,
            }
          );

          if (
            didVoiceCommand === true &&
            editor.selection != null &&
            tempSelection.current != null
          ) {
            enqueueDictationStepToast(
              `[dictation] - Voice command triggered: '${
                firstDictationChunk.payload.action ?? 'n/a'
              }'`
            );
            // $FlowIgnore[incompatible-call] - selection is confirmed above
            selectionRef.current = Editor.range(editor, editor.selection);
            // $FlowIgnore[incompatible-call] - selection is confirmed above
            Transforms.select(editor, tempSelection.current);
          } else if (
            didVoiceCommand === false &&
            firstDictationChunk.payload.originalTextStepEntry != null
          ) {
            // if the voice command was not successful, we want to apply the text insertion from the originalTextStepEntry
            stepEntryList = [firstDictationChunk.payload.originalTextStepEntry];
          }
        } else if (shouldAttemptExternalVoiceCommand) {
          onExternalSubstitution({
            type: 'RecorderVoiceCommand',
            selection: selectionRef.current,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            substitution: firstDictationChunk.payload,
          });

          if (
            firstDictationChunk.payload.action === 'InsertReportTemplate' &&
            selectionRef.current != null
          ) {
            removeDecorationForSelection(selectionRef.current);
          }
        }

        const stepEntriesString = stepEntriesTextToString(stepEntryList);
        if (
          !PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus) &&
          didVoiceCommand === false &&
          selectionRef.current != null
        ) {
          applySteps({
            editor,
            steps: stepEntryList,
            at: selectionRef.current,
          });

          // applySteps may change the editor's selection, so we need to update the selectionRef
          mostRecentStableTextInserted.current = stepEntriesString;
        }
      });
    },
    [
      applySteps,
      autoCorrectDictionary,
      createStepsFromStableText,
      editor,
      enablePicklistDictation,
      enqueueDictationStepToast,
      generateAndStitchImpression,
      ignoreMergeFieldsInNavigation,
      insertSelectedPicklistItemByIndex,
      isMousePressed,
      onExternalSubstitution,
      onInsertTextRequiredField,
      removeDecorationForSelection,
      reportStatus,
      setMostRecentInput,
    ]
  );

  const handleStableText = useCallback(
    async (id: string, msg: StableTextResponse) => {
      const timeToSelectionMap = recorderToTimeSelectionMap.current[id];

      const textAtSelectionMap = new Map<RangeRefType, string>();

      if (msg.payload.markers != null && msg.payload.markers.length > 0) {
        for (const marker of msg.payload.markers) {
          const { audioStart, audioLength, text } = marker;
          const markerStart = 1000 * audioStart;
          const markerEnd = 1000 * (audioStart + audioLength);

          const selectionRefForMarker = getClosestValue(timeToSelectionMap, markerStart, markerEnd);

          if (selectionRefForMarker == null) {
            logger.error(
              '[useTextProcessing] No selection found for word marker. This should never happen.',
              { marker }
            );
            continue;
          }

          const currentText = textAtSelectionMap.get(selectionRefForMarker);

          let newText;
          if (currentText == null) {
            newText = text;
          } else {
            if (
              END_OF_SENTENCE_PUNCTUATION.includes(text) ||
              NO_SPACES_BEFORE.includes(text) ||
              NO_SPACES_AFTER.includes(currentText.slice(-1))
            ) {
              newText = currentText + text;
            } else {
              newText = currentText + ' ' + text;
            }
          }

          textAtSelectionMap.set(selectionRefForMarker, newText);
        }
      }

      // at this point, we have a mapping of selections to fragments; the fragments need to be inserted
      for (const entry of textAtSelectionMap.entries()) {
        const [selectionRef, text] = entry;
        if (text != null && selectionRef.current != null) {
          removeDecorationForSelection(selectionRef.current);

          await processStableTextMarkers(
            { ...msg, payload: { ...msg.payload, text } },
            selectionRef
          );

          textAtSelectionMap.delete(selectionRef);
        }
      }

      // if the stable text is done, we can remove the id from the timeToSelectionMaps; the transaction and recording are complete
      if (msg.payload.done === true) {
        recorderToTimeSelectionMap.current = Object.keys(recorderToTimeSelectionMap.current).reduce(
          (acc, key) => {
            if (key !== id) {
              // $FlowIgnore[prop-missing] we're certain it's a string
              acc[key] = recorderToTimeSelectionMap.current[key];
            }
            return acc;
          },
          {}
        );
      }
    },
    [processStableTextMarkers, removeDecorationForSelection, recorderToTimeSelectionMap]
  );

  const processStableText = useCallback(
    async (msg: NvoqMessage, selection: RangeType) => {
      if (onStableText != null && onStableText({ selection, stableText: msg.data.text })) {
        cleanMessageQueueForSelection(selection);
        return;
      }

      let mutatedMessage = autoCorrectNvoqStableText(msg, autoCorrectDictionary);

      const variant = getCurrentActiveListVariant(editor);
      mutatedMessage = generateNextParagraphInListSironaSubstitutionForNvoq(
        mutatedMessage,
        variant
      );

      const dictationChunks = createDictationChunksFromNvoqStableText(mutatedMessage);
      const queuedStepsForSelection = selectionToStepsQueueMap.current.get(selection) ?? [];
      const queuedDictationChunks = queuedStepsForSelection.map((stepEntry) => stepEntry[1]);

      const fieldNames = getNavigationItemsFromEditor(editor)
        .filter((item) => isSquareBracketType(String(item.node.type)))
        .map((item) => item.name);

      const stepEntries = await createStepsFromStableText(
        [...queuedDictationChunks, ...dictationChunks],
        { fieldNames, enablePicklistDictation }
      );

      let groupedStepEntries = createGroupedStepEntries(stepEntries);

      // if the stable text is empty, and the queued steps don't form a voice command, we don't want to do anything
      if (msg.data.text === '' && stepEntriesTextToString(stepEntries) === '') return;

      if (isMousePressed === true) {
        // if the mouse is pressed, defer everything
        selectionToStepsQueueMap.current.set(selection, stepEntries);
        return;
      }

      // if the mouse is not pressed, but mousePressedSinceLastStableTextRender is true,
      // the user may have changed their selection during dictation
      // so we can proceed with the stable text with a recalculated selection
      if (mousePressedSinceLastStableTextRender.current === true && editor.selection != null) {
        selectionToSelectionRef.current.set(
          selection,
          Editor.rangeRef(editor, getEditorDictationSelectionSafe(editor), { affinity: 'inward' })
        );

        // reset this so we don't recalculate the selection until the next time mouse is pressed
        mousePressedSinceLastStableTextRender.current = false;
      }

      setShouldSkipWebsocketCreationRef.current != null &&
        setShouldSkipWebsocketCreationRef.current(true);

      const deferredSteps = getDeferredSteps(stepEntries, { fieldNames });

      if (deferredSteps.length > 0 && msg.data.textDone === false) {
        selectionToStepsQueueMap.current.set(selection, [...deferredSteps]);

        groupedStepEntries = createGroupedStepEntries(stepEntries.slice(0, -deferredSteps.length));
      }

      // each partition will go through their own process of whether to do editor voice shortcut and/or applySteps.
      groupedStepEntries.forEach((stepEntryList: StepEntry[]) => {
        const mostRecentRangeRefToInsert = selectionToSelectionRef.current.get(selection);

        if (!mostRecentRangeRefToInsert || !mostRecentRangeRefToInsert.current) {
          const rangeRefInfoMessage =
            '[processStableText] Could not find a range ref given the selection. Should correct itself in next iteration.';
          createEditorLog(rangeRefInfoMessage);
          logger.info(rangeRefInfoMessage, {
            editor: editor != null ? partialEditor(editor) : 'null',
            queuedStepsForSelection: JSON.stringify(queuedStepsForSelection),
            createdSteps: JSON.stringify(stepEntryList),
          });
          cleanMessageQueueForSelection(selection);
          return;
        }

        const firstStepEntry: StepEntry = stepEntryList[0];
        const [firstStep, firstDictationChunk]: [Step, ParsedDictationChunk] = firstStepEntry;
        const shouldAttemptEditorVoiceCommand =
          firstStep.type === 'VOICE_COMMAND' &&
          firstDictationChunk.type === 'SUBSTITUTION' &&
          firstDictationChunk.payload.target === 'EDITOR';

        const shouldAttemptExternalVoiceCommand =
          firstStep.type === 'EXTERNAL_VOICE_COMMAND' &&
          firstDictationChunk.type === 'SUBSTITUTION' &&
          firstDictationChunk.payload.target !== 'EDITOR';

        let didVoiceCommand = false;

        if (shouldAttemptEditorVoiceCommand) {
          didVoiceCommand = maybeDoVoiceCommand(
            editor,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            firstDictationChunk,
            {
              insertSelectedPicklistItemByIndex,
              ignoreMergeFieldsInNavigation,
              setMostRecentInput,
              generateAndStitchImpression,
              onInsertTextRequiredField,
            }
          );

          if (didVoiceCommand === true) {
            enqueueDictationStepToast(
              `[dictation] - Voice command triggered: '${
                firstDictationChunk.payload.action ?? 'n/a'
              }'`
            );

            // if forcedUpdatedSelection is present,we want to refresh mostRecentRageRefToInsert,
            // e.g. if it was lost during the voice command attempt
            if (editor.selection != null) {
              selectionToSelectionRef.current.set(
                selection,
                Editor.rangeRef(editor, editor.selection, { affinity: 'inward' })
              );
            }
          } else if (
            didVoiceCommand === false &&
            firstDictationChunk.payload.originalTextStepEntry != null
          ) {
            // if the voice command was not successful, we want to apply the text insertion from the originalTextStepEntry
            stepEntryList = [firstDictationChunk.payload.originalTextStepEntry];
          }
        } else if (shouldAttemptExternalVoiceCommand) {
          onExternalSubstitution({
            type: 'RecorderVoiceCommand',
            selection,
            // $FlowIgnore[incompatible-call] - I'm checking it above to make sure the target is right
            substitution: firstDictationChunk.payload,
          });

          if (firstDictationChunk.payload.action === 'InsertReportTemplate') {
            removeDecorationForSelection(selection);
          }
        }

        cleanMessageQueueForSelection(selection);

        const stepEntriesString = stepEntriesTextToString(stepEntryList);
        if (!PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus) && didVoiceCommand === false) {
          const selectedRange = getRangeRefSafe(mostRecentRangeRefToInsert);
          const replacementText = stepEntriesString.trim();
          const isSelectedTextBeingReplaced =
            selectedRange != null && !Range.isCollapsed(selectedRange) && replacementText !== '';
          // tracks manual corrections of a few words
          if (isSelectedTextBeingReplaced) {
            const [node] = Node.fragment(editor, selectedRange);
            const selectedText = Node.string(node);
            if (wordCount(selectedText) <= MANUAL_CORRECTION_WORDS_LIMIT) {
              analytics.track(reporter.sys.textCorrectedByDictation, {
                selectedText,
                selectedTextWordCount: wordCount(selectedText),
                selectedTextCharacterCount: selectedText.length,
                replacementText,
                replacementTextWordCount: wordCount(replacementText),
                replacementTextCharacterCount: replacementText.length,
              });
            }
          }
          const editorDictationSelectionSafe = getEditorDictationSelectionSafe(editor);
          logger.info(
            `[processStableText] StableText: "${stepEntriesTextToString(stepEntryList)}". Applying ${
              stepEntryList.length
            } steps to editor.`,
            {
              logId: stringifyRange(selection),
              editor: partialEditor(editor),
              steps: stepEntryList,
              stepsText: stepEntriesTextToString(stepEntryList),
              at: selectedRange,
              select: Range.includes(selectedRange, getEditorDictationSelectionSafe(editor)),
            }
          );

          const stepEntriesWordCount = wordCount(stepEntriesString);
          if (stepEntriesWordCount > 0) {
            analytics.track(reporter.usr.wordsDictated, {
              ...analyticsData,
              dictatedWords: stepEntriesString,
              dictatedWordCount: stepEntriesWordCount,
              dictatedCharacterCount: stepEntriesString.length,
            });
          } else {
            logger.info(
              '[processStableText] onStableText returned no dictated words for selection',
              {
                logId: stringifyRange(selection),
                msg,
                editor: editor != null ? partialEditor(editor) : 'null',
              }
            );
          }

          applySteps({
            editor,
            steps: stepEntryList,
            at: selectedRange,
            /**
             * If the at range includes the current editor selection, augment the editor selection,
             * otherwise leave it as is because that means they have tabbed to a future bracket
             * and this one is a previous queue item.
             *
             * NOTE: This function is named funky in Slate. See:
             * https://github.com/ianstormtaylor/slate/issues/4283
             */
            select: Range.includes(selectedRange, editorDictationSelectionSafe),
          });

          if (editor.selection != null) {
            selectionToSelectionRef.current.set(
              selection,
              Editor.rangeRef(editor, editor.selection, { affinity: 'inward' })
            );
          }
        }

        mostRecentStableTextInserted.current = stepEntriesString;
      });
    },
    [
      onStableText,
      autoCorrectDictionary,
      editor,
      createStepsFromStableText,
      enablePicklistDictation,
      isMousePressed,
      cleanMessageQueueForSelection,
      reportStatus,
      insertSelectedPicklistItemByIndex,
      ignoreMergeFieldsInNavigation,
      setMostRecentInput,
      generateAndStitchImpression,
      onInsertTextRequiredField,
      enqueueDictationStepToast,
      onExternalSubstitution,
      removeDecorationForSelection,
      applySteps,
      analyticsData,
    ]
  );

  const renderHypothesisText = useCallback(
    (hypothesisText: string, hypothesisSelection: RangeType, selection: RangeType) => {
      if (selectionToStepsQueueMap.current.has(selection)) {
        decorateDispatch((decorations) => {
          const hypothesisTextMatches = decorations.some(
            (d) => d.selectionRef === selection && d.hypothesisText === hypothesisText
          );
          if (hypothesisTextMatches) return decorations;
          return decorations.map((d) => {
            if (d.selectionRef === selection) {
              return {
                ...d,
                // Overwrite the hypothesis text wholesale
                hypothesisText,
              };
            }

            return d;
          });
        });
        // Start the message queue and add a decoration if it doesn't exist for the selection
      } else {
        decorateDispatch((decorations) => [
          ...decorations,
          {
            anchor: hypothesisSelection.anchor,
            focus: hypothesisSelection.focus,
            selectionRef: selection,
            [DICTATION_PLUGIN_ID]: true,
            hypothesisText,
          },
        ]);

        // Setup the initial selection map entry because hypothesis text will return before
        // stable text.
        selectionToStepsQueueMap.current.set(selection, []);
      }
    },
    [decorateDispatch]
  );

  const skipWebsocketCreationOptionsRef = useRef<?WebsocketCreationOptions>();

  const processHypothesisText = useCallback(
    (msg: NvoqMessage, selection: RangeType) => {
      // if the mouse pressed, user could be highlighting text
      // don't let hypothesis text interfere with this
      if (isMousePressed === true) return;

      let hypothesisSelection = selection;

      // if were not creating a new websocket, provide the most up-to-date dictation selection
      if (
        skipWebsocketCreationOptionsRef.current != null &&
        editor.selection != null &&
        skipWebsocketCreationOptionsRef.current.shouldSkipWebsocketCreation === true
      ) {
        hypothesisSelection = editor.selection;
      }

      try {
        if (Range.isCollapsed(selection) && isCursorAfterHeadingColon(editor, selection)) {
          const nextInlineParagraphEntry = getOrInsertNextInlineParagraph(editor, selection);
          if (nextInlineParagraphEntry != null) {
            const inlineParagraphStart = Editor.start(editor, nextInlineParagraphEntry[1]);
            hypothesisSelection = {
              anchor: inlineParagraphStart,
              focus: inlineParagraphStart,
            };
          }
        }
      } catch (e) {
        const headingErrorMessage =
          '[processHypothesisText] Error occurred when rendering hypothesis text when dictating after colon in heading';
        logger.warn(headingErrorMessage, {
          selection: stringifyRange(selection),
          msg: msg.data.text,
          editor: partialEditor(editor),
        });
      }

      // when a voice command is triggered, trim any hypothesis text prior to that
      // as it should have resolved to stable text already
      let trimmedHypothesisText = msg.data.text;
      if (mostRecentStableTextInserted.current != null) {
        trimmedHypothesisText = trimHypothesisText(
          msg.data.text,
          mostRecentStableTextInserted.current
        );
      }

      renderHypothesisText(trimmedHypothesisText, hypothesisSelection, selection);
    },
    [isMousePressed, editor, renderHypothesisText]
  );

  const processHypothesisTextMarkers = useCallback(
    (msg: HypothesisTextResponse, selection: RangeType) => {
      // if the mouse pressed, user could be highlighting text
      // don't let hypothesis text interfere with this
      if (isMousePressed === true) return;

      let hypothesisSelection = selection;

      try {
        if (Range.isCollapsed(selection) && isCursorAfterHeadingColon(editor, selection)) {
          const nextInlineParagraphEntry = getOrInsertNextInlineParagraph(editor, selection);
          if (nextInlineParagraphEntry != null) {
            const inlineParagraphStart = Editor.start(editor, nextInlineParagraphEntry[1]);
            hypothesisSelection = {
              anchor: inlineParagraphStart,
              focus: inlineParagraphStart,
            };
          }
        }
      } catch (e) {
        const headingErrorMessage =
          '[processHypothesisText] Error occurred when rendering hypothesis text when dictating after colon in heading';
        logger.warn(headingErrorMessage, {
          selection: stringifyRange(selection),
          msg: msg.payload.text,
          editor: partialEditor(editor),
        });
      }

      // when a voice command is triggered, trim any hypothesis text prior to that
      // as it should have resolved to stable text already
      let trimmedHypothesisText = msg.payload.text;
      if (mostRecentStableTextInserted.current != null) {
        trimmedHypothesisText = trimHypothesisText(
          msg.payload.text,
          mostRecentStableTextInserted.current
        );
      }

      decorateDispatch((decorations) => [
        ...decorations,
        {
          anchor: hypothesisSelection.anchor,
          focus: hypothesisSelection.focus,
          selectionRef: selection,
          [DICTATION_PLUGIN_ID]: true,
          hypothesisText: trimmedHypothesisText,
        },
      ]);
    },
    [isMousePressed, decorateDispatch, editor]
  );
  return {
    processStableText,
    handleStableText,
    processHypothesisText,
    processHypothesisTextMarkers,
    renderHypothesisText,
    processFocusMapAppend,
    processFocusMapDelete,
    selectionToSelectionRef,
    selectionToStepsQueueMap,
    mostRecentStableTextInserted,
    isMousePressed,
    setShouldSkipWebsocketCreationRef,
    skipWebsocketCreationOptionsRef,
  };
};
