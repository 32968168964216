// @flow

import type { RangeType, PathType, EditorType } from '../core';
import { Editor, Range, Point } from '../core';

export function isSelectionContainedInNode(
  editor: EditorType,
  path: ?PathType,
  selection: ?RangeType
): boolean {
  if (path == null || selection == null) {
    return false;
  }

  const pathEdges = Editor.edges(editor, path);
  const selectionEdges = Editor.edges(editor, selection);

  return (
    Range.equals(Editor.range(editor, path), selection) ||
    ((Point.isAfter(selectionEdges[0], pathEdges[0]) ||
      Point.equals(selectionEdges[0], pathEdges[0])) &&
      (Point.isBefore(selectionEdges[1], pathEdges[1]) ||
        Point.equals(selectionEdges[1], pathEdges[1])))
  );
}
