// @flow
import { Editor, Node, Range, Text } from '../core';
import type { EditorType, PointType } from '../core';

/**
 * returns a string representation of the text surrounding the current selection in the editor
 */
export const getSurroundingTextString = (editor: EditorType): string => {
  const { beforeText, afterText, selectedText } = getSurroundingText(editor);

  return `${beforeText}|${selectedText.length > 0 ? `${selectedText}|` : ''}${afterText}`;
};

/**
 * returns an object with the text before, after, and selected text for the current selection in the editor
 */
const getSurroundingText = (editor: EditorType) => {
  const { selection } = editor;
  if (selection == null) {
    return { beforeText: '', afterText: '', selectedText: '' };
  }

  const [start, end] = Range.edges(selection);
  const isCollapsed = Range.isCollapsed(selection);

  const beforeText = getFlatText(editor, start, true);
  const afterText = getFlatText(editor, isCollapsed ? start : end, false);
  const selectedText = isCollapsed ? '' : Editor.string(editor, { anchor: start, focus: end });

  return { beforeText, afterText, selectedText };
};
/**
 * returns a string of text before or after a point in the editor
 */
const getFlatText = (editor: EditorType, point: PointType, before: boolean) => {
  const CHAR_COUNT = 10;

  let { path, offset } = point;
  let text = '';
  while (path.length > 0 && text.length < CHAR_COUNT) {
    const node = Node.get(editor, path);
    if (Text.isText(node)) {
      if (before) {
        text = Node.string(node).slice(Math.max(0, offset - CHAR_COUNT), offset) + text;
      } else {
        text += Node.string(node).slice(offset, offset + CHAR_COUNT - text.length);
      }
    }
    if (text.length >= CHAR_COUNT) break;
    const nextPoint = before ? Editor.before(editor, path) : Editor.after(editor, path);
    if (!nextPoint) break;
    ({ path, offset } = nextPoint);
  }
  return before ? text.slice(-CHAR_COUNT) : text.slice(0, CHAR_COUNT);
};
