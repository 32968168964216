// @flow

import { useMemo, Fragment } from 'react';
import { Colors } from 'styles';
import { usePlugins, useReporterState } from '../hooks';
import { Portal } from 'common/ui/Portal';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import Tippy from '@tippyjs/react/headless';
import { maxSize, applyMaxSize } from 'utils/popperModifiers';
import { DropdownIndicator, ToolbarButton } from 'common/ui/Toolbar';
import { Stack } from 'common/ui/Layout';
import Tooltip from 'common/ui/Tooltip';
import type { ToolbarConfigPluginID } from '../plugins/types';

const maxSizeExtended = {
  ...maxSize,
  options: {
    padding: { bottom: 15 },
  },
};

const applyMaxSizeExtended = {
  ...applyMaxSize,
  options: { tippy: true },
};

export const PortalToolbarButton = ({
  pluginID,
  height,
}: {
  pluginID: ToolbarConfigPluginID,
  height?: string,
}): React$Node => {
  const { navMountPoint } = useReporterState();
  const { getToolbarConfigs } = usePlugins();

  const activatedToolbarConfig = useMemo(() => {
    const activatedToolbarConfigs = getToolbarConfigs().map((config) => config());

    if (activatedToolbarConfigs == null) return null;

    return activatedToolbarConfigs.find((config) => config.pluginID === pluginID);
  }, [getToolbarConfigs, pluginID]);

  if (activatedToolbarConfig == null) return null;

  const { toolbarButton, component: Component, options } = activatedToolbarConfig;

  // TODO: @kcwijaya - figure out why merge fields button overflows bounds on hover
  return (
    <>
      <Portal mountPoint={navMountPoint} key={pluginID}>
        <Tooltip content={options?.tooltipText} backgroundColor={Colors.gray7} color={Colors.gray1}>
          <Tippy
            zIndex={10000}
            trigger="mousedown"
            placement="bottom"
            offset={[0, 5]}
            popperOptions={{
              modifiers: [maxSizeExtended, applyMaxSizeExtended],
            }}
            onTrigger={(instance, event) => {
              event.preventDefault();
            }}
            appendTo={() => document.body}
            interactive
            onMount={({ popperInstance }) => {
              // TODO: fix behavior in core libs.
              // 1. The `maxSize` modifier is run before the Tippy is mounted,
              // therefore it needs to be updated after mount.
              // 2. The `maxSize` modifier then causes scrollbars, which offsets
              // the Tippy slightly incorrectly, requiring another update.
              popperInstance.update().then(popperInstance.update);
            }}
            render={(attrs, _, instance) => <Component attrs={attrs} instance={instance} />}
          >
            <ToolbarButton aria-label={options?.ariaLabel} type="button" height={height}>
              <Stack alignX="center">
                {toolbarButton}
                <DropdownIndicator />
              </Stack>
            </ToolbarButton>
          </Tippy>
        </Tooltip>
      </Portal>
    </>
  );
};
