// @flow

/*
 * Creates a promise, which will resolve either when the returned trigger function is called,
 * or after a timeout, whichever comes first.
 */
export const createLockingFunction = (
  timeout: number
): ({ promise: Promise<void>, trigger: () => void }) => {
  let resolvePromise: ?() => void;
  let timer: ?TimeoutID;

  // Create a promise with an externally accessible resolver
  const promise = new Promise((resolve) => {
    resolvePromise = resolve;
  });

  // Function to clean up and resolve the promise
  const resolveAndCleanup = () => {
    clearTimeout(timer);
    timer = null;
    resolvePromise && resolvePromise();
    resolvePromise = null;
  };

  // Function that can be called to resolve the promise
  const trigger = () => {
    resolveAndCleanup();
  };

  // Set a timeout to resolve the promise if trigger isn't called
  timer = setTimeout(() => {
    resolveAndCleanup();
  }, timeout);

  return { promise, trigger };
};
