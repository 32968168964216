// @flow

import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { Contexts } from 'react-vtk-js';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import type { WidgetConfiguration, ToolInteractionWidgetResponse } from 'generated/graphql';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';
import { css } from 'styled-components';
import { Colors, Spacings } from 'styles';
import { showToastMessage } from '../feedback';

const WidgetPlacementValues = {
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end',
  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',
};

function elementSupportsAttribute(element: string, attribute: string): boolean {
  return attribute in document.createElement(element);
}

const isIframeSandboxSupported = elementSupportsAttribute('iframe', 'sandbox');

function generateGetBoundingClientRect(x: number = 0, y: number = 0) {
  return {
    // $FlowIgnore[incompatible-return] Following Popper documentation
    getBoundingClientRect: (): DOMRect => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    }),
  };
}

function Widget({
  title,
  urlPath = '',
  placement,
  displayPosition,
}: {
  ...WidgetConfiguration,
  displayPosition: vec3,
}) {
  const view = useContext(Contexts.ViewContext);
  // displayCoordinates is currently (left, bottom) relative to the canvas, so
  // adjust to be relative to renderer.
  const size = view?.getOpenGLRenderWindow()?.get().getSize() ?? [0, 0];
  const [xmin, ymin] = view?.getRenderer()?.get().getViewport() ?? [0, 0];
  let [left, bottom] = displayPosition;
  left -= size[0] * xmin;
  bottom -= size[1] * ymin;
  const top = size[1] - bottom;

  const widgetRef = useRef<?HTMLDivElement>(null);
  const iframeRef = useRef<?HTMLIFrameElement>(null);

  const reference = useMemo(
    () =>
      generateGetBoundingClientRect(left / window.devicePixelRatio, top / window.devicePixelRatio),
    [left, top]
  );

  // FIXME: coordinates are not correctly handled by Popper and the widget is not displayed
  // at the right position, the input left/top coordinates look correct.
  const { styles, attributes } = usePopper(reference, widgetRef.current, {
    placement: placement ? WidgetPlacementValues[placement] : 'auto',
  });

  // TODO: remove this once the widget backend is ready
  const content = iframeRef.current?.contentDocument?.body
    ? createPortal(
        <>
          <link href="/index.css" rel="stylesheet" />
          <div id="root">{title}</div>
        </>,
        iframeRef.current.contentDocument.body
      )
    : null;

  useEffect(() => {
    if (!isIframeSandboxSupported) {
      showToastMessage(
        "Your browser does not support iframes with sandbox attribute, please use a secure browser, we suggest Google Chrome or Microsoft Edge. Marketplace widgets won't be available to preserve your security.",
        'ERROR'
      );
    }
  }, []);

  if (!isIframeSandboxSupported) {
    return null;
  }

  return (
    <>
      {content}
      <div
        {...attributes.popper}
        ref={widgetRef}
        css={css`
          top: 0;
          left: 0;
          border-radius: 6px;
          background-color: ${Colors.dialogBackground};
          position: absolute;
          z-index: 2;
          display: flex;
          overflow: hidden;
          padding: ${Spacings.xsmall}em;
        `}
        style={styles.popper}
      >
        <iframe
          ref={iframeRef}
          title={title}
          css={css`
            border: 0;
            flex: 1;
          `}
          // sandbox="allow-scripts"
          // src={`https://${id}.ext-sironamedical.com/${urlPath}`}
          // TODO: replace this ↓ with the lines above once the widget backend is ready
          src="about:blank"
        />
      </div>
    </>
  );
}

const widgetState = atom<Array<{ ...WidgetConfiguration, displayPosition: vec3 }>>({
  key: 'viewer.dre.sdk.widgets',
  default: [],
  effects: [broadcastChannelSynchronizerEffect()],
});

type UseWidgetResponseArgs = {
  worldToDisplay: (vec3) => ?vec3,
  indexToWorld: (vec3) => ?vec3,
};

export function useWidgetResponse({
  worldToDisplay,
  indexToWorld,
}: UseWidgetResponseArgs): (response: $ReadOnly<ToolInteractionWidgetResponse>) => mixed {
  const setWidgets = useSetRecoilState(widgetState);

  const handleWidgetResponse = useCallback(
    (response: $ReadOnly<ToolInteractionWidgetResponse>) => {
      const widget = response.widget;

      if (widget == null) return null;

      const worldPosition: vec3 = (widget.position?.indexSpace != null
        ? indexToWorld(widget.position.indexSpace)
        : widget.position?.worldSpace) ?? [0, 0, 0];

      const displayPosition = worldToDisplay(worldPosition) ?? [0, 0, 0];

      setWidgets((widgets) => [
        ...widgets.filter((w) => w.id !== widget.id),
        {
          ...widget,
          displayPosition,
        },
      ]);
    },
    [indexToWorld, setWidgets, worldToDisplay]
  );

  return handleWidgetResponse;
}

export function WidgetsRenderer(): React$Node {
  const widgets = useRecoilValue(widgetState);

  return widgets.map((widgetConfiguration) => (
    <Widget key={widgetConfiguration.id} {...widgetConfiguration} />
  ));
}
