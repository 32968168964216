// @flow

import { enhanceProviderExpandSelection } from './enhanceProviderExpandSelection';
import type { EditorPlugin } from '../../types';
import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types/plugin';

export const ExpandSelection_PLUGIN_ID: 'expandSelection' = 'expandSelection';
export type ExpandSelectionPluginID = typeof ExpandSelection_PLUGIN_ID;

export type ExpandSelectionPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  forceExpandPicklist?: boolean,
}>;

export type ExpandSelectionPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...ExpandSelectionPluginOptions,
}>;

export const ExpandSelectionPlugin = (
  { forceExpandPicklist = false, ...rest }: ExpandSelectionPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: ExpandSelectionPluginPropertyOptions = {
    pluginID: 'expandSelection',
    pluginName: 'ExpandSelection',
    forceExpandPicklist,
  };

  return {
    pluginID: options.pluginID,
    enhanceProvider: enhanceProviderExpandSelection(options),
  };
};
