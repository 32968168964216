// @flow

import { TOOL_CURSORS } from 'config/constants';
import { toCssCursor } from '../../modules/cursor';

/**
 * Common cursors used on the annotation elements
 */

export const CURSOR_HANDLE: string = toCssCursor(TOOL_CURSORS.HANDLE);
export const CURSOR_NODE: string = toCssCursor(TOOL_CURSORS.NODE);
