// @flow
import { Icon } from 'common/ui/Icon';
import type { PickerEvent } from '../modules/usePicker';
import { useHTMLActionFromPickerClick } from '../modules/useHTMLActionFromPickerClick';

type EditableProps = {
  id: string,
  onClick?: (event: PickerEvent) => void,
};

export const Editable = ({ id, onClick }: EditableProps): React$Node => {
  const ref = useHTMLActionFromPickerClick({ id, onClick });

  return (
    <span ref={ref} onClick={onClick}>
      <Icon name="edit_pencil" size="small"></Icon>
    </span>
  );
};
