// @flow

import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';

export const Ellipsis: StyledComponent<{ ... }, { ... }, HTMLDivElement> = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2px 4px;
`;
