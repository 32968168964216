// @flow
import type { ListPluginPropertyOptions } from './types';
import { getRenderToolbar } from '../../utils/getRenderToolbar';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { ListToolbarStacked } from './components';
import type { CreateToolbarConfig } from '../../types/plugin';

export const toolbarConfigList: CreateToolbarConfig<ListPluginPropertyOptions> = (options) => {
  return () => ({
    pluginID: options.pluginID,
    toolbarButton: <FormatListBulletedIcon />,
    component: getRenderToolbar({ ...options, component: ListToolbarStacked }),
    options: {
      ariaLabel: 'List Toolbar Button',
      tooltipText: 'Lists',
    },
  });
};
