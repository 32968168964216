// @flow

import { atom } from 'recoil';
import type { RecoilState } from 'recoil';

type AIModeReportState = 'mapping' | 'generating' | 'editing';

export const findingsMapperState: RecoilState<{
  report_state: AIModeReportState,
  resolve: () => void | Promise<void>,
}> = atom({
  key: 'findingsMapperState',
  default: {
    report_state: 'mapping',
    resolve: () => {},
  },
});
