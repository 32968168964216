// @flow

import type { CreateOnKeyDown } from '../../types';
import type { PlaceholderPluginPropertyOptions } from './types';
import { onKeyDownInlineTemplate } from '../common/inlineTemplate';

export const onKeyDownPlaceholder: CreateOnKeyDown<PlaceholderPluginPropertyOptions> =
  ({ pluginID }) =>
  (e, { editor, nodeState }) => {
    return onKeyDownInlineTemplate({ pluginID })(e, { editor, nodeState });
  };
