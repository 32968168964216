// @flow

import * as React from 'react';
import type { ComponentType } from 'react';

export type GetRenderToolbarOptions = {};

// TODO(mwood23) Types for Tippy
// $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
export type RenderTippyProps = (tippyProps: any) => React$Node;

export const getRenderToolbar = <T, PluginOptions: { component: ComponentType<T>, ... }>({
  component: Component,
  ...pluginOptions
}: PluginOptions): RenderTippyProps =>
  function GetRenderToolbar(tippyProps) {
    return <Component {...tippyProps} {...pluginOptions} />;
  };
