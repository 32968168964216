// @flow

import type { EditorPlugin } from '../../types';
import { SectionHeader } from './components';
import { getRenderElement } from '../../utils';
import { SECTION_HEADER_PLUGIN_ID } from './types';
import type { SectionHeaderPluginOptions, SectionHeaderPluginPropertyOptions } from './types';
import { enhanceEditorStateSectionHeader } from './enhanceEditorStateSectionHeader';
import { enhanceProviderSectionHeader } from './enhanceProviderSectionHeader';
import { onKeyDownSectionHeader } from './onKeyDownSectionHeader';

/**
 * A section header plugin, specific to anatomic navigator.
 *
 * Handles broadcast events from the viewer and inserts, or focuses on appropriate content within the report.
 * @param {SectionHeaderPluginOptions} options - Plugin options
 */
export const SectionHeaderPlugin = (
  {
    component = SectionHeader,
    pluginName = 'Section Header',
    ...rest
  }: SectionHeaderPluginOptions = { ...null }
): EditorPlugin<> => {
  const options: SectionHeaderPluginPropertyOptions = {
    pluginID: SECTION_HEADER_PLUGIN_ID,
    pluginName,
    component,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    renderElement: getRenderElement(options),
    onKeyDown: onKeyDownSectionHeader(options),
    enhanceProvider: enhanceProviderSectionHeader(options),
    enhanceEditorState: enhanceEditorStateSectionHeader(options),
  };
};
