// @flow

import {
  useRef,
  useContext,
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import mitt from 'mitt';
import type { Emitter, EventHandlerList, WildCardEventHandlerList } from 'mitt';
import type { PCMEvent, RawPCMMonoAudioRecorder } from './RawPCMMonoAudioRecorderWorklet';
import {
  NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS,
  RawPCMMonoAudioRecorder as RawPCMMonoAudioRecorderWorklet,
} from './RawPCMMonoAudioRecorderWorklet';
import { DownsampleRawPCMMonoAudioRecorder as DownsampleRawPCMMonoAudioRecorderWorklet } from './DownsampleRawPCMMonoAudioRecorderWorklet';

import { useToasterDispatch } from 'common/ui/Toaster';
import Text from 'common/ui/Text';
import { useWorklistItemAnalytics } from 'hooks/useWorklistItemAnalytics';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { marks, measures } from 'domains/reporter/RichTextEditor/analytics/performance';
import { reporter } from 'modules/analytics/constants';
import analytics from 'modules/analytics';
import { performance } from 'utils/performance';
import { audioContextLazy } from './AudioContextLazy';
import {
  experimentConfigurationState,
  asrPlexConfiguration,
  DEFAULT_EXPERIMENTAL_CONFIGURATION as defaultConfiguration,
} from 'domains/reporter/Reporter/ExperimentControlTab';
import { useMicrophone } from 'domains/reporter/useMicrophone';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';
import type { BlobEvent } from 'types';
import { useReporterFeedbackToast } from 'hooks/useReporterFeedbackToast';
import {
  useSlateSingletonContext,
  useSlateSelectionSingletonContext,
} from 'domains/reporter/Reporter/SlateSingletonContext';
import { Range } from 'slate';
import { SlateMediaRecorder } from './SlateMediaRecorder';
import type { RangeType } from 'slate';
import Deferred from 'utils/deferred';
import { recorderState } from './state';
import { reportStatusState, PROVISIONAL_SUBMIT_STATUSES } from 'domains/reporter/Reporter/state';
import type { ReportStatuses } from 'domains/reporter/Reporter/state';
import { getSelectionForDictation } from 'domains/reporter/RichTextEditor/plugins/expandSelection/useExpandSelection';
import { usePrevious, useUnmount } from 'react-use';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import { partialEditor } from 'domains/reporter/RichTextEditor/utils';
import { useOnUnload } from 'hooks/useOnUnload';
import { useShouldSkipWebsocketCreation } from './useShouldSkipWebsocketCreation';
import { useSplitFlag } from 'modules/feature-flags/useSplitFlag';
import { createLockingFunction } from './utils';
import { useMostRecentInput, RICH_TEXT_EDITOR } from 'hooks/useMostRecentInput';
import { logger } from 'modules/logger';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { useFocusMode } from 'hooks/useFocusMode';
import { useRetryASRPlexVADWebSocket } from 'domains/reporter/RichTextEditor/plugins/dictation/ASRPlex/ASRPlexDictation';
import type { DownsampleRawPCMMonoAudioRecorder } from './DownsampleRawPCMMonoAudioRecorderWorklet';

// https://github.com/twilio/twilio-video.js/issues/149#issuecomment-322545751
const isGetUserMediaSupported = () => {
  return !!navigator.mediaDevices?.getUserMedia;
};

export type RecorderEvent = 'data_available' | 'stop_recording' | 'silence_detected';

export type RecorderDataAvailableEvent = {
  data: ?Blob | null,
  id: string,
  selection: RangeType | null,
  duration: number,
  setShouldSkipWebsocketCreation: (boolean) => void,
  shouldSkipWebsocketCreation: boolean,
};

export type PCMRecorderDataAvailableEvent = {
  ...PCMEvent,
  setShouldSkipWebsocketCreation: (boolean) => void,
  shouldSkipWebsocketCreation: boolean,
};

export const recorderEmitter: Emitter<RecorderEvent, EventHandlerList | WildCardEventHandlerList> =
  mitt<RecorderEvent>();

// nVoq's recommended period of silence before sending a boundary request
export const REQUEST_BOUNDARY_DELAY = 600;

export type RecorderTarget =
  | 'reporter'
  | 'debugTicket'
  | 'editMacroPreviewTab'
  | 'editMacroEditTab'
  | 'newMacro'
  | 'newFieldText'
  | 'newFieldPicklist';

type RecorderContextProps = $ReadOnly<{
  children: React$Node,
}>;

export type RecorderContextBag = $ReadOnly<{
  isRecording: boolean,
  showRecording: boolean,
  getRecorder: () =>
    | null
    | RawPCMMonoAudioRecorder
    | DownsampleRawPCMMonoAudioRecorder
    | SlateMediaRecorder,
  getAudioContext: () => AudioContext | null,
  getStream: () => null | MediaStream,
  startRecording: () => Promise<void>,
  stopRecording: () => Promise<void>,
  toggleRecording: () => void,
  setRecorderTarget: (((RecorderTarget) => RecorderTarget) | RecorderTarget) => void,
  recorderTarget: RecorderTarget,
  userActivityElementRef: { current: ?HTMLElement },
  setTextEditorElement: () => void,
}>;

export const RecorderContext: React$Context<?RecorderContextBag> =
  createContext<?RecorderContextBag>(undefined);

export const RecorderProvider = ({ children }: RecorderContextProps): React$Node => {
  const { enqueueToast } = useToasterDispatch();
  const startRecordingLock = useRef(null);
  const stopRecordingLock = useRef(null);
  const analyticsData = useWorklistItemAnalytics();
  const { audioInputDeviceId } = useMicrophone();
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorderInitialized, setIsRecorderInitialized] = useState(false);
  // Using a transaction instead of useRecoilValue because there seems to be a setPendingSelf
  // conflict that cancels out the atom value set, and not seeing the same behavior with useRecoilTransaction.
  const setIsDisplayingRecorderBorder = useRecoilCallback(
    ({ set }) =>
      (value) => {
        set(recorderState, value);
      },
    []
  );
  const showRecording = useRecoilValue(recorderState);
  const [{ editor }] = useSlateSingletonContext();
  const [{ selection }] = useSlateSelectionSingletonContext();
  const cleanStreamRef = useRef(null);
  const [recorderTarget, setRecorderTarget] = useState<RecorderTarget>('reporter');
  const audioContextRef = useRef(null);
  const streamRef = useRef(null);
  const sourceRef = useRef(null);
  const recorderRef = useRef(null);
  const vadRecorderRef = useRef(null);
  const analyserRef = useRef(null);
  const destinationRef = useRef(null);
  const resolveRecordingDelayRef = useRef(null);
  const [asrPlexFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_ASR_PLEX);
  const [accurateTextPlacementEnabled] = useFeatureFlagEnabled(FF.ACCURATE_TEXT_PLACEMENT);
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);
  const [recordingDelay] = useSplitFlag(FF.REPORTER_RECORDING_STOP_DELAY, '0', parseInt);

  const vadWebsocket = useRetryASRPlexVADWebSocket();
  const silenceDetectedRef = useRef(false);
  const silenceTimerRef = useRef(null);

  const { isMousePressed: mousePressedOnTextEditor, setElement: setTextEditorElement } =
    useMostRecentInput(RICH_TEXT_EDITOR);
  const currentCaseId = useCurrentCaseId();
  const previousCaseId = usePrevious(currentCaseId);

  const [showRecorderNotReadyNotification, setShowRecorderNotReadyNotification] =
    useState<boolean>(false);

  useReporterFeedbackToast(showRecorderNotReadyNotification, {
    enableNotification: true,
    message: 'Audio could not be initialized, please refresh the page.',
    icon: 'redWarning',
  });
  const experimentConfiguration = useRecoilValue(experimentConfigurationState);
  const [hasExperimentalControl, isExperimentalControlFlagLoading] = useFeatureFlagEnabled(
    FF.REPORTER_EXPERIMENTAL_OPTIONS
  );
  const previousSamplingRate = usePrevious(experimentConfiguration.audioSamplingRate);
  const [aiModeFeatureEnabled] = useFeatureFlagEnabled(FF.REPORTER_AI_MODE);

  const selectionRef = useRef();

  const { userActivityElementRef, shouldSkipWebsocketCreationRef, setShouldSkipWebsocketCreation } =
    useShouldSkipWebsocketCreation();

  const { isFocusModeEnabled } = useFocusMode();

  // Experimental control (internal only) enables the user to change the audio sampling rate.
  // When that happens, the audio context needs to be closed and a new one opened.
  // This hook initiates that process, and assigns the new audio context to the audioContextRef.
  useEffect(() => {
    const handleACReset = async () => {
      if (!hasExperimentalControl || isExperimentalControlFlagLoading) return;
      if (audioContextRef.current?.sampleRate === experimentConfiguration.audioSamplingRate) return;

      audioContextRef.current = null;

      audioContextLazy.updateConfiguration({
        audioSamplingRate: experimentConfiguration.audioSamplingRate,
      });
      const resetAudioContext = async () => await audioContextLazy.reset();
      resetAudioContext();

      const newAudioContext = await audioContextLazy.get();
      audioContextRef.current = newAudioContext;
    };
    handleACReset();
  }, [
    experimentConfiguration,
    hasExperimentalControl,
    isExperimentalControlFlagLoading,
    previousSamplingRate,
  ]);

  useEffect(() => {
    if (vadWebsocket == null) return;

    // If we detect 600ms of silence, set silence detected to true
    // We only want to do this once per window of silence
    // vad is short for voice activity detection, so:
    // vad = True: voice detected (silence NOT detected)
    // vad = False: silence detected (voice NOT detected)
    const onMessage = (event: MessageEvent) => {
      const message = event.data;
      if (message === 'False' && !silenceDetectedRef.current) {
        silenceTimerRef.current = setTimeout(() => {
          const recorder = recorderRef.current;
          if (!silenceDetectedRef.current && recorder != null) {
            recorderEmitter.emit('silence_detected', {
              id: recorder.id,
              selection: selectionRef.current,
              duration: recorder.getDurationMillis(),
            });
          }
          silenceDetectedRef.current = true;
          silenceTimerRef.current = null;
        }, REQUEST_BOUNDARY_DELAY);
      } else if (message === 'True' && silenceDetectedRef.current) {
        clearTimeout(silenceTimerRef.current);
        silenceDetectedRef.current = false;
        silenceTimerRef.current = null;
      }
    };

    vadWebsocket.addEventListener('message', onMessage);
    return () => {
      vadWebsocket.removeEventListener('message', onMessage);
      if (silenceTimerRef.current) {
        clearTimeout(silenceTimerRef.current);
        silenceTimerRef.current = null;
      }
    };
  }, [vadWebsocket]);

  // After feature flags have loaded and audio context has initialized, we can start the media stream
  // The media stream will only be started once, and will be reused for all recordings
  useEffect(() => {
    if (isRecorderInitialized) return;

    const createMediaStream = async () => {
      let ac;

      try {
        ac = await audioContextLazy.get();
        if (audioContextRef.current == null) {
          audioContextRef.current = ac;
        }

        if (recorderRef.current) {
          // TODO: handle if recorder is already active
          logger.error('[createMediaStream] Cannot initialize; recorder is already initialized', {
            recorder: JSON.stringify(recorderRef.current),
          });
          return;
        }

        const stream = streamRef.current;
        if (stream) {
          logger.info('[createMediaStream] Stream already exists, stopping and removing it', {
            stream: JSON.stringify(stream),
          });
          const tracks: MediaStreamTrack[] = stream.getTracks() ?? [];
          tracks.forEach((track) => track.stop());
          streamRef.current = null;
        }

        // By default the echoCancellation, autoGainControl, and noiseSuppression are true (i.e. enabled).
        // Note that if you set echoCancellation to false but provide no values for the others, then they will be set to false (i.e. disabled).
        const newStream = await navigator.mediaDevices
          ?.getUserMedia({
            audio: {
              deviceId: audioInputDeviceId,
              echoCancellation: hasExperimentalControl
                ? experimentConfiguration.echoCancellation
                : true,
              autoGainControl: hasExperimentalControl
                ? experimentConfiguration.autoGainControl
                : true,
              noiseSuppression: hasExperimentalControl
                ? experimentConfiguration.noiseSuppression
                : true,
            },
          })
          .catch(() => {
            enqueueToast(
              'Please enable audio recording to enable dictation and other features within the Sirona Workspace.',
              { severity: 'error' }
            );
          });

        if (newStream == null) {
          logger.warn('[createMediaStream]: No audio stream available', {
            audioContext: JSON.stringify(ac),
            newStream: JSON.stringify(newStream),
          });
          return;
        }

        streamRef.current = newStream;
        setShowRecorderNotReadyNotification(false);
      } catch (error) {
        logger.error('[createMediaStream]: failed to create media stream', error);
        setShowRecorderNotReadyNotification(true);
      }
    };

    const init = async () => {
      await audioContextLazy.resolve;
      await createMediaStream();
      setIsRecorderInitialized(true);
    };
    init();
  }, [
    audioInputDeviceId,
    enqueueToast,
    experimentConfiguration.autoGainControl,
    experimentConfiguration.echoCancellation,
    experimentConfiguration.noiseSuppression,
    hasExperimentalControl,
    isRecorderInitialized,
  ]);

  const stopRecording = useCallback(
    async ({ force = false } = {}) => {
      if (startRecordingLock.current != null) {
        await startRecordingLock.current.promise;
      } else {
        console.error(
          '[useRecorder] startRecordingLock expected to exist in stopRecording callback'
        );
        return;
      }
      stopRecordingLock.current = new Deferred();

      setIsDisplayingRecorderBorder(false);

      if (!force && recordingDelay > 0) {
        // A user's hand action to toggle recording may not be perfectly timed with their speech.
        // By adding a 250ms delay, we will catch any trailing audio that would otherwise be cut off.
        const { promise: recordingDelayPromise, trigger: resolveRecordingDelay } =
          createLockingFunction(recordingDelay);
        resolveRecordingDelayRef.current = resolveRecordingDelay;
        await recordingDelayPromise;
        resolveRecordingDelayRef.current = null;
      }

      const recorder = recorderRef.current;
      const vadRecorder = vadRecorderRef.current;

      const onStop = () => {
        performance.mark(marks.sirona.Dictation.RecordingStopped);
        analytics.track(reporter.usr.recordingStopped, {
          selection: selection != null ? stringifyRange(selection) : 'null',
        });
        performance.measure(
          measures.sirona.Dictation.DictationDuration.name,
          measures.sirona.Dictation.DictationDuration.startMark,
          measures.sirona.Dictation.DictationDuration.endMark
        );
        performance.clearMarksByName([
          marks.sirona.Dictation.RecordingStarted,
          marks.sirona.Dictation.RecordingStopped,
        ]);

        if (accurateTextPlacementEnabled && recorder != null) {
          recorderEmitter.emit('stop_recording', {
            duration: recorder.getDurationMillis(),
          });
        }
        // NOTE: This ensures is recording is not set to false until the recorder has done its cleanup
        // otherwise audio frames could be dropped resulting in transcription inaccuracies
        setIsRecording(false);
      };
      if (vadRecorder != null) {
        vadRecorder.stopRecording();
      }
      if (recorder != null) {
        recorder.stopRecording(onStop);
      } else {
        setIsRecording(false);
      }

      // Disconnect all the audio nodes in the audio graph
      sourceRef.current?.disconnect();
      sourceRef.current = null;
      // recorderRef.current.disconnect(); // this is handled by the RecorderWorklet
      recorderRef.current = null;
      vadRecorderRef.current = null;
      analyserRef.current?.disconnect();
      analyserRef.current = null;
      destinationRef.current?.disconnect();
      destinationRef.current = null;
      cleanStreamRef.current = null;

      if (stopRecordingLock.current?.resolve != null) {
        stopRecordingLock.current.resolve();
      }
    },
    [accurateTextPlacementEnabled, recordingDelay, selection, setIsDisplayingRecorderBorder]
  );

  // Effect to create new recorder when selection changes
  useEffect(() => {
    if (!isRecording) return;
    if (mousePressedOnTextEditor) return;
    if (previousCaseId !== currentCaseId) {
      stopRecording();
      return;
    }

    // If ACCURATE_TEXT_PLACEMENT is enabled, we do not want to stop and restart with a new recorder
    if (accurateTextPlacementEnabled && recorderRef.current != null) {
      return;
    }

    // The dictation selection is used to account for potential differences between the user's real selection in the editor
    // e.g. with a collapsed dictation in the middle of a word - we intend to have the dictation selection at end of word instead
    let dictationSelection = selection;
    if (editor != null) {
      dictationSelection = getSelectionForDictation(editor) ?? selection;
    }

    selectionRef.current = selection;

    // The selection should never be null, this would be a failure mode where the user is
    // dictating and we don't know where to place the dictation in the document.
    if (
      !aiModeFeatureEnabled &&
      !isFocusModeEnabled &&
      dictationSelection == null &&
      !PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus)
    ) {
      const errorMessage = '[useRecorder] Recording without a selection, skipping';
      logger.error(errorMessage, {
        reportStatus,
        editor: editor != null ? partialEditor(editor) : 'null',
      });
      return;
    }

    const cleanStream = cleanStreamRef.current;
    if (cleanStream == null) return;

    const currentRecorder = recorderRef.current;
    if (currentRecorder != null && isFocusModeEnabled) return;
    if (
      !aiModeFeatureEnabled &&
      currentRecorder != null &&
      currentRecorder.selection != null &&
      dictationSelection != null &&
      Range.equals(currentRecorder.selection, dictationSelection)
    ) {
      // selections have not changed, so do not stop and start a new recorder at the same selection
      return;
    }

    if (shouldSkipWebsocketCreationRef.current === true) return;

    if (currentRecorder != null) {
      currentRecorder.stopRecording(() => {
        recorderEmitter.emit('stop_recording', {
          id: currentRecorder.id,
          selection: currentRecorder.selection,
          duration: currentRecorder.getDurationMillis(),
        });
      });
    }

    let newRecorder;

    if (
      asrPlexFeatureEnabled ||
      accurateTextPlacementEnabled ||
      !experimentConfiguration.webmEnabled
    ) {
      // Create a PCM recorder when using ASR Plex or when explicitly not using WebM.
      logger.info(
        `[useRecorder] Creating PCM recorder for ${accurateTextPlacementEnabled ? 'DictationProvider' : 'ASRPlexDictation'}`
      );
      const ac = audioContextRef.current;
      if (ac == null) return;
      const workletOptions = {
        onDataAvailable: (ev: PCMEvent) =>
          recorderEmitter.emit<PCMRecorderDataAvailableEvent>('data_available', {
            ...ev,
            setShouldSkipWebsocketCreation,
            shouldSkipWebsocketCreation: shouldSkipWebsocketCreationRef.current,
          }),
        analytics: analyticsData,
        sourceSampleRate: hasExperimentalControl
          ? experimentConfiguration.audioSamplingRate
          : asrPlexFeatureEnabled
            ? asrPlexConfiguration.audioSamplingRate
            : defaultConfiguration.audioSamplingRate,
        selection: dictationSelection,
      };
      if (asrPlexFeatureEnabled || accurateTextPlacementEnabled) {
        newRecorder = new RawPCMMonoAudioRecorderWorklet(cleanStream, ac, workletOptions);
      } else {
        newRecorder = new DownsampleRawPCMMonoAudioRecorderWorklet(cleanStream, ac, {
          targetSampleRate: 16000,
          ...workletOptions,
        });
      }
      newRecorder.startRecording();
    } else {
      logger.info('[useRecorder] Creating WebM recorder for useNvoqQueue');
      newRecorder = new SlateMediaRecorder(cleanStream, {
        mimeType: 'audio/webm',
        selection: dictationSelection,
      });
      // $FlowIgnore[incompatible-call]
      newRecorder.addEventListener('dataavailable', (ev: BlobEvent) => {
        recorderEmitter.emit<RecorderDataAvailableEvent>('data_available', {
          data: ev.data,
          // $FlowIgnore[prop-missing] we know this is a SlateMediaRecorder
          id: ev.target.id,
          // $FlowIgnore[prop-missing] we know this is a SlateMediaRecorder
          selection: ev.target.selection,
          // $FlowIgnore[prop-missing] we know this is a SlateMediaRecorder
          duration: ev.target.getDurationMillis(),
          setShouldSkipWebsocketCreation,
          shouldSkipWebsocketCreation: shouldSkipWebsocketCreationRef.current,
        });
      });
      newRecorder.startRecording(NVOQ_PREFERRED_AUDIO_CHUNK_TIME_MS);
    }
    recorderRef.current = newRecorder;
  }, [
    isFocusModeEnabled,
    isRecording,
    experimentConfiguration.webmEnabled,
    reportStatus,
    selection,
    editor,
    asrPlexFeatureEnabled,
    shouldSkipWebsocketCreationRef,
    setShouldSkipWebsocketCreation,
    mousePressedOnTextEditor,
    previousCaseId,
    currentCaseId,
    stopRecording,
    hasExperimentalControl,
    experimentConfiguration.audioSamplingRate,
    analyticsData,
    aiModeFeatureEnabled,
    accurateTextPlacementEnabled,
  ]);

  /* NOTE ON THE AUDIO GRAPH
   * This method bootstraps an audio graph, which looks like:
   *
   * (Mic) MediaStreamSource => (Placeholder) MediaStreamDestination
   *
   * (Placeholder) MediaStreamSource => (Downsampler) AudioWorklet => (Output) MediaDestination
   *
   * We end up creating two disconnected graphs by making a new stream source from the placeholder destination.
   *
   * TODO: This graph can be simpler.
   */
  const startRecording = useCallback(async () => {
    startRecordingLock.current = new Deferred();

    // If we are currently stopping, wait for the stop to finish before starting again.
    if (stopRecordingLock.current != null) {
      if (resolveRecordingDelayRef.current != null) {
        resolveRecordingDelayRef.current();
      }
      // $FlowIgnore[incompatible-use] we check for null above
      await stopRecordingLock.current.promise;
    }

    const start = async () => {
      // This is to ensure that a websocket is always created when the user starts a new recording.
      // For context, we previously tried setShouldSkipWebsocketCreation(false) during stopRecording
      // However stableText can still be processed after stopRecording, where setShouldSkipWebsocketCreation(true) may be triggered,
      // and thus preventing new websockets from being created
      setShouldSkipWebsocketCreation(false);

      if (!isGetUserMediaSupported()) {
        enqueueToast(
          <>
            Please access the Sirona Workspace through the{' '}
            <Text
              as="a"
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noopener noreferrer"
            >
              latest version of Google Chrome
            </Text>{' '}
            to enable dictation and other voice-driven features within the Sirona Workspace.
          </>,
          { severity: 'error' }
        );
        return;
      }
      if (recorderRef.current) {
        // TODO: handle if recorder is already active
        const activeErrorMessage =
          '[useRecorder] Cannot initialize; recorderRef worklet is already initialized';
        logger.error(activeErrorMessage, {
          recorder: JSON.stringify(recorderRef.current),
        });
        return;
      }

      const ac = audioContextRef.current;
      const mediaStream = streamRef.current;

      if (ac == null || mediaStream == null) {
        logger.error('[useRecorder] Audio context or media stream is null during startRecording', {
          audioContext: JSON.stringify(ac),
          mediaStream: JSON.stringify(mediaStream),
        });
        return;
      }

      const source = ac.createMediaStreamSource(mediaStream);
      sourceRef.current = source;

      const destination = ac.createMediaStreamDestination();
      destinationRef.current = destination;
      source.connect(destination);

      const cleanStream = destination.stream;
      cleanStreamRef.current = cleanStream;

      // Create a vadRecorder. This isn't being handled in the above effects because
      // we only need to create a single connection per dictation (rather than per selection).
      if (vadRecorderRef.current == null) {
        const onDataAvailable = ({
          data,
        }: {
          id: string,
          data: Blob,
          buffer: Float32Array,
          selection: ?RangeType,
          duration: number,
        }) => {
          if (vadWebsocket == null) {
            logger.error(
              '[useRecorder] vadWebsocket is null during onDataAvailable callback',
              vadWebsocket
            );
            return;
          }
          vadWebsocket.send(data);
        };
        const vadRecorder = new RawPCMMonoAudioRecorderWorklet(cleanStream, ac, {
          onDataAvailable,
          sourceSampleRate: asrPlexConfiguration.audioSamplingRate,
          selection,
        });
        vadRecorderRef.current = vadRecorder;
        await vadRecorder.onRecorderReady;
        vadRecorder.startRecording();
        vadRecorderRef.current = vadRecorder;
      }

      performance.mark(marks.sirona.Dictation.RecordingStarted);
      analytics.track(reporter.usr.recordingStarted, {
        selection: selection != null ? stringifyRange(selection) : 'null',
      });

      streamRef.current = mediaStream;

      // allow the audio context and media streams to be created first before doing this check, so it's ready for next time
      if (mousePressedOnTextEditor) {
        logger.warn(
          '[useRecorder] Cannot start recording while mouse is pressed down on text editor'
        );
        return;
      }

      setIsRecording(true);
      setIsDisplayingRecorderBorder(true);
    };

    try {
      await start();
    } catch (e) {
      const startErrorMessage = '[useRecorder] Executing start() threw an error';
      logger.error(startErrorMessage, e, {
        selection: selection != null ? stringifyRange(selection) : 'null',
      });
    } finally {
      // always remove the lock after start returns or throws
      if (startRecordingLock.current?.resolve != null) {
        startRecordingLock.current.resolve();
      } else {
        const recordingLockError =
          '[useRecorder] Cannot resolve recording lock - startRecordingLock expected to exist in startRecording callback';
        logger.error(recordingLockError);
        return;
      }
    }
  }, [
    setShouldSkipWebsocketCreation,
    mousePressedOnTextEditor,
    selection,
    setIsDisplayingRecorderBorder,
    enqueueToast,
    vadWebsocket,
  ]);

  const cleanUpRecording = useCallback(() => {
    if (isRecording) {
      stopRecording({ force: true });
    }
  }, [isRecording, stopRecording]);

  useUnmount(() => {
    cleanUpRecording();
  });

  useOnUnload(cleanUpRecording);

  const toggleRecording = useCallback(() => {
    if (isRecording && resolveRecordingDelayRef.current == null) {
      stopRecording();
    } else {
      startRecording();
    }
  }, [isRecording, stopRecording, startRecording]);

  const getRecorder = useCallback(() => {
    return recorderRef.current;
  }, []);

  const getStream = useCallback(() => {
    return streamRef.current;
  }, []);

  const getAudioContext = useCallback(() => {
    return audioContextRef.current;
  }, []);

  const recorderBag = useMemo(
    () => ({
      isRecording,
      showRecording,
      startRecording,
      recorderTarget,
      setRecorderTarget,
      stopRecording,
      toggleRecording,
      getRecorder,
      getStream,
      getAudioContext,
      userActivityElementRef,
      setTextEditorElement,
    }),
    [
      isRecording,
      showRecording,
      startRecording,
      recorderTarget,
      setRecorderTarget,
      stopRecording,
      toggleRecording,
      getRecorder,
      getStream,
      getAudioContext,
      userActivityElementRef,
      setTextEditorElement,
    ]
  );

  return <RecorderContext.Provider value={recorderBag}>{children}</RecorderContext.Provider>;
};

export const useRecorder = (): RecorderContextBag => {
  const recorder = useContext(RecorderContext);

  if (!recorder) {
    throw new Error('[useRecorder] Hook must be used within a RecorderProvider.');
  }

  return recorder;
};
