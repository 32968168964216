// @flow

import type { Emitter } from 'mitt';
import type { SetterOrUpdater } from 'recoil';
import type { EditorType, RangeRefType, RangeType } from '../../../core';
import type { OnExternalSubstitution, OnStableText } from '../types';
import type {
  AdditionalParams,
  NvoqError,
  NvoqMessage,
  NvoqWebsocketConfig,
  NvoqWebsocketEvent,
  NvoqDoneMessage,
} from './createNvoqWebsocketFactory';
import type { StepEntry } from './useSteps';
import type { RecorderDataAvailableEvent } from 'common/Recorder/useRecorder/useRecorder';
import type { ReportStatuses } from 'domains/reporter/Reporter/state';

import { Editor } from '../../../core';
import { useRangeMasksDispatch } from '../../../hooks';
import { createEditorWarning } from '../../../utils';
import { MINIMUM_DICTATION_DURATION_MILLIS } from '../constants';
import { DICTATION_PLUGIN_ID } from '../types';
import { useTextProcessing } from '../useTextProcessing';
import {
  createNvoqBoundaryRequestMessage,
  createNvoqWebsocketFactory,
  NVOQ_DONE_MESSAGE,
} from './createNvoqWebsocketFactory';

import { recorderEmitter, REQUEST_BOUNDARY_DELAY } from 'common/Recorder/useRecorder';
import { APP_VERSION } from 'config/constants';
import { env } from 'config/env';
import {
  DEFAULT_EXPERIMENTAL_CONFIGURATION as defaultConfiguration,
  experimentConfigurationState,
} from 'domains/reporter/Reporter/ExperimentControlTab';
import {
  dictationQueueSizeState,
  PROVISIONAL_SUBMIT_STATUSES,
  reportStatusState,
} from 'domains/reporter/Reporter/state';
import { marks } from 'domains/reporter/RichTextEditor/analytics/performance';
import { partialEditor } from 'domains/reporter/RichTextEditor/utils';
import { sentinelSelection } from 'domains/reporter/RichTextEditor/utils/sentinelSelection';
import { stringifyRange } from 'domains/reporter/RichTextEditor/utils/stringify';
import { microphoneState } from 'domains/reporter/useMicrophone';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useWorklistItemAnalytics } from 'hooks/useWorklistItemAnalytics';
import mitt from 'mitt';
import analytics from 'modules/analytics';
import { isMetric, reporter } from 'modules/analytics/constants';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { logger } from 'modules/logger';
import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import { useUnmount } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { performance } from 'utils/performance';

type SelectionEventHandler = $ReadOnly<{ id: string, selection: RangeType }>;
type TextEventHandler = $ReadOnly<{ msg: NvoqMessage, selection: RangeType }>;
type MessageToNvoq = Blob | NvoqDoneMessage;

type UseNvoqQueueProps = $ReadOnly<{
  /**
   * The maximum number of connections to open up to nVoq at a given time. They have certain
   * limits per environment.
   */
  maxNumberOfConnections: number,
  editor: EditorType,
  isEnabled: boolean,
  nvoqID: string,
  nvoqAuthorization: string,
  onWebsocketError: () => void,
  onNvoqError: (e?: { msg: NvoqError }) => void,
  onStableText?: OnStableText,
  enablePicklistDictation: boolean,
  onExternalSubstitution: OnExternalSubstitution,
}>;

export type UseNvoqQueue = {
  nvoqEmitter: Emitter<NvoqWebsocketEvent>,
  nvoqWebsocketMap: Map<string, WebSocket>,
  dictationQueueMap: Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>,
  pendingWebsocketDictationQueueMap: Map<
    string,
    { selection: RangeType, data: Array<MessageToNvoq> },
  >,
  selectionToSelectionRef: WeakMap<RangeType, RangeRefType>,
  selectionToStepsQueueMap: Map<RangeType, StepEntry[]>,
  selectionToDoneMessageSentRef: Map<string, true>,
};

// In a Provisional Submit State, the editor's selection will naturally be null.
// In order to leverage the maps, we delegate this special selection (not available with natural use).
// Also allows us to not complicate the code with additional null-selection checks.
export const PROVISIONAL_SUBMIT_SELECTION: RangeType = sentinelSelection('PROVISIONAL_SUBMIT');

const createWorkNextItemInQueue =
  ({
    createNvoqWebsocket,
    dictationQueueMap,
    pendingWebsocketDictationQueueMap,
    nvoqWebsocketMap,
    selectionToSelectionRef,
    editor,
    onNewWebsocketOpenSendQueue,
    worklistItemId,
    additionalParams,
    setDictationQueueSizeState,
  }: {
    createNvoqWebsocket: (config: NvoqWebsocketConfig) => WebSocket,
    dictationQueueMap: Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>,
    pendingWebsocketDictationQueueMap: Map<
      string,
      { selection: RangeType, data: Array<MessageToNvoq> },
    >,
    nvoqWebsocketMap: Map<string, WebSocket>,
    selectionToSelectionRef: WeakMap<RangeType, RangeRefType>,
    editor: EditorType,
    selectionToStepsQueueMap: Map<RangeType, StepEntry[]>,
    onNewWebsocketOpenSendQueue: {
      current: Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>,
    },
    worklistItemId: string,
    additionalParams: AdditionalParams,
    setDictationQueueSizeState: SetterOrUpdater<{
      pendingDictations: number,
      pendingWebsocketDictations: number,
      openWebsocketConnections: number,
    }>,
  }) =>
  () => {
    let itemToWork: void | [string, { selection: RangeType, data: Array<MessageToNvoq> }] =
      undefined;
    let itemToWorkId: void | string = undefined;
    let itemToWorkSelection: void | RangeType = undefined;
    let itemToWorkAudioBlobs: void | Array<MessageToNvoq> = undefined;

    // Pull the first item from the dictation queue
    itemToWork = dictationQueueMap.entries().next().value;

    if (itemToWork == null) {
      return;
    }

    itemToWorkId = itemToWork[0];
    itemToWorkSelection = itemToWork[1].selection;
    itemToWorkAudioBlobs = itemToWork[1].data;

    if (itemToWorkId == null || itemToWorkSelection == null || itemToWorkAudioBlobs == null) {
      const itemToWorkErrorMessage = '[useNvoqQueue] Item to work has a null value, skipping';
      logger.warn(itemToWorkErrorMessage, {
        itemToWorkId,
        itemToWorkSelection,
        itemToWorkAudioBlobs: JSON.stringify(itemToWorkAudioBlobs),
        dictationQueueMap: JSON.stringify(dictationQueueMap),
        dictationQueueMapSize: dictationQueueMap.size,
      });
      dictationQueueMap.delete(itemToWorkId);
      setDictationQueueSizeState((prev) => ({
        ...prev,
        pendingDictations: dictationQueueMap.size,
      }));
      return;
    }

    const websocket = createNvoqWebsocket({
      id: itemToWorkId,
      selection: itemToWorkSelection,
      worklistItemId,
      additionalParams,
    });

    websocket.addEventListener('open', () => {
      setDictationQueueSizeState((prev) => ({
        ...prev,
        openWebsocketConnections: prev.openWebsocketConnections + 1,
      }));
    });

    websocket.addEventListener('close', () => {
      setDictationQueueSizeState((prev) => ({
        ...prev,
        openWebsocketConnections: prev.openWebsocketConnections - 1,
      }));
    });

    const newOpenItem = onNewWebsocketOpenSendQueue.current.get(itemToWorkId);
    // Add queued dictation to pending websocket queue because establishing a connection
    // is async and we don't want to lose any frames. Add any pending blobs that were from the
    // previous selection but captured after the done message to the front to make sure we don't
    // lose frames.
    pendingWebsocketDictationQueueMap.set(itemToWorkId, {
      selection: itemToWorkSelection,
      data: [...(newOpenItem?.data ?? []), ...itemToWorkAudioBlobs],
    });

    // Remove from the dictation queue
    dictationQueueMap.delete(itemToWorkId);

    // Add newly created websocket to the websocket map so we can keep up with its status
    // and send additional dictation to it
    nvoqWebsocketMap.set(itemToWorkId, websocket);

    setDictationQueueSizeState((prev) => ({
      ...prev,
      pendingWebsocketDictations: pendingWebsocketDictationQueueMap.size,
      pendingDictations: dictationQueueMap.size,
    }));

    // Clear any dictation that was captured at the previous selection after the done message was sent since it is
    // appended to the pendingWebsocketDictationQueueMap.
    onNewWebsocketOpenSendQueue.current.delete(itemToWorkId);

    // Create a range ref that will keep the user's intended target for this dictation
    // in sync with changes to the document.
    selectionToSelectionRef.set(
      itemToWorkSelection,
      Editor.rangeRef(editor, itemToWorkSelection, { affinity: 'inward' })
    );
  };

const sendNvoqDoneMessage = ({
  id,
  socket,
  selectionToDoneMessageSentRef,
}: {
  id: string,
  socket: WebSocket,
  selectionToDoneMessageSentRef: Map<string, true>,
}) => {
  // Prevent sending multiple done messages for the same id
  const hasDoneMessageBeenSentForSelection = selectionToDoneMessageSentRef.get(id);
  if (hasDoneMessageBeenSentForSelection) return;

  if (socket?.readyState === 1) {
    performance.mark(marks.nvoq.Dictation.DoneMessageSent, id);
    logger.info('[useNvoqQueue] Stopping dictation (sending done message)', {
      logId: id,
      id,
      selectionToDoneMessageSent: JSON.stringify(selectionToDoneMessageSentRef),
    });
    socket.send(JSON.stringify(NVOQ_DONE_MESSAGE));
  }
  selectionToDoneMessageSentRef.set(id, true);
};

const sendNvoqBoundaryRequestMessage = ({
  id,
  socket,
  selection,
  duration,
}: {
  id: string,
  socket: WebSocket,
  selection: RangeType,
  duration: number,
}) => {
  if (socket?.readyState === 1) {
    logger.info('[useNvoqQueue] Requesting boundary', {
      logId: id,
      id,
      selection: stringifyRange(selection),
    });
    const requestedBoundaryTime = (duration - REQUEST_BOUNDARY_DELAY) / 1000;

    socket.send(JSON.stringify(createNvoqBoundaryRequestMessage(requestedBoundaryTime)));
  }
};

export const useNvoqQueueInternal = ({
  maxNumberOfConnections,
  editor,
  isEnabled,
  nvoqID,
  nvoqAuthorization,
  onWebsocketError,
  onNvoqError,
  enablePicklistDictation,
  onExternalSubstitution,
}: UseNvoqQueueProps): UseNvoqQueue => {
  const analyticsData = useWorklistItemAnalytics();
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);
  useEffect(() => {
    const po = new PerformanceObserver((list) => {
      for (const entry of list.getEntriesByType('measure')) {
        if (isMetric(entry.name, { scope: 'reporter' })) {
          analytics.track(entry.name, {
            ...analyticsData,
            duration_ms: Math.round(entry.duration),
          });
          performance.clearMeasures(entry.name);
        }
      }
    });

    po.observe({
      type: 'measure',
      buffered: true,
    });

    return () => {
      po.disconnect();
    };
  }, [analyticsData]);

  const setDictationQueueSizeState = useSetRecoilState(dictationQueueSizeState);
  const {
    processStableText,
    processHypothesisText,
    selectionToSelectionRef,
    selectionToStepsQueueMap,
    mostRecentStableTextInserted,
    isMousePressed,
    setShouldSkipWebsocketCreationRef,
    skipWebsocketCreationOptionsRef,
  } = useTextProcessing({ editor, enablePicklistDictation, onExternalSubstitution });

  const decorateDispatch = useRangeMasksDispatch();
  const nvoqWebsocketMap = useRef(new Map<string, WebSocket>());
  const selectionToDoneMessageSentRef = useRef(new Map<string, true>());
  const onNewWebsocketOpenSendQueue = useRef(
    new Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>()
  );
  const dictationQueueMap = useRef(
    new Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>()
  );
  const pendingWebsocketDictationQueueMap = useRef(
    new Map<string, { selection: RangeType, data: Array<MessageToNvoq> }>()
  );
  const nvoqEmitter = useRef(mitt<NvoqWebsocketEvent>());

  const { audioInputDeviceLabel } = useRecoilValue(microphoneState);

  const experimentConfiguration = useRecoilValue(experimentConfigurationState);
  const webmEnabled = !!experimentConfiguration.webmEnabled;
  const createNvoqWebsocket = useMemo(
    () =>
      createNvoqWebsocketFactory(nvoqEmitter.current, {
        nvoqID,
        nvoqAuthorization,
      }),
    [nvoqID, nvoqAuthorization]
  );
  const [hasExperimentalControl] = useFeatureFlagEnabled(FF.REPORTER_EXPERIMENTAL_OPTIONS);

  const { data: meData } = useCurrentUser();

  useUnmount(() => {
    setDictationQueueSizeState((prev) => ({
      pendingDictations: 0,
      pendingWebsocketDictations: 0,
      openWebsocketConnections: prev.openWebsocketConnections,
    }));
  });

  const worklistItemId = analyticsData?.worklistItemId ?? '';
  const workNextItemInQueue = useMemo(
    () =>
      createWorkNextItemInQueue({
        createNvoqWebsocket,
        nvoqWebsocketMap: nvoqWebsocketMap.current,
        dictationQueueMap: dictationQueueMap.current,
        pendingWebsocketDictationQueueMap: pendingWebsocketDictationQueueMap.current,
        selectionToSelectionRef: selectionToSelectionRef.current,
        selectionToStepsQueueMap: selectionToStepsQueueMap.current,
        editor,
        onNewWebsocketOpenSendQueue: onNewWebsocketOpenSendQueue,
        worklistItemId,
        additionalParams: {
          selectedConfiguration: hasExperimentalControl
            ? experimentConfiguration
            : defaultConfiguration,
          userId: meData?.me.id,
          clinicId: meData?.me.clinic?.smid,
          worklistItemId: analyticsData?.worklistItemId,
          audioInputLabel: audioInputDeviceLabel,
          env: env?.DEPLOY_ENV,
          version: APP_VERSION,
          userAgent: navigator.userAgent,
        },
        setDictationQueueSizeState,
      }),
    [
      analyticsData?.worklistItemId,
      audioInputDeviceLabel,
      createNvoqWebsocket,
      editor,
      experimentConfiguration,
      hasExperimentalControl,
      meData?.me.clinic?.smid,
      meData?.me.id,
      worklistItemId,
      setDictationQueueSizeState,
      selectionToSelectionRef,
      selectionToStepsQueueMap,
    ]
  );

  useEffect(() => {
    // When recording is stopped, flush all the open sockets
    // forcing them to be resolved
    if (!isEnabled) {
      const sockets = nvoqWebsocketMap.current.entries();

      // If they stop recording clear any queued hypothesis text so that it doesn't auto append to
      // the next place they start recording
      const newWebsockets = onNewWebsocketOpenSendQueue.current.entries();
      for (const [id] of newWebsockets) {
        onNewWebsocketOpenSendQueue.current.delete(id);
      }

      for (const socketTuple of sockets) {
        const [id, socket] = socketTuple;
        if (socket.readyState === 1) {
          sendNvoqDoneMessage({
            id,
            socket,
            selectionToDoneMessageSentRef: selectionToDoneMessageSentRef.current,
          });
          mostRecentStableTextInserted.current = null;
        } else {
          // JUST BECAUSE THE USER IS NO LONGER RECORDING DOESN'T MEAN WE'RE DONE NVOQ'ING!
          //
          // This means the user has dictation awaiting to be worked, but stopped recording.
          // This appends a done message to the end of the pending queue items so that they
          // immediately close after sending all dictation.
          const queueDictation = pendingWebsocketDictationQueueMap.current.get(id);
          if (queueDictation) {
            pendingWebsocketDictationQueueMap.current.set(id, {
              ...queueDictation,
              data: [...queueDictation.data, NVOQ_DONE_MESSAGE],
            });
          }
        }
      }
    }
  }, [isEnabled, mostRecentStableTextInserted]);

  useEffect(() => {
    const onOpen = (event?: SelectionEventHandler) => {
      if (!event) return;
      const { id } = event;

      const socket = nvoqWebsocketMap.current.get(id);
      const dictation = pendingWebsocketDictationQueueMap.current.get(id);

      if (!socket) {
        const socketErrorMessage = '[useNvoqQueue] No socket found for id';
        createEditorWarning(socketErrorMessage);
        logger.warn(socketErrorMessage, {
          logId: id,
          id,
          pendingWebsocketDictationQueueMap: JSON.stringify(
            pendingWebsocketDictationQueueMap.current
          ),
          editor: partialEditor(editor),
        });
        return;
      }
      if (!dictation) {
        const dictationErrorMessage = '[useNvoqQueue] No dictation found for id';

        createEditorWarning(dictationErrorMessage);
        logger.warn(dictationErrorMessage, {
          logId: id,
          id,
          pendingWebsocketDictationQueueMap: JSON.stringify(
            pendingWebsocketDictationQueueMap.current
          ),
          editor: partialEditor(editor),
          socket: JSON.stringify(socket),
        });
        return;
      }

      // Send all the stored blobs
      dictation.data.forEach((d) => {
        if (d?.method === 'AUDIODONE') {
          sendNvoqDoneMessage({
            id,
            socket,
            selectionToDoneMessageSentRef: selectionToDoneMessageSentRef.current,
          });
        } else {
          socket?.send(d);
        }
      });

      // Remove after sending everything
      pendingWebsocketDictationQueueMap.current.delete(id);
      setDictationQueueSizeState((prev) => ({
        ...prev,
        pendingWebsocketDictations: pendingWebsocketDictationQueueMap.current.size,
      }));
      selectionToDoneMessageSentRef.current.delete(id);

      if (!isEnabled) {
        sendNvoqDoneMessage({
          id,
          socket,
          selectionToDoneMessageSentRef: selectionToDoneMessageSentRef.current,
        });
      }
    };

    nvoqEmitter.current.on<SelectionEventHandler>('on_open', onOpen);

    const onStableText = async (event?: TextEventHandler) => {
      if (!event) return;
      const { msg, selection } = event;
      await processStableText(msg, selection);
    };

    nvoqEmitter.current.on<TextEventHandler>('on_stable_text', onStableText);

    const onHypothesisText = (event?: TextEventHandler) => {
      if (!event) return;

      // if the mouse pressed, user could be highlighting text
      // don't let hypothesis text interfere with this
      if (isMousePressed === true) return;

      const { msg, selection } = event;

      processHypothesisText(msg, selection);
    };

    nvoqEmitter.current.on<TextEventHandler>('on_hypothesis_text', onHypothesisText);

    const onWebsocketErrorF = () => onWebsocketError();
    nvoqEmitter.current.on('on_websocket_error', onWebsocketErrorF);

    const onNvoqErrorF = (...args: [void | { msg: NvoqError }]) => onNvoqError(...args);
    nvoqEmitter.current.on('on_nvoq_error', onNvoqErrorF);

    // NOTE: OnClose can be called before text has been dictated for a given selection because
    // the stable text queue relies on async effects.
    const onClose = (event?: SelectionEventHandler) => {
      if (!event) return;
      const { id } = event;

      nvoqWebsocketMap.current.delete(id);

      // Work from the queue on closed if possible
      // I open at the close :D
      if (nvoqWebsocketMap.current.size < maxNumberOfConnections) {
        workNextItemInQueue();
      }

      // If there are no more dictations in the queue, pending websocket dictations in the queue, and
      // there are no more active websockets - cleanup decorations just in case we have stragglers. Should
      // never be needed unless we have bugs or the user does something weird with the selection while
      // dictating.
      if (
        nvoqWebsocketMap.current.size === 0 &&
        dictationQueueMap.current.size === 0 &&
        pendingWebsocketDictationQueueMap.current.size === 0
      ) {
        decorateDispatch((decorations) =>
          decorations.filter((d) => d[DICTATION_PLUGIN_ID] === true)
        );
      }
    };
    nvoqEmitter.current.on<SelectionEventHandler>('on_close', onClose);

    return () => {
      nvoqEmitter.current.off<SelectionEventHandler>('on_open', onOpen);
      nvoqEmitter.current.off<TextEventHandler>('on_stable_text', onStableText);
      nvoqEmitter.current.off<TextEventHandler>('on_hypothesis_text', onHypothesisText);
      // This refs stores an event emitter, not DOM nodes
      // eslint-disable-next-line react-hooks/exhaustive-deps
      nvoqEmitter.current.off<SelectionEventHandler>('on_close', onClose);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      nvoqEmitter.current.off('on_nvoq_error', onNvoqErrorF);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      nvoqEmitter.current.off('on_websocket_error', onWebsocketErrorF);
    };
  }, [
    editor,
    maxNumberOfConnections,
    workNextItemInQueue,
    decorateDispatch,
    onWebsocketError,
    onNvoqError,
    isEnabled,
    setDictationQueueSizeState,
    isMousePressed,
    processStableText,
    processHypothesisText,
  ]);

  useEffect(() => {
    const onProcessedDataAvailable = ({
      data: blob,
      id,
      selection: recorderSelection,
      duration,
      setShouldSkipWebsocketCreation,
      shouldSkipWebsocketCreation,
    }: RecorderDataAvailableEvent) => {
      let selection: RangeType | null;

      if (PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus)) {
        selection = PROVISIONAL_SUBMIT_SELECTION;
      } else {
        selection = recorderSelection ?? editor.selection;
      }
      setShouldSkipWebsocketCreationRef.current = setShouldSkipWebsocketCreation;
      skipWebsocketCreationOptionsRef.current = {
        // for whether hypothesis text selection should use this selection or native from event
        shouldSkipWebsocketCreation,
      };

      if (!isEnabled || !selection || blob == null || blob.size === 0) {
        return;
      }

      // If the duration is under the minimum dictation duration, we
      // short-circuit and return early, storing the audio in the dictation
      // queue so that if it surpasses the minimum dictation duration, it
      // will be sent for transcription.
      //
      // The early return prevents us from opening a websocket connection even
      // if we have one available. This helps us avoid hitting our websocket
      // concurrency limit with short dictations that contain no actual audio
      // data to be transcribed.
      if (duration < MINIMUM_DICTATION_DURATION_MILLIS) {
        logger.info('[usNvoqQueue] Duration of dictation is under the minimum; returning early', {
          logId: stringifyRange(selection),
          editor: editor != null ? partialEditor(editor) : 'null',
          dictationQueueMap: JSON.stringify(dictationQueueMap.current),
          dictationQueueSize: dictationQueueMap.current.size,
          duration,
        });
        // Send it to the queue to be worked on
        const queueDictation = dictationQueueMap.current.get(id);
        if (queueDictation) {
          dictationQueueMap.current.set(id, {
            ...queueDictation,
            data: [...queueDictation.data, blob],
          });
        } else {
          dictationQueueMap.current.set(id, { selection, data: [blob] });
          setDictationQueueSizeState((prev) => ({
            ...prev,
            pendingDictations: dictationQueueMap.current.size,
          }));
        }
        return;
      }

      if (nvoqWebsocketMap.current.has(id)) {
        const websocket = nvoqWebsocketMap.current.get(id);

        if (selectionToDoneMessageSentRef.current.get(id) === true) {
          const queueDictation = pendingWebsocketDictationQueueMap.current.get(id);
          if (queueDictation) {
            pendingWebsocketDictationQueueMap.current.set(id, {
              ...queueDictation,
              data: [...queueDictation.data, blob],
            });
          } else {
            // enqueue dictation
            pendingWebsocketDictationQueueMap.current.set(id, { selection, data: [blob] });
            analytics.track(reporter.sys.dictationQueued);
          }
        } else if (websocket?.readyState === 1) {
          // If this selection has an active websocket, then send the blob.
          websocket?.send(blob);
        } else {
          if (editor.selection != null) {
            // If the websocket is not ready, send it to the queue to be worked
            // when it opens the queue will be ready based on the next branch
            const queueDictation = pendingWebsocketDictationQueueMap.current.get(id);
            if (queueDictation) {
              pendingWebsocketDictationQueueMap.current.set(id, {
                ...queueDictation,
                data: [...queueDictation.data, blob],
              });
            }
          }
        }
      } else if (dictationQueueMap.current.has(id)) {
        const queueDictation = dictationQueueMap.current.get(id);
        if (queueDictation) {
          dictationQueueMap.current.set(id, {
            ...queueDictation,
            data: [...queueDictation.data, blob],
          });
        }
      } else {
        dictationQueueMap.current.set(id, { selection, data: [blob] });
        setDictationQueueSizeState((prev) => ({
          ...prev,
          pendingDictations: dictationQueueMap.current.size,
        }));
      }

      if (
        nvoqWebsocketMap.current.size < maxNumberOfConnections &&
        dictationQueueMap.current.size > 0
      ) {
        workNextItemInQueue();
      }
    };

    recorderEmitter.on<RecorderDataAvailableEvent>('data_available', onProcessedDataAvailable);

    const onStopRecording = ({
      id,
      selection,
      duration,
    }: {
      id: string,
      selection: RangeType,
      duration: number,
    }) => {
      const socket = nvoqWebsocketMap.current.get(id);
      // I think this is where the error is, its attaching a done message before we have received all the data available messages?
      if (socket != null && socket.readyState === 1) {
        sendNvoqDoneMessage({
          id,
          socket,
          selectionToDoneMessageSentRef: selectionToDoneMessageSentRef.current,
        });
        return;
      }

      const skip = duration < MINIMUM_DICTATION_DURATION_MILLIS;

      // The socket being null indicates that the concurrent connection limit to nVoq
      // has been reached and this dictation is still in the queue waiting to be picked
      // up. So we need to queue the done message at the end of the dictation queue so that
      // when the queue gets picked up it will send all its audio data and the done message.
      const queueDictation = pendingWebsocketDictationQueueMap.current.get(id);
      if (skip) {
        logger.info('[useNvoqQueue] Recording stopped before minimum dictation duration', {
          logId: id,
          duration,
          editor: editor != null ? partialEditor(editor) : 'null',
          dictationQueueMap: JSON.stringify(dictationQueueMap.current),
          dictationQueueSize: dictationQueueMap.current.size,
          pendingWebsocketDictations: JSON.stringify(pendingWebsocketDictationQueueMap.current),
          pendingWebsocketDictationsSize: pendingWebsocketDictationQueueMap.current.size,
        });
      }

      if (queueDictation) {
        if (skip) {
          pendingWebsocketDictationQueueMap.current.delete(id);
          setDictationQueueSizeState((prev) => ({
            ...prev,
            pendingWebsocketDictations: pendingWebsocketDictationQueueMap.current.size,
          }));
        } else {
          pendingWebsocketDictationQueueMap.current.set(id, {
            selection,
            data: [...queueDictation.data, NVOQ_DONE_MESSAGE],
          });
        }
      } else if (dictationQueueMap.current.has(id)) {
        if (skip) {
          dictationQueueMap.current.delete(id);
          setDictationQueueSizeState((prev) => ({
            ...prev,
            pendingDictations: dictationQueueMap.current.size,
          }));
        } else {
          const queueDictation = dictationQueueMap.current.get(id);
          if (queueDictation != null) {
            dictationQueueMap.current.set(id, {
              ...queueDictation,
              data: [...queueDictation.data, NVOQ_DONE_MESSAGE],
            });
          }
        }
      } else {
        // Hitting this path means we have a websocket queue that could become active later that will
        // never have a done message added to it. nVoq will eventually close this socket but it will delay
        // the user's transcript from completing.
        const warningMessage = '[useNvoqQueue] No dictation queue found for selection';
        logger.warn(warningMessage, {
          logId: id,
          editor: editor != null ? partialEditor(editor) : 'null',
          dictationQueueMap: JSON.stringify(dictationQueueMap.current),
          dictationQueueSize: dictationQueueMap.current.size,
        });
      }
    };

    recorderEmitter.on<{ id: string, selection: RangeType, duration: number }>(
      'stop_recording',
      onStopRecording
    );

    const onSilenceDetected = ({
      id,
      selection,
      duration,
    }: {
      id: string,
      selection: RangeType,
      duration: number,
    }) => {
      const socket = nvoqWebsocketMap.current.get(id);
      if (socket != null && socket.readyState === 1) {
        sendNvoqBoundaryRequestMessage({
          id,
          socket,
          selection,
          duration,
        });
        return;
      }
    };

    recorderEmitter.on<{ id: string, selection: RangeType, duration: number }>(
      'silence_detected',
      onSilenceDetected
    );

    return () => {
      recorderEmitter.off('stop_recording', onStopRecording);
      recorderEmitter.off('data_available', onProcessedDataAvailable);
      recorderEmitter.off('silence_detected', onSilenceDetected);
    };
  }, [
    editor,
    maxNumberOfConnections,
    workNextItemInQueue,
    isEnabled,
    webmEnabled,
    reportStatus,
    setDictationQueueSizeState,
    setShouldSkipWebsocketCreationRef,
    skipWebsocketCreationOptionsRef,
  ]);

  return {
    nvoqEmitter: nvoqEmitter.current,
    nvoqWebsocketMap: nvoqWebsocketMap.current,
    dictationQueueMap: dictationQueueMap.current,
    pendingWebsocketDictationQueueMap: pendingWebsocketDictationQueueMap.current,
    selectionToSelectionRef: selectionToSelectionRef.current,
    selectionToStepsQueueMap: selectionToStepsQueueMap.current,
    selectionToDoneMessageSentRef: selectionToDoneMessageSentRef.current,
  };
};

export type NvoqQueueContextProps = $ReadOnly<{
  ...UseNvoqQueueProps,
  children: React$Node,
}>;
export type NvoqQueueContextBag = UseNvoqQueue;

const NvoqQueueContext = createContext<?NvoqQueueContextBag>(undefined);

export const NvoqQueueProvider = ({ children, ...rest }: NvoqQueueContextProps): React$Node => {
  const bag = useNvoqQueueInternal(rest);
  return <NvoqQueueContext.Provider value={bag}>{children}</NvoqQueueContext.Provider>;
};

export const useNvoqQueue = (): NvoqQueueContextBag => {
  const queueBag = useContext(NvoqQueueContext);

  if (!queueBag) {
    throw new Error('useNvoqQueue must be used within a NvoqQueueProvider.');
  }

  return queueBag;
};
