// @flow

import { useCallback, useMemo } from 'react';
import { MY_QUEUE, WORKLIST_VIEW } from 'config/constants';
import { GET_WORKLIST } from 'modules/Apollo/queries';
import type { ApolloQueryResult } from '@apollo/client';
import type { GetWorklistQuery, GetWorklistQueryVariables } from 'generated/graphql';
import { useDebouncedCallback } from 'use-debounce';
import { usePrefetchTopWorklistItems } from 'hooks/usePrefetchWorklistItems';
import type { PixelDataSharedWorker } from 'modules/viewer/workers/PixelDataSharedWorker';
import { useWorklistFiltersContext } from '../../WorklistFilters/WorklistFiltersContext';
import type { FilterDefinitionType, FilterStateType } from '../../WorklistFilters/types';
import { processFiltersForQuery } from '../../WorklistFilters/utils';
import type { WorklistSurfaceType } from '../types';
import { WorklistSurface } from '../types';
import { useTablePaginatedData } from './useTablePaginatedData';
import type { WorklistItemData } from './useGenerateWorklistTableColumnConfig';
import { WORKLIST_QUERY_LIMIT, useWorklistVariables } from './useWorklistVariables';
import type { FetchMoreFn } from './useTablePaginatedData';

type useWorklistTableDataProps = {
  surface: ?WorklistSurfaceType,
  search: string,
  userId: ?string,
  pixelDataSharedWorker: ?PixelDataSharedWorker,
  skipGetClaimedItems?: boolean,
  overrideFilters?: ?FilterStateType,
  overrideAvailableFilters?: ?FilterDefinitionType,
};

type useWorklistTableDataReturn = {
  items: GetWorklistQuery['worklistItems']['items'],
  totalCount: number,
  filteredCount: number,
  refetch: (variables: GetWorklistQueryVariables) => Promise<ApolloQueryResult<GetWorklistQuery>>,
  fetchMore: FetchMoreFn,
  loading: boolean,
  variables: GetWorklistQueryVariables,
  isFetchMoreLoading: boolean,
  hasNextPage: boolean,
  nextCursor: ?string,
};

const FALLBACK_WORKLIST_DATA = {
  worklistItems: {
    items: ([]: GetWorklistQuery['worklistItems']['items']),
    totalCount: 0,
    filteredCount: 0,
    next: null,
  },
};

export const useWorklistTableData = ({
  search,
  surface,
  userId,
  pixelDataSharedWorker,
  skipGetClaimedItems = false,
  overrideFilters,
  overrideAvailableFilters,
}: useWorklistTableDataProps): useWorklistTableDataReturn => {
  const { filters: _filters, availableFilters: _availableFilters } = useWorklistFiltersContext();

  const filters = overrideFilters ?? _filters;
  const availableFilters = overrideAvailableFilters ?? _availableFilters;

  const processedFilters = useMemo(() => {
    return processFiltersForQuery(filters, availableFilters);
  }, [availableFilters, filters]);

  const variables = useWorklistVariables(
    userId,
    search,
    surface ?? WorklistSurface.PENDING,
    processedFilters
  );

  let skipGetWorklistQuery = surface === MY_QUEUE && userId == null;

  // Do not allow querying on the worklist view tab until at least one view is selected and enabled
  if (surface === WORKLIST_VIEW) {
    const selectedWorklistViews = filters.views;

    skipGetWorklistQuery = selectedWorklistViews == null || selectedWorklistViews.length === 0;
  }

  const transformData = useCallback((data) => {
    const {
      worklistItems: { items, totalCount, filteredCount, next },
    } = data ? data : FALLBACK_WORKLIST_DATA;

    return {
      items,
      totalCount,
      next,
      filteredCount,
    };
  }, []);

  const {
    items,
    totalCount,
    loading,
    fetchMore,
    hasNextPage,
    isFetchMoreLoading,
    refetch,
    filteredCount,
    nextCursor,
  } = useTablePaginatedData<WorklistItemData, GetWorklistQueryVariables, GetWorklistQuery>({
    query: GET_WORKLIST,
    variables,
    skip: skipGetWorklistQuery,
    limit: WORKLIST_QUERY_LIMIT,
    transformData,
    pollInterval: 60000,
  });

  usePrefetchTopWorklistItems(items, pixelDataSharedWorker);

  const fetchMoreDebounced = useDebouncedCallback(fetchMore, 500);

  return {
    items,
    totalCount,
    filteredCount,
    refetch,
    isFetchMoreLoading,
    hasNextPage,
    loading,
    variables,
    fetchMore: fetchMoreDebounced,
    nextCursor,
  };
};
