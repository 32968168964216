// @flow

export type EditorFormProps = {
  testIdPrefix: string,
  children: React$Node,
  containerCss?: string,
  onSubmit: (Event) => Promise<void>,
};

export const EditorForm = ({
  testIdPrefix,
  children,
  containerCss,
  onSubmit,
}: EditorFormProps): React$Node => {
  return (
    <form
      data-testid={`${testIdPrefix}-form`}
      onSubmit={onSubmit}
      css={`
        min-width: 0;
        flex: 1 1 auto;
        height: 100%;
        isolation: isolate;
        display: flex;
        flex-flow: column nowrap;
        ${containerCss || ''}
      `}
    >
      {children}
    </form>
  );
};
