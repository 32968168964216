// @flow
import { createEditorWarning } from '../../../../utils';
import {
  VOICE_COMMAND_TARGET_TO_TYPES,
  VOICE_COMMAND_TARGET_UNKNOWN,
} from '../createNvoqWebsocketFactory';
import type { VoiceCommandTarget } from '../createNvoqWebsocketFactory';

export const getVoiceCommandTargetFromAction = (action: string): VoiceCommandTarget => {
  const targetEntry = VOICE_COMMAND_TARGET_TO_TYPES.find(([target, types]) =>
    types.includes(action)
  );

  if (targetEntry == null) {
    createEditorWarning(
      `Could not find target for voice command "${action}". Please add it to a voice commands type so it can be handled appropriately!`
    );
    return VOICE_COMMAND_TARGET_UNKNOWN;
  }

  return targetEntry[0];
};
