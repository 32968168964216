//@flow
import { atom } from 'recoil';
import type { RecoilState } from 'recoil';
import type { StatusAuditLogs } from 'hooks/useWorklistItemAuditLogs';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import type { WorklistSurfaceType } from './types';
import type { FilterStateType, FilterDefinitionType } from '../WorklistFilters/types';

export const claimedItemsCountState: RecoilState<number> = atom({
  key: 'worklist.claimedItemsCountAtom',
  default: 0,
});

export type ClaimCaseDialogState = {
  open: boolean,
  caseId: string,
  fullName: string,
  action: 'QUEUE' | 'READ' | '',
  callback?: () => void,
};

export const claimCaseDialogStateDefault = {
  open: false,
  caseId: '',
  fullName: '',
  action: '',
  callback: () => {},
};

export const claimCaseDialogState: RecoilState<ClaimCaseDialogState> = atom({
  key: 'worklist.claimCaseDialogState',
  default: claimCaseDialogStateDefault,
});

type CaseAuditLogModalState = {
  open: boolean,
  auditLogs: StatusAuditLogs,
};

export const caseAuditLogModalStateDefault: CaseAuditLogModalState = {
  open: false,
  auditLogs: {
    statusChanges: [],
    customStatusChanges: [],
  },
};

export const caseAuditLogModalState: RecoilState<CaseAuditLogModalState> = atom({
  key: 'worklist.caseAuditLogModalState',
  default: caseAuditLogModalStateDefault,
});

type WorklistAutoLoadState = {
  surface: ?WorklistSurfaceType,
  searchParamsString: ?string,
  filters: ?FilterStateType,
  availableFilters: ?FilterDefinitionType,
  searchForSurface: string,
};

export const worklistAutoLoadStateDefault: WorklistAutoLoadState = {
  surface: null,
  searchParamsString: null,
  filters: null,
  availableFilters: null,
  searchForSurface: '',
};

export const worklistAutoLoadState: RecoilState<WorklistAutoLoadState> = atom({
  key: 'worklistAutoLoadState',
  default: worklistAutoLoadStateDefault,
  effects: [broadcastChannelSynchronizerEffect()],
});
