// @flow

import { Colors } from 'styles';
import { NotEditable } from '../../../components';
import type { RenderElementProps } from '../../../core';
import type { MacroPlaceholderPluginElement } from '../types';
import { MACRO_PLACEHOLDER_DELIMITER_LEFT, MACRO_PLACEHOLDER_DELIMITER_RIGHT } from '../constants';

export type MacroPlaceholderTemplateElementProps = $ReadOnly<{
  ...RenderElementProps<MacroPlaceholderPluginElement>,
  selected: boolean,
  leftDelimiter?: React$Node,
  rightDelimiter?: React$Node,
  testID?: string,
  'data-editor-element': string,
}>;

export const MacroPlaceholderTemplateElement: React$AbstractComponent<
  MacroPlaceholderTemplateElementProps,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  any,
> = ({
  attributes,
  children,
  selected,
  element,
  leftDelimiter = MACRO_PLACEHOLDER_DELIMITER_LEFT,
  rightDelimiter = MACRO_PLACEHOLDER_DELIMITER_RIGHT,
  testID,
  ...props
}) => {
  return (
    <NotEditable>
      <span
        {...attributes}
        data-testid={testID}
        css={`
          padding: 2px 0px;
          vertical-align: baseline;
          border-radius: 4px;
          color: ${Colors.pink6};
          box-shadow: ${selected ? `0 0 0 1px ${Colors.pink6}` : 'none'};

          &:focus {
            outline: 0;
          }
        `}
        {...props}
      >
        {leftDelimiter}
        {children}
        {rightDelimiter}
      </span>
    </NotEditable>
  );
};
