// @flow

import { transparentize } from 'color2k';
import type { DeepLink } from 'domains/reporter/RichTextEditor/plugins/deepLink/types';

import { Colors } from 'styles';
import { useCurrentComparativeStudies } from './useCurrentCase';
import { useStudyColor } from './useStudyColor';
import { useStudies } from 'domains/viewer/Viewer/StudyLoader/useStudies';

export type DeepLinkColors = {
  backgroundColor: string,
  border: string,
};

export const DEFAULT_DEEP_LINK_BACKGROUND_COLOR = Colors.teal2;
export const DEFAULT_DEEP_LINK_BORDER = '1px solid transparent';
const DEFAULT_PRIMARY_COLOR = Colors.blue3;

const getDeepLinkColors = ({
  node,
  color,
  loadedStudySmids,
}: {
  node: DeepLink,
  color: string,
  loadedStudySmids: string[],
}): DeepLinkColors => {
  if (node.variant === 'study') {
    const isActive = loadedStudySmids.includes(node.context.studySmid);
    const backgroundColor = isActive ? color : transparentize(DEFAULT_PRIMARY_COLOR, 0.8);
    const borderStyle = isActive ? 'solid' : 'dashed';
    const borderColor = isActive ? 'transparent' : DEFAULT_PRIMARY_COLOR;
    const border = `1px ${borderStyle} ${borderColor}`;
    return { backgroundColor, border };
  }
  return {
    backgroundColor: DEFAULT_DEEP_LINK_BACKGROUND_COLOR,
    border: DEFAULT_DEEP_LINK_BORDER,
  };
};

export const useDeepLinkColors = (node: DeepLink): DeepLinkColors => {
  const [currentComparativeStudies] = useCurrentComparativeStudies();
  const [color] = useStudyColor(node.variant === 'study' ? node.context.studySmid : null);
  const { mainStudiesIds } = useStudies();
  const loadedStudySmids = [...mainStudiesIds, ...currentComparativeStudies];
  return getDeepLinkColors({ node, color, loadedStudySmids });
};
