//@flow

import { Colors } from 'styles';
import { Divider } from '../Divider';

export const ToolbarDivider = (): React$Node => (
  <Divider
    css={`
      margin: 16px 0px;
      background-color: ${Colors.gray6};
      width: 1px;
    `}
    orientation="vertical"
    flexItem
  />
);
