// @flow
import { useCallback } from 'react';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import type { ToolInteractionAnnotationResponse } from 'generated/graphql';
import type { HandleCreateAnnotation } from '../../Toolbox';
import type { Segment } from '../../../Annotations/types';

const OPTIONAL_ANNOTATION_PROPERTIES = [
  'label',
  'groupId',
  'style',
  'allowedTransformations',
  'customLogic',
];

type UseAnnotationResponseArgs = {
  createAnnotation: HandleCreateAnnotation,
  indexToWorld: (vec3) => ?vec3,
};

export function useAnnotationResponse({
  createAnnotation,
  indexToWorld,
}: UseAnnotationResponseArgs): (response: $ReadOnly<ToolInteractionAnnotationResponse>) => mixed {
  const handleAnnotationResponse = useCallback(
    (response: $ReadOnly<ToolInteractionAnnotationResponse>) => {
      const annotation = response.annotation;

      if (annotation == null) return null;

      const optional = OPTIONAL_ANNOTATION_PROPERTIES.reduce((acc, key) => {
        if (annotation[key] != null) {
          // $FlowIgnore[prop-missing] keys's won't always be present that's why we create an object with only the present keys
          acc[key] = annotation[key];
        }
        return acc;
      }, {});

      return createAnnotation({
        ...optional,
        type: annotation.type,
        segments: annotation.segments.map(
          ({ start, end }) =>
            ([
              // $FlowIssue[incompatible-cast] being a union, one of the properties is always defined
              // $FlowIssue[incompatible-call]
              start.worldSpace ?? indexToWorld(start.indexSpace),
              // $FlowIssue[incompatible-cast] being a union, one of the properties is always defined
              // $FlowIssue[incompatible-call]
              end.worldSpace ?? indexToWorld(end.indexSpace),
            ]: Segment)
        ),
      });
    },
    [createAnnotation, indexToWorld]
  );

  return handleAnnotationResponse;
}
