// @flow

import * as React from 'react';
import type { MarkPluginID } from '../plugins';
import type { RenderLeaf } from '../types';
import { TEXT_SOURCE_STYLING_PLUGIN_ID } from '../plugins/textSourceStyling/types';

export type GetRenderLeafOptions = $ReadOnly<{
  /**
   * By passing true, helper automatically spreads any required attributes down to the component. If this is not used,
   * you must spread the attributes onto the component.
   *
   * @default true
   */
  spreadAttributes?: boolean,
}>;

export const getRenderLeaf = <
  PluginOptions: $ReadOnly<{
    pluginID: MarkPluginID,
    component: React$ComponentType<$FlowFixMe>,
    ...
  }>,
>(
  { component: Component, pluginID }: PluginOptions,
  { spreadAttributes = true }: GetRenderLeafOptions = { spreadAttributes: true }
): RenderLeaf<> =>
  function GetRenderLeaf({ leaf, attributes, children, ...rest }): React$Node {
    const bagToSpread = React.useMemo(() => {
      return spreadAttributes ? attributes : { attributes };
    }, [attributes]);

    if (
      typeof pluginID === 'string' &&
      (pluginID === TEXT_SOURCE_STYLING_PLUGIN_ID || leaf[pluginID] != null) &&
      !!leaf.text
    ) {
      return (
        <Component
          data-editor-leaf={pluginID}
          {...bagToSpread}
          leaf={leaf}
          children={children}
          {...rest}
        />
      );
    }

    return children;
  };
