// @flow

import { useEffect, useMemo } from 'react';
import { useToolPreferences } from 'hooks/usePreferences';
import { sendEvent, NAMESPACES } from 'modules/EventsManager';
import Mousetrap from 'mousetrap';
import { unreachableCaseError } from 'types';
import { innerJoin, pipe, map } from 'ramda';
import type { Shortcut } from 'generated/graphql';

export type ReporterShortcutID =
  | 'REPORTER_TOGGLE_DICTATION'
  | 'REPORTER_PREVIOUS'
  | 'REPORTER_NEXT'
  | 'REPORTER_SUBMIT_REPORT'
  | 'REPORTER_GENERATE_IMPRESSION'
  | 'REPORTER_MED_CHECK'
  | 'REPORTER_DISCARD_REPORT'
  | 'REPORTER_DRAFT_REPORT';

export const REPORTER_SHORTCUT_IDS: ReporterShortcutID[] = [
  'REPORTER_TOGGLE_DICTATION',
  'REPORTER_PREVIOUS',
  'REPORTER_NEXT',
  'REPORTER_SUBMIT_REPORT',
  'REPORTER_GENERATE_IMPRESSION',
  'REPORTER_MED_CHECK',
  'REPORTER_DISCARD_REPORT',
  'REPORTER_DRAFT_REPORT',
];

const generateReportHandlerByID = (
  shortcutID: string
): /**
 * Mousetrap sends an empty object in place of a proper Event instance on Node,
 * so we have to check for that not to creash during unit tests
 */
((e: Event | {}, combo?: string) => void) => {
  switch (shortcutID) {
    case 'REPORTER_TOGGLE_DICTATION':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, {
          type: 'keyPress',
          action: 'toggleRecording',
        });
      };
    case 'REPORTER_PREVIOUS':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, {
          type: 'keyPress',
          action: 'previousBracket',
        });
      };
    case 'REPORTER_NEXT':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'nextBracket' });
      };
    case 'REPORTER_SUBMIT_REPORT':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'submitReport' });
      };

    case 'REPORTER_GENERATE_IMPRESSION':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'generateImpression' });
      };

    case 'REPORTER_MED_CHECK':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.URT_SHORTCUT, { type: 'keyPress', action: 'medCheck' });
      };

    case 'REPORTER_DISCARD_REPORT':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'discardReport' });
      };

    case 'REPORTER_DRAFT_REPORT':
      return (evt) => {
        if (evt instanceof Event) evt.preventDefault();
        sendEvent(NAMESPACES.REPORTER_SHORTCUT, { type: 'keyPress', action: 'draftReport' });
      };

    default:
      return unreachableCaseError(shortcutID);
  }
};

export const generateGlobalReporterShortcutsHandlers = (
  shortcuts: $ReadOnlyArray<$ReadOnly<Shortcut>>
): ({
  key: string,
  callback: (e: Event, combo?: string) => void,
  id: string,
}[]) =>
  pipe(
    (val) =>
      innerJoin(
        (shortcutEntity, shortcutID) => shortcutEntity.id === shortcutID,
        val,
        REPORTER_SHORTCUT_IDS
      ),
    map((shortcut: $Exact<Shortcut>) => ({
      key: shortcut.key ?? '',
      callback: generateReportHandlerByID(shortcut.id),
      id: shortcut.id,
    }))
  )(shortcuts);

export const useCrossWindowReporterShortcuts = () => {
  const [toolPreferences] = useToolPreferences();

  const reporterGlobalShortcutHandlers = useMemo(
    () =>
      toolPreferences
        ? generateGlobalReporterShortcutsHandlers(toolPreferences.keyboard.shortcuts)
        : [],
    [toolPreferences]
  );

  useEffect(() => {
    reporterGlobalShortcutHandlers.forEach(({ key, callback }) => {
      if (key == null) return;
      Mousetrap.bind(key, callback);
    });

    return () => {
      reporterGlobalShortcutHandlers.forEach(({ key, callback }) => {
        if (key == null) return;
        Mousetrap.unbind(key);
      });
    };
  }, [reporterGlobalShortcutHandlers]);
};
