// @flow

import type { CreateOnKeyDown } from '../../types/plugin';
import type { ListPluginPropertyOptions } from './types';
import { isNodeTypeIn } from '../../utils/isNodeTypeIn';
import { Node, Range } from '../../core';

import isHotkey from 'is-hotkey';
import {
  findClosestBlockPathAtSelection,
  getDecreaseIndentHotkey,
  getIncreaseIndentHotkey,
  isListItemEmpty,
  getBulletedListHotkey,
  getNumberedListHotkey,
  getCurrentActiveListVariant,
} from './utils';
import { LIST_VARIANTS } from './constants';
import { insertParagraph } from '../paragraph/utils';
import { increaseIndent, decreaseIndent } from './functions';
import { toggleList } from './toggleFunctions';

export const onKeyDownLists: CreateOnKeyDown<ListPluginPropertyOptions> =
  ({ pluginID, setCurrentActiveListVariant }) =>
  (e, { editor }) => {
    const variant = getCurrentActiveListVariant(editor);
    const path = findClosestBlockPathAtSelection(editor);
    const node = Node.get(editor, path);

    if (isHotkey('enter', e) && isNodeTypeIn(editor, pluginID)) {
      if (isListItemEmpty(node)) {
        e.preventDefault();
        decreaseIndent(editor, variant);

        return true;
      }
    }

    if (isHotkey('shift+enter', e) && isNodeTypeIn(editor, pluginID)) {
      // only catering for collapsed selection to minimize edge cases
      if (variant != null && editor.selection && Range.isCollapsed(editor.selection)) {
        e.preventDefault();

        insertParagraph(editor);

        return true;
      }
    }

    if (isHotkey(getIncreaseIndentHotkey(), e)) {
      e.preventDefault();
      if (isNodeTypeIn(editor, pluginID)) {
        increaseIndent(editor, variant);
      }
    }

    if (isHotkey(getDecreaseIndentHotkey(), e)) {
      e.preventDefault();
      if (isNodeTypeIn(editor, pluginID)) {
        return decreaseIndent(editor, variant);
      }
    }

    if (isHotkey(getBulletedListHotkey(), e)) {
      e.preventDefault();
      toggleList(editor, variant, LIST_VARIANTS.ul);
    }

    if (isHotkey(getNumberedListHotkey(), e)) {
      e.preventDefault();
      toggleList(editor, variant, LIST_VARIANTS.ol);
    }
  };
