// @flow
import type { Layouts } from '../Viewer/StudyLoader/viewerLoaderState';
import type {
  StudyGroup,
  ViewportConfiguration,
  ViewportsConfigurations,
  DehydratedViewportsConfigurations,
} from './types';
import { DEFAULT_LAYOUT } from '../Viewer/StudyLoader/viewerLoaderState';
import { generateViewportId, viewportIdToConfig } from '../Viewer/viewerUtils';
import { mergeViewportsConfigurations, filterViewportsConfigurations } from './manipulators';

/**
 * Returns an empty viewport configuration list from the given viewers and layouts.
 * The list is an hashmap with each key set to a viewport identifier.
 */
export function generateEmptyViewportConfigurationList({
  windows,
  layouts,
}: {
  windows: $ReadOnlyArray<string>,
  layouts: Layouts,
}): ViewportsConfigurations {
  return windows
    .flatMap((windowId) => {
      const [rows, cols] = layouts?.[windowId] ?? DEFAULT_LAYOUT;
      return Array.from({ length: rows }).flatMap((_, rowIndex) =>
        Array.from({ length: cols }).flatMap((_, colIndex) =>
          generateViewportId(windowId, rowIndex, colIndex)
        )
      );
    })
    .reduce<ViewportsConfigurations>((acc, viewportId) => ({ ...acc, [viewportId]: null }), {});
}

function viewportToNumber(viewportId: string) {
  const [windowId, rowIndex, colIndex] = viewportId.split('-').slice(1);
  return Number(windowId) * 100 + Number(rowIndex) * 10 + Number(colIndex);
}

/**
 * Fills all the empty (null) viewports with a series from the provided list.
 * `groupedStudies` is an array of study tuples, where the first element contains
 * the primary studies, and any other element is a single-element tuple, one for
 * each comparison study.
 *
 * NOTE: in the future, priors may be grouped by case as well so the above may change.
 */
export function fillEmptyViewportsWithGroupedStudies({
  viewportsConfigurations,
  groupedStudies,
  defaults,
}: {
  viewportsConfigurations: ViewportsConfigurations,
  groupedStudies: StudyGroup[],
  defaults?: ?$Partial<ViewportConfiguration>,
}): ViewportsConfigurations {
  // We'll use this list to avoid filling a viewport with a series that is already hung
  const assignedSeries = Object.values(viewportsConfigurations)
    .map((viewportConfiguration) => viewportConfiguration?.series?.smid)
    .filter(Boolean);

  const list = groupedStudies
    .flatMap((group, groupIndex) =>
      group.flatMap((study) =>
        study.stackedFrames.flatMap((stack) => ({
          study,
          stack,
          comparisonIndex: groupIndex === 0 ? null : groupIndex,
          // TODO: remove this once we move away from series
          series: study.seriesList.find(
            (series) => stack.frames && series.smid === stack.frames[0].series.smid
          ),
        }))
      )
    )
    .filter(({ series }) => !assignedSeries.includes(series?.smid));

  const sortedEmptyViewports = Object.entries(viewportsConfigurations)
    .reduce<string[]>((acc, [viewportId, viewportConfiguration], index) => {
      if (viewportConfiguration != null) {
        return acc;
      }

      return [...acc, viewportId];
    }, [])
    .sort((a, b) => viewportToNumber(a) - viewportToNumber(b));

  const emptyViewports = sortedEmptyViewports.reduce<ViewportsConfigurations>(
    (acc, viewportId, index) => ({
      ...acc,
      [viewportId]: list[index] != null ? { ...defaults, ...list[index] } : null,
    }),
    {}
  );

  return Object.fromEntries(
    Object.entries(viewportsConfigurations).map(([viewportId, viewportConfiguration]) => {
      if (viewportConfiguration != null) {
        return [viewportId, (viewportConfiguration: ViewportConfiguration)];
      }
      return [viewportId, emptyViewports[viewportId]];
    })
  );
}

/**
 * Checks if the viewport will be visible in the viewer layout.
 */
function isViewportInLayout(windows: $ReadOnlyArray<string>, layouts: Layouts) {
  return ([viewportId, viewportConfiguration]: [string, ?ViewportConfiguration]) => {
    const { windowId, colIndex, rowIndex } = viewportIdToConfig(viewportId);
    return (
      windows.includes(windowId) &&
      (layouts?.[windowId] ?? DEFAULT_LAYOUT)[0] > rowIndex &&
      (layouts?.[windowId] ?? DEFAULT_LAYOUT)[1] > colIndex
    );
  };
}

type GenerateViewportsConfigurationsArgs = {
  defaults: ?$Partial<ViewportConfiguration>,
  groupedStudies: StudyGroup[],
  layouts: Layouts,
  windows: $ReadOnlyArray<string>,
  viewportsConfigurations: ?ViewportsConfigurations | ?DehydratedViewportsConfigurations,
  hangingProtocolViewportsConfiguration: ?ViewportsConfigurations,
  isPreviewingHangingProtocol: boolean,
};

export function generateViewportsConfigurations({
  defaults,
  groupedStudies,
  layouts,
  windows,
  viewportsConfigurations,
  hangingProtocolViewportsConfiguration,
  isPreviewingHangingProtocol,
}: GenerateViewportsConfigurationsArgs): ViewportsConfigurations {
  const baseViewportsConfigurations: ViewportsConfigurations =
    hangingProtocolViewportsConfiguration != null
      ? // If we have a hanging protocol, we use it to generate the viewport configurations.
        fillEmptyViewportsWithGroupedStudies({
          viewportsConfigurations: mergeViewportsConfigurations(
            generateEmptyViewportConfigurationList({ layouts, windows }),
            hangingProtocolViewportsConfiguration
          ),
          groupedStudies,
          defaults,
        })
      : // Otherwise, we generate a list of empty viewport configurations using the provided data
        // we'll fill them with series on the next step.
        generateEmptyViewportConfigurationList({
          layouts,
          windows,
        });

  // Let's fill any empty viewport with a series, this already ensures no duplicate series
  // are hung on the two different viewports.
  const filledViewportsConfigurations = filterViewportsConfigurations(
    fillEmptyViewportsWithGroupedStudies({
      viewportsConfigurations: baseViewportsConfigurations,
      groupedStudies,
      defaults,
    }),
    isViewportInLayout(windows, layouts)
  );

  const availableSeries = groupedStudies.flatMap((studyGroup) =>
    studyGroup.flatMap((study) => study.seriesList.flatMap((series) => series.smid))
  );

  // Filter the configuration to only include viewports that have a series
  // currently loaded in the viewer and that where dropped by a user.
  const onlyCurrentSeriesViewportsConfigurations =
    viewportsConfigurations != null
      ? filterViewportsConfigurations(
          viewportsConfigurations,
          ([viewportId, viewportConfiguration]) =>
            availableSeries.includes(viewportConfiguration?.series?.smid) ||
            viewportConfiguration?.wasDropped === true // May force an empty viewport when setting next batch
        )
      : null;

  // Remove any viewports pulled from the recoil state that don't match active viewer windows and layouts
  const onlyActiveWindowsViewportsConfigurations =
    onlyCurrentSeriesViewportsConfigurations != null
      ? filterViewportsConfigurations(
          onlyCurrentSeriesViewportsConfigurations,
          isViewportInLayout(windows, layouts)
        )
      : null;

  // Drop from the atom-persisted configuration anything that is not directly
  // matching the user-intent or the fallback HP.
  const cleanedFromNotDroppedViewportsConfigurations =
    onlyActiveWindowsViewportsConfigurations != null
      ? filterViewportsConfigurations(
          onlyActiveWindowsViewportsConfigurations,
          ([viewportId, viewportConfiguration]) => {
            // If the viewport configuration is null we can DROP it
            if (viewportConfiguration == null) {
              return false;
            }
            // If it was dropped by the user we want to KEEP it
            // If it was dragged by the user we want to UPDATE it
            if (
              viewportConfiguration.wasDropped === true ||
              viewportConfiguration.wasDragged === true
            ) {
              return true;
            }

            // If it was not dropped by the user but it's displaying the same
            // series then we want to KEEP it
            const seriesSmid = viewportConfiguration.series?.smid;
            const incomingViewportConfiguration = filledViewportsConfigurations[viewportId];
            if (incomingViewportConfiguration?.series?.smid === seriesSmid) {
              return true;
            }

            // Otherwise we want to DROP it
            return false;
          }
        )
      : null;

  const mergedViewportsConfigurations = mergeViewportsConfigurations(
    filledViewportsConfigurations,
    // Get the viewport configurations defined by our user
    !isPreviewingHangingProtocol ? cleanedFromNotDroppedViewportsConfigurations : null
  );

  return mergedViewportsConfigurations;
}
