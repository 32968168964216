// @flow
import { useRef, useState, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { BroadcastChannel } from 'broadcast-channel';
import { WORKLIST_UPDATE } from 'config/constants';
import { ReportStatus, reportStatusState } from '../state';
import { getCountdownSeconds } from 'utils/report';

export const worklistUpdateBc: BroadcastChannel<string> = new BroadcastChannel<string>(
  WORKLIST_UPDATE
);

type UseCountdownOpts = {
  worklistItem: $ReadOnly<{ smid: string, ... }>,
  onStart?: () => void,
  onCancel?: () => void,
};

type UseCountdownReturn = {
  cancelCountdown: (v?: { request: ?boolean }) => Promise<void>,
  clearTimer: () => void,
  countdownSeconds: null | number,
  setCountdownSeconds: (((null | number) => null | number) | null | number) => void,
  startCountdown: (v?: { submittedAt: ?string | ?Date }) => Promise<void>,
};

export const useCountdown = ({
  worklistItem,
  onStart = () => {},
  onCancel = () => {},
}: UseCountdownOpts): UseCountdownReturn => {
  const setStatus = useSetRecoilState(reportStatusState);
  const [countdownSeconds, setCountdownSeconds] = useState(null);
  const countdownIntervalRef = useRef();

  const clearTimer = useCallback(() => {
    setCountdownSeconds(null);
    clearInterval(countdownIntervalRef.current);
  }, []);

  const changeTime = useCallback((submittedAt: string | Date) => {
    setCountdownSeconds(getCountdownSeconds(submittedAt));
  }, []);

  const startCountdown = useCallback(
    async ({ submittedAt }: { submittedAt?: ?(string | Date) } = {}) => {
      clearTimer();
      onStart();

      setStatus((status) =>
        status === ReportStatus.AddendumAdd
          ? ReportStatus.ProvisionalAddendumSubmit
          : ReportStatus.ProvisionalSubmit
      );

      const submittedAtWithFallback = submittedAt != null ? submittedAt : new Date().toString();

      changeTime(submittedAtWithFallback);

      // interval every 500ms to prevent edge cases to do with rounding errors with timestamp-differences
      countdownIntervalRef.current = setInterval(() => changeTime(submittedAtWithFallback), 500);

      worklistUpdateBc.postMessage(worklistItem.smid);
    },
    [worklistItem, setStatus, onStart, clearTimer, changeTime]
  );

  const cancelCountdown = useCallback(
    async ({ request = true }: { request?: ?boolean } = {}) => {
      clearTimer();
      onCancel();

      worklistUpdateBc.postMessage(worklistItem.smid);
    },
    [worklistItem, onCancel, clearTimer]
  );

  return {
    countdownSeconds,
    setCountdownSeconds,
    startCountdown,
    cancelCountdown,
    clearTimer,
  };
};
