// @flow

import { Element } from '../core';
import type { ElementPlugin } from '../plugins';
import { ElementComponent } from './Element';
import { TextComponent } from './Text';
import type { RendererRenderElement, RendererRenderText } from './types';

export type ChildrenProps = $ReadOnly<{
  node: ElementPlugin,
  renderElement: RendererRenderElement,
  renderText: RendererRenderText,
}>;

export const Children = ({ node, renderElement, renderText }: ChildrenProps): React$Node => {
  const children = node.children.map((n, i) => {
    if (Element.isElement(n)) {
      return (
        // $FlowIgnore[incompatible-type] Need type guards in flow
        <ElementComponent key={i} node={n} renderElement={renderElement} renderText={renderText} />
      );
    } else {
      // $FlowIgnore[incompatible-type] Need type guards in flow
      // $FlowIgnore[prop-missing]
      return <TextComponent key={i} node={n} renderText={renderText} />;
    }
  });

  return children;
};
