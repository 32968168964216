// @flow
import { useRecoilState } from 'recoil';
import { previousHangingProtocolState } from './state';
import type { HangingProtocol } from './useHangingProtocol';
import { usePreviewHangingProtocolConfiguration } from './usePreviewHangingProtocol';

export type UsePreviousHangingProtocol = {
  previousHangingProtocol: ?HangingProtocol,
  savePreviousHangingProtocol: () => void,
};

export const usePreviousHangingProtocol = (): UsePreviousHangingProtocol => {
  const previewedHangingProtocol = usePreviewHangingProtocolConfiguration();
  const [previousHangingProtocol, setPreviousHangingProtocol] = useRecoilState(
    previousHangingProtocolState
  );
  const savePreviousHangingProtocol = () => setPreviousHangingProtocol(previewedHangingProtocol);
  return {
    previousHangingProtocol,
    savePreviousHangingProtocol,
  };
};
