// @flow
import { Icon } from 'common/ui/Icon';
import type { PickerEvent } from '../modules/usePicker';
import { useHTMLActionFromPickerClick } from '../modules/useHTMLActionFromPickerClick';

type TriangleProps = {
  id: string,
  expanded?: boolean,
  onClick?: (event: PickerEvent) => void,
};

export const Expander = ({
  id,
  onClick,
  visible = true,
  expanded = false,
}: TriangleProps): React$Node => {
  const ref = useHTMLActionFromPickerClick({ id, onClick });

  return (
    <span ref={ref}>
      <Icon name={expanded ? 'expand_vertical_chevron' : 'collapse_vertical_chevron'}></Icon>
    </span>
  );
};
