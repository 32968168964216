// @flow

import { Range, Editor, Transforms, Path, Element, Point, ReactEditor, Node } from '../core';
import type {
  EditorType,
  RangeType,
  NodeType,
  NodeEntry,
  AncestorType,
  LocationType,
} from '../core';
import { BRACKET_TYPES, isSquareBracketType, getNavigationNodeTypes } from '../constants';

// $FlowFixMe[untyped-import] (automated-migration-2022-01-19)
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { getLowestBracket } from './getLowestBracket';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';
import { HeadingLevel } from '../plugins/heading/constants';
import equals from 'lodash/fp/equals';
import type { ElementPluginID } from '../plugins/types';

export const getNextNestedNavigableBracket = (
  editor: EditorType,
  currentBracketNode: NodeEntry<>,
  navigationNodeTypes: Array<ElementPluginID>
): ?NodeEntry<> => {
  for (const [bracketChildNode, bracketChildPath] of Node.children(editor, currentBracketNode[1])) {
    if (navigationNodeTypes.includes(bracketChildNode.type)) {
      return [bracketChildNode, bracketChildPath];
    }
  }

  return null;
};

export const getNextSiblingNavigableBracket = (
  editor: EditorType,
  selection: RangeType,
  currentBracketNode: ?NodeEntry<>,
  navigationNodeTypes: Array<ElementPluginID>
): ?NodeEntry<> => {
  const fromSelectionNodeGenerator = Editor.nodes(editor, {
    at: {
      anchor: Editor.after(editor, Range.end(selection)) ?? Editor.end(editor, []),
      focus: Editor.end(editor, []),
    },
    depth: currentBracketNode != null ? currentBracketNode[1].length : undefined,
    match: (n: NodeType) => navigationNodeTypes.includes(n.type),
  });

  if (currentBracketNode == null) {
    const nextNodeEntry = fromSelectionNodeGenerator.next().value;
    if (nextNodeEntry != null) {
      return nextNodeEntry;
    }
  } else {
    for (const [nextNode, nextNodePath] of fromSelectionNodeGenerator) {
      if (currentBracketNode != null && Path.compare(currentBracketNode[1], nextNodePath) === -1) {
        return [nextNode, nextNodePath];
      }
    }
  }

  const firstBracketGeneratorEntry = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => navigationNodeTypes.includes(n.type),
  }).next();

  if (!firstBracketGeneratorEntry.done) {
    return firstBracketGeneratorEntry.value;
  }

  return null;
};

export const getNextNonOverlappingBracket = (
  editor: EditorType,
  selection: RangeType,
  nextBracketEntry: ?NodeEntry<>
): ?NodeEntry<> => {
  while (
    nextBracketEntry != null &&
    (Path.isAncestor(nextBracketEntry[1], Range.start(selection).path) ||
      Path.isAncestor(nextBracketEntry[1], Range.end(selection).path))
  ) {
    nextBracketEntry = Editor.next<NodeType>(editor, {
      match: (n: NodeType) => Element.isElement(n) && BRACKET_TYPES.includes(n.type),
      at: nextBracketEntry[1],
    });
  }

  return nextBracketEntry;
};

/**
 * Navigating nested fields will always go from the least nested to the most nested field (shallow -> deep).
 * If there are no nested fields, this simply means going to the next bracket in the report. With nested fields, it gets
 * more complicated. For example:
 *
 *    <cursor> [A [B] C [D [E] ] ] [F]
 *
 * Continually navigating to the next bracket will result in the following sequence of selection:
 *
 *    [A [B] C [D [E] ] ]
 *
 *    [B]
 *
 *    [D [E] ]
 *
 *    [E]
 *
 *    [F]
 *
 *    [A [B] C [D [E] ] ]
 */

export const getNextBracketWithNesting = (
  editor: EditorType,
  selection: RangeType,
  navigationNodeTypes: Array<ElementPluginID>
): ?NodeEntry<> => {
  const currentBracketNode = Editor.above(editor, {
    at: Range.start(selection),
    match: (n: NodeType) => navigationNodeTypes.includes(n.type),
    mode: 'lowest',
  });

  // If the bracket contains at least one navigable node type as a child, the
  // first nested bracket should be selected
  if (
    currentBracketNode != null &&
    // $FlowFixMe[incompatible-use] this array is an array of nodes
    currentBracketNode[0].children.some((node) => navigationNodeTypes.includes(node.type))
  ) {
    return getNextNestedNavigableBracket(
      editor,
      // $FlowFixMe[incompatible-call] this array is an array of nodes
      currentBracketNode,
      navigationNodeTypes
    );
  }

  // If the there are no nested brackets left, or if we were never in a bracket to begin
  // with, get the next navigable bracket that is a sibling at the same level as the current bracket
  return getNextSiblingNavigableBracket(editor, selection, currentBracketNode, navigationNodeTypes);
};

export const getNextBracket = ({
  editor,
  location,
  skip = false,
  ignoreMergeFieldsInNavigation = false,
}: {
  editor: EditorType,
  location?: RangeType,
  skip?: boolean,
  ignoreMergeFieldsInNavigation?: boolean,
}): ?NodeEntry<> => {
  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFieldsInNavigation);
  const selection = location ?? editor.selection;

  if (selection == null) {
    return null;
  }

  const nextBracketEntry = getNextBracketWithNesting(editor, selection, navigationNodeTypes);

  return nextBracketEntry != null
    ? nextBracketEntry
    : getLowestBracket(editor, { ignoreMergeFields: ignoreMergeFieldsInNavigation });
};

/**
 * Moves to the next bracket in an editor given the current selection. If the selection is inside
 * a bracket at the non-directional edge, it will highlight the current bracket before moving to the
 * next.
 */
export const selectNextBracket = (
  editor: EditorType,
  {
    ignoreMergeFieldsInNavigation,
    isVoiceCommand,
  }: {
    ignoreMergeFieldsInNavigation?: boolean,
    isVoiceCommand?: boolean,
  } = {
    ignoreMergeFieldsInNavigation: false,
    isVoiceCommand: false,
  }
): boolean => {
  const selection = editor.selection;

  if (selection == null) return false;

  const anchorPath = selection.anchor.path;
  const focusPath = selection.focus.path;

  const targetEntry = getNextBracket({
    editor,
    location: selection,
    ignoreMergeFieldsInNavigation,
  });

  // If no target entry found, then it's a noop. Can happen if the user has no fields
  if (!targetEntry) return false;

  const [targetNode, targetEntryPath] = targetEntry;
  Transforms.select(editor, targetEntryPath);

  // if the selection was within the target bracket, it will still reference the same target bracket, so we should continue to the next one
  if (
    isVoiceCommand === true &&
    equals(editor.selection?.anchor.path, anchorPath) &&
    equals(editor.selection?.focus.path, focusPath)
  ) {
    return selectNextBracket(editor, {
      ignoreMergeFieldsInNavigation,
    });
  }

  try {
    // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
    const target = ReactEditor.toDOMNode(editor, targetNode);
    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'nearest',
    });
  } catch (error) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }

  return true;
};

export const selectNamedField = (editor: EditorType, name: ?string): boolean => {
  if (name == null) {
    return false;
  }
  const fields = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => isSquareBracketType(String(n.type)) && n.name === name,
  });

  const targetEntry = fields.next().value;

  if (targetEntry == null) return false;

  const [targetNode, targetEntryPath] = targetEntry;
  Transforms.select(editor, targetEntryPath);

  try {
    // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
    const target = ReactEditor.toDOMNode(editor, targetNode);
    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'nearest',
    });
  } catch (error) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }
  return true;
};

/**
 * Based on the current position of our cursor, retrieve the previous heading section
 * where a heading section is defined as the nodes between the next two consecutive
 * headings from our position. Then, it selects in the section either the first bracket
 * in the section, or node if a bracket does not exist. If neither exist, then it places
 * the cursor next to the section's heading.
 *
 * For example, in the following report, where | is our cursor:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    [MRI of the entire spine without contrast.]
 *
 *    CLINICAL HISTORY:
 *
 *    |[Neck and back pain.]|
 *
 *    FINDINGS:
 *
 *    Medial patellar retinaculum is intact.
 *    --------------------------------------
 *
 * If we call this function, the state of the editor would change to:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    |[MRI of the entire spine without contrast.]|
 *
 *    CLINICAL HISTORY:
 *
 *    [Neck and back pain.]
 *
 *    FINDINGS:
 *
 *    Medial patellar retinaculum is intact.
 *    --------------------------------------
 *
 * If we call the function again, the state of the editor would change to:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    |[MRI of the entire spine without contrast.]|
 *
 *    CLINICAL HISTORY:
 *
 *    [Neck and back pain.]
 *
 *    FINDINGS:
 *
 *    |Medial patellar retinaculum is intact.
 *    --------------------------------------
 **/
export const selectPreviousHeadingSectionBracket = (
  editor: EditorType,
  { ignoreMergeFieldsInNavigation }: { ignoreMergeFieldsInNavigation?: boolean } = {
    ignoreMergeFieldsInNavigation: false,
  }
): ?NodeEntry<NodeType> | ?NodeEntry<AncestorType> => {
  const { selection } = editor;
  if (selection == null) {
    return null;
  }

  let topBorderNodeEntry = null;
  let bottomBorderNodeEntry = null;

  const currentNode = Editor.node(editor, selection, { depth: 1 });
  const bottomBorderNode =
    currentNode[0].type === HEADING_PLUGIN_ID && currentNode[0].level === HeadingLevel.H1
      ? currentNode
      : Editor.previous<NodeType>(editor, { at: currentNode[1] });

  // Look for the closest previous heading to our current cursor location. This will serve as
  // the bottom of the previous section.
  const rangeEnd =
    bottomBorderNode != null ? Editor.point(editor, bottomBorderNode[1], { edge: 'end' }) : null;
  if (rangeEnd != null) {
    bottomBorderNodeEntry = Editor.previous<NodeType>(editor, {
      at: rangeEnd,
      match: (n: NodeType) =>
        Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
    });

    if (bottomBorderNodeEntry != null) {
      // The next closest previous heading to our bottom border will serve as the top border of our section.
      // This is null if our bottom border is the first heading in the editor
      topBorderNodeEntry = Editor.previous<NodeType>(editor, {
        at: Editor.start(editor, bottomBorderNodeEntry[1]),
        match: (n: NodeType) =>
          Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
      });
    }
  }

  // If our top border is null, that means that we are currently in the very first section of the
  // editor, and we need to wrap around to the end, with the last heading in the editor as our
  // top border, and the end of the editor as our bottom border.
  if (topBorderNodeEntry == null) {
    topBorderNodeEntry = Editor.previous<NodeType>(editor, {
      at: Editor.end(editor, []),
      match: (n: NodeType) =>
        Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
    });

    bottomBorderNodeEntry =
      [...Editor.levels(editor, { at: Editor.end(editor, []) })].length > 1
        ? // $FlowFixMe[incompatible-type] (automated-migration-2023-01-22)
          Editor.parent(editor, Editor.end(editor, []))
        : Editor.node(editor, Editor.end(editor, []));
    if (bottomBorderNodeEntry != null) {
      bottomBorderNodeEntry = Editor.last(editor, bottomBorderNodeEntry[1]);
    }
  }

  // This will only happen if there isn't a single heading in the editor
  if (topBorderNodeEntry == null || bottomBorderNodeEntry == null) {
    return;
  }

  // If there is only one heading in the editor, we will only have a top border.
  // As a result, we end up searching until the end of the editor for a bracket.
  const endOfSectionPoint =
    bottomBorderNodeEntry != null
      ? Editor.point(editor, bottomBorderNodeEntry[1])
      : Editor.edges(editor, [])[1];

  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFieldsInNavigation);
  const bracketEntry = [
    ...Editor.nodes<NodeType>(editor, {
      at: {
        anchor: Editor.point(editor, topBorderNodeEntry[1]),
        focus: endOfSectionPoint,
      },
      match: (n: NodeType) => Element.isElement(n) && navigationNodeTypes.includes(n.type),
      mode: 'lowest',
    }),
  ];

  const afterTopBorderPath = Editor.after(editor, topBorderNodeEntry[1]);
  const fallbackNodePath =
    afterTopBorderPath == null ||
    (Point.equals(afterTopBorderPath, Editor.point(editor, bottomBorderNodeEntry[1])) &&
      bottomBorderNodeEntry[0].type === HEADING_PLUGIN_ID)
      ? Editor.end(editor, topBorderNodeEntry[1])
      : afterTopBorderPath;

  const selectionNodePath = bracketEntry.length !== 0 ? bracketEntry[0][1] : fallbackNodePath;
  Transforms.select(editor, selectionNodePath);

  return Editor.node(editor, selectionNodePath);
};

/**
 * Based on the current position of our cursor, retrieve the next heading section
 * where a heading section is defined as the nodes between the next two consecutive
 * headings from our position. Then, it selects in the section either the first bracket
 * in the section, or node if a bracket does not exist. If neither exist, then it places
 * the cursor next to the section's heading.
 *
 * For example, in the following report, where | is our cursor:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    [MRI of the entire spine without contrast.]
 *
 *    CLINICAL HISTORY:
 *
 *    |[Neck and back pain.]|
 *
 *    FINDINGS:
 *
 *    Medial patellar retinaculum is intact.
 *    --------------------------------------
 *
 * If we call this function, the state of the editor would change to:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    [MRI of the entire spine without contrast.]
 *
 *    CLINICAL HISTORY:
 *
 *    [Neck and back pain.]
 *
 *    FINDINGS:
 *
 *    |Medial patellar retinaculum is intact.
 *    --------------------------------------
 *
 * If we call the function again, the state of the editor would change to:
 *
 *    --------------------------------------
 *    EXAMINATION:
 *
 *    |[MRI of the entire spine without contrast.]|
 *
 *    CLINICAL HISTORY:
 *
 *    [Neck and back pain.]
 *
 *    FINDINGS:
 *
 *    Medial patellar retinaculum is intact.
 *    --------------------------------------
 **/
export const selectNextHeadingSectionBracket = (
  editor: EditorType,
  {
    startPoint,
    fallbackPoint,
    ignoreMergeFieldsInNavigation,
  }: {
    startPoint?: LocationType,
    fallbackPoint?: 'start' | 'end',
    ignoreMergeFieldsInNavigation?: boolean,
  } = {
    ignoreMergeFieldsInNavigation: false,
  }
): ?NodeEntry<NodeType> => {
  const { selection: editorSelection } = editor;
  const selection = startPoint ?? editorSelection;
  if (selection == null) {
    return null;
  }

  let topBorderNodeEntry = null;
  let bottomBorderNodeEntry = null;

  const currentRootNode = Editor.node(editor, selection, { depth: 1 });
  const nextRootNode = Editor.next<NodeType>(editor, { at: currentRootNode[1] });
  // Look for a heading node between end of current selection and the end of the editor
  const rangeStart = nextRootNode != null ? Editor.point(editor, nextRootNode[1]) : null;
  if (rangeStart != null) {
    // Get the next closest heading, which will be the top border of the next section
    topBorderNodeEntry = Editor.next<NodeType>(editor, {
      at: rangeStart,
      match: (n: NodeType) =>
        Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
    });

    if (topBorderNodeEntry != null) {
      // The next closest heading to our top border will serve as the bottom border of our section.
      // This is null if our top border was the last heading in the editor
      bottomBorderNodeEntry = Editor.next<NodeType>(editor, {
        at: Editor.end(editor, topBorderNodeEntry[1]),
        match: (n: NodeType) =>
          Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
      });
    }
  }

  // If our top border is null, that means that we are currently inside the last section of
  // the editor, and so we should wrap around and have the first heading in the editor be
  // our top border
  if (topBorderNodeEntry == null) {
    topBorderNodeEntry = Editor.next<NodeType>(editor, {
      at: Editor.start(editor, []),
      match: (n: NodeType) =>
        Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
    });

    if (topBorderNodeEntry != null) {
      // We then find the next closest heading to the first heading in the editor. This will
      // be null if there is only one heading in the editor
      bottomBorderNodeEntry = Editor.next<NodeType>(editor, {
        at: Editor.end(editor, topBorderNodeEntry[1]),
        match: (n: NodeType) =>
          Element.isElement(n) && n.type === HEADING_PLUGIN_ID && n.level === HeadingLevel.H1,
      });
    }
  }

  // This will only happen if there isn't a single heading in the editor
  if (topBorderNodeEntry == null) {
    return;
  }

  // If there is only one heading in the editor, we will only have a top border.
  // As a result, we end up searching until the end of the editor for a bracket.
  const endOfSectionPoint =
    bottomBorderNodeEntry != null
      ? Editor.point(editor, bottomBorderNodeEntry[1])
      : Editor.end(editor, []);

  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFieldsInNavigation);
  const bracketEntry = [
    ...Editor.nodes<NodeType>(editor, {
      at: {
        anchor: Editor.point(editor, topBorderNodeEntry[1]),
        focus: endOfSectionPoint,
      },
      match: (n: NodeType) => Element.isElement(n) && navigationNodeTypes.includes(n.type),
      mode: 'lowest',
    }),
  ];

  // If there is no bracket within the section we found, then we grab either the first node in the
  // section. If the section is literally just the heading and nothing else, then we place
  // the cursor right after the heading
  const afterTopBorderPath = Editor.after(editor, topBorderNodeEntry[1]);
  const endOfTopBorderPath = Editor.end(editor, topBorderNodeEntry[1]);

  let fallbackNodePath = afterTopBorderPath;

  if (fallbackPoint === 'end' && bottomBorderNodeEntry != null) {
    fallbackNodePath = Editor.before(editor, bottomBorderNodeEntry[1]) ?? endOfTopBorderPath;
  } else if (
    afterTopBorderPath == null ||
    (Point.equals(afterTopBorderPath, endOfSectionPoint) && bottomBorderNodeEntry != null)
  ) {
    fallbackNodePath = endOfTopBorderPath;
  }

  const selectionNodePath =
    bracketEntry.length !== 0 ? bracketEntry[0][1] : (fallbackNodePath ?? endOfTopBorderPath);

  Transforms.select(editor, selectionNodePath);

  return Editor.node(editor, selectionNodePath);
};

/**
 * Moves to the first bracket of the previous section given the current selection. If there
 * is no bracket in the previous section, it will move to the first child in the previous section.
 */
export const selectPreviousSection = (
  editor: EditorType,
  ignoreMergeFieldsInNavigation?: boolean
): boolean => {
  if (editor.selection == null) {
    return false;
  }

  const matchNodeEntry = selectPreviousHeadingSectionBracket(editor);
  if (matchNodeEntry == null) {
    return false;
  }

  const [matchNode] = matchNodeEntry;

  try {
    // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
    const target = ReactEditor.toDOMNode(editor, matchNode);
    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'nearest',
    });
  } catch (error) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }

  return true;
};

/**
 * Moves to the first bracket of the next section given the current selection. If there
 * is no bracket in the next section, it will move to the first child in the next section.
 */
export const selectNextSection = (
  editor: EditorType,
  { ignoreMergeFieldsInNavigation }: { ignoreMergeFieldsInNavigation?: boolean } = {
    ignoreMergeFieldsInNavigation: false,
  }
): boolean => {
  if (editor.selection == null) {
    return false;
  }

  const matchNodeEntry = selectNextHeadingSectionBracket(editor, { ignoreMergeFieldsInNavigation });

  if (matchNodeEntry == null) {
    return false;
  }

  const [matchNode] = matchNodeEntry;

  try {
    // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
    const target = ReactEditor.toDOMNode(editor, matchNode);
    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'nearest',
    });
  } catch (error) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }

  return true;
};

export const getPreviousBracket = (
  editor: EditorType,
  location?: RangeType,
  skip?: boolean
): ?NodeEntry<> => {
  const selection = location ?? editor.selection;
  if (selection == null) {
    return null;
  }

  let previousNodeEntry = Editor.previous<NodeType>(editor, {
    match: (n: NodeType) => Element.isElement(n) && BRACKET_TYPES.includes(n.type),
    at: selection,
  });

  if (skip === true) {
    while (
      previousNodeEntry != null &&
      (Path.isAncestor(previousNodeEntry[1], Range.start(selection).path) ||
        Path.isAncestor(previousNodeEntry[1], Range.end(selection).path))
    ) {
      previousNodeEntry = Editor.previous<NodeType>(editor, {
        match: (n: NodeType) => Element.isElement(n) && BRACKET_TYPES.includes(n.type),
        at: previousNodeEntry[1],
        mode: 'lowest',
      });
    }
  }
  return previousNodeEntry ? previousNodeEntry : getLowestBracket(editor, { reverse: true });
};

/**
 * Moves to the previous bracket in an editor given the current selection. If the selection is inside
 * a bracket at the non-directional edge, it will highlight the current bracket before moving to the
 * next.
 */
export const selectPreviousBracket = (
  editor: EditorType,
  {
    ignoreMergeFieldsInNavigation,
    isVoiceCommand,
  }: { ignoreMergeFieldsInNavigation?: boolean, isVoiceCommand?: boolean } = {
    ignoreMergeFieldsInNavigation: false,
    isVoiceCommand: false,
  }
): boolean => {
  const navigationNodeTypes = getNavigationNodeTypes(ignoreMergeFieldsInNavigation);

  if (editor.selection == null) return false;

  const anchorPath = editor.selection.anchor.path;
  const focusPath = editor.selection.focus.path;

  const previousNodeEntry = Editor.previous<NodeType>(editor, {
    match: (n: NodeType) => Element.isElement(n) && navigationNodeTypes.includes(n.type),
  });
  const targetEntry = previousNodeEntry
    ? previousNodeEntry
    : getLowestBracket(editor, { reverse: true, ignoreMergeFields: ignoreMergeFieldsInNavigation });
  // If no target entry found, then it's a noop. Can happen if the user has no fields
  if (!targetEntry) return false;
  const [targetNode, targetEntryPath] = targetEntry;
  Transforms.select(editor, targetEntryPath);

  // if the selection was within the target bracket, it will still reference the same target bracket, so we should continue to the previous one
  if (
    isVoiceCommand === true &&
    equals(editor.selection?.anchor.path, anchorPath) &&
    equals(editor.selection?.focus.path, focusPath)
  ) {
    return selectPreviousBracket(editor, { ignoreMergeFieldsInNavigation });
  }

  try {
    // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
    const target = ReactEditor.toDOMNode(editor, targetNode);
    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'nearest',
    });
  } catch (error) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }

  return true;
};
