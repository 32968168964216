// @flow
import type { MediaDeviceInfo } from '../useMicrophone/useMicrophone';

import { NOOP } from 'config/constants';
import { useEffect, useState } from 'react';

type UseMediaDevicesState = {
  devices: MediaDeviceInfo[],
  loaded: boolean,
};

export const useMediaDevices = (): UseMediaDevicesState => {
  const [state, setState] = useState<UseMediaDevicesState>({
    devices: [],
    loaded: false,
  });

  useEffect(() => {
    const { mediaDevices } = navigator;
    if (mediaDevices == null) return;

    let mounted = true;

    const onChange = () => {
      mediaDevices
        .enumerateDevices()
        .then((devices) => {
          if (mounted) {
            setState({
              devices: devices.map(({ deviceId, groupId, kind, label }) => ({
                deviceId,
                groupId,
                kind,
                label,
              })),
              loaded: true,
            });
          }
        })
        .catch(NOOP);
    };

    mediaDevices.addEventListener('devicechange', onChange);
    onChange();

    return () => {
      mounted = false;
      mediaDevices.removeEventListener('devicechange', onChange);
    };
  }, []);

  return state;
};
