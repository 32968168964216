// @flow

import { createContext, useContext } from 'react';
import colors from 'styles/colors';

export type AnnotationColors = { defaultColor: string, hoverColor: string };

export const AnnotationColorsContext: React$Context<AnnotationColors> = createContext({
  defaultColor: colors.ugcAnnotation,
  hoverColor: colors.ugcAnnotationHover,
});

export function useAnnotationColors(): AnnotationColors {
  return useContext(AnnotationColorsContext);
}
