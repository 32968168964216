// @flow

import type { CreateOnKeyDown } from '../../types';
import type { GeneralKeyboardControlsPluginPropertyOptions } from './types';
import {
  selectNextBracket,
  selectPreviousBracket,
  createEditorWarning,
  selectNextHeadingSectionBracket,
  selectPreviousHeadingSectionBracket,
} from '../../utils';
import { TEXT_SOURCE_STYLING_PLUGIN_ID } from '../textSourceStyling/types';
import isHotkey from 'is-hotkey';
import { logger } from 'modules/logger';

export const onKeyDownGeneralKeyboardControls: CreateOnKeyDown<
  GeneralKeyboardControlsPluginPropertyOptions,
> =
  ({ pluginID, onKeyDownOverride, ignoreMergeFieldsInNavigation }) =>
  (...args) => {
    const [e, { editor }] = args;

    const overrideReturnValue = onKeyDownOverride(...args);

    if (overrideReturnValue === true) {
      if (e.defaultPrevented === false) {
        createEditorWarning(
          'You handled an event without preventing default. Slate will handle this event unless you call event.preventDefault(). Calling this under the hood for you. This needs to be fixed at the callsite!'
        );

        e.preventDefault();
      }

      return true;
    }

    if (isHotkey('tab', e) && editor.selection) {
      e.preventDefault();
      if (!selectNextBracket(editor, { ignoreMergeFieldsInNavigation })) {
        selectNextHeadingSectionBracket(editor, {
          fallbackPoint: 'end',
          ignoreMergeFieldsInNavigation,
        });
      }
      return true;
    }

    if (isHotkey('shift+tab', e) && editor.selection) {
      e.preventDefault();
      if (!selectPreviousBracket(editor, { ignoreMergeFieldsInNavigation })) {
        selectPreviousHeadingSectionBracket(editor, { ignoreMergeFieldsInNavigation });
      }
      return true;
    }

    const isTypingKey = (event: KeyboardEvent) => {
      const ignoredKeys = [
        'Control',
        'Alt',
        'Meta',
        'Shift',
        'Tab',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'CapsLock',
        'Escape',
        'PageUp',
        'PageDown',
        'Home',
        'End',
      ];

      return !ignoredKeys.includes(event.key) && !event.ctrlKey && !event.altKey && !event.metaKey;
    };

    // TODO: in the future, this will instead be adding the typed mark
    if (e.key.length === 1 && isTypingKey(e)) {
      try {
        editor.removeMark(TEXT_SOURCE_STYLING_PLUGIN_ID);
      } catch (error) {
        logger.error(
          '[onKeyDownGeneralKeyboardControls] Error occurred removing text source styling mark',
          error
        );
      }
    }
  };
