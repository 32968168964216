// @flow

import isHotkey from 'is-hotkey';
import { Editor, Transforms } from 'slate';
import type { CreateOnKeyDown } from '../../types';
import { find } from '../../utils';
import type { DeepLinkPluginPropertyOptions } from './types';
import type { NodeType } from 'slate';
import { ReactEditor } from 'slate-react';
import { DEEP_LINK_VARIANT_TYPES } from './constants';

export const onKeyDown: CreateOnKeyDown<DeepLinkPluginPropertyOptions> =
  (options) =>
  (e, { editor, nodeState }) => {
    const { pluginID, getIsEditingStudyNode } = options;
    // If the user is at the right outside edge of a section header node, adjust the selection to be
    // the left outside edge (skip over the node)
    if (isHotkey('left', e) && editor.selection != null) {
      const { selection } = editor;
      const matchEntryBefore = find(editor, (n: NodeType) => n.type === pluginID, {
        at: Editor.before(editor, selection),
      });

      if (matchEntryBefore != null) {
        if (
          matchEntryBefore[0].variant === DEEP_LINK_VARIANT_TYPES.STUDY &&
          getIsEditingStudyNode
        ) {
          try {
            // $FlowIgnore[prop-missing] ReactEditorType and Editor type are compatible
            const key = ReactEditor.findKey(editor, matchEntryBefore[0]);
            // $FlowIgnore[incompatible-call] key is a Key
            const isEditing = getIsEditingStudyNode(key);
            // don't delete the entire node if in editing mode
            if (isEditing) {
              return true;
            }
          } catch (e) {
            return true;
          }
        }
        const targetPoint = Editor.before(editor, matchEntryBefore[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }

    // If the user is at the left outside edge of a section header node, adjust the selection to be
    // the right outside edge (skip over the node)
    if (isHotkey('right', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryAfter = find(editor, (n: NodeType) => n.type === pluginID, {
        at: Editor.after(editor, selection),
      });

      if (matchEntryAfter != null) {
        if (matchEntryAfter[0].variant === DEEP_LINK_VARIANT_TYPES.STUDY && getIsEditingStudyNode) {
          try {
            // $FlowIgnore[prop-missing] ReactEditorType and Editor type are compatible
            const key = ReactEditor.findKey(editor, matchEntryAfter[0]);
            // $FlowIgnore[incompatible-call] key is a Key
            const isEditing = getIsEditingStudyNode(key);
            // don't delete the entire node if in editing mode
            if (isEditing) {
              return true;
            }
          } catch (e) {
            return true;
          }
        }
        const targetPoint = Editor.after(editor, matchEntryAfter[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }
  };
