// @flow

import { useRecoilState } from 'recoil';
import { emptyPlaceholderDialogState } from 'domains/reporter/Reporter/state';
import DialogConfirm from 'common/ui/DialogConfirm';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import { useFields } from 'domains/reporter/Reporter/Fields/Fields.Context';
import { moveSelectionToFirstEmptyPlaceholder } from 'domains/reporter/RichTextEditor/plugins/placeholder/utils';
import { EmptyPlaceholderDialogContent } from 'domains/reporter/RichTextEditor/plugins/placeholder/components/EmptyPlaceholderDialogContent';
import { DEFAULT_FIELDS_WARNING_MODAL_PROPS } from './constants';

export const EmptyPlaceholderFieldsWarning = (): React$Node => {
  const [{ editor }] = useSlateSingletonContext();
  const [{ content }, { handleSubmit: onSubmit }] = useFields();

  const [placeholderFieldDialogState, setPlaceholderFieldDialogState] = useRecoilState(
    emptyPlaceholderDialogState
  );

  const handleCloseEmptyMergeFieldsDialog = () => {
    setPlaceholderFieldDialogState({
      ...placeholderFieldDialogState,
      open: false,
    });

    if (editor != null) {
      moveSelectionToFirstEmptyPlaceholder(content, editor);
    }
  };

  return (
    <DialogConfirm
      {...DEFAULT_FIELDS_WARNING_MODAL_PROPS}
      open={placeholderFieldDialogState.open}
      slug="empty-merge-fields-dialog"
      onConfirm={async () => {
        setPlaceholderFieldDialogState({
          ...placeholderFieldDialogState,
          open: false,
        });
        await onSubmit({ force: true });
      }}
      confirmText="Yes, sign report"
      confirmButtonVariant="light"
      cancelText="No, edit merge fields"
      cancelButtonVariant="secondary"
      onCancel={handleCloseEmptyMergeFieldsDialog}
    >
      <EmptyPlaceholderDialogContent />
    </DialogConfirm>
  );
};
