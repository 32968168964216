// @flow
import { useEffect, useState } from 'react';
import { NAMESPACES, sendEvent } from 'modules/EventsManager/eventsManager';
import { nanoid } from 'nanoid';
import type { SnackBarIcon } from 'common/GenericSnackBar';
import { PAGE_TYPES } from '../utils/pageTypes';

type ReporterFeedbackToast = {
  enableNotification: boolean,
  message: string,
  icon: SnackBarIcon,
};

export const useReporterFeedbackToast = (
  threshold: boolean,
  {
    message = 'A problem has occurred.',
    icon = 'redWarning',
    enableNotification = false,
  }: ReporterFeedbackToast
): void => {
  const [toastID, setToastID] = useState<?string>(null);
  const [toastIsDisplayingForMinimumDuration, setToastIsDisplayingForMinimumDuration] =
    useState<boolean>(false);

  useEffect(() => {
    if (enableNotification === false) {
      return;
    }

    // If threshold exceeded and there is no toast, queue a toast
    if (threshold && toastID == null) {
      const newToastID = nanoid();
      sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
        type: 'showCrossTabNotification',
        payload: {
          toastKey: newToastID,
          targetPageTypes: [PAGE_TYPES.REPORTER, PAGE_TYPES.VIEWER],
          severity: 'default',
          icon,
          position: 'top-right',
          message,
          isUnique: true,
          autoHideDuration: null,
        },
      });
      setToastID(newToastID);

      // This part is a bit tricky. The toast should show for *at least* 4sec.
      setToastIsDisplayingForMinimumDuration(true);
      setTimeout(() => setToastIsDisplayingForMinimumDuration(false), 4000);
    }

    // If below the threshold and has been displayed *at least* 4sec, then clear the toast.
    if (!threshold && toastID != null && !toastIsDisplayingForMinimumDuration) {
      sendEvent(NAMESPACES.CROSS_TAB_NOTIFICATION, {
        type: 'closeCrossTabNotification',
        payload: {
          id: toastID,
        },
      });
      setToastID(null);
      setToastIsDisplayingForMinimumDuration(false);
    }
  }, [enableNotification, icon, message, threshold, toastID, toastIsDisplayingForMinimumDuration]);
};
