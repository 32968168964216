// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ReportPicklist, ReportPicklistOption } from 'generated/graphql';
import type { ComponentType } from 'react';
import type { AllReporterVariant } from '../../hooks/useReporter';

export const PICKLIST_PLUGIN_ID: 'picklist' = 'picklist';
export type PicklistPluginID = typeof PICKLIST_PLUGIN_ID;

export type PicklistPluginElement = $ReadOnly<{
  ...EditorElement,
  picklistID: string,
  type: PicklistPluginID,
  name?: string,
}>;

export type ReportPicklistBeforeSubmissionOptions = ReportPicklistOption;

export type ReportPicklistBeforeSubmission = $ReadOnly<{
  id: string,
  options: ReportPicklistBeforeSubmissionOptions[],
}>;

export type PicklistItem = ReportPicklist | (ReportPicklist | ReportPicklistBeforeSubmission);

export type PicklistPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,
  showPicklistOptionsInEditor?: boolean,
  onPicklistInsert?: (props: { +picklistID: string, ... }) => void,
  onPicklistCopy?: (props: { +picklistID: string, ... }) => PicklistItem,
  onPicklistRemove?: (props: { +picklistID: string, ... }) => void,
  onPicklistUpdate?: (props: {
    +picklistID: string,
    options: ReportPicklistBeforeSubmissionOptions[],
  }) => void,
  disableKeyDown?: boolean,
  variant?: AllReporterVariant,
}>;

export type PicklistPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...PicklistPluginOptions,
  showPicklistOptionsInEditor: boolean,
  pluginID: PicklistPluginID,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
}>;

// TODO(mwood23) Move this to PicklistSelector once it's converted to flow
export type NodeSelectorState = $ReadOnly<{
  isOpen: boolean,
  moveItemUp: () => void,
  moveItemDown: () => void,
  selectPicklistItem: () => void,
  resetActiveSelectedPicklistOption: () => void,
}>;
