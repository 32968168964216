// @flow

import { useCallback } from 'react';
import analytics from 'modules/analytics';
import { worklist } from 'modules/analytics/constants';
import { CASE_ACTIONS } from 'config/constants';
import { sendEvent, NAMESPACES } from 'modules/EventsManager';
import { useCurrentCase } from './useCurrentCase';
import { useSetRecoilState } from 'recoil';
import { draftReportWorklistSmid } from '../domains/reporter/Reporter/state';
import { useMutation } from '@apollo/client';
import {
  CHANGE_WORKLIST_ITEM_STATUS,
  GET_CURRENT_WORKLIST_REPORT,
  GET_WORKLIST_ITEM,
} from '../modules/Apollo/queries';
import type {
  GetWorklistItemQuery,
  GetWorklistItemQueryVariables,
  ChangeWorklistItemStatusMutation,
  ChangeWorklistItemStatusMutationVariables,
  GetCurrentWorklistReportQuery,
  GetCurrentWorklistReportQueryVariables,
} from 'generated/graphql';
import { logger } from 'modules/logger';
import useStudyIds from './useStudyIds';
import { useOpenCase } from './useOpenCase';
import { useToasterDispatch } from '../common/ui/Toaster/Toaster';

/**
 * The hook is used to claim a patient (and start reading a case).
 * This is for use outside of the Worklist domain.
 */

type UseClaimPatientReturn = {
  claimPatient: (args?: {
    overrideCaseId?: ?string,
    overrideCaseGroupId?: ?string,
  }) => Promise<void>,
  startRead: (args?: {
    overrideCaseId?: ?string,
    overrideCaseGroupId?: ?string,
    overrideStudyIds?: ?Array<string>,
  }) => Promise<void>,
};

export const useClaimPatient = (): UseClaimPatientReturn => {
  const { refreshCase, currentCaseId, currentCase } = useCurrentCase();
  const setDraftReportWorklistSmid = useSetRecoilState(draftReportWorklistSmid);
  const studyIds = useStudyIds();
  const { openCase } = useOpenCase();
  const { enqueueToast } = useToasterDispatch();

  const [changeWorklistItemStatus] = useMutation<
    ChangeWorklistItemStatusMutation,
    ChangeWorklistItemStatusMutationVariables,
  >(CHANGE_WORKLIST_ITEM_STATUS, {
    refetchQueries: [
      { query: GET_WORKLIST_ITEM, variables: { smid: currentCaseId } },
      { query: GET_CURRENT_WORKLIST_REPORT, variables: { smid: currentCaseId } },
    ],
    onCompleted: async (data) => {
      await refreshCase();

      sendEvent(NAMESPACES.CROSS_WINDOW_DATA_REFETCH, {
        type: 'claimedItems',
      });

      analytics.track(worklist.usr.addToQueue, {
        worklistItemSmid: data.changeWorklistItemStatus?.smid,
      });
    },
    update: (proxy, data) => {
      const worklistSmid = data.data?.changeWorklistItemStatus?.smid;
      const user = data.data?.changeWorklistItemStatus?.claimedBy;

      if (worklistSmid != null && user) {
        const worklistItem =
          proxy.readQuery<GetWorklistItemQuery, GetWorklistItemQueryVariables>({
            query: GET_WORKLIST_ITEM,
            variables: { smid: worklistSmid },
          }) || {};

        if (worklistItem.worklistItem != null) {
          const item = worklistItem.worklistItem;
          logger.info('[useClaimPatient]: update get worklist item claimed status');
          proxy.writeQuery<GetWorklistItemQuery, GetWorklistItemQueryVariables>({
            query: GET_WORKLIST_ITEM,
            variables: {
              smid: worklistSmid,
            },
            data: {
              worklistItem: {
                ...item,
                claimedBy: { id: user.id, firstName: user.firstName, lastName: user.lastName },
              },
            },
          });
        }

        const currentWorklistReport =
          proxy.readQuery<GetCurrentWorklistReportQuery, GetCurrentWorklistReportQueryVariables>({
            query: GET_CURRENT_WORKLIST_REPORT,
            variables: { smid: worklistSmid },
          }) || {};

        if (currentWorklistReport.worklistItem) {
          const item = currentWorklistReport.worklistItem;
          logger.info('[useClaimPatient]: update current worklist report claimed status');

          proxy.writeQuery<GetCurrentWorklistReportQuery, GetCurrentWorklistReportQueryVariables>({
            query: GET_CURRENT_WORKLIST_REPORT,
            variables: {
              smid: worklistSmid,
            },
            data: {
              worklistItem: {
                ...item,
                claimedBy: { id: user.id, firstName: user.firstName, lastName: user.lastName },
              },
            },
          });
        }
      }
    },
  });

  const claimPatient = useCallback(
    async (args?: { overrideCaseId?: ?string, overrideCaseGroupId?: ?string }) => {
      const { overrideCaseId = null, overrideCaseGroupId = null } = args || {};

      const caseGroupId = overrideCaseGroupId ?? currentCase?.groupId;
      const caseId = overrideCaseId ?? currentCaseId;
      if (caseId != null) {
        if (caseGroupId != null) {
          const message =
            'The exam you claimed is grouped with other exams. Those exams have been claimed as well.';
          enqueueToast(message);
        }

        await changeWorklistItemStatus({
          variables: {
            smid: caseId,
            claim: true,
          },
        });
      }
    },
    [currentCase, currentCaseId, changeWorklistItemStatus, enqueueToast]
  );

  const startRead = useCallback(
    async (args?: {
      overrideCaseId?: ?string,
      overrideCaseGroupId?: ?string,
      overrideStudyIds?: ?Array<string>,
    }) => {
      const {
        overrideCaseId = null,
        overrideCaseGroupId = null,
        overrideStudyIds = null,
      } = args || {};

      await claimPatient({ overrideCaseId, overrideCaseGroupId });

      const caseId = overrideCaseId ?? currentCaseId;

      if (caseId != null) {
        analytics.startReadSession(caseId);
        openCase({
          smid: caseId,
          action: CASE_ACTIONS.READ,
          studyIds: overrideStudyIds ?? studyIds,
        });
        setDraftReportWorklistSmid(null);
      }
    },
    [claimPatient, currentCaseId, openCase, setDraftReportWorklistSmid, studyIds]
  );

  return {
    claimPatient,
    startRead,
  };
};
