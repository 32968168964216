// @flow

import { omit } from 'ramda';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactEditor, useSlateSelection } from 'slate-react';
import { css } from 'styled-components';
import { useSlateSingletonContext } from 'domains/reporter/Reporter/SlateSingletonContext';
import { Range } from 'slate';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useReporterStyles } from 'hooks/useReporterStyles';
import { useRecoilValue } from 'recoil';
import { proceduresScopeState } from '../../Reporter/state';
import { PICKLIST_PLUGIN_ID } from '../plugins/picklist/types';
import { useReporterState } from '../hooks/useReporter';
import { isSelectionContainedInNode } from '../utils/isSelectionContainedInNode';
import { find } from '../utils/find';
import { useFocusMode } from 'hooks/useFocusMode';

const CURSOR_HEIGHT_PADDING = 2;

export type CursorIndicatorProps = {
  containerWidth: number,
  containerHeight: number,
  color: string,
  isAddendumEditor?: boolean,
};

export function CursorIndicator(props: CursorIndicatorProps): React$Node {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const { data } = useCurrentUser();
  const reporterStyles = useReporterStyles(data?.me?.reporterSettings);
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: 'right',
    modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
  });
  const proceduresScope = useRecoilValue(proceduresScopeState);

  const [{ editor }] = useSlateSingletonContext();
  const selection = useSlateSelection();
  const { variant } = useReporterState();
  const { isFocusModeEnabled } = useFocusMode();

  useEffect(() => {
    const updateReferenceElement = () => {
      if (selection == null || editor == null) {
        setReferenceElement(null);
        return;
      }

      if (Range.isCollapsed(selection)) {
        try {
          // If you are in the template or macro editor, we check to see if your selection is currently entirely contained within
          // a picklist. If it is, that means that you are not able to interact with the picklist as you need to edit options inside the pane. To make this clear
          // to the user, we hide the cursor indicator until the selection is no longer contained within the picklist.
          if (['template', 'fragment'].includes(variant)) {
            const picklistEntry = find(editor, (n) => n.type === PICKLIST_PLUGIN_ID);

            if (
              picklistEntry != null &&
              isSelectionContainedInNode(editor, picklistEntry[1], editor.selection)
            ) {
              setReferenceElement(null);
              return;
            }
          }
          // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
          const domRange = ReactEditor.toDOMRange(editor, selection);
          // $FlowIgnore[incompatible-call] it works
          setReferenceElement(domRange);
        } catch {
          const domSelection = window.getSelection();
          if (domSelection != null) {
            const domRange = domSelection.getRangeAt(0);
            setReferenceElement(domRange);
          }
        }
      } else {
        setReferenceElement(null);
      }
    };

    updateReferenceElement();
    const interval = setInterval(updateReferenceElement, 16); // 16ms is ~60fps
    return () => clearInterval(interval);
  }, [selection, popperElement, editor, reporterStyles, proceduresScope, variant]);

  if (referenceElement == null || isFocusModeEnabled) {
    return null;
  }

  let containerElement = referenceElement.commonAncestorContainer;

  // If the container is a text node, get its parent element
  if (containerElement.nodeType === Node.TEXT_NODE) {
    containerElement = containerElement.parentNode;
  }

  const fontSize = parseInt(
    window.getComputedStyle(containerElement).fontSize.replace('px', ''),
    10
  );
  const popperStyles = omit(['transform'], styles.popper || {});
  const popperOffsetX = Math.round(state?.modifiersData?.popperOffsets?.x ?? 0);
  const popperOffsetY = Math.round(state?.modifiersData?.popperOffsets?.y ?? 0);

  return (
    <div
      data-testid="cursor-indicator"
      ref={setPopperElement}
      {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
      style={{
        transform: `translate(${popperOffsetX}px, ${popperOffsetY}px)`,
      }}
      css={css`
        @keyframes cursor-blink {
          from {
            opacity: 1;
          }
          20% {
            opacity: 1;
          }
          28% {
            opacity: 0;
          }
          72% {
            opacity: 0;
          }
          80% {
            opacity: 1;
          }
          to {
            opacity: 1;
          }
        }

        &:has(~ [contenteditable]:focus) {
          animation: cursor-blink 1s linear infinite alternate;
        }

        ${css(popperStyles)};
        background-color: ${props.color};
        display: flex;
        align-content: center;
        z-index: 0;
        height: ${fontSize + CURSOR_HEIGHT_PADDING}px;
        width: 1px;
      `}
    />
  );
}
