// @flow
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import { assertion } from '@recoiljs/refine';
import { Suspense, useContext, useEffect, useMemo } from 'react';
import { Handles } from '../helpers/Handles';
import { useHover } from '../helpers/useHover';
import { useMoveAnnotation } from '../helpers/useMoveAnnotation';
import type { Segment } from '../types';
import { CURSOR_NODE } from '../helpers/cursors';
import { encodeId } from '../../utils/representationId';
import { Contexts } from 'react-vtk-js';
import { primitivesConfig } from './primitives';
import type { Primitive } from './primitives';
import type {
  AnnotationStatus,
  BaseAnnotationProps,
} from '../../../AnnotationsManager/annotationCreators';
import { useAnnotationReactiveRender } from '../../modules/useAnnotationReactiveRender';
import { executeToolAnnotationLogic, useQuickJsContext } from './logic';
import { useViewCamera } from '../../modules/useViewCamera';
import equals from 'lodash/fp/equals';
import { useStableValue } from 'hooks/useEqualValue';

export type Configuration = $ReadOnly<{
  primitives: $ReadOnlyArray<Primitive>,
  maxPoints: number,
  completeOnDoubleClick: boolean,
}>;

type CustomAnnotationProps = {
  ...BaseAnnotationProps,
  annotationLogic: string,
  onConfigurationUpdate?: (Configuration) => mixed,
  mousePosition?: ?vec3,
  segments: $ReadOnlyArray<Segment>,
  status?: AnnotationStatus,
  // This is only used for backwards compatibility with the old
  // annotation logic. It will be removed once the old logic is
  // removed and we start using points instead of segments.
  // All the new annotations will always use a list of segments
  // that directly map to the points of the annotation.
  handlesMapper?: React$ElementConfig<typeof Handles>['mapper'],
};

export const defaultConfiguration: Configuration = Object.freeze({
  primitives: [],
  maxPoints: Infinity,
  completeOnDoubleClick: false,
});

const defaultHandlersMapper = (segmentIndex: number, pointIndex: number, mousePosition: vec3) => [
  [segmentIndex, pointIndex, mousePosition],
];

function LazyCustomAnnotation({
  id,
  visible,
  onConfigurationUpdate,
  annotationLogic,
  mousePosition,
  segments,
  allowedTransformations = { move: false, transform: false },
  status = 'idle',
  handlesMapper = defaultHandlersMapper,
  label, // TODO: We should support multiple labels for multiple annotations
}: CustomAnnotationProps): React$Node {
  const view = useContext(Contexts.ViewContext);
  const camera = useViewCamera(view);

  const quickJsContext = useQuickJsContext(annotationLogic);

  const unstableConfiguration = useMemo(() => {
    let configuration: ?Configuration = defaultConfiguration;
    if (segments.length === 0) return configuration;

    const points = segments.flatMap((segment) => segment);

    configuration = executeToolAnnotationLogic({
      context: quickJsContext,
      annotationLogic,
      points,
      mousePosition,
      camera,
      status,
      label,
    });

    return configuration;
  }, [segments, quickJsContext, annotationLogic, mousePosition, camera, status, label]);
  const configuration = useStableValue(unstableConfiguration, equals);

  useEffect(() => {
    if (onConfigurationUpdate != null && configuration != null) {
      onConfigurationUpdate(configuration);
    }
  }, [configuration, onConfigurationUpdate]);

  const { hovered, ...hoverProps } = useHover({ targetId: id, allowedTransformations });
  const dragHandlers = useMoveAnnotation({ annotationId: id, allowedTransformations });

  useAnnotationReactiveRender(configuration?.primitives.length);

  if (configuration == null || configuration.primitives.length === 0) {
    return null;
  }

  return (
    <>
      {configuration.primitives.map((item, index) => {
        const primitive = primitivesConfig.find(({ type }) => type === item.type);

        if (!primitive) {
          throw new Error(`Unknown primitive type: ${item.type}`);
        }
        const { renderer: Primitive, checker } = primitive;

        const { type, ...primitiveConfiguration } = assertion(checker)(item);

        // NOTE(fzivolo): I can't make Flow work, here, it generates an union of all the props types
        // and an union of all the Primitive types but they don't end up being compatible
        let props: $FlowFixMe = { ...primitiveConfiguration };
        if (status === 'idle') {
          if (allowedTransformations.move === true) {
            props = { ...props, ...dragHandlers, ...hoverProps };
          }
        }

        return (
          <Primitive
            {...props}
            visible={visible}
            cursor={CURSOR_NODE}
            key={index}
            id={encodeId({ id, name: `${type}-${index}` })}
          />
        );
      })}

      {allowedTransformations.transform === true && status === 'idle' && (
        <Handles
          visible={visible === true && status === 'idle'}
          opacity={hovered ? 1 : 0}
          annotationId={id}
          segments={segments}
          mapper={handlesMapper}
          {...hoverProps}
        />
      )}
    </>
  );
}

export const CustomAnnotation = (props: CustomAnnotationProps): React$Node => (
  <Suspense fallback={null}>
    <LazyCustomAnnotation {...props} />
  </Suspense>
);
