// @flow

import type { CreateOnKeyDown } from '../../types';
import isHotkey from 'is-hotkey';
import type { BoldPluginPropertyOptions } from './types';
import { toggleMark } from '../../utils';
import { selectionIncludesHeading } from '../heading/utils/normalization';

export const onKeyDownBold: CreateOnKeyDown<BoldPluginPropertyOptions> =
  ({ hotkey, pluginID }) =>
  (e, { editor }) => {
    // Only enable the hotkey action if the selection does not include a heading.
    if (isHotkey(hotkey, e) && !selectionIncludesHeading(editor)) {
      e.preventDefault();
      toggleMark(editor, pluginID);
      return true;
    }
  };
