// @flow
import { Coordinate } from '@kitware/vtk.js/Rendering/Core/Coordinate/Constants';
import { withCursor } from '../../modules/cursor';
import { withPickerEvent } from '../../modules/usePicker';
import type { WithPickerEventReturn } from '../../modules/usePicker';

export const withCommonPrimitiveHelpers = <T>(
  Component: React$ComponentType<T>
): WithPickerEventReturn<T> => withPickerEvent(withCursor(Component));

export const transformCoordinate = {
  coordinateSystem: Coordinate.WORLD,
};

export function numberToOpacity(number: number): number {
  return Math.max(0.000000000001, Math.min(1, number));
}
