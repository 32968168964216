// @flow

import { Editor, Span, Node } from '../core';
import type {
  EditorType,
  PathType,
  NodeEntry,
  NodeType,
  PointType,
  RangeType,
  SpanType,
} from '../core';

export type EditorFindOptions = $Partial<
  $ReadOnly<{
    at?: RangeType | PathType | PointType | SpanType | void,
    mode?: 'highest' | 'lowest' | 'all' | void,
    universal?: boolean | void,
    reverse?: boolean | void,
    voids?: boolean | void,
  }>,
>;

/**
 * Iterate through all of the nodes in the editor and return the first match. If
 * no match is found, return undefined.
 */
export const find = <T = NodeType>(
  editor: EditorType,
  match: (node: NodeType, path: PathType) => boolean,
  options: EditorFindOptions = {}
): ?NodeEntry<T> => {
  // Slate throws when things aren't found so we wrap in a try catch and return undefined on throw.
  try {
    const { at = editor.selection, reverse = false, voids = false } = options;
    if (!at) {
      return;
    }

    let from;
    let to;
    if (Span.isSpan(at)) {
      // $FlowIgnore[incompatible-cast] - Span.isSpan is a Typescript typeguard and Flow doesn't understand it
      // $FlowIgnore[incompatible-type]
      from = (at[0]: PathType);
      // $FlowIgnore[incompatible-cast] - Span.isSpan is a Typescript typeguard and Flow doesn't understand it
      // $FlowIgnore[incompatible-type]
      to = (at[1]: PathType);
    } else {
      // $FlowIgnore[incompatible-call] - Span.isSpan is a Typescript typeguard and Flow doesn't understand it
      const first = Editor.path(editor, at, { edge: 'start' });
      // $FlowIgnore[incompatible-call] - Span.isSpan is a Typescript typeguard and Flow doesn't understand it
      const last = Editor.path(editor, at, { edge: 'end' });
      from = reverse ? last : first;
      to = reverse ? first : last;
    }
    const nodeEntries = Node.nodes(editor, {
      reverse,
      from,
      to,
      pass: ([n]) => (voids ? false : Editor.isVoid(editor, n)),
    });
    let foundMatch: ?NodeEntry<T> = undefined;
    for (const [node, path] of nodeEntries) {
      if (match(node, path)) {
        // $FlowIgnore[incompatible-type] - Node can be a union of things and it doesn't like that.
        foundMatch = [node, path];
        break;
      }
    }
    return foundMatch;
  } catch (error) {
    return undefined;
  }
};
