// @flow
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCurrentCaseId } from 'hooks/useCurrentCase';
import { APP_VERSION } from 'config/constants';
import { DEPLOY_ENV } from 'config';
import { useMemo } from 'react';

export const useSessionContext = (): {
  email: ?string,
  caseId: ?string,
  version: string,
  env: ?string,
} => {
  const { data } = useCurrentUser();
  const email = data?.me?.email;
  const caseId = useCurrentCaseId();

  return useMemo(() => ({ email, caseId, version: APP_VERSION, env: DEPLOY_ENV }), [email, caseId]);
};
