// @flow

import { Editor } from '../core';
import type { NodeType, NodeEntry, RangeType, EditorType } from '../core';
import { PARAGRAPH_PLUGIN_ID } from '../plugins/paragraph/types';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';

export const getBlocksInRange = (editor: EditorType, range?: RangeType): Array<NodeEntry<>> => {
  const selection = range ?? editor.selection;
  if (selection == null) {
    return [];
  }

  return Array.from(
    Editor.nodes(editor, {
      at: selection,
      match: (n: NodeType) => n.type === PARAGRAPH_PLUGIN_ID || n.type === HEADING_PLUGIN_ID,
    })
  );
};
