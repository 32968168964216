// @flow

import type { CreateOnKeyDown } from '../../types';
import { find } from '../../utils';
import type { SectionHeaderPluginPropertyOptions } from './types';

import isHotkey from 'is-hotkey';
import { Editor, Transforms } from 'slate';
import type { NodeType } from 'slate';

export const onKeyDownSectionHeader: CreateOnKeyDown<SectionHeaderPluginPropertyOptions> =
  (options) =>
  (e, { editor, nodeState }) => {
    // If the user is at the right outside edge of a section header node, adjust the selection to be
    // the left outside edge (skip over the node)
    if (isHotkey('left', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryBefore = find(editor, (n: NodeType) => n.type === options.pluginID, {
        at: Editor.before(editor, selection),
      });

      if (matchEntryBefore) {
        const targetPoint = Editor.before(editor, matchEntryBefore[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }

    // If the user is at the left outside edge of a section header node, adjust the selection to be
    // the right outside edge (skip over the node)
    if (isHotkey('right', e) && editor.selection != null) {
      const { selection } = editor;

      const matchEntryAfter = find(editor, (n: NodeType) => n.type === options.pluginID, {
        at: Editor.after(editor, selection),
      });

      if (matchEntryAfter) {
        const targetPoint = Editor.after(editor, matchEntryAfter[1]);

        if (targetPoint != null) {
          e.preventDefault();
          Transforms.select(editor, targetPoint);

          return true;
        }
      }
    }
  };
