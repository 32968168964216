// @flow
import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import type { RecoilState } from 'recoil';
import type { GamepadActionId } from '../generated/graphql';
import { useGamepadBindings } from '../utils/gamepad';
import { logger } from 'modules/logger';
import { useSlateSingletonContext } from '../domains/reporter/Reporter/SlateSingletonContext';
import { partialEditor } from '../domains/reporter/RichTextEditor/utils/partialEditor';
import { getSurroundingTextString } from '../domains/reporter/RichTextEditor/utils/getSurroundingTextString';
import { useTypedCharacters } from 'hooks/useTypedCharacters';
import { useFocusMode } from './useFocusMode';

export const mostRecentInputStateFamily: (
  container: string
) => RecoilState<{ isMousePressed: boolean, mostRecentInput: string }> = atomFamily({
  key: 'mousePressedState',
  default: {
    isMousePressed: false,
    mostRecentInput: '',
  },
});

export const INPUT_TYPES = {
  KEYBOARD: 'keyboard',
  GAMEPAD: 'gamepad',
  MOUSE: 'mouse',
  VOICE_COMMAND: 'voiceCommand',
};
export type InputType = $Values<typeof INPUT_TYPES>;

// List of target containers listeners are attached to
export const RICH_TEXT_EDITOR = 'RICH_TEXT_EDITOR';

// NOTE: Hook is somewhat similar to useShouldSkipWebsocketCreation,
// may be possible to refactor and collapse them into a single hook.
export const useMostRecentInput = (
  id: string
): ({
  isMousePressed: boolean,
  mostRecentInput: string,
  setMostRecentInput: (string) => void,
  setElement: () => void,
}) => {
  const [element, setElement] = useState<?React$Node>(null);
  const [{ isMousePressed, mostRecentInput }, setMostRecentInputAndPressed] = useRecoilState(
    mostRecentInputStateFamily(id)
  );
  const [{ editor }] = useSlateSingletonContext();

  const isMouseDownGlobal = useRef(false);

  const setMostRecentInput = useCallback(
    (input: InputType) => {
      setMostRecentInputAndPressed((curr) => ({ ...curr, mostRecentInput: input }));
    },
    [setMostRecentInputAndPressed]
  );

  const { handleTrackingTypedCharacters } = useTypedCharacters(id);

  const { isFocusModeSettingEnabled, handleFocusModeDoubleClick } = useFocusMode();

  useEffect(() => {
    const handleGlobalMouseDown = () => (isMouseDownGlobal.current = true);
    const handleGlobalMouseUp = () => {
      isMouseDownGlobal.current = false;
      setMostRecentInputAndPressed((curr) => ({
        ...curr,
        mostRecentInput: INPUT_TYPES.MOUSE,
        isMousePressed: false,
      }));
    };

    document.addEventListener('mousedown', handleGlobalMouseDown);
    document.addEventListener('mouseup', handleGlobalMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleGlobalMouseDown);
      document.removeEventListener('mouseup', handleGlobalMouseUp);
    };
  }, [setMostRecentInputAndPressed]);

  useEffect(() => {
    if (!(element instanceof HTMLElement)) return;
    const currentElement = element;

    const handleMouseDown = () => {
      if (editor != null) {
        const surroundingTextString = getSurroundingTextString(editor);
        logger.info(`[useMostRecentInput] mouseDown at: "${surroundingTextString}." `, {
          editor: editor != null ? partialEditor(editor) : 'null',
        });
      }

      if (isFocusModeSettingEnabled === true) {
        handleFocusModeDoubleClick();
      }

      setMostRecentInputAndPressed((curr) => ({
        ...curr,
        mostRecentInput: INPUT_TYPES.MOUSE,
        isMousePressed: true,
      }));
    };

    const handleMouseUp = () => {
      if (editor != null) {
        const surroundingTextString = getSurroundingTextString(editor);
        logger.info(`[useMostRecentInput] mouseUp at: "${surroundingTextString}." `, {
          editor: editor != null ? partialEditor(editor) : 'null',
        });
      }
      setMostRecentInputAndPressed((curr) => ({
        ...curr,
        mostRecentInput: INPUT_TYPES.MOUSE,
        isMousePressed: false,
      }));
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (editor != null) {
        const surroundingTextString = getSurroundingTextString(editor);
        logger.info(
          `[useMostRecentInput] keyDown "${event.key} (code ${event.keyCode})" at: "${surroundingTextString}." `,
          {
            editor: editor != null ? partialEditor(editor) : 'null',
          }
        );
      }

      setMostRecentInputAndPressed((curr) => ({ ...curr, mostRecentInput: INPUT_TYPES.KEYBOARD }));

      handleTrackingTypedCharacters({ event, editor });
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (editor != null) {
        const surroundingTextString = getSurroundingTextString(editor);
        logger.info(
          `[useMostRecentInput] keyUp "${event.key} (code ${event.keyCode})" at: "${surroundingTextString}." `,
          {
            editor: editor != null ? partialEditor(editor) : 'null',
          }
        );
      }
    };

    currentElement.addEventListener('mousedown', handleMouseDown);
    currentElement.addEventListener('mouseup', handleMouseUp);
    currentElement.addEventListener('keydown', handleKeydown);
    currentElement.addEventListener('keyup', handleKeyUp);
    return () => {
      currentElement.removeEventListener('mousedown', handleMouseDown);
      currentElement.removeEventListener('mouseup', handleMouseUp);
      currentElement.removeEventListener('keydown', handleKeydown);
      currentElement.removeEventListener('keyup', handleKeyUp);
    };
  }, [
    element,
    editor,
    setMostRecentInputAndPressed,
    handleTrackingTypedCharacters,
    handleFocusModeDoubleClick,
    isFocusModeSettingEnabled,
  ]);

  const gamepadCallback = useCallback(
    (action: GamepadActionId, pressed: boolean) => {
      setMostRecentInputAndPressed((curr) => ({ ...curr, mostRecentInput: INPUT_TYPES.GAMEPAD }));
    },
    [setMostRecentInputAndPressed]
  );
  useGamepadBindings({ callback: gamepadCallback });

  const memoizedValue = useMemo(() => {
    return { isMousePressed, mostRecentInput, setMostRecentInput, setElement };
  }, [isMousePressed, mostRecentInput, setMostRecentInput, setElement]);

  return memoizedValue;
};
