// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { HeadingKeywords } from 'generated/graphql';

import type { ComponentType } from 'react';
import type { THeadingLevel } from './constants';

export const HEADING_PLUGIN_ID: 'heading' = 'heading';
export type HeadingPluginID = typeof HEADING_PLUGIN_ID;

export type HeadingPluginElement = $ReadOnly<{
  ...EditorElement,
  type: HeadingPluginID,
  level: THeadingLevel, // H1, H2
}>;

export type HeadingPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type]
  component?: ComponentType<any>,
  headingKeywords?: HeadingKeywords,
  isInsertingMacro?: boolean,
}>;

export type HeadingPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...HeadingPluginOptions,
  pluginID: HeadingPluginID,
  // $FlowFixMe[unclear-type]
  component: ComponentType<any>,
  pluginName: string,
}>;
