// @flow
import { AUTOCORRECT_PLUGIN_ID } from './types';
import type { AutoCorrectPluginOptions, AutoCorrectPluginPropertyOptions } from './types';
import { MarkButton } from '../../components/MarkButton';
import type { EditorPlugin } from '../../types';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
export const AutoCorrectPlugin = (
  { pluginName = 'AutoCorrect', icon = SpellcheckIcon, ...rest }: AutoCorrectPluginOptions = {
    ...null,
  }
): EditorPlugin<> => {
  const options: AutoCorrectPluginPropertyOptions = {
    pluginID: AUTOCORRECT_PLUGIN_ID,
    pluginName,
    icon,
    ...rest,
  };

  return {
    pluginID: options.pluginID,
    hoveringToolbarConfig: () => [
      {
        pluginID: options.pluginID,
        toolbarButton: (
          <MarkButton
            icon={options.icon}
            pluginID={options.pluginID}
            pluginName={options.pluginName}
          />
        ),
      },
    ],
  };
};
