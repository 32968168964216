// @flow

import { INLINE_BOOKMARK_PLUGIN_ID } from './types';
import { PARAGRAPH_PLUGIN_ID } from '../paragraph/types';
import type { InlineBookmarkPluginElement } from './types';
import { Transforms, Editor, Node } from 'domains/reporter/RichTextEditor/core';
import type {
  EditorType,
  NodeType,
  NodeEntry,
  LocationType,
} from 'domains/reporter/RichTextEditor/core';
import { isSquareBracketType } from 'domains/reporter/RichTextEditor/constants';
import { BRACKET_TYPES } from '../../constants';
import { areMultipleNodesSelected } from '../../utils/areMultipleNodesSelected';
import { PLACEHOLDER_PLUGIN_ID } from '../placeholder/types';
import { HEADING_PLUGIN_ID } from '../heading/types';

export const createInlineBookmark = (text: string = ''): InlineBookmarkPluginElement => ({
  type: INLINE_BOOKMARK_PLUGIN_ID,
  children: [{ text: text }],
});

export const splitInlineBookmark = (editor: EditorType): void => {
  Transforms.splitNodes(editor, {
    always: true,
    match: (n) => n.type === PARAGRAPH_PLUGIN_ID,
    mode: 'highest',
  });
  if (editor.selection != null) {
    Transforms.setNodes(
      editor,
      { type: INLINE_BOOKMARK_PLUGIN_ID, picklistID: undefined },
      {
        at: editor.selection,
        match: (n: NodeType) => isSquareBracketType(String(n.type)),
      }
    );
  }
};

export const insertInlineBookmark = (
  editor: EditorType,
  options?: {
    location?: LocationType,
    select?: boolean,
  }
): void => {
  const { selection } = editor;
  const location = options?.location ?? selection;
  if (location == null) {
    return;
  }

  const [fragment, insertLocation] = prepFragmentAndInsertionPoint(
    editor,
    // $FlowFixMe[incompatible-call]
    [createInlineBookmark()],
    true,
    {
      location,
      split: true,
    }
  );

  if (insertLocation != null) {
    Transforms.insertNodes(editor, fragment, {
      at: insertLocation,
      select: options?.select ?? true,
    });
  }
};
/**
 * Handle inserting a fragment inside brackets if not able to render nested fields:
 * - If the brackets are empty + param is provided, remove the brackets and insert the fragment
 * - If the brackets are non-empty
 *   - And the fragment contains only text nodes, insert inline (for both single or multiline fragments)
 *   - And the fragment contains fields (picklists, bookmarks, placeholders), insert after the brackets
 *
 * If you are able to render nested fields, this logic is only run if the containing bracket is a placeholder
 */
export const prepFragmentAndInsertionPoint = (
  editor: EditorType,
  fragment: Array<NodeType>,
  containsFields?: boolean,
  options?: {
    location?: LocationType,
    removeEmptyBrackets?: boolean,
    split?: boolean,
  }
): [Array<NodeType>, ?LocationType] => {
  const { selection } = editor;
  const insertionPoint = options?.location ?? selection;
  if (insertionPoint == null) {
    return [fragment, insertionPoint];
  }

  if (!areMultipleNodesSelected(editor)) {
    const [maybeBracketNode, maybeBracketLocation] = Editor.parent(editor, insertionPoint);
    const isInsideBrackets = BRACKET_TYPES.includes(maybeBracketNode.type);

    if (maybeBracketNode.type === HEADING_PLUGIN_ID) {
      const locationAfter = Editor.after(editor, maybeBracketLocation);
      if (locationAfter != null) {
        return [fragment, locationAfter];
      } else {
        const nextNode = Editor.next(editor, {
          at: maybeBracketLocation,
          match: (n: NodeType) => n.type === PARAGRAPH_PLUGIN_ID && n.shouldForceInline === true,
        });
        const startOfNextNode = nextNode != null ? Editor.start(editor, nextNode[1]) : null;
        return [
          fragment,
          startOfNextNode ?? [maybeBracketLocation[0], maybeBracketLocation[1] + 1],
        ];
      }
    } else if (!isInsideBrackets) {
      return [fragment, insertionPoint];
    }

    if (maybeBracketNode.type === PLACEHOLDER_PLUGIN_ID) {
      const locationAfter = Editor.after(editor, maybeBracketLocation);
      return [fragment, locationAfter ?? [maybeBracketLocation[0], maybeBracketLocation[1] + 1]];
    }
  }

  return [fragment, insertionPoint];
};

export const getLastInlineBookmarkInRange = (
  editor: EditorType,
  range: LocationType
): ?NodeEntry<> => {
  const maybeInlineBookmark = Editor.nodes(editor, {
    at: range,
    reverse: true,
    match: (n: NodeType) => {
      return n.type === INLINE_BOOKMARK_PLUGIN_ID;
    },
  }).next();

  if (!maybeInlineBookmark.done && maybeInlineBookmark.value != null) {
    return maybeInlineBookmark.value;
  }

  return null;
};

export const getHasMultipleInlineBookmarksInRange = (
  editor: EditorType,
  range: LocationType
): boolean => {
  let count = 0;
  // eslint-disable-next-line no-unused-vars
  for (const node of Editor.nodes(editor, {
    at: range,
    match: (n: NodeType) => n.type === INLINE_BOOKMARK_PLUGIN_ID,
  })) {
    count++;
    if (count > 1) {
      return true;
    }
  }

  return false;
};

export const getLastInlineBookmarkInRangeIfEmpty = (
  editor: EditorType,
  range: LocationType
): ?NodeEntry<> => {
  const maybeInlineBookmark = Editor.nodes(editor, {
    at: range,
    reverse: true,
    match: (n: NodeType) => {
      return n.type === INLINE_BOOKMARK_PLUGIN_ID;
    },
  }).next();

  if (
    !maybeInlineBookmark.done &&
    maybeInlineBookmark.value != null &&
    Node.string(maybeInlineBookmark.value[0]).trim() === ''
  ) {
    return maybeInlineBookmark.value;
  }

  return null;
};
