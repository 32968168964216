// @flow

import type { HeadingPluginElement, HeadingPluginPropertyOptions } from '../types';
import styled, { css } from 'styled-components';
import type { StyledComponent } from 'styled-components';

import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import type { THeadingLevel } from '../constants';
import {
  getHeadingFontSize,
  getHeadingTextTransform,
  getHeadingTextDecoration,
} from '../utils/styling';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useReporterStyles } from 'hooks/useReporterStyles';
import type { Styles } from 'generated/graphql';
import { Children } from 'react';
import { useSelected } from 'slate-react';

type HeadingWrapperProps = {
  children: React$Node,
  level: THeadingLevel,
};

export const HeadingWrapper = ({ children, level }: HeadingWrapperProps): React$Node => {
  const { data } = useCurrentUser();
  const reporterSettings = data?.me?.reporterSettings;

  const style: Styles = useReporterStyles(reporterSettings);
  const headingStyles = [...(style?.headingStyles ?? [])];
  const fontSize = getHeadingFontSize(headingStyles, level);
  const textTransform = getHeadingTextTransform(headingStyles, level);
  const textDecoration = getHeadingTextDecoration(headingStyles, level);

  // Currently using a single font family for whole report, dictated by the body style.
  const fontFamily = style?.bodyStyle?.fontFamily;

  const headingName = Children.toArray(children)
    .map((child) => child.props?.text?.text ?? '')
    .join('');
  const selected = useSelected();

  return (
    <p
      css={css`
        margin: 0;
        display: inline;
        text-transform: ${textTransform};
        font-family: ${fontFamily};
        font-size: ${fontSize}px;
        font-weight: ${textDecoration?.isBold === true ? 'bold' : 'normal'};
        font-style: ${textDecoration?.isItalic === true ? 'italic' : 'normal'};
        text-decoration: ${textDecoration?.isUnderline === true ? 'underline' : 'none'};
        &:before {
          content: '';
          display: block;
        }
      `}
      data-testid={`heading-${level}-${headingName.toLowerCase()}`}
      data-editor-element={`heading-${level}`}
      data-selected={selected}
    >
      {children}
    </p>
  );
};

export const HeadingEditable: GetRenderElementComponentType<
  HeadingPluginPropertyOptions,
  HeadingPluginElement,
> = ({ element, children, ...rest }): React$Node => {
  return (
    <span {...rest.attributes}>
      <HeadingWrapper level={element.level}>{children}</HeadingWrapper>
    </span>
  );
};

export const HeadingRenderer: StyledComponent<{ ... }, { ... }, typeof HeadingEditable> = styled(
  HeadingEditable
)`
  white-space: pre-wrap;
`;
