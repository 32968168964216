// @flow

import { Text } from '../core';
import type { EditorType, NodeType } from '../core';
import { INLINE_BOOKMARK_PLUGIN_ID, PICKLIST_PLUGIN_ID, PLACEHOLDER_PLUGIN_ID } from '../plugins';
import type { Fragment } from '../types';
import { Editor, Node, Point } from 'slate';
import type { AncestorType, LocationType, NodeEntry } from 'slate';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';

export const isText = Text.isText;

export const isInline =
  (editor: EditorType): ((node: NodeType) => boolean) =>
  (node) =>
    // $FlowIgnore[incompatible-call] - Internal types wrong
    isText(node) || editor.isInline(node);

export const isBlock =
  (editor: EditorType): ((node: NodeType) => boolean) =>
  (node) =>
    !isInline(editor)(node);

export const isTextFragment = (_editor: EditorType, fragment: Fragment): boolean => {
  return fragment.every(Text.isText);
};

export const isInlineFragment = (editor: EditorType, fragment: Fragment): boolean => {
  return fragment.every((item) => Text.isText(item) || editor.isInline(item));
};

export const isBlockFragment = (editor: EditorType, fragment: Fragment): boolean => {
  return !isInlineFragment(editor, fragment);
};

export const isEmptyFragment = (fragment: Fragment): boolean => {
  return (
    fragment.length === 1 &&
    fragment.every((node) => node.text === '' && Object.keys(node).length === 1)
  );
};

// Checks a fragment to see if it contains a node of a given type within all of its descendants
export const fragmentContainsNodeType = (fragment: Fragment, type: string): boolean => {
  return fragment.some((child) => {
    for (const [node] of Node.nodes(child)) {
      if (node.type === type) {
        return true;
      }
    }
    return false;
  });
};

// Checks a fragment to see if it only contains a node of a given type within all of its descendants
export const fragmentOnlyContainsParagraphText = (fragment: Fragment, type: string): boolean => {
  return fragment.every((child) => {
    // Descendants don't check on root node
    for (const [node] of Node.descendants(child)) {
      if (!Text.isText(node)) {
        return false;
      }
    }
    return true;
  });
};

export const fragmentContainsBrackets = (fragment: Fragment): boolean => {
  return (
    fragmentContainsNodeType(fragment, PICKLIST_PLUGIN_ID) ||
    fragmentContainsNodeType(fragment, INLINE_BOOKMARK_PLUGIN_ID) ||
    fragmentContainsNodeType(fragment, PLACEHOLDER_PLUGIN_ID)
  );
};

/**
 * Checks if the selection is right at the end of the heading,
 * and return heading entry if it is.
 *
 * Example:
 *
 *  <heading level="1">
 *      <text>EXAMINATION:<cursor/></text>
 *  </heading>
 */
export const getHeadingIfCursorRightAtEnd = (
  editor: EditorType,
  selection?: LocationType
): NodeEntry<AncestorType> | void => {
  if (selection == null || !isSelectionCollapsed(editor, selection)) {
    return;
  }
  const point = selection || editor.selection;
  const selectionPoint = Editor.point(editor, point);
  const headingEntry = Editor.above(editor, {
    at: selectionPoint,
    match: (n: NodeType) => n.type === HEADING_PLUGIN_ID,
  });

  if (headingEntry != null) {
    const endOfHeadingPoint = Editor.end(editor, headingEntry[1]);

    if (Point.equals(selectionPoint, endOfHeadingPoint)) {
      return headingEntry;
    }
  }
};

export const isLastCharacterAColon = (text: string): boolean =>
  text.length > 0 && text[text.length - 1] === ':';

export const isSelectionCollapsed = (editor: EditorType, selection?: LocationType): boolean => {
  if (selection == null) {
    return false;
  }
  if (Point.isPoint(selection)) {
    return true;
  }
  const [left, right] = Editor.edges(editor, selection);
  return Point.equals(left, right);
};
