// @flow
import { pick } from 'ramda';
import type { NodeType, EditorType, RangeType, OperationType, HistoryType } from '../core';

export type PartialEditor = {
  children: NodeType[],
  selection: RangeType | null,
  operations: OperationType[],
  log?: { ops: OperationType[] },
  history?: HistoryType,
};

/*
 * This function return an editor-like object which has been stripped of
 * editor methods.
 * Useful only for logging purposes.
 */
export const partialEditor = (editor: EditorType): PartialEditor =>
  // $FlowFixMe[prop-missing] Ramda internals will ignore any keys that are not present in the editor
  // $FlowFixMe[incompatible-return] Ramda internals will ignore any keys that are not present in the editor
  pick(['children', 'operations', 'selection'], editor);
