// @flow

import type { CreateContextReturn } from 'common/ui/util/createContext';
import type { Dispatch, SetStateAction } from 'types/react';
import { useScale } from '../modules/useScale';

import { createContext } from 'common/ui/util/createContext';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Contexts } from 'react-vtk-js';
import { useRecoilValue } from 'recoil';
import { isActiveViewportScrollingAtom, isActiveViewportPanningAtom } from '../modules/state';
import { useDebouncedCallback } from 'use-debounce';

export const [
  AnnotationsRenderBatcherProvider,
  useAnnotationsRenderBatcher,
  AnnotationsRenderBatcherContext,
]: CreateContextReturn<() => void> = createContext<() => void>({
  strict: true,
  errorMessage:
    'useAnnotationsRenderBatcher: `context` is undefined. Seems you forgot to wrap component within the AnnotationsRenderBatcherProvider',
  name: 'AnnotationsRenderBatcherContext',
});

type RenderTrackingState = 'WAITING' | 'REQUESTED' | 'RENDERED';

export const [
  AnnotationsRenderTrackingStateProvider,
  useAnnotationsRenderTrackingState,
  AnnotationsRenderTrackingStateContext,
]: CreateContextReturn<[RenderTrackingState, Dispatch<SetStateAction<RenderTrackingState>>]> =
  createContext<[RenderTrackingState, Dispatch<SetStateAction<RenderTrackingState>>]>({
    strict: true,
    errorMessage:
      'useAnnotationsRenderTrackingState: `context` is undefined. Seems you forgot to wrap component within the AnnotationsRenderTrackingStateProvider',
    name: 'AnnotationsRenderTrackingStateContext',
  });

type AnnotationsRenderBatcherProps = {
  children: React$Node,
};

// this component may not be necessary once a future version of react-vtk-js (maybe 1.16?) comes out
export const AnnotationsRenderBatcher = ({
  children,
}: AnnotationsRenderBatcherProps): React$Node => {
  const [shouldRender, setShouldRender] = useState(false);
  const [renderTrackingState, setRenderTrackingState] = useState<RenderTrackingState>('WAITING');
  const view = useContext(Contexts.ViewContext);
  const isScrolling = useRecoilValue(isActiveViewportScrollingAtom);
  const isPanning = useRecoilValue(isActiveViewportPanningAtom);
  const requestRender = useCallback(() => setShouldRender(true), []);

  const isScaleStable = useWaitForStableScale();
  // rather than throttling or debouncing the render function temporally, we need to batch it according to when calculations are done
  // the effect will fire only after all annotation primitives have recalculated
  // with temporal throttling, it was easy to run into situations where handles would render slightly behind polygons
  useEffect(() => {
    if (shouldRender && view && isScaleStable && !isScrolling && !isPanning) {
      view.requestRender();
      setRenderTrackingState((state) => (state === 'REQUESTED' ? 'RENDERED' : 'WAITING'));
      setShouldRender(false);
    }
  }, [shouldRender, view, isScaleStable, isScrolling, isPanning]);

  return (
    <AnnotationsRenderBatcherProvider value={requestRender}>
      <AnnotationsRenderTrackingStateProvider value={[renderTrackingState, setRenderTrackingState]}>
        {children}
      </AnnotationsRenderTrackingStateProvider>
    </AnnotationsRenderBatcherProvider>
  );
};

const useWaitForStableScale = () => {
  const scale = useScale(false);
  const [stable, setStable] = useState(true);
  const waitForNoMoreChangesInScale = useDebouncedCallback(() => {
    /*
     * This timeout is necessary due to weird behavior with Handles.
     * In some circumstances, each Handle gets the new scale and fires off a rerender request
     * then the RenderBatcher handles that request BEFORE the rest of the Handles report it.
     * The result is the renderView firing repeatedly when we only want it to fire once.
     * Instead, we wait until the Handles have all settled before doing the next renderView()
     */
    setTimeout(() => {
      setStable(true);
    });
  }, 1000 / 60);

  useEffect(() => {
    setStable(false);
    waitForNoMoreChangesInScale();
  }, [scale, waitForNoMoreChangesInScale]);

  return stable;
};
