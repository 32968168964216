// @flow
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { PAGE_TYPES } from 'utils/pageTypes';
import { CASE_ACTIONS, PATH } from 'config/constants';
import { useCaseSync } from 'hooks/useCaseSync';
import { openPopup } from 'utils/openPopup';
import { makeViewerUrl, makeReporterUrl } from 'utils/router';
import { UPDATE_LAST_VIEWED_WORKLISTS } from 'modules/Apollo/queries';
import type {
  UpdateLastViewedWorklistsMutation,
  UpdateLastViewedWorklistsMutationVariables,
} from 'generated/graphql';
import { usePopupOptionsGetter } from 'hooks/useWindowsRects';
import { useSendFocusEvent } from '../modules/focusManager';
import { logEvents } from '../common/Navbar/MenuItem';
import { atom, useRecoilCallback, useSetRecoilState } from 'recoil';
import type { RecoilState, Snapshot } from 'recoil';
import { useToolPreferences } from 'hooks/usePreferences';
import {
  applyWorkspacePresetEvent,
  setCaseIdEvent,
} from 'domains/extension/extensionEventCreators';
import { useOpenTabs } from './useOpenTabs';
import { dispatchExtensionEvent } from '../domains/extension/extensionEventCreators';
import { useToasterDispatch } from '../common/ui/Toaster/Toaster';
import { matchPath } from 'react-router-dom';
import { ScreenNameValues } from '../generated/graphql';
import type { WorkspacePreset } from '../generated/graphql';

export const popupLockAtom: RecoilState<boolean> = atom({
  key: 'popupLockAtom',
  default: false,
});

type OpenCaseOpts = {
  smid: string,
  action: $Values<typeof CASE_ACTIONS>,
  studyIds?: string[],
};

export type OpenCase = {
  openCase: (opts: OpenCaseOpts) => Promise<void>,
};

type UseOpenCaseOpts = {
  reporterOnly?: boolean,
};

const toastKey = 'useOpenCase';

export const useOpenCase = ({ reporterOnly = false }: UseOpenCaseOpts = {}): OpenCase => {
  const syncCase = useCaseSync();
  const sendEvent = useSendFocusEvent();
  const [updateLastViewedWorklists] = useMutation<
    UpdateLastViewedWorklistsMutation,
    UpdateLastViewedWorklistsMutationVariables,
  >(UPDATE_LAST_VIEWED_WORKLISTS);

  const { enqueueOrUpdateToast } = useToasterDispatch();

  const getPopupOptions = usePopupOptionsGetter();
  const setPopupLock = useSetRecoilState(popupLockAtom);
  const [toolPreferences] = useToolPreferences();
  const openTabs = useOpenTabs();

  const openCase = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ smid, action, studyIds }: OpenCaseOpts) => {
        const isViewer0Open = openTabs.some(
          (tab) => tab.type === PAGE_TYPES.VIEWER && tab.windowId === '0'
        );
        const isViewer1Open = openTabs.some(
          (tab) => tab.type === PAGE_TYPES.VIEWER && tab.windowId === '1'
        );
        const isReporterOpen = openTabs.some((tab) => tab.type === PAGE_TYPES.REPORTER);

        syncCase(smid, studyIds);
        dispatchExtensionEvent(setCaseIdEvent({ smid }));

        // Remember to call this to update the user profile
        updateLastViewedWorklists({ variables: { smids: [smid] } });

        const workspacePresets = toolPreferences?.workspaces?.presets ?? [];
        const toApply = Array.from(workspacePresets.filter((w) => w.instanceId)).sort(
          sortByMostRecentlyUpdated
        )[0];

        const autoApply = toolPreferences?.workspaces.autoApply ?? false;

        const shouldOpenViewer1 =
          toApply?.windows.some((wind) => wind.screen === ScreenNameValues.Viewer1) && autoApply;

        const viewerUrl0 = makeViewerUrl(smid, 0);
        const viewerUrl1 = makeViewerUrl(smid, 1);
        const reporterUrl = makeReporterUrl(smid);

        // this operation uses a cross window recoil atom to lock the open popup operation
        // this prevents the user from opening multiple popups at the same time
        const openPopupWithLock = async (snapshot: Snapshot, url: string) => {
          const popupLock = await snapshot.getPromise(popupLockAtom);

          if (popupLock) return true;

          const viewerMatch = matchPath(url, PATH.VIEWER);

          const openingOpenReporter = isReporterOpen && url.includes('reporter');
          const openingOpenViewer0 =
            isViewer0Open && viewerMatch != null && viewerMatch.params.windowId === '0';
          const openingOpenViewer1 =
            isViewer1Open && viewerMatch != null && viewerMatch.params.windowId === '1';

          if (openingOpenReporter || openingOpenViewer0 || openingOpenViewer1) return true;

          set(popupLockAtom, true);
          return await openPopup(url, getPopupOptions(url));
        };

        const loadCaseError = (window: string) => {
          const message = `A popup blocker prevented the application from opening the ${window}, try disabling the popup blocker and try again.`;

          enqueueOrUpdateToast(message, toastKey, {
            severity: 'error',
          });
        };

        if (reporterOnly) {
          const loadCaseSuccess = () => {
            const message = isReporterOpen
              ? `Your exam has been loaded on the existing Reporter`
              : `Your exam has been loaded on a new Reporter`;

            enqueueOrUpdateToast(message, toastKey, {
              severity: 'success',
            });
          };

          if ([CASE_ACTIONS.VIEW, CASE_ACTIONS.READ].includes(action)) {
            if (!isReporterOpen) {
              const success = await openPopupWithLock(snapshot, reporterUrl);
              if (success) {
                loadCaseSuccess();
              } else {
                loadCaseError('Reporter');
              }
            }
          }

          return;
        }

        const openPromises: Promise<['Viewer' | 'Reporter', boolean]>[] = [];
        if (action === CASE_ACTIONS.VIEW || action === CASE_ACTIONS.READ) {
          if (!isViewer0Open) {
            const promise = openPopupWithLock(snapshot, viewerUrl0).then((success) => [
              'Viewer',
              success,
            ]);
            openPromises.push(promise);
            logEvents({ pageType: PAGE_TYPES.VIEWER, sendEvent, viewerIndex: '0' });
          }
          if (!isViewer1Open && shouldOpenViewer1) {
            const promise = openPopupWithLock(snapshot, viewerUrl1).then((success) => [
              'Viewer',
              success,
            ]);
            openPromises.push(promise);
            logEvents({ pageType: PAGE_TYPES.VIEWER, sendEvent, viewerIndex: '0' });
          }
        }
        if (action === CASE_ACTIONS.READ) {
          if (!isReporterOpen) {
            const promise = openPopupWithLock(snapshot, reporterUrl).then((success) => [
              'Reporter',
              success,
            ]);
            openPromises.push(promise);
          }
        }

        const results = await Promise.all(openPromises);

        const maybeFailedResult = results.find(([type, success]) => !success);
        if (maybeFailedResult != null) {
          if (
            results.some(([type]) => type === 'Viewer') &&
            results.some(([type]) => type === 'Reporter')
          ) {
            loadCaseError('Viewer and Reporter');
          } else {
            loadCaseError(maybeFailedResult[0]);
          }

          return;
        }

        if (results.every(([type, success]) => success)) {
          const message = generateToastMessage(
            action,
            isReporterOpen,
            isViewer0Open,
            isViewer1Open,
            shouldOpenViewer1
          );

          enqueueOrUpdateToast(message, toastKey, {
            severity: 'success',
          });
        }

        if (toApply != null && autoApply) {
          // apply workspace preset
          dispatchExtensionEvent(applyWorkspacePresetEvent({ preset: toApply, caseId: smid }));
        }
      },

    [
      openTabs,
      syncCase,
      updateLastViewedWorklists,
      reporterOnly,
      getPopupOptions,
      sendEvent,
      toolPreferences?.workspaces.autoApply,
      toolPreferences?.workspaces.presets,
      enqueueOrUpdateToast,
    ]
  );

  useEffect(() => {
    const isViewer0Open = openTabs.some(
      (tab) => tab.type === PAGE_TYPES.VIEWER && tab.windowId === '0'
    );
    const isViewer1Open = openTabs.some(
      (tab) => tab.type === PAGE_TYPES.VIEWER && tab.windowId === '1'
    );
    const isReporterOpen = openTabs.some((tab) => tab.type === PAGE_TYPES.REPORTER);
    if (isViewer0Open || isViewer1Open || isReporterOpen) {
      setPopupLock(false);
    }
  }, [openTabs, setPopupLock, reporterOnly]);

  return {
    openCase,
  };
};

function sortByMostRecentlyUpdated(a: WorkspacePreset, b: WorkspacePreset): number {
  return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
}

function generateToastMessage(
  action: 'READ' | 'VIEW',
  isReporterOpen: boolean,
  isViewer0Open: boolean,
  isViewer1Open: boolean,
  shouldOpenViewer1: boolean
): string {
  if (action === CASE_ACTIONS.VIEW) {
    if (isViewer0Open && isReporterOpen) {
      // only Viewers should open, but the Reporter might already be open
      return `Your exam has been loaded on the existing Viewer${isViewer1Open ? 's' : ''} and Reporter`;
    } else if (isViewer0Open) {
      // load Viewer(s)
      return `Your exam has been loaded on the existing Viewer${isViewer1Open ? 's' : ''}`;
    } else if (isReporterOpen) {
      // we have a Reporter for some reason! Load the Viewers
      return `Your exam has been loaded on the existing Reporter and in ${
        shouldOpenViewer1 ? 'new Viewers' : 'a new Viewer'
      }`;
    }
    // open new Viewer(s)
    return `Your exam has been loaded on ${shouldOpenViewer1 ? 'new Viewers' : 'a new Viewer'}`;
  } else {
    if (isViewer0Open && isReporterOpen) {
      // most/everything is already open
      return `Your exam has been loaded on the existing Viewer${isViewer1Open ? 's' : ''} and Reporter`;
    } else if (isViewer0Open) {
      // need to open Reporter
      return `Your exam has been loaded on the existing Viewer${
        isViewer1Open ? 's' : ''
      } and in a new Reporter`;
    } else if (isReporterOpen) {
      // need to open Viewer(s)
      return `Your exam has been loaded on the existing Reporter and in ${
        shouldOpenViewer1 ? 'new Viewers' : 'a new Viewer'
      }`;
    }
    // open everything
    return `Your exam has been loaded on ${
      shouldOpenViewer1 ? 'new Viewers and a new' : 'a new Viewer and'
    } Reporter`;
  }
}
