// @flow

import isHotkey from 'is-hotkey';
import { Editor, Node, Transforms, Range } from '../../../core';
import { isNodeTypeIn } from '../../../utils';
import type { CreateOnKeyDown } from '../../../types';
import { LIST_PLUGIN_ID } from '../../list/types';
import {
  findClosestBlockPathAtSelection,
  listItemContainsOnlyEmptyInlineBookmark,
} from '../../list/utils';
import { INLINE_BOOKMARK_PLUGIN_ID } from '../../inlineBookmark/types';
import { BRACKET_TYPES } from '../../../constants';
import type { NodeType } from '../../../core';
import { PARAGRAPH_PLUGIN_ID } from '../../paragraph/types';
import { createLineBreak } from '../../lineBreak/utils';

export const onKeyDownInlineTemplate: CreateOnKeyDown<> =
  ({ pluginID }) =>
  (e, { editor }) => {
    /**
     * We override the default left key behavior in two cases:
     *
     * 1. If you are at the start of a picklist and are arrowing out of it
     * 2. If you are at the outside right edge of a picklist and are arrowing into it
     *
     * What we are doing is changing the unit size that Slate alters the selection with to be
     * via offset instead of character since there are no editable characters between the bracket edges
     * and the next nodes.
     */

    if (isHotkey('left', e) && editor.selection != null) {
      const { selection } = editor;

      const beforeLocation = Editor.before(editor, selection);
      const [matchEntry] = [
        ...Editor.nodes(editor, {
          match: (n: NodeType) => n.type === pluginID,
          at: Editor.range(editor, selection, beforeLocation),
          mode: 'lowest',
        }),
      ];

      if (
        matchEntry &&
        beforeLocation &&
        (Editor.isStart(editor, selection.anchor, matchEntry[1]) ||
          Editor.isEnd(editor, beforeLocation, matchEntry[1]))
      ) {
        e.preventDefault();

        if (editor.selection && Range.isCollapsed(editor.selection)) {
          Transforms.move(editor, { reverse: true, unit: 'offset' });
        } else {
          Transforms.collapse(editor, { edge: 'start' });
        }

        return true;
      }
    }

    /**
     * We override the default right key behavior in two cases:
     *
     * 1. If you are at the end of a picklist and are arrowing out of it
     * 2. If you are at the outside left edge of a picklist and are arrowing into it
     *
     * What we are doing is changing the unit size that Slate alters the selection with to be
     * via offset instead of character since there are no editable characters between the bracket edges
     * and the next nodes.
     */
    if (isHotkey('right', e) && editor.selection != null) {
      const { selection } = editor;
      const afterLocation = Editor.after(editor, editor.selection);
      const [matchEntry] = [
        ...Editor.nodes(editor, {
          match: (n: NodeType) => n.type === pluginID,
          at: Editor.range(editor, selection, afterLocation),
          mode: 'lowest',
        }),
      ];

      if (
        matchEntry &&
        afterLocation &&
        (Editor.isEnd(editor, selection.focus, matchEntry[1]) ||
          Editor.isStart(editor, afterLocation, matchEntry[1]))
      ) {
        e.preventDefault();

        if (editor.selection && Range.isCollapsed(editor.selection)) {
          Transforms.move(editor, { unit: 'offset' });
        } else {
          Transforms.collapse(editor, { edge: 'end' });
        }

        return true;
      }
    }

    if ((isHotkey('enter', e) || isHotkey('shift+enter', e)) && isNodeTypeIn(editor, pluginID)) {
      e.preventDefault();

      const node = Node.get(editor, findClosestBlockPathAtSelection(editor));
      // condition to allow override by OnKeyDownList 'enter'
      if (isNodeTypeIn(editor, LIST_PLUGIN_ID)) {
        if (listItemContainsOnlyEmptyInlineBookmark(node)) {
          return;
        } else {
          Editor.withoutNormalizing(editor, () => {
            Transforms.splitNodes(editor, {
              always: true,
              match: (n) => n.type === PARAGRAPH_PLUGIN_ID,
              mode: 'highest',
            });

            const selection = editor.selection;
            if (selection != null) {
              Transforms.setNodes(
                editor,
                {
                  type: INLINE_BOOKMARK_PLUGIN_ID,
                },
                { at: selection, match: (n: NodeType) => BRACKET_TYPES.includes(n.type) }
              );
              Transforms.unsetNodes(
                editor,
                ['placeholderID', 'picklistID', 'shouldForceInline', 'workListItemSmid'],
                {
                  at: selection,
                  match: (n: NodeType) => BRACKET_TYPES.includes(n.type),
                }
              );
              Transforms.unsetNodes(editor, 'shouldForceInline', {
                at: selection,
                match: (n: NodeType) => n.type === PARAGRAPH_PLUGIN_ID,
              });
            }
          });

          return true;
        }
      } else {
        const marks = Editor.marks(editor);
        Transforms.insertNodes(editor, [createLineBreak(), { text: '' }]);

        if (marks != null) {
          Object.entries(marks).forEach(([key, value]) => {
            Editor.addMark(editor, key, value);
          });
        }
      }
    }
  };
