// @flow
import { atom } from 'recoil';
import type { RecoilState } from 'recoil';

import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';

export const recorderState: RecoilState<boolean> = atom({
  key: 'recorderState',
  default: false,
  effects: [broadcastChannelSynchronizerEffect()],
});
