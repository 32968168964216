// @flow

import type { EditorType, RangeType } from '../core';
import { getSelectionForDictation } from '../plugins/expandSelection/useExpandSelection';

export const getEditorDictationSelectionSafe = (editor: EditorType): RangeType => {
  if (editor.selection == null) {
    throw new Error('Cannot get editor selection of null!');
  }

  const selection = getSelectionForDictation(editor) ?? editor.selection;

  if (selection == null) {
    throw new Error('Cannot get editor dictation selection of null!');
  }

  return selection;
};

export const getEditorSelectionSafe = (editor: EditorType): RangeType => {
  if (editor.selection == null) {
    throw new Error('Cannot get editor selection of null!');
  }

  return editor.selection;
};
