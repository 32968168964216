// @flow

import type { ViewportTypeKeys } from 'config/constants';
import {
  RESTRICTED_FUNCTIONALITY_TOOLS,
  SOP_CLASSES_TO_TOOLS_DISABLED_MAP,
  VIEW_TYPE_TO_TOOLS_DISABLED_MAP,
} from '../constants';

export const isToolDisabled = (
  tool: string,
  viewType: ViewportTypeKeys,
  sopClassUID: ?string,
  validSpacing: ?boolean
): boolean => {
  const isToolDisabledForSop =
    sopClassUID != null &&
    SOP_CLASSES_TO_TOOLS_DISABLED_MAP[sopClassUID] &&
    SOP_CLASSES_TO_TOOLS_DISABLED_MAP[sopClassUID].includes(tool);

  const isToolDisabledForViewType =
    VIEW_TYPE_TO_TOOLS_DISABLED_MAP[viewType] &&
    VIEW_TYPE_TO_TOOLS_DISABLED_MAP[viewType].includes(tool);

  const isToolDisabledForSpacing =
    validSpacing === false && RESTRICTED_FUNCTIONALITY_TOOLS.includes(tool);

  return isToolDisabledForSop || isToolDisabledForViewType || isToolDisabledForSpacing;
};
