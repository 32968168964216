// @flow

import type { NodeType, PathType, EditorType } from '../../RichTextEditor/core';
import { ReactEditor, Node, Editor, Transforms } from '../../RichTextEditor/core';
import type { NavigationItem } from './BaseOutline';
// $FlowFixMe[untyped-import]
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { HeadingLevel } from '../../RichTextEditor/plugins/heading/constants';
import {
  isHeadingNode,
  removePunctuationAndWhitespace,
} from '../../RichTextEditor/plugins/heading/utils/normalization';
import { isEligibleNamedField } from '../../RichTextEditor/utils/fieldNaming';
import { getPathRefSafe } from '../../RichTextEditor/utils/refHelpers';
import type { SlateContent } from '../types';
import { walkSlateContent } from '../../RichTextEditor/utils/walkSlateContent';

export const getNavigationItemsFromReportContent = (
  content: SlateContent
): Array<NavigationItem> => {
  const items = [];
  if (content == null) {
    return [];
  }

  const addNavigationItem = (node: NodeType) => {
    items.push(getNavigationItem(node, null, null));
  };

  walkSlateContent(addNavigationItem)(content);

  // $FlowIgnore[incompatible-return] we are filtering out the nulls
  return items.filter((item) => item != null);
};

export const getNavigationItemsFromEditor = (editor: ?EditorType): Array<NavigationItem> => {
  const items = [];
  if (editor?.children == null) {
    return [];
  }

  for (const [node, path] of Node.nodes(editor)) {
    items.push(getNavigationItem(node, path, editor));
  }
  // $FlowIgnore[incompatible-return] we are filtering out the nulls
  return items.filter((node) => node != null);
};

export const getNavigationItem = (
  node: NodeType,
  path: ?PathType,
  editor: ?EditorType
): ?NavigationItem => {
  if (isHeadingNode(node, HeadingLevel.H1)) {
    return {
      node,
      name: removePunctuationAndWhitespace(Node.string(node)),
      path: path != null && editor != null ? Editor.pathRef(editor, path) : null,
    };
  } else if (isEligibleNamedField(String(node.type))) {
    return {
      node,
      name: removePunctuationAndWhitespace(String(node.name)),
      path: path != null && editor != null ? Editor.pathRef(editor, path) : null,
    };
  }

  return null;
};

export const selectNavigationItem = (editor: EditorType, item: NavigationItem): void => {
  const fieldPath = item.path != null ? getPathRefSafe(item.path) : null;
  if (fieldPath == null) {
    return;
  }

  try {
    Transforms.select(editor, fieldPath);

    // $FlowIgnore[prop-missing] ReactEditorType vs EditorType
    const domRange = ReactEditor.toDOMRange(editor, Editor.range(editor, fieldPath));
    const domSelection = window.getSelection();

    domSelection.removeAllRanges();
    domSelection.addRange(domRange);
    const [targetNode] = Editor.node(editor, fieldPath);
    // $FlowIgnore[prop-missing] ReactEditorType vs EditorType
    const target = ReactEditor.toDOMNode(editor, targetNode);

    scrollIntoViewIfNeeded(target, {
      behavior: 'smooth',
      scrollMode: 'if-needed',
      block: 'end',
    });
  } catch (e) {
    // no-op, this can happen if we run Slate headlessly since there will be no
    // associated DOM
  }
};
