// @flow

import { useSelected } from '../../../core';
import type {
  MacroPlaceholderPluginElement,
  MacroPlaceholderPluginPropertyOptions,
} from '../types';
import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { unreachableCaseError } from 'types';
import { useReporterState } from '../../../hooks';
import styled from 'styled-components';
import { MacroPlaceholderTemplateElement } from './MacroPlaceholderTemplate';

export const MacroPlaceholderEditable: GetRenderElementComponentType<
  MacroPlaceholderPluginPropertyOptions,
  MacroPlaceholderPluginElement,
> = ({ pluginOptions: { pluginID }, element, ...rest }): React$Node => {
  const { variant } = useReporterState();
  const selected = useSelected();

  switch (variant) {
    case 'report':
      throw new Error('Macros cannot be a part of report content.');
    case 'fragment':
    case 'template':
      return <MacroPlaceholderTemplateElement {...rest} selected={selected} element={element} />;

    default:
      unreachableCaseError(variant);
  }
};

// $FlowIgnore[signature-verification-failure] Styled components types won't play nice
export const MacroPlaceholderRenderer = styled(MacroPlaceholderTemplateElement)`
  white-space: pre-wrap;
`;
