// @flow

import type { vtkRenderer } from '@kitware/vtk.js';

import { sleep } from 'utils/sleep';
import { useContext } from 'react';
import { Contexts } from 'react-vtk-js';

type Status = 'pending' | 'error' | 'success';

const MAX_ATTEMPTS = 20;
const SLEEP_TIME = 100;

/**
 * This waits for the renderer to be available and suspends the component
 * until it is. It will throw an error if the renderer is not available
 * after the specified number of attempts.
 * The error can be caught and handled by the parent component with an
 * ErrorBoundary.
 */
export function useRenderer(): vtkRenderer {
  const view = useContext(Contexts.ViewContext);
  let result;

  let status: Status = 'pending';

  const promise = new Promise(async (resolve, reject) => {
    let openGLRenderWindow = view?.getOpenGLRenderWindow()?.get();
    let attempts = 0;
    while ((view == null || openGLRenderWindow == null) && attempts < MAX_ATTEMPTS) {
      attempts += 1;
      await sleep(SLEEP_TIME);
      openGLRenderWindow = view?.getOpenGLRenderWindow()?.get();
    }

    if (attempts >= MAX_ATTEMPTS || view == null || openGLRenderWindow == null) {
      status = 'error';
      return reject(new Error('No openGLRenderWindow found'));
    }

    const renderer = view.getRenderer()?.get();
    if (renderer != null) {
      result = renderer;
      status = 'success';
      return resolve(result);
    }

    openGLRenderWindow.onModified(() => {
      const renderer = view.getRenderer()?.get();
      if (renderer == null) {
        return;
      }

      result = renderer;
      status = 'success';
      resolve(result);
    });
  });

  switch (status) {
    case 'pending':
      throw promise;
    case 'error':
      throw new Error('No renderer found');
    case 'success':
      if (result == null) {
        throw new Error('Unrechable error: renderer should always be defined at this point');
      }
      return result;
    default:
      throw new Error(`Unknown status: ${status}`);
  }
}
