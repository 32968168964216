// @flow
import { useMemo, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import type { PickResult } from 'react-vtk-js';
import { useSetRecoilState, useRecoilCallback } from 'recoil';
import { useLatest } from 'react-use';
import { selectedPrimitiveState, activePrimitiveState, useViewportId } from './state';
import { decodeId } from '../utils/representationId';
import { useEmitter } from './useEmitter';
import { rafThrottle } from 'utils/rafThrottle';
import type { DreMouseEvent, MouseHandlers } from './types';
import { useDragEvents } from './useDragEvents';
import { mouseButtonToVTKButton } from '../constants';
import type { MouseButtonValue } from '../constants';
import { env } from 'config/env';

const EVENT_MOUSE_BUTTON = {
  left: 0,
  middle: 1,
  right: 2,
};

export type DreEvent = {
  encodedId: ?string,
  info: { [string]: string },
  displayPosition: { x: number, y: number },
  button?: MouseButtonValue,
  event: SyntheticMouseEvent<Element>,
};
export type MouseHandler = (PickResult, SyntheticMouseEvent<Element>) => void;
export type PickerMode =
  | 'click'
  | 'mouseDown'
  | 'mouseUp'
  | 'mouseMove'
  | 'hover'
  | 'select'
  | 'doubleClick'
  | 'contextMenu';

export const usePicker = ({
  viewportId,
  interactionPaused,
}: {
  viewportId: string,
  interactionPaused: boolean,
}): ({
  onClick: MouseHandler,
  onMouseDown: MouseHandler,
  onMouseUp: MouseHandler,
  onMouseOver: MouseHandler,
  onMouseMove: (SyntheticMouseEvent<Element>) => mixed,
  onSelect: MouseHandler,
}) => {
  const emitter = useEmitter(viewportId);
  const setSelected = useSetRecoilState(selectedPrimitiveState(viewportId));
  const setActive = useSetRecoilState(activePrimitiveState);

  const mouseHandler = useCallback(
    (mode: PickerMode) =>
      (data: PickResult | { representationId: null }, event: SyntheticMouseEvent<Element>) => {
        let actions = [mode];
        const { representationId: encodedId } = data ?? {};

        // If the ID is provided, we mark it as selected primitive
        if (encodedId != null) {
          setSelected(encodedId);
        }

        // If the event is a "mouse leave" we unset the selected primitive
        // We know that `hover` will be triggered once with `null` as ID
        // when the mouse moves out from a primitive
        if (mode === 'hover' && encodedId == null) {
          setSelected(null);
        }

        // Consider this an implementation of document#activeElement
        // It allows primitives to gain an active state when clicked
        // and lose it when something else is clicked
        if (mode === 'click') {
          setActive(encodedId);
        }

        // If the number of clicks is 2, register it as double click instead
        if (mode === 'click' && event.detail === 2) {
          actions = ['doubleClick'];
        }

        // If on mouse up the right button is pressed, we register it as a context menu
        if (mode === 'mouseUp' && event.button === EVENT_MOUSE_BUTTON.right) {
          actions = [...actions, 'contextMenu'];
        }

        actions = actions.filter((action) => {
          if (interactionPaused) {
            return !['mouseDown', 'click', 'doubleClick'].includes(action);
          }
          return true;
        });

        actions.forEach((action) => {
          emitter.emit(action, {
            encodedId,
            info: encodedId != null ? decodeId(encodedId) : {},
            displayPosition: { x: event.pageX, y: event.pageY },
            button: mouseButtonToVTKButton(event.button),
            event,
          });
        });
      },
    [emitter, setActive, setSelected, interactionPaused]
  );

  const mouseMoveHandler = useCallback(
    (event: SyntheticMouseEvent<Element>) => {
      mouseHandler('mouseMove')(
        {
          representationId: null,
        },
        event
      );
    },
    [mouseHandler]
  );

  const handlers = useMemo(
    () => ({
      onClick: mouseHandler('click'),
      onMouseDown: mouseHandler('mouseDown'),
      onMouseUp: mouseHandler('mouseUp'),
      onMouseOver: mouseHandler('hover'),
      onMouseMove: rafThrottle(mouseMoveHandler),
      onSelect: mouseHandler('select'),
    }),
    [mouseHandler, mouseMoveHandler]
  );

  return handlers;
};

const usePickerListener = (callback: ({ action: PickerMode, ...DreEvent }) => mixed) => {
  const viewportId = useViewportId();
  const emitter = useEmitter(viewportId);

  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (
      action: PickerMode,
      { encodedId, info, displayPosition, button, event }: $FlowFixMe | DreEvent
    ) => {
      if (action == null) return;
      callbackRef.current({
        action,
        encodedId,
        displayPosition,
        info,
        button,
        event,
      });
    };
    emitter.on<DreEvent>('*', handler);
    return () => {
      emitter.off('*', handler);
    };
  }, [emitter]);
};

export const usePickerEvent = ({
  encodedId,
  onClick,
  onDoubleClick,
  onMouseOver,
  onSelect,
  onMouseLeave,
  onMouseEnter,
  onMouseDown,
  onMouseUp,
  onDragStart,
  onDrag,
  onDragEnd,
  onContextMenu,
  __test__onAfterMouseLeave,
}: {
  encodedId: string,
  onClick?: ?(DreMouseEvent) => mixed,
  onDoubleClick?: ?(DreMouseEvent) => mixed,
  onMouseOver?: ?(DreMouseEvent) => mixed,
  onMouseLeave?: ?(DreMouseEvent) => mixed,
  onMouseEnter?: ?(DreMouseEvent) => mixed,
  onMouseDown?: ?(DreMouseEvent) => mixed,
  onMouseUp?: ?(DreMouseEvent) => mixed,
  onSelect?: ?(DreMouseEvent) => mixed,
  onDragStart?: ?(DreMouseEvent) => mixed,
  onDrag?: ?(DreMouseEvent) => mixed,
  onDragEnd?: ?(DreMouseEvent) => mixed,
  onContextMenu?: ?(DreMouseEvent) => mixed,
  // DO NOT USE THIS, it's only for testing purposes
  __test__onAfterMouseLeave?: ?(DreMouseEvent) => mixed,
}): void => {
  const viewportId = useViewportId();

  const dragHandlers = useDragEvents({ onDragStart, onDrag, onDragEnd });

  const pickerHandler = useRecoilCallback(
    ({ snapshot }) =>
      async ({ action, encodedId: newEncodedId, info, displayPosition, button }) => {
        const selected = await snapshot.getPromise(selectedPrimitiveState(viewportId));
        const { id, type, name } = info;
        const newInfo = { id, type, name, displayPosition, button };

        // In any case, if the user mouses up, we want to stop the drag event
        if (action === 'mouseUp') {
          dragHandlers.onMouseUp?.(newInfo);
        }

        if (newEncodedId !== encodedId) {
          // if the mouse has left the primitive, trigger a mouseLeave event
          if (action === 'hover' || (selected === encodedId && action !== 'mouseMove')) {
            onMouseLeave?.(newInfo);
          }

          // This is only for testing purposes
          // There's no way to assert of onMouseLeave "not to be called" as it is async
          // So we use this to wait for the whole logic to be completed before
          // asserting that onMouseLeave has not been called
          if (env.TEST === 'true' && __test__onAfterMouseLeave) {
            __test__onAfterMouseLeave(newInfo);
          }

          // skip the rest of the logic
          return;
        }

        // if the mouse has entered the primitive, trigger a mouseEnter event
        if (action === 'hover' && selected !== newEncodedId) {
          onMouseEnter != null && onMouseEnter(newInfo);
        }

        switch (action) {
          case 'click':
            onClick?.(newInfo);
            break;
          case 'doubleClick':
            onDoubleClick?.(newInfo);
            break;
          case 'mouseDown':
            onMouseDown?.(newInfo);
            dragHandlers.onMouseDown?.(newInfo);
            break;
          case 'mouseUp':
            onMouseUp?.(newInfo);
            break;
          case 'hover':
            onMouseOver?.(newInfo);
            break;
          case 'select':
            onSelect?.(newInfo);
            break;
          case 'mouseMove':
            break;
          case 'contextMenu':
            onContextMenu?.(newInfo);
            break;
          default:
            throw new Error(`Unknown action: ${action}`);
        }
      }
  );

  usePickerListener(pickerHandler);
};

const WithPickerEvent = ({ id, ...props }: { ...MouseHandlers, id: string }) => {
  usePickerEvent({
    encodedId: id,
    ...props,
  });
  return null;
};

export type WithPickerEventReturn<T> = React$ComponentType<{
  ...MouseHandlers,
  ...T,
  id: string,
  visible?: boolean,
  enablePickResults?: boolean,
}>;

export const withPickerEvent = <T>(
  Component: React$ComponentType<{ ...T, id: string, visible?: boolean }>
): WithPickerEventReturn<T> =>
  function WithPickerEventHOC({
    id,
    visible = true,
    enablePickResults = true,
    onClick,
    onMouseOver,
    onSelect,
    onMouseLeave,
    onMouseEnter,
    onMouseDown,
    onMouseUp,
    onDragStart,
    onDrag,
    onDragEnd,
    ...props
  }) {
    return (
      <>
        {visible && enablePickResults && (
          <WithPickerEvent
            id={id}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onSelect={onSelect}
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
          />
        )}

        <Component id={id} visible={visible} {...props} />
      </>
    );
  };

export type PickerEvent = {
  id: ?string,
  type: ?string,
  name: ?string,
  displayPosition: ?{ x: number, y: number },
  button?: number,
  originalEvent: SyntheticMouseEvent<Element>,
};

export const useEventAction = (
  desiredAction: PickerMode,
  callback: (PickerEvent) => mixed
): void => {
  const viewportId = useViewportId();
  const emitter = useEmitter(viewportId);
  const latestCallback = useLatest(callback);

  useEffect(
    () => {
      const handler = ({ encodedId, info, displayPosition, button, event }: DreEvent) => {
        const { id, type, name } = info;
        latestCallback.current({ id, type, name, displayPosition, button, originalEvent: event });
      };
      emitter.on<DreEvent>(desiredAction, handler);
      return () => {
        emitter.off<DreEvent>(desiredAction, handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desiredAction, emitter]
  );
};
