// @flow

import { Text, Editor, Transforms } from '../core';
import type { EditorType, PathType } from '../core';
import { createEditorWarning, partialEditor } from '.';
import { clone } from 'ramda';
import type { Fragment } from '../types';
import { getPathRefSafe } from './refHelpers';
import { logger } from 'modules/logger';

export const replaceNodeAtPath = (
  editor: EditorType,
  fragment: Fragment,
  {
    at,
  }: {
    at: PathType,
  }
) => {
  if (fragment.length === 0) {
    const errorMessage =
      '[replaceNodeAtPath] Cannot stitch fragment to editor if it contains no nodes. Ignoring.';

    createEditorWarning(errorMessage);
    logger.error(errorMessage, {
      editor: editor != null ? partialEditor(editor) : 'null',
      at,
    });
    return;
  }

  Editor.withoutNormalizing(editor, () => {
    const insertPathRef = Editor.pathRef(editor, at);
    fragment.forEach((node) => {
      if (Text.isText(node)) {
        Transforms.insertNodes(editor, clone(node), {
          at: getPathRefSafe(insertPathRef),
          select: false,
        });
      } else if (editor.isInline(node)) {
        Transforms.insertNodes(editor, clone(node), {
          at: getPathRefSafe(insertPathRef),
          select: false,
        });
      } else {
        if (getPathRefSafe(insertPathRef).length === 1) {
          Transforms.insertNodes(editor, clone(node), {
            at: getPathRefSafe(insertPathRef),
            select: false,
          });
        } else {
          Transforms.splitNodes(editor, {
            always: true,
            at: getPathRefSafe(insertPathRef),
          });
          Transforms.insertNodes(editor, clone(node.children), {
            at: getPathRefSafe(insertPathRef),
            select: false,
          });
        }
      }
    });

    Transforms.removeNodes(editor, { at: getPathRefSafe(insertPathRef) });
    insertPathRef.unref();
  });
};
