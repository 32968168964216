// @flow

import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useSlateSingletonContext } from '../domains/reporter/Reporter/SlateSingletonContext';
import type { RangeType } from '../domains/reporter/RichTextEditor/core';
import { Editor } from '../domains/reporter/RichTextEditor/core';
import { logger } from 'modules/logger';
import { getSurroundingTextString } from 'domains/reporter/RichTextEditor/utils/getSurroundingTextString';

import type { SlateContent } from 'domains/reporter/Reporter/types';
import { useCurrentCaseId } from './useCurrentCase';
import { usePrevious } from 'react-use';
type EditorBackup = {
  content: ?SlateContent,
  selection: ?RangeType,
};

type UseEditorBackupReturn = {
  editorBackup: EditorBackup,
  setEditorBackup: (SlateContent) => void,
  restoreEditorBackup: (SlateContent) => void,
};

const MAX_RECENT_EDITOR_LOGS = 10;

export const useEditorBackup = (): UseEditorBackupReturn => {
  const [{ editor }] = useSlateSingletonContext();

  const currentCaseId = useCurrentCaseId();
  const previousCaseId = usePrevious(currentCaseId);

  const [editorBackup, setEditorBackup] = useState<EditorBackup>({
    content: editor?.children ?? null,
    selection: editor?.selection ?? null,
  });

  const editorNonNullRef = useRef<boolean>(false);

  useEffect(() => {
    if (previousCaseId != null && previousCaseId !== currentCaseId) {
      setEditorBackup({ content: null, selection: null });
      editorNonNullRef.current = false;
      return;
    }
  }, [currentCaseId, previousCaseId]);

  // only automatically set the editor backup once upon initial render when editor is not null
  useEffect(() => {
    if (
      editor?.children != null &&
      editor?.selection != null &&
      editorNonNullRef.current === false
    ) {
      setEditorBackup({ content: editor.children, selection: editor.selection });
      editorNonNullRef.current = true;
    }
  }, [editor?.children, editor?.selection]);

  const restoreEditorBackup = useCallback(
    (priorContent: SlateContent) => {
      if (editor == null || editor.selection == null) return;

      const { content, selection } = editorBackup;
      if (content == null || selection == null) return;

      Editor.withoutNormalizing(editor, () => {
        editor.children = content;
        editor.selection = selection;
      });

      const surroundingTextString = getSurroundingTextString(editor);

      logger.info(
        `[useEditorBackup] Backup restore of editor content complete, cursor moved to: "${surroundingTextString}"`,
        {
          backupSelection: selection,
          backupContent: content,
          priorContent,
          // in a long running session, the editor.log.ops can be very long
          recentOpsEditorOpsLog: editor.log?.ops.slice(-MAX_RECENT_EDITOR_LOGS) ?? 'n/a',
        }
      );
    },
    [editor, editorBackup]
  );

  const setEditorBackupCallback = useCallback(
    (content: SlateContent) => {
      if (editor == null || editor.selection == null) return;

      setEditorBackup({
        content,
        selection: editor.selection,
      });
    },
    [editor]
  );

  return useMemo(
    () => ({
      editorBackup,
      setEditorBackup: setEditorBackupCallback,
      restoreEditorBackup,
    }),
    [editorBackup, restoreEditorBackup, setEditorBackupCallback]
  );
};
