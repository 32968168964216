// @flow

import type { NodeType } from 'slate';

import { useOpsLogger } from '../hooks/useOpsLogger';

type Props = {
  initialState: NodeType[],
};

export function ReporterOpsLogger({ initialState }: Props): React$Node {
  useOpsLogger(initialState);
  return null;
}
