// @flow
import { LIST_VARIANTS } from '../constants';
import { useCurrentList } from './useCurrentList';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import {
  getBulletedListHotkey,
  getDecreaseIndentHotkey,
  getIncreaseIndentHotkey,
  getNumberedListHotkey,
} from '../utils';
import { useSlateSingletonContext } from '../../../../Reporter/SlateSingletonContext';
import { toggleList } from '../toggleFunctions';

export type ToolbarButtonConfig = {
  icon: React$Node,
  label: string,
  secondaryLabel: string,
  onMouseDown: () => void,
  selected?: boolean,
};

export type UseListInteractionReturn = {
  toolbarButtonConfigs: Array<ToolbarButtonConfig>,
  toolbarDividerIndex: number,
};

export const useListInteraction = (): UseListInteractionReturn => {
  const { currentActiveListVariant, decreaseIndent, increaseIndent } = useCurrentList();

  const [{ editor }] = useSlateSingletonContext();

  // The order of this array represents the order it will show up on the toolbar.
  // The TOOLBAR_DIVIDER_INDEX represents the position at which the divider will slice the array
  const TOOLBAR_DIVIDER_INDEX = 2;
  const toolbarButtonConfigs: Array<ToolbarButtonConfig> = [
    {
      icon: <FormatListBulletedIcon />,
      label: 'Bulleted List',
      secondaryLabel: getBulletedListHotkey(true),
      onMouseDown: () => toggleList(editor, currentActiveListVariant, LIST_VARIANTS.ul),
      selected: currentActiveListVariant === LIST_VARIANTS.ul,
    },
    {
      icon: <FormatListNumberedIcon />,
      label: 'Numbered List',
      secondaryLabel: getNumberedListHotkey(true),
      onMouseDown: () => toggleList(editor, currentActiveListVariant, LIST_VARIANTS.ol),
      selected: currentActiveListVariant === LIST_VARIANTS.ol,
    },
    {
      icon: <FormatIndentDecreaseIcon />,
      label: 'Decrease Indent',
      secondaryLabel: getDecreaseIndentHotkey(true),
      onMouseDown: decreaseIndent,
    },
    {
      icon: <FormatIndentIncreaseIcon />,
      label: 'Increase Indent',
      secondaryLabel: getIncreaseIndentHotkey(true),
      onMouseDown: increaseIndent,
    },
  ];

  return {
    toolbarButtonConfigs,
    toolbarDividerIndex: TOOLBAR_DIVIDER_INDEX,
  };
};
