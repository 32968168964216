// @flow
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import type { EditorType } from '../core';
import { omit } from 'ramda';
import { useLayoutEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { ReactEditor, useSlateSelection } from 'slate-react';
import { css } from 'styled-components';
import { Colors } from 'styles';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useReporterStyles } from 'hooks/useReporterStyles';
import { UNIFIED_EDITOR_PADDING, UNIFIED_EDITOR_VERTICAL_PADDING } from './UnifiedEditor';
import { proceduresScopeState } from '../../Reporter/state';
import { useRecoilValue } from 'recoil';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags';
import { REPORT_OUTLINE_WIDTH } from '../../Reporter/Outline/BaseOutline';
import { useReporterState } from '../hooks/useReporter';

export type LineIndicatorProps = {
  containerWidth: number,
  containerHeight: number,
  variant: 'arrow' | 'dot',
  placement: 'left' | 'right',
  editor: EditorType,
  isAddendumEditor: boolean,
};

export function LineIndicator(props: LineIndicatorProps): React$Node {
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { editor } = props;

  const { data } = useCurrentUser();
  const reporterStyles = useReporterStyles(data?.me?.reporterSettings);
  const { styles, state } = usePopper(referenceElement, popperElement, {
    // Intentionally not using props.placement. Basing the calculations always off
    // one placement makes the math simpler.
    placement: 'right',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });
  const proceduresScope = useRecoilValue(proceduresScopeState);
  const [isReportOutlineEnabled] = useFeatureFlagEnabled(FF.REPORT_OUTLINE);
  const { variant } = useReporterState();

  const selection = useSlateSelection();

  useLayoutEffect(() => {
    if (selection == null || editor == null) {
      setReferenceElement(null);
      return;
    }

    // The DOM selection lags behind the editor selection.
    // We wait for one tick of the event loop to let it catch up,
    // before updating the reference element position.
    setTimeout(() => {
      try {
        // $FlowIgnore[prop-missing] - It's there, the singleton is mutated for Slate-React
        const domRange = ReactEditor.toDOMRange(editor, selection);
        // $FlowIgnore[incompatible-call] it works
        setReferenceElement(domRange);
      } catch {
        setTimeout(() => {
          const domSelection = window.getSelection();
          if (domSelection != null) {
            const domRange = domSelection.getRangeAt(0);
            setReferenceElement(domRange);
          }
        }, 0);
      }
    }, 0);
  }, [editor, selection, reporterStyles, proceduresScope]);

  if (referenceElement == null) {
    return null;
  }

  const xOffset = variant === 'report' ? 6 : 0;
  const popperStyles = omit(['transform'], styles.popper || {});
  const arrowStyles = omit(['transform'], styles.arrow || {});
  const popperOffsetY = Math.round(state?.modifiersData?.popperOffsets?.y ?? 0);
  const leftArrowMargin =
    isReportOutlineEnabled && !props.isAddendumEditor
      ? REPORT_OUTLINE_WIDTH + xOffset
      : -UNIFIED_EDITOR_PADDING - 1;
  const rightArrowMargin = props.containerWidth - UNIFIED_EDITOR_PADDING * 2 + 2;
  const rightDotMargin = props.containerWidth - UNIFIED_EDITOR_VERTICAL_PADDING - 8;
  const leftDotMargin =
    isReportOutlineEnabled && !props.isAddendumEditor ? REPORT_OUTLINE_WIDTH + 8 : 2;

  return (
    <div
      data-testid="line-indicator"
      ref={setPopperElement}
      {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
      style={{
        transform: `translate(0px, ${popperOffsetY}px)`,
      }}
      css={css`
        ${css(popperStyles)};
        background-color: transparent;
        display: flex;
        align-content: center;
        z-index: 0;
        height: 35px;
      `}
    >
      {props.variant === 'arrow' && (
        <div
          css={css`
            ${css(arrowStyles)};
            display: flex;
            align-content: center;
          `}
          style={{
            transform: `translate(
              ${props.placement === 'left' ? leftArrowMargin : rightArrowMargin}px,
              0px
            )`,
          }}
          ref={setArrowElement}
        >
          {props.placement === 'left' && (
            <ArrowRightIcon
              fontSize="large"
              css={css`
                color: ${Colors.blue5};
              `}
            />
          )}
          {props.placement === 'right' && (
            <ArrowLeftIcon
              fontSize="large"
              css={css`
                color: ${Colors.blue5};
              `}
            />
          )}
        </div>
      )}
      {props.variant === 'dot' && (
        <div
          css={css`
            ${css(arrowStyles)};
            display: flex;
            align-content: center;
          `}
          style={{
            transform: `translate(
              ${props.placement === 'left' ? leftDotMargin : rightDotMargin}px,
              14px
            )`,
          }}
          ref={setArrowElement}
        >
          <div
            css={css`
              height: 8px;
              width: 8px;
              border-radius: 9999px;
              background-color: ${Colors.gray10};
            `}
          />
        </div>
      )}
    </div>
  );
}
