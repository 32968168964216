// @flow

import { css } from 'styled-components';

import { useState, useLayoutEffect, useMemo, Fragment } from 'react';
import { usePopper } from 'react-popper';
import { Range, Editor } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { Colors } from 'styles';

import { getOppositePlacement, some, hasMultipleInSelection } from '../utils';
import { usePlugins } from '../hooks';
import { BRACKET_TYPES } from '../constants';
import { HeadingLevel } from '../plugins/heading/constants';
import { HEADING_PLUGIN_ID } from '../plugins/heading/types';
import Divider from '@material-ui/core/Divider';
import type { AllPluginID } from '../plugins/types';
import type { HoveringToolbarExtraProps } from '../types/plugin';
import type { EditorType, NodeType } from 'domains/reporter/RichTextEditor/core';
import { AUTOCORRECT_PLUGIN_ID } from '../plugins/autoCorrect/types';
import { isInsideListAtSelection } from '../plugins/list/utils';
import { areMultipleBracketsSelected } from '../utils/multipleBracketsSelected';
import { PRIOR_REPORT_ZINDEX } from 'domains/reporter/Reporter/PriorReport';
import { env } from 'config/env';
import { useMostRecentInput, INPUT_TYPES, RICH_TEXT_EDITOR } from 'hooks/useMostRecentInput';
import type { InputType } from 'hooks/useMostRecentInput';
import { useReporterState } from '../hooks/useReporter';
import { PICKLIST_PLUGIN_ID } from '../plugins/picklist/types';
import { find } from '../utils/find';
import { isSelectionContainedInNode } from '../utils/isSelectionContainedInNode';

export const getDisplayablePluginIDs = ({
  editor,
  pluginIDs: _pluginIDs,
  mostRecentInput,
}: {
  editor: EditorType,
  pluginIDs: Array<AllPluginID>,
  mostRecentInput: InputType,
}): Array<AllPluginID> => {
  let pluginIDs = _pluginIDs;
  const selection = editor.selection;
  if (selection == null) {
    return pluginIDs;
  }

  if (areMultipleBracketsSelected(editor)) {
    return [];
  }

  if (hasMultipleInSelection(editor, 'paragraph')) {
    pluginIDs = pluginIDs.filter((pluginID) => pluginID !== AUTOCORRECT_PLUGIN_ID);
  }

  if (isInsideListAtSelection(editor)) {
    pluginIDs = pluginIDs.filter((pluginID) => pluginID !== HEADING_PLUGIN_ID);
  }

  // if selection spans multiple nodes of different types, hide toolbar
  const uniqueNodeTypes = new Set();
  for (const [node] of Editor.nodes(editor, { at: selection })) {
    if (!Editor.isEditor(node) && node.type != null) {
      uniqueNodeTypes.add(node.type);
    }
  }
  if (uniqueNodeTypes.size > 1 && hasMultipleInSelection(editor, 'paragraph')) {
    return [];
  }

  // If we are inside a bracket, do not show heading buttons
  if (some(editor, (n: NodeType) => BRACKET_TYPES.includes(n.type))) {
    pluginIDs = pluginIDs.filter((pluginID) => pluginID !== HEADING_PLUGIN_ID);
  }

  if (mostRecentInput !== INPUT_TYPES.MOUSE) {
    pluginIDs = [];
  }

  return pluginIDs;
};

export const HoveringToolbar = (): React$Node => {
  const editor = useSlate();
  const { getHoveringToolbarConfigs } = usePlugins();

  const activatedToolbarConfigs = useMemo(() => {
    return getHoveringToolbarConfigs()
      .map((config) => config())
      .flat();
  }, [getHoveringToolbarConfigs]);

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [displayableToolbarConfigs, setDisplayableToolbarConfigs] =
    useState(activatedToolbarConfigs);
  const { mostRecentInput } = useMostRecentInput(RICH_TEXT_EDITOR);
  const { variant } = useReporterState();
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      { name: 'offset', options: { offset: [0, 10] } },
      { name: 'arrow', options: { element: arrowElement } },
    ],
  });

  useLayoutEffect(() => {
    const { selection } = editor;

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      if (referenceElement != null) {
        setReferenceElement(null);
      }
      return;
    }

    // We do not allow you to directly edit a picklist inside the template or macro editors. You must edit
    // the options inside the pane. As a result, we hide the hovering toolbar when the selection is entirely contained within a picklist
    // entry. The hovering toolbar should instead be used inside the pane.
    if (['template', 'fragment'].includes(variant)) {
      const picklistEntry = find(editor, (n) => n.type === PICKLIST_PLUGIN_ID);
      if (
        picklistEntry != null &&
        isSelectionContainedInNode(editor, picklistEntry[1], selection)
      ) {
        if (referenceElement != null) {
          setReferenceElement(null);
        }
        return;
      }
    }

    // The DOM selection lags behind the editor selection.
    // We wait for one tick of the event loop to let it catch up,
    // before updating the reference element position.
    setTimeout(
      () => {
        const domSelection = window.getSelection();
        if (domSelection && domSelection.rangeCount > 0 && !domSelection.isCollapsed) {
          const domRange = domSelection.getRangeAt(0);
          setReferenceElement(domRange);
        }

        const displayablePluginIDs: AllPluginID[] = getDisplayablePluginIDs({
          editor,
          pluginIDs: activatedToolbarConfigs.map((toolbarConfig) => toolbarConfig.pluginID),
          mostRecentInput,
        });

        const displayableToolbarConfigs = activatedToolbarConfigs.filter((toolbarConfig) =>
          displayablePluginIDs.includes(toolbarConfig.pluginID)
        );

        if (displayableToolbarConfigs.length === 0) {
          setReferenceElement(null);
        } else {
          setDisplayableToolbarConfigs(displayableToolbarConfigs);
        }
      },
      env.STORYBOOK_STORYSHOTS === 'true' ? 50 : 0
    );
  }, [
    activatedToolbarConfigs,
    editor.selection,
    editor,
    mostRecentInput,
    referenceElement,
    variant,
  ]);

  return referenceElement ? (
    <div
      {...(state?.attributes?.popper != null ? state.attributes.popper : {})}
      ref={setPopperElement}
      css={css`
        ${css(styles.popper || {})};
        background-color: ${Colors.gray3};
        padding: 5px 10px;
        border-radius: 6px;
        display: flex;
        align-content: center;
        z-index: ${PRIOR_REPORT_ZINDEX + 3};
        &[data-popper-reference-hidden='true'] {
          visibility: hidden;
          pointer-events: none;
        }
      `}
    >
      {displayableToolbarConfigs.map(
        ({
          toolbarButton,
          pluginID,
          options,
        }: {
          toolbarButton: React$Node,
          pluginID: AllPluginID,
          options?: HoveringToolbarExtraProps,
        }) => {
          const fragment = <Fragment key={pluginID}>{toolbarButton}</Fragment>;
          if (
            displayableToolbarConfigs.length > 1 &&
            ((pluginID === HEADING_PLUGIN_ID && options?.level === HeadingLevel.H2) ||
              pluginID === AUTOCORRECT_PLUGIN_ID)
          ) {
            return (
              <div css="display: flex; margin-left: 4px" key={pluginID + String(options?.level)}>
                {fragment}
                <Divider css="width: 1px; height: 2rem; margin: 0.25rem 0.5rem !important;" />
              </div>
            );
          }
          return fragment;
        }
      )}

      <div
        ref={setArrowElement}
        css={css`
          ${css(styles.arrow || {})};
          transform: ${styles.arrow?.transform} rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: ${Colors.gray3};

          ${state?.placement
            ? css`
                ${getOppositePlacement(state?.placement)}: -5px;
              `
            : undefined}
        `}
      />
    </div>
  ) : null;
};
