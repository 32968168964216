// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const UNDERLINE_PLUGIN_ID: 'underline' = 'underline';
export type UnderlinePluginID = typeof UNDERLINE_PLUGIN_ID;

export type UnderlinePluginElement = EditorElement;

export type UnderlinePluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,
}>;

export type UnderlinePluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...UnderlinePluginOptions,
  pluginID: UnderlinePluginID,
  hotkey: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  icon: ComponentType<any>,
}>;
