// @flow
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';
import { useQuery } from '@apollo/client';
import { GET_MY_QUEUE } from 'modules/Apollo/queries';
import type { GetMyQueueQuery, GetMyQueueQueryVariables } from 'generated/graphql';
import { useMemo } from 'react';

export type UseIsCaseGroupSubmittingResult = $ReadOnly<{
  caseInGroupSubmitting: ?GetMyQueueQuery['worklistItems']['items'][0],
  isCaseGroupSubmitting: boolean,
  isCurrentCaseSubmitting: boolean,
  submittedAt: ?Date,
}>;

export const useIsCaseGroupSubmitting = (): UseIsCaseGroupSubmittingResult => {
  const { currentCaseReport } = useCurrentCaseReport();

  const { data } = useCurrentUser();
  const myId = data?.me?.id;

  // Get all worklist items in my queue
  const { data: casesData } = useQuery<GetMyQueueQuery, GetMyQueueQueryVariables>(GET_MY_QUEUE, {
    fetchPolicy: 'cache-and-network',
    variables:
      myId == null
        ? undefined
        : {
            claimedBy: myId,
          },
    skip: myId == null,
  });

  const myQueue = casesData?.worklistItems.items;

  const caseInGroupSubmitting =
    currentCaseReport?.groupId != null && myQueue != null && myQueue.length > 0
      ? myQueue.find(
          (item) => item.groupId === currentCaseReport.groupId && item.report?.submittedAt != null
        )
      : null;
  const isCaseGroupSubmitting = caseInGroupSubmitting != null;
  const isCurrentCaseSubmitting =
    caseInGroupSubmitting != null &&
    currentCaseReport != null &&
    caseInGroupSubmitting.smid === currentCaseReport.smid;
  const submittedAt = isCaseGroupSubmitting ? caseInGroupSubmitting?.report?.submittedAt : null;

  const result = useMemo(
    () => ({
      caseInGroupSubmitting,
      isCaseGroupSubmitting,
      isCurrentCaseSubmitting,
      submittedAt,
    }),
    [caseInGroupSubmitting, isCaseGroupSubmitting, isCurrentCaseSubmitting, submittedAt]
  );

  return result;
};
