// @flow

import type { DreAllViewportTypes } from 'config/constants';
import type { BaseImagingProvider, EmitterEvent } from './BaseImagingProvider';
import debounce from 'lodash.debounce';
import analytics from 'modules/analytics';
import { viewer } from 'modules/analytics/constants';
import { rafThrottle } from 'utils/rafThrottle';
import type { ThrottledCallback } from 'utils/rafThrottle';
import type {
  FullMultiLayerStack,
  FullSingleLayerStack,
  Stack,
} from '../../../ViewportsConfigurations/types';
export class RenderManager {
  /* A requestAnimationFrame throttled callback to update the VTKJS view + reactive app state */
  #rafThrottledCallback: ThrottledCallback<() => void>;

  #debouncedAnimationFrameCallback: (cb: () => void) => void;

  #providerUpdates: Map<string, [BaseImagingProvider<Stack>, string]>;
  #providersNeedingUpdates: Set<BaseImagingProvider<Stack>>;

  #frameDurations: Array<number>;

  constructor(sliceChangedDebounceTime?: number = 2000) {
    this.#providersNeedingUpdates = new Set();
    this.#providerUpdates = new Map();

    this.#frameDurations = [];

    this.#rafThrottledCallback = rafThrottle((updateCallback: () => void) => updateCallback());

    this.#debouncedAnimationFrameCallback = debounce(
      (cb: () => void) => cb(),
      sliceChangedDebounceTime
    );
  }

  queueUpdate<T: FullSingleLayerStack | FullMultiLayerStack>(
    provider: BaseImagingProvider<T>,
    event: string
  ) {
    this.#providersNeedingUpdates.add(provider);
    this.#providerUpdates.set(`${provider.linkSmid}-${event}`, [provider, event]);
  }

  throttledRender(viewType: DreAllViewportTypes) {
    this.#rafThrottledCallback(() => this._renderViews(viewType));
  }

  _renderViews(viewType: DreAllViewportTypes) {
    const start = Date.now();
    const updatesForDebounce: {
      [string]: { provider: BaseImagingProvider<Stack>, viewType: DreAllViewportTypes },
    } = {};
    // Emit the slice changed event for this series and all linked series.
    // This will update components that need to be reactive to the current active slice.
    this.#providerUpdates.forEach(([provider, event]) => {
      const activeSliceMatch = event.match(/(.*):activeSliceChanged/);

      if (activeSliceMatch != null) {
        const viewType = activeSliceMatch[1];
        updatesForDebounce[provider.linkSmid] = { provider, viewType };
      }
      provider.emitter.emit(event);
    });

    // If we've updated the active slice for a series, but it's not currently
    // being displayed in a viewport (such as when syncing from another viewer window),
    // we can just retrieve _any_ VTK view reference from any of the updating providers
    // and use it to re-render the view.

    const providerWithView = Array.from(this.#providersNeedingUpdates.values()).find((p) =>
      p.hasVtkView()
    );
    const vtkView = providerWithView?.viewRef.current;

    this.#providerUpdates.clear();
    this.#providersNeedingUpdates.clear();

    // Doing a renderView here runs at most once every 16ms, targeting EXACTLY 60fps.
    // View is only re-rendered for the source view (whose active slice changed), as
    // the view is shared between all viewports. Since the XYZ/IJK slice has already
    // been manually updated at this point, we only need to trigger a single re-render.
    //
    // This MUST come after the active slice change event emission and linked
    // series provider updates. Those calls update react state controlling other
    // UI elements like annotations and localization lines. Updating the view
    // here allows those updates to occur and be appropriately updated in the scene
    // (via react-vtk-js) before taking the final step here of updating the view.
    vtkView?.requestRender();

    // Measure duration of this frame and store temporarily for analytics
    const duration = Date.now() - start;
    this.#frameDurations.push(duration);
    this.#debouncedAnimationFrameCallback(() => {
      this.#reportFrameDurations();
      providerWithView?.emitter.emit(createDebouncedActiveSliceChangedEmitterEvent(viewType));

      Object.keys(updatesForDebounce)
        .filter((linkSmid) => linkSmid !== providerWithView?.linkSmid)
        .forEach((linkSmid) => {
          const { provider, viewType } = updatesForDebounce[linkSmid];
          provider.emitter.emit(createDebouncedActiveSliceChangedEmitterEvent(viewType));
        });
    });
  }

  /**
   * Report animation frame duration time analytics.
   */
  #reportFrameDurations() {
    const numFrames = this.#frameDurations.length;

    if (numFrames > 0) {
      const { sum, min, max } = this.#frameDurations.reduce(
        (acc, duration) => {
          acc.sum = acc.sum + duration;
          acc.min = Math.min(acc.min, duration);
          acc.max = Math.max(acc.max, duration);

          return acc;
        },
        { sum: 0, max: 0, min: Infinity }
      );
      const averageFrameDuration = sum / numFrames;

      // Sometimes we can get a frame with no duration, so
      // we only accept 2 or more frames and an average duration of more than 1ms.
      // If we ever _actually_ have sub-millisecond frame durations, we'll be in a good spot)
      if (numFrames > 1 && averageFrameDuration > 1) {
        analytics.track(viewer.usr.averageScrollingFrameDuration, {
          minDuration: min,
          maxDuration: max,
          averageDuration: averageFrameDuration,
          totalFrames: this.#frameDurations.length,
        });
      }

      this.#frameDurations = [];
    }
  }
}

export const createDebouncedActiveSliceChangedEmitterEvent = (
  viewType: DreAllViewportTypes
): EmitterEvent => `${viewType}:debouncedActiveSliceChanged`;
