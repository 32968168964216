// @flow

import type { RenderLeafProps, TextType } from '../../../core';

import { getSourceTextColor } from '../utils';

const TextSourceStyling = ({
  attributes,
  children,
  leaf,
}: RenderLeafProps<TextType>): React$Node => {
  if (leaf?.source != null) {
    children = <span style={{ color: getSourceTextColor(String(leaf.source)) }}>{children}</span>;
  }

  return <span {...attributes}>{children}</span>;
};

TextSourceStyling.displayName = 'TextSourceStyling';

export const TextSourceStylingEditable = TextSourceStyling;
export const TextSourceStylingRenderer = TextSourceStyling;
