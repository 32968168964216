// @flow

import { createContext, useContext, useCallback, useEffect, useState } from 'react';
import { LIST_TOOLBAR_MODES } from 'domains/reporter/RichTextEditor/plugins/list/constants';
import type {
  ListToolbarModes,
  ListVariant,
} from 'domains/reporter/RichTextEditor/plugins/list/constants';
import { getCurrentActiveListVariant } from 'domains/reporter/RichTextEditor/plugins/list/utils';
import {
  useSlateSingletonContext,
  useSlateSelectionSingletonContext,
} from 'domains/reporter/Reporter/SlateSingletonContext';
import {
  demoteListItems as _demoteListItems,
  increaseIndent as _increaseIndent,
  decreaseIndent as _decreaseIndent,
  switchListVariant as _switchListVariant,
  insertList as _insertList,
} from 'domains/reporter/RichTextEditor/plugins/list/functions';
import type { RangeType } from 'domains/reporter/RichTextEditor/core';
import { Range } from 'domains/reporter/RichTextEditor/core';

export type CurrentActiveListVariantState = ListVariant | null;

export type CurrentListContextBag = {
  listToolbarModes: ListToolbarModes,
  currentActiveListVariant: CurrentActiveListVariantState,
  setCurrentActiveListVariant: (CurrentActiveListVariantState) => void,
  switchListVariant: (range: RangeType) => void,
  insertList: ({ variant: ListVariant, range: RangeType }) => void,
  demoteListItems: (range: RangeType) => void,
  increaseIndent: () => void,
  decreaseIndent: () => void,
};

const CurrentListContext = createContext<?CurrentListContextBag>(undefined);

const useCurrentListInternal = (): CurrentListContextBag => {
  const [{ editor }] = useSlateSingletonContext();
  const [{ selection }] = useSlateSelectionSingletonContext();
  const [currentActiveListVariant, setCurrentActiveListVariant] =
    useState<CurrentActiveListVariantState>(null);

  const listToolbarModes = LIST_TOOLBAR_MODES;

  const insertList = useCallback(
    ({ variant, range }: { variant: ListVariant, range: RangeType }) => {
      _insertList(editor, variant, range);
    },
    [editor]
  );

  const demoteListItems = useCallback(
    (range: RangeType) => {
      if (editor != null) {
        _demoteListItems(editor, range);
      }
    },
    [editor]
  );

  const increaseIndent = useCallback(() => {
    _increaseIndent(editor, currentActiveListVariant);
  }, [currentActiveListVariant, editor]);

  const decreaseIndent = useCallback(() => {
    _decreaseIndent(editor, currentActiveListVariant);
  }, [currentActiveListVariant, editor]);

  const switchListVariant = useCallback(
    (range: RangeType) => {
      if (editor != null) {
        _switchListVariant(editor, range);
        if (selection !== null && Range.equals(range, selection)) {
          setCurrentActiveListVariant(getCurrentActiveListVariant(editor));
        }
      }
    },
    [editor, selection]
  );

  useEffect(() => {
    setCurrentActiveListVariant(getCurrentActiveListVariant(editor));
  }, [editor, selection]);

  return {
    listToolbarModes,
    currentActiveListVariant,
    setCurrentActiveListVariant,
    insertList,
    demoteListItems,
    increaseIndent,
    decreaseIndent,
    switchListVariant,
  };
};

export const useCurrentList = (): CurrentListContextBag => {
  const currentListBag = useContext(CurrentListContext);

  if (!currentListBag) {
    throw new Error('useCurrentList must be used within provider');
  }

  return currentListBag;
};

export type ProviderComponentProps = $ReadOnly<{|
  children: React$Node,
|}>;

export const ListProvider = ({ children }: ProviderComponentProps): React$Node => {
  const currentListBag = useCurrentListInternal();

  return (
    <CurrentListContext.Provider value={currentListBag}>{children}</CurrentListContext.Provider>
  );
};
