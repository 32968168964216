// @flow
import type {
  NvoqMessage,
  ParsedDictationChunk,
  SironaSubstitution,
} from '../createNvoqWebsocketFactory';
import { SIRONA_SUBSTITUTION_REGEX } from './utils';
import {
  createSyntheticSubstitutions,
  createSyntheticSubstitutionsForMarkers,
} from './createSyntheticSubstitutions';
import { getVoiceCommandTargetFromAction } from './getVoiceCommandTargetFromAction';
import { isObject } from 'utils/isObject';
import { pipe } from 'ramda';
import type { StableTextResponse } from '../../ASRPlex/ASRPlexProtocol';

export const createDictationChunksFromNvoqStableText = (
  msg: NvoqMessage
): ParsedDictationChunk[] => {
  return pipe(createSyntheticSubstitutions, parseNvoqStableText)(msg);
};

/**
 * Parses substitutions from nVoq text. Will look something like this:
 * Text <srna>{something: 'here'}</srna> more text
 */
export const parseNvoqStableText = (msg: NvoqMessage): ParsedDictationChunk[] =>
  msg.data.text.split(SIRONA_SUBSTITUTION_REGEX).map((textFragment) => {
    // See if it's a stringified object we can parse, otherwise return a nVoqMessage
    try {
      const substitution: SironaSubstitution = JSON.parse(textFragment);

      // Substitutions must be an object, otherwise we throw and insert what nVoq gives us
      if (!isObject(substitution)) {
        throw new Error('Not a valid substitution as it is NOT an object!');
      }

      // $FlowIgnore[speculation-ambiguous] - I don't know how to type this in Flow
      return {
        type: 'SUBSTITUTION',
        payload: {
          target: getVoiceCommandTargetFromAction(substitution.action),
          ...substitution,
        },
      };
    } catch (err) {
      return {
        type: 'TEXT',
        payload: {
          ...msg,
          data: { ...msg.data, text: textFragment, substitutedText: textFragment, markers: [] },
        },
      };
    }
  });

export const createDictationChunks = (msg: StableTextResponse): ParsedDictationChunk[] => {
  return pipe(createSyntheticSubstitutionsForMarkers, parseStableTextForMarkers)(msg);
};

/**
 * Parses substitutions from ASRPlex stable text. Will look something like this:
 * Text <srna>{something: 'here'}</srna> more text
 */
export const parseStableTextForMarkers = (msg: StableTextResponse): ParsedDictationChunk[] =>
  msg.payload.text.split(SIRONA_SUBSTITUTION_REGEX).map((textFragment) => {
    // See if it's a stringified object we can parse, otherwise return a StableTextResponse
    try {
      const substitution: SironaSubstitution = JSON.parse(textFragment);

      // Substitutions must be an object, otherwise we throw and insert what nVoq gives us
      if (!isObject(substitution)) {
        throw new Error('Not a valid substitution as it is NOT an object!');
      }

      // $FlowIgnore[speculation-ambiguous] - I don't know how to type this in Flow
      return {
        type: 'SUBSTITUTION',
        payload: {
          target: getVoiceCommandTargetFromAction(substitution.action),
          ...substitution,
        },
      };
    } catch (err) {
      return {
        ...msg,
        payload: {
          ...msg.payload,
          text: textFragment,
          substitutedText: textFragment,
        },
      };
    }
  });
