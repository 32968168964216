// @flow

import { useMemo, useState } from 'react';
import { useExpandSelection } from './useExpandSelection';
import type { EditorType } from '../../core';
import type { CreateEnhanceProvider } from '../../types/plugin';
import type { ExpandSelectionPluginPropertyOptions } from './expandSelectionPlugin';
import type { SlateProps } from 'slate-react';

type ProviderComponentProps = $ReadOnly<{
  editor: EditorType,
  forceExpandPicklist: boolean,
  children: React$Node,
}>;

export const ProviderComponentWithExpandSelection = ({
  editor,
  forceExpandPicklist,
  children,
}: ProviderComponentProps): React$Node => {
  const [selection, setSelection] = useState();
  useExpandSelection({ selection, editor, forceExpandPicklist });

  useMemo(() => {
    const initialChange = editor.onChange;
    editor.onChange = (...change) => {
      setSelection((selection) => (editor.selection !== selection ? editor.selection : selection));
      initialChange(...change);
    };
  }, [editor]);

  return children;
};

export const enhanceProviderExpandSelection: CreateEnhanceProvider<
  ExpandSelectionPluginPropertyOptions,
> =
  ({ pluginID, forceExpandPicklist = false }) =>
  (Component: React$ComponentType<SlateProps>) =>
    function EnhanceProviderExpandSelection(props) {
      return (
        <ProviderComponentWithExpandSelection
          editor={props.editor}
          forceExpandPicklist={forceExpandPicklist}
        >
          <Component {...props} />
        </ProviderComponentWithExpandSelection>
      );
    };
