// @flow

import { useQuery } from '@apollo/client';
import type { GetWorklistViewsQuery, GetWorklistViewsQueryVariables } from 'generated/graphql';
import { GET_WORKLIST_VIEWS } from 'modules/Apollo/queries';
import { useMemo } from 'react';

type useWorklistViewsReturn = {
  worklistViews: GetWorklistViewsQuery['worklistViews'],
  areWorklistViewsLoading: boolean,
};
export function useWorklistViews(): useWorklistViewsReturn {
  const { data, loading } = useQuery<GetWorklistViewsQuery, GetWorklistViewsQueryVariables>(
    GET_WORKLIST_VIEWS,
    // Using `cache-and-network` since the set of views can change
    // on the back end without any user interaction
    { fetchPolicy: 'cache-and-network' }
  );

  return useMemo(
    () => ({ worklistViews: data?.worklistViews ?? [], areWorklistViewsLoading: loading }),
    [loading, data]
  );
}
