// @flow

import { useFeatureFlagEnabled, FF, useSplitFlag } from 'modules/feature-flags';
import { FilterVariant, WorklistFilterSourceName, WorklistFilters } from '../types';
import { useWorklistCustomStatuses, formatStatusName } from 'hooks/useWorklistCustomStatuses';
import { useMemo } from 'react';
import type {
  DateRangeFilterType,
  FilterDefinitionType,
  MultiSelectFilterItemType,
  MultiSelectFilterType,
  NumberRangeFilterType,
  TextFilterType,
} from '../types';
import { WorklistSurface } from '../../Worklist/types';
import { subDays } from 'date-fns';
import type { FilterConfig } from '../constants';
import { useWorklistFilterDropdown } from './useWorklistFilterDropdown';

type useGenerateFilterConfigReturn = {
  surfaceToFilterConfig: FilterConfig,
};
export const useGenerateFilterConfig = (): useGenerateFilterConfigReturn => {
  const [isWorklistCustomStatusEnabled] = useSplitFlag(
    FF.WORKLIST_CUSTOM_STATUS,
    'on',
    (v) => v === 'on'
  );
  const [isWorklistImprovedFiltersEnabled, isWorklistImprovedFiltersLoading] =
    useFeatureFlagEnabled(FF.WORKLIST_IMPROVED_FILTERS);
  const [isWorklistDerivedNameFiltersEnabled] = useFeatureFlagEnabled(
    FF.WORKLIST_DERIVED_NAME_FILTERS
  );

  const recentlyReadWindow = useSplitFlag(FF.WORKLIST_RECENTLY_READ_DAYS, '8', (flag) =>
    Number(flag)
  )[0];

  // Keeping this for now so that we can still use the dropdown
  // in the legacy version
  const { items: modalityItems } = useWorklistFilterDropdown(WorklistFilterSourceName.Modality);

  const { customStatusFilters, isLoadingCustomStatuses } = useWorklistCustomStatuses();

  const defaultFilters = useMemo(() => {
    const baseFilters: FilterDefinitionType = {
      [WorklistFilters.PatientSex]: ({
        label: 'Gender',
        type: FilterVariant.MultiSelect,
        isSearchable: false,
        source: WorklistFilterSourceName.PatientSex,
        items: isWorklistImprovedFiltersEnabled
          ? undefined
          : [
              { value: 'F', label: 'Female' },
              { value: 'M', label: 'Male' },
            ],
      }: MultiSelectFilterType),
      [WorklistFilters.StudyDate]: ({
        label: 'Exam Date',
        type: FilterVariant.DateRange,
        isLoading: isWorklistImprovedFiltersLoading,
      }: DateRangeFilterType),
      [WorklistFilters.PatientDob]: ({
        label: 'DOB',
        type: FilterVariant.DateRange,
        isLoading: isWorklistImprovedFiltersLoading,
      }: DateRangeFilterType),
      [WorklistFilters.Views]: ({
        label: 'Views',
        type: FilterVariant.MultiSelect,
        items: [],
        hidden: true,
        source: '',
      }: MultiSelectFilterType),
      [WorklistFilters.Modality]: ({
        label: 'Modality',
        type: FilterVariant.MultiSelect,
        isSearchable: isWorklistImprovedFiltersEnabled,
        source: WorklistFilterSourceName.Modality,
        items: isWorklistImprovedFiltersEnabled ? undefined : modalityItems,
      }: MultiSelectFilterType),
    };

    return baseFilters;
  }, [isWorklistImprovedFiltersEnabled, isWorklistImprovedFiltersLoading, modalityItems]);

  const worklistSurfaceFilters = useMemo(() => {
    const baseWorklistSurfaceFilters: FilterDefinitionType = {
      [WorklistFilters.HasDraftReport]: ({
        label: 'Draft',
        type: FilterVariant.MultiSelect,
        items: isWorklistImprovedFiltersEnabled
          ? undefined
          : [
              { value: 'true', label: 'Has Draft' },
              { value: 'false', label: 'No Draft' },
            ],
        isBoolean: true,
        source: WorklistFilterSourceName.HasDraftReport,
        isLoading: isWorklistImprovedFiltersLoading,
        formatItems: (items: MultiSelectFilterItemType[]) =>
          items.map((item) => ({
            value: String(item.value).toLowerCase(),
            label: item.value === 'True' ? 'Has Draft' : 'No Draft',
            count: item.count,
          })),
      }: MultiSelectFilterType),
    };

    if (isWorklistCustomStatusEnabled) {
      baseWorklistSurfaceFilters[WorklistFilters.CustomStatus] = ({
        label: 'Workflow Status',
        type: FilterVariant.MultiSelect,
        isLoading: isWorklistImprovedFiltersLoading || isLoadingCustomStatuses,
        source: WorklistFilterSourceName.CustomStatus,
        items: isWorklistImprovedFiltersEnabled ? undefined : customStatusFilters,
        formatItems: (items: MultiSelectFilterItemType[]) =>
          items.map((item) => ({
            label: formatStatusName(item.label),
            value: item.value,
            count: item.count,
          })),
      }: MultiSelectFilterType);
    }

    if (isWorklistImprovedFiltersEnabled) {
      baseWorklistSurfaceFilters[WorklistFilters.Age] = ({
        label: 'Age',
        type: FilterVariant.NumberRange,
        isLoading: isWorklistImprovedFiltersLoading,
      }: NumberRangeFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.AccessionNumber] = ({
        label: 'Accession #',
        type: FilterVariant.Text,
      }: TextFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.Mrn] = ({
        label: 'MRN #',
        type: FilterVariant.Text,
      }: TextFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.PatientType] = ({
        label: 'Patient Type',
        type: FilterVariant.MultiSelect,
        isSearchable: false,
        source: WorklistFilterSourceName.PatientType,
      }: MultiSelectFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.StudyDescription] = ({
        label: 'Exam Description',
        type: FilterVariant.Text,
      }: TextFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.Site] = ({
        label: 'Site',
        type: FilterVariant.MultiSelect,
        isSearchable: true,
        source: WorklistFilterSourceName.Site,
      }: MultiSelectFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.BodyPart] = ({
        label: 'Body Part',
        type: FilterVariant.MultiSelect,
        isSearchable: true,
        source: WorklistFilterSourceName.BodyPart,
      }: MultiSelectFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.PatientName] = ({
        label: 'Name',
        type: FilterVariant.Text,
      }: TextFilterType);
      baseWorklistSurfaceFilters[WorklistFilters.Priority] = {
        label: 'Priority',
        type: FilterVariant.MultiSelect,
        isSearchable: false,
        source: WorklistFilterSourceName.Priority,
      };

      if (isWorklistDerivedNameFiltersEnabled) {
        baseWorklistSurfaceFilters[WorklistFilters.Radiologist] = ({
          label: 'Radiologist',
          type: FilterVariant.MultiSelect,
          isSearchable: true,
          source: WorklistFilterSourceName.Radiologist,
        }: MultiSelectFilterType);
        baseWorklistSurfaceFilters[WorklistFilters.ReferringPhysician] = ({
          label: 'Ordering Physician',
          type: FilterVariant.MultiSelect,
          isSearchable: true,
          source: WorklistFilterSourceName.ReferringPhysician,
        }: MultiSelectFilterType);
      }
    }

    return baseWorklistSurfaceFilters;
  }, [
    customStatusFilters,
    isLoadingCustomStatuses,
    isWorklistCustomStatusEnabled,
    isWorklistDerivedNameFiltersEnabled,
    isWorklistImprovedFiltersEnabled,
    isWorklistImprovedFiltersLoading,
  ]);

  const surfaceToFilterConfig = useMemo(
    () => ({
      [WorklistSurface.MY_QUEUE]: {
        lockedFilters: {},
        defaultFilters: { ...defaultFilters, ...worklistSurfaceFilters },
        hiddenFilters: [WorklistFilters.SubmittedAt, WorklistFilters.PatientSearch],
      },
      [WorklistSurface.PENDING]: {
        lockedFilters: {},
        defaultFilters: { ...defaultFilters, ...worklistSurfaceFilters },
        hiddenFilters: [WorklistFilters.SubmittedAt, WorklistFilters.PatientSearch],
      },
      [WorklistSurface.IN_PROGRESS]: {
        lockedFilters: {},
        defaultFilters: {
          ...defaultFilters,
          ...worklistSurfaceFilters,
          [WorklistFilters.Status]: {
            label: 'Case Status',
            type: FilterVariant.MultiSelect,
            items: [
              { value: 'Locked', label: 'Locked' },
              { value: 'Claimed', label: 'Claimed' },
            ],
            // This filter be shown once the BE performance is improved
            hidden: true,
            source: WorklistFilterSourceName.Status,
          },
        },
        hiddenFilters: [WorklistFilters.SubmittedAt, WorklistFilters.PatientSearch],
      },
      [WorklistSurface.RECENTLY_READ]: {
        lockedFilters: {
          [WorklistFilters.SubmittedAt]: [
            subDays(new Date(), recentlyReadWindow).toISOString(),
            new Date().toISOString(),
          ],
        },
        defaultFilters: {
          ...defaultFilters,
          ...worklistSurfaceFilters,
          [WorklistFilters.SubmittedAt]: ({
            type: FilterVariant.DateRange,
            label: 'Submitted At',
          }: DateRangeFilterType),
        },
        hiddenFilters: [WorklistFilters.HasDraftReport, WorklistFilters.PatientSearch],
      },
      [WorklistSurface.QC]: {
        lockedFilters: {},
        defaultFilters: { ...defaultFilters, ...worklistSurfaceFilters },
        hiddenFilters: [],
      },
      [WorklistSurface.ARCHIVE]: {
        lockedFilters: {},
        defaultFilters: {},
        hiddenFilters: [WorklistFilters.HasDraftReport, WorklistFilters.PatientSearch],
      },
      [WorklistSurface.STUDIES_DIALOG]: {
        lockedFilters: {},
        defaultFilters: {
          ...defaultFilters,
          [WorklistFilters.StudyDate]: {
            ...defaultFilters[WorklistFilters.StudyDate],
            label: 'Study Date',
          },
          [WorklistFilters.PatientSearch]: {
            label: 'Patient Search',
            type: FilterVariant.Toggle,
            defaultValue: true,
          },
        },
        hiddenFilters: [WorklistFilters.HasDraftReport, WorklistFilters.CustomStatus],
      },
      [WorklistSurface.WORKLIST_VIEW]: {
        lockedFilters: {},
        defaultFilters: {
          ...defaultFilters,
          ...worklistSurfaceFilters,
        },
        hiddenFilters: [],
      },
      [WorklistSurface.PATIENT_JACKET_PRIORS]: {
        lockedFilters: {
          [WorklistFilters.PatientSearch]: ['true'],
        },
        defaultFilters: {
          ...defaultFilters,
          [WorklistFilters.PatientSearch]: {
            label: 'Patient Search',
            type: FilterVariant.Toggle,
            defaultValue: true,
          },
          [WorklistFilters.StudyDate]: {
            ...defaultFilters[WorklistFilters.StudyDate],
            label: 'Study Date',
          },
          [WorklistFilters.StudyDescription]: ({
            label: 'Exam Description',
            type: FilterVariant.Text,
          }: TextFilterType),
          [WorklistFilters.BodyPart]: ({
            label: 'Body Part',
            type: FilterVariant.MultiSelect,
            isSearchable: true,
            source: WorklistFilterSourceName.BodyPart,
          }: MultiSelectFilterType),
        },
        hiddenFilters: [],
      },
    }),
    [defaultFilters, recentlyReadWindow, worklistSurfaceFilters]
  );

  return useMemo(
    () => ({
      surfaceToFilterConfig,
    }),
    [surfaceToFilterConfig]
  );
};
