// @flow
import { useCallback } from 'react';
import { useImagingContext } from 'domains/viewer/ViewportDre/modules/imaging/ImagingContext';
import type { ToolInteractionUpdateViewportSliceResponse } from 'generated/graphql';
import { useViewType, useViewportId } from '../../../modules/state';

export function useUpdateViewportSliceResponse(): (
  response: $ReadOnly<ToolInteractionUpdateViewportSliceResponse>
) => void {
  const viewType = useViewType();
  const { imagingProvider } = useImagingContext();
  const activeViewportId = useViewportId();
  const stackSmid = imagingProvider?.stack.smid;

  const updateViewportSlice = useCallback(
    ({ slice, viewportId }: $ReadOnly<ToolInteractionUpdateViewportSliceResponse>) => {
      if (stackSmid != null && imagingProvider) {
        imagingProvider.setActiveSlice(viewType, viewportId ?? activeViewportId, slice);
      }
    },
    [activeViewportId, imagingProvider, stackSmid, viewType]
  );

  return updateViewportSlice;
}
