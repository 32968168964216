// @flow

import { useQuery } from '@apollo/client';
import type {
  GetHeadingKeywordsQuery,
  GetHeadingKeywordsQueryVariables,
  HeadingKeywords,
} from 'generated/graphql';
import { GET_HEADING_KEYWORDS } from 'modules/Apollo/queries';
import { useMemo } from 'react';

export const DEFAULT_HEADING_KEYWORDS = {
  exam: ['exam', 'examination', 'procedure', 'study', 'type of study'],
  history: [
    'history',
    'clinical history',
    'clinical information',
    'clinical background',
    'background',
    'medical history',
    'indication',
    'patient history',
    'clinical data',
    'provided history',
    'clinical indication',
    'reason for exam',
  ],
  technique: ['technique', 'techniques', 'clinical technique', 'imaging', 'imaging technique'],
  findings: ['findings', 'finding', 'doctor findings', 'key findings', 'observations'],
  comparison: [
    'comparison',
    'comparison exam',
    'comparison study',
    'comparison studies',
    'prior report',
    'previous reports',
    'compare',
  ],
  impressions: [
    'impression',
    'impressions',
    'findings/impression',
    'findings/impressions',
    'conclusion',
    'diagnosis',
    'conclusions',
    'conclusion',
    'recommendations',
    'recommendation',
    'summary',
    'summary of impressions',
  ],
};

export function useHeadingKeywords(): HeadingKeywords {
  const { data: headingData } = useQuery<GetHeadingKeywordsQuery, GetHeadingKeywordsQueryVariables>(
    GET_HEADING_KEYWORDS
  );
  const headingKeywords = useMemo(
    () => headingData?.headingKeywords ?? DEFAULT_HEADING_KEYWORDS,
    [headingData]
  );
  return headingKeywords;
}
