// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const HIGHLIGHTED_PLUGIN_ID: 'highlighted' = 'highlighted';
export type HighlightedPluginId = typeof HIGHLIGHTED_PLUGIN_ID;

export type HighlightedPluginElement = EditorElement;

export type HighlightedPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,
  words: Array<string | RegExp>,
}>;

export type HighlightedPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...HighlightedPluginOptions,
  pluginID: HighlightedPluginId,
  pluginName: string,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  words: Array<string | RegExp>,
}>;
