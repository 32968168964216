// @flow
import { useState, useCallback, createContext, useContext, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { PICKLIST_PLUGIN_ID, insertSelectedPicklistItem } from '../RichTextEditor/plugins/picklist';
import type {
  ReportPicklistOption,
  ReportPicklist,
  GetMacroQuery,
  Macro,
  ReportTemplateFieldsFragment,
} from 'generated/graphql';
import { sendEvent, NAMESPACES } from 'modules/EventsManager';
import { useSlateSingletonContext } from '../Reporter/SlateSingletonContext';
import { unfurlMacroPlaceholderStatic } from '../RichTextEditor/plugins/macroPlaceholder/useMacroPlaceholder';
import type { NodeType } from 'slate';
import { logger } from 'modules/logger';
import { partialEditor } from '../RichTextEditor/utils';
import type { ReactSetStateFunction } from 'react';
import { Editor, ReactEditor } from '../RichTextEditor/core';
import { useRequiredFieldIndicator } from 'hooks/useRequiredFieldIndicator';
import type { ReportMacros } from 'hooks/useCurrentCaseReport';

const PicklistContext = createContext(undefined);

export type Picklist = ReportPicklist;
export type Picklists = $ReadOnlyArray<ReportPicklist>;

// TODO: RP-3211 - Fix Macros types
export type Macros =
  | ReportMacros
  | GetMacroQuery['macro']?.['nestedMacros']
  | Array<Macro>
  | ReportTemplateFieldsFragment['macros'];

export type SelectedPicklistOptionsMap = {
  [string]: string,
};

type PicklistBagInternal = $ReadOnly<{
  picklists: Picklists,
  insertPicklist: ({ +picklistID: string, ... }) => void,
  copyPicklist: ({ +picklistID: string, ... }) => Picklist,
  removePicklist: ({ +picklistID: string, ... }) => void,
  updatePicklist: ({ +picklistID: string, options: Array<ReportPicklistOption>, ... }) => void,
  setPicklists: (Picklists | ((Picklists) => Picklists)) => void,
  selectedPicklistOptionIds: SelectedPicklistOptionsMap,
  setSelectedPicklistOptionIds: ReactSetStateFunction<SelectedPicklistOptionsMap>,
}>;

type PicklistBag = {
  ...PicklistBagInternal,
  setMacros: ReactSetStateFunction<Macros>,
  macros: Macros,
  setActivePicklist: (((?Picklist) => ?Picklist) | ?Picklist) => void,
  activePicklist: ?Picklist,
  selectPicklistItem: (index: number) => void,
};

export const usePicklist = (initialPicklists?: Picklists): PicklistBagInternal => {
  const [picklists, setPicklists] = useState<Picklists>(initialPicklists ?? []);
  const [selectedPicklistOptionIds, setSelectedPicklistOptionIds] =
    useState<SelectedPicklistOptionsMap>(() => {
      return (initialPicklists ?? []).reduce<SelectedPicklistOptionsMap>((acc, picklist) => {
        if (picklist.id != null && picklist.options.length > 0) {
          acc[picklist.id] = picklist.options[0]?.id;
        }
        return acc;
      }, {});
    });

  useEffect(() => {
    const selectedPicklistOptionIdKeys = Object.keys(selectedPicklistOptionIds);
    const validPicklists = picklists.filter((p) => p.id != null && p.options.length > 0);
    if (validPicklists.length > 0 && selectedPicklistOptionIdKeys.length < validPicklists.length) {
      setSelectedPicklistOptionIds((prev) => {
        const unmappedPicklists = picklists.filter(
          (p) => !selectedPicklistOptionIdKeys.includes(p.id)
        );

        const newOptions = unmappedPicklists.reduce<SelectedPicklistOptionsMap>((acc, picklist) => {
          if (picklist.id != null && picklist.options.length > 0) {
            acc[picklist.id] = picklist.options[0]?.id;
          }
          return acc;
        }, {});

        return {
          ...prev,
          ...newOptions,
        };
      });
    }
  }, [picklists, selectedPicklistOptionIds]);

  const insertPicklist = useCallback(({ picklistID }: { +picklistID: string, ... }) => {
    setPicklists((picklists) => [...picklists, { id: picklistID, options: [] }]);
  }, []);
  const copyPicklist = useCallback(({ picklistID }: { +picklistID: string, ... }) => {
    const newPicklist = { id: nanoid(), options: [] };

    setPicklists((picklists) => {
      const foundPicklist = picklists.find((p) => p.id === picklistID);
      newPicklist.options = [...(foundPicklist?.options ?? [])];

      setSelectedPicklistOptionIds((prev) => ({
        ...prev,
        [newPicklist.id]: newPicklist.options.length > 0 ? newPicklist[0]?.id : '',
      }));

      return [...picklists, newPicklist];
    });

    return newPicklist;
  }, []);

  const removePicklist = useCallback(({ picklistID }: { +picklistID: string, ... }) => {
    setPicklists((picklists) => picklists.filter((picklist) => picklist.id !== picklistID));
    setSelectedPicklistOptionIds((prev) => {
      const newSelectedPicklists = { ...prev };
      if (newSelectedPicklists[picklistID] != null) {
        delete newSelectedPicklists[picklistID];
      }
      return newSelectedPicklists;
    });
  }, []);

  const updatePicklist = useCallback(
    ({
      picklistID,
      options,
    }: {
      options: Array<ReportPicklistOption>,
      +picklistID: string,
      ...
    }) => {
      setSelectedPicklistOptionIds((prev) => ({
        ...prev,
        [picklistID]: options.length > 0 ? options[0]?.id : '',
      }));

      setPicklists((picklists) => {
        if (picklists.find((p) => p.id === picklistID)) {
          return picklists.map((picklist) => {
            if (picklist.id === picklistID) {
              return { id: picklistID, options };
            }
            return picklist;
          });
        } else {
          return [...picklists, { id: picklistID, options }];
        }
      });
    },
    []
  );

  return {
    picklists,
    insertPicklist,
    copyPicklist,
    removePicklist,
    updatePicklist,
    setPicklists,
    selectedPicklistOptionIds,
    setSelectedPicklistOptionIds,
  };
};

export const PicklistProvider = ({
  children,
  initialPicklists,
  initialMacros = ([]: Array<Macro>),
}: {
  children: React$Node,
  initialPicklists?: Picklists,
  initialMacros?: Macros,
}): React$Node => {
  const picklistBag = usePicklist(initialPicklists);
  const [activePicklist, setActivePicklist] = useState<?Picklist>(null);
  const [macros, setMacros] = useState<Macros>(initialMacros);
  const [{ editor, pluginsBag }] = useSlateSingletonContext();
  const { onInsertTextRequiredField } = useRequiredFieldIndicator();

  useEffect(() => {
    sendEvent(NAMESPACES.ACTIVE_PICKLIST_DATA, {
      type: 'activePicklistData',
      data: {
        activePicklist,
        activePicklistOptionID:
          activePicklist == null ? '' : picklistBag.selectedPicklistOptionIds[activePicklist?.id],
      },
    });
  }, [activePicklist, picklistBag.selectedPicklistOptionIds]);

  const selectPicklistItem = useCallback(
    (index: number) => {
      if (
        activePicklist == null ||
        editor == null ||
        pluginsBag == null ||
        editor.selection == null
      ) {
        const picklistErrorMessage =
          '[usePicklist] Could not select picklist item because no active picklist, selection, editor, or pluginsBag was found. Please try again.';
        logger.warn(picklistErrorMessage, {
          activePicklist,
          editor: editor != null ? partialEditor(editor) : 'null',
          pluginsBag: JSON.stringify(pluginsBag),
        });
        return;
      }
      const selection = editor.selection;

      const option = activePicklist.options[index];

      if (!option) return;

      const nodes = [
        ...Editor.nodes(editor, {
          at: selection,
          match: (n: NodeType) =>
            n.type === PICKLIST_PLUGIN_ID && n.picklistID === activePicklist.id,
        }),
      ];

      const node = nodes[0];

      if (!node) return;

      const unfurledPicklistContent = unfurlMacroPlaceholderStatic(
        option.text,
        macros,
        pluginsBag.getEditorStateEnhancers(),
        {
          fragmentMode: true,
        }
      );

      picklistBag.setSelectedPicklistOptionIds((prev) => ({
        ...prev,
        [activePicklist.id]: option.id,
      }));

      insertSelectedPicklistItem(
        editor,
        [...node],
        unfurledPicklistContent,
        // $FlowIgnore[prop-missing]
        () => onInsertTextRequiredField(ReactEditor.findKey(editor, node[0]))
      );
    },
    [activePicklist, editor, pluginsBag, macros, picklistBag, onInsertTextRequiredField]
  );

  return (
    <PicklistContext.Provider
      value={{
        ...picklistBag,
        macros,
        setMacros,
        activePicklist,
        setActivePicklist,
        selectPicklistItem,
      }}
    >
      {children}
    </PicklistContext.Provider>
  );
};

export const usePicklistState = (): PicklistBag => {
  const picklistBag = useContext(PicklistContext);

  if (!picklistBag) {
    const errorMessage = '[usePicklist] usePicklistState must be used within a PicklistProvider.';
    const error = new Error(errorMessage);
    logger.error(errorMessage, error);
    throw error;
  }

  return picklistBag;
};
