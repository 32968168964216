// @flow

import type {
  GetWorklistQuery,
  GetBaseViewerDataQuery,
  GetBaseViewerDataQueryVariables,
} from 'generated/graphql';
import { BroadcastChannel } from 'broadcast-channel';

import { precacheOperation } from 'modules/Apollo/precacheOperation';
import { GET_BASE_VIEWER_DATA } from 'modules/Apollo/queries';
import { FF, useFeatureFlagEnabled, useSplitFlag } from 'modules/feature-flags';

import { useEffect, useMemo, useState } from 'react';
import { useOpenTabs } from './useOpenTabs';
import { PAGE_TYPES } from 'utils/pageTypes';
import { useViewerId } from './useViewerId';
import debounce from 'lodash.debounce';
import { SUPPORTED_TEXTURES } from 'utils/textureUtils';
import type { PixelDataSharedWorker } from '../modules/viewer/workers/PixelDataSharedWorker';
import { logger } from '../modules/logger';
import { useViewerDownloadingFlags } from 'domains/viewer/hooks/useViewerDownloadingFlags';
import { LOADING_PRIORITIES } from 'domains/viewer/loadingPriorities';

const DEBOUNCE_HOVER_TIME = 250;

type Item = GetWorklistQuery['worklistItems']['items'][number];

export const bc: BroadcastChannel<string[]> = new BroadcastChannel<string[]>(
  'PREFETCH_WORKLIST_ITEMS'
);

export const usePrefetchWorklistItems = (
  worklistSmids: string[],
  pixelDataSharedWorker: ?PixelDataSharedWorker
): boolean => {
  useEffect(() => {
    if (worklistSmids.length > 0) {
      bc.postMessage(worklistSmids);
    }
  }, [worklistSmids]);
  return usePrefetchListener({
    pageType: PAGE_TYPES.WORKLIST,
    sourceItems: worklistSmids,
    pixelDataSharedWorker,
  });
};

export const usePrefetchTopWorklistItems = (
  worklistItems: $ReadOnlyArray<Item>,
  pixelDataSharedWorker: ?PixelDataSharedWorker
): boolean => {
  const [shouldPrefetch] = useFeatureFlagEnabled(FF.PREFETCH_TOP_WORKLIST_ITEMS);
  const itemCount = useSplitFlag(FF.PREFETCH_TOP_WORKLIST_ITEMS_LARGE, '20', (flag) =>
    Number(flag)
  )[0];
  const smidsToPrefetch = useMemo(
    () => worklistItems.slice(0, shouldPrefetch ? itemCount : 0).map((item) => item.smid),
    [worklistItems, shouldPrefetch, itemCount]
  );
  return usePrefetchWorklistItems(smidsToPrefetch, pixelDataSharedWorker);
};

export const usePrefetchHoveredWorklistItem = (
  pixelDataSharedWorker: ?PixelDataSharedWorker
): ({
  prefetchHoveredItem: (itemSmid: string) => void,
  prefetching: boolean,
}) => {
  const [shouldPrefetch] = useFeatureFlagEnabled(FF.PREFETCH_HOVERED_WORKLIST_ITEM);

  const [hovered, setHovered] = useState<string[]>([]);

  const debounceHover = useMemo(
    () =>
      shouldPrefetch
        ? debounce((itemSmid: string) => setHovered([itemSmid]), DEBOUNCE_HOVER_TIME)
        : () => {},
    [shouldPrefetch]
  );
  const prefetching = usePrefetchWorklistItems(hovered, pixelDataSharedWorker);

  return { prefetchHoveredItem: debounceHover, prefetching };
};

export const usePrefetchListener = ({
  pageType,
  sourceItems,
  pixelDataSharedWorker,
}: {
  pageType: $Values<typeof PAGE_TYPES>,
  sourceItems?: string[],
  pixelDataSharedWorker: ?PixelDataSharedWorker,
}): boolean => {
  const batchSize = useSplitFlag(FF.PREFETCH_TOP_WORKLIST_ITEMS_BIG_BATCHING, '5', (flag) =>
    Number(flag)
  )[0];

  const [queue, setQueue] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);

  const tabs = useOpenTabs();
  const windowId = useViewerId();

  const { viewerTransferType, viewerTransferProtocol, loadingViewerDownloadingFlags } =
    useViewerDownloadingFlags();

  const transferType = viewerTransferProtocol === 'http' ? 'http' : viewerTransferType;

  useEffect(() => {
    if (pixelDataSharedWorker != null && !loadingViewerDownloadingFlags) {
      pixelDataSharedWorker.port.postMessage({
        type: 'init',
        transferType,
        SUPPORTED_TEXTURES,
      });
    }
  }, [pixelDataSharedWorker, transferType, loadingViewerDownloadingFlags]);

  const viewerOpen = tabs.some((tab) => tab.type === PAGE_TYPES.VIEWER);
  const active = (pageType === PAGE_TYPES.VIEWER && windowId === '0') || !viewerOpen;

  useEffect(() => {
    if (pageType === PAGE_TYPES.VIEWER && windowId === '0') {
      bc.onmessage = (studySmids: string[]) => {
        // The broadcast queue comes from multiple sources, as opposed to multiple, distinct queues
        // within the worklist. We should combine them with priority to the most recent request.
        setQueue((queue) => studySmids.concat(queue));
      };
    }
  }, [pageType, windowId]);

  useEffect(() => {
    if (pageType === PAGE_TYPES.WORKLIST && active && sourceItems != null) {
      setQueue(sourceItems);
    }
  }, [pageType, active, sourceItems]);

  // simple batching setup so we don't make a large bunch of calls at once with `usePrefetchTopWorklistItems`
  useEffect(() => {
    if (fetching || queue.length === 0 || pixelDataSharedWorker == null) {
      return;
    }

    const firstSet = queue.slice(0, batchSize);
    setFetching(true);
    setQueue(queue.slice(batchSize));
    Promise.all(
      firstSet.map(async (smid) => {
        try {
          const data = await precacheOperation<
            GetBaseViewerDataQuery,
            GetBaseViewerDataQueryVariables,
          >(GET_BASE_VIEWER_DATA, {
            worklistIds: [smid],
            studyIds: [],
            hasWorklistIds: true,
            hasStudyIds: false,
          });

          data?.worklistItems?.items?.forEach((worklistItem) =>
            worklistItem.studies.forEach((study) => {
              study.stackedFrames.forEach((stack) => {
                if (stack?.frames != null) {
                  const firstFrame = stack.frames[0];
                  if (firstFrame != null && firstFrame.hasPixels) {
                    pixelDataSharedWorker.port.postMessage({
                      type: 'load-frame',
                      stack,
                      frameIndex: 0,
                      priority: LOADING_PRIORITIES.PREFETCHING,
                    });
                  } else {
                    if (firstFrame != null) {
                      logger.debug(
                        `frame request not send for non pixel frame, series: ${firstFrame.series.smid}, sop: ${firstFrame.sopClassUID}`
                      );
                    }
                  }
                }
              });
            })
          );
        } catch (e) {
          console.warn('error prefetching case', smid);
          return Promise.resolve();
        }
      })
    ).then(() => {
      setFetching(false);
    });
  }, [queue, fetching, batchSize, pixelDataSharedWorker]);

  return fetching || queue.length > 0 || pixelDataSharedWorker == null;
};
