// @flow
import styled from 'styled-components';
import type { StyledComponent } from 'styled-components';

const HighlightedComponent: StyledComponent<{ ... }, { ... }, HTMLElement> = styled.span`
  color: #ffc7a6;
  font-weight: bold;
`;
export const HighlightedEditable = HighlightedComponent;
export const HighlightedRenderer = HighlightedComponent;
