// @flow

import type { PathType, RangeType } from 'slate';

export const sentinelPath = (name: string): PathType => {
  return name.split('').map((ch) => -ch.charCodeAt(0));
};

export const sentinelSelection = (name: string): RangeType => {
  const path = sentinelPath(name);
  return {
    anchor: { path, offset: 0 },
    focus: { path, offset: 0 },
  };
};
