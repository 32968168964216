// @flow

import { useSelected } from '../../../core';
import type { PlaceholderPluginElement, PlaceholderPluginPropertyOptions } from '../types';
import type { GetRenderElementComponentType } from '../../../utils/getRenderElement';
import { unreachableCaseError } from 'types';
import { useReporterState } from '../../../hooks';
import styled from 'styled-components';
import { PlaceholderTemplateElement } from './PlaceholderTemplate';
import { PlaceholderReportElement } from './PlaceholderReport';
import { Node } from 'slate';
import { getPlaceholderName } from '../utils';
import { PLACEHOLDER_DELIMITER_LEFT, PLACEHOLDER_DELIMITER_RIGHT } from '../constants';
import { useCurrentWorklistItems } from 'hooks/useCurrentWorklistItems';
import { formatStudyInformationWithoutDate } from 'common/StudyInformationCard';

export type PlaceholderEditableProps = $ReadOnly<{
  children: React$Node,
  selected?: boolean,
  handleClick?: () => void,
  testID?: string,
  'data-editor-element'?: string,
  'data-slate-node'?: 'element',
  'data-slate-inline'?: true,
  'data-slate-void'?: true,
  'data-selected'?: boolean,
  dir?: 'rtl',
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  element: any,
  leftDelimiter?: string,
  rightDelimiter?: string,
}>;

export const PlaceholderEditable: GetRenderElementComponentType<
  PlaceholderPluginPropertyOptions,
  PlaceholderPluginElement,
> = (
  {
    pluginOptions: { onPlaceholderRemove, pluginID },
    element,
    leftDelimiter = PLACEHOLDER_DELIMITER_LEFT,
    rightDelimiter = PLACEHOLDER_DELIMITER_RIGHT,
    ...rest
  },
  ref
) => {
  const { variant } = useReporterState();
  const selected = useSelected();
  const { currentWorklistItems } = useCurrentWorklistItems();
  const { placeholderID, workListItemSmid } = element;
  let emptyText;
  // $FlowIgnore[incompatible-call]
  const isEmpty = Node.string(element) === '';
  const placeholderName = getPlaceholderName(placeholderID);
  const worklistItem = currentWorklistItems.find((item) => item.smid === workListItemSmid);

  if (isEmpty === true) {
    emptyText = `missing value for ${placeholderName}${
      currentWorklistItems.length > 1
        ? ' for ' +
          formatStudyInformationWithoutDate(
            worklistItem?.studyDescription,
            worklistItem?.accessionNumber
          )
        : ''
    }`;
  }

  switch (variant) {
    case 'report':
      return (
        <PlaceholderReportElement
          {...rest}
          leftDelimiter={leftDelimiter}
          rightDelimiter={rightDelimiter}
          selected={selected}
          element={element}
          emptyText={emptyText}
          testID={`placeholder-${placeholderName}`}
        />
      );
    case 'fragment':
    case 'template':
      return (
        <PlaceholderTemplateElement
          {...rest}
          leftDelimiter={leftDelimiter}
          rightDelimiter={rightDelimiter}
          selected={selected}
          element={element}
          emptyText={emptyText}
          testID={`placeholder-${placeholderName}`}
        />
      );
    case 'view':
      return null;

    default:
      unreachableCaseError(variant);
  }
};

// $FlowIgnore[signature-verification-failure] Styled components types won't play nice
export const PlaceholderRenderer = styled(PlaceholderReportElement)`
  white-space: pre-wrap;
`;
