// @flow
import { createContext, useCallback, useContext, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import type { ApolloQueryResult } from '@apollo/client';
import { GET_CURRENT_WORKLIST_REPORT } from 'modules/Apollo/queries';
import type {
  GetCurrentWorklistReportQuery,
  GetCurrentWorklistReportQueryVariables,
} from 'generated/graphql';
import { useAsyncGlobalAction } from 'hooks/useGlobalAction';
import { logger } from 'modules/logger';
import { useMatch } from 'react-router-dom';
import { PATH } from 'config/constants';

export type ReportWorklistItem = GetCurrentWorklistReportQuery['worklistItem'];
export type LoadedReportWorklistItem = $NonMaybeType<ReportWorklistItem>;
export type Report = $NonMaybeType<LoadedReportWorklistItem['report']>;
export type ReportMacros = Report['macros'];
export type ReportTemplate = Report['template'];

type HookResult = {
  currentCaseId?: ?string,
  currentCaseReport?: ReportWorklistItem,
  refreshCaseReport?: () => Promise<void>,
  loadingCaseReport: boolean,
  refetch?: (
    variables?: $Rest<GetCurrentWorklistReportQueryVariables, { ... }>
  ) => Promise<ApolloQueryResult<GetCurrentWorklistReportQuery>>,
};

/**
 * This hook is similar to `useCurrentCase` but only fetches data related to the current case report.
 */
export const useCurrentCaseReport = (): HookResult => {
  const currentCaseReport = useContext(CurrentCaseReport);

  if (!currentCaseReport) {
    throw new Error('useCurrentCaseReport must be used within a CurrentCaseReportProvider.');
  }

  return currentCaseReport;
};

const CurrentCaseReport = createContext<HookResult>({ loadingCaseReport: true });

export const CurrentCaseReportProvider = ({ children }: { children: React$Node }): React$Node => {
  // This hook is used by only the reporter and URT tools, so extract the case ID from one or the other.
  const reporterWorklistId = useMatch(PATH.REPORTER)?.params?.worklistId;
  const urtWorklistId = useMatch(PATH.URT)?.params?.worklistId;
  const patientJacketId = useMatch(PATH.PATIENT_JACKET)?.params?.smid;
  const currentCaseId = reporterWorklistId ?? urtWorklistId ?? patientJacketId;

  const {
    data: caseData,
    loading,
    refetch,
  } = useQuery<GetCurrentWorklistReportQuery, GetCurrentWorklistReportQueryVariables>(
    GET_CURRENT_WORKLIST_REPORT,
    {
      variables: currentCaseId == null ? undefined : { smid: currentCaseId },
      skip: currentCaseId == null,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    }
  );

  useEffect(() => {
    logger.info(`[Current Case Report]: currentCaseId: ${currentCaseId ?? 'none'}`);
  }, [currentCaseId]);

  const refetchWorklistItem = useCallback(async () => {
    if (currentCaseId != null) {
      logger.info(`[Current Case Report]: refetching case ${currentCaseId}`);

      await refetch({ smid: currentCaseId });
    }
  }, [currentCaseId, refetch]);

  const currentCaseReport = caseData?.worklistItem;

  const refreshCaseReport = useAsyncGlobalAction('use-current-case', refetchWorklistItem);

  const api = useMemo(
    () => ({
      currentCaseId,
      currentCaseReport,
      refreshCaseReport,
      refetch,
      loadingCaseReport: loading && currentCaseReport?.smid !== currentCaseId,
    }),
    [currentCaseId, loading, currentCaseReport, refetch, refreshCaseReport]
  );

  return <CurrentCaseReport.Provider value={api}>{children}</CurrentCaseReport.Provider>;
};
