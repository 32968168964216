// @flow
import { useEffect, useContext, useCallback } from 'react';
import { Contexts } from 'react-vtk-js';
import { useSetRecoilState, useRecoilValue, selectorFamily } from 'recoil';
import type { RecoilValueReadOnly } from 'recoil';
import { useViewportId, primitiveCursorsState, selectedPrimitiveState, useViewType } from './state';
import { useActiveSlice, useImagingContext } from '../modules/imaging/ImagingContext';

import { TOOL_CURSORS, CURSOR_DISABLED } from 'config/constants';
import { setCursor } from '../utils/dom';
import type { ViewportTypeKeys } from 'config/constants';
import { isToolDisabled } from '../utils/isToolDisabled';

// Special ID used to identify the default cursor between the other primitive IDs
export const DEFAULT_CURSOR_ID: '__DEFAULT_CURSOR_ID__' = '__DEFAULT_CURSOR_ID__';

// The tools listed here are the ones that will override any other cursor while active
export const CURSORS_WITH_PRECEDENCE = ['ERASER', 'ERASE_ALL', 'ZOOM', 'ZOOM_IN', 'ZOOM_OUT'];

type CursorArguments = { id: string, cursor: ?string };

export type SetCursor = (CursorArguments) => void;

export const useSetCursor = (): SetCursor => {
  const viewportId = useViewportId();
  const setPrimitiveCursors = useSetRecoilState(primitiveCursorsState(viewportId));

  return useCallback(
    ({ id, cursor }) => setPrimitiveCursors((state) => ({ ...state, [id]: cursor })),
    [setPrimitiveCursors]
  );
};

export const useRegisterCursor = ({ id, cursor }: CursorArguments): void => {
  const viewportId = useViewportId();
  const setPrimitiveCursors = useSetRecoilState(primitiveCursorsState(viewportId));
  useEffect(() => {
    setPrimitiveCursors((state) => ({ ...state, [id]: cursor }));
  }, [id, cursor, setPrimitiveCursors]);
};

export const withCursor = <T>(
  Component: React$ComponentType<{ ...T, cursor?: string, id: string }>
): React$ComponentType<{ ...T, cursor?: string, id: string }> =>
  function WithCursor({ id, cursor, ...props }) {
    useRegisterCursor({ id, cursor });
    return <Component {...props} cursor={cursor} id={id} />;
  };

export const defaultCursorSelector: (string) => RecoilValueReadOnly<?string> = selectorFamily({
  key: 'viewer.dre.defaultCursorSelector',
  get:
    (viewportId: string) =>
    ({ get }) => {
      return get(primitiveCursorsState(viewportId))[DEFAULT_CURSOR_ID];
    },
});
export const selectedCursorSelector: (string) => RecoilValueReadOnly<?string> = selectorFamily({
  key: 'viewer.dre.selectedCursorSelector',
  get:
    (viewportId: string) =>
    ({ get }) => {
      const selectedPrimitive = get(selectedPrimitiveState(viewportId));
      return selectedPrimitive != null
        ? get(primitiveCursorsState(viewportId))[selectedPrimitive]
        : null;
    },
});

export const useCursorHandler = () => {
  const view = useContext(Contexts.ViewContext);
  const viewportId = useViewportId();
  const defaultCursor = useRecoilValue(defaultCursorSelector(viewportId));
  const cursor = useRecoilValue(selectedCursorSelector(viewportId));
  const viewType = useViewType();
  const activeSlice = useActiveSlice();
  const { imagingProvider } = useImagingContext();
  const activeSliceTags = imagingProvider?.getFrameTagsForViewIndex(viewType, activeSlice);

  useEffect(() => {
    const element = view?.getViewContainer();
    if (element == null) return;
    if (activeSliceTags?.sopClassUID == null) return;

    const targetCursor = pickCursor(
      cursor,
      defaultCursor,
      viewType,
      activeSliceTags?.sopClassUID,
      activeSliceTags?.validSpacing
    );

    setCursor(element, targetCursor);
  }, [
    cursor,
    defaultCursor,
    view,
    viewType,
    activeSliceTags?.sopClassUID,
    activeSliceTags?.validSpacing,
  ]);
};

export const CursorProvider = ({ children }: { children: React$Node }): React$Node => {
  useCursorHandler();
  return children;
};

export const toCssCursor = (cursorConfig: { value: string, x?: number, y?: number }): string =>
  `${cursorConfig.value} ${cursorConfig?.x ?? 0}  ${cursorConfig?.y ?? 0}, auto`;

const toolCursorEntries: $ReadOnlyArray<[string, $Values<typeof TOOL_CURSORS>]> =
  Object.entries(TOOL_CURSORS);

// FIXME(fzivolo): ideally we shouldn't store the resolved CSS cursor string in the state
const cursorReverseLookup = (cssCursor: string): ?string =>
  toolCursorEntries.find(([key, config]) => config.value === cssCursor.split(' ')[0])?.[0];

const getCursorForToolAndViewType = (
  cursor: ?string,
  viewType: ViewportTypeKeys,
  sopClassUID: ?string,
  validSpacing: ?boolean
) => {
  if (cursor == null) return CURSOR_DISABLED;
  const tool = cursorReverseLookup(cursor);

  if (tool != null && isToolDisabled(tool, viewType, sopClassUID, validSpacing)) {
    return CURSOR_DISABLED;
  }

  return cursor;
};

export const pickCursor = (
  cursor: ?string,
  defaultCursor: ?string,
  viewType: ViewportTypeKeys,
  sopClassUID: ?string,
  validSpacing: ?boolean
): ?string => {
  let targetCursor = cursor ?? defaultCursor;
  if (
    defaultCursor != null &&
    (defaultCursor.startsWith('data:image/svg+xml;base64') ||
      CURSORS_WITH_PRECEDENCE.includes(cursorReverseLookup(defaultCursor)))
  ) {
    targetCursor = defaultCursor;
  }

  targetCursor = getCursorForToolAndViewType(targetCursor, viewType, sopClassUID, validSpacing);

  return targetCursor;
};
