// @flow
import { useViewType } from '../../modules/state';
import { useMouseWorldPosition } from '../../modules/useMouseWorldPosition';
import { useActiveSlice, useImagingContext } from '../../modules/imaging/ImagingContext';
import { useMutation } from '@apollo/client';
import { TRIGGER_TOOL_INTERACTION } from 'modules/Apollo/queries';
import type { HandleCreateAnnotation } from '../Toolbox';
import { useCallback, useContext } from 'react';
import type {
  TriggerToolInteractionMutation,
  TriggerToolInteractionMutationVariables,
  ToolInteractionUnion,
  GetToolsQuery,
} from 'generated/graphql';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';
import type { VtkAnnotation } from '../../Annotations/types';
import { Contexts } from 'react-vtk-js';
import { useAnnotationInteraction, useViewportClickInteraction } from './interactions';
import { showToastMessage } from './feedback';
import {
  useWidgetResponse,
  WidgetsRenderer,
  useAnnotationResponse,
  useUpdateViewportPresentationStateResponse,
} from './responses';
import { usePayloadGetter } from './preparePayload';
import { handleMessageResponse } from './responses/message';
import { useHandleUpdateToolActiveStateResponse } from './responses/updateToolActiveState';
import { useUpdateViewportSliceResponse } from './responses/updateViewportSlice';
import {
  CustomAnnotationToolRenderer,
  useStartAnnotationResponse,
} from './responses/startAnnotation';

export type Config = $ReadOnly<{
  ...GetToolsQuery['tools'][number],
  interactions: $ReadOnlyArray<$ReadOnly<ToolInteractionUnion>>,
}>;

type UseConfigBasedTool = {
  createAnnotation: HandleCreateAnnotation,
  annotations: Array<VtkAnnotation>,
  payloadCapabilities: Config['payloadCapabilities'],
};

type ConfigBasedToolProps = {
  onComplete: HandleCreateAnnotation,
  config: Config,
  annotations: Array<VtkAnnotation>,
};

function useWorldToDisplay() {
  const view = useContext(Contexts.ViewContext);

  return useCallback(
    (worldPosition) => {
      const renderer = view?.getRenderer()?.get();
      if (renderer == null) return null;
      return view
        ?.getOpenGLRenderWindow()
        ?.get()
        .worldToDisplay(...worldPosition, renderer);
    },
    [view]
  );
}

export type TriggerToolInteraction = ({
  toolId: TriggerToolInteractionMutationVariables['toolId'],
  toolInteractionId: TriggerToolInteractionMutationVariables['toolInteractionId'],
  toolInteractionType: TriggerToolInteractionMutationVariables['toolInteractionType'],
  toolPayloadCapabilities?: Config['payloadCapabilities'],
}) => Promise<void | TriggerToolInteractionMutation>;

export type HandleInteractionResponse = (
  responses: TriggerToolInteractionMutation['triggerToolInteraction'],
  toolId: string
) => Promise<void>;

type ConfigBasedToolResult = {
  loading: boolean,
  triggerToolInteraction: TriggerToolInteraction,
  handleInteractionResponse: HandleInteractionResponse,
};
const missingDataErrorMessage =
  "We couldn't process your request due to missing data, please try again.";

export const useConfigBasedTool = ({
  createAnnotation,
  annotations,
  payloadCapabilities,
}: UseConfigBasedTool): ConfigBasedToolResult => {
  const mousePosition = useMouseWorldPosition();
  const { imagingProvider } = useImagingContext();
  const activeSlice = useActiveSlice();
  const viewType = useViewType();

  const sliceInstanceTags = imagingProvider?.getFrameTagsForViewIndex(viewType, activeSlice);
  const indexToWorld = useCallback(
    (index: vec3) => imagingProvider?.indexToWorld(index, sliceInstanceTags),
    [imagingProvider, sliceInstanceTags]
  );
  const worldToDisplay = useWorldToDisplay();

  const handleWidgetResponse = useWidgetResponse({ worldToDisplay, indexToWorld });
  const handleAnnotationResponse = useAnnotationResponse({
    createAnnotation,
    indexToWorld,
  });
  const handleUpdateViewportSliceResponse = useUpdateViewportSliceResponse();
  const handleUpdateToolActiveStateResponse = useHandleUpdateToolActiveStateResponse();
  const handleUpdateViewportPresentationStateResponse =
    useUpdateViewportPresentationStateResponse();
  const handleStartAnnotationResponse = useStartAnnotationResponse({ mousePosition });
  const handleInteractionResponse = useCallback(
    async (responses, toolId) => {
      const tasks = responses.map((response) => {
        switch (response.__typename) {
          case 'ToolInteractionAnnotationResponse':
            return handleAnnotationResponse(response);
          case 'ToolInteractionStartAnnotationResponse':
            return handleStartAnnotationResponse(response);
          case 'ToolInteractionWidgetResponse':
            return handleWidgetResponse(response);
          case 'ToolInteractionMessageResponse':
            return handleMessageResponse(response);
          case 'ToolInteractionUpdateToolActiveStateResponse':
            return handleUpdateToolActiveStateResponse(response, toolId);
          case 'ToolInteractionUpdateViewportPresentationStateResponse':
            return handleUpdateViewportPresentationStateResponse(response);
          case 'ToolInteractionUpdateViewportSliceResponse':
            return handleUpdateViewportSliceResponse(response);
          default:
            console.warn(
              `[SDK] The SDK encountered an unsupported interaction response type: ${String(
                response.type
              )}`
            );
            return null;
        }
      });

      await Promise.all(tasks);
    },
    [
      handleAnnotationResponse,
      handleStartAnnotationResponse,
      handleWidgetResponse,
      handleUpdateToolActiveStateResponse,
      handleUpdateViewportPresentationStateResponse,
      handleUpdateViewportSliceResponse,
    ]
  );

  const [triggerToolInteraction, { loading }] = useMutation<
    TriggerToolInteractionMutation,
    TriggerToolInteractionMutationVariables,
  >(TRIGGER_TOOL_INTERACTION);

  const getPayload = usePayloadGetter();

  const preparedTriggerToolInteraction = useCallback(
    async ({ toolId, toolInteractionId, toolInteractionType, toolPayloadCapabilities }) => {
      if (imagingProvider == null) {
        showToastMessage(missingDataErrorMessage, 'ERROR');
        return;
      }

      let payload;
      try {
        payload = await getPayload({
          viewType,
          mousePosition,
          annotations,
          payloadCapabilities: toolPayloadCapabilities ?? payloadCapabilities,
        });
      } catch (err) {
        console.error(err);
        showToastMessage(missingDataErrorMessage, 'ERROR');
        return;
      }

      return new Promise((resolve, reject) => {
        // Define the payload data as it's the same for all interactions
        // This way when we call this wrapped function we only have to pass the interaction type and id
        triggerToolInteraction({
          variables: {
            toolId,
            toolInteractionId,
            toolInteractionType,
            payload,
          },
          onCompleted(data) {
            handleInteractionResponse(data.triggerToolInteraction, toolId);
            resolve(data);
          },
          onError(error) {
            reject(error);
            const interactionErrorMessage =
              error?.message ?? 'There was an error with the interaction';
            showToastMessage(interactionErrorMessage, 'ERROR');
          },
        });
      });
    },
    [
      annotations,
      getPayload,
      handleInteractionResponse,
      imagingProvider,
      mousePosition,
      payloadCapabilities,
      triggerToolInteraction,
      viewType,
    ]
  );

  return {
    triggerToolInteraction: preparedTriggerToolInteraction,
    loading,
    handleInteractionResponse,
  };
};

/**
 * Handles Cursor rendering and tool interactions based on a configuration object
 * Interaction data is sent to GraphQL and forwarded to a 3rd party endpoint defined in the config.
 * Creates annotations from the endpoint response.
 * */
export const ConfigBasedTool = ({
  onComplete: createAnnotation,
  config,
  annotations,
}: ConfigBasedToolProps): React$Node => {
  const { triggerToolInteraction, loading } = useConfigBasedTool({
    createAnnotation,
    annotations,
    payloadCapabilities: config.payloadCapabilities,
  });

  /**
   * TODO: this solution only allows the following events to be triggered while the tool is active.
   * We should move them to an higher level so they can be triggered even when the tool is not active.
   */
  useAnnotationInteraction({
    annotations,
    interactions: config?.interactions,
    triggerToolInteraction,
    toolId: config.id,
  });

  useViewportClickInteraction({
    interactions: config?.interactions,
    triggerToolInteraction,
    loading,
    toolId: config.id,
  });

  return (
    <>
      {/**
       * TODO: this solution only allows widgets to be rendered while
       * a ConfigBasedTool is enabled.
       * In the future we should move this to a higher level so widgets
       * can be rendered even when the tool is not active.
       */}
      <WidgetsRenderer />
      <CustomAnnotationToolRenderer onComplete={createAnnotation} toolId={config.id} />
    </>
  );
};
