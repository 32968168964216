// @flow

/**
 * The minimum duration that should produce a valid dictation. This is related to the
 * shortest english phoneme could be uttered in milliseconds. Based on an nVoq
 * engineer's recommendation this was 120. Because it is reasonable to take up to
 * 200ms to consecutively tab between fields, we are setting this to 200ms to exclude
 * connections from radiologists tabbing between fields.
 *
 * To ensure we weren't going to exclude any valid dictations, a query was ran on the
 * review and correct tool data. The findings were 99.33% of dictations are over 200ms
 * in length, and 99.84% of dictations are over 120ms in length. In raw numbers, the
 * cutoff of 200ms would have excluded 30 of 4,516 dictations. If the cutoff was 120ms
 * it would have excluded 7 of 4,516 dictations.
 *
 * Further analysis was done on production usage between May 23, 2023 and July 5th, 2023.
 * Of the 21,816 dictations in that time frame, 3,993 had audio lengths below 200ms,
 * or 18.3%. Of those 3,993, only 10 had words in them, but upon further review the audio
 * was intelligible and should not have had any transcribed words.
 */
export const MINIMUM_DICTATION_DURATION_MILLIS = 200;

/**
 * NOTE: this is for analytics purposes
 *
 * The maximum number of words to consider for tracking simple dictation corrections.
 * If a user makes a selection and dictates over it, this is the kind of metric that
 * is being considered.
 */
export const MANUAL_CORRECTION_WORDS_LIMIT = 3;
