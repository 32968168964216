// @flow

import { Editor, Transforms, Text, Range } from '../../core';
import type { EditorType, NodeType, TextType, ElementType } from '../../core';
import type { CreateEnhanceProvider } from '../../types';
import type { AnatomicNavigatorPluginPropertyOptions } from './types';
import { useEffect } from 'react';
import type { DeepLink } from '../deepLink/types';
import { useDebouncedCallback } from 'use-debounce';
import { createDeepLinkWrapper } from '../deepLink/utils';
import { DEEP_LINK_PLUGIN_ID } from '../deepLink/types';
import { atom, useRecoilValue } from 'recoil';
import type { RecoilState } from 'recoil';
import { broadcastChannelSynchronizerEffect } from 'utils/recoilEffects';
import type { SlateProps } from 'slate-react';

export const AN_DEEP_LINKS_KEY = 'anDeepLinks';

type ProviderComponentProps = $ReadOnly<{|
  editor: EditorType,
  children: React$Node,
|}>;

const TYPING_SMART_INSERT_DEBOUNCE = 300;

const makeRegexFor = (loc: string): string => {
  if (loc[0] === loc[3]) {
    return `(?<disc>[${loc[0]}])${loc[1]}-\\1?${loc[4]}`;
  }

  return loc;
};

export const deepLinks: RecoilState<DeepLink[]> = atom({
  key: AN_DEEP_LINKS_KEY,
  default: [],
  effects: [broadcastChannelSynchronizerEffect()],
});

/**
 * The provider component.
 *
 * Attaches an event handler when the editor is loaded, which listens for messages on the anatomic navigator broadcast channel.
 * @param {ProviderComponentProps} props - Provider component properties.
 */
const ProviderComponent = ({ children, editor, ...rest }: ProviderComponentProps): React$Node => {
  const discriminatoryLabels = useRecoilValue(deepLinks);

  const debouncedHandleSmartLink = useDebouncedCallback(() => {
    if (discriminatoryLabels != null) {
      handleSmartLinks(
        editor,
        discriminatoryLabels.reduce<{ [key: string]: DeepLink }>((acc, deepLink) => {
          if (deepLink.variant === 'point') {
            acc[deepLink.context.label] = deepLink;
          }
          return acc;
        }, {})
      );
    }
  }, TYPING_SMART_INSERT_DEBOUNCE);

  useEffect(() => {
    handleSmartLinks(
      editor,
      discriminatoryLabels.reduce<{ [key: string]: DeepLink }>((acc, deepLink) => {
        if (deepLink.variant === 'point') {
          acc[deepLink.context.label] = deepLink;
        }
        return acc;
      }, {})
    );
  }, [discriminatoryLabels, editor]);

  useEffect(() => {
    const initialChange = editor.onChange;

    editor.onChange = (...change: []) => {
      debouncedHandleSmartLink();

      initialChange(...change);
    };
  }, [editor, debouncedHandleSmartLink]);

  return children;
};

/**
 * Creates the provider component.
 *
 * @param {DeepLinkPluginPropertyOptions} props - Plugin property options for section header
 */
export const enhanceProvider: CreateEnhanceProvider<AnatomicNavigatorPluginPropertyOptions> =
  ({ pluginID }) =>
  (Component: React$ComponentType<SlateProps>) =>
    function EnhanceProviderAnatomicNavigator(props) {
      return (
        <ProviderComponent editor={props.editor}>
          <Component {...props} />
        </ProviderComponent>
      );
    };

const handleSmartLinks = (editor: EditorType, links: { [string]: DeepLink }) => {
  const locRegExp = Object.keys(links).map((loc): [string, RegExp] => [
    loc,
    new RegExp(`(?<!\\S)\\b${makeRegexFor(loc)}(?!-)\\b`, 'gi'),
  ]);

  const textElements = Editor.nodes(editor, {
    at: [],
    match: (n: NodeType) => Text.isText(n) && String(n.text).trim() !== '',
  });

  Editor.withoutNormalizing(editor, () => {
    for (const [node, path] of textElements) {
      const parent = Editor.parent(editor, path);

      if (parent != null && parent[0].type !== DEEP_LINK_PLUGIN_ID) {
        locRegExp.forEach(([loc, regexp]) => {
          const allMatches = node.text.matchAll(regexp);

          for (const match of allMatches) {
            //Split the text at all matches first before we wrap in case there are dupes

            const at = {
              anchor: { path, offset: match.index },
              focus: { path, offset: match.index + match[0].length },
            };
            const start = Editor.start(editor, at);
            const end = Editor.end(editor, at);

            Transforms.splitNodes(editor, {
              at: end,
              match: (n) => Text.isText(n),
            });

            Transforms.splitNodes(editor, {
              at: start,
              match: (n) => Text.isText(n),
            });
          }
        });
      }
    }

    const replacementTextElements = Editor.nodes(editor, {
      at: [],
      // $FlowIgnore[unclear-type] This is a text node
      match: (n: NodeType) => Text.isText(n) && ((n: any): TextType).text.trim() !== '',
    });

    for (const [node, path] of replacementTextElements) {
      const parent = Editor.parent(editor, path);

      if (parent != null && parent[0].type !== DEEP_LINK_PLUGIN_ID) {
        locRegExp.forEach(([loc, regexp]) => {
          const deepLink = links[loc];
          const allMatches = node.text.matchAll(regexp);

          for (const match of allMatches) {
            //Split the text at all matches first before we wrap in case there are dupes
            // $FlowIgnore[unclear-type] DeepLinkPluginElement is a type of ElementType
            Transforms.wrapNodes(editor, ((createDeepLinkWrapper(deepLink): any): ElementType), {
              at: {
                anchor: { path, offset: match.index },
                focus: { path, offset: match.index + match[0].length },
              },
              split: true,
            });
          }
        });
      }
    }
  });

  const selection = editor.selection;
  if (selection != null && Range.isCollapsed(selection)) {
    const [node, location] = Editor.parent(editor, selection);
    // If selection is in a deep link - lets move it to one after (after we normalize the document)
    if (node.type === DEEP_LINK_PLUGIN_ID) {
      const after = Editor.after(editor, location);
      if (after != null) {
        Transforms.select(editor, after);
      }
    }
  }
};
