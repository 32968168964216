// @flow

export const HeadingLevel: { H1: 1, H2: 2 } = Object.freeze({
  H1: 1,
  H2: 2,
});

export type THeadingLevel = $Values<typeof HeadingLevel>;

export const HEADING_VARIANTS = {
  exam: {
    schemaID: 'exam',
    name: 'Examination',
  },
  history: {
    schemaID: 'history',
    name: 'Clinical history',
  },
  comparison: {
    schemaID: 'comparison',
    name: 'Comparison',
  },
  technique: {
    schemaID: 'technique',
    name: 'Technique',
  },
  findings: {
    schemaID: 'findings',
    name: 'Findings',
  },
  impressions: {
    schemaID: 'impressions',
    name: 'Impression',
  },
};

export type HeadingVariant = $Keys<typeof HEADING_VARIANTS>;
