// @flow
import type { StableTextResponse } from '../../ASRPlex/ASRPlexProtocol';
import type { NvoqMessage } from '../createNvoqWebsocketFactory';
import { addCapitalizedAutoCorrectEntries } from './addCapitalizedAutoCorrectEntries';
import { SIRONA_SUBSTITUTION_REGEX, isLetter } from './utils';

const generateAutoCorrectedText = (
  autoCorrectedText: string,
  _dictionary: { [string]: string }
) => {
  const srnaPositions = [];

  const correctedPhrases = [];

  // Extract <srna> </srna> tagged text and store their positions in srnaPositions
  autoCorrectedText = autoCorrectedText.replace(SIRONA_SUBSTITUTION_REGEX, (match, _, offset) => {
    srnaPositions.push({ start: offset, end: offset + match.length });
    return match;
  });

  const dictionary = addCapitalizedAutoCorrectEntries(_dictionary);

  // Longer autoCorrects are prioritized over shorter ones
  // Get dictionary keys sorted by length in descending order
  const sortedDictionary = Object.keys(dictionary).sort((a, b) => b.length - a.length);

  // Loop through the sorted dictionary and make replacements
  sortedDictionary.forEach((currentPhrase) => {
    const correctedWord = dictionary[currentPhrase];

    const autoCorrectedTextRef = autoCorrectedText;
    const correctedPhrasesRef = correctedPhrases;

    let startIndex = 0;
    while (true) {
      const index = autoCorrectedText.indexOf(currentPhrase, startIndex);
      if (index === -1) break;

      // only make replacement if both prefix and suffix boundary rules are met
      let prefixWordBoundaryRulesMet = false;
      let suffixWordBoundaryRulesMet = false;

      const doesCurrentPhraseBeginWithLetter = isLetter(currentPhrase[0]);

      if (!doesCurrentPhraseBeginWithLetter) {
        prefixWordBoundaryRulesMet = true;
      } else {
        const isStartOfText = index === 0;
        const isPrefixCharacterWhitespaceOrPunctuation = /[\s'"]/.test(
          autoCorrectedText[index - 1]
        );

        if (isStartOfText || isPrefixCharacterWhitespaceOrPunctuation) {
          prefixWordBoundaryRulesMet = true;
        }
      }

      const doesCurrentPhraseEndWithLetter = isLetter(currentPhrase[currentPhrase.length - 1]);

      if (!doesCurrentPhraseEndWithLetter) {
        suffixWordBoundaryRulesMet = true;
      } else {
        const isEndOfText = index + currentPhrase.length === autoCorrectedText.length;
        const isSuffixCharacterWhitespaceOrPunctuation = /[\s,.!?:'"]/.test(
          autoCorrectedText[index + currentPhrase.length]
        );

        if (isEndOfText || isSuffixCharacterWhitespaceOrPunctuation) {
          suffixWordBoundaryRulesMet = true;
        }
      }

      const boundaryRulesMet = prefixWordBoundaryRulesMet && suffixWordBoundaryRulesMet;

      // Only make replacement if the corrected word is not inside <srna></srna> tags
      const isInsideSrnaTag = srnaPositions.some(
        (srna) => srna.start <= index && srna.end >= index + currentPhrase.length
      );

      // Go through the autoCorrects that have already happened.
      // If we come across a shorter phrase in the autoCorrect dictionary that matches a phrase already autoCorrected
      // Ignore it, if its part of the autoCorrected phrase, otherwise correct it, if unrelated to an already autoCorrected phrase
      const matchesAlreadyAutoCorrectedCorrectedPhrase = correctedPhrasesRef.some(
        (correctedPhrase) =>
          correctedPhrase.includes(currentPhrase) &&
          autoCorrectedTextRef.substr(
            index - correctedPhrase.indexOf(currentPhrase),
            correctedPhrase.length
          ) === correctedPhrase
      );

      if (boundaryRulesMet && !isInsideSrnaTag && !matchesAlreadyAutoCorrectedCorrectedPhrase) {
        autoCorrectedText =
          autoCorrectedText.substr(0, index) +
          correctedWord +
          autoCorrectedText.substr(index + currentPhrase.length);
        correctedPhrases.push(correctedWord);
      }
      startIndex = index + correctedWord.length;
    }
  });

  return autoCorrectedText;
};

/**
 * Parses stable text and performs any autoCorrections from the user-defined autoCorrect Dictionary
 * Skips over substitutions
 */
export const autoCorrectNvoqStableText = (
  msg: NvoqMessage,
  _dictionary: { [string]: string }
): NvoqMessage => {
  const autoCorrectedText = generateAutoCorrectedText(msg.data.text, _dictionary);

  return {
    ...msg,
    data: {
      ...msg.data,
      text: autoCorrectedText,
    },
  };
};

/**
 * Parses stable text and performs any autoCorrections from the user-defined autoCorrect Dictionary
 * Skips over substitutions
 */
export const autoCorrectStableTextMarkers = (
  msg: StableTextResponse,
  _dictionary: { [string]: string }
): StableTextResponse => {
  const autoCorrectedText = generateAutoCorrectedText(msg.payload.text, _dictionary);

  return {
    ...msg,
    payload: {
      ...msg.payload,
      text: autoCorrectedText,
    },
  };
};
