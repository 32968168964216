// @flow

import type { ElementPlugin } from '../plugins';
import type { RendererRenderElement, RendererRenderText } from './types';
import { Children } from './Children';

export type ElementComponentProps = $ReadOnly<{
  node: ElementPlugin,
  renderElement: RendererRenderElement,
  renderText: RendererRenderText,
}>;

export const ElementComponent = ({
  node,
  renderElement,
  renderText,
}: ElementComponentProps): React$Node => {
  const children = <Children node={node} renderElement={renderElement} renderText={renderText} />;
  return renderElement({ children, node });
};
