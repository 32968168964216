// @flow

import type { WorklistItem, Addendum, ReportPicklist, AddendumContent } from 'generated/graphql';
import { clone } from 'ramda';
import { REPORT_FIELDS } from 'config/constants';
import { HEADING_VARIANTS, resolvePlaceholderID } from '../../RichTextEditor';
import type { SlateContent } from '../../RichTextEditor';
import { slateContentToString, walkSlateContent } from '../../RichTextEditor/utils';
import { format } from 'date-fns';
import {
  getDefaultContentForHeading,
  insertHeading,
  normalizeText,
  toTitleCase,
} from '../../RichTextEditor/plugins/heading/utils/normalization';
import { HeadingLevel } from '../../RichTextEditor/plugins/heading/constants';
import {
  createInlineParagraph,
  isInlineParagraph,
  createParagraph,
  createParagraphWithChildren,
  isEmptyParagraph,
} from '../../RichTextEditor/plugins/paragraph/utils';
import { HEADING_PLUGIN_ID } from '../../RichTextEditor/plugins/heading/types';
import { Node, Text, Editor } from '../../RichTextEditor/core';
import type { EditorType } from '../../RichTextEditor/core';

import {
  endsWithNewline,
  endsWithNumber,
} from '../../RichTextEditor/stitching/normalizationHelpers';
import { isText } from '../../RichTextEditor/stitching/fragmentHelpers';
import { newline } from './Fields.Context';
import { isEligibleNamedField } from '../../RichTextEditor/utils/fieldNaming';
import { PARAGRAPH_PLUGIN_ID } from '../../RichTextEditor/plugins/paragraph/types';
import { LIST_PLUGIN_ID } from '../../RichTextEditor/plugins/list/types';
import { isSquareBracketType } from '../../RichTextEditor/constants';
import { createListItemNode } from '../../RichTextEditor/plugins/list/utils';
import { LIST_VARIANTS } from '../../RichTextEditor/plugins/list/constants';
import { logger } from 'modules/logger';
import { getSurroundingTextString } from '../../RichTextEditor/utils/getSurroundingTextString';
import type { ReportWorklistItem, ReportTemplate } from 'hooks/useCurrentCaseReport';
import type { ParagraphPluginElementMutable } from '../../RichTextEditor/plugins/paragraph/types';

export const getDefaultSlateFieldChildren = (): SlateContent => {
  return [
    createParagraphWithChildren([
      {
        text: '',
      },
      {
        type: 'inlineBookmark',
        children: [
          {
            text: '',
          },
        ],
      },
      {
        text: '',
      },
    ]),
  ];
};

export const generateSlateHeadingSection = (text?: string): SlateContent => {
  return [
    insertHeading(HeadingLevel.H1, (text?.toUpperCase() ?? 'PLACEHOLDER') + ':'),
    ...getDefaultContentForHeading(text?.toLowerCase() ?? 'placeholder'),
  ];
};

export const generateEmptySlateContent = (): SlateContent => {
  return REPORT_FIELDS.map((field) =>
    generateSlateHeadingSection(HEADING_VARIANTS[field].name)
  ).flat();
};

export const normalizeSlateContent = (contentToNormalize: ?SlateContent): SlateContent => {
  const content = clone(contentToNormalize ?? []);
  const normalizedContent: Array<ParagraphPluginElementMutable | SlateContent> = [];
  const fieldNames = [];
  let closestSubheadingText = null;
  let closestHeadingText = null;

  for (let i = 0; i < content.length; i++) {
    const node = content[i];

    if (node.children.length === 0) {
      node.children = [node.type === LIST_PLUGIN_ID ? createListItemNode() : { text: '' }];
    }

    if (
      node.type === LIST_PLUGIN_ID &&
      ![LIST_VARIANTS.ul, LIST_VARIANTS.ol].includes(node.variant)
    ) {
      node.variant = LIST_VARIANTS.ol;
      normalizedContent.push(node);
    } else if (node.type === HEADING_PLUGIN_ID) {
      if (Node.string(node).trim() === '') {
        i++;
        continue;
      }

      // Make sure headings end in a colon
      if (!node.children[0].text.trim().endsWith(':')) {
        node.children[0].text += ':';
      }

      if (node.level === HeadingLevel.H1) {
        node.children[0].text = node.children[0].text.toUpperCase();
        closestSubheadingText = null;
        closestHeadingText = toTitleCase(normalizeText(node.children[0].text), true);
      } else if (node.level === HeadingLevel.H2) {
        node.children[0].text = toTitleCase(node.children[0].text);
        closestSubheadingText = toTitleCase(normalizeText(node.children[0].text), true);
      }

      normalizedContent.push(node);
    } else if (node.type === PARAGRAPH_PLUGIN_ID) {
      if (isInlineParagraph(node)) {
        const shouldRemoveInlineProperty =
          normalizedContent.length === 0 ||
          normalizedContent[normalizedContent.length - 1].type !== HEADING_PLUGIN_ID;

        // If an inline paragraph is not preceded by a heading, remove the inline property
        if (shouldRemoveInlineProperty) {
          delete node.shouldForceInline;
        }
      } else if (Node.string(node.children[0]).trim() === ':') {
        i++;
        continue;
      } else if (
        normalizedContent.length !== 0 &&
        normalizedContent[normalizedContent.length - 1].type === HEADING_PLUGIN_ID &&
        !isInlineParagraph(node)
      ) {
        // If the last node was a heading, and this node is not an inline paragraph, add an
        // in line paragraph
        normalizedContent.push(createInlineParagraph());
      }

      // We have to loop through all of the children of the paragraph to see if any of them
      // is a field type. If it is, we check to see if it has a name. If it doesn't, we give it
      // a default name
      for (let childIndex = 0; childIndex < node.children.length; childIndex++) {
        const childNode = node.children[childIndex];
        if (isEligibleNamedField(childNode.type)) {
          const fieldName = childNode.name;
          if (
            fieldName == null ||
            String(fieldName).trim() === '' ||
            fieldNames.includes(fieldName)
          ) {
            // The default name of a field is, in order of priority, closest H2, H1, or 'Field'
            // followed by the number of default named fields of that type before it. If it
            // is named after the H1, then the numbering will start after the second H1 default
            // named field
            const defaultName = closestSubheadingText ?? closestHeadingText ?? 'Field';
            const counter = fieldNames.filter(
              (name) =>
                name === defaultName || (name.startsWith(defaultName) && endsWithNumber(name))
            ).length;
            if (defaultName === closestHeadingText && counter === 0) {
              childNode.name = defaultName;
            } else {
              childNode.name = defaultName + (counter + 1);
            }
          }

          fieldNames.push(childNode.name);
          node.children[childIndex] = childNode;
        }
      }
      normalizedContent.push(node);
    } else {
      normalizedContent.push(node);
    }
  }

  return normalizedContent;
};

export function removeDuplicateNewLines(content: SlateContent): SlateContent {
  for (let i = 0; i < content.length - 1; i++) {
    if (isEmptyParagraph(content[i]) && isEmptyParagraph(content[i + 1])) {
      content.splice(i, 1);
      i--;
    }
  }

  return content;
}

export const worklistItemToSlateContent = (worklistItem: ReportWorklistItem): SlateContent => {
  if (worklistItem == null) {
    return [];
  }

  const { report } = worklistItem;
  let reportContent = null;
  // The backend generates a default report when it creates a case, so it's unlikely to be null.
  // The check here is a bit redundant, but it provides backwards-compatibility
  // for cases generated before the backend default report was updated to the Unified Editor standard.
  if (report == null || report.created === report.updated) {
    reportContent = generateEmptySlateContent();
  } else {
    reportContent = clone(report.content ?? []);
  }

  return normalizeSlateContent(reportContent);
};

export const isReportEmpty = (content: SlateContent): boolean => {
  // Trim whitespace?
  return slateContentToString(content) === '';
};

export const applyPlaceholdersToSlateContentTemplate =
  ({ worklistItem }: { worklistItem: WorklistItem }): ((content: SlateContent) => SlateContent) =>
  (content) => {
    const newContent = clone(content);
    const onElement = (elementNode: $FlowFixMe) => {
      if (elementNode.type === 'placeholder') {
        elementNode.children = [
          { text: resolvePlaceholderID(elementNode.placeholderID, worklistItem) },
        ];
        return elementNode;
      }

      return elementNode;
    };

    walkSlateContent(onElement)(newContent);

    return newContent;
  };

export const createAddendumFieldName = ({
  lastName,
  createdDate,
  addendumNumber,
}: {
  lastName: ?string,
  createdDate: ?Date,
  addendumNumber: number,
}): string =>
  `Addendum #${addendumNumber} [${
    createdDate ? format(new Date(createdDate), 'MM/dd/yyyy h:mm:ss a') : 'No date'
  } by Dr. ${lastName || ''}]`;

export const formatAddendumContentByType = (content: AddendumContent): SlateContent => {
  // The backend supports several ways to store text, below we handle them all
  // so that they can be rendered by Slate.
  // If in the future we decide to support a different Rich Text Editor plugin
  // we'll be able to use this branching capabilities to adapt our code accordingly.
  // For example, we may decide to replace the Slate node with a different
  // Rich Text Editor component.
  switch (content.__typename) {
    // The text is already stored as Sirona Slate schema specification
    case 'AddendumSironaSlate':
      return content.sironaSlate ?? [];
    // The text is stored as plain text, we perform some very simple conversion
    // to make sure newlines are properly rendered
    case 'AddendumTextBlob':
      return content.textBlob.split('\n').map((text) => createParagraph(text));
    // The text type is not supported and we throw an error
    default:
      return null;
  }
};

export const generateAddendumSlateContentForAddendum = (addendum: Addendum): SlateContent => {
  const children = formatAddendumContentByType(addendum.content);

  if (children == null) {
    throw new Error(`Unreachable case: ${String(addendum.content.__typename)}`);
  }

  return children;
};

/**
 * Picklists can live on a report template or on a macro. Templates can contain an
 * infinite number of macros. We have decided to not support picklists as a first class
 * entity so the client has to do heavy lifting to get all of the possible picklists that
 * could be a part of a given report.
 *
 * This will break if a user copy/pastes a picklist into the report.
 *
 * Ticket: https://sironamedical.atlassian.net/browse/EN-3433
 */
export const getAllPicklistsForTemplate = (template: ReportTemplate): ReportPicklist[] => {
  if (template == null) {
    return [];
  }

  return [...template.picklists, ...template.macros.map((macro) => macro.picklists).flat()];
};

export const convertFieldsToUnified = (sections: SlateContent): SlateContent => {
  return sections.reduce((acc, curr) => {
    if (curr.type !== 'field') {
      return acc;
    }

    const { label, children } = curr;
    const heading = { type: 'heading', level: HeadingLevel.H1, children: [{ text: label }] };

    // wrap text nodes in paragraphs because they are being moved to the top level of the editor
    // which should only contain block nodes (slate will remove any inlines upon normalization).
    const normalizedChildren = children.map((child) => {
      if (isText(child)) {
        return createParagraphWithChildren([child]);
      }

      return child;
    });

    // the preamble is a special case because it is a field with no heading
    if (label === PREAMBLE_LABEL) {
      acc.push(...normalizedChildren);
      return acc;
    }

    const hasComparisonHeading = children.some(
      (child) => normalizeText(Node.string(child)) === 'comparison'
    );
    if (hasComparisonHeading) {
      const comparisonIndex = normalizedChildren.findIndex(
        (node) =>
          isText(node.children?.[0]) && normalizeText(node.children?.[0]?.text) === 'comparison'
      );
      const preComparisonSection = normalizedChildren.splice(0, comparisonIndex);
      const comparisonSection = normalizedChildren.splice(comparisonIndex + 1);

      if (!endsWithNewline(preComparisonSection)) {
        preComparisonSection.push(newline);
        acc.push(heading, ...preComparisonSection);
        return acc;
      }

      if (!endsWithNewline(comparisonSection)) {
        comparisonSection.push(newline);
        acc.push(
          { type: 'heading', level: 1, children: [{ text: 'comparison' }] },
          ...preComparisonSection
        );
        return acc;
      }
    }

    acc.push(heading, ...normalizedChildren);

    return acc;
  }, []);
};

/**
 * Preamble is a special field that can be present at the top of the report before
 * any other headings. The preamble field does not have a heading in the report, but
 * we need to capture the content in a field so that it can be saved and submitted.
 */
export const PREAMBLE_LABEL = '';

export const convertUnifiedToFields = (content: SlateContent): SlateContent => {
  const sections: SlateContent = [];

  let clonedContent = clone(content);
  const preamble = [];
  for (const node of clonedContent) {
    if (node.type === 'heading' && node.level === HeadingLevel.H1) {
      break;
    }
    preamble.push(node);
  }
  if (preamble.length > 0) {
    sections.push({
      type: 'field',
      label: PREAMBLE_LABEL,
      children: preamble,
    });
    clonedContent = clonedContent.slice(preamble.length);
  }

  const headingEntries = clonedContent
    .map((node, index) => {
      if (node.type !== 'heading' || node.level !== HeadingLevel.H1 || node.children.length === 0) {
        return null;
      }

      const headingText = Node.string(node);
      sections.push({
        type: 'field',
        label: headingText,
        children: [],
      });

      return { node, index };
    })
    .filter((entry) => entry != null);

  for (let i = 0; i < headingEntries.length; i++) {
    const headingEntry = headingEntries[i];

    if (headingEntry == null) {
      break;
    }

    const headingText = Node.string(headingEntry.node);
    const { index } = headingEntry;
    const sectionIndex = sections.findIndex((section) => section.label === headingText);
    const endIndex =
      headingEntries.length === i + 1 ? clonedContent.length : headingEntries[i + 1]?.index;

    sections[sectionIndex].children = clonedContent
      .slice(index + 1, endIndex)
      .map((contentNode) => {
        const node = clone(contentNode);

        if (Text.isText(node)) {
          return createParagraphWithChildren([node]);
        }

        return node;
      });

    if (sections[sectionIndex].children.length === 0) {
      sections[sectionIndex].children.push(createParagraph());
    }

    sections[sectionIndex].isCompleted = Node.string(sections[sectionIndex]) !== '';
  }

  return sections;
};

export const hasNestedSquareBrackets = (
  content: SlateContent,
  isInsideBracket: boolean
): boolean => {
  for (const node of content) {
    if (isInsideBracket && isSquareBracketType(node.type)) {
      return true;
    }

    if (isSquareBracketType(node.type)) {
      if (node.children && hasNestedSquareBrackets(node.children, true)) {
        return true;
      }
    } else if (node.children && hasNestedSquareBrackets(node.children, isInsideBracket)) {
      return true;
    }
  }

  return false;
};

export const insertClipboardDataAsFragment = (
  editor: EditorType,
  event: ClipboardEvent
): SlateContent => {
  const clipboardData = event.clipboardData;
  if (clipboardData == null) {
    logger.info(
      `[Editable] Attempted to paste clipboard data into editor, but clipboard data was null`,
      {
        event,
      }
    );

    return;
  }

  const fragment = clipboardData.getData('application/x-slate-fragment');
  const plainText = clipboardData.getData('text/plain');

  // If we have a slate fragment that we are pasting, make sure it obeys the schema
  if (fragment != null && fragment !== '') {
    event.preventDefault();

    const decoded = decodeURIComponent(window.atob(fragment));
    const parsedFragment = JSON.parse(decoded);

    logger.info(
      `[Editable] Fragment pasted into editor with cursor at: "${getSurroundingTextString(
        editor
      )}"`,
      {
        fragment: parsedFragment,
        selection: editor?.selection ?? '',
      }
    );

    editor.insertFragment(parsedFragment);
    Editor.normalize(editor, { force: true });

    // If it's plain text, we split it into paragraphs and insert those into the editor
  } else if (plainText != null) {
    event.preventDefault();

    const paragraphs = plainText.split(/\r?\n/).map((text) => {
      return { type: PARAGRAPH_PLUGIN_ID, children: [{ text }] };
    });

    logger.info(
      `[Editable] Plain text pasted into editor with cursor at: "${getSurroundingTextString(
        editor
      )}`,
      {
        plainText,
        fragment: paragraphs,
        selection: editor?.selection ?? '',
      }
    );

    editor.insertFragment(paragraphs);
    Editor.normalize(editor, { force: true });
  }
};
