// @flow
export const prettifyHotkey = (hotkey: string): string => {
  let parts = hotkey.split('+');

  // Capitalize and trim each part
  parts = parts.map((part) => {
    const trimmedPart = part.trim().toLowerCase();

    return trimmedPart
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  });

  return parts.join(' + ');
};
