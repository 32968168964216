// @flow

import type { RecoilState } from 'recoil';
import type { EditorType, RangeType } from 'slate';
import type { OnExternalSubstitution, OnStableText } from '../types';
import type {
  ErrorResponse,
  HypothesisTextResponse,
  StoppedResponse,
  StableTextResponse,
  FocusMapAppendResponse,
  FocusMapDeleteResponse,
  CommandResponse,
  HangStudyCommandResponse,
  UpdateToolInteractionCommandResponse,
  SDKCommandResponse,
  ReportGeneratedResponse,
} from './ASRPlexProtocol';
import type { ReportStatuses } from 'domains/reporter/Reporter/state';
import type { PCMRecorderDataAvailableEvent } from 'common/Recorder/useRecorder/useRecorder';

import { useRangeMasksDispatch } from '../../../hooks/useRangeMasks';
import { sentinelSelection } from '../../../utils/sentinelSelection';
import { stringifyRange } from '../../../utils/stringify';
import { PROVISIONAL_SUBMIT_SELECTION } from '../NvoqQueue/useNvoqQueue';
import { useTextProcessing } from '../useTextProcessing';
import { ASRPlexTransaction } from './ASRPlexProtocol';
import { asrPlexVADWebSocketService, asrPlexWebSocketService } from './ASRPlexWebSocketService';

import { recorderEmitter } from 'common/Recorder/useRecorder';
import { convertUnifiedToFields } from 'domains/reporter/Reporter/Fields';
import { findingsMapperState } from 'domains/reporter/Reporter/findingsMapperState';
import { PROVISIONAL_SUBMIT_STATUSES, reportStatusState } from 'domains/reporter/Reporter/state';
import {
  triggerToolInteractionResponse,
  useSDKPayload,
} from 'domains/viewer/ViewportDre/AnnotationTools/ConfigBasedTool/clientSDK';
import { useCaseSync } from 'hooks/useCaseSync';
import { useCurrentCaseId, useCurrentComparativeStudies } from 'hooks/useCurrentCase';
import { RICH_TEXT_EDITOR, useMostRecentInput } from 'hooks/useMostRecentInput';
import { logger } from 'modules/logger';
import { useCallback, useEffect, useRef } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Editor } from 'slate';
import { v4 as uuidv4 } from 'uuid';
import { useFocusMode } from 'hooks/useFocusMode';
import { usePrevious } from 'react-use';
import { useReportTemplate } from 'hooks/useReportTemplate';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';

export const AI_MODE_SELECTION: RangeType = sentinelSelection('AI_MODE');

export type ASRPlexWebSocketState = {
  socket: WebSocket | null,
  active_transaction_id: string,
};
export const asrPlexWebSocketState: RecoilState<ASRPlexWebSocketState> = atom({
  key: 'asrPlexWebSocket',
  default: {
    socket: null,
    active_transaction_id: '',
  },
});

export const asrPlexVadWebSocketState: RecoilState<ASRPlexWebSocketState> = atom({
  key: 'asrPlexVadWebSocket',
  default: {
    socket: null,
    active_transaction_id: '',
  },
});

const useRetryASRPlexWebSocket = () => {
  const [socketState, setSocketState] = useRecoilState(asrPlexWebSocketState);
  const attemptsRef = useRef(0);

  useEffect(() => {
    let socket = asrPlexWebSocketService.connect();

    const onOpen = () => {
      // Reset the attempts counter
      attemptsRef.current = 1;
      setSocketState((prev) => ({
        ...prev,
        socket,
      }));
    };
    const onClose = () => {
      // Cleanup previous event listeners
      socket.removeEventListener('open', onOpen);
      socket.removeEventListener('close', onClose);

      // Exponential backoff, capping at 30 seconds after 5 attempts
      // 2 ** 1 = 2, 2 ** 2 = 4, 2 ** 3 = 8, 2 ** 4 = 16, 2 ** 5 = 32
      const backoff = Math.min(1000 * 2 ** attemptsRef.current, 30000);
      const jitter = Math.floor(Math.random() * 50);

      logger.info(
        `[ASRPlexDictation] WebSocket connection closed, retrying connection in ${
          backoff + jitter
        } ms...`,
        {
          attempts: attemptsRef.current,
          backoff,
          jitter,
        }
      );
      setTimeout(() => {
        socket = asrPlexWebSocketService.connect();
        attemptsRef.current += 1;
        socket.addEventListener('open', onOpen);
        socket.addEventListener('close', onClose);
      }, backoff + jitter);
    };
    socket.addEventListener('open', onOpen);
    socket.addEventListener('close', onClose);

    return () => {
      socket.removeEventListener('open', onOpen);
      socket.removeEventListener('close', onClose);
    };
  }, [setSocketState]);

  return socketState.socket;
};

export const useRetryASRPlexVADWebSocket = (): ?WebSocket => {
  const [socketState, setSocketState] = useRecoilState(asrPlexVadWebSocketState);
  const attemptsRef = useRef(0);

  useEffect(() => {
    let socket = asrPlexVADWebSocketService.connect();

    const onOpen = () => {
      // Reset the attempts counter
      attemptsRef.current = 1;
      setSocketState((prev) => ({
        ...prev,
        socket,
      }));
    };
    const onClose = () => {
      // Cleanup previous event listeners
      socket.removeEventListener('open', onOpen);
      socket.removeEventListener('close', onClose);

      // Exponential backoff, capping at 30 seconds after 5 attempts
      // 2 ** 1 = 2, 2 ** 2 = 4, 2 ** 3 = 8, 2 ** 4 = 16, 2 ** 5 = 32
      const backoff = Math.min(1000 * 2 ** attemptsRef.current, 30000);
      const jitter = Math.floor(Math.random() * 50);

      logger.info(
        `[ASRPlexVADDictation] WebSocket connection closed, retrying connection in ${
          backoff + jitter
        } ms...`,
        {
          attempts: attemptsRef.current,
          backoff,
          jitter,
        }
      );
      setTimeout(() => {
        socket = asrPlexVADWebSocketService.connect();
        attemptsRef.current += 1;
        socket.addEventListener('open', onOpen);
        socket.addEventListener('close', onClose);
      }, backoff + jitter);
    };
    socket.addEventListener('open', onOpen);
    socket.addEventListener('close', onClose);

    return () => {
      socket.removeEventListener('open', onOpen);
      socket.removeEventListener('close', onClose);
    };
  }, [setSocketState]);

  return socketState.socket;
};

export type ASRPlexDictationProps = {
  editor: EditorType,
  isEnabled: boolean,
  activeTemplateId?: string,
  aiMode?: boolean,
  onStableText?: OnStableText,
  onWebsocketError: () => void,
  onError: (e: ErrorResponse) => void,
  enablePicklistDictation: boolean,
  onExternalSubstitution: OnExternalSubstitution,
  children: React$Node,
};

export function ASRPlexDictation({
  editor,
  isEnabled,
  activeTemplateId,
  aiMode = false,
  onStableText,
  onWebsocketError,
  onError,
  enablePicklistDictation,
  onExternalSubstitution,
  children,
}: ASRPlexDictationProps): React$Node {
  const decorateDispatch = useRangeMasksDispatch();
  const reportStatus = useRecoilValue<ReportStatuses>(reportStatusState);
  const selectionToTransactionMap = useRef(new Map<string, ASRPlexTransaction>());
  const transactionIdToTransactionMap = useRef(new Map<string, ASRPlexTransaction>());
  const setSocketState = useSetRecoilState(asrPlexWebSocketState);
  const caseSmid = useCurrentCaseId();
  const { isFocusModeEnabled } = useFocusMode();
  const previousIsFocusModeEnabled = usePrevious(isFocusModeEnabled);
  const { template } = useReportTemplate();
  const { currentCaseReport } = useCurrentCaseReport();

  const { setMostRecentInput } = useMostRecentInput(RICH_TEXT_EDITOR);

  const findingsMapper = useRecoilValue(findingsMapperState);

  useEffect(() => {
    // When recording is stopped, stop all open transactions
    // forcing them to be resolved
    if (!isEnabled) {
      const transactions = selectionToTransactionMap.current.values();

      for (const transaction of transactions) {
        transaction.stopTransaction();
      }
    }
  }, [isEnabled]);

  const [studySmids] = useCurrentComparativeStudies();
  const syncCase = useCaseSync();
  const sdkVoiceCommandPayload = useSDKPayload();
  const socket = useRetryASRPlexWebSocket();
  const {
    processStableText,
    processFocusMapAppend,
    processFocusMapDelete,
    processHypothesisText,
    selectionToSelectionRef,
    setShouldSkipWebsocketCreationRef,
    skipWebsocketCreationOptionsRef,
  } = useTextProcessing({
    editor,
    onStableText,
    enablePicklistDictation,
    onExternalSubstitution,
  });

  const stopTransaction = useCallback(
    ({ selection, duration }: { selection: RangeType | null, duration: number }) => {
      if (selection == null) {
        // handle error case
        return;
      }

      const selectionKey = stringifyRange(selection);

      const transaction = selectionToTransactionMap.current.get(selectionKey);
      if (transaction == null) {
        // handle error case
        return;
      }
      transaction.stopTransaction();
    },
    []
  );

  const createNewTransaction = useCallback(
    ({
      socket,
      selection,
      selectionKey,
    }: {
      socket: WebSocket,
      selection: RangeType,
      selectionKey: string,
    }) => {
      // TODO: remove uuidv4 when asr-plex fixes bug with accepting uuids
      const transaction = new ASRPlexTransaction(
        `${Math.floor(Math.random() * 10000000)}` || uuidv4(),
        socket,
        aiMode,
        isFocusModeEnabled
      );

      transaction.startTransaction({
        // $FlowIgnore[incompatible-call] handle case smid missing error
        case_smid: caseSmid,
        template_smid: activeTemplateId,
        report_selection: selection,
        report_content: editor.children,
        report_fields: convertUnifiedToFields(editor.children),
        ai_mode: aiMode,
        focus_mode: isFocusModeEnabled,
        sdk_context: sdkVoiceCommandPayload,
        // $FlowIgnore[incompatible-call] handle template sections missing error
        template_fields: template?.sections ?? currentCaseReport?.report?.template?.sections,
      });

      const transaction_id = transaction.transaction_id;
      setSocketState((prev) => ({
        ...prev,
        active_transaction_id: transaction_id,
      }));
      selectionToTransactionMap.current.set(selectionKey, transaction);
      transactionIdToTransactionMap.current.set(transaction.transaction_id, transaction);

      // Create a range ref that will keep the user's intended target for this dictation
      // in sync with changes to the document.
      selectionToSelectionRef.current.set(
        selection,
        Editor.rangeRef(editor, selection, { affinity: 'inward' })
      );

      return transaction;
    },
    [
      activeTemplateId,
      aiMode,
      caseSmid,
      template,
      editor,
      isFocusModeEnabled,
      sdkVoiceCommandPayload,
      selectionToSelectionRef,
      setSocketState,
      currentCaseReport?.report?.template?.sections,
    ]
  );

  useEffect(() => {
    if (socket == null) return;

    const onMessage = async (ev: MessageEvent) => {
      const msg = (JSON.parse(typeof ev.data === 'string' ? ev.data : ''):
        | HypothesisTextResponse
        | StableTextResponse
        | FocusMapAppendResponse
        | FocusMapDeleteResponse
        | CommandResponse
        | SDKCommandResponse
        | StoppedResponse
        | ReportGeneratedResponse
        | ErrorResponse);

      if (msg.type === 'hypothesis_text') {
        const { transaction_id, text } = msg.payload;

        if (isFocusModeEnabled) return;

        const transaction = transactionIdToTransactionMap.current.get(transaction_id);
        const selectionKey = transaction?.selectionKey;
        const selection = transaction?.selection;

        if (selectionKey == null || selection == null) {
          // handle error case
          return;
        }

        // Simulate an NvoqMessage, since this is expected downstream.
        // TODO: Flatten signature and expect more primitive types.
        const nvoqMessage = {
          id: '',
          apiVersion: '',
          method: 'TEXT',
          data: {
            text,
            substitutedText: text,
            kind: 'HYPOTHESISTEXT',
            markers: [],
            textDone: false,
            maxAlternates: 0,
          },
        };
        processHypothesisText(nvoqMessage, selection);
      } else if (msg.type === 'stable_text') {
        const { transaction_id, text, done } = msg.payload;

        const transaction = transactionIdToTransactionMap.current.get(transaction_id);
        const selectionKey = transaction?.selectionKey;
        const selection = transaction?.selection;

        if (selectionKey == null || selection == null) {
          // handle error case
          return;
        }

        // Simulate an NvoqMessage, since this is expected downstream.
        // TODO: Flatten signature and expect more primitive types.
        const nvoqMessage = {
          id: '',
          apiVersion: '',
          method: 'TEXT',
          data: {
            text,
            substitutedText: text,
            kind: 'STABLETEXT',
            markers: [],
            textDone: done,
            maxAlternates: 0,
          },
        };

        await processStableText(nvoqMessage, selection);

        if (done) {
          transactionIdToTransactionMap.current.delete(transaction_id);
          selectionToTransactionMap.current.delete(selectionKey);
        }
      } else if (msg.type === 'focus_map_delete') {
        const { text, section } = msg.payload;

        if (text.trim() === '') {
          return;
        }

        processFocusMapDelete(text, section);
      } else if (msg.type === 'focus_map_append') {
        const { text, section } = msg.payload;

        // USE THE COMMENTED CODE 2 LINES BELOW IF YOU WANT TO MOCK IT
        // const { text } = msg.payload;
        // const section = ['findings'];

        // shortcut: were turning it into nVoq stable text, so that we can create 'steps' in the
        // same way, even though its not a message from nVoq.
        const nvoqMessage = {
          id: '',
          apiVersion: '',
          method: 'TEXT',
          data: {
            text,
            substitutedText: text,
            kind: 'STABLETEXT',
            markers: [],
            textDone: false,
            maxAlternates: 0,
          },
        };

        if (text.trim() === '') {
          return;
        }

        processFocusMapAppend(nvoqMessage, section);
      } else if (msg.type === 'command') {
        if (msg.payload.name === 'hang_study_by_id') {
          // $FlowIgnore[unclear-type]
          const { smid } = ((msg: any): HangStudyCommandResponse).payload.arguments;
          if (caseSmid != null) {
            syncCase(caseSmid, [...studySmids, smid]);
          }
        } else if (msg.payload.name === 'update_tool_interaction') {
          // $FlowIgnore[unclear-type]
          const { llm_response_json } = ((msg: any): UpdateToolInteractionCommandResponse).payload
            .arguments;
          const llmResponseJsonList = JSON.parse(llm_response_json);
          triggerToolInteractionResponse(llmResponseJsonList);
        }
      } else if (msg.type === 'stopped') {
        if (msg.payload.case_smid === caseSmid) {
          findingsMapper.resolve();
        } else {
          logger.warn(
            `Received stopped message for a different case: current = "${
              caseSmid ?? ''
            }" received = "${msg.payload.case_smid}"`
          );
        }
      } else if (msg.type === 'error') {
        onError(msg);
        const { transaction_id } = msg.payload;

        const transaction = transactionIdToTransactionMap.current.get(transaction_id);
        transactionIdToTransactionMap.current.delete(transaction_id);

        const selectionKey = transaction?.selectionKey;
        if (selectionKey != null) {
          selectionToTransactionMap.current.delete(selectionKey);
        }
      } else {
        // TODO handle error case
      }
    };
    socket.addEventListener('message', onMessage);

    const onProcessedDataAvailable = ({
      data: blob,
      buffer,
      selection: recorderSelection,
      duration,
      setShouldSkipWebsocketCreation,
      shouldSkipWebsocketCreation,
    }: PCMRecorderDataAvailableEvent) => {
      let selection: RangeType | null;

      if (aiMode && findingsMapper.report_state === 'mapping') {
        selection = AI_MODE_SELECTION;
      } else if (PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus)) {
        selection = PROVISIONAL_SUBMIT_SELECTION;
      } else {
        selection = recorderSelection ?? editor.selection;
      }
      setShouldSkipWebsocketCreationRef.current = setShouldSkipWebsocketCreation;
      skipWebsocketCreationOptionsRef.current = {
        // for whether hypothesis text selection should use this selection or native from event
        shouldSkipWebsocketCreation,
      };

      if (!isEnabled || !selection || buffer == null || buffer.length === 0) {
        return;
      }

      const selectionKey = stringifyRange(selection);
      let transaction = selectionToTransactionMap.current.get(selectionKey);
      if (transaction == null) {
        // TODO: remove uuidv4 when asr-plex fixes bug with accepting uuids
        transaction = createNewTransaction({
          socket,
          selection,
          selectionKey,
        });
      } else if (
        previousIsFocusModeEnabled != null &&
        previousIsFocusModeEnabled !== isFocusModeEnabled
      ) {
        stopTransaction({ selection, duration });
        transaction = createNewTransaction({
          socket,
          selection,
          selectionKey,
        });
      }
      transaction.sendAudio(buffer);
    };

    recorderEmitter.on<PCMRecorderDataAvailableEvent>('data_available', onProcessedDataAvailable);

    type StopRecordingEmitted = {
      selection: RangeType,
      duration: number,
    };

    const onStopRecording = ({ selection: recorderSelection, duration }: StopRecordingEmitted) => {
      let selection: RangeType | null;

      if (PROVISIONAL_SUBMIT_STATUSES.includes(reportStatus)) {
        selection = PROVISIONAL_SUBMIT_SELECTION;
      } else {
        selection = recorderSelection ?? editor.selection;
      }

      stopTransaction({ selection, duration });
    };

    recorderEmitter.on<StopRecordingEmitted>('stop_recording', onStopRecording);

    return () => {
      socket.removeEventListener('message', onMessage);
      recorderEmitter.off('data_available', onProcessedDataAvailable);
      recorderEmitter.off('stop_recording', onStopRecording);
    };
  }, [
    setSocketState,
    socket,
    caseSmid,
    activeTemplateId,
    editor.selection,
    isEnabled,
    reportStatus,
    editor.children,
    decorateDispatch,
    editor,
    onError,
    onStableText,
    aiMode,
    findingsMapper.report_state,
    findingsMapper,
    studySmids,
    syncCase,
    sdkVoiceCommandPayload,
    setMostRecentInput,
    processStableText,
    processHypothesisText,
    selectionToSelectionRef,
    setShouldSkipWebsocketCreationRef,
    skipWebsocketCreationOptionsRef,
    isFocusModeEnabled,
    createNewTransaction,
    stopTransaction,
    previousIsFocusModeEnabled,
    processFocusMapAppend,
    processFocusMapDelete,
  ]);
  return <>{children}</>;
}
