// @flow

import type { EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';

export const DICTAPHONE_CONTROLS_PLUGIN_ID: 'dictaphoneControls' = 'dictaphoneControls';
export type DictaphoneControlsPluginID = typeof DICTAPHONE_CONTROLS_PLUGIN_ID;

export type DictaphoneControlsPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  ignoreMergeFieldsInNavigation?: boolean,
  toggleFocusMode?: () => void,
}>;

export type DictaphoneControlsPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...DictaphoneControlsPluginOptions,
  pluginID: DictaphoneControlsPluginID,
  pluginName: string,
}>;
