// @flow

import { Colors } from 'styles';
import { Stack } from 'common/ui/Layout';
import { css } from 'styled-components';
import { useState } from 'react';
import type { AllReporterVariant } from '../hooks/useReporter';
import { headingErrorState } from '../../Reporter/state';
import { useRecoilValue } from 'recoil';
import { getDuplicateHeadingErrorText } from '../plugins/headingError/utils';
import Text from 'common/ui/Text';
import useResizeAware from 'react-resize-aware';
import { createContext } from 'common/ui/util';
import type { CreateContextReturn } from 'common/ui/util';
import { useCurrentUser } from 'hooks/useCurrentUser/legacy';
import { useImpressionGenerator } from 'hooks/useImpressionGenerator';
import { useSlateSingletonContext } from '../../Reporter/SlateSingletonContext';
import { useCompactUI } from 'hooks/useCompactUI';
import { StatusIndicator } from '../../Reporter/Toolbars/ReportToolbar/StatusIndicator';
import { useReportLockedState } from '../../Reporter/hooks/useReportLockedState';
import { reporterSavingState, ReporterSaveState } from 'config/recoilState';
import { useCurrentCaseReport } from 'hooks/useCurrentCaseReport';
import { useFocusMode } from 'hooks/useFocusMode';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Icon } from 'common/ui/Icon';
import { logger } from 'modules/logger';

export const UNIFIED_EDITOR_PADDING = 12;
export const UNIFIED_EDITOR_VERTICAL_PADDING = 8;

type UnifiedEditorSizesContextType = {
  height: number,
  width: number,
};

export const [
  UnifiedEditorSizesProvider,
  useUnifiedEditorSizes,
  UnifiedEditorSizesContext,
]: CreateContextReturn<?UnifiedEditorSizesContextType> =
  createContext<?UnifiedEditorSizesContextType>({
    name: 'unified-editor-sizes',
    errorMessage: 'useUnifiedEditorSizes must be used within a UnifiedEditorSizesProvider',
    defaultValue: {
      width: 0,
      height: 0,
    },
  });

const ZERO_SIZES = { width: 0, height: 0 };

export type UnifiedEditorProps = {
  isDisabled?: boolean,
  variant: AllReporterVariant,
  aiMode?: boolean,
  children: React$Node,
  isAddendumEditor?: boolean,
  label?: ?string,
  containerCss?: ?string,
  unifiedEditorRef?: { current: ?HTMLElement },
  addendumSMID?: ?string,
};

export const UnifiedEditor = ({
  isDisabled = false,
  variant = 'report',
  aiMode = false,
  children,
  isAddendumEditor = false,
  label,
  containerCss,
  unifiedEditorRef,
  addendumSMID,
}: UnifiedEditorProps): React$Node => {
  const [focused, setIsFocused] = useState(false);
  const hasHeadingError = useRecoilValue<boolean>(headingErrorState);
  const [resizeListener, sizes] = useResizeAware();
  const { data } = useCurrentUser();
  const lineIndicator = data?.me?.reporterSettings?.lineIndicator;
  const { isCurrentImpressionGenerated } = useImpressionGenerator();
  const [{ editor }] = useSlateSingletonContext();
  const { showCompactHeight } = useCompactUI();
  const { isClaimedByMe, isSubmittedStatus, isAddendumStatus } = useReportLockedState();

  const isInProgressAndClaimedByMe = isClaimedByMe && !isSubmittedStatus;
  const isAddendumStatusAndEditor = isAddendumStatus && isAddendumEditor;
  const saveStatus = useRecoilValue(reporterSavingState);

  const { currentCaseReport: currentCase } = useCurrentCaseReport();

  const { isFocusModeToggleVisible, toggleFocusMode, isFocusModeEnabled } = useFocusMode();

  const currentReport = currentCase?.report;
  const currentAddendum = (currentReport?.addendums ?? []).find((addendum) => {
    return addendum.smid === addendumSMID;
  });

  const lastUpdated = isAddendumEditor
    ? currentAddendum?.updated
    : currentReport?.sectionsLastUpdated;

  const isReportAndUnchanged =
    !isAddendumEditor &&
    (currentReport?.sectionsLastUpdated == null ||
      currentReport?.sectionsLastUpdated === currentReport?.created);

  const isAddendumAndUnchanged =
    isAddendumEditor &&
    (currentAddendum == null || currentAddendum?.updated === currentAddendum?.created);

  const isReadOnly = variant === 'view' || isDisabled;
  const isEditableByMe =
    (!isAddendumStatus && isInProgressAndClaimedByMe) || isAddendumStatusAndEditor;
  const isSavedWithNoChanges =
    saveStatus === ReporterSaveState.Saved && (isReportAndUnchanged || isAddendumAndUnchanged);
  const isReportEditor = variant === 'report';
  const shouldShowStatusIndicator =
    isReportEditor && !isReadOnly && isEditableByMe && !isSavedWithNoChanges;
  const shouldShowFocusModeToggle =
    isReportEditor && !isReadOnly && isEditableByMe && !isAddendumStatusAndEditor;

  return (
    <Stack
      ref={unifiedEditorRef}
      id="unified-editor-wrapper"
      vertical
      stretchX
      stretchY
      data-testid="unified-editor-wrapper"
      css={css`
        flex: 1;
        max-width: 100%;
        ${containerCss}
      `}
    >
      <Stack
        alignX={isFocusModeToggleVisible || label != null ? 'between' : 'end'}
        alignY="center"
        css={css`
          margin-bottom: 2px !important;
        `}
      >
        {label != null && (
          <Text variant="body1" color={Colors.gray8}>
            {label}
          </Text>
        )}
        {isFocusModeToggleVisible && shouldShowFocusModeToggle && (
          <FormControlLabel
            data-testid="focus-mode-form-input"
            control={
              <Switch
                color="primary"
                size="small"
                onChange={() => {
                  logger.info('Focus mode toggled via form control');
                  toggleFocusMode();
                }}
              />
            }
            label={
              <Stack css={{ marginLeft: 16 }} alignY="center" space="xxsmall">
                <Icon name="focusMode" css="width: 16px; height: 16px;" />
                <Text variant="body2">Focus Mode</Text>
              </Stack>
            }
            labelPlacement="start"
            checked={isFocusModeEnabled}
          />
        )}
        <Stack
          alignX="center"
          alignY="center"
          css={css`
            margin-right: 2px;
            ${isReportEditor ? 'height: 16px;' : ''}
          `}
        >
          {shouldShowStatusIndicator && (
            <div data-testid="status-indicator-wrapper">
              <StatusIndicator status={saveStatus} lastUpdated={lastUpdated} />
            </div>
          )}
        </Stack>
      </Stack>
      <Stack
        data-stack-stretch
        vertical
        data-testid="unified-editor-text-area"
        onFocus={setIsFocused}
        onBlur={() => setIsFocused(false)}
        css={css`
          box-sizing: border-box;
          width: 100%;
          margin-top: 2px;
          padding-top: ${UNIFIED_EDITOR_VERTICAL_PADDING}px;
          padding-bottom: ${UNIFIED_EDITOR_VERTICAL_PADDING}px;
          padding-left: ${lineIndicator?.variant === 'dot'
            ? lineIndicator.placement === 'left'
              ? UNIFIED_EDITOR_PADDING + 8
              : UNIFIED_EDITOR_PADDING
            : UNIFIED_EDITOR_PADDING}px;
          padding-right: ${lineIndicator?.variant === 'dot'
            ? lineIndicator.placement === 'right'
              ? UNIFIED_EDITOR_PADDING + 8
              : UNIFIED_EDITOR_PADDING
            : UNIFIED_EDITOR_PADDING}px;
          height: ${isAddendumEditor ? '20vh' : '100%'};
          background-color: ${aiMode ? Colors.gray3 : isDisabled ? Colors.gray5 : Colors.gray2};
          border: 1px solid
            ${hasHeadingError && variant === 'report'
              ? Colors.red6
              : focused
                ? Colors.mainBlue
                : Colors.gray8};
          border-radius: 6px;
          overflow: auto;
          overflow-wrap: anywhere;
          position: relative;
          ${isAddendumEditor ? 'max-height: 20vh; min-height: 20vh;' : ''}
          &::-webkit-scrollbar {
            display: none;
          }
          scrollbar-width: none;
        `}
      >
        {resizeListener}
        <UnifiedEditorSizesProvider value={sizes ?? ZERO_SIZES}>
          {children}
        </UnifiedEditorSizesProvider>
      </Stack>
      <Stack
        alignX="between"
        space="small"
        css={{
          marginLeft: 16,
          ...(variant !== 'report' || showCompactHeight
            ? {}
            : { marginTop: 4, height: 16, marginBottom: 4 }),
        }}
      >
        <Text variant="caption" color={Colors.gray8}>
          {editor != null && isCurrentImpressionGenerated(editor)
            ? 'Impression has been generated by artificial intelligence.'
            : ''}
        </Text>
        {hasHeadingError && variant === 'report' && (
          <Text variant="caption" color="error">
            {getDuplicateHeadingErrorText(variant)}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
