// @flow
import { withReact, createEditor, Transforms, Editor, ReactEditor } from '../core';
import type { NodeType, EditorType } from '../core';
import { useMount } from 'react-use';
import { IdentityFn } from 'config/constants';

// $FlowFixMe[untyped-import]
import { withOpsLog } from '@sironamedical/slate-ops-log';
import { BaseEditor } from './Editable';
import type { EditorPlugin, SlateContent } from '../types';
import type { CursorStyle, LineIndicator as LineIndicatorType } from 'generated/graphql';
import {
  usePlugins,
  PluginsProvider,
  NodeStateProvider,
  ReporterProvider,
  RangeMasksProvider,
} from '../hooks';
import type { AllReporterVariant, PluginsBag } from '../hooks';
import { compose } from 'ramda';
import { useMemo, useEffect, Component, Suspense, useCallback } from 'react';
import { HoveringToolbar } from './HoveringToolbar';
import { PortalToolbarButton } from './PortalToolbar';
import { safeDeselect, getLowestBracket, partialEditor } from '../utils';
import { ReporterDevTools } from '../../Reporter/ReporterDevTools';
import analytics from 'modules/analytics';
import { ReporterOpsLogger } from './ReporterOpsLogger';
import { env } from 'config/env';
import { selectNextHeadingSectionBracket } from '../utils/bracketNavigation';
import { useSlateSelection } from 'slate-react';
import { useSlateSelectionSingletonContext } from '../../Reporter/SlateSingletonContext';
import { EnhancedSlate } from './EnhancedSlate';
import { Renderer } from '../renderer';
import type { ReactEditorType } from 'slate-react';
import { PARAGRAPH_PLUGIN_ID } from '../plugins';
import { logger } from 'modules/logger';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHaveRichTextEditorFeatureFlagsLoaded } from 'hooks/useHaveRichTextEditorFeatureFlagsLoaded';
import { Stack } from 'common/ui/Layout';
import { useUnifiedEditorSizes } from './UnifiedEditor';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { CursorIndicator } from './CursorIndicator';
import { LineIndicator } from './LineIndicator';
import { useRecorder } from 'common/Recorder/useRecorder/useRecorder';
import { AutoGenerateImpressions } from '../../Reporter/ImpressionGenerator/AutoGenerateImpressions';
import { useFeatureFlagEnabled, FF } from 'modules/feature-flags';

const isTestEnv = env.NODE_ENV === 'test' || env.STORYBOOK_STORYSHOTS === 'true';

export type EnhancedRendererComponentProps = $ReadOnly<{
  value: SlateContent,
  editor?: ?EditorType,
  isAddendumEditor?: boolean,
  onChange: (value: Array<NodeType>) => void,
  aiMode?: boolean,
  onReportChange?: (content: SlateContent) => void,
  /**
   * Called onMount after the Slate singleton has been created.
   */
  onInit?: (props: { editor: EditorType, pluginsBag: PluginsBag }) => void,
}>;

export type RichTextEditorComponentProps = $ReadOnly<{
  value: SlateContent,
  onChange: (value: Array<NodeType>) => void,
  editor?: ?EditorType,

  /**
   * Apply the default selection for the reporter. Otherwise the starting selection will be null.
   *
   * @default true
   */
  enableDefaultSelection?: boolean,

  /**
   * Toggles the hovering toolbar functionality.
   *
   * @default true
   */
  enableHoveringToolbar?: boolean,

  /**
   * Apply style to the contenteditable
   */
  style?: { ... },

  /**
   * Apply cursor style settings
   */
  cursorStyle?: CursorStyle,

  /**
   * Apply line indicator settings
   */
  lineIndicator?: LineIndicatorType,

  /**
   * Called onMount after the Slate singleton has been created.
   */
  onInit?: (props: { editor: EditorType, pluginsBag: PluginsBag }) => void,

  variant: AllReporterVariant,
  placeholder?: ?string,

  isAddendumEditor?: boolean,
}>;

export const defaultSelection = (
  editor: EditorType,
  { ignoreMergeFields }: { ignoreMergeFields: boolean } = { ignoreMergeFields: false }
) => {
  // If there's an empty bracket, select it. Otherwise select the first bracket.
  const firstBracket =
    getLowestBracket(editor, { textEmpty: true, ignoreMergeFields }) ||
    getLowestBracket(editor, { ignoreMergeFields });
  if (firstBracket != null) {
    Transforms.select(editor, Editor.range(editor, firstBracket[1]));
    return;
  }

  const node = selectNextHeadingSectionBracket(editor, {
    startPoint: Editor.end(editor, []),
    fallbackPoint: 'end',
    ignoreMergeFieldsInNavigation: ignoreMergeFields,
  });
  if (node == null) {
    // In this case there is no headings or brackets in the editor so we
    // set the selection to be the first paragraph.
    try {
      const start = Editor.start(editor, []);
      const startBlockNodeEntry = Editor.node(editor, start, {
        depth: 1,
      });
      if (startBlockNodeEntry != null && startBlockNodeEntry[0].type === PARAGRAPH_PLUGIN_ID) {
        Transforms.select(editor, startBlockNodeEntry[1]);
      } else {
        const firstParagraphNodeEntry = Editor.next(editor, {
          at: start,
          // $FlowIgnore[speculation-ambiguous]
          match: (n) => n.type === PARAGRAPH_PLUGIN_ID,
        });
        if (firstParagraphNodeEntry != null) {
          Transforms.select(editor, firstParagraphNodeEntry[1]);
        } else {
          logger.error('[RichTextEditor] Cannot find default selection for editor', {
            editor: partialEditor(editor),
            ignoreMergeFields,
          });
        }
      }
    } catch (err) {
      const errorMessage = '[RichTextEditor] Error occurred during defaultSelection';

      logger.error(
        errorMessage,
        {
          editor: partialEditor(editor),
          ignoreMergeFields,
        },
        err
      );
    }
  }
};

export class ErrorBoundary extends Component<{
  editor: EditorType,
  ignoreMergeFields?: boolean,
  children: React$Node,
}> {
  componentDidCatch(error: Error, errorInfo: mixed) {
    logger.error(error, errorInfo);

    // If we get an error from inside Slate, reset its selection to the default, since this
    // is the most likely cause of the error (Cannot resolve a DOM point from Slate point)
    // NOTE: this will not hide the error overlay in development mode!!
    defaultSelection(this.props.editor, {
      ignoreMergeFields: this.props.ignoreMergeFields ?? false,
    });
    analytics.error(error);
  }
  render(): React$Node {
    return this.props.children;
  }
}

function SyncSlateSelection({
  editor,
  variant,
}: {
  editor?: EditorType,
  variant: AllReporterVariant,
}) {
  const selection = useSlateSelection();
  const [, setSlateSelectionSingletonContext] = useSlateSelectionSingletonContext();

  useEffect(() => {
    setSlateSelectionSingletonContext((v) => ({ ...v, selection }));
  }, [selection, setSlateSelectionSingletonContext]);

  return null;
}

const LoadingComponent = () => (
  <Stack css="width: 100%; height: 100%;" vertical stretchX alignX="center" alignY="center">
    <CircularProgress css="z-index: 2; position: absolute; inset: auto;" />
  </Stack>
);

const EnhancedRendererComponent = ({
  editor: editorOverride,
  isAddendumEditor = false,
  onInit,
  value,
  onChange,
  aiMode = false,
  onReportChange = IdentityFn,
}: EnhancedRendererComponentProps): React$Node => {
  // $FlowIgnore[prop-missing] - not worried about editor properties in this context
  const editor: ReactEditorType = useMemo(() => editorOverride || createEditor(), [editorOverride]);
  const pluginsBag = usePlugins();

  // ensures that EnhancedSlate has access to properly-initialized SlateSingletonContext
  // in order to access provider enhancers
  useMount(() => {
    onInit?.({ editor, pluginsBag });
  });

  useEffect(() => {
    if (aiMode && editorOverride != null) {
      onReportChange(editor.children);
    }
  }, [aiMode, onReportChange, editorOverride, editor.children]);

  const handleChange = useCallback(
    (v) => {
      if (aiMode) {
        onChange(v);
      }
    },
    [aiMode, onChange]
  );

  return (
    <EnhancedSlate editor={editor} onChange={handleChange} value={editor.children}>
      {/* $FlowIgnore[incompatible-type] used for ai mode */}
      <Renderer content={editorOverride != null && aiMode ? editor.children : value} />
    </EnhancedSlate>
  );
};

export const RichTextEditorComponent = ({
  onChange,
  value,
  enableDefaultSelection = true,
  enableHoveringToolbar = true,
  editor: editorOverride,
  style,
  cursorStyle,
  lineIndicator,
  onInit,
  variant,
  placeholder = null,
  isAddendumEditor = false,
}: RichTextEditorComponentProps): React$Node => {
  const sizes = useUnifiedEditorSizes();
  const pluginsBag = usePlugins();
  const { getEditorStateEnhancers } = pluginsBag;
  const { data } = useCurrentUser();
  // tells us when the user clicks or types into the RichTextEditor
  const { userActivityElementRef, setTextEditorElement } = useRecorder();
  const ignoreMergeFields =
    data?.me?.reporterSettings?.mergeFieldsSettings?.ignoreDefaultSelection ?? false;
  const [isAutoImpressionsEnabled] = useFeatureFlagEnabled(FF.REPORTER_AUTO_IMPRESSIONS);

  const editor = useMemo(
    () =>
      compose(
        !isTestEnv ? withOpsLog : IdentityFn,
        withReact,
        ...getEditorStateEnhancers()
      )(editorOverride || createEditor()),
    [getEditorStateEnhancers, editorOverride]
  );

  useMount(() => {
    if (enableDefaultSelection) {
      defaultSelection(editor, {
        ignoreMergeFields,
      });
    }

    try {
      ReactEditor.focus(editor);
    } catch (e) {
      logger.error(
        '[RichTextEditor] Error occurred when focusing the editor on load.',
        {
          editor: partialEditor(editor),
          ignoreMergeFields,
        },
        e
      );
    }

    onInit?.({ editor, pluginsBag });
  });

  return (
    <ErrorBoundary editor={editor} ignoreMergeFields={ignoreMergeFields}>
      <div ref={userActivityElementRef} css={{ display: 'flex', flex: '1', maxWidth: '100%' }}>
        <div ref={setTextEditorElement} css={{ display: 'flex', flex: '1', maxWidth: '100%' }}>
          <EnhancedSlate
            editor={editor}
            onChange={(content) => {
              const isContentChange = editor.operations.some((op) => 'set_selection' !== op.type);
              //Slate triggers a change on a selection as well so we prevent the onChange from
              //firing when it is only a set selection operation
              if (isContentChange) {
                if (content.length === 0) {
                  logger.info("[RichTextEditor] Editor's content is empty, adding a paragraph.");
                  Transforms.insertNodes(editor, {
                    type: PARAGRAPH_PLUGIN_ID,
                    children: [{ text: '' }],
                  });
                  defaultSelection(editor, { ignoreMergeFields });
                  onChange([{ type: PARAGRAPH_PLUGIN_ID, children: [{ text: '' }] }]);
                  return;
                }

                onChange(content);
              }
            }}
            value={value}
          >
            {enableHoveringToolbar && <HoveringToolbar />}
            <PortalToolbarButton
              pluginID="placeholder"
              height={variant === 'fragment' ? '4.5rem' : '6rem'}
            />
            <PortalToolbarButton
              pluginID="list"
              height={variant === 'fragment' ? '4.5rem' : '6rem'}
            />
            {cursorStyle != null && sizes != null && (
              <CursorIndicator
                isAddendumEditor={isAddendumEditor}
                containerWidth={sizes.width}
                containerHeight={sizes.height}
                color={cursorStyle.color}
              />
            )}
            {lineIndicator != null && lineIndicator.enabled && sizes != null && (
              <LineIndicator
                containerWidth={sizes.width}
                containerHeight={sizes.height}
                variant={lineIndicator.variant}
                placement={lineIndicator.placement}
                editor={editor}
                isAddendumEditor={isAddendumEditor}
              />
            )}
            <BaseEditor
              css={
                cursorStyle?.color != null
                  ? `
              * {
                caret-color: transparent;
              }
            `
                  : undefined
              }
              placeholder={placeholder ?? ''}
              style={style}
              onDrop={undefined}
              onDragStart={undefined}
            />
            {variant === 'report' && isAutoImpressionsEnabled && <AutoGenerateImpressions />}
            {env.MODE === 'development' && (
              <Suspense fallback={<></>}>
                <ReporterDevTools />
              </Suspense>
            )}
            {env.NODE_ENV !== 'test' && <ReporterOpsLogger initialState={value} />}
            <SyncSlateSelection editor={editor} variant={variant} />
          </EnhancedSlate>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export type RichTextEditorProps = $ReadOnly<{
  ...RichTextEditorComponentProps,
  plugins: EditorPlugin<>[],
  editor?: ?EditorType,
  variant: AllReporterVariant,
  isDisabled?: boolean,
  aiMode?: boolean,
  onReportChange?: (content: SlateContent) => void,

  /**
   * Run the browser in a headless manner. In this mode, the selection of the editor will never be set to null
   * unless you set it yourself using safeDeselect from utils. This allows for all operations to be ran under
   * the hood as expected and updates the selection as operations are applied.
   *
   * NON-HEADLESS MODE IS NOT FULLY SUPPORTED. YOU WILL LIKELY RUN INTO BUGS IF YOU USE IT DUE TO THE PICKLIST
   * AND INLINE BOOKMARK PLUGINS.
   *
   * @default true
   */
  headlessMode?: boolean,

  /**
   * A ref to point nav items to.
   */
  navMountPoint?: ?HTMLElement,
}>;

export const RichTextEditor = ({
  plugins,
  variant,
  navMountPoint,
  headlessMode = true,
  isAddendumEditor = false,
  isDisabled = false,
  value,
  onInit,
  editor,
  aiMode,
  onReportChange,
  ...rest
}: RichTextEditorProps): React$Node => {
  // Set this before bootstrapping the editor
  useEffect(() => {
    if (headlessMode) {
      // $FlowIgnore[cannot-write] - We override this globally to turn headless mode on
      Transforms.deselect = () => {};
    } else {
      // $FlowIgnore[cannot-write] - We set this back to safeDeselect if we want to turn it off
      Transforms.deselect = safeDeselect;
    }
  }, [headlessMode]);

  const featureFlagsLoaded = useHaveRichTextEditorFeatureFlagsLoaded();

  return (
    <ReporterProvider variant={variant} headlessMode={headlessMode} navMountPoint={navMountPoint}>
      <NodeStateProvider>
        <PluginsProvider plugins={plugins}>
          <RangeMasksProvider>
            {featureFlagsLoaded === false ? (
              <LoadingComponent />
            ) : isDisabled === true ? (
              <EnhancedRendererComponent
                editor={editor}
                value={value}
                onInit={onInit}
                onChange={rest.onChange}
                aiMode={aiMode}
                onReportChange={onReportChange}
              />
            ) : (
              <RichTextEditorComponent
                variant={variant}
                value={value}
                onInit={onInit}
                isAddendumEditor={isAddendumEditor}
                editor={editor}
                {...rest}
              />
            )}
          </RangeMasksProvider>
        </PluginsProvider>
      </NodeStateProvider>
    </ReporterProvider>
  );
};
