// @flow
import { useState } from 'react';

type Box = {
  left: number,
  top: number,
  right: number,
  bottom: number,
  height: number,
  width: number,
};

export function getElementBox(el: Element): Box {
  const { left, top, right, bottom, height, width } = el.getBoundingClientRect();
  return { left, top, right, bottom, height, width };
}

export function useElementBox(el: ?Element): ?Box {
  const [r, setRect] = useState(el != null ? getElementBox(el) : el);

  if (el != null) {
    const n = getElementBox(el);

    if (
      r == null ||
      r.left !== n.left ||
      r.top !== n.top ||
      r.right !== n.right ||
      r.bottom !== n.bottom ||
      r.width !== n.width ||
      r.height !== n.height
    ) {
      setRect(n);
    }
  }

  return r;
}
