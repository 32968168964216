// @flow

import { withInlineTemplate } from '../common/inlineTemplate';
import type { CreateEnhanceEditorState } from '../../types';
import type { MacroPlaceholderPluginPropertyOptions, MacroPlaceholderPluginElement } from './types';
import type { EditorType } from 'slate';

export const enhanceEditorStateMacroPlaceholder: CreateEnhanceEditorState<
  MacroPlaceholderPluginPropertyOptions,
> = (options) => (editor: EditorType) => {
  return withInlineTemplate<MacroPlaceholderPluginElement>({
    pluginID: options.pluginID,
  })(editor);
};
