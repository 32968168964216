// @flow
import { intervalToDuration } from 'date-fns';

export default function getTimeRemaining(remainingSeconds: ?number): string {
  if (remainingSeconds == null) {
    return '';
  }
  const { minutes, seconds } = intervalToDuration({ start: 0, end: remainingSeconds * 1000 });

  if (minutes == null || seconds == null) {
    return '';
  }

  return `${minutes.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${seconds.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;
}
