// @flow

import type { EditorElement, EditorPluginOptions, EditorPluginPropertyOptions } from '../../types';
import type { ComponentType } from 'react';

export const PARAGRAPH_PLUGIN_ID: 'paragraph' = 'paragraph';
export type ParagraphPluginID = typeof PARAGRAPH_PLUGIN_ID;

export const PARAGRAPH_LIST_ITEM_VARIANT = 'li';
type ParagraphVariant = ParagraphVariant;

export type ParagraphPluginElement = $ReadOnly<{
  ...EditorElement,
  type: ParagraphPluginID,
  variant?: ParagraphVariant,
  shouldForceInline?: boolean,
}>;

export type ParagraphPluginElementMutable = {
  ...ParagraphPluginElement,
};

export type ParagraphPluginOptions = $ReadOnly<{
  ...EditorPluginOptions,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component?: ComponentType<any>,
  allowNewLines?: boolean,
}>;

export type ParagraphPluginPropertyOptions = $ReadOnly<{
  ...EditorPluginPropertyOptions,
  ...ParagraphPluginOptions,
  pluginID: ParagraphPluginID,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  component: ComponentType<any>,
  pluginName: string,
}>;
