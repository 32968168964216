// @flow

import type { CreateOnDictaphoneButtonPress } from '../../types';
import type { DictaphoneControlsPluginPropertyOptions } from './types';
import { unreachableCaseError } from 'types';
import {
  selectNextBracket,
  selectPreviousBracket,
  selectNextHeadingSectionBracket,
  selectPreviousHeadingSectionBracket,
} from '../../utils';
import type { GamepadActionId } from 'generated/graphql';

export const onDictaphoneButtonPressDictaphoneControls: CreateOnDictaphoneButtonPress<
  DictaphoneControlsPluginPropertyOptions,
> =
  ({ hotkey, pluginID, ignoreMergeFieldsInNavigation }) =>
  (action: GamepadActionId, { editor }) => {
    switch (action) {
      case 'forward':
        if (!selectNextBracket(editor, { ignoreMergeFieldsInNavigation })) {
          selectNextHeadingSectionBracket(editor, { ignoreMergeFieldsInNavigation });
        }
        return false;
      case 'back':
        if (!selectPreviousBracket(editor, { ignoreMergeFieldsInNavigation })) {
          selectPreviousHeadingSectionBracket(editor, { ignoreMergeFieldsInNavigation });
        }
        return false;
      case 'record':
        return false;
      case 'submitReport':
        // This is handled in Fields.Context, so that the FieldsProvider does not need to be lifted further up the tree
        return false;
      case 'toggleFocusMode':
        return false;
      default:
        unreachableCaseError(action);
    }
  };
