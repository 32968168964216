// @flow

import type { Stack } from '../ViewportsConfigurations/types';
import { isSupportedRender } from './stackUtils';
import { PDF_SOP_CLASS_UIDS, useIsRenderableStructuredReportStack } from './viewerUtils';
import { useMemo } from 'react';

// These functions cause issues hitting functionality not defined on SharedWorkers,
// so they have been split off to avoid the general stackUtils import breaking
// the SharedWorker startup.

/** Returns true if all frames' SOP Class UIDs matches the list of supported non-pixel UIDs */
export const useIsSupportedNonPixelsSOPClass = (stack: ?Stack): boolean => {
  const isSupportedSR = useIsRenderableStructuredReportStack(stack);
  return useMemo(() => {
    if (stack?.frames != null) {
      return (
        isSupportedSR ||
        stack.frames.every((frame) => PDF_SOP_CLASS_UIDS.includes(frame.sopClassUID))
      );
    }

    return false;
  }, [stack, isSupportedSR]);
};

/** Returns true if the renderer is supported */
export const useCanRenderImageStack = (stack: ?Stack, renderer: string): boolean => {
  return useMemo(() => {
    return isSupportedRender(stack, renderer);
  }, [stack, renderer]);
};
