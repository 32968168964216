// @flow

import { Colors } from 'styles';
import { NotEditable } from '../../../components';
import type { RenderElementProps } from '../../../core';
import type { PlaceholderPluginElement } from '../types';
import { PLACEHOLDER_DELIMITER_LEFT, PLACEHOLDER_DELIMITER_RIGHT } from '../constants';

export type PlaceholderTemplateElementProps = $ReadOnly<{
  ...RenderElementProps<PlaceholderPluginElement>,
  selected: boolean,
  leftDelimiter?: React$Node,
  rightDelimiter?: React$Node,
  emptyText?: string,
  testID?: string,
  'data-editor-element': string,
}>;

export const PlaceholderTemplateElement: React$AbstractComponent<
  PlaceholderTemplateElementProps,
  // $FlowFixMe[unclear-type] (automated-migration-2022-01-19)
  any,
> = ({
  attributes,
  children,
  selected,
  element,
  leftDelimiter = PLACEHOLDER_DELIMITER_LEFT,
  rightDelimiter = PLACEHOLDER_DELIMITER_RIGHT,
  emptyText,
  testID,
  ...props
}) => {
  return (
    <NotEditable>
      <span
        {...attributes}
        data-testid={testID}
        data-dd-privacy="mask"
        css={`
          padding: 2px 0px;
          vertical-align: baseline;
          border-radius: 4px;
          color: ${Colors.pink6};

          &:focus {
            outline: 0;
          }
        `}
        {...props}
      >
        {leftDelimiter}
        {children}
        {emptyText != null && (
          <NotEditable>
            <span css="opacity: 0.5">{emptyText}</span>
          </NotEditable>
        )}
        {rightDelimiter}
      </span>
    </NotEditable>
  );
};
