// @flow
import type { TriggerToolInteractionMutation, ToolInteractionPayload } from 'generated/graphql';
import { usePayload } from './preparePayload';
import { NAMESPACES, sendEvent, useEventsListener } from 'modules/EventsManager/eventsManager';
import { useConfigBasedTool } from './ConfigBasedTool';
import { useCallback } from 'react';
import {
  ToolPayloadCapabilityValues,
  ToolInteractionResponseTypeValues as V,
} from 'generated/graphql';
import type { HandleCreateAnnotation } from '../Toolbox';
import type { VtkAnnotation } from '../../Annotations/types';

const TOOL_INTERACTION_TYPE_TO_RESPONSE_TYPE_NAME_MAP = {
  [V.AddInteraction]: 'ToolInteractionAddInteractionResponse',
  [V.Annotation]: 'ToolInteractionAnnotationResponse',
  [V.InsertText]: 'ToolInteractionInsertTextResponse',
  [V.KeyboardShortcut]: 'ToolInteractionKeyboardShortcutResponse',
  [V.Message]: 'ToolInteractionMessageResponse',
  [V.StartAnnotation]: 'ToolInteractionStartAnnotationResponse',
  [V.UpdateToolActiveState]: 'ToolInteractionUpdateToolActiveStateResponse',
  [V.UpdateToolbarIcon]: 'ToolInteractionUpdateToolbarButtonIconResponse',
  [V.UpdateViewportPresentationState]: 'ToolInteractionUpdateViewportPresentationStateResponse',
  [V.UpdateViewportSlice]: 'ToolInteractionUpdateViewportSliceResponse',
  [V.Widget]: 'ToolInteractionWidgetResponse',
};

export const useClientSDKHandler = ({
  createAnnotation,
  annotations,
}: {
  createAnnotation: HandleCreateAnnotation,
  annotations: Array<VtkAnnotation>,
}): void => {
  const { handleInteractionResponse } = useConfigBasedTool({
    createAnnotation,
    annotations,
    payloadCapabilities: [], // they will be overridden at `triggerToolInteraction` call
  });

  const handleDREViewportMessage = useCallback(
    ({ payload }) => {
      if (payload.type !== 'SDKTriggerToolInteractionResponse') {
        return;
      }

      handleInteractionResponse(payload.responses, 'SDK');
    },
    [handleInteractionResponse]
  );

  useEventsListener(NAMESPACES.DRE, handleDREViewportMessage);
};

export const ClientSDKAdaptor = ({
  createAnnotation,
  annotations,
}: {
  createAnnotation: HandleCreateAnnotation,
  annotations: Array<VtkAnnotation>,
}): React$Node => {
  useClientSDKHandler({
    createAnnotation,
    annotations,
  });

  return null;
};

export function triggerToolInteractionResponse(
  responses: TriggerToolInteractionMutation['triggerToolInteraction']
) {
  sendEvent(NAMESPACES.DRE, {
    type: 'SDKTriggerToolInteractionResponse',

    /**
     * GraphQL doesn't support assigning a specific enum value to a property,
     * this means we end up with the `type` property being the whole enum for every case
     * even though we know it will be assigned to a specific enum value.
     * Here we are trying to reconcile this by assigning the `__typename` property
     * to its corresponding GraphQL type name. Flow correctly tries to warn us that
     * we may end up with a wrong value, but we know that this is not the case.
     *
     * See https://github.com/graphql/graphql-spec/issues/878
     */
    // $FlowIgnore[incompatible-call]
    responses: responses.map((response) => ({
      ...response,
      __typename:
        response.type != null
          ? // $FlowIgnore[incompatible-call]
            TOOL_INTERACTION_TYPE_TO_RESPONSE_TYPE_NAME_MAP[response.type]
          : undefined,
    })),
  });
}

export function useSDKPayload(): ToolInteractionPayload {
  // $FlowFixMe[incompatible-call] we know values will return valid interaction type values
  return usePayload(Object.values(ToolPayloadCapabilityValues));
}
