// @flow

export const marks = Object.freeze({
  Render: {
    Start: (id: string): string => `viewport-${id}.render.start`,
    Rendered: (id: string): string => `viewport-${id}.render.rendered`,
    Interactable: (id: string): string => `viewport-${id}.render.interactable`,
    Finished: (id: string): string => `viewport-${id}.render.finished`,
  },
  Annotation: {
    Start: (id: string): string => `${id}_start`,
  },
  Annotations: {
    Start: (id: string): string => `${id}_render`,
  },
});

type MeasureArgs = [string, string];
export const measures = Object.freeze({
  Viewport: {
    TimeToRendered: (id: string): MeasureArgs => [
      `viewport_rendering.${id}.rendered`,
      marks.Render.Start(id),
    ],
    TimeToInteractable: (id: string): MeasureArgs => [
      `viewport_rendering.${id}.interactable`,
      marks.Render.Start(id),
    ],
    TimeToFinished: (id: string): MeasureArgs => [
      `viewport_rendering.${id}.finished`,
      marks.Render.Start(id),
    ],
    TimeToError: (id: string): MeasureArgs => [
      `viewport_rendering.${id}.error`,
      marks.Render.Start(id),
    ],
  },
  Annotation: (id: string): MeasureArgs => [
    `annotation_rendering.${id}.finished`,
    marks.Annotation.Start(id),
  ],
  Annotations: (id: string): MeasureArgs => [
    `annotations_rendering.${id}.finished`,
    marks.Annotations.Start(id),
  ],
});
