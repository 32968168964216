// @flow
import { custom, tuple, number } from '@recoiljs/refine';
import type { Checker } from '@recoiljs/refine';
import type { vec3 } from '@kitware/vtk.js/Common/Core/Math';

// Same as stringLiterals but ignores the key values and only checks the values
// useful to test *Values objects generated by Codegen
export function stringLiteralsCodegen<T: { +[string]: string }>(
  enumValues: T
): Checker<$Values<T>> {
  return custom(
    (value) => {
      if (typeof value !== 'string') {
        return null;
      }

      const out = Object.values(enumValues).includes(value);
      if (out == null) {
        return null;
      }
      return value;
    },
    `value is not one of ${Object.keys(enumValues).join(', ')}`
  );
}

export const vec3Checker = (): Checker<vec3> => tuple(number(), number(), number());
