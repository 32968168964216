// @flow

import type { ListPluginElement, ListPluginPropertyOptions } from '../types';
import type { StyledComponent } from 'styled-components';
import styled from 'styled-components';
import { LIST_VARIANTS } from '../constants';
import { forwardRef } from 'react';

type ListEditableProps = {
  pluginOptions: ListPluginPropertyOptions,
  element: ListPluginElement,
  children: React$Node,
};

export const ListEditable: React$AbstractComponent<ListEditableProps, Element> = forwardRef<
  ListEditableProps,
  Element,
>(({ pluginOptions: { pluginID }, element, children, ...rest }, ref) => {
  if (element.variant === LIST_VARIANTS.ol) {
    return (
      <OrderedListComponent ref={ref} pluginID={pluginID} element={element} {...rest}>
        {children}
      </OrderedListComponent>
    );
  } else if (element.variant === LIST_VARIANTS.ul) {
    return (
      <UnorderedListComponent ref={ref} pluginID={pluginID} element={element} {...rest}>
        {children}
      </UnorderedListComponent>
    );
  } else {
    return null;
  }
});

ListEditable.displayName = 'ListEditable';

const OrderedListComponent: StyledComponent<{ ... }, { ... }, HTMLOListElement> = styled.ol`
  margin: 0;
`;

export const OrderedListRenderer = OrderedListComponent;

const UnorderedListComponent: StyledComponent<{ ... }, { ... }, HTMLUListElement> = styled.ul`
  margin: 0;
`;

export const UnorderedListRenderer = UnorderedListComponent;
